import { useEffect } from "react";
import { AiOutlineHome, AiOutlineRadarChart, AiOutlineCar, AiOutlineSend } from "react-icons/ai";
import { BiBox } from "react-icons/bi";
import { BsFileEarmarkText, BsTools } from "react-icons/bs";
import { FaUserAltSlash } from "react-icons/fa";
import { FiUsers, FiShare2 } from "react-icons/fi";
import { GiCaptainHatProfile } from "react-icons/gi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { SiMongodb } from "react-icons/si";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {CardBody, CardHeader, Input } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { useAppSelector } from "../../../redux/hooks";
import { Subscription } from "./Subscription";



export const Modules = () => {
    const { manage } = useAppSelector((state) => state.billingReducer);
    const { modules } = manage;

    const modulesList = [
        { icon: AiOutlineHome, name: 'poi' },
        { icon: AiOutlineRadarChart, name: 'zone' },
        { icon: IoIosNotificationsOutline, name: 'alarm' },
        { icon: GiCaptainHatProfile, name: 'driver' },
        { icon: SiMongodb, name: 'ecoDriving' },
        { icon: FiUsers, name: 'user' },
        { icon: BsFileEarmarkText, name: 'report' },
        { icon: FaUserAltSlash, name: 'blacklist' },
        { icon: BiBox, name: 'group' },
        { icon: AiOutlineCar, name: 'vehicle' },
        { icon: AiOutlineSend, name: 'command' },
        { icon: FiShare2, name: 'share' },
        { icon: BsTools, name: 'maintenance' },
        { icon: BsTools, name: 'instant-report' },
        { icon: BsTools, name: 'stops' },
        { icon: BsTools, name: 'road' },
    ];

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="USER.ROLE" /></h4>
            </CardHeader>
            <CardBody>
            {modulesList.map((current) => (
                    <Subscription
                        ICON={current.icon}
                        name={current.name}
                        active={modules.includes(current.name)}
                    />
                ))}
            </CardBody>
        </CardUi>               
    );
}
import React from "react";
import Select, { Props } from "react-select";
import {Label } from "reactstrap";

type InputSelectType =  Omit<Props, 'classNamePrefix'> & {label?: string};
export const InputSelect = (props: InputSelectType) => {
    const {label, ...options} =  props;
    return (
        <React.Fragment>
            { label && <Label htmlFor="choices-single-default">{label}</Label> }                                           
            <Select
                {...props}
                className="choices"
                classNamePrefix="choices"
            />
        </React.Fragment>
    );
};
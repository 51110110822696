import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col} from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearVehicleCreation, CreateVehicleStart, GetVehicleUpdateStart } from "../../redux/vehicleModule.actions";
import { General } from "./General";

export const ManageVehicleBasicInfo = () => {
    const dispatch = useDispatch();
    const {_id} = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { loading, manage } = useAppSelector((state) => state.vehicleModuleReducer);
    

    const onSubmit = async (data: any) => {
        dispatch(CreateVehicleStart(data));
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/units' });
    }


    useEffect(() => {
        if(_id && _id !== 'add') {
            dispatch(GetVehicleUpdateStart({_id}));
        }
        return function cleanup() {
            dispatch(ClearVehicleCreation());
        };
    }, []);

    useEffect(()=> {
        if(manage.name) {
            const {...data } = manage;
            const userInfo:any = data;
            Object.keys(data).forEach((key: any) => setValue(key, userInfo[key]));    
        }
    }, [manage.name]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
            <Row>
                <LoaderUi loading={loading}/>

                <Col xl={12}>
                    <Col>
                        <General register={register} errors={errors}/>
                    </Col>
                </Col>
                {/* <Col xl={6}>
                    <Profile register={register} errors={errors}/>
                </Col>
                <Col xl={6}>
                    <Engine register={register} errors={errors}/>
                </Col> */}

                <Col>
                    <div className="hstack gap-2 justify-content-end">
                        <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                            <FormattedMessage id="COMMON.CANCEL"/>
                        </ButtonUi>
                        <ButtonUi color={"primary"} type="submit">
                            {
                                _id ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                            }
                            
                        </ButtonUi> 
                    </div>
                </Col>
                

            </Row>
        </Form>
    );
}
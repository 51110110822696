import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Form, Row, Col, FormGroup} from "reactstrap";
import { LoaderUi } from "../../../../../../Components/basic/LoaderUi";
import { InputSelect } from "../../../../../../Components/Form/InputSelect";
import { InputText } from "../../../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../../../redux/hooks";
import { SetFuelConfig, SetFuelDetection, ToogleFuelConfig } from "../../../../redux/vehicleModule.actions";


export const FuelConfiguration = ({className}: {className: string}) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const STATUS_OPTIONS  = [
        {
            label: intl.formatMessage({id: 'COMMON.ACTIVATE'}),
            value: true
        },
        {
            label: intl.formatMessage({id: 'COMMON.DEACTIVATE'}),
            value: false
        }
    ];

    const DETECTION_TYPE  = [
        {
            label: intl.formatMessage({id: 'FUEL.MIELAGE'}),
            value: "mielage"
        },
        {
            label: intl.formatMessage({id: 'FUEL.TIME_BASED'}),
            value: "time"
        }
    ];
    
    const { loading, sensors } = useAppSelector((state) => state.vehicleModuleReducer);
    const { register, formState: { errors }, getValues, setValue } = useForm({
        mode: 'all',
        defaultValues: {
            drill: sensors.fuel && sensors.fuel.drill,
            fill: sensors.fuel && sensors.fuel.fill,
            fillThreshold: sensors.fuel && sensors.fuel.fillThreshold,
            drillThreshold: sensors.fuel && sensors.fuel.drillThreshold,
            timeout: sensors?.fuel?.timeout ?? 0,

        }
    });

    const onSubmit = async () => {
        const fill = getValues('fill');
        const drill = getValues('drill');
        const fillThreshold = getValues('fillThreshold');
        const drillThreshold = getValues('drillThreshold');
        const timeout = getValues('timeout');
        if(sensors.fuel && fill && drill && fillThreshold && drillThreshold) {
            dispatch(SetFuelConfig({
                fill: +fill, 
                drill: +drill, 
                fillThreshold: +fillThreshold, 
                drillThreshold: +drillThreshold, 
                type: sensors.fuel.type, 
                timeout
            }));
        }
    }

    useEffect(()=> {
        if(sensors.fuel) {
            const {fill, drill, fillThreshold, drillThreshold} = sensors.fuel;
            setValue('fill', +fill);
            setValue('drill', +drill);
            setValue('fillThreshold', +fillThreshold);
            setValue('drillThreshold', +drillThreshold);
        } else {
            setValue('fill', 0);
            setValue('drill', 0);
            setValue('fillThreshold', 0);
            setValue('drillThreshold', 0);
        }
    }, [sensors.fuel]);

    return (
        <Form className={`position-relative ${className}`}>
            <Row>
                <LoaderUi loading={loading}/>

                <Col xl={12}>
                    <FormGroup>
                        <InputSelect
                            label={intl.formatMessage({ id: 'FUEL.ACTIVATE_MODULE' })}
                            value={STATUS_OPTIONS.find(type => type.value === !!sensors.fuel) ?? []}
                            options={STATUS_OPTIONS}
                            onChange={(element) => dispatch(ToogleFuelConfig())}
                        />
                    </FormGroup>
                </Col>
                <Col xl={12}>
                    <FormGroup>
                        <InputSelect
                            label={intl.formatMessage({ id: 'FUEL.DETECTION_TYPE' })}
                            value={DETECTION_TYPE.find(detection => detection.value === sensors.fuel?.type)}
                            options={DETECTION_TYPE}
                            onChange={(element: any) => dispatch(SetFuelDetection(element.value))}
                            isDisabled={!sensors.fuel}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            disabled={!sensors.fuel}
                            type="number"
                            min={1} 
                            label={intl.formatMessage({id: 'FUEL.MINIUM_FILL'})}
                            id={'fill'}
                            name={'fill'}
                            placeholder={intl.formatMessage({id: 'FUEL.PALCEHOLDER.MINIUM_FILL'})}
                            register={register('fill', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }
                            })}
                            onChange={(e: any) => {
                                const value = +e.target.value;
                                setValue("fill", value === 0 ? 1 : value);
                                onSubmit();
                            }}
                            error={errors['fill']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            disabled={!sensors.fuel}
                            type="number"
                            min={1} 
                            label={intl.formatMessage({id: 'FUEL.MINIUM_FILL_THREASHOLD'})}
                            id={'fillThreshold'}
                            name={'fillThreshold'}
                            placeholder={intl.formatMessage({id: 'FUEL.PALCEHOLDER.MINIUM_FILL_THREASHOLD'})}
                            register={register('fillThreshold', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }
                            })}
                            onChange={(e: any) => {
                                const value = +e.target.value;
                                setValue("fillThreshold", value === 0 ? 1 : value);
                                onSubmit();
                            }}
                            error={errors['fillThreshold']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            disabled={!sensors.fuel}
                            type="number"
                            min={1} 
                            label={intl.formatMessage({id: 'FUEL.MINIUM_DRILL'})}
                            id={'drill'}
                            name={'drill'}
                            placeholder={intl.formatMessage({id: 'FUEL.PALCEHOLDER.MINIUM_DRILL'})}
                            register={register('drill', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            onChange={(e: any) => {
                                const value = +e.target.value;
                                setValue("drill", value === 0 ? 1 : value);
                                onSubmit();
                            }}
                            
                            error={errors['drill']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            disabled={!sensors.fuel}
                            type="number"
                            min={1} 
                            label={intl.formatMessage({id: 'FUEL.MINIUM_DRILL_THREASHOLD'})}
                            id={'drillThreshold'}
                            name={'drillThreshold'}
                            placeholder={intl.formatMessage({id: 'FUEL.PALCEHOLDER.MINIUM_DRILL_THREASHOLD'})}
                            register={register('drillThreshold', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }
                            })}
                            onChange={(e: any) => {
                                const value = +e.target.value;
                                setValue("drillThreshold", value === 0 ? 1 : value);
                                onSubmit();
                            }}
                            error={errors['drillThreshold']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            disabled={!sensors.fuel}
                            type="number"
                            min={0} 
                            label={intl.formatMessage({id: 'FUEL.TIME_OUT'})}
                            id={'timeout'}
                            name={'timeout'}
                            placeholder={intl.formatMessage({id: 'FUEL.PALCEHOLDER.TIME_OUT'})}
                            register={register('timeout', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            onChange={(e: any) => {
                                const value = +e.target.value;
                                setValue("timeout", value);
                                onSubmit();
                            }}
                            
                            error={errors['timeout']}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}
import { axiosInstance } from '../../../redux/setupAxios';
import { EventType, FilterEventType, ManageEvenType } from './types';

export async function fetchTypesEvent(): Promise<EventType> {
    const url = `/v2/event/types`;
    try {
        const res = await axiosInstance.get<EventType>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function saveEvent(event: ManageEvenType): Promise<any> {
    const url = `/v2/event`;
    try {
        const res = await axiosInstance.post<any>(url, event);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateEvent(event: ManageEvenType): Promise<any> {
    const url = `/v2/event`;
    try {
        const res = await axiosInstance.patch<any>(url, event);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getEvents(filter: FilterEventType): Promise<any> {
    let url = `/v2/event?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteEvents({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number; deletedIds: string[] } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number; deletedIds: string[] }>(
        `/v2/event${params}`
    );
}

export async function UpdateEventsStatus({
    _ids,
    status,
}: {
    _ids: string[];
    status: boolean;
}): Promise<{ data: { modifiedCount: number; updatedIds: string[] } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.patch(`/v2/event/status`, {
        status,
        _ids,
    });
}

export async function GetEvent({
    _id,
}: {
    _id: string;
}): Promise<{ data: ManageEvenType }> {
    return axiosInstance.get(`/v2/event/${_id}`);
}

export async function getAssignedVehicle(_id: string): Promise<string[]> {
    const url = `/v2/event/${_id}/vehicles`;
    try {
        const res = await axiosInstance.get<string[]>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function assignVehiclesToEvent(
    _id: string,
    vehicles: string[]
): Promise<{
    updatedBy: string;
    updatedAt: string;
    modifiedCount: number;
}> {
    const url = `/v2/event/assign`;
    try {
        const res = await axiosInstance.patch<any>(url, {
            _id,
            vehiclesToAssign: vehicles,
        });
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function findAlarmsType(tracer: string, typedText?: string): Promise<any[]> {
    let url = `/v2/event/types/${tracer}`;
    if(typedText){
        url = `${url}?name=${typedText}`
    }
    try {
        const res = await axiosInstance.get<any[]>(url);
        return res.data;
    } catch (e: any) {
        
    }
    return [];
}

export async function findAlarmsSubType(tracer: string, subType?: string, typedText?: string): Promise<any[]> {
    let url = `/v2/event/${tracer}/subType/${subType}`;
    if(typedText){
        url = `${url}?name=${typedText}`
    }
    try {
        const res = await axiosInstance.get<any[]>(url);
        return res.data;
    } catch (e: any) {
        
    }
    return [];
}

export async function getZonesForEvent(value: string): Promise<any[]> {
    let params = '';
    if (value) {
        params = `?name=${value}`;
    }
    const zones = await axiosInstance.get<any[]>(
        `geofence/event${params}`
    );
    return zones.data;
}




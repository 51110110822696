import { divIcon } from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { BsFillPlayFill, BsStopFill } from 'react-icons/bs';
import { FaParking } from 'react-icons/fa';
import { Marker } from 'react-leaflet';
import { ActiveTripOnType } from '../../../../pages/Map/Object/History/_redux/types';

export function TrajectLimitMarker({ activeTrip }: {activeTrip: ActiveTripOnType}) {
    
    const refMarker = useRef<any>();
    const iconPinHtml = renderToStaticMarkup(
        <div className={`marker-pin bg-danger`}>
            <BsFillPlayFill size={16} className={'text-danger'}/>
        </div>
    );

    const iconPinHtmlStop = renderToStaticMarkup(
        <div className={`marker-pin bg-info`}>
            <FaParking size={15} className={'text-info'}/>
        </div>
    );

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });

    const iconPinStop = divIcon({
        html: iconPinHtmlStop,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });

    useEffect(()=> {
        refMarker.current?.setZIndexOffset(999);
    }, [refMarker.current])


    if(activeTrip.on && activeTrip.traject.length > 0) {
        const start = activeTrip.traject[0];
        const stop = activeTrip.traject[activeTrip.traject.length-1];

        return(
            <>
                <Marker 
                    ref={refMarker} icon={iconPin}
                    position={{lat: start.location[1], lng: start.location[0]}} 
                />
                <Marker 
                    ref={refMarker} icon={iconPinStop}
                    position={{lat: stop.location[1], lng: stop.location[0]}} 
                />
            </>
        ) 


    }

    return (  null );
}

import classNames from "classnames";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { formatDate } from "../../../helpers/dateHelper";
import { SetActiveStart } from "../../../pages/Map/Object/Fuel/_redux/fuelActions";
import { useAppSelector } from "../../../redux/hooks";

const EventName = ({event}: {event: any}) => {
    let eventName = '';

    if(event.type === 'fill') {
        eventName = `Remplissage (+${event.value.toFixed(2)} Litres)`;
    } else if(event.type === 'drill') {
        eventName = `Pérte (-${event.value.toFixed(2)} Litres)`;
    }

    return <h6 className="mb-0">{eventName}</h6>
}

const EventIcon = ({event}: {event: any}) => {
      if(event.type === 'fill') {
        return <HiOutlineArrowUp size={18}/>
    } else if(event.type === 'drill') {
        return <HiOutlineArrowDown size={18}/>
    }
    return <FiHelpCircle size={18}/>
    
}

export const FuelInfo = ({event, active}: {event: any, active: boolean}) => {
    const dispatch = useDispatch();
    const user = useAppSelector((state) => state.authReducer.user);
    const fuelInfoClassName = classNames({
        [`d-flex flex-row justify-content-between align-items-center py-2 px-3`]: true,
        ['event-info']: true,
        ['active']: active
    });
    const handleClick = (event: any) => {
        dispatch(SetActiveStart(event))
    }
    return(
        <div className={fuelInfoClassName} onClick={()=> handleClick(event)}>
                <div className="d-flex flex-column">
                    <EventName event={event}/>
                    <span className="text-muted address">...</span>
                    <span className="text-muted">{
                        user ? formatDate(event.gpsDate, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
                    }</span>
                </div>
                <EventIcon event={event} />
        </div>
    )  
}
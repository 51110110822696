import { useEffect, useState } from "react";
import { useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Modal from "../../Components/Modal/modal";
import { Col, Form, FormGroup, ModalBody } from "reactstrap";
import ModalHeader from "../../Components/Modal/modal-header";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { InputText } from "../../Components/Form/InputText";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { useForm } from "react-hook-form";
import { useManagePassenger } from "../../common/hooks/axios/passenger/useManagePassenger";
import { SetRedirectAction, SetNotificationAction } from "../../redux/rootActions";
import { useFetchPassenger } from "../../common/hooks/axios/passenger/useFetchPassenger";
import { AsyncInputSelect } from "../../Components/Form/AsyncInputSelect";
import { axiosInstance } from "../../redux/setupAxios";

const ManagePassengerModal = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [queryParameters] = useSearchParams();
    const {key} = useParams();

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const { saveAsyncPassenger } = useManagePassenger({key});
    const { passenger } = useFetchPassenger({manual: false, key});
    const [ passengerStop, setPassengerStop ] = useState();

    const addPassenger = useMatch('/passengers');
    const updatePassenger = useMatch('/passengers/:key/details');
    const isPassenger = !!addPassenger || !!updatePassenger;
    const isUpdate = queryParameters.get("action") === "update";
    const isCreation = queryParameters.get("action") === "add";

    // Effects
    useEffect(()=> {
        if(passenger) {
            setValue('name', passenger?.name);
            setValue('phone', passenger?.phone);
            setValue('identifier', passenger?.identifier);
            if(passenger.stop) {
                setStop(passenger.stop);
            }
        }
    }, [passenger?.stop, passenger?.identifier, passenger?.name, passenger?.phone, passenger?.location]);

    // Events
    const onSubmit = async (data: any) => { 
        if(passengerStop) {
            const result = await saveAsyncPassenger({...data, key, stop: passengerStop});
            if(result.success) {            
                const successId = result.success.insertedId ?? key;      
                dispatch(SetRedirectAction(`/passengers/${successId}/details`));
                dispatch(SetNotificationAction({
                    title: "succès",
                    "message": key ? "Passager modifié" : "Passager ajouté",
                    timeToClose: 3000,
                    type: 'success'
                }));
            }
        }
    }

    const toggleModal = () => {
        if(key) {
            navigate(`/passengers/${key}/details`)
        } else {
            navigate(`/passengers`)
        }     
    };

    const fetchStops = async (inputValue: string) => getStops({ name: inputValue });
    const setStop = (stop: any) => { setPassengerStop(stop) };


    return (
        <Modal isOpen={isPassenger && (!!isCreation || !!isUpdate)} size={"md"} fullscreen={"md"} toggle={toggleModal}>
            <ModalBody>
                <ModalHeader 
                    Icon={MdOutlineAirlineSeatReclineNormal} 
                    title={isUpdate ? "Modifier le passager" : "Ajouter un passager"} 
                    description={"Veuillez renseigner les informations demandées."} 
                />
                <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'PASSENGER.NAME'})}
                            id={'name'}
                            name={'name'}
                            placeholder={intl.formatMessage({id: 'PASSENGER.PLACEHOLDER.NAME'})}
                            register={register('name', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                },
                                maxLength: {
                                    value: 30,
                                    message: `${intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })}: 30`
                                }})}
                            error={errors['name']}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'PASSENGER.IDENTIFIER'})}
                            id={'identifier'}
                            name={'identifier'}
                            placeholder={intl.formatMessage({id: 'PASSENGER.PLACEHOLDER.IDENTIFIER'})}
                            register={register('identifier')}
                            error={errors['identifier']}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'PASSENGER.PHONE'})}
                            id={'phone'}
                            name={'phone'}
                            placeholder={intl.formatMessage({id: 'PASSENGER.PLACEHOLDER.PHONE'})}
                            register={register('phone')}
                            error={errors['phone']}
                        />
                    </FormGroup>
                    <FormGroup>
                        {
                            <AsyncInputSelect 
                                isClearable
                                label={"Arrêt"}
                                cacheOptions
                                defaultOptions
                                defaultValue={passengerStop}
                                error={!passengerStop}
                                loadOptions={fetchStops}
                                value={passengerStop}
                                onChange={setStop}
                            />
                        }
                    </FormGroup>
                    <Col>
                        <div className="mt-4 hstack gap-2 justify-content-end">
                            <ButtonUi color={"primary"} soft>
                                <FormattedMessage id="COMMON.CANCEL"/>
                            </ButtonUi>
                            <ButtonUi color={"primary"} type="submit">
                                {
                                    isUpdate ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                                }
                            </ButtonUi> 
                        </div>
                    </Col>
                </Form>
            </ModalBody>
        </Modal>
    );    
}

export default ManagePassengerModal;

export async function getStops({
    name,
}: {
    name: string;
}): Promise<{label: string, value: string}[]> {
    const url = `/stops/select?name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}
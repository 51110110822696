import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsQuestionCircle } from 'react-icons/bs';
import { FaCarCrash, FaGasPump } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import { GiNinjaMask } from 'react-icons/gi';
import { GoSettings } from 'react-icons/go';
import { IoMdSpeedometer } from 'react-icons/io';
import { MdPanTool, MdTrackChanges } from 'react-icons/md';
import { RiLayoutTop2Fill, RiLayoutTop2Line } from 'react-icons/ri';
import FuelIcon from '../../../../Components/advanced/Object-info/Icon/FuelIcon';
import IdlingnIcon from '../../../../Components/advanced/Object-info/Icon/idlingIcon';
import IgnitionIcon from '../../../../Components/advanced/Object-info/Icon/ignitionIcon';
import MovementIcon from '../../../../Components/advanced/Object-info/Icon/movementIcon';


type TypeIcon = {
    type: string;
    size?: number;
    map?: boolean;
};
export function Icon({ type, map, size=12 }: TypeIcon): JSX.Element {
    switch (type) {
        case 'notAuthorized':
            return <MdPanTool size={size}/>;
        case 'inZone':
        case 'outZone':
            return <MdTrackChanges size={12}/>;
        case 'speed':
            return <IoMdSpeedometer size={size}/>;
        case 'noElectricity':
        case 'towing':
            return <AiOutlineQuestionCircle size={size}/>;
        case 'jamming':
            return <GiNinjaMask size={size}/>;
        case 'crash':
            return <FaCarCrash size={size}/>;
        case 'fill':
            return <FaGasPump size={size}/>;
        case 'hood_close':
            return <RiLayoutTop2Fill size={size}/>;
        case 'hood_open':
            return<RiLayoutTop2Line size={size}/>;
        default:
            return <BsQuestionCircle size={size}/>;
    }
}


export const EventMapIcon = ({event}: {event: any}) => {
    if(event.type === 'custom') {
        return <GoSettings size={15}/>
    } else {
        switch(true) {
            case event.type === "drill": 
            case event.type === "fill": 
                return <FuelIcon fill={event.type === 'fill'} size={13} />
            case event.type === 'event-time' && event.io === 'ignition':
                return <IgnitionIcon enable={event.value} size={13} />
            case event.type === 'event-time' && event.io === 'idling':
                return <IdlingnIcon enable={event.value} size={15}/>
            case event.type === 'event-time' && event.io === 'movement':
                return <MovementIcon speed={"1"} moving={event.value} size={15}/>
    }
    }
    return <FiHelpCircle size={14}/>
    
}
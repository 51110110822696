import React from 'react';

//import images
import { layoutModeTypes } from '../types';
import { FullScreenDropdown } from '../../Components/Dropdown/FullScreenDropDown';
import { ProfileDropdown } from '../../Components/Dropdown/ProfileDropdown';
import { LightDark } from '../../Components/LightDark';
import { NotificationDropdown } from '../../async-components/notification-dropdown/notification-dropdown';


type HeaderType = {
    layoutMode: layoutModeTypes
    onChangeLayoutMode: ()=> void
}
const Header = ({ onChangeLayoutMode, layoutMode }: HeaderType) => {

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767) {
            const burger = document.querySelector(".hamburger-icon");
            if(burger) {
                burger.classList.toggle('open');
            }
        }
        
        if (document.documentElement.getAttribute('data-layout') === "vertical") {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }
    };
    
    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            {/* <div className="navbar-brand-box horizontal-logo">
                                <a href="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </a>

                                <a href="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLight} alt="" height="17" />
                                    </span>
                                </a>
                            </div> */}

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>

                    <div className="d-flex align-items-center">
                        {/* <LanguageDropdown/> */}
                        <FullScreenDropdown/>
                        <LightDark layoutMode={layoutMode} onChangeLayoutMode={onChangeLayoutMode}/>
                        <NotificationDropdown />
                        <ProfileDropdown/>
                    </div>
                        
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
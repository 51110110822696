import { useEffect, useState } from "react";
import { FaCircle, FaParking } from "react-icons/fa";
import { CardBody, Col } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { getUnitsStats } from "../dashboard.service";

export const ParcStatus = () => {

    const [ loading, setLoading ] = useState(false);
    const [ status, setStatus ] = useState({total: 0, movement: 0, parking: 0, idling: 0});

    useEffect(()=> {
        setLoading(true);
        getUnitsStats()
            .then((response)=> {
                setStatus(response);
            })
            .catch((response)=> {
                setStatus(response);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);

    return (
        <>
            <Col md={3}>
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardBody>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <FaCircle className="text-primary me-3" size={20}/>
                            <div className="d-flex flex-column justify-content-center align-items-start">
                                <span>Total des unités</span>
                                <span className="fs-20">{ status.movement + status.idling + status.parking }</span>
                            </div>
                        </div>
                    </CardBody>
                </CardUi> 
            </Col>
            <Col md={3}>
                <CardUi>
                <LoaderUi loading={loading}/>
                    <CardBody>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <FaCircle className="text-success me-3" size={20}/>
                            <div className="d-flex flex-column justify-content-center align-items-start">
                                <span>Unités en mouvement</span>
                                <span className="fs-20">{ status.movement }</span>
                            </div>
                        </div>
                    </CardBody>
                </CardUi> 
            </Col>
            <Col md={3}>
                <CardUi>
                <LoaderUi loading={loading}/>
                    <CardBody>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <FaParking className="text-info me-3" size={24}/>
                            <div className="d-flex flex-column justify-content-center align-items-start">
                                <span>Unités en arrêt</span>
                                <span className="fs-20">{ status.parking }</span>
                            </div>
                        </div>
                    </CardBody>
                </CardUi> 
            </Col>
            <Col md={3}>
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardBody>
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <FaCircle className="text-warning me-3" size={24}/>
                            <div className="d-flex flex-column justify-content-center align-items-start">
                                <span>Unités en ralenti</span>
                                <span className="fs-20">{ status.idling }</span>
                            </div>
                        </div>
                    </CardBody>
                </CardUi> 
            </Col>
        </>             
    );
}
import { GET_TOKEN_END, GET_TOKEN_START, SharedMapActionType, TOOGLE_IS_SHARED } from "./sharedMap.actions";

type StateType = {
    isShared: boolean;
    loading: boolean;
    token?: string;
};

const INITIAL_STATE: StateType = {
    loading: false,
    isShared: false,
};

export function sharedMapReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: SharedMapActionType
): StateType {
    switch (action.type) {
        case GET_TOKEN_START:
            return {
                ...state,
                token: undefined,
                loading: true
            }
        case GET_TOKEN_END:
            return {
                ...state,
                token: action.payload,
                loading: false
            }
        case TOOGLE_IS_SHARED:
            return {
                ...state,
                isShared: !state.isShared
            }
        default:
            return state;
    }
}

import { VehicleEndListType as UnitListEndType, VehicleStartListType } from './types';

export const GET_START = '[VEHICLES_MAP] GET_START';
export const GET_SHARE_START = '[VEHICLES_MAP] GET_SHARE_START';

export const GET_END = '[VEHICLES_MAP] GET_END';
export const ERROR = '[VEHICLES_MAP] ERROR';

export type GetStartActionType = {
    type: typeof GET_START;
    payload: VehicleStartListType;
};
export function GetStart(payload: VehicleStartListType): GetStartActionType {
    return { type: GET_START, payload };
}

export type GetShareStartActionType = {
    type: typeof GET_SHARE_START;
    payload: VehicleStartListType & {_id: string};
};
export function GetShareStart(payload: VehicleStartListType & {_id: string}): GetShareStartActionType {
    return { type: GET_SHARE_START, payload };
}

export type GetEndActionType = {
    type: typeof GET_END;
    payload: UnitListEndType;
};
export function GetEnd(payload: UnitListEndType): GetEndActionType {
    return { type: GET_END, payload };
}

export const SET_VEHICLE_DATA = '[VEHICLES_MAP] SET_VEHICLE_DATA';
export type SetVehicleDataInListType = {
    type: typeof SET_VEHICLE_DATA;
    payload: any;
};
export function SetVehicleDataInList(payload: any): SetVehicleDataInListType {
    return { type: SET_VEHICLE_DATA, payload };
}

export const SET_VEHICLE_CNX = '[VEHICLES_MAP] SET_VEHICLE_CNX';
export type SetVehicleCnxInListType = {
    type: typeof SET_VEHICLE_CNX;
    payload: any;
};
export function SetVehicleCnxInList(payload: any): SetVehicleCnxInListType {
    return { type: SET_VEHICLE_CNX, payload };
}


export const FIT_BOUNDS = '[VEHICLES_MAP] FIT_BOUNDS';
export type FitBoundsActionType = {
    type: typeof FIT_BOUNDS;
};
export function FitBoundsAction(): FitBoundsActionType {
    return { type: FIT_BOUNDS };
}

type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function Error(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export type VehiclesListActionsType =
    | GetStartActionType
    | GetShareStartActionType
    | GetEndActionType
    | SetVehicleDataInListType
    | SetVehicleCnxInListType
    | FitBoundsActionType
    | ErrorActionType;

import { Nav, NavItem, NavLink } from "reactstrap"
import classNames from 'classnames';
import { useState } from "react";
import { navUiType } from "./basic/NavUi";


type tabsType = navUiType & { defaultActive?: number, onChange?: (index: number) => void, onScroll?: () => void };

export const Tabs = ({color, tabs, justified, isCustom, arrow, pills, className, defaultActive, children, onChange, ...other}: tabsType) => {
    const [active, setActive] = useState(defaultActive ?? 0);
    
    const navClassname = classNames({
        [`${className}`]: !!className,
        [`nav-tabs-custom`]: isCustom,
        [`arrow-navtabs`]: arrow,
        [`nav-${color}`]: !!color,
    });

    const onChangeNav = (index: number) => {
        setActive(index);
        if(onChange){
            onChange(index);
        }
    }

    return (
        
            <Nav 
                color={color}
                className={navClassname}
                tabs={tabs} 
                justified={justified} 
                pills={pills || arrow}
                {...other}
                >
                    {children.map((navItem, index) => {
                        return (
                            <NavItem key={index} onClick={()=>onChangeNav(index)}>
                                <NavLink style={{ cursor: "pointer" }} className={active === index ? 'active' : ''}>
                                    {navItem}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
    )
}

type InfoDetailProps = {
    icon: any,
    title: string,
    children: any
}
const InfoDetail = ({title, children, icon}: InfoDetailProps): JSX.Element => {
    return(
        <div className="info-detail py-2 px-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                {icon}
                <div className="d-flex flex-column ms-4">
                    <h6 className="mb-0">{title}</h6>
                    <span className="text-muted fs-11">
                        {children}
                    </span>      
                </div>
            </div>
        </div>
    );
}

export default InfoDetail;
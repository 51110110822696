import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DriverAssignmentAction } from "../../../../../Components/advanced/Driver/Driver-info/actions/driverAssignment";
import { Avatar } from "../../../../../Components/basic/avatar/avatar";
import { DriverAction } from "../../../types";
import { UnbindDriverStart } from "../../../_redux/driverModule.actions";
import { DriverInListType } from "../../../_redux/types";
import DriverProfileSkeleton from "./Skeleton";

type DriverProfileProps = {
    loading: boolean,
    driver: DriverInListType | null,
    [key: string]: any
}
export const DriverProfile = ({driver, loading, ...other}: DriverProfileProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileClassName = classNames(
        'border-bottom d-flex flex-row justify-content-between align-items-center', {
        [other?.className ?? '']: !!other?.className
    });


    const assignDriver = (key: string, action: DriverAction) => {
        if(action === DriverAction.BIND) {
            navigate('?assign-request=1')
        } else if(action === DriverAction.UNBIND){
            dispatch(UnbindDriverStart({key}))
        }
    }

    if(loading) {
        return <div className={profileClassName}> <DriverProfileSkeleton /></div>
    }

    if(!driver) {
        return <></>
    }

    return (
        <div className={profileClassName}>
            <div className="d-flex flex-row">
                <Avatar 
                    text={driver.name ?? '-'}
                    size="sm" 
                    color="primary"
                    className="me-3"
                />
                <div className="d-flex flex-column justify-content-center fs-12">
                    <label className="mb-0">{driver.name ?? '-'}</label>
                    {
                        driver?.assignment?.to && <span className="text-muted fs-11">{driver?.assignment?.to}</span>
                    }
                    
                </div>
            </div>

            <DriverAssignmentAction driver={driver} onClick={assignDriver}/>
        </div>             
    );
}
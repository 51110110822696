import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineLogin } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";

import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { formatDate } from "../../helpers/dateHelper";
import { useAppSelector } from "../../redux/hooks";
import { ActionTabBar } from "./components/ActionTabBar";
import { GetCompanysStart, SetSelectedCompanys, SwitchCompanyStart } from "./redux/company.actions";
import { CompanyInList } from "./types";

const FILTERS = [
    { label: 'COMMON.ALL', value: 'all' },
    { label: 'COMMON.ACTIVED', value: 'activated' },
    { label: 'COMMON.DEACTIVED', value: 'deactivated' },
    { label: 'COMMON.EXPIRED', value: 'expired' },
    { label: 'COMMON.NOT_EXPIRED', value: 'noExpired' },
    // { label: 'Avec Google API', value: 'googleApi' },
];

const CompanyPage = () => {

    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { companys, filter, selectedCompanys, loading } =
        useAppSelector((state) => state.companyReducer);


    const { user } = useAppSelector((state) => state.authReducer);
    useEffect(() => {
        if(companys.list.length === 0) dispatch(GetCompanysStart(filter));
    }, []);

    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
            case 'edit':
                navigate({
                    pathname: `/companies/${_ids[0]}`,
                });
                break;
            case 'switchTo': {
                dispatch(SwitchCompanyStart({ _id: _ids[0] }))
                break;
            }
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
        {
            name: 'divier'
        },
        {
            Icon: AiOutlineLogin,
            name: intl.formatMessage({ id: 'COMMON.SWITCH_TO' }),
            action: 'switchTo'
        },
    ];


    const columns = [
        {
            title: intl.formatMessage({ id: 'COMPANY.BILLING' }),
            dataIndex: 'plan',
            key: 'plan',
        },
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name',
            render: (name: string, data:CompanyInList) => {
                return(user?.company._id === data.key ? <BadgeUi color="primary">{name}</BadgeUi> : name)
            }
        },
        {
            title:intl.formatMessage({ id: 'COMPANY.STATUS' }),
            dataIndex: 'active',
            key: 'active',
            render: (active: boolean) => {
                if(active) {
                    return(<BadgeUi color="success" light><FormattedMessage id="COMMON.ACTIVED" /></BadgeUi>);
                }
                return(<BadgeUi color="danger" light><FormattedMessage id="COMMON.DEACTIVED" /></BadgeUi>)
            }
        },
        {
            title:intl.formatMessage({ id: 'COMPANY.SUBSCRIPTION_END' }),
            dataIndex: 'subscription',
            key: 'subscription',
            render: (date: number) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.LAST_UPDATE' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.UPDATED_BY' }),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (name: string) => {
                return (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" rounded="rounded-circle" text="name"></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>)
                
            }
            
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedCompanys(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetCompanysStart({ ...filter, ...{ select: FILTERS[index].value } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetCompanysStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetCompanysStart({ ...filter, ...{ skip, limit } }));
    };



    return (
        <div className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'COMPANY' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMPANY.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            selectedCompanys.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${companys.total})`}</div>)}
                            </Tabs>
                            :
                            <ActionTabBar selected={selectedCompanys} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={companys.list}
                            selected={selectedCompanys}
                            onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+60+ (Math.ceil(companys.total / filter.limit) > 1 ? 60 : 0)}
                        />
                    </CardBody>
                    {
                        Math.ceil(companys.total / filter.limit) > 1 ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={companys.total}
                                        skip={filter.skip}
                                        limit={filter.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                   
                </CardUi>

            </Container>
            
            
        </div>
    );
}
export default CompanyPage;
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Container, Row, Col, Form } from "reactstrap";
import { BreadCrumbActionUi } from "../../../../Components/basic/BreadCrumbActionUi";
import { useAppSelector } from "../../../../redux/hooks";


import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { SaveNewMaintenanceStart } from "../../../Maitenance/_redux/maintenance.actions";
import { General } from "./General";
import { GenearateReportStart } from "../redux/report.actions";
import { AssignVehiclesReport } from "../../../components/VehicleAssignReport";

const ManageReportPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {groupId, maintenanceId} = useParams();
    const navigate = useNavigate();
    const { user, maintenance } = useAppSelector((state) => {
        return {
            user: state.authReducer,
            maintenance: state.MaintenanceReducer
        }
    });

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm({
        mode: 'onChange'
    });

    const backToPrevious = () => {
        navigate({ pathname: '/reports' });
    }

    const onSubmit = async (data: any) => {
        const {km, engineTime, date, name, unit} = maintenance.manage;
        if((date.active || km.active || engineTime.active) 
            && name && unit && (!date.active || date.active && date.startTime)) {
            dispatch(SaveNewMaintenanceStart(data));
        }
        
    }


    return (
        <div id="driverPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'REPORTS' })} 
                    pageTitle={user.user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        
                        <Col md={6}>
                           <General />
                        </Col>
                        <Col md={12}>
                            <div className="hstack gap-2 justify-content-end">
                                <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                    <FormattedMessage id="COMMON.CANCEL"/>
                                </ButtonUi>
                                <ButtonUi color={"primary"} type="submit" onClick={()=> dispatch(GenearateReportStart())}>
                                    {
                                        !maintenanceId ? <FormattedMessage id="COMMON.GENERATE"/>  : <FormattedMessage id="COMMON.UPDATE"/>
                                    }
                                </ButtonUi> 
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <AssignVehiclesReport/>
        </div>
    );
}
export default ManageReportPage;
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { SetNotificationAction, SetRedirectAction } from '../redux/rootActions';
import {NotificationManager, NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { ModalDelete } from '../Components/modal-delete/modal-delete';

export function ToolsComponents() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { redirect, notification } = useAppSelector(
        (state) => state.mainReducer
    );
    
    useEffect(()=> {
        if(redirect) {
            navigate(redirect);
            dispatch(SetRedirectAction());
        }
    }, [redirect])

    useEffect(()=> {
        if(notification) {
            // eslint-disable-next-line default-case
            switch (notification.type) {
                case 'info':
                    NotificationManager.info(
                        notification.title,
                        notification.message,
                        notification.timeToClose
                    );
                  break;
                case 'success':
                    NotificationManager.success(
                        notification.title,
                        notification.message,
                        notification.timeToClose
                    );
                  break;
                case 'warning':
                    NotificationManager.warning(
                        notification.title,
                        notification.message,
                        notification.timeToClose
                    );
                  break;
                case 'error':
                    NotificationManager.error(
                        notification.title,
                        notification.message,
                        notification.timeToClose
                    );
                  break;
              }
            dispatch(SetNotificationAction());
        }
    }, [notification])

    return (
        <>
            <NotificationContainer/>
            <ModalDelete />
        </>
    );
}

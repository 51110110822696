import classNames from 'classnames';


type avatarGroupType = {
    className?: string;
    children?: any[];
}

export const AvatarGroup = (props: avatarGroupType) => {
    const {className, ...otherProps} = props;
    const avatarClass = classNames('avatar-group', {
        [`${className}`]: !!className
    });
    return (
        <div className={avatarClass} {...otherProps}/>
            
        
    );
}

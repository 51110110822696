import { divIcon } from 'leaflet';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker } from 'react-leaflet';
import { EventMapIcon } from '../../Icon';

export function EventMarker({ event, click, active }: any) {
    let lng = 0;
    let lat = 0;
    if(Array.isArray(event.coordinates)) {
        lng = event.coordinates[0]
        lat = event.coordinates[1]
    } else {
        lng = event.coordinates.coordinates[0];
        lat = event.coordinates.coordinates[1];
    }
    const iconPinHtml = renderToStaticMarkup(
        <div className='marker-pin bg-primary'>
            <EventMapIcon  event={event} />
        </div>
    );

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });

    const clickEvent = (): void => {
        if (click) {
            click(event);
        }
    };
    return (
        <Marker
            position={{ lat, lng }}
            icon={iconPin}
            eventHandlers={{
                click: clickEvent,
            }}
            zIndexOffset={active ? 999 : 0}
        />
    );
}

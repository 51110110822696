import { Form, FormGroup, Label, Modal, ModalBody } from "reactstrap"
import ModalHeader from "../Modal/modal-header"
import { MdWarningAmber } from "react-icons/md"
import { InputText } from "../Form/InputText";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { ButtonUi } from "../basic/ButtonUi";
import { useEffect, useState } from "react";
import Emitter from "../../helpers/emitter";
import { LoaderUi } from "../basic/LoaderUi";

type ModalDeleteEventPayloadType = {
    title: string,
    description: string,
    textConfirmation: string,
    onDelete: () => void | Promise<void>,
    onDeleteSuccess?: () => void,
    onDeleteError?: () => void
}
export const ModalDelete = () => {
    const intl = useIntl();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    
    const [isOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [eventPayload, setEventPayload] = useState<ModalDeleteEventPayloadType | null>(null);
    
    const confirmDelete = (data: ModalDeleteEventPayloadType) => { 
        if(isOpen) { onCancel(); return; }
        setOpen(true);
        setEventPayload(data);
    }
    useEffect( () => {
        Emitter.on(Emitter.event.DELETE_POPUP, confirmDelete);
        return () => {
            Emitter.off(Emitter.event.DELETE_POPUP, confirmDelete);
        };
    }, []);

    // Events
    const onSubmit =  async ({name}: any) => { 
        setLoading(true);
        const result: any = await eventPayload?.onDelete();
        if(result && result.error) {  
            if(eventPayload?.onDeleteError) { eventPayload?.onDeleteError(); }
            setLoading(false);
            return;
        }
        if(eventPayload?.onDeleteSuccess) { eventPayload?.onDeleteSuccess(); }
        setLoading(false);
        onCancel(); 
    }

    const onCancel =  () => { 
        setOpen(false);
        setValue('name', '');
        setEventPayload(null);
    }

    const inputConfirmText = watch("name");
    return(
        <Modal 
            isOpen={isOpen}
            size={"md"}
            fullscreen={"sm"}
        >
            <ModalBody>
                <LoaderUi loading={isLoading} />
                <ModalHeader 
                    Icon={MdWarningAmber} 
                    title={eventPayload?.title ?? ''} 
                    description={eventPayload?.description ?? ''} 
                />
                <Form onSubmit={handleSubmit(onSubmit)} className={`position-relative mt-5`}>
                    <FormGroup>
                        <Label for={'name'}> Pour valider la suppression, merci de saisir le texte: <span style={{fontSize: "bold"}} className="text-danger fs-13">{eventPayload?.textConfirmation}</span> </Label>
                        <InputText
                            type="text"
                            id={'name'}
                            name={'name'}
                            register={register('name', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            error={errors['name']}
                        />
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                        <ButtonUi className="me-2" color={"primary"} soft onClick={onCancel}>
                            <FormattedMessage id="COMMON.CANCEL"/>
                        </ButtonUi> 
                        <ButtonUi color={"primary"} type="submit" loading={isLoading} disabled={!inputConfirmText || inputConfirmText !== eventPayload?.textConfirmation}>
                            <FormattedMessage id="COMMON.DELETE"/>
                        </ButtonUi> 
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}
import axios from 'axios';
import Cookies from 'universal-cookie';
import { BASE_URL } from '../../../env';
import { axiosInstance } from '../../../redux/setupAxios';
import {
    LoginCookiesTypes,
    LoginEndType,
    LoginResponseType,
    LoginStartType,
    UsersEventType
} from './types';

const authKey = 'auth';
const refreshKey = 'refresh';

export async function switchToUser({ _id }: { _id: string }): Promise<any> {
    const url = `/account/switch/${_id}`;
    try {
        const res = await axiosInstance.post<any>(url);
        // await sleep(1000)
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function switchToMainUser(): Promise<any> {
    const url = `/account/switchmain`;
    try {
        const res = await axiosInstance.post<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function logIn(
    payload: LoginStartType
): Promise<{ data: LoginResponseType }> {
    const url = `${BASE_URL}/api/account/login`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };
    return axios.post(url, payload, config);
}

export async function requestNewToken(token: string): Promise<{
    data: LoginEndType;
    status: number;
}> {
    const url = `${BASE_URL}/api/account/refreshToken`;
    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    };
    return axios.post(url, { token }, config);
}

export async function fetchUsersByName(value: string): Promise<UsersEventType> {
    let params = '';
    if (value) {
        params = `?name=${value}`;
    }

    const result = await axiosInstance.get<UsersEventType>(
        `account/event${params}`
    );
    return result.data;
}

export async function fetchUsersMobileByName(
    value: string
): Promise<UsersEventType> {
    let params = '';
    if (value) {
        params = `?name=${value}`;
    }

    const result = await axiosInstance.get<UsersEventType>(
        `account/event/mobile${params}`
    );
    return result.data;
}

export function saveDataToCookies(payload: LoginEndType): void {
    const { user, expiresIn, refreshToken, token, expiresInRefresh } = payload;
    const cookies = new Cookies();
    cookies.set(
        authKey,
        JSON.stringify({
            user,
            token,
            expiresIn,
        }),
        {
            path: '/',
            expires: new Date(expiresIn),
        }
    );
    if (refreshToken)
        cookies.set(
            refreshKey,
            JSON.stringify({ refreshToken, expiresInRefresh }),
            {
                path: '/',
                expires: new Date(expiresInRefresh),
            }
        );
}

export function deleteLoginCookie(): void {
    const cookies = new Cookies();
    cookies.remove(authKey);
    cookies.remove(refreshKey);
}

export function deleteAuthCookie(): void {
    const cookies = new Cookies();
    cookies.remove(authKey);
}

export function getAuthToCookies(): LoginCookiesTypes {
    const cookies = new Cookies();
    return {
        refreshToken: cookies.get(refreshKey),
        token: cookies.get(authKey),
    };
}

export function remmoveAuthToCookies(): void {
    const cookies = new Cookies();
    return cookies.remove(authKey);
}

function sleep(s: any) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, s);
    });
}
import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import {  FiMoreHorizontal } from 'react-icons/fi';
import { IconType } from 'react-icons';

type ActionDropdown = {
    _id: string;
    onChange: (action: string, _ids: string[])=> void;
    text?: string,
    Icon?: IconType,
    options: ({
        action: string,
        Icon: IconType,
        name: string,
    } | { name: 'divier', action?: string, Icon?: IconType, })[]
}
export const ActionTableDropdown = ({_id, onChange, options, Icon, text}: ActionDropdown) => {

    const [isOpen, setOpen] = useState(false);
    const toggleProfileDropdown = () => {
        setOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isOpen} toggle={toggleProfileDropdown}>
                <DropdownToggle tag="button" type="button" className="ghost btn btn-primary rounded-circle p-0 m-0">
                        { text ? text : " "} { Icon ? <Icon className="icon-sm"/> :  <FiMoreHorizontal className="icon-sm"/>}   
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    {
                        options.map(option => {
                            if(option.Icon && option.action !== undefined) {
                                const {Icon, action} = option;
                                if(action === "delete") {
                                    return(
                                        <DropdownItem onClick={()=> onChange(action, [_id])}>
                                            <Icon className="align-middle text-muted me-1 icon-sm"/>
                                            <span className="align-middle">{option.name}</span>
                                        </DropdownItem>
                                    )
                                } else {
                                    return(
                                        <DropdownItem onClick={()=> onChange(action, [_id])}>
                                            <Icon className="align-middle text-muted me-1 icon-sm"/>
                                            <span className="align-middle">{option.name}</span>
                                        </DropdownItem>
                                    )
                                }

                            } else {
                                return <div className="dropdown-divider"></div>
                            }
                        }
    
                            
                        )
                    }
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};
import { ErrorMaintenance, GetMaintenanceVehicleInfoEndAction, GetMaintenanceVehicleInfoStartActionType, GET_MAINTENANCE_VEHICLE_INFO_START, MaintenanceActionType, SAVE_NEW_MAINTENANCE_START, SaveNewMaintenanceStartType, SaveNewMaintenanceEnd, GetFiltersMaintenanceStartType, GET_FILTERS_MAINTENANCE_START, GetFiltersMaintenanceEnd, GetMaintenanceListStartType, GET_MAINTENANCE_LIST_START, GetMaintenanceListEnd, DeleteMaintenancesStartType, DELETE_MAINTENANCES_START, DeleteMaintenanceEnd, GetFiltersMaintenanceStart, GetMaintenanceStartType, GET_MAINTENANCE_START, GetMaintenanceEnd } from './maintenance.actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootState } from '../../../redux/store';
import { deleteMaintenances, getMaintenance, getMaintenanceGroups, getMaintenanceList, getMaintenanceVehicle, saveMaintenance } from './maintenance.service';
import moment from 'moment';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';

export const GetMaintenanceVehicleInfoEpic = (
    action$: ActionsObservable<GetMaintenanceVehicleInfoStartActionType>,
    state$: { value: RootState }
): Observable<MaintenanceActionType> =>
    action$.pipe(
        ofType(GET_MAINTENANCE_VEHICLE_INFO_START),
        switchMap((action: GetMaintenanceVehicleInfoStartActionType) => {
            return from(getMaintenanceVehicle(action.payload)).pipe(
                map((result) => {
                    return GetMaintenanceVehicleInfoEndAction(result);
                }),
                catchError((err) => {
                    return of(ErrorMaintenance(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorMaintenance(err.msg));
        })
    );

export const SaveMaintenanceEpic = (
    action$: ActionsObservable<SaveNewMaintenanceStartType>,
    state$: { value: RootState }
): Observable<MaintenanceActionType | any> =>
    action$.pipe(
        ofType(SAVE_NEW_MAINTENANCE_START),
        switchMap((action: SaveNewMaintenanceStartType) => {
            const {manage} = state$.value.MaintenanceReducer;
            const {km, date, engineTime, alert, ...other} = manage;
            const payload: any = {...other};
            if(km.active) {
                payload.km= {
                    active: km.active,
                    intervalKm: +action.payload.intervalKm,
                    lastServiceMielage: +action.payload.lastServiceMielage,
                    actualMielage: +action.payload.actualMielage,
                    expiration: +action.payload.intervalKm + (+action.payload.lastServiceMielage)
                }
            };

            if(engineTime.active) {
                payload.engineTime= {
                    active: engineTime.active,
                    intervalHours: +action.payload.intervalHours || 0,
                    lastServiceHours : +action.payload.lastServiceHours || 0,
                    actualHours: +action.payload.actualHours || 0,
                    expiration: (+action.payload.lastServiceHours  + (+action.payload.intervalHours)) * 60 * 60 * 1000,
                }
            };

            if(date.active) {
                payload.date= {
                    active: date.active,
                    intervalDay: +action.payload.intervalDay || 0,
                    startTime: date.startTime,
                    expiration: moment(date.startTime).add(+action.payload.intervalDay, 'day').toDate()
                }
            };
            
            if(alert && alert?.emails.length > 0) {
                payload.alert = {
                    emails: alert.emails.map(email => email.value),
                    alertKm: +action.payload.alertKm || 0,
                    alertDay: +action.payload.alertDay || 0,
                    alertEngineTime: +action.payload.alertEngineTime || 0,
                }
            }
            
            return from(saveMaintenance(payload)).pipe(
                mergeMap((result) => {
                    return of(SaveNewMaintenanceEnd(),
                    SetRedirectAction(`/maintenance`),
                    SetNotificationAction({
                        title: "success",
                        "message": 'Action',
                        timeToClose: 3000,
                        type: 'success'
                    }));
                }),
                catchError((err) => {
                    return of(ErrorMaintenance(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorMaintenance(err.msg));
        })
    );


export const GetMaintenanceGroupsEpic = (
    action$: ActionsObservable<GetFiltersMaintenanceStartType>,
    state$: { value: RootState }
): Observable<MaintenanceActionType> =>
    action$.pipe(
        ofType(GET_FILTERS_MAINTENANCE_START),
        switchMap((action: GetFiltersMaintenanceStartType) => {
            return from(getMaintenanceGroups()).pipe(
                map((result) => {
                    return GetFiltersMaintenanceEnd(result);
                }),
                catchError((err) => {
                    return of(ErrorMaintenance(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorMaintenance(err.msg));
        })
    );

export const GetMaintenanceListEpic = (
    action$: ActionsObservable<GetMaintenanceListStartType>,
    state$: { value: RootState }
): Observable<MaintenanceActionType> =>
    action$.pipe(
        ofType(GET_MAINTENANCE_LIST_START),
        switchMap((action: GetMaintenanceListStartType) => {
            return from(getMaintenanceList(action.payload)).pipe(
                map((result) => {
                    return GetMaintenanceListEnd({result, filter: action.payload});
                }),
                catchError((err) => {
                    return of(ErrorMaintenance(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorMaintenance(err.msg));
        })
    );

    export const DeleteMaintenanceEpic = (
        action$: ActionsObservable<DeleteMaintenancesStartType>,
        state$: { value: RootState }
    ): Observable<MaintenanceActionType | any> =>
        action$.pipe(
            ofType(DELETE_MAINTENANCES_START),
            switchMap((action: DeleteMaintenancesStartType) => {
                return from(deleteMaintenances(action.payload)).pipe(
                    mergeMap((result) => {
                        return of(
                            DeleteMaintenanceEnd({
                                ...result.data,
                                _ids: action.payload._ids,
                            }),
                            GetFiltersMaintenanceStart(),
                            SetNotificationAction({
                                title: "success",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }),
                    catchError((err) => {
                        return of(ErrorMaintenance(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorMaintenance(err.msg));
            })
        );
    
export const GetMaintenanceEpic = (
    action$: ActionsObservable<GetMaintenanceStartType>,
    state$: { value: RootState }
): Observable<MaintenanceActionType | any> =>
    action$.pipe(
        ofType(GET_MAINTENANCE_START),
        switchMap((action: GetMaintenanceStartType) => {
            return from(getMaintenance(action.payload)).pipe(
                map((result) => {
                    return GetMaintenanceEnd({result, filter: action.payload})
                }),
                catchError((err) => {
                    return of(ErrorMaintenance(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorMaintenance(err.msg));
        })
    );
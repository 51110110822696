import { FormattedMessage, useIntl } from "react-intl";
import { ButtonUi } from "../../basic/ButtonUi";
import { InputSearch } from "../../Form/InputSearch";

export type ListMapSearchComponentProps = {
    onClickCreate: () => void;
    onSearch: (data: any) => void;
}
export const ListMapSearchComponent = (props: ListMapSearchComponentProps) => {
    const intl = useIntl();

    return (
        <div className="py-2 px-3 border-bottom">
            <div className="d-flex flex-row">
                <ButtonUi 
                    size="sm" 
                    color={"info"}
                    onClick={props.onClickCreate}
                >
                    <FormattedMessage id="COMMON.NEW"/>
                </ButtonUi>
                <div className="ms-1 flex-grow-1">
                    <InputSearch
                        type="text"
                        name={'searchBy'}
                        size="sm"
                        placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                        onSubmit={props.onSearch}
                    />
                </div>
            </div>
        </div>             
    );
}
import { Modal, ModalBody } from "reactstrap";
import { GroupManagement } from "../../../Components/GroupManagement";
import ModalHeader from "../../../Components/Modal/modal-header";
import { RiGroupLine } from "react-icons/ri";
import { PassengerGroupForm } from "./passenger-group-form";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { useDeleteGroupPassenger } from "../../../common/hooks/axios/passenger/group/useDeleteGroupPassenger";
import Emitter from "../../../helpers/emitter";
import EmitterActions from "../../../helpers/emitter/emitter.actions";
import { SetNotificationAction } from "../../../redux/rootActions";
import { useDispatch } from "react-redux";

type PassengerGroupProps = {
    onSelect: (key: string | null) => void
}
const PassengerGroup = (props: PassengerGroupProps) => {
    const passengerGroup = useMatch('/passenger-groups');
    const passengerGroupEdit = useMatch('/passenger-groups/:key');
    const [queryParameters] = useSearchParams();
    const isPassengerGroup = !!passengerGroup || !!passengerGroupEdit;
    const isCreation = queryParameters.get("action") === "add";
    const isUpdate = queryParameters.get("action") === "update";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { deleteGroupAsyncPassenger} = useDeleteGroupPassenger({
        manual: true,
        type: "passenger"
    });

    const onAction = async (action: string, _ids: string[]) => {
        switch(action) {
            case 'new': {
                navigate('/passenger-groups?action=add')
                break;
            }
            case 'edit': {
                navigate(`/passenger-groups/${_ids[0]}?action=update`)
                break;
            }
            case 'delete': {
                const deleteGroup = await deleteGroupAsyncPassenger(_ids[0]);
                if(deleteGroup.success?.deletedCount > 0) {
                    dispatch(SetNotificationAction({
                        title: "succès",
                        "message": "Groupe supprimé",
                        timeToClose: 3000,
                        type: 'success'
                    }));
                    Emitter.emit(EmitterActions["GROUP:GO_TO"], { key: null, name: 'Tous les passagers', totalItems: 0 })
                }
                break;
            }
        }    
    }
    
    return (
        <GroupManagement minusHeight={195} onClickAction={onAction} type="passenger" onSelect={props.onSelect}>
            <Modal isOpen={isPassengerGroup && (isCreation || isUpdate)} size={"md"} fullscreen={"md"}>
                <ModalBody>
                    <ModalHeader 
                        Icon={RiGroupLine} 
                        title={isUpdate ? "Modifier le groupe de passagers" : "Créer un groupe de passagers"} 
                        description={"Veuillez renseigner le nom du groupe"} 
                    />
                    <div className="mt-5">
                        <PassengerGroupForm name="" />
                    </div>
                </ModalBody>
            </Modal>
        </GroupManagement>
    );    
}

export default PassengerGroup;
import { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { EmptyUi } from "../../../Components/basic/EmptyUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { EventPie } from "../chart/event-pie";
import { EventsStatsResponse, getEventsStats } from "../dashboard.service";

export const EventStatus = () => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<EventsStatsResponse[]>([]);

    useEffect(()=> {
        setLoading(true);
        getEventsStats()
            .then((response)=> {
                setData(response);
            })
            .catch((response)=> {
                setData(response);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);

    return (
        <CardUi className="flex-grow-1">
            <LoaderUi loading={loading} />
            <CardBody>
                <div className="position-relative d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Évenements du parc (24 heures)</h6>
                </div>
                {
                    data.length > 0 ? 
                        <div className="mt-4 d-flex flex-row justify-content-center align-items-center">
                            <EventPie data={data}/>
                        </div> 
                    : 
                        <EmptyUi />
                }
                
                    
                    
                
            </CardBody>
        </CardUi>               
    );
}
import { UseFormRegister } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { Acl } from "../../../../Components/Acl";
import { CardUi } from "../../../../Components/basic/Card";
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect";
import { InputText } from "../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../redux/hooks";
import { getTracersForVehicle } from "../../../Tracers/redux/tracer.service";
import { SetGroupVehicleAction, SetTracerVehicleAction } from "../../redux/vehicleModule.actions";
import { getGroupsForVehicle } from "../../redux/vehicleModule.service";

type ManageUsersPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any},
}

export const General = ({register ,errors}: ManageUsersPageType) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    
    const isUpdate = _id !== undefined;

    const { manage } = useAppSelector((state) => state.vehicleModuleReducer);

           
    const handleChangeTracer = (newValue: any, actionMeta: any) => {
        dispatch(SetTracerVehicleAction(newValue));
    };

    const fetchTracers = async (inputValue: string): Promise<any> => {
        return getTracersForVehicle({ name: inputValue, _id });
    };

    const fetchGroups = async (inputValue: string): Promise<any> => {
        return getGroupsForVehicle({ name: inputValue });
    };

    const handleChangeGroup = (newValue: any, actionMeta: any) => {
        dispatch(SetGroupVehicleAction(newValue.length > 0 ? newValue : undefined));
    };

    


    return (
        <CardUi noShadow={isUpdate}>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xl={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                label={intl.formatMessage({id: 'UNIT.NAME'})}
                                id={'name'}
                                name={'name'}
                                placeholder={intl.formatMessage({id: 'UNIT.PLACEHOLDER.NAME'})}
                                register={register('name', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                error={errors['name']}
                            />
                        </FormGroup>
                    </Col>
                    <Acl type="admin">
                        <Col xl={6}>
                            <FormGroup>
                                {
                                (manage._id || !isUpdate ) && <AsyncInputSelect 
                                        isClearable
                                        label={intl.formatMessage({ id: 'UNIT.TRACER'})}
                                        cacheOptions
                                        defaultOptions
                                        defaultValue={manage.tracer}
                                        loadOptions={fetchTracers}
                                        onChange={handleChangeTracer}
                                    />
                                }
                            </FormGroup>
                        </Col> 
                    </Acl>
                    
                    <Col xl={6}>
                        <FormGroup>
                            {
                                (manage._id || !isUpdate ) && <AsyncInputSelect 
                                    isMulti
                                    label={intl.formatMessage({ id: 'UNIT.GROUPE'})}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={manage.groups}
                                    loadOptions={fetchGroups}
                                    onChange={handleChangeGroup}
                                />
                            }
                        
                        </FormGroup>
                    </Col> 
                </Row>
                
                
            </CardBody>
        </CardUi>               
    );
}

import moment from "moment-timezone";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DriverMark } from "../../../../Components/advanced/Driver/Driver-eco-mark";
import Emitter from "../../../../helpers/emitter";
import EmitterActions from "../../../../helpers/emitter/emitter.actions";
import { useAxios } from "../../../../common/hooks/axios/useAxios";

export const DriverEcoRate = () => {
    const { key } = useParams();
        const navigate = useNavigate();

        
    const {data, loading, execute} = useAxios<{score: number | null}>(
        false, 
        `driver/${key}/eco-mark`, 
        { method: 'GET', params: {
            start: moment().startOf('month').toDate().getTime(), 
            end: moment().endOf('month').toDate().getTime()
        }}
    );
        
    useEffect( () => {
        Emitter.on(EmitterActions.REFRESH_DRIVER_ECO, ()=> {
            execute(`driver/${key}/eco-mark`, { method: 'GET', params: {
                start: moment().startOf('month').toDate().getTime(), 
                end: moment().endOf('month').toDate().getTime()
            }})
        });
        return () => {
            Emitter.off(EmitterActions.REFRESH_DRIVER_ECO, ()=> {
                execute(`driver/${key}/eco-mark`, { method: 'GET', params: {
                    start: moment().startOf('month').toDate().getTime(), 
                    end: moment().endOf('month').toDate().getTime()
                }})
            });
        };
    }, []);
    
    return (
        <DriverMark
            className="py-3 px-3 border-bottom"
            text={"Note du comportement de conduite"} 
            description={`(Mois en cours)`} 
            mark={data?.score} 
            showMore={()=> navigate(`/eco/${key}`)}
            loading={loading}
            colorChange
        />               
    );
}
import { FieldValues, UseFormRegister } from "react-hook-form";
import { useIntl } from "react-intl";
import { FormGroup } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { InputText } from "../../../Components/Form/InputText";


export enum Incident {
    "PENALTY" = "penalty",
    "ACCIDENT" = "accident",
    "OTHER" = "other"
}

type IncidentTypeComponentProps = {
    register: UseFormRegister<FieldValues>;
    errors: Record<string, string>;
    active: Incident;
    onChange: (incident: Incident) => void
}
const incidents = [Incident.PENALTY, Incident.ACCIDENT, Incident.OTHER]
export function IncidentTypeComponent(props: IncidentTypeComponentProps) {

    // hooks
    const intl = useIntl();

    return ( 
        <>
            <FormGroup className="d-flex flex-column">
                <label className="form-label">Quel est le type de l'incident ?</label>
                <div>
                    <ButtonUi       
                        color="primary" 
                        size="sm" 
                        className="me-2"
                        outline={Incident.PENALTY !== props.active}
                        onClick={()=> props.onChange(Incident.PENALTY)}
                    >
                        Amende
                    </ButtonUi>
                    <ButtonUi       
                        color="primary" 
                        size="sm" 
                        className="me-2"
                        outline={Incident.ACCIDENT !== props.active}
                        onClick={()=> props.onChange(Incident.ACCIDENT)}
                    >
                        Accident
                    </ButtonUi>
                <ButtonUi       
                    color="primary" 
                    size="sm" 
                    className="me-2"
                    outline={Incident.OTHER !== props.active}
                    onClick={()=> props.onChange(Incident.OTHER)}
                >
                    Autres
                </ButtonUi>
                </div>
            </FormGroup>
            {
                props.active === Incident.OTHER && <FormGroup>
                <InputText
                    type="text"
                    label={"C'est quoi le motif de l'incident ?"}
                    id={'motif'}
                    name={'motif'}
                    placeholder={"Veuillez renseigner le motif de l'incident"}
                    register={props.register('motif', {
                        required: {
                            value: true, 
                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                        }})}
                    error={props.errors['motif']}
                />
            </FormGroup>
            }
             
        </>
    );
}
import { UnitList } from "./Objects/unit-list";
import { Object } from "./Object";
import { useAppSelector } from "../../redux/hooks";


const MapPage = () => {
    const { vehicle, sensors } = useAppSelector((state) => {
        return {
            vehicle: state.ObjectReducer.vehicle,
            sensors: state.ObjectReducer.sensors,
        };
    });
    
    return (
        <div className="page-content object-page">
            <div className="d-flex flex-row">
                <UnitList/>
                { !!vehicle && <Object object={vehicle} sensors={sensors}/>  }
                
            </div>       
        </div>
    );
}

export default MapPage;
import { AiOutlineClose } from "react-icons/ai";
import { Container, CardHeader,Row, Col, TabContent, TabPane, UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem } from "reactstrap";
import { TableUi } from "../../../Components/Table";
import { BreadCrumbUi } from "../../../Components/basic/BreadCrumbUi";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { Tabs } from "../../../Components/Tabs";


const columns = [
    {
        title: "Début",
        dataIndex: 'start',
        key: 'start',
    },
    {
        title: "Position",
        dataIndex: 'startPos',
        key: 'startPos',
    },
    {
        title: "Fin",
        dataIndex: 'end',
        key: 'end',
    },
    {
        title: "Position",
        dataIndex: 'endPos',
        key: 'endPos',
    },
    {
        title: "Durée",
        dataIndex: 'duration',
        key: 'duration',
    },
    {
        title: "Distance",
        dataIndex: 'distance',
        key: 'distance',
    },
    {
        title: "Vitesse max",
        dataIndex: 'maxSpeed',
        key: 'maxSpeed',
    },
    {
        title: "Vitesse moyenne",
        dataIndex: 'avgSpeed',
        key: 'avgSpeed',
    },
    {
        title: "Consomation",
        dataIndex: 'consomation',
        key: 'consomation',
    },
];



export const ViewReportPage = () => {
    return (
        <div id="reportPage" className="page-content">
            
            <Container fluid>
            <BreadCrumbUi title={"Rapport des trajets"} pageTitle={'14/11/2023 - 24/11/2023'} />
                <Row>
                    <Col xl={12}>
                        <CardUi>
                            <CardHeader >
                                <Tabs isCustom color="primary" className="card-header-tabs border-bottom-0">
                                    <div className="d-flex flex-row align-items-center">
                                        <span>Toyota</span>
                                        <AiOutlineClose className="ms-2"/>
                                    </div>
                                    <></>
                                </Tabs>
                            </CardHeader>
                            <CardBodyUi>  
                                <TabContent activeTab={`${0}`}>
                                    <UncontrolledAccordion open="1">
                                        <AccordionItem>
                                            <AccordionHeader targetId="1">
                                                Trajets
                                            </AccordionHeader>
                                            <AccordionBody accordionId="1">
                                                <TabPane tabId="0" id="account-general">
                                                    <TableUi 
                                                        columns={columns}
                                                        data={[]}
                                                    />
                                                </TabPane>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>   
                                </TabContent>
                            </CardBodyUi> 
                        </CardUi>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
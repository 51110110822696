const Title = ({ text }: { text: string }) => {
  return (
    <h5 className="title-group fs-16">
      <div className="bg-primary me-2" style={{height: 10, width: 10, borderRadius: "100%"}}></div>
      {text}:
    </h5>
  );
};

export default Title;

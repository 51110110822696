import classNames from "classnames";
import { IconType } from "react-icons";

type ChoiceIconComponentProps = {
    className?: string,
    option: string;
    Icon: IconType;
    name: string;
    onClick: (option: string) => void;
}

export const ChoiceIconComponent = (props: ChoiceIconComponentProps) => {
   
    const choiceIconClassName = classNames(
        "choice-icon-component rounded-3 d-flex justify-content-center align-items-center flex-column border px-5 py-3 me-3  cursor-pointer border-1",
        {[props.className ?? '']: !!props.className}
    )

    const chooseChoice = () => {
        props.onClick(props.option);
    }

    return (
        <div className={choiceIconClassName} style={{flex: 1}} onClick={chooseChoice}>
            <props.Icon size={42} className="text-info"/> 
            <h6 className="mt-3">{ props.name }</h6>
        </div>          
    );     
}
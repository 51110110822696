import { FilterGeofenceType, GeofenceType, GetGeofencesResponse, ManageGeofence, PointType } from "../types";

export const SET_GEOFENCE_TYPE = '[GEOFENCE] SET_GEOFENCE_TYPE';
export type SetGeofenceTypeType = {
    type: typeof SET_GEOFENCE_TYPE;
    payload: {type: GeofenceType};
};
export function SetGeofenceType(payload: {type: GeofenceType}): SetGeofenceTypeType {
    return { type: SET_GEOFENCE_TYPE, payload };
}

export const SET_GEOFENCE_COLOR = '[GEOFENCE] SET_GEOFENCE_COLOR';
export type SetGeofenceColorType = {
    type: typeof SET_GEOFENCE_COLOR;
    payload: {color: string};
};
export function SetGeofenceColor(payload: {color: string}): SetGeofenceColorType {
    return { type: SET_GEOFENCE_COLOR, payload };
}

export const SET_GEOFENCE_DRAW = '[GEOFENCE] SET_GEOFENCE_DRAW';
export type SetGeofenceDrawType = {
    type: typeof SET_GEOFENCE_DRAW;
    payload: {geofence: any};
};
export function SetGeofence(payload: {geofence: any}): SetGeofenceDrawType {
    return { type: SET_GEOFENCE_DRAW, payload };
}

export const SET_GEOFENCE_RADIUS = '[GEOFENCE] SET_GEOFENCE_RADIUS';
export type SetGeofenceRadiusType = {
    type: typeof SET_GEOFENCE_RADIUS;
    payload: {radius: number};
};
export function SetGeofenceRadius(payload: {radius: number}): SetGeofenceRadiusType {
    return { type: SET_GEOFENCE_RADIUS, payload };
}

export const CLEAR_GEOFENCE_DRAW = '[GEOFENCE] CLEAR_GEOFENCE_DRAW';
export type ClearGeofenceDrawType = {
    type: typeof CLEAR_GEOFENCE_DRAW;
};
export function ClearGeofenceDraw(): ClearGeofenceDrawType {
    return { type: CLEAR_GEOFENCE_DRAW };
}

export const SET_GEOFENCE_POINT = '[GEOFENCE] SET_GEOFENCE_POINT';
export type SetGeofencePointType = {
    type: typeof SET_GEOFENCE_POINT;
    payload: { point: PointType };
};
export function SetGeofencePoint(payload: { point: PointType }): SetGeofencePointType {
    return { type: SET_GEOFENCE_POINT, payload};
}

export const GET_GEOFENCES_START = '[GEOFENCE] GET_GEOFENCES_START';
export type GetGeofencesStartType = {
    type: typeof GET_GEOFENCES_START;
    payload: FilterGeofenceType;
};
export function GetGeofencesStart(
    payload: FilterGeofenceType
): GetGeofencesStartType {
    return { type: GET_GEOFENCES_START, payload };
}

export const GET_GEOFENCES_END = '[GEOFENCE] GET_GEOFENCES_END';
export type GetGeofencesEndType = {
    type: typeof GET_GEOFENCES_END;
    payload: { result: GetGeofencesResponse; filter: FilterGeofenceType };
};
export function GetGeofencesEnd(payload: {
    result: GetGeofencesResponse;
    filter: FilterGeofenceType;
}): GetGeofencesEndType {
    return { type: GET_GEOFENCES_END, payload };
}

export const CREATE_GEOFENCE_START = '[GEOFENCE] CREATE_GEOFENCE_START';
export type CreateGeofenceStartType = {
    type: typeof CREATE_GEOFENCE_START;
    payload: ManageGeofence;
};
export function CreateGeofenceStart(
    payload: ManageGeofence
): CreateGeofenceStartType {
    return { type: CREATE_GEOFENCE_START, payload };
}

export const CREATE_GEOFENCE_END = '[GEOFENCE] CREATE_GEOFENCE_END';
export type CreateGeofenceEndType = {
    type: typeof CREATE_GEOFENCE_END;
    payload: ManageGeofence & {modifiedCount: number};
};
export function CreateGeofenceEnd(
    payload: ManageGeofence & {modifiedCount: number}
): CreateGeofenceEndType {
    return { type: CREATE_GEOFENCE_END, payload };
}

export const DELETE_GEOFENCE_START = '[GEOFENCE] DELETE_GEOFENCE_START';
export type DeleteGeofenceStartType = {
    type: typeof DELETE_GEOFENCE_START;
    payload: string[];
};
export function DeleteGeofenceStart(payload: string[]): DeleteGeofenceStartType {
    return { type: DELETE_GEOFENCE_START, payload };
}

export const DELETE_GEOFENCE_END = '[GEOFENCE] DELETE_GEOFENCE_END';
export type DeleteGeofenceEndType = {
    type: typeof DELETE_GEOFENCE_END;
    payload: { deletedCount: number; keys: string[] };
};

export function DeleteGeofenceEnd(payload: { deletedCount: number, keys: string[] }): DeleteGeofenceEndType {
    return { type: DELETE_GEOFENCE_END, payload};
}

export const GET_GEOFENCE_UPDATE_START = '[GEOFENCE] GET_GEOFENCE_UPDATE_START';
export type GetGeofenceUpdateStartType = {
    type: typeof GET_GEOFENCE_UPDATE_START;
    payload: { _id: string };
};
export function GetGeofenceUpdateStart(payload: {
    _id: string;
}): GetGeofenceUpdateStartType {
    return { type: GET_GEOFENCE_UPDATE_START, payload };
}

export const GET_GEOFENCE_UPDATE_END = '[GEOFENCE] GET_GEOFENCE_UPDATE_END';
export type GetGeofenceUpdateEndType = {
    type: typeof GET_GEOFENCE_UPDATE_END;
    payload: { result: ManageGeofence& {geofence: any} };
};
export function GetGeofenceUpdateEnd(payload: {
    result: ManageGeofence& {geofence: any};
}): GetGeofenceUpdateEndType {
    return { type: GET_GEOFENCE_UPDATE_END, payload };
}

export const ERROR = '[GEOFENCE] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorGeofence(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export type GeofenceActionsType =
    | SetGeofenceTypeType
    | SetGeofenceColorType
    | SetGeofenceDrawType
    | SetGeofenceRadiusType
    | SetGeofencePointType
    | GetGeofencesStartType
    | GetGeofencesEndType
    | CreateGeofenceStartType
    | CreateGeofenceEndType
    | DeleteGeofenceStartType
    | DeleteGeofenceEndType
    | GetGeofenceUpdateStartType
    | GetGeofenceUpdateEndType
    | ErrorActionType
    | ClearGeofenceDrawType;
import { useEffect } from "react";
import { REVERSE_ADDRESS } from "../env";
import { useAxios } from "../common/hooks/axios/useAxios";

export const Address = ({coordinates}: any) => {
    let lng = 0;
    let lat = 0;
    if(Array.isArray(coordinates)) {
        lng = coordinates[0]
        lat = coordinates[1]
    } else {
        lng = coordinates.coordinates[0];
        lat = coordinates.coordinates[1];
    }
    const {data, execute} = useAxios<{display_name: string}>(
        true, 
        REVERSE_ADDRESS, 
        { method: 'GET', params: {format: 'jsonv2', lat, lon: lng, zoom: 18, addressdetails: 0, extratags: 0}}
    );

    useEffect(()=> {
        execute(REVERSE_ADDRESS, { method: 'GET', params: {format: 'jsonv2', lat, lon: lng, zoom: 16, addressdetails: 0, extratags: 0}})
    }, [lng, lat])



    return(    
        <>
            { data?.['display_name'] ?? "..." }
        </>
    );
}
import { useAxios } from "../useAxios";

export type OptimizedRoad = {
  "value": string, "label": string,
}

type OptimizedRoadResult = any

type UseOptimizedRoadResponse = { 
  optimizedRoad: any;
  optimizedRoadLoading: boolean;
  error?: string;
  initOptimizedRoad: () => void;
  fetchOptimizedRoad: () => void;
  cancel: () => void;
}

type FetchOptimizedRoadProps = any

type UseOptimizedRoadProps = {
  manual?: boolean
  key?: string;
}

export function useFetchOptimizedRoad(props: UseOptimizedRoadProps): UseOptimizedRoadResponse {
  const URL = `road/${props.key}/optimization`;
  const { execute, data, cancel, error, loading, init } = useAxios<OptimizedRoadResult>(props.manual);

  const fetchOptimizedRoad = () => {
    execute(URL, { method: "GET" });
  };

  return { 
    initOptimizedRoad: init,
    optimizedRoad: data, 
    fetchOptimizedRoad, 
    cancel, 
    optimizedRoadLoading: loading, 
    error: error
  };
}

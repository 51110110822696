import { useEffect } from "react";
import { useAxios } from "../useAxios";
import { Stop } from "./type";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
}

type UseFetchStopResponse = { 
  stop: Stop | null;
  fetchStopLoading: boolean;
  error?: string;
  fetchStop: () => void;
  cancel: () => void;
}



type UseFetchReportItemsProps = {
  manual?: boolean,
  key?: string
}

export function useFetchStop(props: UseFetchReportItemsProps): UseFetchStopResponse {
  const URL = `stops/${props.key}`;
  const { execute, data, cancel, error, loading, init } = useAxios<Stop>(props.manual);

  useEffect(() => {
    if(!props.manual && props.key) {
      fetchStop();
    }
  }, []);

  const fetchStop = () => {
    execute(URL, { method: "GET"});
  };

  return { 
    stop: data,
    fetchStopLoading: loading, 
    error,
    fetchStop,
    cancel, 
  };
}

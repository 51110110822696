import { useAxios } from "../../../../common/hooks/axios/useAxios";

type UseCountUnreadNotificationsResponse = { 
  totalUnreadNotifications: number;
  countUnreadNotificationsLoading: boolean;
  error?: string;
  countUnreadNotifications: () => void;
  cancel: () => void;
}

type UseCountUnreadNotificationsProps = {
  manual?: boolean,
}

export function useCountUnreadNotifications(props: UseCountUnreadNotificationsProps): UseCountUnreadNotificationsResponse {
  const URL = `notifications/unread-notification-count`;
  const { execute, data, cancel, error, loading, init } = useAxios<{total: number}>(props.manual);

  const countUnreadNotifications = () => {
    execute(URL, { method: "GET" });
  };

  return { 
    totalUnreadNotifications: data?.total ?? 0,
    countUnreadNotificationsLoading: loading, 
    error,
    countUnreadNotifications,
    cancel, 
  };
}

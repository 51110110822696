import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Form, Row, Col} from "reactstrap";
import { BreadCrumbActionUi } from "../../../Components/basic/BreadCrumbActionUi";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../redux/hooks";
import { General } from "./General";
import { Notification } from "./Notification";
import { Interval } from "./Interval";
import { Conditions } from "./Conditions";
import { ClearEventCreation, CreateEvent, GetEventUpdateStart } from "../redux/events.actions";


const ManageAlarmPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {_id} = useParams();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const { user } = useAppSelector((state) => state.authReducer);
    const { loading, manage } = useAppSelector((state) => state.eventsReducer);

    const validEventConditions = (event: any)=> {
        const isBoolean = manage.events.length > 0 && event.subType?.type === "boolean";
        const isNumberOrCustom = manage.events.length > 0 &&  (event.subType?.type === "number" || event.subType?.type === "Custom");
        const isSpeed = manage.events.length > 0 &&  (event.subType?.type === "road" ||event.subType?.type === "custom");
        const isZone = manage.events.length > 0 && event.type?.value === "Zone";
        const isFuel = manage.events.length > 0 && event.type?.value === "Fuel";
    
        return !!event.subType && 
        (
            ((isBoolean || isSpeed) && (!!event.value) 
            || 
            (isNumberOrCustom && (!!event.value) && (!!event.operation)))
            || 
            isFuel
            || 
            (isZone &&  event.zones !== undefined))
    }

    
    const onSubmit = async (payload: any) => {
        const notValidEvents = manage.events.filter(ev => !validEventConditions(ev))
        if(notValidEvents.length === 0) {
            dispatch(CreateEvent(payload))
        }
        
        // const newData = { ...data };
        // const {_id} = params;
        // setFetchLoading(true);
        // if (_id !== 'add') {
        //     newData.username = username;
        // } else {
        //     const exist = await fetchAccountByUserName(newData.username);

        //     if (exist && exist.error) {
        //         setFetchLoading(false);
        //         setError(exist.for, { message: exist.msg, type: 'isUnique' });
        //         return;
        //     }
        // }
        // dispatch(CreateUserStart(data));
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/alarms' });
    }

    useEffect(() => {
        if(_id && _id !== 'add') {
            dispatch(GetEventUpdateStart({ _id }))
        }
        return function cleanup() {
            dispatch(ClearEventCreation());
        };
    }, []);

    useEffect(() => {
        setValue('name', manage.name);
        setValue('description', manage.description);
        setValue('interval', manage.interval ?? 2);
    }, [manage.name, manage.description, manage.interval]);


    return (
        <div id="ManageAlarmPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'ALARM' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
                    <Row>
                        <LoaderUi loading={loading || fetchLoading}/>
                        <Col xl={4}>
                            <General register={register} errors={errors} />
                            <Notification />
                        </Col>
                        <Col xl={8}>
                            <Conditions />
                            <Interval />
                        </Col>
                        <Col>
                                <div className="hstack gap-2 justify-content-end">
                                    <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                        <FormattedMessage id="COMMON.CANCEL"/>
                                    </ButtonUi>
                                    <ButtonUi color={"primary"} type="submit">
                                        {
                                            _id === 'add' ? <FormattedMessage id="COMMON.ADD"/>  : <FormattedMessage id="COMMON.UPDATE"/>
                                        }
                                    </ButtonUi> 
                                </div>
                            </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}
export default ManageAlarmPage;
import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

type BreadCrumbUiType = {
    title: string,
    pageTitle: string
}
export const BreadCrumbUi = ({ title, pageTitle }: BreadCrumbUiType) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{title}</h4>

                        <div className="page-title-right">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to="#">{pageTitle}</Link>
                                </BreadcrumbItem>
                                {/* <BreadcrumbItem>{title}</BreadcrumbItem> */}
                            </Breadcrumb>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

import { GiMovementSensor } from 'react-icons/gi';
import { ImStop } from 'react-icons/im';

export type MovementProps = { moving: boolean; size?: number };

export default function MovementObjectIcon({moving, size=11}: MovementProps): JSX.Element {
    return moving ? <GiMovementSensor style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }} />
    :<ImStop style={{ fontSize: size, color: 'red' }} />;
}

import moment from "moment";
import { GroupsMaintenance, MaintenanceInList, ManageMaintenanceType } from "../types";
import { DELETE_MAINTENANCES_END, DELETE_MAINTENANCES_START, GET_FILTERS_MAINTENANCE_END, GET_FILTERS_MAINTENANCE_START, GET_MAINTENANCE_END, GET_MAINTENANCE_LIST_END, GET_MAINTENANCE_LIST_START, GET_MAINTENANCE_START, GET_MAINTENANCE_VEHICLE_INFO_END, GET_MAINTENANCE_VEHICLE_INFO_START, MaintenanceActionType, SAVE_NEW_MAINTENANCE_END, SAVE_NEW_MAINTENANCE_START, SET_MAINTENANCE_NOTIFICATION_EMAIL, SET_MAINTENANCE_NOTIFICATION_TYPE, SET_MAINTENANCE_TIME_DAY, SET_MAINTENANCE_TYPE, SET_MAINTENANCE_VEHICLE, SET_SELECTED_MAINTENANCE, TOOGLE_MAINTENANCE_CONFIG } from "./maintenance.actions";

type StateType = {
    loading: boolean;
    manage: ManageMaintenanceType;
    groups: GroupsMaintenance[],
    maintenance: {
        list: MaintenanceInList[];
        total: number;
    };
    selectedMaintenances: string[];
    activeGroup?: string;
    activeMaintenance?: string;
    filter: {
        limit: number;
        skip: number;
    };

};

const INITIAL_STATE: StateType = {
    loading: false,
    groups: [],
    maintenance: {
        list: [],
        total: 0
    },
    selectedMaintenances: [],
    manage: {
        name: null,
        unit: null,
        km: {
            active: false,
            intervalKm: 0,
            lastServiceMielage: 0,
            actualMielage: 0,
            expiration: 0
        },
        date: {
            active: false,
            intervalDay: 0,
            startTime: null,
            expiration: null,
        },
        engineTime: {
            active: false,
            intervalHours: 0,
            lastServiceHours: 0,
            actualHours: 0,
            expiration: 0,
        },
        alert: {
            emails: []
        }
    },
    filter: {
        limit: 20,
        skip: 0
    }

};

export function MaintenanceReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: MaintenanceActionType
): StateType {
    switch (action.type) {
        case SAVE_NEW_MAINTENANCE_START:
        case GET_MAINTENANCE_VEHICLE_INFO_START:
        case GET_FILTERS_MAINTENANCE_START:
        case GET_MAINTENANCE_LIST_START:
        case GET_MAINTENANCE_START:    
        case DELETE_MAINTENANCES_START:
            return {
                ...state,
                loading: true
            }
        case SET_MAINTENANCE_TYPE:
            return { 
                ...state, 
                manage: {
                    ...state.manage,
                    name: action.payload
                }
            };

        case SET_MAINTENANCE_VEHICLE: {
            return { 
                ...state, 
                manage: {
                    ...state.manage,
                    unit: action.payload
                }
            };
        }

        case TOOGLE_MAINTENANCE_CONFIG: {
            let config = state.manage[action.payload];
            return { 
                ...state,
                manage: {
                    ...state.manage,
                    [action.payload]:  {
                        ...config,
                       active: !state.manage[action.payload].active
                    }
                } 
            }   
        }

        case SET_MAINTENANCE_NOTIFICATION_TYPE: {
            return { ...state, loading: true };
        }

        case GET_MAINTENANCE_VEHICLE_INFO_END: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    km: {
                        ...state.manage.km,
                        // lastServiceMielage: action.payload.km,
                        actualMielage: action.payload.km
                    },
                    engineTime: {
                        ...state.manage.engineTime,
                        lastServiceHours: moment.duration(action.payload.engineTime).asHours(),
                        actualHours: moment.duration(action.payload.engineTime).asHours()
                    }
                }
            }
        }

        case SET_MAINTENANCE_NOTIFICATION_EMAIL: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    alert: {
                        ...state.manage.alert,
                        emails: action.payload
                    }
                },
            };
        }

        case SET_MAINTENANCE_TIME_DAY: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    date: {
                        ...state.manage.date,
                        startTime: action.payload
                    }
                },
            };
        }
        
        case GET_FILTERS_MAINTENANCE_END: {
            return {
                ...state,
                groups: action.payload,
                loading: false
            }
        }

        case GET_MAINTENANCE_LIST_END: {
            const {result, filter} = action.payload;
            return {
                ...state,
                maintenance: result,
                filter,
                loading: false
            }
        }

        case SAVE_NEW_MAINTENANCE_END: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
                loading: false
            }
        }

        case SET_SELECTED_MAINTENANCE:
            return {
                ...state,
                selectedMaintenances: action.payload,
            };

        case DELETE_MAINTENANCES_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedMaintenances:
                        state.selectedMaintenances.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    maintenance: {
                        total: state.maintenance.total - deletedCount,
                        list:
                            state.maintenance.list.filter(
                                (maintenance) => !_ids.includes(maintenance.key)
                            ) || [],
                    },
                };
            }
            
            return {
                ...state,
                loading: false
            }; 
        }

        case GET_MAINTENANCE_END:
            const {alert, km, engineTime, date, ...data} = action.payload.result;
            const manage: ManageMaintenanceType = {...data, engineTime, date, km};

            if(alert && alert.emails.length > 0) {
                const {alertKm, alertDay, alertEngineTime, emails} = alert;
                manage.alert = {
                    emails: emails.map(email => {
                        return {
                            label: email,
                            value:email
                        }
                    }),
                    alertKm,
                    alertEngineTime,
                    alertDay
                }
            }
            return {
                ...state,
                manage,
                activeGroup: action.payload.filter.groupId,
                activeMaintenance: action.payload.filter._id,
                loading: false
            };
        default:
            return state;
    }
}

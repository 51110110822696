import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetTokenStartType, GET_TOKEN_START, GetTokenEnd } from './sharedMap.actions';
import { RootState } from '../../../redux/store';
import { ErrorShares } from '../../SharePosition/redux/share.actions';
import { getTokenShare } from './share.service';

export const GetShareTokenEpic = (
    action$: ActionsObservable<GetTokenStartType>,
    state$: { value: RootState }
): Observable<any> =>
    action$.pipe(
        ofType(GET_TOKEN_START),
        switchMap((action: GetTokenStartType) => {
            return from(getTokenShare(action.payload)).pipe(
                map((result) => {
                    return GetTokenEnd(result);
                }),
                catchError((err) => {
                    return of(ErrorShares(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorShares(err.msg));
        })
    );
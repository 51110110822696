import { Steps } from "antd";
import { useState } from "react";

type StepperProps = {
    step?: number;
    onChange?: (step: number) => void;
}
export const Stepper = (props: StepperProps) => {
    const [current, setCurrent] = useState(props.step);
    const onChange = (value: number) => {
        if(props.onChange) props.onChange(value);
        setCurrent(value);
    };

    return (
        <Steps
            current={current}
            onChange={onChange}
            direction="vertical"
            size="small"
            items={[
                {
                    title: 'Groupe',
                    description: "Choix des passagers",
                },
                {
                    title: 'Paramétrage',
                    description: 'Choix de la déstination',
                },
                {
                    title: 'Transport',
                    description: 'Choix des véhicules',
                },
                {
                    title: 'Résultat',
                    description: 'Trajets générés',
                },
            ]}
        />
    );
}
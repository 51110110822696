import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts';
import { useCallback } from 'react';
import { FuelBarResponse } from '../dashboard.service';


const apexPlotOptions: ApexPlotOptions = {
    bar: {
        horizontal: true,
        dataLabels: {
            position: 'top',
        },
        borderRadius: 4,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
    }
}
const options: ApexOptions = {
    chart: {
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: true,
        offsetX: -30,
        style: {
            fontSize: '12px',
            colors: ['#fff'],
        },
        formatter: function (size) {
            return `${size} L`;
          },
    },
    xaxis: {
        categories: [],
    },
    tooltip: {
        enabled: false
    },
    plotOptions: apexPlotOptions,
}

type FuelBarProps = {
    data: FuelBarResponse[]
}
export const FuelBar = (props: FuelBarProps) => {
    const values = useCallback(()=> {
        return props.data.map(entry => entry.consumed);
    }, [props.data]);

    const getOptions = useCallback(()=> {
        
        return {
            ...options,
            xaxis: {
                categories: props.data.map(entry => entry.name)
            }
        }
    }, [props.data]);

    return (
        <Chart 
            options={getOptions()} 
            series={[{ data: values() }]} 
            type="bar"
            height={250} 
        />
    );
}
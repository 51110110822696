import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { CardBody, CardFooter, Row, Col } from "reactstrap";
import { InputSearch } from "../../../../Components/Form/InputSearch";
import { TableUi } from "../../../../Components/Table";
import { BadgeUi } from "../../../../Components/basic/BadgeUi";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { CardUi } from "../../../../Components/basic/Card";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { Paginator } from "../../../../Components/basic/Paginator";
import { LIMIT } from "../../../../env";
import { useFetchPassengersWithStop } from "../../../../common/hooks/axios/passenger/useFetchPassengersWithStop";
import { GroupsSelection } from "../components/group-selection-road copy";

type GroupsRoadPageProps = {
    default: Record<string, {label: string, value: string}[]>,
    onChange: (groups: Record<string, {label: string, value: string}[]>) => void,
}
export const GroupsRoadPage = (props: GroupsRoadPageProps) => {
    const intl = useIntl();
    const [ selectedGroup, setSelectedGroup] = useState<string | null>(props.default?.other?.length ? props.default.other[0].value : null);
    const {passengersWithStop, fetchPassengersWithStop, fetchPassengersWithStopLoading} = useFetchPassengersWithStop({manual: true});
    const [filter, setFilter] = useState<{"pagination[limit]": number, "pagination[skip]": number, name?: string, group?: string}>({"pagination[limit]": LIMIT, "pagination[skip]": 0});
    const refetchData = (skip: number, params?: {searchBy?: string, group?: string}) => {
        const newFilter = {...filter, 'pagination[limit]': LIMIT, 'pagination[skip]': skip };
        if(params?.hasOwnProperty('group')) { newFilter.group = params.group; }
        if(params?.hasOwnProperty('searchBy')) { newFilter.name = params.searchBy; }
        setFilter(newFilter);
        fetchPassengersWithStop(newFilter);
    };
    
    useEffect(() => {
        if(selectedGroup) {
            refetchData(0, { group: selectedGroup })
        }
    }, [selectedGroup]);

    const onPaginate = (skip: number, limit: number): void => {
        refetchData(skip);
    };

    const onSearch = ({searchBy}: {searchBy?: string}) => { 
        refetchData(0, {searchBy});
    }

    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: "Identifiant",
            dataIndex: 'identifier',
            key: 'identifier',
        },
        {
            title: "Arrêt",
            dataIndex: 'stop',
            key: 'stop',
            render: (value: string) => {
                return <BadgeUi color="info">{value}</BadgeUi>
            }
        },
        {
            title: "Description",
            dataIndex: 'stopDescription',
            key: 'stopDescription'
        },
        {
            title: "action",
            dataIndex: 'key',
            key: 'key',
            render: (value: string) => {
                return <ButtonUi color="danger" size="sm" ghost>Retirer </ButtonUi>
            }
        },
    ];
    
    const showFooter = Math.ceil(passengersWithStop.total / LIMIT) > 1;


    return (
        <div className="d-flex flex-row">
            <LoaderUi loading={fetchPassengersWithStopLoading} />
            <GroupsSelection 
                default={props.default}
                selectedGroup={selectedGroup} 
                setSelectedGroup={setSelectedGroup}
                onChange={props.onChange}
            />
            {
                selectedGroup && <CardUi className="ms-2" style={{flex:1}}>
                <InputSearch
                        type="text"
                        name={'searchBy'}
                        placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' }) + " par nom"}
                        onSubmit={onSearch}
                    />
                <CardBody className="p-0">
                    <TableUi 
                        columns={columns}
                        data={ passengersWithStop.list }
                        minusHeight={190 + (showFooter ? 80 : 20)}
                    />
                </CardBody>
                {
                    Math.ceil(passengersWithStop.total / filter["pagination[limit]"]) > 1 ? (
                        <CardFooter>
                        <Row>
                            <Col>
                                <Paginator
                                    total={passengersWithStop.total}
                                    skip={filter["pagination[skip]"]}
                                    limit={filter["pagination[limit]"]}
                                    onPaginate={onPaginate}
                                />
                            </Col>
                        </Row>
                    </CardFooter>
                    ) : <></>
                }
            </CardUi>
            }
            
        </div>
    );
}
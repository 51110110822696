import { FormattedMessage } from "react-intl";
import { RateUiComponent } from "../../../basic/RateUi";

type DriverSubMarkProps = {
    text: string,
    mark?: number | null,
    onChange: (mark: number)=> void,
    [key: string]: any
}
export const DriverSubMark = ({text, mark, description, onChange, ...otherProps}: DriverSubMarkProps) => {
    
    return (
        <div className="d-flex flex-row align-items-center justify-content-between" {...otherProps}>
            <h6 className="mb-0">
                <FormattedMessage id={text} />
            </h6>
            <RateUiComponent mark={mark ?? 0} size={18} allowHalf={false} allowColor={true} onChange={onChange}/> 
        </div>
    );
}
import { FaCar } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { UncontrolledTooltip } from 'reactstrap';
import { DriverAction } from '../../../../pages/Driver/types';
import { DriverInListType } from '../../../../pages/Driver/_redux/types';
import { BadgeUi } from '../../../basic/BadgeUi';
import { ButtonUi } from '../../../basic/ButtonUi';
import { Avatar } from '../../../basic/avatar/avatar';

type DriverComponentProps = {
    driver: DriverInListType,
    onClick?: (key: string, action: DriverAction) => void
}

const SliceText = ({text, limit}: {text: string, limit: number}) => {
    const id = text.replaceAll(" ", "");
    if(text.length > limit) {
        return <>
            <span id={`driver-${id}`}>
                {`${text.slice(0, limit)}...`}
            </span>
            <UncontrolledTooltip target={`driver-${id}`}>
                { text }
            </UncontrolledTooltip>
        </>
    }
    return <>{text}</>
}
export const DriverComponent = ({driver, onClick}: DriverComponentProps) => {
    const chooseDriver = () => {
        if(driver.assignment.to && onClick) {
            onClick(driver.assignment.to, DriverAction.SEARCH_UNIT)
        }
    }

    return(
        <div className="object-info py-2 px-3 d-flex align-items-center justify-content-between border-bottom" onClick={chooseDriver}>
            <div className="d-flex align-items-center flex-grow-1">
                <Avatar 
                    text={driver.name}
                    size="sm" 
                    color="primary" 
                    className="me-2"
                    rounded='rounded-circle'
                />
                <div className="flex-grow-1">
                    <div className='d-flex flex-row justify-content-between align-items-center flex-grow-1'>
                        <h6 className="mb-0">
                            <SliceText text={driver.name.toLowerCase()} limit={20}/>
                            {driver.assignment.to && <BadgeUi className="ms-2" color={'success'} light>Affecté</BadgeUi>}
                        </h6>
                        {
                            onClick && <ButtonUi 
                            color="secondary" 
                            ghost 
                            size='sm'
                            onClick={()=> onClick(driver.key, DriverAction.DETAILS)}
                        >
                                <>Voir détails <FiExternalLink/></>
                        </ButtonUi>
                        }
                        
                    </div>

                    {
                        driver.assignment?.to && <div className="d-flex">
                            <span className="text-muted fs-10">
                                <FaCar/> { driver.assignment.to }
                            </span>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
import { FormattedMessage } from "react-intl"
import { ButtonUi } from "../../../Components/basic/ButtonUi"

type ActionBarType = {
    selected: string[];
    onClick: (action: string, _ids: string[])=> void
} 
export const ActionTabBar = ({selected, onClick}: ActionBarType) => {
    return(
        <div className="hstack gap-2 justify-content-end">
            {
                selected.length === 1 && <ButtonUi 
                                            color="secondary"
                                             size="sm" 
                                             soft
                                             onClick={()=> onClick('edit', selected)}
                                        >
                                            <FormattedMessage id="COMMON.UPDATE" />
                                        </ButtonUi>
            }
            <ButtonUi color="danger" size="sm" soft
                onClick={()=> onClick('delete', selected)}
            >
                <FormattedMessage id="COMMON.DELETE" /></ButtonUi>
        </div>
    )
}
import { useContext, useEffect, useRef, useState } from "react";
import { VehicleInfoType } from "../../Objects/_redux/types";

import classNames from "classnames";
import { LayoutContext } from "../../../../context/layout-style-context";

import { FuelChart } from "./ChartFuel";
import { FuelInfo } from "../../../../Components/advanced/Fuel-info";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { useAppSelector } from "../../../../redux/hooks";
import { ButtonGroup } from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { VisibleSensor } from "../Details/_redux/type";
import { useDispatch } from "react-redux";
import { GetChartFuelStart, GetFuelStart } from "./_redux/fuelActions";
import React from "react";





export const ObjectFuel = React.memo(({display, sensors, rangeTime}: {sensors?: VisibleSensor[], display: boolean, rangeTime: {from: number, to: number} | null}) => {
    const dispatch = useDispatch();
    const listRef = useRef<any>();
    const HEIGHT_FIX = 279;
    const { height } = useContext(LayoutContext);
    const { fuel, activeFuel } = useAppSelector(
        (state: any) => state.fuelReducer
    );

    const [ fuelSensors, setFuelSensors] = useState<VisibleSensor[]>([]);
    const [ active, setActive] = useState<any| null>();
    const showFilter = active && rangeTime && fuelSensors.length > 1;
    const detailsClass = classNames({
        [`d-none`]: !display,
    });

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50,
    });

    useEffect(()=> {
        if(sensors) { 
            const fuelSensors = sensors.filter(s => !!s.isFuelLvl);
            setFuelSensors(fuelSensors);
            setActive(fuelSensors.length > 0 ? fuelSensors[0] : null );
        }
    }, [sensors]);

    useEffect(()=> {
        if(rangeTime && fuelSensors.length > 0 && active) {
            dispatch(GetChartFuelStart({range: rangeTime, active: active.param}));
        }
    }, [rangeTime, active]);

    useEffect(()=> {
        if(rangeTime ) {
            dispatch(GetFuelStart(rangeTime));
        }
    }, [rangeTime]);



    
    return (
        <div className={detailsClass} style={{height: height - HEIGHT_FIX}}>
            {
                showFilter && <div className="py-2 px-3 border-bottom">
                <div className="d-flex justify-content-center">    
                    <ButtonGroup size="sm">
                        {
                            fuelSensors.map(sensor => {
                                return (
                                    <ButtonUi 
                                        key={sensor.param}
                                        color="primary" 
                                        outline={sensor.param !== active.param} 
                                        onClick={()=> setActive(sensor)}>{
                                        sensor.name}
                                    </ButtonUi>
                                )
                            })
                        }  
                    </ButtonGroup>
                </div>
            </div>
            }
            

            <FuelChart unit={active?.unit} />
            <AutoSizer>
                {
                    ({ height, width }) => {
                        return(
                            <List
                                ref={listRef}
                                width={width}
                                height={height > 0 ? height - 174 : 0}
                                rowCount={fuel.length}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={rowRenderer}
                                scrollToIndex={activeFuel ? activeFuel.index : undefined}
                            />
                        )
                    }     
                } 
            </AutoSizer>
        </div>          
    );

    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                    <FuelInfo event={fuel[index]} active={activeFuel?.index === index}/>
                </div>
            )}
          </CellMeasurer>
        );
    }
    
});


import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './eventActions';
import { Error, getEventsEnd, SetActiveEnd } from './eventActions';
import { fetchEvents } from './eventService';

/*
 * Fetch vehicles data
 * */

export const FetchEventsEpics = (action$: any, state$: any): any =>
    action$.pipe(
        ofType(actions.GET_START),
        switchMap((action: any) => {
            const { token } = state$.value.authReducer;
            const vehicleId = state$.value.ObjectReducer.vehicle._id;
            const range = state$.value.eventsObjectReducer.eventsPeriod;
            if(range && (action.payload.from === range.from && action.payload.to === range.to)) {
                return of(Error(''));
            }
            return from(fetchEvents(vehicleId, action.payload, token)).pipe(
                map((res) => {
                    return getEventsEnd({events: res, period: action.payload});
                }),
                catchError((err) => {
                    return of(Error(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(Error(err.msg));
        })
    );

export const SetActiveEventEpic = (action$: any, state$: any): any =>
    action$.pipe(
        ofType(actions.SET_ACTIVE_START),
        switchMap((action: any) => {
            const leafletMap = state$.value.mainReducer.map;
            const { events } = state$.value.eventsObjectReducer;
            const { _id, coordinates } = action.payload;
            let lng = 0;
            let lat = 0;
            if(Array.isArray(coordinates)) {
                lng = coordinates[0]
                lat = coordinates[1]
            } else {
                lng = coordinates.coordinates[0];
                lat = coordinates.coordinates[1];
            }
            leafletMap.fitBounds([[lat, lng]], {
                paddingTopLeft: [600, 20],
            });
            const index = events.findIndex((event: any) => event._id === _id);
            return of(SetActiveEnd({ ...{ index }, ...action.payload }));
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );
/*
export const NewEvents = (action$: any, state$: any) => action$.pipe(
    ofType(actions.SET_NEWEVENT),
    map((action: any) => {
        if(action.payload && state$.value.mainReducer.vibrate) {
            Vibration.vibrate(300);
        }
        return Error('');
    }),
    catchError(err => {
        return of(Error('Problem unknow'))
    })
);
*/

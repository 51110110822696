import { AiOutlineRadarChart } from 'react-icons/ai';
import { BsFillPinMapFill } from 'react-icons/bs';
import { TbRadar2 } from 'react-icons/tb';
import { GeofenceAction, GeofenceInListType, GeofenceType } from '../../../pages/Geofence/types';
import { GeofenceDeleteAction } from './actions/geofenceDelete';
import { GeofenceEditAction } from './actions/geofenceEdit';


type GeofenceComponentProps = {
    geofence: GeofenceInListType,
    onClick: (key: string, action: GeofenceAction) => void
}

export const GeofenceIcon = ({type, color}: {type: GeofenceType, color: string}) => {
    switch(type) {
        case GeofenceType.CIRCLE:
            return <TbRadar2 size={28} className="me-2" color={color}/>
            
        case GeofenceType.POINT_OF_INTEREST:
            return <BsFillPinMapFill size={24} className="me-2" color={color}/>
        case GeofenceType.POLYGONE:
            return <AiOutlineRadarChart size={28} className="me-2" color={color}/>
    }
    return <></>;
}
export const GeofenceComponent = ({geofence, onClick}: GeofenceComponentProps) => {
    return(
        <div className="object-info py-2 px-3 d-flex align-items-center justify-content-between border-bottom">
        <div className="d-flex align-items-center">
            
            <GeofenceIcon type={geofence.type} color={geofence.color}/>
            <div>
                <h6 className="mb-0">{geofence.name}</h6>
                {
                    geofence.radius && <div className="d-flex">
                    <div>
                        <span className="text-muted fs-10">
                            Rayon: { geofence.radius } m
                        </span>
                    </div>
                </div>
                }
                
            </div>
        </div>

        <div className="d-flex align-items-center justify-content-between" style={{width: '38px'}}> 
            <GeofenceEditAction geofence={geofence} onClick={onClick} />
            <GeofenceDeleteAction geofence={geofence} onClick={onClick} />
        </div>
    </div>
    )
}
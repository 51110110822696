import { useEffect } from "react";
import { useAxios } from "../useAxios";
import { StopListItemType } from "./type";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
}

export type FetchStopProps = {
  name?: string,
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
}

type UseFetchStopResponse = { 
  stops: { list: StopListItemType[], total: number };
  fetchStopsLoading: boolean;
  error?: string;
  fetchStops: (params?: FetchStopProps) => void;
  cancel: () => void;
}



type UseFetchStopsProps = {
  manual?: boolean,
  skip?: number,
  limit?: number,
}

export function useFetchStops(props: UseFetchStopsProps): UseFetchStopResponse {
  const URL = `stops`;
  const { execute, data, cancel, error, loading } = useAxios<{list: StopListItemType[], total: number}>(props.manual);

  useEffect(() => {
    if(!props.manual) {
      fetchStops({"pagination[skip]": props.skip ?? 0, "pagination[limit]": props.limit ?? 25});
    }
  }, []);

  const fetchStops = (params?: FetchStopProps) => {
    execute(URL, { method: "GET", params});
  };

  return { 
    stops: data ?? {list: [], total: 0},
    fetchStopsLoading: loading, 
    error,
    fetchStops,
    cancel, 
  };
}

import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BiBell } from 'react-icons/bi';
import { NotificationItemDropdown } from './notification-dropdown-item';
import NotificationSkeleton from './notification-skeleton';
import { useFetchNotifications } from './hooks/notification/useFetchNotifications';
import { NotificationType } from './hooks/notification/type';
import { BadgeUi } from '../../Components/basic/BadgeUi';
import { useCountUnreadNotifications } from './hooks/notification/useCountUnreadNotifications';
import { IF } from '../../Components/If';
import { useNavigate } from 'react-router-dom';
import { useMarkUnreadNotifications } from './hooks/notification/useMarkNotificationsAsRead';
import { NotificationEmpty } from './notification-empty';
import Emitter from '../../helpers/emitter';
import { InfiniteScroll } from '../../Components/List/infinite-scroll/infinite-scroll';


export const NotificationDropdown = () => {
    const navigate = useNavigate();
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const { fetchAsyncNotifications, notifications } = useFetchNotifications({manual: true});
    const { countUnreadNotifications, totalUnreadNotifications} = useCountUnreadNotifications({manual: true});
    const { markAsyncNotificationsAsRead } = useMarkUnreadNotifications({manual: true});
    const notificationsMapRef = React.useRef<NotificationType[]>([]);
    const itemStatusRef = React.useRef<any>({});


    const [notificationsList, _setNotificationsList] = useState<NotificationType[]>([]);
    const setNotificationsList = (notification: NotificationType[]) => {
        _setNotificationsList(notification);
        notificationsMapRef.current = notification

    }
    const [ total, setTotal] = useState<number>(-1);

    useEffect( () => {
        Emitter.on(Emitter.event['IO:NEW_NOTIFICATION'], newNotifications);
        return () => {
            Emitter.off(Emitter.event['IO:NEW_NOTIFICATION'], newNotifications);
        };
    }, []);

    useEffect(() => {  countUnreadNotifications(); }, []);
    useEffect(() => { 
        if(total === 0 && isNotificationDropdown === false && notifications.total === 0) {
            setTotal(-1);
        } else if((notifications.total) > 0) {
            setTotal(notifications.total);
        }
        console.log(notifications.total)
    }, [notifications.total, isNotificationDropdown]);

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    const goTo =  async (key: string, action: string, link: string) => {
        const result = await markAsyncNotificationsAsRead({keys: [key]});
        const newNotificationsList = [...notificationsList];
        const index = newNotificationsList.findIndex(item => item.key === key && !item.isReaded);

        if(index != null && result.success) {
            newNotificationsList[index].isReaded = true;
            setNotificationsList(newNotificationsList);
            countUnreadNotifications(); 
        }
        navigate(link);
    };



    const loadMoreRows = async ({ startIndex, stopIndex }: any): Promise<boolean> => {
        const newNotifications = [...notificationsList];
        const maxItems = (stopIndex - startIndex) ;
        const limit = maxItems + 1;
        const { success } = await fetchAsyncNotifications({"pagination[limit]": limit, "pagination[skip]": startIndex});
        
        if(success && success.data.length > 0) {
            setNotificationsList(success.data);
            for (let index = startIndex; index <= stopIndex; index++) {
                newNotifications[index] = success.data[index - startIndex];
            }
            setNotificationsList(newNotifications);
            return true;
        }
        setNotificationsList([]);
        setTotal(0);
        
        return false;
    };

    
    const newNotifications = async () => {
        countUnreadNotifications();
        const { success } = await fetchAsyncNotifications({"pagination[limit]": 1, "pagination[skip]": 0});
        if(success && success.data.length > 0) {
            const newNotifications = [...(notificationsMapRef.current ?? [])];
            newNotifications.unshift(success.data[0]);
           _setNotificationsList(newNotifications);
        }
    }
    const remoteRowCount = total > -1 ?  total : 1;

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="ms-1 header-item topbar-notifications">
                <DropdownToggle tag="button" type="button" className="rou&nded-circle btn-icon ghost btn btn-secondary">
                    <BiBell size={18}/>
                    <IF condition={ totalUnreadNotifications > 0} >
                        <BadgeUi className="position-absolute topbar-badge" color="danger" pill style={{padding: "2px 4px"}}>
                            <span>{ totalUnreadNotifications }</span>
                        </BadgeUi>
                    </IF>
                </DropdownToggle>

                <DropdownMenu end className='dropdown-menu-lg'>
                    <p className="p-3 border-bottom mb-0">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h5 className="mb-0">Notifications</h5>
                            <IF condition={ totalUnreadNotifications > 0} >
                                <BadgeUi color="info" light>{totalUnreadNotifications} Non lue</BadgeUi> 
                            </IF>
                            
                        </div>
                    </p>

                    <IF condition={total === 0}><NotificationEmpty /></IF>

                    <InfiniteScroll 
                        itemStatusRef={itemStatusRef}
                        data={notificationsList} 
                        totalItems={remoteRowCount} 
                        Component={NotificationItemDropdown} 
                        ComponentSkeleton={NotificationSkeleton}
                        onEvent={goTo} 
                        loadMoreRows={loadMoreRows} 
                        minHeight={350}
                        autoHeight={remoteRowCount < 6}
                    />
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};


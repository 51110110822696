import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import {
    CreateShareEnd,
    CreateShareStartType,
    CREATE_SHARE_START,
    DeleteSharesEnd,
    DeleteSharesStartType,
    DELETE_SHARES_START,
    ErrorShares,
    GetSharesEnd,
    GetSharesStartType,
    GetShareUpdateEnd,
    GetShareUpdateStartType,
    GET_SHARES_START,
    GET_SHARE_UPDATE_START,
    ShareActionType
} from './share.actions';
import { deleteShares, getShare, getShares, saveShare } from './share.service';

export const CreateShareEpic = (
    action$: ActionsObservable<CreateShareStartType>,
    state$: { value: RootState }
): Observable<ShareActionType | any> =>
    action$.pipe(
        ofType(CREATE_SHARE_START),
        switchMap((action: CreateShareStartType) => {
            const { manage } = state$.value.shareReducer;
            const payload = { ...manage, ...action.payload };
            return from(saveShare(payload)).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...payload,
                        units: payload.units?.map(
                            (unit) => unit.label
                        ),
                        ...result,
                        ...{
                            _id: result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                        },
                    };

                    return of(
                        CreateShareEnd(newManage),
                        SetRedirectAction('/share'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                    );
                }),
                catchError((err) => {
                    return of(ErrorShares(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorShares(err.msg));
        })
    );

export const GetSharesEpic = (
    action$: ActionsObservable<GetSharesStartType>,
    state$: { value: RootState }
): Observable<ShareActionType> =>
    action$.pipe(
        ofType(GET_SHARES_START),
        switchMap((action: GetSharesStartType) => {
            return from(getShares(action.payload)).pipe(
                map((result) => {
                    return GetSharesEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorShares(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorShares(err.msg));
        })
    );

export const GetShareEpic = (
    action$: ActionsObservable<GetShareUpdateStartType>,
    state$: { value: RootState }
): Observable<ShareActionType> =>
    action$.pipe(
        ofType(GET_SHARE_UPDATE_START),
        switchMap((action: GetShareUpdateStartType) => {
            return from(getShare(action.payload)).pipe(
                map((result) => {
                    return GetShareUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorShares(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorShares(err.msg));
        })
    );

export const DeleteSharesEpic = (
    action$: ActionsObservable<DeleteSharesStartType>,
    state$: { value: RootState }
): Observable<ShareActionType> =>
    action$.pipe(
        ofType(DELETE_SHARES_START),
        switchMap((action: DeleteSharesStartType) => {
            return from(deleteShares({ _ids: action.payload })).pipe(
                map((result) => {
                    return DeleteSharesEnd({
                        ...result.data,
                        _ids: action.payload,
                    });
                }),
                catchError((err) => {
                    return of(ErrorShares(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorShares(err.msg));
        })
    );

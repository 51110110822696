import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Col, Form, Input, Row } from "reactstrap";
import { InputSelect } from "../../../../Components/Form/InputSelect";
import { InputText } from "../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeEventOperation, SetSelectValueAlarmAction } from "../../redux/events.actions";

type BooleanTypeProps = {
    position: number,
    selected?: string
}
export const OperationValueType = ({position, selected}: BooleanTypeProps) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [types, setTypes] = useState<{label: string, value: string}[]>([]);
    const { manage } = useAppSelector((state) => state.eventsReducer);
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            value: manage.events[position].value
        }
    });

    const options: any[] = [
        { label: '=', value: '===' },
        { label: '>=', value: '>=' },
        { label: '<=', value: '<=' },
        { label: '<', value: '<' },
        { label: '>', value: '>' },
    ];

    const defaultValue = options.find((option) => option.value === selected);
    const [selectedOption, setSelectedOption] = useState(defaultValue);

    const handleChange = (data: any) => {
        const value = data.target.value &&  data.target.value.length > 0 ? data.target.value : undefined;
        if(value) {
            dispatch(SetSelectValueAlarmAction({position, data: value}))
        } else {
            dispatch(SetSelectValueAlarmAction({position}));
        } 
    }

    const handleChangeOperations = (data: any) => {
        setSelectedOption(data);
        dispatch(ChangeEventOperation({position, operation: data.value}))
    }

    return (
        <Form>
            <Row>
            <Col xl={6}>
                <InputSelect 
                    key={`select__${manage.events[0].type?.value}__${manage.events[0].subType?.value}`}
                    label={intl.formatMessage({ id: 'ALARM.OPERATION'})}
                    options={options}
                    defaultValue={selectedOption}
                    onChange={handleChangeOperations}
                />
            </Col>
            
            <Col xl={6}>
            <InputText 
                label={intl.formatMessage({ id: 'ALARM.VALUE'})}
                type="text"
                id="value"
                name="value"
                placeholder="valeur"
                register={register("value")}
                error={manage.events[position].value ? undefined : {message: 'required'}}
                onChange={handleChange}
            />
            </Col>
            </Row>
            
        </Form>
                
    );
}


import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Offcanvas, OffcanvasHeader, Container, OffcanvasBody } from "reactstrap";
import { useAppSelector } from "../../../redux/hooks";
import { AssignVehicles } from "../../components/VehiclesAssign";
import { AssignVehicleAlarmStartAction, ToogleAffectToAction } from "../redux/events.actions";
import { getAssignedVehicle } from "../redux/events.service";

export const AssignVehiclesSlider = () => {
    const [selected, setSelected] = useState<string[] | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const { affectTo } =
        useAppSelector((state) => state.eventsReducer);




    const fetchSelected = async (): Promise<void> => {
        setLoading(true);
        if (affectTo && affectTo._id) {
            const vehiclesId = await getAssignedVehicle(affectTo._id);
            setSelected(vehiclesId);
        } else {
            setSelected(null);
        }
        setLoading(false);
    };


    useEffect(() => {
        fetchSelected();
    }, [affectTo]);

    return (
        <Offcanvas  className="assignVehicles" direction="end" isOpen={!!affectTo} toggle={()=> dispatch(ToogleAffectToAction(affectTo))}>
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark border-none">
                <span className="m-0 me-2 text-white"><FormattedMessage id={'COMMON.ASSIGN'}/></span>
            </OffcanvasHeader>
            <OffcanvasBody>
            <Container fluid>
                
                {
                    affectTo && selected && (
                        <AssignVehicles
                            _id={affectTo?._id}
                            selectedVehicles={selected}
                            onSubmit={(vehicles: string[]) =>
                                dispatch(AssignVehicleAlarmStartAction(vehicles))
                            }
                            onCancel={() =>
                                dispatch(ToogleAffectToAction(affectTo))
                            }
                        />
                )
                }
                
                </Container>
            </OffcanvasBody>
                
        </Offcanvas>  
            
    );
}
import { useAppSelector } from "../../../../../redux/hooks";
import { TrajectInfoMarker } from "../TrajectInfoMarker";

export function TrajectSelectedPositions(): JSX.Element {
    const { mouseOver, mouseClick } = useAppSelector(
        (state: any) => state.historyReducer.points
    );
    return (
        <>
            {mouseOver && <TrajectInfoMarker data={mouseOver} />}

             {mouseClick &&
                Object.keys(mouseClick).map((point) => {
                    return (
                        <TrajectInfoMarker
                            active={true}
                            data={mouseClick[point]}
                            index={point}
                        />
                    );
                })} 
        </>
    );
}
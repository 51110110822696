
import { FaKey } from 'react-icons/fa';

export type IgnitionProps = { enable: boolean, size?: number };

export default function IgnitionIcon({enable, size=11}: IgnitionProps): JSX.Element {

    return enable ? <FaKey style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }} /> :
    <FaKey style={{ fontSize: size, color: 'gray' }} />;
}

import { Button } from "reactstrap"
import { ColorType } from "../types"
import classNames from 'classnames';
import { IconType } from "react-icons";


type buttonUiType = {
    children?: JSX.Element | string | any;
    className?: string;
    color: ColorType
    outline?: boolean;
    pill?: boolean;
    circle?: boolean;
    soft?: boolean;
    ghost?: boolean;
    size?: 'sm' | 'lg'
    animated?: boolean,
    Icon?: IconType,
    onClick?: (e: any)=> void,
    type?: 'submit' | 'reset' | 'button'
    loading?: boolean
    disabled?: boolean,
    [key: string]: any
    
}

export const ButtonUi = ({className, children, color, outline, pill, circle, soft, ghost, Icon, size, loading, type = 'button', disabled, animated = true, onClick, ...other}: buttonUiType) => {
    
    const buttonClassName = classNames({
        [`${className}`]: !!className,
        [`rounded-circle`]: circle,
        [`btn-icon`]: !!Icon,
        [`animation`]: animated,
        [`soft`]: soft,
        [`ghost`]: ghost,
        [`rounded-pill`]: pill,
        [`btn-loading`]: loading,
      });

    return <Button 
            className={buttonClassName} 
            color={color} 
            outline={outline}
            size={size}
            onClick={onClick}
            type={type}
            disabled={disabled}
            {...other}
        >
            {Icon && !loading && <Icon size={18}/>}
            {children}
        </Button>
}



import { BIND_DRIVERS_END, BIND_DRIVERS_START, CLEAR_MANAGE_DRIVER, CREATE_DRIVER_END, CREATE_DRIVER_START, DELETE_DRIVERS_END, DELETE_DRIVERS_START, DriverActionsType, ERROR, GET_DRIVER_UPDATE_END, GET_DRIVER_UPDATE_START, GET_END, GET_START, GET_UNIT_TO_ASSIGN_END, GET_UNIT_TO_ASSIGN_START, UNBIND_DRIVERS_END, UNBIND_DRIVERS_START } from './driverModule.actions';
import { AssignmentType, DriverInListType, UnitToAssignDriverInListType } from './types';

type StateType = {
    loading: boolean;
    filter: {
        limit: number;
        skip: number;
    };
    drivers: {
        list: DriverInListType[];
        total: number;
    };
    assignment: {
        units: UnitToAssignDriverInListType[],
        active?: string
    };
    manage: any,
    error?: string;
};

const INITIAL_STATE: StateType = {
    loading: false,
    filter: {
        limit: 1,
        skip: 0,
    },
    manage: {},
    drivers: {
        list: [],
        total: 0,
    },
    assignment: {
        units: []
    }
};

export function driverModuleReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: DriverActionsType
): StateType {
    switch (action.type) {
        case CREATE_DRIVER_START:
        case GET_DRIVER_UPDATE_START:
        case DELETE_DRIVERS_START:
        case BIND_DRIVERS_START:
        case UNBIND_DRIVERS_START:
        case GET_UNIT_TO_ASSIGN_START:
        case GET_START:{
            return {
                ...state,
                error: undefined,
                loading: true
            }
        }

        case CREATE_DRIVER_END:{
            const list = [...state.drivers.list];
            const { key, modifiedCount } = action.payload;
            const updatedDriver: any = { ...action.payload, ...{ key } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const driverIndex = state.drivers.list.findIndex(
                        (driver) => driver.key === key
                    );

                    if (driverIndex !== -1) {
                        list[driverIndex] = {
                            ...list[driverIndex],
                            ...updatedDriver,
                        };
                    }
                }
            } else {
                list.unshift(updatedDriver);
            }

            return {
                ...state,
                loading: false,
                error: undefined,
                drivers: {
                    list,
                    total: state.drivers.total + 1,
                },
            };
        }

        case GET_DRIVER_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                loading: false,
            };
        }

        case GET_END: {
            return {
                ...state,
                loading: false,
                drivers: { ...action.payload.result },
                filter: action.payload.filter
            }
        }

        case DELETE_DRIVERS_END: {
            const { deletedCount, keys } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    drivers: {
                        total: state.drivers.total - deletedCount,
                        list:
                            state.drivers.list.filter(
                                (driver) => !keys.includes(driver.key)
                            ) || [],
                    },
                };
            }
            
            return {
                ...state,
                loading: false
            }; 
        }

        case BIND_DRIVERS_END: {
            const list = [...state.drivers.list];
            const {driver, unitName, unitKey}: any = action.payload;
        
            const driverIndex = state.drivers.list.findIndex(
                (entry) => entry.key === driver
            );

            if (driverIndex !== -1) {
                list[driverIndex] = {
                    ...list[driverIndex],
                    assignment: {
                        ...list[driverIndex].assignment,
                        to: unitName,
                        type: AssignmentType.MANUAL
                    }
                };
            }

            return {
                ...state,
                loading: false,
                error: undefined,
                drivers: {
                    ...state.drivers,
                    list,
                },
            };
            
        }

        case UNBIND_DRIVERS_END: {
            const list = [...state.drivers.list];
            const {key, assignment}: any = action.payload;
        
            const driverIndex = state.drivers.list.findIndex(
                (driver) => driver.key === key
            );

            if (driverIndex !== -1) {
                list[driverIndex] = {
                    ...list[driverIndex],
                    assignment
                };
            }

            return {
                ...state,
                loading: false,
                error: undefined,
                drivers: {
                    ...state.drivers,
                    list,
                },
            };
            
        }

        case CLEAR_MANAGE_DRIVER: {
            return {
                ...state,
                manage: INITIAL_STATE.manage
            }
        }

        case GET_UNIT_TO_ASSIGN_END: {
            return {
                ...state,
                assignment: {
                    units: action.payload.result.list,
                    active: undefined
                },
                loading: false,   
            }
        }
        case ERROR: {
            return {
                ...state,
                error: action.payload
            }
        }
        
        default:
            return state;
    }
}

import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Address } from '../../../../../Components/Address';
import { formatDate } from '../../../../../helpers/dateHelper';
import { SetPointMouseClick } from '../../../../../pages/Map/Object/History/_redux/historyActions';
import { TrajectMarker } from './Traject-marker';

export function TrajectInfoMarker({data, active, index}: any): JSX.Element | null {
    const dispatch = useDispatch();
    const { timezone } = useSelector((state: any) => state.authReducer.user);
    
    const {unitDate, km, location, speed, fuel} = data;
    

    const [lng, lat] = location;
    const intl = useIntl();

    const address = '...';
    const getMarginTop = (): number => {
        const addressSize = address.length;
        return fuel === undefined ? 155 :175;
    }

    const handleClick = () => {
        if(active) {
            dispatch(
                SetPointMouseClick({
                    index,
                    point: index,
                })
            );
        }
        
    }
    const iconPinPopupHtml = renderToStaticMarkup(
        <div className={`popup ${active ? 'popup-active' : 'popup-hovered'}`}>
            <div className="d-flex flex-row align-items-center address-container">
                <span className="address">
                    {' '}
                    <span className="key">Addresse:</span> ...
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Position:</span>
                <span className="value">
                    [{lat}, {lng}]
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Temps:</span>
                <span className="value">
                    {formatDate(unitDate, timezone, 'DD/MM/YYYY HH:mm:ss')}
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Kilométrage:</span>
                <span className="value">
                    {km ?? '-'} KM
                </span>
            </div>

            <div className="d-flex flex-row justify-content-between">
                <span className="key">Vitésse:</span>
                <span className="value">
                    {speed ?? '-'} KM/H
                </span>
            </div>

            {
                fuel && 
                <div className="d-flex flex-row justify-content-between">
                    <span className="key">Carburant:</span>
                    <span className="value">
                        {fuel} Litres
                    </span>
                </div>
            }
        </div>
    );

    const iconPopup = divIcon({
        html: iconPinPopupHtml,
        iconSize: [0, 0],
        iconAnchor: [128, getMarginTop()],
        popupAnchor: [0, -32],
    });
    return ((
        <>
            <Marker
                position={[lat, lng]}
                icon={iconPopup}
                zIndexOffset={99}
                eventHandlers={{
                    click: handleClick,
                }}
            />
            <TrajectMarker position={[lat, lng]}/>
        </>
            
        )
    );
}

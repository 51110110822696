import { useAxios } from "../useAxios";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
  }

type SaveRoadProps = {
  shiftDirection: "start" | "end";
  shiftStop: { label: string, value: string };
  selectedUnits: {key: string, capacity: number}[];
  period: [Date, Date];
  groups: {
    label: string,
    value: string,
    // totalItems: number,
  }[];
}

type UseSaveRoadResponse = { 
  response: boolean | undefined;
  saveRoadLoading: boolean;
  error?: string;
  saveRoad: (payload: SaveRoadProps) => void;
  saveAsyncRoad: (payload: SaveRoadProps) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}



type UseSaveRoadProps = {
  manual?: boolean
}

export function useSaveRoad(props: UseSaveRoadProps): UseSaveRoadResponse {
  const URL = `road`;
  const { execute, data, cancel, error, loading, init } = useAxios<UseSaveRoadResponse>(props.manual);

  const saveRoad = (data: SaveRoadProps) => {
    saveAsyncRoad(data);
  };

  const saveAsyncRoad = async (data: SaveRoadProps) => {
    return execute(URL, { method: "POST", data });
  };

  return { 
    response: data?.response,
    saveRoadLoading: loading, 
    error,
    saveRoad,
    saveAsyncRoad, 
    cancel, 
  };
}

import classNames from "classnames";
import React from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {Label } from "reactstrap";

type AsyncInputSelectType =  any & {label?: string};
export const InputAsyncCreatableSelect = (props: AsyncInputSelectType) => {
    const {label, error, className, ...options} =  props;
    const asyncSelectClass = classNames({
        choices: true,
        [`is-invalid`]: !!error,
        [`${className}`]: !!className,
      });
    return (
        <React.Fragment>
            { label && <Label>{label}</Label> }                                           
            <AsyncCreatableSelect
                {...props}
                className={asyncSelectClass}
                classNamePrefix="choices"
            />
        </React.Fragment>
    );
};
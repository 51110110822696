import { Marker, Tooltip, useMap } from "react-leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import { GiBusStop } from "react-icons/gi";
import { Label } from "reactstrap";
import { useRef } from "react";

type StopMarkerProps = {
    stop: any,
    permanent: boolean;
    count: number
}
export const StopMarker = ({permanent, stop, count}: StopMarkerProps) => {
    const refMarker = useRef<any>();
    const refTooltip = useRef<any>();
    const map = useMap();
    const iconPinHtml = (color: string ) => renderToStaticMarkup(
        <div className={`marker-pin`} style={{backgroundColor: color}}>
            <GiBusStop size={16} style={{color}}/>
        </div>
    );
    const iconPin = (color: string) => divIcon({
        html: iconPinHtml(color),
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });
    const [lng, lat] = stop.location[0].coordinates;
    const moveTo = () => {
        map.setView([lat, lng], 18)
    }
    
    return (
        <Marker 
            ref={refMarker} icon={iconPin(stop.color)}
            position={{lat, lng}}
            eventHandlers={{
                click: moveTo,
              }}
            
        >
            <Tooltip 
                ref={refTooltip}
                className="tooltip-marker" 
                direction="top"
                interactive
                key={`${stop.key}-${permanent}`}
                permanent={permanent}
            >
                <Label className="mb-0">{ stop.name }</Label>
            </Tooltip>
        </Marker>
    );

}
import { CompanyInList, ManageCompanyType } from '../types';
import {
    CLEAR_COMPANY_CREATION,
    CompanyActionType,
    CREATE_COMPANY_END,
    CREATE_COMPANY_START,
    DELETE_COMPANYS_END,
    DELETE_COMPANYS_START,
    ERROR,
    GET_COMPANYS_END,
    GET_COMPANYS_START,
    GET_COMPANY_UPDATE_END,
    GET_COMPANY_UPDATE_START,
    SET_COMPANY_STATUS_TYPE,
    SET_COMPANY_SUBSCRIPTION_DATE_TYPE,
    SET_COMPANY_SUBSCRIPTION_TYPE_TYPE,
    SET_SELECTED_COMPANYS,
    SWITCH_COMPANY_END,
    SWITCH_COMPANY_START,
} from './company.actions';

type StateType = {
    loading: boolean;
    popup: boolean;
    manage: ManageCompanyType;
    selectedCompanys: string[];
    filter: {
        limit: number;
        skip: number;
    };
    companys: {
        list: CompanyInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    popup: false,
    companys: {
        list: [],
        total: 0,
    },
    selectedCompanys: [],
    manage: {
        name: '',
        active: false,
        limit: {
            user: 20,
            driver: 5,
            vehicle: 5,
            event: 20,
        },
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function companyReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: CompanyActionType
): StateType {
    switch (action.type) {
        case CREATE_COMPANY_START:
        case GET_COMPANYS_START:
        case GET_COMPANY_UPDATE_START:
        case SWITCH_COMPANY_START:
        case DELETE_COMPANYS_START: {
            return { ...state, loading: true };
        }
        case SWITCH_COMPANY_END:
        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }
        case CREATE_COMPANY_END: {
            const list = [...state.companys.list];
            const { _id, modifiedCount } = action.payload;
            const updatedCompany: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const companyIndex = state.companys.list.findIndex(
                        (company) => company.key === _id
                    );
                    if (companyIndex !== -1) {
                        list[companyIndex] = {
                            ...list[companyIndex],
                            ...updatedCompany,
                        };
                    }
                }
            } else {
                list.unshift(updatedCompany);
            }

            return {
                ...state,
                loading: false,
                companys: {
                    list,
                    total:
                        modifiedCount === undefined
                            ? state.companys.total + 1
                            : state.companys.total,
                },
                popup: false,
                selectedCompanys: [],
            };
        }

        case GET_COMPANYS_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                companys: result,
                filter,
                loading: false,
            };
        case SET_SELECTED_COMPANYS:
            return {
                ...state,
                selectedCompanys: action.payload,
            };
        case DELETE_COMPANYS_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedCompanys:
                        state.selectedCompanys.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    companys: {
                        total: state.companys.total - deletedCount,
                        list:
                            state.companys.list.filter(
                                (company) => !_ids.includes(company.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_COMPANY_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                popup: true,
                loading: false,
            };
        }

        case CLEAR_COMPANY_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
            };
        }


        case SET_COMPANY_STATUS_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    active: action.payload,
                },
            };
        }

        case SET_COMPANY_SUBSCRIPTION_DATE_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    subscription: action.payload,
                },
            };
        }

        case SET_COMPANY_SUBSCRIPTION_TYPE_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    subscriptionType: action.payload,
                },
            };
        }

        default:
            return state;
    }
}

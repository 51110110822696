import { useAppSelector } from "../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { AntPolyline } from "./polyline-ant";
import { TrajectLimitMarker } from "../marker/TrajectLimitMarker";
import { TrajectSelectedPositions } from "../marker/TrajectMarker/traject-selected-positions";


import L from 'leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet-geometryutil';
import { SetPointMouseOver, SetPointMouseClick } from "../../../../pages/Map/Object/History/_redux/historyActions";

export const ActiveTrajectPolyline = () => {
    const map = useMap();
    const dispatch = useDispatch();
    const { activeTrip } = useAppSelector(state => state.historyReducer);

    const antPathOptions = {
        "className": 'hicham',
        "delay": 900,
        "dashArray": [
            32,
            59
        ],
        "weight": 6,
        "color": "#0ab39c",
        "pulseColor": "rgba(255, 255, 255, 1)",
        "reverse": false,
        "hardwareAccelerated": true
    };
 

        return (
            <>
                
            {                   
                activeTrip && activeTrip.on === true &&                            
                    <>
                        <AntPolyline
                            positions={activeTrip.position}
                            options={antPathOptions}
                            eventHandlers={{
                                mouseout: () => dispatch(SetPointMouseOver(null)),
                                mouseover: (e) => {
                                    const allData = e.target.getLatLngs();
                                    const point = L.GeometryUtil.closest(
                                        map,
                                        allData,
                                        e.latlng,
                                        true
                                    );
                                    const index = e.target.getLatLngs().findIndex((t) => {
                                        return t.lat === point.lat && t.lng === point.lng;
                                    });
                                    dispatch(SetPointMouseOver(activeTrip.traject[index]));
                                },
                                click: (e) => {
                                    const allData = e.target.getLatLngs();
                                    const point = L.GeometryUtil.closest(
                                        map,
                                        allData,
                                        e.latlng,
                                        true
                                    );
                                    const index = e.target.getLatLngs().findIndex((t) => {
                                        return t.lat === point.lat && t.lng === point.lng;
                                    });
                                    dispatch(
                                        SetPointMouseClick({
                                            index,
                                            point: activeTrip.traject[index],
                                        })
                                    );
                                }
                            }}
                            zIndexOffset={99999}
                        />
                        <TrajectLimitMarker activeTrip={activeTrip}/>
                        <TrajectSelectedPositions/>
                    </>
            }
            </>
            
        )
}
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col} from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearTracerCreation, CreateTracerStart, GetTracerUpdateStart } from "../../redux/tracer.actions";
import { fetchTracerByUid } from "../../redux/tracer.service";
import { General } from "./General";

export const ManageTracerBasicInfo = () => {
    const dispatch = useDispatch();
    const {_id} = useParams();
    const intl = useIntl();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const { loading, manage } = useAppSelector((state) => state.TracerReducer);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);


    const onSubmit = async (data: any) => {
        const newData = { ...data };
        setFetchLoading(true);
        if (_id) {
            // dispatch(GetTracerUpdateStart({ _id }))
        } else {
            const exist = await fetchTracerByUid(newData.uid);

            if (exist && exist.error) {
                setFetchLoading(false);
                setError(exist.for, { message: exist.msg, type: 'isUnique' });
                return;
            }
        }
        dispatch(CreateTracerStart(data));
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/tracers' });
    }


    useEffect(() => {
        if(_id && _id !== 'add') {
            dispatch(GetTracerUpdateStart({ _id }))
        }
        return function cleanup() {
            dispatch(ClearTracerCreation());
        };
    }, []);

    useEffect(()=> {
        if(manage.name && manage.name.length > 0) {
            const {...data } = manage;
            const userInfo:any = data;
            Object.keys(data).forEach((key: any) => setValue(key, userInfo[key]));    
        }
    }, [manage.name]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
            <Row>
                <LoaderUi loading={loading || fetchLoading}/>

                <Col xl={12}>
                    <Col>
                        <General register={register} errors={errors} watch={watch}/>
                    </Col>
                </Col>

                <Col>
                    <div className="hstack gap-2 justify-content-end">
                        <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                            <FormattedMessage id="COMMON.CANCEL"/>
                        </ButtonUi>
                        <ButtonUi color={"primary"} type="submit">
                            {
                                _id !== 'add' ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                            }
                        </ButtonUi> 
                    </div>
                </Col>
                

            </Row>
        </Form>
    );
}
import { formatMessage } from "@formatjs/intl";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {  Row, Col, FormGroup, Input, Label } from "reactstrap";
import { ManageMaintenancePageType } from ".";
import { InputText } from "../../../../Components/Form/InputText";
import { numberValidator } from "../../../../helpers/validationHelper";
import { useAppSelector } from "../../../../redux/hooks";
import { ToogleMaitenanceConfigAction } from "../../_redux/maintenance.actions";

export const MaintenanceHour = ({register, watch ,errors}: ManageMaintenancePageType) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const { manage } = useAppSelector((state) => state.MaintenanceReducer);
    const intervalHoursValidation = numberValidator(manage.km.active, 0, 999999);

    return (
        <Row>
            <Col md={12}>
                <FormGroup>
                    <div className="form-check form-switch form-switch-md">
                        <Input 
                        disabled={!manage.unit}
                            className="form-check-input" 
                            type="checkbox" 
                            id="engineHourOption"
                            checked={manage.engineTime.active}
                            onClick={()=>dispatch(ToogleMaitenanceConfigAction('engineTime'))}
                        />
                        <Label className="form-check-label" for="engineHourOption">
                            <FormattedMessage id="MAINTENANCE.ENGINE_HOURS"/>
                        </Label>
                    </div>
                </FormGroup>
            </Col>
           
           {
               manage.engineTime.active && <> <Col md={4}>
               <FormGroup>
                   {
                       <InputText
                           disabled={!manage.engineTime.active || !manage.unit}
                           type="number"
                           label={intl.formatMessage({id: 'MAINTENANCE.INTERVAL_HOURS'})}
                           id={'intervalHours'}
                           name={'intervalHours'}
                           placeholder={intl.formatMessage({id: 'MAINTENANCE.INTERVAL_HOURS'})}
                           register={register('intervalHours', intervalHoursValidation)}
                           error={errors['intervalHours']}
                       />
                   }
               </FormGroup>
           </Col>
           <Col md={4}>
               <FormGroup>
                   {
                       <InputText
                           disabled={!manage.engineTime.active || !manage.unit}
                           type="number"
                           label={intl.formatMessage({id: 'MAINTENANCE.LAST_SERVICE_HOURS'})}
                           id={'lastServiceHours'}
                           name={'lastServiceHours'}
                           placeholder={intl.formatMessage({id: 'MAINTENANCE.LAST_SERVICE_HOURS'})}
                           register={register('lastServiceHours')}
                           error={errors['lastServiceHours']}
                       />
                   }
               </FormGroup>
           </Col>
           <Col md={4}>
               <FormGroup>
                   {
                       <InputText
                           type="number"
                           label={intl.formatMessage({id: 'MAINTENANCE.ACTUAL_HOURS'})}
                           id={'actualHours'}
                           name={'actualHours'}
                           placeholder={intl.formatMessage({id: 'MAINTENANCE.ACTUAL_HOURS'})}
                           register={register('actualHours')}
                           error={errors['actualHours']}
                           disabled={true}
                       />
                   }
               </FormGroup>
           </Col>
       
       </>
           }
            
        
        
        
        
        
        </Row>
    );
}
import { useAxios } from "../useAxios";

type UseDeletePassengerProps = {
  manual?: boolean
  key?: string,
}

type UseDeletePassengerResponse = { 
  isPassengerDeleted: boolean | undefined;
  deletePassengerLoading: boolean;
  error?: string;
  deletePassenger: () => void;
  deleteAsyncPassenger: () => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}


export function useDeletePassenger(props: UseDeletePassengerProps): UseDeletePassengerResponse {
  const URL = `passengers/${props.key ?? '-'}`;
  const { execute, data, cancel, error, loading, init } = useAxios<any>(props.manual);

  const deletePassenger = () => {
    deleteAsyncPassenger();
  };

  const deleteAsyncPassenger = async () => {
    return execute(URL, { method: "DELETE" });
  };

  return { 
    isPassengerDeleted: true,
    deletePassengerLoading: loading, 
    error,
    deletePassenger,
    deleteAsyncPassenger, 
    cancel, 
  };
}

import { useAxios } from "../useAxios";

type UseDeleteStopProps = {
  manual?: boolean
  key?: string,
}

type UseDeleteStopResponse = { 
  isStopDeleted: boolean | undefined;
  deleteStopLoading: boolean;
  error?: string;
  deleteStop: () => void;
  deleteAsyncStop: (key: string) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}


export function useDeleteStop(props: UseDeleteStopProps): UseDeleteStopResponse {
  const { execute, cancel, error, loading } = useAxios<any>(props.manual);

  const deleteStop = () => {
    const URL = `stops/${props.key ?? '-'}`;

    execute(URL, { method: "DELETE" });
  };

  const deleteAsyncStop = async (key: string) => {
    const URL = `stops/${key}`;
    return execute(URL, { method: "DELETE" });
  };

  return { 
    isStopDeleted: true,
    deleteStopLoading: loading, 
    error,
    deleteStop,
    deleteAsyncStop, 
    cancel, 
  };
}

import { useEffect, useRef, useState } from "react";
import { AiOutlineCloseSquare, AiOutlineUnorderedList } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {CardHeader} from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { Tabs } from "../../../Components/Tabs";
import { useAppSelector } from "../../../redux/hooks";
import { VehicleInfoType } from "../Objects/_redux/types";
import {ObjectDetails} from "./Details/DetailsObject";
import { Clear } from "./Details/_redux/vehicleActions";
import { ObjectHistory } from "./History";
import { GetStart, InitHistory } from "./History/_redux/historyActions";
import { PickerTime } from "../../../Components/advanced/Traject-info/PickerTime";
import { ObjectEvent } from "./Event";
import { GetEventsStart, InitEvents } from "./Event/_redux/eventActions";
import { ObjectFuel } from "./Fuel";
import { InitFuel } from "./Fuel/_redux/fuelActions";
import { VisibleSensor } from "./Details/_redux/type";
import { SetOptionMapAction } from "../../../redux/rootActions";
import React from "react";

const FILTERS = [
    { label: 'COMMON.DETAILS', value: 'details', shared: true},
    { label: 'COMMON.HISTORY', value: 'history'},
    { label: 'COMMON.EVENTS', value: 'events'},
    { label: 'COMMON.FUEL', value: 'fuel'},
];

export const Object = React.memo(({object, sensors, sharedMap}: {object: VehicleInfoType, sensors?: VisibleSensor[], sharedMap?: boolean}) => {
    const dispatch = useDispatch();
    const { loading, fuelLoading, eventLoading, detailLoading, timezone } = useAppSelector(
        (state) => {
            return {
                detailLoading: state.ObjectReducer.loading,
                loading: state.historyReducer.trip.loading,
                fuelLoading: state.fuelReducer.loading,
                eventLoading: state.eventsObjectReducer.loading,
                timezone: state.authReducer.user?.timezone,
            }
        }, (old, next)=> {
            return old.detailLoading === next.detailLoading 
            && old.loading === next.loading
            && old.fuelLoading === next.fuelLoading
            && old.eventLoading === next.eventLoading
            && old.timezone === next.timezone
        });

    const [activeNav, setActiveNav] = useState(0);
    const [rangeTime, setRangeTime] = useState<{from: number, to: number} | null>(null);
    const [selectedDay, setSelectedDay] = useState<Date | null>(null);
    const activeNavValueRef = useRef(0);

    const changeActiveNav = (active: number) =>{
        setActiveNav(active);
        activeNavValueRef.current = active;
        if(rangeTime) {
            onChangeDateRange(rangeTime);
        }
    }

    const clearAll = () => {
        dispatch(InitHistory());
        dispatch(InitEvents());
        dispatch(InitFuel());
    }
    
    useEffect(() => {
        return () => {
            dispatch(SetOptionMapAction({opt: 'showMarker', value: true}))
            clearAll();
        };
    }, []);

    useEffect(() => {
        return () => {
            clearAll();
            if(rangeTime) onChangeDateRange(rangeTime);
        };
    }, [object._id]);

    const onChangeDateRange = (range: {from: number, to: number}) => {
        switch(activeNavValueRef.current) {
            case 1:
                dispatch(GetStart(range));
                break;
            case 2:
                dispatch(GetEventsStart(range));
                break;
        }
        setRangeTime(range);
    }

    const onChange = (range: Date[]) => {
        if(range.length === 1) {
           setSelectedDay(range[0])
        }
    }
    
    return (
        <CardUi className="details-object ms-2">
            <LoaderUi loading={loading || fuelLoading|| eventLoading || detailLoading}/>
            <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                <h5 className="d-flex align-items-center mb-0">
                    <AiOutlineUnorderedList className="me-2"/>
                    <span>{object.name.toUpperCase()} {object.driver && <span className='text-success'>({object.driver})</span>}</span>
                </h5>
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => dispatch(Clear())}
                    onKeyDown={() => dispatch(Clear())}
                >
                    <AiOutlineCloseSquare size={20} />
                </div>

            </CardHeader>
            
            <CardBodyUi className="p-0">
                    <Tabs 
                        color="primary" 
                        isCustom
                        className="d-none d-sm-flex" 
                        onChange={changeActiveNav}
                    >
                        { 
                            FILTERS.filter(filter => sharedMap ? filter.shared : true)
                                .map((filter, index: number)=> (
                                <span key={index} className="mx-2">
                                    <FormattedMessage id={filter.label}/>
                                </span>
                            ))
                        }
                    </Tabs>
                    <div>
                        {
                            timezone && 
                                <PickerTime 
                                    timezone={timezone}
                                    visible={activeNav !== 0}
                                    onChangeDateRange={onChangeDateRange}  
                                    onChange={onChange}
                                           
                                 />
                        }
                            <ObjectDetails object={object} sensors={sensors} display={activeNav === 0} sharedMap={sharedMap}/>
                        {
                            !sharedMap && <>
                                <ObjectHistory object={object} display={activeNav === 1}/>
                                <ObjectEvent object={object} display={activeNav === 2}/>
                                <ObjectFuel sensors={sensors} rangeTime={rangeTime} display={activeNav === 3}/>
                            </>
                        }
                        
                         
                    </div> 
            </CardBodyUi>
        </CardUi>  
    );
})

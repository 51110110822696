import { useEffect, useState } from "react";
import { IconType } from "react-icons";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { formatDate } from "../../helpers/dateHelper";
import { useAxios } from "../../common/hooks/axios/useAxios";
import { useAppSelector } from "../../redux/hooks";
import { SetNotificationAction } from "../../redux/rootActions";
import Emitter from "../../helpers/emitter";

type Incidents = {
    list: [],
    total: number
}
type queryFilter = {
    skip: number;
    limit: number;
    name?: string;
    severity: string;
}
const severityColor: Record<string, 'success' | 'warning' | "danger"> = {
    "MINOR": "success",
    "MAJOR": "warning",
    "CRITICAL": "danger"
}
export function IncidentPage() {

    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authReducer);
    const [ params, setParams ] = useState<queryFilter>({skip: 0, limit: 25, severity: 'all'});
    const { data, loading, execute } = useAxios<Incidents>(true);
    const { data: deleteData, loading: loadingDelete, execute: executeDelete } = useAxios<any>(true);


    const canPaginate = Math.ceil((data?.total ?? 0) / +params.limit) > 1;
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },
        { label: 'COMMON.MINOR', value: 'minor' },
        { label: 'COMMON.MAJOR', value: 'major' },
        { label: 'COMMON.CRITICAL', value: 'critical' },
    ];
    
    const onClickAction = (action: string, _ids: string[]) => { 
        switch(action) {
            case 'add':
            case 'edit': {
                navigate({ pathname: `/incident/${_ids[0]}`});
                break;
            }
            case 'delete': {
                let params = '?';
                _ids.forEach((id, i) => {
                    if (i !== 0) {
                        params += `&ids[]=${id}`;
                    } else {
                        params += `ids[]=${id}`;
                    }
                });
               
                Emitter.emit(Emitter.event.DELETE_POPUP, {
                    textConfirmation: "confirm",
                    title: "Supprimer l'incident",
                    description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                    onDelete: () => executeDelete(`incidents${params}`, { method: 'DELETE' }),
                });
                break;
            }
        }
    }

    const onSearch = ({ searchBy }: { searchBy: string }): void => {
        if(searchBy.length > 0) {
            setParams({...params, name: searchBy})
        } else {
            const newParams = {...params};
            delete newParams.name;
            setParams(newParams);
        }
    };

    const onChangeFilter = (index: number): void => { 
        setParams({...params, severity: FILTERS[index].value})
    };
    const onPaginate = (skip: number, limit: number): void => {
        setParams({...params, ...{ skip, limit }})
    };

    // Effects
    useEffect(()=> {
        execute('incidents', { method: 'GET', params });
    }, [params]);

    useEffect(()=> {
        if(deleteData?.deletedCount > 0 && !loadingDelete ) {
            execute('incidents', { method: 'GET', params });
            dispatch(SetNotificationAction({
                title: "Incident(s) supprimé(s) avec succès",
                "message": 'Succès',
                timeToClose: 3000,
                type: 'success'
            }));
        }
    }, [deleteData?.deletedCount, loadingDelete])

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
    ];
    
    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.TYPE' }),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => type ? <BadgeUi  color={"primary"}><FormattedMessage id={`INCIDENT.${type.toUpperCase()}`}/></BadgeUi> : '-'
        },
        {
            title: intl.formatMessage({ id: 'COMMON.SEVERITY' }),
            dataIndex: 'severity',
            key: 'severity',
            render: (severity: string) => severity ? <BadgeUi  color={severityColor[severity.toUpperCase()]}><FormattedMessage id={`INCIDENT.${severity.toUpperCase()}`}/></BadgeUi> : '-'
        },
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: intl.formatMessage({ id: 'COMMON.DATE' }),
            dataIndex: 'date',
            key: 'date',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY') : '-'

        },
        {
            title: intl.formatMessage({ id: 'COMMON.UNIT' }),
            dataIndex: 'unit',
            key: 'unit'
        },
        {
            title: intl.formatMessage({ id: 'COMMON.DRIVER' }),
            dataIndex: 'driver',
            key: 'driver'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.UPDATED_BY' }),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (name: string) => {
                return name ? (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" text={name}></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>) : '-'
                
            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.LAST_UPDATE' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    return (
        <div id="incidentPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'INCIDENT' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'INCIDENT.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading || loadingDelete}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            // selectedVehicles.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div>
                                    <FormattedMessage id={filter.label}/>
                                    </div>)}
                            </Tabs>
                            // :
                            // <ActionTabBar selected={selectedVehicles} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' }) + " par nom"}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={data?.list ?? []}
                            // selected={[]}
                            // onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+(canPaginate ? 120 : 60)}
                        />
                    </CardBody>
                    {
                        canPaginate ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={data?.total ?? 0}
                                        skip={params.skip}
                                        limit={params.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                </CardUi>
            </Container>
        </div>
    );
}
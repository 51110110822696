import React, { useContext, useEffect, useState } from "react";
import {Input, Label, Table } from "reactstrap";
import { LayoutContext } from "../../context/layout-style-context";
import { EmptyUi } from "../basic/EmptyUi";
import { TableRowComponent } from "./row";
import classNames from "classnames";

type TableType = {
    columns: any[],
    selected?: string[],
    data: any[],
    className?: string,
    onChangeSelectedItem?: (keys: string[]) => void,
    minusHeight?: number
};

export const TableUi = ({className, columns, selected, data, minusHeight, onChangeSelectedItem}: TableType) => {
    const { height } = useContext(LayoutContext);
    const [selectedItem, setSelectedItem] = useState<string[]>(selected ?? []);
    const tableClassName = classNames('table-responsive', {[className ?? '']: !!className})
    useEffect(() => {
        if(selected && selected.length ===0) {
            setSelectedItem([]);
        }
    }, [selected]);
    
    const changeSelectedItems = (keys: string[]) => {
        
        setSelectedItem(keys);
        if(onChangeSelectedItem) {
            onChangeSelectedItem(keys);
        }
        
    }
    
    let isAllChecked: 'true' | 'false' | 'mixed' = 'false';
    if(selectedItem.length === data.length && data.length >0) {
        isAllChecked= 'true';
    } else if (selectedItem.length > 0){
        isAllChecked= 'mixed';
    }
    
    const toogleSelectAll = () => {
        if(isAllChecked === 'true') {
            changeSelectedItems([]);
        } else {
            changeSelectedItems(data.map(item => item.key));
        }
    }

    const toogleSelected = (key: string, isSelected: boolean) => {
        if(isSelected) {
            const newSelectedItem = [...selectedItem];
            newSelectedItem.push(key);
            changeSelectedItems(newSelectedItem);
        } else {
            changeSelectedItems(selectedItem.filter(item => item !== key));
        }
    }
    
    return (
        <React.Fragment>
            <div className={tableClassName} style={{height: `${height - (minusHeight ?? 0)}px`}}>
                <Table 
                    className="align-middle table-nowrap mb-0 table-header-fixed">
                    <thead className="table-light text-muted">
                        <tr>
                            
                            {
                                selected && <th scope="col" style={{"width": "42px"}}>
                                    <div className="form-check">
                                        <Input 
                                            className="form-check-input" 
                                            type="checkbox" 
                                            defaultValue="" 
                                            id="responsivetableCheck"
                                            checked={isAllChecked !== 'false'}
                                            onClick={toogleSelectAll}
                                        />
                                        <Label className="form-check-label" for="responsivetableCheck"></Label>
                                    </div>
                                </th>
                            }
                            { columns.map(column => <th scope="col" key={column.dataIndex} style={{zIndex: 1}}>{column.title}</th> ) }
                        </tr>
                    </thead>
            
                    {
                        data.length > 0 ? 
                            <tbody>
                                { data.map(row => <TableRowComponent 
                                        key={row.key} 
                                        data={row} 
                                        columns={columns} 
                                        toogleSelected={toogleSelected} 
                                        checked={selectedItem.includes(row.key)} 
                                        haveSelected={!!selected}
                                    />) }
                            </tbody> 
                        :
                            <EmptyUi />
                    }  
                </Table>
            </div>
           
        </React.Fragment>
    );
};
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { InputSelect } from "../../../../Components/Form/InputSelect";
import { useAppSelector } from "../../../../redux/hooks";
import { SetSelectValueAlarmAction } from "../../redux/events.actions";

type BooleanTypeProps = {
    position: number,
    selected?: string
}
export const BooleanType = ({position, selected}: BooleanTypeProps) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();

    const { manage } = useAppSelector((state) => state.eventsReducer);

    const options: any[] = [
        { label: 'Activé', value: 'true' },
        { label: 'Désactivé', value: 'false' },
    ];

    const defaultValue = options.find((option) => option.value === selected);
    const [selectedOption, setSelectedOption] = useState(defaultValue);

    const handleChange = (data: any) => {
        setSelectedOption(data);
        if(data) {
            dispatch(SetSelectValueAlarmAction({position, data: data.value}))
        } else {
            dispatch(SetSelectValueAlarmAction({position}));
        } 
    }


    return (
        <InputSelect 
            key={`select__${manage.events[0].type?.value}__${manage.events[0].subType?.value}`}
            label={intl.formatMessage({ id: 'ALARM.VALUE'})}
            options={options}
            defaultValue={selectedOption}
            onChange={handleChange}
        />        
    );
}

import React from 'react';
import useHotjar from 'react-use-hotjar';
import { HJID, HJSV } from '../env';

export const HotjarContext = React.createContext<null>(null);

type HotjarContextProviderType = {
  children: JSX.Element;
};
export const HotjarContextProvider: React.FunctionComponent<HotjarContextProviderType> = (
  props: HotjarContextProviderType,
) => {
  const { initHotjar } = useHotjar();
  

  React.useEffect(() => {
    if (HJID && HJSV) {
      initHotjar(parseInt(HJID), parseInt(HJSV), false);
    }
  }, [initHotjar]);

  return <HotjarContext.Provider value={null}>{props.children}</HotjarContext.Provider>;
};
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";
import { Input } from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { useEffect, useState } from "react";

type ReportPaginationType = {
    total: number;
    limit: number;
    skip: number;
    onPaginate: (skip: number, limit: number) => void;
}

const ReportPagination = ({total, skip, limit, onPaginate}: ReportPaginationType) => {
    const totalPages = Math.ceil(total / limit);

    const getPage = () => {
        const pageIndex = Math.ceil(skip / limit);
        return pageIndex + 1;
    };

    const [currentPage, setCurrentPage] = useState(getPage());
    const [inputValue, setInputValue] = useState<any>(currentPage);

    useEffect(() => {
        setCurrentPage(getPage());
        setInputValue(getPage())
    }, [skip]);

    // useEffect(() => {
    //     setCurrentPage(1);
    //     setInputValue(1)
    // }, [total]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(inputValue > 0 && inputValue <= totalPages) {
                gotToPage(inputValue);
            }  
        }, 500);
        
        return () => clearTimeout(delayDebounceFn);  
    }, [inputValue]);

    const next = () => {
        onPaginate(skip + limit, limit);
    };

    const back = () => {
        onPaginate(skip - limit, limit);
    };

    const first = () => {
        gotToPage(1);
    };

    const last = () => {
        gotToPage(totalPages);
    };


    const gotToPage = (page: number) => {
        if(page !== currentPage)
        onPaginate((page -1) * limit, limit);
    };

    const handleChangeInput = (e: any) => {
        const value = +e.target.value;
        if(value > 0 && value <= totalPages ) {
            setInputValue(value);
        }
    }

    const selectInput = (e: any) => {
        e.target.select()
    }

    return (
        <div className="d-flex align-items-center">
            <ButtonUi 
                disabled={(currentPage === 1)}
                        style={{ padding: "0.25rem 0.25rem" }} color="primary" size="sm" ghost onClick={first}>
                <FaAngleDoubleLeft />
            </ButtonUi>

            <ButtonUi 
                disabled={currentPage===1}
                style={{ padding: "0.25rem 0.25rem" }} color="primary" size="sm" ghost onClick={back}>
                <FaAngleLeft/>
            </ButtonUi>
            <div className="d-flex align-items-center">
                <Input 
                    className="ms-1 me-2" 
                    type="text" 
                    style={{ height: 25, width: 50}} 
                    value={inputValue}
                    onChange={handleChangeInput}
                    onClick={selectInput}
                    max={totalPages}
                />
                <span className="me-1">de {currentPage}/{totalPages}</span>
            </div>
            
            <ButtonUi 
                disabled={!(currentPage >= 1 && skip + limit < total)}
                style={{ padding: "0.25rem 0.25rem" }} color="primary" size="sm" ghost onClick={next}>
                <FaAngleRight />
            </ButtonUi>
            
            <ButtonUi 
                disabled={(currentPage === totalPages)}
                style={{ padding: "0.25rem 0.25rem" }} color="primary" size="sm" ghost onClick={last}>
                <FaAngleDoubleRight />
            </ButtonUi>
        </div>

    );
}

export default ReportPagination;
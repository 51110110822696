import React from 'react';
import { GoPrimitiveDot } from 'react-icons/go';

export function TrajectLimiter() {
    return (
        <div className="limiter py-2 px-1 d-flex flex-column justify-content-between align-items-center">
            <div className="d-flex">
                <GoPrimitiveDot size="22" />
            </div>
            <div className="line" />
            <div className="d-flex">
                <GoPrimitiveDot size="22" />
            </div>
        </div>
    );
}

import { axiosInstance } from '../../../redux/setupAxios';
import { FilterType } from '../../User/types';
import { SaveDriverType, UnitToAssignDriverInListType, UnitToAssignDriverType } from './types';

export async function saveDriver(
    event: SaveDriverType
): Promise<SaveDriverType> {
    const url = `/driver`;
    try {
        const res = await axiosInstance.post<SaveDriverType>(url, event);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateDriver(event: SaveDriverType): Promise<any> {
    const url = `/driver`;
    try {
        const res = await axiosInstance.patch<SaveDriverType>(url, event);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getDriver({_id}: {
    _id: string
}): Promise<any> {
    const url = `/driver/${_id}`;
    try {
        const res = await axiosInstance.get<SaveDriverType>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function bindDriver({driver, unit}: {
    driver: string;
    unit: string;
}): Promise<{ unitName: string, unitKey: string, driver: string }> {
    const url = `/driver/${driver}/assign/${unit}`;
    try {
        const res = await axiosInstance.post<{ unit: string }>(url);
        return {
            unitName: res.data.unit,
            unitKey: unit,
            driver
        };
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function unbindDriver({key}: {
    key: string
}): Promise<{ unAssignedDate: Date, type: any, key: string }> {
    const url = `/driver/${key}/unassign`;
    try {
        const res = await axiosInstance.delete<{ unAssignedDate: Date, type: any, key: string }>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}



export async function getDrivers(filter: FilterType): Promise<any> {
    let url = `/driver?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }

    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function fetchDriverByCode(
    code: string | undefined
): Promise<any> {
    try {
        const res = await axiosInstance.get<any>(
            `driver/${code}/exist`
        );
        if (res.data.found === false) {
            return { for: 'code', error: false };
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return {
        for: 'code',
        error: true,
        msg: "Code unique déjà utilisé !",
    };
}

export async function deleteDrivers({
    keys}: {
    keys: string[];
    
}): Promise<{ data: { deletedCount: number } }> {
    let params = `?`;
    keys.forEach((id, i) => {
        if (i !== 0) {
            params += `&keys[]=${id}`;
        } else {
            params += `keys[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/driver${params}`);
}

export async function getUnitsToAssign(filter: UnitToAssignDriverType): Promise<UnitToAssignDriverInListType[]> {
    let url = `vehicle/assign/driver`;
    if (filter.name && filter.name !== '') {
        url += `?name=${filter.name}`;
    }

    try {
        const res = await axiosInstance.get<UnitToAssignDriverInListType[]>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getDriversForInput({
    name,
}: {
    name: string;
}): Promise<{label: string, value: string}[]> {
    const url = `/driver/input?name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}
import {
    LoginEndType,
    LoginGenerateTokenType,
    LoginStartType,
    UserType,
} from './types';

export const LOGIN_START = '[LOGIN] START';
export const LOGIN_END = '[LOGIN] END';
export const LOGOUT = '[LOGIN] LOGOUT';
export const LOGIN_GENERATE_TOKEN = '[LOGIN] GENERATE_TOKEN';
export const ERROR = '[LOGIN] ERROR';

// Types
export type LoginStartActionType = {
    type: typeof LOGIN_START;
    payload: LoginStartType;
};
export function LoginStart(payload: LoginStartType): LoginStartActionType {
    return { type: LOGIN_START, payload };
}

export type LoginEndActionType = {
    type: typeof LOGIN_END;
    payload: LoginEndType;
};
export function LoginEnd(payload: LoginEndType): LoginEndActionType {
    return { type: LOGIN_END, payload };
}

export type LogoutActionType = { type: typeof LOGOUT };
export function Logout(): LogoutActionType {
    return { type: LOGOUT };
}

type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function Error(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

// Types
export type LoginGenerateTokenActionType = {
    type: typeof LOGIN_GENERATE_TOKEN;
    payload: LoginGenerateTokenType;
};
export function LoginGenerateToken(
    payload: LoginGenerateTokenType
): LoginGenerateTokenActionType {
    return { type: LOGIN_GENERATE_TOKEN, payload };
}

export const CHANGE_USER_TYPE = '[LOGIN] CHANGE_USER_TYPE';

export type ChangeUserType = {
    type: typeof CHANGE_USER_TYPE;
    payload: UserType;
};
export function ChangeUser(payload: UserType): ChangeUserType {
    return { type: CHANGE_USER_TYPE, payload };
}

export const SWITCH_USER_START_TYPE = '[LOGIN] SWITCH_USER_START_TYPE';

export type SwitchUserStartType = {
    type: typeof SWITCH_USER_START_TYPE;
    payload: { _id: string };
};
export function SwitchToUserStartAction(payload: {
    _id: string;
}): SwitchUserStartType {
    return { type: SWITCH_USER_START_TYPE, payload };
}

export const SWITCH_USER_END_TYPE = '[LOGIN] SWITCH_USER_END_TYPE';

export type SwitchUserEndType = {
    type: typeof SWITCH_USER_END_TYPE;
};
export function SwitchToUserEndAction(): SwitchUserEndType {
    return { type: SWITCH_USER_END_TYPE };
}

export const SWITCH_TO_MAIN_USER_START_TYPE =
    '[LOGIN] SWITCH_TO_MAIN_USER_START_TYPE';

export type SwitchToMainUserStartType = {
    type: typeof SWITCH_TO_MAIN_USER_START_TYPE;
};
export function SwitchToMainUserStartAction(): SwitchToMainUserStartType {
    return { type: SWITCH_TO_MAIN_USER_START_TYPE };
}

export const SWITCH_TO_MAIN_USER_END_TYPE =
    '[LOGIN] SWITCH_TO_MAIN_USER_END_TYPE';

export type SwitchToMainUserEndType = {
    type: typeof SWITCH_TO_MAIN_USER_END_TYPE;
};
export function SwitchToMainUserEndAction(): SwitchToMainUserEndType {
    return { type: SWITCH_TO_MAIN_USER_END_TYPE };
}

export type AuthActionsType =
    | LoginStartActionType
    | LoginEndActionType
    | LogoutActionType
    | LoginGenerateTokenActionType
    | ErrorActionType
    | ChangeUserType
    | SwitchUserStartType
    | SwitchUserEndType
    | SwitchToMainUserStartType
    | SwitchToMainUserEndType;

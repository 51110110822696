import React, { useContext } from "react"
import { LayoutContext } from "../../context/layout-style-context";
import { MapModule } from "../../modules/Map";
import { SocketIoModule } from "../../modules/Socket-io";
import Header from "./Header"
import { SideBar } from "./SideBar"
import MetaTags from 'react-meta-tags';
import { useAppSelector } from "../../redux/hooks";
import { IF } from "../../Components/If";

type VerticalLayoutType = {
    children: JSX.Element | any
}
export const VerticalLayout = ({children}: VerticalLayoutType) => {
    const { layoutMode, toogleDarkMode } = useContext(LayoutContext);
    const user = useAppSelector((state) => state.authReducer.user);
    const title = user?.company.title;
    const icoId = user?.company.ico;
    
    const onChangeLayoutMode = () => {
        if(toogleDarkMode){ toogleDarkMode();}
    }
    return(<React.Fragment>
        <div id="layout-wrapper">
            <IF condition={!!user?.company.title}>
                <MetaTags>
                    <title>--{`${title}`}--</title>
                    <link rel="icon" href={`/images/${icoId}`}/>
                </MetaTags>
            </IF>

            <SocketIoModule/>
            {/* <FormattedMessage id="COMMON.PREVIOUS" /> */}
            <Header layoutMode={layoutMode} onChangeLayoutMode={onChangeLayoutMode}/>
            <SideBar/>
            <MapModule/>
            <div className="main-content">
                {children}
            </div>
            
        </div>
        
    </React.Fragment>)
}
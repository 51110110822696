import { IoDocumentTextOutline } from "react-icons/io5";
import { RiBarcodeLine } from "react-icons/ri";
import {Tooltip} from "reactstrap";
import { InputFlatPickrUi } from "../../../Components/Form/InputFlatPickr";
import moment from 'moment-timezone';
import classNames from "classnames";
import { useState } from "react";

export type PickerObjectTime = {
    visible: boolean,
    timezone: string,
    onChangeDateRange: (props: {from: number, to: number}) => void,
    onChange: (dates: Date[]) => void
}
export const PickerTime = ({visible, timezone, onChangeDateRange, onChange}: PickerObjectTime) => {
    
    const PickerTimeClassname = classNames({
        [`d-none`]: !visible,
        'd-flex flex-row justify-content-center pe-3 border-bottom': true
    });


    const changeDateRange = (selectedDates: Date[], dateStr: string, instance: any) => {
        
            if (selectedDates.length == 1) {
                let threeDaysLater = new Date(
                    selectedDates[0].getTime() + 86400000 * 4
                )

                instance.config.minDate = selectedDates[0].toUTCString()
                const data =  threeDaysLater.getTime() - new Date().getTime()
                if( data >= 0) {
                    instance.config.maxDate = new Date()
                } else {
                    instance.config.maxDate = threeDaysLater.toUTCString()
                }
                
            } else if (selectedDates.length == 2) {
                instance.config.minDate = undefined
                instance.config.maxDate = new Date().toUTCString();
                const from = moment.tz(timezone)
                    .set({
                        year: selectedDates[0].getFullYear(),
                        month: selectedDates[0].getMonth(),
                        date: selectedDates[0].getDate(),
                        hour: 0, minute: 0, second: 0, millisecond: 0
                    }).unix()*1000;
                const to = moment.tz(timezone)
                    .set({
                        year: selectedDates[1].getFullYear(),
                        month: selectedDates[1].getMonth(),
                        date: selectedDates[1].getDate(),
                        hour: 23, minute: 59, second: 59, millisecond: 59
                    }).unix()*1000;
                    onChangeDateRange({from, to});
            }

    }
        

    return (
        <div className={PickerTimeClassname}>
            <InputFlatPickrUi
                className="form-control border-0 ps-4"
                placeholder="Cliquer pour choisir une Date !"
                options={{
                    mode: "range",
                    maxRange: 10,
                    dateFormat: "d M, Y",
                    maxDate: 'today',
                }}
                
                onChange={changeDateRange}
                onValueUpdate={(dara: any)=> {console.log(dara)}}
            />
                <div className="d-flex flex-row align-items-center border-start ps-2">
                    <div className="text-primary" id="TooltipExample">
                        <RiBarcodeLine size={20} />                       
                    </div>
                    <Tooltip placement="right" target="TooltipExample">
                        top
                    </Tooltip>
                    <div className="text-primary ms-1"> 
                        <IoDocumentTextOutline size={18} />
                    </div>
                    
                </div>
        </div>           
    );
}


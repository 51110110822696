import { AiOutlineCloseSquare } from "react-icons/ai";
import { CardHeader } from "reactstrap";
import { CardUi } from "../../../../../Components/basic/Card";
import { CardBodyUi } from "../../../../../Components/basic/CardBodyUi";
import { useNavigate, useParams, useSearchParams, useLocation } from "react-router-dom";
import { ViewPointDetailComponent } from "../../../../../Components/advanced/Driver/Driver-view-point";
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';
import { Avatar } from "../../../../../Components/basic/avatar/avatar";
import { RateUiComponent } from "../../../../../Components/basic/RateUi";
import { IF } from "../../../../../Components/If"
import { axiosInstance } from "../../../../../redux/setupAxios";
import { useAppSelector } from "../../../../../redux/hooks";
import { useState, useEffect } from "react";
import { formatDate } from "../../../../../helpers/dateHelper";

const fetchReviews = async (key, page) => {
    let url = `/driver/${key}/reviews`;
   
        try {
            const { data } = await axiosInstance.get(
              `${url}?page=${page}`
            );
        
            return data ?? {list: [], total: 1};
          } catch (error) {}
  };


export const DriverViewPoint = () => {
    const [queryParameters] = useSearchParams()
    const {key} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authReducer);
    const [pageCount, setPageCount] = useState(1);
    const [reviews, setReviews] = useState([]);
    const [total, setTotal] = useState(0);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    const showReviews = queryParameters.get('reviews') === '1' && key
    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const close = () => navigate(location.pathname);
    const clearData = () => {
        setPageCount(1);
        setReviews([]);
        setTotal(0)
    }
    function isRowLoaded({ index }) {
        return !!reviews[index];
    }

    const firstPageLoad =  async () => {
        if(showReviews) {            
            const reviews = await fetchReviews(key, pageCount);            
            setReviews(reviews.list);
            setTotal(reviews.total)
            setPageCount(pageCount => pageCount + 1);
        }
    }
    const handleNewPageLoad = async () => {
        if(showReviews) {
            setIsNextPageLoading(true);
            const reviews = await fetchReviews(key, pageCount);
            setPageCount(pageCount => pageCount + 1);
            
            setReviews(currentReviews => [
            ...currentReviews,
            ...reviews.list
            ]);
            setTotal(reviews.total)
            setIsNextPageLoading(false);
        }

        
        return;
      };

    const loadMoreRows = isNextPageLoading ? () => {} : handleNewPageLoad;

    
    
    useEffect(() => {
        firstPageLoad();
        return ()=> {
            clearData();
        }
      }, [showReviews]);


    function rowRenderer ({ key, index, style, parent}) {
        const name = reviews[index]?.updatedBy?.length > 0 ? reviews[index]?.updatedBy[0] : 'Utilisateur supprimé';
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={reviews[index]?._id ?? 1}
                rowIndex={index}
                parent={parent}
            >
                {({measure, registerChild}) => {
                    return (
                        <div ref={registerChild} key={reviews[index]?._id ?? 1} style={style} className="border-bottom">
                            <div className="p-3">
                                <div className="d-flex flex-row">
                                    <Avatar 
                                        text={name} 
                                        size="sm" 
                                        color="primary"
                                        className="me-3"
                                    />
                                    <div className="d-flex flex-column justify-content-center fs-12">
                                        <label className="mb-0">{name}</label>
                                        <div>
                                            <RateUiComponent mark={reviews[index]?.mark} size={14} disabled allowColor/>
                                            <span className="ms-2 fs-11" style={{fontWeight: 500}}>{ user?.timezone ? formatDate(reviews[index]?.rankingTime, user.timezone, 'DD/MM/YYYY') : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                                {
                                    !!reviews[index]?.comment && <p className="mb-0 mt-1 fs-12" style={{marginLeft: "4rem"}}>{reviews[index]?.comment}</p>
                                }
                                
                            </div>
                        </div> 
                )}}
            </CellMeasurer>
        )
    }

    return (
        <IF condition={showReviews}>
            <CardUi className="ms-1 details-driver">    
                <CardHeader className="d-flex align-items-center justify-content-between mb-0">
                    
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span>Détails des avis </span>
                    </h5>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={close}
                        onKeyDown={close}
                        className="p-1 ghost btn btn-primary btn-sm rounded-circle"
                    >
                        <AiOutlineCloseSquare size={20} />
                    </div>
                </CardHeader>       
                <CardBodyUi className="p-0 d-flex flex-column" style={{with: "300px"}}>
                    <ViewPointDetailComponent className="p-3 border-bottom"/> 
                    <div className="d-flex" style={{flex: 1}}>
                        <InfiniteLoader
                            isRowLoaded={isRowLoaded}
                            loadMoreRows={loadMoreRows}
                            rowCount={total}
                        >
                            {({ onRowsRendered, registerChild }) => (
                                <AutoSizer>
                                    {
                                        ({ height, width }) => {
                                            return(
                                                <List
                                                    ref={registerChild}
                                                    height={height}
                                                    width={width}
                                                    rowCount={total}
                                                    deferredMeasurementCache={cache}
                                                    rowHeight={cache.rowHeight}
                                                    rowRenderer={rowRenderer}
                                                    onRowsRendered={onRowsRendered}
                                                />
                                            )}
                                    }
                                </AutoSizer>
                            )}
                        </InfiniteLoader>
                    </div>
                </CardBodyUi>
            </CardUi> 
        </IF>
    );
    
}

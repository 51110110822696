import { DriverList } from "./List/DriverList";
import { useMatch } from "react-router-dom";
import { DriverDetails } from "./List/Details";
import { DriverViewPoint } from "./List/Details/DriverViewPoint";


const DriverPage = () => {
    const detailsMatch = useMatch({path: "/drivers/:_id/details"});
    const actionDetailsMatch = useMatch({path: "/drivers/:_id/details/:action"});
    const showDriverDetails = !!detailsMatch || !!actionDetailsMatch;
    const showDriverList = !showDriverDetails;
    return (
        <div className="page-content object-page" >
            <div className="d-flex flex-row">
                { showDriverList && <DriverList /> }
                { showDriverDetails &&  <DriverDetails /> }
                <DriverViewPoint />
            </div>
        </div>
    );
}

export default DriverPage;
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import {
    AssignVehicleAlarmEndAction,
    ASSIGN_VEHICLE_ALARM_START,
    CreateEventEnd,
    CreateEventStartType,
    CREATE_EVENT_START,
    DeleteEventsEnd,
    DeleteEventsStartType,
    DELETE_EVENTS_START,
    ErrorEvents,
    EventsActionType,
    GetEventsEnd,
    GetEventsStartType,
    GetEventUpdateEnd,
    GetEventUpdateStartType,
    GET_EVENTS_START,
    GET_EVENT_UPDATE_START,
    SetStatusEventsEnd,
    SetStatusEventsStartType,
    SetVehicleAssignStartActionType,
    SET_STATUS_EVENTS_START,
} from './events.actions';
import {
    assignVehiclesToEvent,
    deleteEvents,
    GetEvent,
    getEvents,
    saveEvent,
    updateEvent,
    UpdateEventsStatus,
} from './events.service';
import { ManageEvenType } from './types';



export const CreateEventEpic = (
    action$: ActionsObservable<CreateEventStartType>,
    state$: { value: RootState }
): Observable<EventsActionType | any> =>
    action$.pipe(
        ofType(CREATE_EVENT_START),
        switchMap((action: CreateEventStartType) => {

            const { manage } = state$.value.eventsReducer;
            const {name, description, ...oldData} = manage;
            const interval = action.payload.interval && +action.payload.interval > 2 ? +action.payload.interval : 3
            const data: ManageEvenType = {...oldData, name: action.payload.name, interval};
            if(action.payload.description) {
                data.description = action.payload.description
            }
            return from(
                manage._id ? updateEvent(data) : saveEvent(data)
            ).pipe(
                mergeMap((result) => {
                    return of(
                        CreateEventEnd({
                            ...state$.value.eventsReducer.manage,
                            ...result,
                            ...{
                                _id: manage._id ? manage._id : result.insertedId,
                                updatedAt: result.updatedAt,
                                updatedBy: result.updatedBy,
                                modifiedCount: result.modifiedCount,
                            },
                        }),                         
                        SetRedirectAction('/alarms'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                    );
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

export const GetEventsEpic = (
    action$: ActionsObservable<GetEventsStartType>,
    state$: { value: RootState }
): Observable<EventsActionType> =>
    action$.pipe(
        ofType(GET_EVENTS_START),
        switchMap((action: GetEventsStartType) => {
            return from(getEvents(action.payload)).pipe(
                map((result) => {
                    return GetEventsEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

export const DeleteEventsEpic = (
    action$: ActionsObservable<DeleteEventsStartType>,
    state$: { value: RootState }
): Observable<EventsActionType> =>
    action$.pipe(
        ofType(DELETE_EVENTS_START),
        switchMap((action: DeleteEventsStartType) => {
            return from(deleteEvents({ _ids: action.payload })).pipe(
                map((result) => {
                    return DeleteEventsEnd(result.data);
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

export const UpdateEventsStatusEpic = (
    action$: ActionsObservable<SetStatusEventsStartType>,
    state$: { value: RootState }
): Observable<EventsActionType> =>
    action$.pipe(
        ofType(SET_STATUS_EVENTS_START),
        switchMap((action: SetStatusEventsStartType) => {
            return from(UpdateEventsStatus(action.payload)).pipe(
                map((result) => {
                    return SetStatusEventsEnd({
                        ...result.data,
                        ...{ status: action.payload.status },
                    });
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

export const GetEventEpic = (
    action$: ActionsObservable<GetEventUpdateStartType>,
    state$: { value: RootState }
): Observable<EventsActionType> =>
    action$.pipe(
        ofType(GET_EVENT_UPDATE_START),
        switchMap((action: GetEventUpdateStartType) => {
            return from(GetEvent(action.payload)).pipe(
                map((result) => {
                    return GetEventUpdateEnd({ result: result.data });
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

export const AssignVehiclesToAlarmEpic = (
    action$: ActionsObservable<SetVehicleAssignStartActionType>,
    state$: { value: RootState }
): Observable<EventsActionType> =>
    action$.pipe(
        ofType(ASSIGN_VEHICLE_ALARM_START),
        switchMap((action: SetVehicleAssignStartActionType) => {
            const {_id} = (state$.value.eventsReducer.affectTo ?? {_id: ''}) as {_id: string};
            return from(assignVehiclesToEvent(_id, action.payload)).pipe(
                map((result) => {
                    return AssignVehicleAlarmEndAction({
                        ...result,
                        _id,
                    });
                }),
                catchError((err) => {
                    return of(ErrorEvents(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorEvents(err.msg));
        })
    );

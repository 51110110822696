import { useAxios } from "../useAxios";

export type ReportItems = {
  "value": string, "label": string,
}
type ReportTemplatesResult = {
    list: ReportItems[];
    total: number;
}

type UseFetchReportItemsResponse = { 
  reportItems: ReportTemplatesResult;
  reportItemsLoading: boolean;
  error?: string;
  initReportItems: () => void;
  fetchReportItems: (template: string) => void;
  cancel: () => void;
}

type UseFetchReportItemsProps = {
  manual?: boolean
}
export function useFetchReportItems(props: UseFetchReportItemsProps): UseFetchReportItemsResponse {
  const URL = "report-template/items";
  const INIT_VALUE = { list: [], total: 0 };
  const { execute, data, cancel, error, loading, init } = useAxios<ReportTemplatesResult>(props.manual);

  const fetchReportItems = (template: string) => {
    execute(URL, { method: "GET", params: { template } });
  };

  return { 
    initReportItems: init,
    reportItems: data ?? INIT_VALUE, 
    fetchReportItems, 
    cancel, 
    reportItemsLoading: loading, 
    error: error
  };
}

import { useState } from "react";
import { ListMapComponent } from "../../Components/List/ListMap/ListMap";
import HeaderListMapComponent from "../../Components/List/ListMap/ListMapHeader";
import { ListMapSearchComponent } from "../../Components/List/ListMap/ListMapSearch";
import { ListMapData } from "../../Components/List/ListMap/ListMapData";
import { HEIGHT_LIST_MAP_FOOTER, ListMapFooter } from "../../Components/List/ListMap/ListMapFooter";
import { IF } from "../../Components/If";
import { useNavigate } from "react-router-dom";
import ManagePassengerModal from "./manage-passenger-modal";
import { useFetchPassengers } from "../../common/hooks/axios/passenger/useFetchPassengers";
import PassengerListItem from "./components/passenger-list-item";
import { PassengerAction } from "./types";
import PassengerGroup from "./group-management";

const CHILDREN_HEIGHT = 240;
const LIMIT = 25;

const ListPassengerPage = () => {
    const navigate = useNavigate();
    const [isVisible, setVisibility] = useState<boolean>(true);
    const [filter, setFilter] = useState<{"pagination[limit]": number, "pagination[skip]": number, name?: string, group?: string}>({"pagination[limit]": LIMIT, "pagination[skip]": 0});
    const { passengers, fetchPassengers, fetchPassengersLoading } = useFetchPassengers({manual: false, limit: filter["pagination[limit]"]})

    const showFooter = Math.ceil(passengers.total / LIMIT) > 1;

    // Functions
    const refetchData = (skip: number, params?: {searchBy?: string, group?: string}) => {
        const newFilter = {...filter, 'pagination[limit]': LIMIT, 'pagination[skip]': skip };
        if(params?.hasOwnProperty('group')) { newFilter.group = params.group; }
        if(params?.hasOwnProperty('searchBy')) { newFilter.name = params.searchBy; }
        setFilter(newFilter);
        fetchPassengers(newFilter);
    };

    const onSelectGroup = (key: string | null) => {
        refetchData(0, {group: key ?? undefined});
    }

    const onSearch = ({searchBy}: {searchBy?: string}) => { 
        refetchData(0, {searchBy});
    }

    const onPaginate = (skip: number, limit: number) => { 
        refetchData(skip);
    }

    // Events
    const toggleVisibility = () => { setVisibility(!isVisible); }

    
    const onClickCreate = () => navigate(`/passengers?action=add`); 

    const onEvent = async (action: string, key: string) => { 
        if(PassengerAction.DETAILS === action) {
            navigate(`/passengers/${key}/details`)
        }
    }



    return (
        <div className="page-content object-page" >
            <ListMapComponent isVisible={isVisible} toggleVisibility={toggleVisibility} loading={fetchPassengersLoading}>
                <HeaderListMapComponent title="Liste des passagers" toggleVisibility={toggleVisibility}/>
                <PassengerGroup onSelect={(key: string | null) => onSelectGroup(key)}/>
                <ListMapSearchComponent onClickCreate={onClickCreate} onSearch={(data: any) => onSearch(data)} />
                <ListMapData 
                    Component={PassengerListItem}
                    onEvent={onEvent}
                    data={passengers.list} 
                    minusHeight={CHILDREN_HEIGHT + (showFooter ? HEIGHT_LIST_MAP_FOOTER : 0)} 
                />

                <IF condition={showFooter}>
                    <ListMapFooter 
                        total={passengers.total}
                        skip={filter["pagination[skip]"]}
                        limit={filter["pagination[limit]"]}
                        onPaginate={onPaginate}
                    />
                </IF>
            </ListMapComponent>
            <ManagePassengerModal />
        </div>
    );    
}

export default ListPassengerPage;
import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { Label } from "reactstrap";
import { InputSelect } from "../../../../Components/Form/InputSelect";


type SelectUnitProps = {
    units: {label: string, value: string}[],
    onChange: (unit: {label: string, value: string}) => void
}

const SelectUnit = (props: SelectUnitProps) => {
    const [selected, setSelected] = useState<{label: string, value: string} | null>();
    
    useEffect(()=> {
        if(props.units.length) { 
            props.onChange(props.units[0]); 
            setSelected(props.units[0]);
        }
    }, []);

    if(!selected) {
        return <></>
    }
    return (
        
        <div className="m-3 d-flex flex-column cursor-pointer">
            <Label className="d-flex flex-row align-items-center cursor-pointer">
                <span>Unité séléctionnée:</span> <FaRegEdit className="text-info ms-2"/>
            </Label>
            <InputSelect 
                // key={`select__${manage.events[0].type?.value}__${manage.events[0].subType?.value}`}
                options={props.units}
                defaultValue={props.units[0]}
                onChange={props.onChange as any}
            />  
        </div>
    );
}

export default SelectUnit;
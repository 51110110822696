import { Label } from "reactstrap";
import { ButtonUi } from "../../../../../Components/basic/ButtonUi";


export const RoadTableFooter = (props: any) => {
  return (
    <div
    style={{
      height: 50,
      padding: '0 20px',
      textAlign: 'center',
      position: 'sticky',
    }}
    className="d-flex justify-content-center align-items-center bg-white"
  >
    <div
      className="d-flex d-row"
      style={{ width: 'calc(100vw - 300px)', minWidth: '30vw', gap: 20 }}
    >
      <Label className="form-check-label">
        {/* {MockUpData.summary.routes - MockUpData.summary.unassigned}/
          */}
        { props?.optimizedRoad?.routes.length } route(s) Programmée(s)
      </Label>
      <Label className="form-check-label">
        { props?.optimizedRoad?.summary.duration / 60} Minutes de travail
      </Label>
      <Label className="form-check-label">
        { (props?.optimizedRoad?.summary.distance / 1000).toFixed(2) } Total km
      </Label>
      <Label className="form-check-label text-success">
        { props?.optimizedRoad?.type === "end" ? props?.optimizedRoad.summary.pickup[0] : props?.optimizedRoad.summary.delivery[0]} assigné(s)
      </Label>
      <Label className="form-check-label text-danger">
        { props?.optimizedRoad?.summary.unassigned} Non assigné(s)
      </Label>
    </div>
    <div
      className="d-flex d-row justify-content-end align-items-center"
      style={{ gap: 20 }}
    >
      <ButtonUi color="danger" size="sm">
        {/* <img src={Control} className="me-2" /> */}
        Assigner
      </ButtonUi>
    </div>
  </div>
  );
};

import React, { createContext, useEffect, useState } from 'react';
import { changeHTMLAttribute, DATA_LAYOUT_MODE } from '../Layouts/helpers';

type LayoutContextPropsType = {
    children: JSX.Element
}

export type layoutModeType = 'dark' | 'light';
type LayoutContextType = {
    layoutMode: layoutModeType,
    height: number,
    width: number,
    toogleDarkMode?: ()=>void
}
export const LayoutContext = createContext<LayoutContextType>({
    layoutMode: 'light',
    height: window.innerHeight,
    width: window.innerWidth
});

export function LayoutStyleContextProvider({
    children,
}: LayoutContextPropsType): JSX.Element {
    const [layoutMode, setLayoutMode] = useState<layoutModeType>('light');
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const updateDimensions = (): void => {
        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const toogleLayoutMode = (newLayoutMode: layoutModeType) => {
        setLayoutMode(newLayoutMode);
        changeHTMLAttribute(DATA_LAYOUT_MODE, newLayoutMode);
    }

    function initLayoutMode() {
        const layoutMode = (localStorage.getItem(DATA_LAYOUT_MODE) || 'light') as layoutModeType;
        toogleLayoutMode(layoutMode)
    }

    const toogleDarkMode = () => {
        const mode = layoutMode === 'dark' ? 'light' : 'dark';
        toogleLayoutMode(mode);
    }


    useEffect(() => {
        initLayoutMode();
    }, []);

    const value = React.useMemo(
        () => ({ layoutMode, toogleDarkMode, width, height }),
        [layoutMode, height, width]
    );

    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
}



import { AffectToType } from '../../User/types';

import {
    ADD_NEW_EVENT,
    ASSIGN_VEHICLE_ALARM_END,
    ASSIGN_VEHICLE_ALARM_START,
    CHANGE_EVENT_OPERATION,
    CLEAR_EVENT_CREATION,
    CREATE_EVENT_END,
    CREATE_EVENT_START,
    DELETE_EVENTS_END,
    DELETE_EVENTS_START,
    ERROR,
    EventsActionType,
    GET_EVENTS_END,
    GET_EVENTS_START,
    GET_EVENT_UPDATE_END,
    GET_EVENT_UPDATE_START,
    REMOVE_NEW_EVENT,
    SET_EVENT_LOGIC,
    SET_EVENT_NOTIFICATION_TYPE,
    SET_SELECTED_EVENTS,
    SET_SELECTED_ZONE_EVENT_TYPE,
    SET_SELECT_SUB_TYPE_ALARM,
    SET_SELECT_TYPE_ALARM,
    SET_SELECT_VALUE_ALARM,
    SET_STATUS_EVENTS_END,
    SET_STATUS_EVENTS_START,
    SET_TIMES_EVENT_TYPE,
    TOOGLE_AFFECT_TO,
} from './events.actions';
import {
    EventInList,
    ManageEvenType,
} from './types';

type StateType = {
    loading: boolean;
    affectTo?: AffectToType;
    manage: ManageEvenType;
    selectedEvents: string[];
    filter: {
        limit: number;
        skip: number;
    };
    events: {
        list: EventInList[];
        total: number;
    };

};

const INITIAL_STATE: StateType = {
    loading: false,
    events: {
        list: [],
        total: 0,
    },
    selectedEvents: [],
    manage: {
        name: '',
        timesSelected: [],
        events: [
            {key: new Date().getTime()},
        ]
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function eventsReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: EventsActionType
): StateType {
    switch (action.type) {
        case CREATE_EVENT_START:
        case GET_EVENTS_START:
        case SET_STATUS_EVENTS_START:
        case DELETE_EVENTS_START:
        case GET_EVENT_UPDATE_START:
        case ASSIGN_VEHICLE_ALARM_START: {
            return {
                ...state,
                loading: true,
            };
        }

        case CREATE_EVENT_END: {
            const list = [...state.events.list];
            const { name, _id, updatedBy, updatedAt, modifiedCount } =
                action.payload;
            const updatedEvent = {
                key: _id,
                name: name || '',
                updatedBy,
                updatedAt,
            };

            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const eventIndex = state.events.list.findIndex(
                        (event) => event.key === _id
                    );
                    if (eventIndex !== -1) {
                        list[eventIndex] = {
                            ...list[eventIndex],
                            ...updatedEvent,
                        };
                    }
                }
            } else {
                list.unshift({
                    ...updatedEvent,
                    ...{
                        status: false,
                        assigned: false,
                    },
                });
            }

            return {
                ...state,
                loading: false,
                events: {
                    list,
                    total: state.events.total + 1,
                },
                selectedEvents: [],
            };
        }

        // events
        case SET_SELECT_TYPE_ALARM: {
            const {data, position} = action.payload;
            const newEvents = [...state.manage.events];
            const key = new Date().getTime();
            if(data) {
                newEvents[position] = {
                    key,
                    type: data,
                    logic: '&&'
                }

            } else {
                newEvents[position] = {key}
            }
            return {
                ...state,
                loading: false,
                manage: {
                    ...state.manage,
                    events: newEvents
                }
            }
        }

        case SET_SELECT_SUB_TYPE_ALARM: {
            const {data, position} = action.payload;
            const newEvents = [...state.manage.events];
            const {type, key} = newEvents[position];
            if(data) {
                newEvents[position].subType = data;
                
            } else {
                newEvents[position] = {
                    key, type
                }
            }
            return {
                ...state,
                loading: false,
                manage: {
                    ...state.manage,
                    events: newEvents
                }
            }
        }

        case SET_SELECT_VALUE_ALARM: {
            const {data, position} = action.payload;
            const newEvents = [...state.manage.events];
            newEvents[position].value = data;
            return {
                ...state,
                loading: false,
                manage: {
                    ...state.manage,
                    events: newEvents
                }
            }
        }

        case CHANGE_EVENT_OPERATION: {
            const {operation, position} = action.payload;
            const newEvents = [...state.manage.events];
            if(operation) {
                newEvents[position].operation = operation;
                return {
                    ...state,
                    loading: false,
                    manage: {
                        ...state.manage,
                        events: newEvents
                    }
                }
            }
            return state;
        }

        case SET_EVENT_LOGIC: {
            const { position, logic } = action.payload;

            const events = [...state.manage.events];
            events[position].logic = logic;

            return {
                ...state,
                loading: false,
                manage: {
                    ...state.manage,
                    events,
                },
            };
        }

        case ADD_NEW_EVENT: {
            const events = [...state.manage.events];
            events.push({logic: '&&', key: new Date().getTime()});
            return {
                ...state,
                manage: {
                    ...state.manage,
                    events,
                },
                loading: false,
            };
        }

        case REMOVE_NEW_EVENT: {
            const { position } = action.payload;
            let tmpEventsActive = state.manage.events;
            if (position === 0 && tmpEventsActive.length > 1) {
                const event = { ...tmpEventsActive[position + 1] };
                if (event.logic) {
                    delete event.logic;
                    tmpEventsActive[action.payload.position + 1] = event;
                }
            }

            if(position > 0) {
                tmpEventsActive = tmpEventsActive.filter(
                    (event, i: number) => i !== position
                );
            }
            

            return {
                ...state,
                manage: {
                    ...state.manage,
                    events: tmpEventsActive,
                },
                loading: false,
            };
        }

        case SET_SELECTED_ZONE_EVENT_TYPE: {
            const { position, zones } = action.payload;
            const events = [...state.manage.events];
            const event = events[position];
            if(zones.length > 0) {
                event.zones = zones
            } else {
                delete event.zones;
            }
            events[position] = event;
            return {
                ...state,
                loading: false,
                manage: {
                    ...state.manage,
                    events,
                },
            };
        }

        case SET_EVENT_NOTIFICATION_TYPE: {
            const notification = { ...state.manage.notification };
            const { key, value } = action.payload;
            notification[key] = value;
            return {
                ...state,
                manage: {
                    ...state.manage,
                    notification,
                },
            };
        }

        case SET_TIMES_EVENT_TYPE:
            return {
                ...state,
                manage: {
                    ...state.manage,
                    timesSelected: action.payload,
                },
            };


        case SET_SELECTED_EVENTS:
            return {
                ...state,
                selectedEvents: action.payload,
            };

        case GET_EVENTS_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                events: result,
                filter,
                loading: false,
            };

        case SET_STATUS_EVENTS_END: {
            const { modifiedCount, updatedIds, status } = action.payload;

            if (modifiedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedEvents: [],
                    events: {
                        ...state.events,
                        list: state.events.list.map((event) => {
                            const newEvent = { ...event };
                            if (updatedIds.includes(event.key)) {
                                newEvent.status = status;
                            }
                            return newEvent;
                        }),
                    },
                };
            }
            return state;
        }

        case GET_EVENT_UPDATE_END:
            return {
                ...state,
                manage: action.payload.result,
                loading: false,
            };

        case DELETE_EVENTS_END: {
            const { deletedCount, deletedIds } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedEvents:
                        state.selectedEvents.filter(
                            (_id) => !deletedIds.includes(_id)
                        ) || [],
                    events: {
                        total: state.events.total - deletedCount,
                        list:
                            state.events.list.filter(
                                (event) => !deletedIds.includes(event.key)
                            ) || [],
                    },
                };
            }
            return { ...state, loading: false };
        }

        case CLEAR_EVENT_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
                loading: false,
            };
        }


        case TOOGLE_AFFECT_TO: {
            const newState = { ...state };
            if (newState.affectTo) {
                delete newState.affectTo;
            } else {
                newState.affectTo = action.payload;
            }
            return newState;
        }

        case ASSIGN_VEHICLE_ALARM_END: {
            const { _id, modifiedCount } = action.payload;
            const list = [...state.events.list];
            if (modifiedCount !== undefined) {
                const eventIndex = state.events.list.findIndex(
                    (event) => event.key === _id
                );
                const updatedUser: any = { ...action.payload, ...{ key: _id } };
                if (eventIndex !== -1) {
                    list[eventIndex] = {
                        ...list[eventIndex],
                        ...updatedUser,
                    };
                }
            }

            return {
                ...state,
                loading: false,
                events: {
                    ...state.events,
                    list,
                },
                selectedEvents: [],
                affectTo: INITIAL_STATE.affectTo,
            };
        }
        
        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        default:
            return state;
    }
}

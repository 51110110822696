import { axiosInstance } from '../../../redux/setupAxios';
import {
    DeleteSensorType,
    FilterVehicleType,
    GetUpdateSensorType,
    GroupsVehiclesToAssignType,
    ManageVehicleType,
    UnitTripType
} from '../types';

export async function saveVehicle(vehicle: ManageVehicleType): Promise<any> {
    const url = `/vehicle`;
    try {
        const res = await axiosInstance.post<ManageVehicleType>(url, vehicle);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateVehicle(
    vehicle: ManageVehicleType
): Promise<ManageVehicleType> {
    const url = `/vehicle`;
    try {
        const res = await axiosInstance.patch<any>(url, vehicle);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getVehicles(filter: FilterVehicleType): Promise<any> {
    let url = `/vehicle?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getVehicle({ _id }: { _id: string }): Promise<any> {
    const url = `/vehicle/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteVehicles({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/vehicle${params}`);
}

export async function getGroupsForVehicle({
    name,
}: {
    name: string;
}): Promise<any> {
    const url = `/group/vehicle?name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function getVehicleToAssign({
    name,
}: {
    name: string;
}): Promise<GroupsVehiclesToAssignType> {
    const url = `/vehicle/assign?name=${name}`;
    try {
        const res = await axiosInstance.get<GroupsVehiclesToAssignType>(url);
        return res.data;
    } catch (e: any) {
        return { groups: [], units: [] };
    }
}

export async function getAssignedVehicle(_id: string): Promise<string[]> {
    const url = `/account/${_id}/vehicles`;
    try {
        const res = await axiosInstance.get<string[]>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function getHistoryDetails(_id: string | undefined): Promise<any[]> {
    if(_id) {
        const url = `/vehicle/${_id}/history/details?from=1651017600000&to=1651103999000`;
        try {
            const res = await axiosInstance.get<any[]>(url);
            return res.data;
        } catch (e: any) {
            
        }
    }
    return [];
}

export async function findSensorsType(tracer: string, typedText?: string): Promise<any[]> {
    let url = `/sensors/types/${tracer}`;
    if(typedText){
        url = `${url}?name=${typedText}`
    }
    try {
        const res = await axiosInstance.get<any[]>(url);
        return res.data;
    } catch (e: any) {
        
    }
    return [];
}

export async function findSensorsSubType(vehicleId: string | undefined, sensor: {tracer: string, type?: string}, typedText?: string): Promise<any[]> {
    const {tracer, type} = sensor;
    if(type && vehicleId) {
        let url = `/sensors/${tracer}/subtype/${type}/vehicle/${vehicleId}`;
        if(typedText){
            url = `${url}?name=${typedText}`
        }
        try {
            const res = await axiosInstance.get<any[]>(url);
            return res.data;
        } catch (e: any) {
            
        }
    }

    return [];
}

export async function findSensorsIo(sensor: {tracer: string, type?: string, subType?: string}, typedText?: string): Promise<any[]> {
    const {tracer, type, subType} = sensor;
    if(type && subType) {
        let url = `/sensors/${tracer}/type/${type}/subtype/${subType}`;
        if(typedText){
            url = `${url}?name=${typedText}`
        }
        try {
            const res = await axiosInstance.get<any[]>(url);
            return res.data;
        } catch (e: any) {
            
        }
    }

    return [];
}

export async function createSensor(vehicleId: string, sensor: any): Promise<any> {

    let url = `/vehicle/${vehicleId}/sensor`;
    
    try {
        const res = await axiosInstance.post<any[]>(url, sensor);
        return res.data;
    } catch (e: any) {
        
    }
}

export async function updateSensor(vehicleId: string, sensorId: string, info: any): Promise<any> {

    const url = `/vehicle/${vehicleId}/sensor/${sensorId}`;
    try {
        const res = await axiosInstance.patch<any[]>(url, info);
        return res.data;
    } catch (e: any) {
        
    }
}

export async function toogleSensorTiming(vehicleId: string| undefined, sensorId: string, value: boolean): Promise<any> {

    if(vehicleId) {
        const url = `/vehicle/${vehicleId}/sensor/${sensorId}/timing`;

        try {
            const res = await axiosInstance.patch<any>(url, {value});
            return res.data;
        } catch (e: any) {
            
        }
    }
   
}





export async function getSensors(vehicleId: string): Promise<any> {

    let url = `/vehicle/${vehicleId}/sensor`;
    
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        
    }
}

export async function fetchSensorByName(vehicleId: string, sensorName: string): Promise<any> {

    let url = `/vehicle/${vehicleId}/sensor/${sensorName}`;
    
    try {
        const res = await axiosInstance.get<any>(url);
        if (res.data.found === false) {
            return { for: 'param', error: false };
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return {
        for: 'param',
        error: true,
        msg: "Paramètre  déjà utilisé !",
    };
}


export async function deleteSensor(
    {_id, sensorId}: DeleteSensorType
): Promise<{ modifiedCount: number; }> {
    const url = `/vehicle/${_id}/sensor/${sensorId}`;
    try {
        const res = await axiosInstance.delete<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getSensor(
    {_id, sensorId}: DeleteSensorType
): Promise<GetUpdateSensorType> {
    const url = `/vehicle/${_id}/sensorId/${sensorId}`;
    try {
        const res = await axiosInstance.get<any>(url);
        const { calibration, fuel, ...manage } = res.data;
        const result: GetUpdateSensorType  = { manage };
        if(calibration) {
            result.calibration = calibration;
        }
        if(fuel) {
            result.fuel = fuel;
        }
        return result;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateFuelConfig(vehicleId: string | undefined, fuel: {drill: number, fill: number} | undefined): Promise<any> {
    const url = `/vehicle/${vehicleId}/fuel`;

    try {
        const res = await axiosInstance.patch<any[]>(url, fuel);
        return res.data;
    } catch (e: any) {
        
    }
}

export async function updateTripConfig(vehicleId: string | undefined, trip: UnitTripType): Promise<any> {
    const url = `/vehicle/${vehicleId}/trip`;

    try {
        const res = await axiosInstance.patch<any[]>(url, trip);
        return res.data;
    } catch (e: any) {
        
    }
}


export async function getFuelConfig(vehicleId: string | undefined): Promise<any> {
    const url = `/vehicle/${vehicleId}/fuel/config`;
    try {
        const res = await axiosInstance.get<any[]>(url);
        if(Object.keys(res.data).length > 0)
            return res.data;
    } catch (e: any) {
        
    }
}







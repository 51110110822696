import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {  Row, Col, FormGroup, Input, Label } from "reactstrap";
import { ManageMaintenancePageType } from ".";
import { InputFlatPickrUi } from "../../../../Components/Form/InputFlatPickr";
import { InputText } from "../../../../Components/Form/InputText";
import { numberValidator } from "../../../../helpers/validationHelper";
import { useAppSelector } from "../../../../redux/hooks";
import { SetMaintenanceTimeDay, ToogleMaitenanceConfigAction } from "../../_redux/maintenance.actions";

export const MaintenanceDate = ({register, watch ,errors}: ManageMaintenancePageType) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const { manage } = useAppSelector((state) => state.MaintenanceReducer);
    const intervalDayValidation = numberValidator(manage.date.active, 0, 999999);

    return (
        <Row>
        <Col md={12}>
            <FormGroup>
                <div className="form-check form-switch form-switch-md">
                    <Input 
                        disabled={!manage.unit}
                        className="form-check-input" 
                        type="checkbox" 
                        id="dateOption"
                        checked={manage.date.active}
                        onClick={()=>dispatch(ToogleMaitenanceConfigAction('date'))}
                    />
                    <Label className="form-check-label" for="dateOption">
                        <FormattedMessage id="MAINTENANCE.DATE"/>
                    </Label>
                </div>
            </FormGroup>
        </Col>
        {
            manage.date.active && <>
                    <Col md={6}>
                        <FormGroup>
                            {
                                <InputText
                                    disabled={!manage.date.active || !manage.unit}
                                    type="number"
                                    label={intl.formatMessage({id: 'MAINTENANCE.ITERVAL_DAY'})}
                                    id={'intervalDay'}
                                    name={'intervalDay'}
                                    placeholder={intl.formatMessage({id: 'MAINTENANCE.ITERVAL_DAY'})}
                                    register={register('intervalDay', intervalDayValidation)}
                                    error={errors['intervalDay']}
                                />
                            }
                        </FormGroup>
                    </Col>
                    <Col xl={6}>
                        <FormGroup>
                            {
                                <InputFlatPickrUi
                                    className="form-control"
                                    label={intl.formatMessage({ id: 'MAINTENANCE.DATE_SERVICE' })}
                                    placeholder={intl.formatMessage({ id: 'MAINTENANCE.DATE_SERVICE' })}
                                    value={ manage.date.startTime }
                                    options={{ dateFormat: "d M, Y"}}
                                    onChange={(date: Date[]) =>  dispatch(SetMaintenanceTimeDay(date[0])) }
                                    error={!manage.date.startTime}
                                    disabled={!manage.date.active}
                                />
                            }
                        </FormGroup>
                    </Col>
            </>
        }

    </Row>
    );
}
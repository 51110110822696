import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { BreadCrumbActionUi } from "../../../../Components/basic/BreadCrumbActionUi";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearTracerCreation } from "../../redux/tracer.actions";
import { ManageTracerBasicInfo } from "../manageBasicInfo";
export const AddTracerPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { user } = useAppSelector((state) => state.authReducer);
    
    useEffect(() => {
        return function cleanup() {
            dispatch(ClearTracerCreation());
        };
    }, []);

    return (
        <div id="tracerPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'TRACER' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <ManageTracerBasicInfo/>
            </Container>
        </div>
    );
}

export default AddTracerPage;
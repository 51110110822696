import { ManageTracerType, TracerInList } from '../types';
import {
    CLEAR_TRACER_CREATION,
    CREATE_TRACER_END,
    DELETE_TRACERS_END,
    DELETE_TRACERS_START,
    ERROR,
    GET_TRACERS_END,
    GET_TRACERS_START,
    GET_TRACER_UPDATE_END,
    GET_TRACER_UPDATE_START,
    SET_SELECTED_TRACERS,
    TracerActionType,
} from './tracer.actions';

type StateType = {
    loading: boolean;
    manage: ManageTracerType;
    selectedTracers: string[];
    filter: {
        limit: number;
        skip: number;
    };
    tracers: {
        list: TracerInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    tracers: {
        list: [],
        total: 0,
    },
    selectedTracers: [],
    manage: { uid: '', name: '', sim: '' },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function TracerReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: TracerActionType
): StateType {
    switch (action.type) {
        case GET_TRACERS_START:
        case DELETE_TRACERS_START:
        case GET_TRACER_UPDATE_START: {
            return { ...state, loading: true };
        }

        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }

        case SET_SELECTED_TRACERS:
            return {
                ...state,
                selectedTracers: action.payload,
            };


        case GET_TRACERS_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                tracers: result,
                filter,
                loading: false,
            };
        
        case CREATE_TRACER_END: {
            const list = [...state.tracers.list];
            const { _id, modifiedCount } = action.payload;
            const updatedTracer: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const tracerIndex = state.tracers.list.findIndex(
                        (tracer) => tracer.key === _id
                    );
                    if (tracerIndex !== -1) {
                        list[tracerIndex] = {
                            ...list[tracerIndex],
                            ...updatedTracer,
                        };
                    }
                }
            } else {
                list.unshift({...updatedTracer});
            }

            return {
                ...state,
                loading: false,
                tracers: {
                    list,
                    total:
                        modifiedCount && modifiedCount > 0
                            ? state.tracers.total
                            : state.tracers.total + 1,
                },
                selectedTracers: [],
            };
        }

        case DELETE_TRACERS_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedTracers:
                        state.selectedTracers.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    tracers: {
                        total: state.tracers.total - deletedCount,
                        list:
                            state.tracers.list.filter(
                                (tracer) => !_ids.includes(tracer.key)
                            ) || [],
                    },
                };
            }
            return {
                ...state,
                loading: false
            };
        }
        

        case GET_TRACER_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                loading: false,
            };
        }

        case CLEAR_TRACER_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
                loading: false,
            };
        }

        default:
            return state;
    }
}

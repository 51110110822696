import { useDispatch, useSelector } from 'react-redux';
import { SetActiveStart } from '../../../../../pages/Map/Object/Fuel/_redux/fuelActions';
import { FuelMarker } from './fuel-marker';
import { MarkerFuelInfo } from './marker-fuel-info';

export function FuelMarkers(): JSX.Element | null {
    const dispatch = useDispatch();

    const { showFuel, fuel, activeFuel } = useSelector(
        (state: any) => state.fuelReducer
    );

    const handleClick = (event: any): void => {
        dispatch(SetActiveStart(event));
    };

    if (fuel.length > 0 && showFuel) {
        return (
            <>
                {
                    fuel.map((event: any, index: number) => {
                        const active = activeFuel && activeFuel._id === event._id;
                        if (active) {
                            return (
                                <>
                                    <MarkerFuelInfo
                                        event={event}
                                        active={active}
                                        index={index}
                                    /> 
                                    <FuelMarker event={event} active={active} />
                                </>
                            );
                        }
                        return <FuelMarker event={event} click={handleClick} />;
                    })
                }
            </>
        );
    }

    return null;
}

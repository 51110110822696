import {  Modal as ModalReactstrap, ModalProps as ModalPropsReactstrap, ModalBody } from "reactstrap";
import {  } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IF } from "../If";
import { ButtonUi } from "../basic/ButtonUi";

const Modal = (props: ModalPropsReactstrap) => {
    return (
        <ModalReactstrap {...props}>
            <IF condition={!!props.toggle}>
                <ButtonUi 
                    style={{zIndex: 4, right: 10, top: 10}}
                    className="position-absolute"
                    color="primary"
                    rounded-pill
                    onClick={props.toggle}
                    ghost
                    size="sm"
                >
                    <IoMdClose size={20}/>
                </ButtonUi>
            </IF>
            <ModalBody>{ props.children }</ModalBody>
        </ModalReactstrap>
    );
}

export default Modal;
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Form, Row, Col} from "reactstrap";
import { BreadCrumbActionUi } from "../../../../Components/basic/BreadCrumbActionUi";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../../redux/hooks";
import { Contact } from "../../../components/form/Contact";
import { ClearUserCreation, CreateUserStart, GetUserUpdateStart } from "../../redux/user.actions";
import { fetchAccountByUserName } from "../../redux/user.service";
import { General } from "./General";
import { UserRole } from "./Role";

const CreateUserPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const { user } = useAppSelector((state) => state.authReducer);
    const { loading, manage } = useAppSelector((state) => state.userReducer);
    const { username } = manage;
    
    const onSubmit = async (data: any) => {
        const newData = { ...data };
        const {_id} = params;
        setFetchLoading(true);
        if (_id !== 'add') {
            newData.username = username;
        } else {
            const exist = await fetchAccountByUserName(newData.username);

            if (exist && exist.error) {
                setFetchLoading(false);
                setError(exist.for, { message: exist.msg, type: 'isUnique' });
                return;
            }
        }
        dispatch(CreateUserStart(data));
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/users' });
    }

    useEffect(()=> {
        if(manage.username) {
            const { roles, ...data } = manage;
            const userInfo:any = data;
            Object.keys(data).forEach((key: any) => setValue(key, userInfo[key]));    
        }
    }, [manage.username]);

    useEffect(() => {
        const {_id} = params;
        if(_id && _id !== 'add') {
            dispatch(GetUserUpdateStart({ _id }))
        }
        return function cleanup() {
            dispatch(ClearUserCreation());
        };
    }, []);

    return (
        <div id="userPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'USER' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
                    <Row>
                        <LoaderUi loading={loading || fetchLoading}/>

                        <Col xl={6}>
                            <Col>
                                <General register={register} errors={errors} watch={watch}/>
                            </Col>
                            <Col>
                                <Contact register={register} errors={errors}/>
                            </Col>
                        </Col>
                        <Col xl={6}>
                            <UserRole />
                            <Col>
                                <div className="hstack gap-2 justify-content-end">
                                    <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                        <FormattedMessage id="COMMON.CANCEL"/>
                                    </ButtonUi>
                                    <ButtonUi color={"primary"} type="submit">
                                        {
                                            params._id === 'add' ? <FormattedMessage id="COMMON.ADD"/>  : <FormattedMessage id="COMMON.UPDATE"/>
                                        }
                                    </ButtonUi> 
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default CreateUserPage;
import { CardFooter, Row, Col } from "reactstrap";
import { Paginator } from "../../basic/Paginator";

type ListMapFooterProps = {
    total: number,
    skip: number,
    limit: number,
    onPaginate: (skip: number, limit: number) => void,
}

export const HEIGHT_LIST_MAP_FOOTER = 60;
 
export const ListMapFooter = (props: ListMapFooterProps) => {

    return (           
        <CardFooter>
            <Row>
                <Col>
                    <Paginator { ...props} />
                </Col>
            </Row>
        </CardFooter>        
    );
}
import { divIcon } from 'leaflet';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-geometryutil';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { EventName } from '../../../../../Components/advanced/Event-info';
import { formatDate, getDuration } from '../../../../../helpers/dateHelper';
import { SetActiveEnd } from '../../../../../pages/Map/Object/Event/_redux/eventActions';


export function MarkerEventInfo({ event, active, index }: any): JSX.Element {
    const dispatch = useDispatch();
    const { timezone } = useSelector((state: any) => state.authReducer.user);
    const {date, km, zone} = event;
    let lng = 0;
    let lat = 0;
    if(Array.isArray(event.coordinates)) {
        lng = event.coordinates[0]
        lat = event.coordinates[1]
    } else {
        lng = event.coordinates.coordinates[0];
        lat = event.coordinates.coordinates[1];
    }
    const intl = useIntl();

    // RP5035, Aghbalou Aqorar, , Fès
    const address = '...';
    const getMarginTop = (): number => {
        const addressSize = address.length;
        switch(true) {
            case event.type === 'event-time' && !!event.duration:
                return 155;
            default:
                return 134;
            }
    };

    const iconPinPopupHtml = renderToStaticMarkup(
        <div className={`popup ${active ? 'popup-active' : ''}`}>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Nom:</span>
                <span className="value">
                    <EventName event={event} onlyText />
                </span>
            </div>
            
            <div className="d-flex flex-row align-items-center address-container">
                <span className="address">
                    {' '}
                    <span className="key">Addresse:</span>                     
                       ...
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Position:</span>
                <span className="value">
                    [{lat}, {lng}]
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Temps:</span>
                <span className="value">
                    {formatDate(date, timezone, 'DD/MM/YYYY HH:mm:ss')}
                </span>
            </div>
            {
                event.duration && <div className="d-flex flex-row justify-content-between">
                <span className="key">Durée:</span>
                <span className="value">
                    {getDuration(event.duration)}
                </span>
            </div>
            }
            
            {/* <div className="d-flex flex-row justify-content-between">
                <span className="key">Zone:</span>
                <span className="value">{zone || '-'}</span>
            </div> */}

            {/* {
                event.speed && event.max && (
                <>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="key">Vitésse maximum:</span>
                        <span className="value">
                            {event.max}{' '}
                            {intl.formatMessage({ id: 'COMMON.KM/H' })}
                        </span>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                        <span className="key">
                            {intl.formatMessage({ id: 'COMMON.SPEED' })}:
                        </span>
                        <span className="value">
                            {event.speed}{' '}
                            {intl.formatMessage({ id: 'COMMON.KM/H' })}
                        </span>
                    </div>
                </>
            )} */}
            {
                km && (
                    <div className="d-flex flex-row justify-content-between">
                        <span className="key">
                            {intl.formatMessage({ id: 'COMMON.MILEAGE' })}:
                        </span>
                        <span className="value">
                            {km} {intl.formatMessage({ id: 'COMMON.KM' })}
                        </span>
                    </div>
                )
            }
        </div>
    );

    const handleClick = (): void => {
        if (active) {
            const payload = { ...event, ...{ index } };
            dispatch(SetActiveEnd(payload));
        }
    };

    const iconPopup = divIcon({
        html: iconPinPopupHtml,
        iconSize: [0, 0],
        iconAnchor: [128, getMarginTop()],
        popupAnchor: [0, -32],
    });

    
    return (
        event && (
            <Marker
                position={[lat, lng]}
                icon={iconPopup}
                zIndexOffset={99}
                eventHandlers={{
                    click: handleClick,
                }}
            />
        )
    );
}

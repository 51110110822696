import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { fitBounds } from '../../../../modules/Map/leaflet-helper';
import { RootState } from '../../../../redux/store';
import { InitVehicle } from '../../Object/Details/_redux/vehicleActions';
import * as actions from './unit-list.actions';
import {
    FitBoundsActionType,
    GetShareStartActionType,
    GetStartActionType,
    VehiclesListActionsType,
} from './unit-list.actions';
import { fetchShareVehicles, fetchUnits } from './unit-list.service';

export const FetchVehiclesEpic = (
    action$: ActionsObservable<GetStartActionType>,
    state$: { value: RootState }
): Observable<VehiclesListActionsType | any> =>
    action$.pipe(
        ofType(actions.GET_START),
        switchMap((action: GetStartActionType) => {
            const filter = {
                ...state$.value.vehicleListReducer.filter,
                ...action.payload,
            };
            if (action.payload.name) {
                filter.name = action.payload.name;
            }
            return from(fetchUnits(filter)).pipe(
                switchMap((res) => {
                    const {driver} = action.payload;
                    if(driver) {
                        return of(
                            actions.GetEnd({
                                vehicleResponse: res.data,
                                filter,
                            }), 
                            InitVehicle({ vehicle: res.data.list[0] }),
                            actions.FitBoundsAction(),
    
                            );
                    }
                    return of(
                        actions.GetEnd({
                            vehicleResponse: res.data,
                            filter,
                        }), 
                        actions.FitBoundsAction(),

                        );
                }),
                catchError((err) => {
                    let msg = 'COMMON.INTERN_ERROR';
                    if (err.response.status === 401) {
                        msg = 'LOGIN.WRONG_PASSWORD';
                    }
                    return of(actions.Error(msg));
                })
            );
        }),
        catchError((err) => {
            return of(actions.Error('COMMON.INTERN_ERROR'));
        })
    );

export const FetchVehiclesShareEpic = (
    action$: ActionsObservable<GetShareStartActionType>,
    state$: { value: RootState }
): Observable<VehiclesListActionsType> =>
    action$.pipe(
        ofType(actions.GET_SHARE_START),
        switchMap((action: GetShareStartActionType) => {
            const filter = {
                ...state$.value.vehicleListReducer.filter,
                ...action.payload,
            };
            if (action.payload.name) {
                filter.name = action.payload.name;
            }
            return from(fetchShareVehicles(filter)).pipe(
                switchMap((res) => {
                    return of(actions.GetEnd({
                        vehicleResponse: res.data,
                        filter,
                    }), actions.FitBoundsAction());
                }),
                catchError((err) => {
                    let msg = 'COMMON.INTERN_ERROR';
                    if (err.response.status === 401) {
                        msg = 'LOGIN.WRONG_PASSWORD';
                    }
                    return of(actions.Error(msg));
                })
            );
        }),
        catchError((err) => {
            return of(actions.Error('COMMON.INTERN_ERROR'));
        })
    );


export const FitBoundsEpic = (
    action$: ActionsObservable<FitBoundsActionType>,
    state$: { value: RootState }
): Observable<VehiclesListActionsType> =>
    action$.pipe(
        ofType(actions.FIT_BOUNDS),
        switchMap((action: FitBoundsActionType) => {
            const map = state$.value.mainReducer.map;
            const {list} = state$.value.vehicleListReducer.vehicles
            const positions = list.filter(v => !!v.baseInfo)
                .map(vehicle =>[ vehicle.baseInfo?.longitude, vehicle.baseInfo?.latitude]);
                
            fitBounds(map, positions);
            return of(actions.Error(''));
        }),
        catchError((err) => {
            return of(actions.Error('COMMON.INTERN_ERROR'));
        })
    );

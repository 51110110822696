import classNames from 'classnames';
import MovingStatus from '../../../../assets/images/objects/moving.svg';
import StopStatus from '../../../../assets/images/objects/stop.svg';
import { VehicleInfoType } from '../../../../pages/Map/Objects/_redux/types';
import { Timer } from '../../../Timer';
import ConnexionIcon from '../Icon/connexionIcon';
import IgnitionIcon from '../Icon/ignitionIcon';
import MovementIcon from '../Icon/movementIcon';

type unitObjectProps = {
    object: VehicleInfoType,
    active: boolean,
    onClick: (object: VehicleInfoType)=> void
}
export const UnitsObject = ({object, active, onClick}: unitObjectProps) => {
    const { baseInfo, name, online, driver } = object;

    const VehicleObjectClass = classNames({
        ['object-info py-2 px-3 d-flex align-items-center justify-content-between']: true,
        ['object-info-active']: active,
      });
    const ignitionStatus = (typeof baseInfo?.ignition === 'boolean' &&  baseInfo?.ignition) || (typeof baseInfo?.ignition === 'string' &&  baseInfo?.ignition === 'true');
    const movementStatus = (typeof baseInfo?.movement === 'boolean' &&  baseInfo?.movement) || (typeof baseInfo?.movement === 'string' &&  baseInfo?.movement === 'true');
      return(
        <div className={VehicleObjectClass} onClick={()=> onClick(object)}>
            <div className="d-flex align-items-center">
                <UnitIcon moving={movementStatus} speed={baseInfo?.speed ?? "0"} />
                <div>
                    <h6 className="mb-0">{name} {driver && <span className='text-success'>({driver})</span>}</h6>
                    <div className="d-flex">
                        <div>
                            <span className="text-muted fs-10">
                                <Timer date={baseInfo?.unitDate ?? '-'} lang={"fr"}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between" style={{width: '60px'}}> 
                <IgnitionIcon size={14} enable={ignitionStatus}/>
                <MovementIcon size={14} moving={movementStatus} speed={baseInfo?.speed ?? "0"} />
                <ConnexionIcon size={16} enable={!online}/>
            </div>
        </div>
    )
}

export const UnitIcon = (props: {speed: string, moving: boolean}) => {
    if(props.moving && +props.speed > 0) {
        return <img src={MovingStatus} className="me-2"/>
    } else {
        return <img src={StopStatus} className="me-2"/>
    }
   
}
import { FuelChartType, FuelFillType, RangeType } from './types';

export const GET_START = '[FUEL] GET_START';
export const GET_END = '[FUEL] GET_END';
export const ERROR = '[FUEL] ERROR';

export const SET_ACTIVE_START = '[FUEL] SET_ACTIVE_START';
export const SET_ACTIVE_END = '[FUEL] SET_ACTIVE_END';

export const TOOGLE_FUEL = '[FUEL] TOOGLE_FUEL';
export const INIT_FUEL = '[FUEL] INIT_FUEL';

export const GET_START_CHART = '[FUEL] GET_START_CHART';
export const GET_END_CHART = '[FUEL] GET_END_CHART';

export const CLEAR_FUEL = '[FUEL] CLEAR_FUEL';

export type GetFuelStartActionType = {
    type: typeof GET_START;
    payload: RangeType;
};
export function GetFuelStart(payload: RangeType): GetFuelStartActionType {
    return { type: GET_START, payload };
}

export type GetFuelEndActionType = {
    type: typeof GET_END;
    payload: FuelFillType[];
};

export function getFuelEnd(payload: FuelFillType[]): GetFuelEndActionType {
    return { type: GET_END, payload };
}

export type GetChartFuelStartActionType = {
    type: typeof GET_START_CHART;
    payload: {range: RangeType, active: string};
};
export function GetChartFuelStart(
    payload: {range: RangeType, active: string}
): GetChartFuelStartActionType {
    return { type: GET_START_CHART, payload };
}

export type GetChartFuelEndActionType = {
    type: typeof GET_END_CHART;
    payload?: FuelChartType;
};
export function GetChartFuelEnd(
    payload?: FuelChartType
): GetChartFuelEndActionType {
    return { type: GET_END_CHART, payload };
}

type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function Error(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export type SetActiveStartActionType = {
    type: typeof SET_ACTIVE_START;
    payload: FuelFillType;
};
export function SetActiveStart(
    payload: FuelFillType
): SetActiveStartActionType {
    return { type: SET_ACTIVE_START, payload };
}

export type SetActiveEndActionType = {
    type: typeof SET_ACTIVE_END;
    payload: FuelFillType & { index: number };
};
export function SetActiveEnd(
    payload: FuelFillType & { index: number }
): SetActiveEndActionType {
    return { type: SET_ACTIVE_END, payload };
}

export type ToogleFuelActionType = {
    type: typeof TOOGLE_FUEL;
    payload?: boolean;
};
export function ToogleFuelAction(payload?: boolean): ToogleFuelActionType {
    return { type: TOOGLE_FUEL, payload };
}

export type InitFuelActionType = {
    type: typeof INIT_FUEL;
    payload?: boolean;
};
export function InitFuel(): InitFuelActionType {
    return { type: INIT_FUEL };
}

export type ClearFuelActionType = {
    type: typeof CLEAR_FUEL;
};
export function ClearFuel(): ClearFuelActionType {
    return { type: CLEAR_FUEL };
}

export type FuelActionsType =
    | GetFuelStartActionType
    | GetFuelEndActionType
    | GetChartFuelStartActionType
    | GetChartFuelEndActionType
    | ErrorActionType
    | SetActiveEndActionType
    | ToogleFuelActionType
    | InitFuelActionType;

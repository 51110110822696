import { useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CardBody, Row, Col, Modal, ModalHeader, ModalBody, Input } from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { CardUi } from "../../../../Components/basic/Card";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { ActionTableDropdown } from "../../../../Components/Dropdown/TableActionDropdown";
import { TableUi } from "../../../../Components/Table";
import { useAppSelector } from "../../../../redux/hooks";
import { DeleteSensorStart, GetSensorsStart, GetSensorUpdateStart, ToogleSensorPopup, ToogleTimingSensorStart } from "../../redux/vehicleModule.actions";
import { ManageSensor } from "./Manage";



export const Sensors = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {_id} = useParams();
    const { loading, sensors } =
        useAppSelector((state) => state.vehicleModuleReducer);
    
    const toogleModal = () => dispatch(ToogleSensorPopup());

    useEffect(() => {
        if(_id) {
            dispatch(GetSensorsStart({vehicleId: _id}));
        }
    }, []);

    const onClickAction = (action: string, _ids: string[]) => {
        if(_id) {
            switch(action) {
                case 'edit':
                    dispatch(GetSensorUpdateStart({_id, sensorId: _ids[0]}));
                    break;
                case 'delete':
                    dispatch(DeleteSensorStart({_id, sensorId: _ids[0]}));
                    break;
            }
        
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        }
    ];


    const columns = [
        {
            title:intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title:intl.formatMessage({ id: 'COMMON.DESCRIPTION' }),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title:intl.formatMessage({ id: 'COMMON.TYPE' }),
            dataIndex: 'type',
            key: 'type',
        },
        {
            title:intl.formatMessage({ id: 'COMMON.SUB_TYPE' }),
            dataIndex: 'subType',
            key: 'subType',
        },
        {
            title:intl.formatMessage({ id: 'COMMON.IO' }),
            dataIndex: 'io',
            key: 'io',
        },

        {
            title:intl.formatMessage({ id: 'COMMON.UNIT' }),
            dataIndex: 'unit',
            key: 'unit',
        },

        {
            title:intl.formatMessage({ id: 'COMMON.EVENT_TIME' }),
            dataIndex: 'additional',
            key: 'additional',
            render: (checked: string | undefined, data: any) => {
                const {type} = data;
                if(type === 'Digital') {
                    const checkedBool = checked === 'event-time'
                    return (<div className="form-check">
                    <Input 
                        className="form-check-input" 
                        type="checkbox" 
                        defaultValue="" 
                        id="responsivetableCheck"
                        checked={checkedBool}
                        onClick={(e)=> {
                            dispatch(ToogleTimingSensorStart({sensorId: data.key, value: !checkedBool}))
                        }}
                    />
                </div>)
                }
                return '-';
                
            }
        },

        {
            title:intl.formatMessage({ id: 'COMMON.VISIBLE' }),
            dataIndex: 'visible',
            key: 'visible',
            render: (checked: string | undefined, data: any) => {
                    const checkedBool = checked === 'event-time'
                    return (<div className="form-check">
                    <Input 
                        className="form-check-input" 
                        type="checkbox" 
                        defaultValue="" 
                        id="responsivetableCheck"
                        checked={checkedBool}
                        onClick={(e)=> {
                            dispatch(ToogleTimingSensorStart({sensorId: data.key, value: !checkedBool}))
                        }}
                    />
                </div>)
                
                
            }
        },

        
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (key: string) => {
                return (
                    <ActionTableDropdown 
                        _id={key} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
        
    ];


    return (
        <>
        
            <Row>
                <LoaderUi loading={loading}/>
                <Col>
                    <ButtonUi color="primary" className="mb-2 float-end" onClick={toogleModal}>
                        { sensors.manage._id ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/> }
                    </ButtonUi>
                </Col> 
            </Row>

                
            <CardUi>
                <CardBody className="p-0">
                    <TableUi 
                        columns={columns}
                        data={sensors.list ?? []}
                        minusHeight={190+120+60}
                    />
                </CardBody>
                
            </CardUi>
            <Modal 
                isOpen={sensors.popupSensor}
                toggle={toogleModal}
                size={"lg"}
                fullscreen={"sm"}
            >
                <ModalHeader toggle={toogleModal}>
               {
                    sensors.manage._id ? <FormattedMessage id="SENSOR.UPDATE"/> : <FormattedMessage id="SENSOR.ADD"/>
               } 
                </ModalHeader>
                <ModalBody>
                    <ManageSensor/>
                </ModalBody>
                
            </Modal>
        </>
               
    );
}
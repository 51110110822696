export const TOOGLE_IS_SHARED = '[SHARE_MAP] TOOGLE_IS_SHARED';
export type ToogleIsSharedType = {
    type: typeof TOOGLE_IS_SHARED;
    payload: boolean;
};
export function TooglePoup(payload: boolean): ToogleIsSharedType {
    return { type: TOOGLE_IS_SHARED, payload };
}

export const GET_TOKEN_START = '[SHARE_MAP] GET_TOKEN_START';
export type GetTokenStartType = {
    type: typeof GET_TOKEN_START;
    payload: string;
};
export function GetTokenStart(payload: string): GetTokenStartType {
    return { type: GET_TOKEN_START, payload };
}

export const GET_TOKEN_END = '[SHARE_MAP] GET_TOKEN_END';
export type GetTokenEndType = {
    type: typeof GET_TOKEN_END;
    payload: string;
};
export function GetTokenEnd(payload: string): GetTokenEndType {
    return { type: GET_TOKEN_END, payload };
}



export type SharedMapActionType =
    | ToogleIsSharedType
    | GetTokenStartType
    | GetTokenEndType;

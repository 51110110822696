import { formatMessage } from "@formatjs/intl";
import { useEffect } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Container, Row, Col, CardBody, CardHeader, FormGroup, Input, Label } from "reactstrap";
import { AlertUi } from "../../../../Components/basic/AlertUi";
import { CardUi } from "../../../../Components/basic/Card";
import { useAppSelector } from "../../../../redux/hooks";
import { GetMaintenanceVehicleInfoStartAction } from "../../_redux/maintenance.actions";
import { MaintenanceDate } from "./MaintenanceDate";
import { MaintenanceHour } from "./MaintenanceHours";
import { MaintenanceKm } from "./MaintenanceKm";


export type ManageMaintenancePageType = {
    register: UseFormRegister<any>,
    errors: {[x: string]: any},
    watch: UseFormWatch<FieldValues>
 }
export const Type = ({register, watch ,errors}: ManageMaintenancePageType) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const params = useParams();
    const navigate = useNavigate();
    const { manage } = useAppSelector((state) => state.MaintenanceReducer);
    const {groupId, maintenanceId} = useParams();
    const isUpdate = !!(groupId && maintenanceId);

    useEffect(()=>{
        if(manage.unit) {
            dispatch(GetMaintenanceVehicleInfoStartAction({_id: manage.unit.value}))
        }
    }, [manage.unit]);

    return (
        <CardUi>
            <CardHeader>
                <h5 className="card-title mb-0">
                    <FormattedMessage id="COMMON.CONFIGURATIONS"/>
                </h5>
            </CardHeader>
            <CardBody>
                {
                    !manage.date.active && !manage.km.active && !manage.engineTime.active && <AlertUi color={"danger"} solid={true}>
                    Veuillez choisir au moins une configuration
                </AlertUi>
                }
                
                <MaintenanceKm register={register} errors={errors} watch={watch}/>
                <MaintenanceDate register={register} errors={errors} watch={watch}/>
                <MaintenanceHour register={register} errors={errors} watch={watch}/>
            </CardBody>
        </CardUi>
    );
}
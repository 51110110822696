import classNames from "classnames";
import { AiOutlineArrowLeft, AiOutlineEyeInvisible, AiOutlineUnorderedList } from "react-icons/ai";
import { CardHeader } from "reactstrap";
import { IF } from "../../If";

type HeaderListMapHeaderComponentProps = {
    title: string;
    toggleVisibility?: () => void;
    back?: () => void
}
export const HeaderListMapComponent = (props: HeaderListMapHeaderComponentProps) => {

    const headerListMapClassname = classNames("d-flex justify-content-between align-items-center mb-0");

    return (
        <CardHeader className={headerListMapClassname}>
            <h5 className="d-flex align-items-center mb-0 text-primary">
                <IF condition={!!props.toggleVisibility && !props.back}>
                    <AiOutlineUnorderedList className="me-2"/>
                </IF>
                <IF condition={!!props.back}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={props.back}
                        onKeyDown={props.back}
                        className="me-3 p-1 ghost btn btn-primary btn-sm rounded-circle"
                    >
                        <AiOutlineArrowLeft size={20} />
                    </div>
                </IF>
                
                <span>{props.title}</span>
            </h5>
            
                <div
                    role="button"
                    tabIndex={0}
                    onClick={props.toggleVisibility}
                    onKeyDown={props.toggleVisibility}
                >
                    <AiOutlineEyeInvisible size={20} />
                </div>
            

        </CardHeader>
    );
}

export default HeaderListMapComponent;
import { UseFormRegister } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {CardBody, CardHeader, FormGroup } from "reactstrap";

import { CardUi } from "../../../Components/basic/Card";
import { InputText } from "../../../Components/Form/InputText";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any}
}
export const General = ({register, errors}: ManageCompanyPageType) => {
    const intl = useIntl();

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>
            <FormGroup>
                    <InputText
                        type="text"
                        label={intl.formatMessage({id: 'COMMON.NAME'})}
                        id={'name'}
                        name={'name'}
                        placeholder={intl.formatMessage({id: 'COMPANY.PLACEHOLDER.NAME'})}
                        register={register('name', {
                            required: {
                                value: true, 
                                message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                            }})}
                        error={errors['name']}
                    />
                </FormGroup>
                <FormGroup>
                    <InputText
                        style={{height: '134px'}}
                        type="textarea"
                        label={intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                        id={'description'}
                        name={'description'}
                        placeholder={intl.formatMessage({id: 'COMPANY.PLACEHOLDER.DESCRIPTION'})}
                        register={register('description')}
                        error={errors['description']}
                    />
                </FormGroup>
            </CardBody>
        </CardUi>               
    );
}
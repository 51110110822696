import { BASE_URL } from './../../../env';
import axios from "axios";

export async function getTokenShare(_id: string): Promise<string> {
    const url = `${BASE_URL}/api/share/${_id}/token`;
    try {
        const res = await axios.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {  AiOutlineEyeInvisible, AiOutlineUnorderedList } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import {CardFooter, CardHeader, Col, Row} from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { InputSearch } from "../../../Components/Form/InputSearch";
import { Tabs } from "../../../Components/Tabs";
import AllStatus from '../../../assets/images/objects/all.svg';
import MovementStatus from '../../../assets/images/objects/moving.svg';
import StopStatus from '../../../assets/images/objects/stop.svg';
import Idling from '../../../assets/images/objects/idling.svg';
import { UnitsObject } from "../../../Components/advanced/Object-info/Objects/VehicleObject";
import { Object } from "../../../Components/advanced/Object-info/Objects";
import { GetShareStart, GetStart } from "./_redux/unit-list.actions";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { VehicleInfoType } from "./_redux/types";
import { Paginator } from "../../../Components/basic/Paginator";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { InitVehicle } from "../Object/Details/_redux/vehicleActions";
import classNames from "classnames";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { LayoutContext } from "../../../context/layout-style-context";
import { SetOptionMapAction } from "../../../redux/rootActions";
import { useParams } from "react-router-dom";
import { EmptyUi } from "../../../Components/basic/EmptyUi";
import { LIMIT } from "../../../env";


export const UnitList = ({sharedMap}: {sharedMap?: boolean}) => {
    const { _id, vehicleKey } = useParams();    
    const { height } = useContext(LayoutContext);
    const listRef = useRef<any>();
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50,
    });
    const dispatch = useDispatch();
    
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all', img:  AllStatus},
        { label: 'COMMON.In_Movement', value: 'movement', img: MovementStatus },
        { label: 'COMMON.STOPED', value: 'stoped', img: StopStatus },
        { label: 'COMMON.IDLING', value: 'idling', img: Idling },

    ];
    const { filter, vehicles, loading } = useAppSelector(
        (state) => state.vehicleListReducer
    );
    const { vehicleActive } = useAppSelector((state) => {
        return {
            vehicleActive: state.ObjectReducer.vehicle?._id,
        };
    });
    const canShowFooter = Math.ceil(vehicles.total / filter.limit) > 1;
    const [activeNav, setActiveNav] = useState(0);
    const [hide, setHide] = useState<boolean>(false);
    const toogleHide = (): void => {
        setHide(!hide);
        let padding = 0;
        if(hide) { padding = padding + 374; }
        if(vehicleActive) { padding = padding + 450; }
        dispatch(SetOptionMapAction({opt: 'padding', value: padding}))
    };
    const intl = useIntl();

    const onChangeNav = (active: number) => {
        setActiveNav(active);
        fetchVehicles({ ...filter, skip: 0, limit: LIMIT, status: FILTERS[active].value })
    }

    const fetchVehicles = ({skip, limit, name, status} : { skip: number, limit: number, name?: string, status?: string}) => {
        if(!sharedMap) {
            dispatch(GetStart({ skip, limit, name, status }));
        } else if(_id){
            dispatch(GetShareStart({ skip, limit, name, _id }));
        }
    }

    const onSearch = (data: { searchBy: string }): void => {
        fetchVehicles({ ...filter, skip: 0, limit: LIMIT, name: data.searchBy });
    };

    const chooseObject = (object: VehicleInfoType): void => {
        dispatch(InitVehicle({ vehicle: object, sharedMap }));
        dispatch(SetOptionMapAction({opt: 'zoom', value: 16}));
    };
    
    useEffect(() => {
        fetchVehicles({ status: FILTERS[activeNav].value, skip: 0, limit: LIMIT });
    }, [])

    const onPaginate = (skip: number, limit: number): void => {
        fetchVehicles({ ...filter, skip, limit });
    };

    const ListObjectClassname = classNames({
        [`d-none`]: hide,
        'list-object': true
    });

    const getIndex = useMemo(() => {
        if(vehicleActive) {
            const index = vehicles.list.findIndex(v => v._id === vehicleActive);
            if(index !== -1) {
                return index;
            }
        }
        return undefined
    }, [vehicleActive]);
    
    return (
        <>
            <ButtonUi 
                onClick={toogleHide}
                className={hide ? '' : 'd-none'} color={"primary"} Icon={AiOutlineUnorderedList}/>
            <CardUi className={ListObjectClassname}>
                <LoaderUi loading={loading}/>
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <AiOutlineUnorderedList className="me-2"/>
                        <span >Liste des objets</span>
                    </h5>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={toogleHide}
                        onKeyDown={toogleHide}
                    >
                        <AiOutlineEyeInvisible size={20} />
                    </div>
                </CardHeader>
                
                <CardBodyUi className="p-0">
                    <div className="mt-2 px-3">
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                        <Tabs 
                            color="primary" 
                            isCustom
                            className="border-bottom-0 d-none d-sm-flex my-2 justify-content-between" 
                            onChange={onChangeNav}
                        >
                            { FILTERS.map((filter, index: number)=> (
                                <div className="d-flex align-items-center">
                                    <img src={filter.img} />
                                    <span className="fs-10 ms-2">
                                        <FormattedMessage id={filter.label}/>
                                    </span>
                                </div>
                                )
                            )}
                        </Tabs>
                    </div>

                    <div style={{height: height - (canShowFooter ? 330 : 255)}}>
                        <AutoSizer>
                            {
                                ({ height, width }) => {
                                    return(
                                        vehicles.list.length > 0 ? 
                                        <List
                                            ref={listRef}
                                            width={width}
                                            height={height > 0 ? height : 0}
                                            rowCount={ vehicles.list.length}
                                            deferredMeasurementCache={cache}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={rowRenderer}
                                            scrollToIndex={getIndex}
                                        /> : 
                                        <EmptyUi msg="Liste vide"/>
                                    )
                                }     
                            } 
                        </AutoSizer> 
                    </div>   
                    
                </CardBodyUi>
                {
                    canShowFooter ? (
                        <CardFooter>
                        <Row>
                            <Col>
                                <Paginator
                                    total={vehicles.total}
                                    skip={filter.skip}
                                    limit={filter.limit}
                                    onPaginate={onPaginate}
                                />
                            </Col>
                        </Row>
                    </CardFooter>
                    ) : <></>
                }
                
            </CardUi> 
        </>
 
    );

    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                        {
                            vehicles.list[index].type !== 'sac' 
                                ? <UnitsObject                             
                                    object={vehicles.list[index]}
                                    active={vehicles.list[index]._id === vehicleActive}
                                    onClick={chooseObject} /> 
                                : 
                                    <Object/>
                        }
                </div>
            )}
          </CellMeasurer>
        );
    }
}
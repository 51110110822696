import { divIcon } from 'leaflet';
import React, { useEffect, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { BsFillPlayFill, BsStopFill } from 'react-icons/bs';
import { FaParking } from 'react-icons/fa';
import { Marker } from 'react-leaflet';
import { PolylineData } from '../../../../pages/Map/Object/History/_redux/types';

export function AllTrajectLimitMarker({ polyline, last }: {polyline: PolylineData[], last?: boolean}) {
    
    const refMarker = useRef<any>();
    const iconPinHtml = renderToStaticMarkup(
        <div className={`marker-pin ${last ? 'bg-info' : 'bg-success'}`}>
            {
                last ?  <FaParking size={15} className={'text-info'}/>
                    : 
                    <BsFillPlayFill size={16} className={'text-success'}/>
            }
            
        </div>
    );

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });

    useEffect(()=> {
        refMarker.current?.setZIndexOffset(999);
    }, [refMarker.current])

    
    if(polyline && polyline.length > 1) {
        if(last) {
            const end = polyline[polyline.length -1].location[polyline[polyline.length -1].location.length -1] as number[];
            return <Marker ref={refMarker} position={{lat: end[0], lng: end[1]}} icon={iconPin} />

        } else {
            const start = polyline[0].location[0] as number[];
            return <Marker ref={refMarker} position={{lat: start[0], lng: start[1]}} icon={iconPin} />
        }
        
        
        
       
    }

    return null;
    
}

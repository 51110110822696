import { useContext, useEffect, useState } from "react";
import { useFetchReportDetails } from "../../../../common/hooks/axios/report/useFetchReport";
import ReportTable from "../report-table";
import { Card } from "reactstrap";
import { LayoutContext } from "../../../../context/layout-style-context";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";

type ReportDetailProps = {
    template: string;
    reportId: string;
    item: { label: string; value: string; }
    range: { from: string; to: string; }
    isFull: boolean;
    position: number;
    close: () => void;
}
const ReportDetail = (props: ReportDetailProps) => {
    const [filter, setFilter] = useState<any>(null);
    const [pagination, setPagination] = useState<{skip: number, limit: number}>({skip: 0, limit: 25});
    const { height } = useContext(LayoutContext);
    const { fetchReportDetails, reportDetails, reportDetailsLoading } = useFetchReportDetails({manual: true});

    useEffect(()=> {
        if(reportDetails?.extra) {
            setFilter(reportDetails.extra)
        }
    }, [reportDetails]);

    useEffect(() => {
        fetchReportDetails({
            template: props.template,
            reportId: props.reportId,
            "item[label]": props.item.label,
            "item[value]": props.item.value,
            "range[from]": props.range.from,
            "range[to]": props.range.to,
            "hasExtra": !reportDetails?.extra
        });
    }, [props.item, props.range.from, props.range.to]);

    useEffect(() => {
        fetchReportDetails({
        template: props.template,
        reportId: props.reportId,
        "item[label]": props.item.label,
        "item[value]": props.item.value,
        "range[from]": props.range.from,
        "range[to]": props.range.to,
        "pagination[skip]": pagination.skip,
        "pagination[limit]": 25,
        "hasExtra": !reportDetails?.extra
        });
    }, [pagination.skip, pagination.limit]);

    const fetchData = (skip: number, limit: number) => {
        setPagination({skip, limit});
    };
    
    switch(true) {
        case filter && filter.columns && filter.format === "table": {
            return <ReportTable 
                    name={filter.name}
                    columns={filter.columns}
                    data={reportDetails}
                    fetch={fetchData} 
                    close={props.close}
                    skip={pagination.skip}
                    loading={reportDetailsLoading}
                    isFull={props.isFull}
                    position={props.position}
                />
        }
    default:
        return <Card className="mb-1" style={{height: height/2 - 55}}>
            <LoaderUi loading={true}></LoaderUi>
        </Card>
    }
   
}

export default ReportDetail;
import { useEffect, useState } from 'react';
import { diffDate } from '../helpers/dateHelper';

type TimerType = {
    date: Date | string | number;
    lang: string;
};
export function Timer({ date, lang }: TimerType) {
    const customInterval = 2000;
    const [time, setTime] = useState('-');

    const updateTimer = () => {
        const timer = diffDate(new Date(date), lang);
        setTime((time) => `${timer.text}`);
    };

    useEffect(() => {
        updateTimer();
        const _interval = setInterval(() => {
            updateTimer();
        }, customInterval);
        return () => {
            clearInterval(_interval);
        };
    }, [date, lang]);

    return <span>{time}</span>;
}

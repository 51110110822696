import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, CardHeader, CardBody, FormGroup} from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { CardUi } from "../../../../Components/basic/Card";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { InputSelect } from "../../../../Components/Form/InputSelect";
import { InputText } from "../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../redux/hooks";
import { ToogleFuelConfig, UpdateTripStart } from "../../redux/vehicleModule.actions";
const TRIP_DETECTION = 'ignition';

export const TripConfiguration = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const STATUS_OPTIONS  = [
        {
            label: intl.formatMessage({id: 'COMMON.SENSOR.IGNITION'}),
            value: TRIP_DETECTION
        }
    ];
    const { loading, trip } = useAppSelector((state) => state.vehicleModuleReducer);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        defaultValues: {
            minSpeed: trip.minSpeed,
            minParkingTime: trip.minParkingTime
        }
    });
    

    useEffect(()=> {
        if(trip) {
            const {minParkingTime, minSpeed,} = trip;
            setValue('minParkingTime', minParkingTime);
            setValue('minSpeed', minSpeed);
        }
    }, [trip]);

    const onSubmit = async ({minParkingTime, minSpeed}: any) => {
        dispatch(UpdateTripStart({by: 'ignition', minParkingTime: +minParkingTime, minSpeed: +minSpeed}))
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/units' });
    }

    
    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
            <Row>
                <LoaderUi loading={loading}/>

                <Col xl={6}>
                    <CardUi noShadow={true}>
                        <CardHeader>
                            <h4 className='card-title mb-0'>
                                <FormattedMessage id="TRIP_MOVEMENT_DETECTOR"/>
                            </h4>
                        </CardHeader>
                        <CardBody>
                        <FormGroup>
                                <InputSelect
                                    label={intl.formatMessage({ id: 'TRIP_MOVEMENT.DETECTOR_BY' })}
                                    value={STATUS_OPTIONS.find(type => type.value === TRIP_DETECTION) ?? []}
                                    options={STATUS_OPTIONS}
                                    isDisabled={true}
                                    onChange={(element) => dispatch(ToogleFuelConfig())}
                                />
                            </FormGroup>
                            
                        </CardBody>
                    </CardUi>  
                </Col>
                <Col xl={6}>
                    <CardUi noShadow={true}>
                        <CardHeader>
                            <h4 className='card-title mb-0'>
                                <FormattedMessage id="TRIP_MOVEMENT_FILTER"/>
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <InputText
                                    type="number"
                                    label={intl.formatMessage({id: 'TRIP.MINIUM_MOVING_SPEED'})}
                                    id={'minSpeed'}
                                    name={'minSpeed'}
                                    placeholder={intl.formatMessage({id: 'TRIP.MINIUM_MOVING_SPEED'})}
                                    register={register('minSpeed', {
                                        required: {
                                            value: true, 
                                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                        }})}
                                    error={errors['minSpeed']}
                                />
                            </FormGroup>
                            <FormGroup>
                                <InputText
                                    type="number"
                                    label={intl.formatMessage({id: 'TRIP.MINIUM_PARKING_TIME'})}
                                    id={'minParkingTime'}
                                    name={'minParkingTime'}
                                    placeholder={intl.formatMessage({id: 'TRIP.MINIUM_PARKING_TIME'})}
                                    register={register('minParkingTime', {
                                        required: {
                                            value: true, 
                                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                        }})}
                                    error={errors['minParkingTime']}
                                />
                            </FormGroup>
                        </CardBody>
                    </CardUi>  
                </Col>


                <Col>
                    <div className="hstack gap-2 justify-content-end">
                        <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                            <FormattedMessage id="COMMON.CANCEL"/>
                        </ButtonUi>
                        <ButtonUi color={"primary"} type="submit">
                                <FormattedMessage id="COMMON.UPDATE"/>
                            
                            
                        </ButtonUi> 
                    </div>
                </Col>
                

            </Row>
        </Form>
    );
}
import { useAppSelector } from "../redux/hooks";
import { IF } from "./If";

export const Acl = (props: { children: JSX.Element | JSX.Element[], type: string }) => {
    const { user } = useAppSelector((state) => state.authReducer);
    return (
        <IF condition={user && ((user.connectedAs && user.connectedAs.type === props.type) || (!user.connectedAs && user?.type === props.type))}>
            <>{props.children}</>
        </IF>
    );
}
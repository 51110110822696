import { useEffect } from "react";
import { useAxios } from "../useAxios";
import { PassengerListItemType } from "./type";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
}

export type FetchPassengerProps = {
  name?: string,
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
  group?: string;
}

type UseFetchPassengerResponse = { 
  passengers: { list: PassengerListItemType[], total: number };
  fetchPassengersLoading: boolean;
  error?: string;
  fetchPassengers: (params?: FetchPassengerProps) => void;
  cancel: () => void;
}



type UseFetchReportItemsProps = {
  manual?: boolean,
  skip?: number,
  limit?: number,
}

export function useFetchPassengers(props: UseFetchReportItemsProps): UseFetchPassengerResponse {
  const URL = `passengers`;
  const { execute, data, cancel, error, loading, init } = useAxios<{list: PassengerListItemType[], total: number}>(props.manual);

  useEffect(() => {
    if(!props.manual) {
      fetchPassengers({"pagination[skip]": props.skip ?? 0, "pagination[limit]": props.limit ?? 25});
    }
  }, []);

  const fetchPassengers = (params?: FetchPassengerProps) => {
    execute(URL, { method: "GET", params});
  };

  return { 
    passengers: data ?? {list: [], total: 0},
    fetchPassengersLoading: loading, 
    error,
    fetchPassengers,
    cancel, 
  };
}

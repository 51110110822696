import { ButtonUi } from "../../basic/ButtonUi";
import { AvatarGroup } from "../../basic/avatar/avatar-group";
import { Avatar } from "../../basic/avatar/avatar";
import { LabelValue } from "../../../common/types";
import { IF } from "../../If";
import { AiOutlineEdit } from "react-icons/ai";
import { BiAddToQueue } from "react-icons/bi";
import classNames from "classnames";

type Item = LabelValue & { description?: string };
type SelectUnitsProps = {
    target: "unit",
    items: Item[],
    limit: number,
    disabled?: boolean;
    editActionName: string,
    addActionName: string,
    onClick: () => void,
    children: JSX.Element
}
const ViewSelectedItems= ({disabled, items, limit, addActionName, editActionName, children, onClick}: SelectUnitsProps) => {
    const size = items.length;
    const visibleItems = size - limit;
    const maxIndex = visibleItems > 0 ? limit : size;
    const className = classNames("d-flex flex-row justify-content-between align-items-center", {"opacity-50": !!disabled, 'cursor-not-allowed': !!disabled});

    return (
        <div className={className}>
            <IF condition={size > 0}>
                <AvatarGroup>
                    {
                        items.slice(0, maxIndex).map(item => 
                            <Avatar
                                key={item.value}
                                color='primary'
                                text={item.label}
                                rounded="rounded-circle"
                                size="xs"
                            /> 
                        )
                    }
                    <IF condition={visibleItems > 0}>
                        <Avatar
                            color='success'
                            text={`+${visibleItems}`}
                            rounded="rounded-circle"
                            size="xs"
                            maxChar={4}
                        /> 
                    </IF>
                </AvatarGroup>
            </IF>
            
            <ButtonUi 
                color="secondary" 
                soft 
                size='sm'
                onClick={onClick}
                disabled={disabled}
            >
                {
                    size ? 
                        <>{addActionName} <AiOutlineEdit size={14}/></>
                        : <>{editActionName} <BiAddToQueue  size={14}/></>
                }
            </ButtonUi>
            { children }
        </div> 
    );
}

export default ViewSelectedItems;
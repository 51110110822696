import { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { I18nContext } from '../context/i18n-context';
import arMessages from './messages/ar.json';
import enMessages from './messages/en.json';
import frCompany from './messages/fr/fr-company.json';
import frCommon from './messages/fr/fr-common.json';
import frAside from './messages/fr/fr-aside.json';
import frUSER from './messages/fr/fr-user.json';
import frUnit from './messages/fr/fr-unit.json';
import frTracer from './messages/fr/fr-tracer.json';
import frAlarm from './messages/fr/fr-alarm.json';
import frMaintenance from './messages/fr/fr-maintenance.json';
import frShare from './messages/fr/fr-share.json';
import frDriver from './messages/fr/fr-driver.json';
import frSensor from './messages/fr/fr-sensor.json';
import frTrip from './messages/fr/fr-trip.json';
import frGeofence from './messages/fr/fr-geofence.json';
import frIncident from './messages/fr/fr-incident.json';
import frReport from './messages/fr/fr-report.json';
import frRoutes from './messages/fr/fr-route.json';

import { I18nProviderType } from './types';

const allMessages = {
    fr: { ...frReport, ...frTracer, ...frCompany, ...frCommon, ...frAside, ...frUSER, ...frAlarm, ...frMaintenance, ...frShare, ...frDriver, ...frUnit, ...frSensor, ...frTrip, ...frGeofence, ...frIncident, ...frRoutes},
    en: enMessages,
    ar: arMessages,
};

export function I18nProvider({ children }: I18nProviderType): JSX.Element {
    const { lang } = useContext(I18nContext);
    const locale = lang;
    const messages = allMessages[locale];

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
}

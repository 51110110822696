import { useAppSelector } from "../../../../redux/hooks";
import { useDispatch } from "react-redux";
import { AllTrajectLimitMarker } from "../marker/AllTrajectLimitMarker";
import { ActiveTrajectStart } from "../../../../pages/Map/Object/History/_redux/historyActions";
import { ActiveTrajectPolyline } from "./active-traject";
import { FeatureGroup, Polyline, useMap } from "react-leaflet";
import { useEffect, useRef } from "react";
const WIDTH_DETAILS_DIV = 450;
export const TrajectPolyline = () => {
    const dispatch = useDispatch();
    const featureRef = useRef<any>();
    const map = useMap();
    const { polyline, showTraject } = useAppSelector(state => state.historyReducer);

    useEffect(() => {
        if (map && showTraject && polyline.length > 0 && featureRef.current) {
            map.fitBounds(featureRef.current?.getBounds(), { 'paddingTopLeft': [ WIDTH_DETAILS_DIV + 10, 10] });
        }
    }, [showTraject, polyline]);
      
    
    
    // const antPathOptions = {
    //     "className": 'hicham',
    //     "delay": 900,
    //     "dashArray": [
    //         32,
    //         59
    //     ],
    //     "weight": 6,
    //     "color": "#405189",
    //     "pulseColor": "rgba(255, 255, 255, 1)",
    //     "reverse": false,
    //     "hardwareAccelerated": false
    // };
    // const colors = ["#ff5722", "#ff5722", "#00bcd4", "#4caf50", "#795548", "#9c27b0", "#009587", "#2196f3"];
    // <Polyline positions={el.location} color="red" fillColor="white"/>
    const color = ['red', '#405189', 'green', "black",]

    return (
        <FeatureGroup  ref={featureRef}>
            {
                showTraject && polyline.length > 0 ? (
                    <>
                        {
                            polyline.map((el) => (
                                <>
                                
                                <Polyline 
                                    positions={el.location} color="white" weight={8}
                                    eventHandlers={{
                                        click: () => {
                                            dispatch(ActiveTrajectStart({ _id: el._id}))
                                        } 
                                    }}
                                />
                                <Polyline positions={el.location} color="gray" weight={5} eventHandlers={{
                                        click: () => {
                                            dispatch(ActiveTrajectStart({ _id: el._id}))
                                        } 
                                    }}/>
                                </>
                            ))
                        }
                        <AllTrajectLimitMarker polyline={polyline} />
                        <AllTrajectLimitMarker polyline={polyline} last/>
                        <ActiveTrajectPolyline/>
                    </>
                ) : <ActiveTrajectPolyline/>
            } 
        </FeatureGroup>
    )
}





// import { useAppSelector } from "../../../../redux/hooks";
// import { useDispatch } from "react-redux";
// import { AntPolyline } from "./polyline-ant";
// import { AllTrajectLimitMarker } from "../marker/AllTrajectLimitMarker";
// import { ActiveTrajectStart } from "../../../../pages/Map/Object/History/_redux/historyActions";
// import { ActiveTrajectPolyline } from "./active-traject";
// import "leaflet-arrowheads";
// import { FeatureGroup, Polyline, useMap } from "react-leaflet";
// import { useEffect, useRef } from "react";
// export const TrajectPolyline = () => {
//     const featureRef = useRef<any>();
//     const map = useMap();
//     const { polyline, showTraject, trip } = useAppSelector(state => state.historyReducer);


      
//     const dispatch = useDispatch();
    
//     const antPathOptions = {
//         "className": 'hicham',
//         "delay": 900,
//         "dashArray": [
//             32,
//             59
//         ],
//         "weight": 6,
//         "color": "#405189",
//         "pulseColor": "rgba(255, 255, 255, 1)",
//         "reverse": false,
//         "hardwareAccelerated": false
//     };
//     // const colors = ["#ff5722", "#ff5722", "#00bcd4", "#4caf50", "#795548", "#9c27b0", "#009587", "#2196f3"];
//     // <Polyline positions={el.location} color="red" fillColor="white"/>
//     const color = ['red', '#405189', 'green', "black",]
//     return (
//         <FeatureGroup  ref={featureRef}>

//             {
//                 showTraject && polyline.length > 0 ? (
//                     {
//                         polyline.map(el => <>
//                         <Polyline positions={el.location} color="white" weight={8}/>
//                         <Polyline positions={el.location}  color={color[Math.floor(Math.random() * color.length)]} stroke={true} weight={5}/>
                            
//                         </>
                    
//                         )
//                     }
                   


{/* {polyline.map(el => <AntPolyline
                            positions={el.location}
                            options={antPathOptions}
                            eventHandlers={{
                                click: () => {
                                    dispatch(ActiveTrajectStart({ _id: el._id, on: true}))
                                } 
                            }}
                        />)} */}
                        {/* <AllTrajectLimitMarker trips={trips.data} />
                        <AllTrajectLimitMarker trips={trips.data} last={true}/>                     */}


                        
//                         <ActiveTrajectPolyline/>
                   
//                 ) : <ActiveTrajectPolyline/>
//             } 
//         </FeatureGroup>
         
//     )
// }
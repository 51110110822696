import { FormattedMessage, useIntl } from "react-intl";
import {CardBody, CardHeader } from "reactstrap";
import { CardUi } from "../../../../Components/basic/Card";
import { useAppSelector } from "../../../../redux/hooks";
import { ConditionItem } from "./ConditionItem";



export const Conditions = () => {

    const { manage } = useAppSelector((state) => state.eventsReducer);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="ALARMS.CONDITION" /></h4>
            </CardHeader>
            <CardBody>
                {
                    manage.events.map((event, index) =>  <ConditionItem key={index} position={index}/> )
                }
            </CardBody>
        </CardUi>               
    );
}

import { FormGroup, Label, UncontrolledAccordion } from "reactstrap";
import { InputSearch } from "../../../Form/InputSearch";
import { useIntl } from "react-intl";
import SelectItem from "./select-item";
import SelectItemsIndicator from "./select-items-indectaor";
import { memo, useCallback, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";


type SelectItemsProps = {
    data: {
        key: string,
        group: string,
        data: {
            label: string, value: string
        }[]
    }[],
    default: Record<string, { label: string, value: string }[]>,
    maxSelection?: number;
    showSelectedItems?: boolean;
    onRefresh?: ({searchBy}: {searchBy: string}) => void;
    onSearch: ({searchBy}: {searchBy: string}) => void;
    onSelect: (selection: Record<string, {label: string, value: string}[]>) => void;
}
const SelectItems= (props: SelectItemsProps) => {
    const [selectedItems, setSelectedItems] = useState<Record<string, {label: string, value: string}[]>>(props.default);
    const intl = useIntl();

    useEffect(()=> {
        const items: Record<string, { label: string, value: string }[]> = {};
        Object.keys(selectedItems).forEach(item => {
            if(selectedItems[item].length > 0) {
                items[item] = selectedItems[item];
            }
        });
        props.onSelect(items);
    }, [selectedItems]);

    const onSelect = (id: string, data: { label: string, value: string }[]) => {
        const newSelectItems = {...selectedItems};
        newSelectItems[id] = data;
        setSelectedItems(newSelectItems);
    }

    const extractedSelectedItems = useCallback(()=> {
        const keys = Object.keys(selectedItems);
        return Object.values(selectedItems).reduce((result, current, position) => {
            const data = current.map(item => { return {...item, key: keys[position]}})
            return [...result, ...data]
        }, []);
    }, [selectedItems])();

    const selectedItemsCount = useCallback(()=> {
        return Object.keys(selectedItems).reduce((accumulator, currentValue) => accumulator + selectedItems[currentValue].length, 0);
    }, [selectedItems]);
    

    const unselectItems = (item: any) => {
        const newSelectedItems = { ...selectedItems };
        if(newSelectedItems[item.key] != null) {
            newSelectedItems[item.key] = newSelectedItems[item.key].filter(data => { return data.value !== item.value})
            setSelectedItems(newSelectedItems);
        }
    }

    return (
        <>
        {
            (props.showSelectedItems && extractedSelectedItems.length > 0) && 
            <FormGroup>
                <Label>Passagers selectionnés ({extractedSelectedItems.length})</Label>
                <div className="border rounded-1 p-2 d-flex flex-wrap" style={{maxHeight: 100, overflow: "auto"}}>
                    {
                        extractedSelectedItems.map((item) => {
                            return(
                                <span className="bg-light py-1 px-2 m-1 rounded-pill position-relative"> 
                                    { item.label } <AiFillCloseCircle className={`clear-text-icon text-primary cursor-pointer ms-1 mt-0 mb-0`} onClick={() => unselectItems(item)}/>
                                </span>
                            )
                        })
                    }
                </div>
            </FormGroup> 
        }
            <div className="select-items">
                <InputSearch
                    id="search-unit"
                    type="text"
                    name={'searchBy'}
                    placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                    onSubmit={props.onSearch}
                    onRefresh={props.onRefresh}
                />
                <div className="mt-4">
                    {
                        props.maxSelection && <SelectItemsIndicator value={selectedItemsCount()} max={props.maxSelection}/>
                    }
                    
                    
                        <UncontrolledAccordion open={props.data[0]?.group} defaultOpen={props.data[0]?.group} className="mt-3 assign-items">
                            {
                                props.data.map(item => <SelectItem 
                                    id={item.key} 
                                    key={item.key} 
                                    group={item.group} 
                                    data={item.data}
                                    selectedItems={selectedItems[item.key] ?? []}
                                    onSelect={onSelect}
                                />)
                            }
                        </UncontrolledAccordion>
                </div> 
            </div>  
        </>
 
    );
}

export default memo(SelectItems);
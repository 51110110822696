import { axiosInstance } from "../../../../../redux/setupAxios";

export async function fetchEvents(
    vehicleId: string,
    payload: any,
    token: string
): Promise<any> {
    const { from, to } = payload;
    const params = `?from=${from}&to=${to}`;

    const url = `event/${vehicleId}${params}`;
    const data =  (await axiosInstance.get(url)).data
    return data;
}

import { SelectEventType } from "../../Alarms/redux/types";
import { FilterShareType, GetSharesResponse, ManageShareType } from "../types";

export const TOOGLE_POPUP = '[SHARE] TOOGLE_POPUP';
export type TooglePoupType = {
    type: typeof TOOGLE_POPUP;
    payload: boolean;
};
export function TooglePoup(payload: boolean): TooglePoupType {
    return { type: TOOGLE_POPUP, payload };
}

export const CREATE_SHARE_START = '[SHARE] CREATE_SHARE_START';
export type CreateShareStartType = {
    type: typeof CREATE_SHARE_START;
    payload: { licence?: any };
};
export function CreateShareStart(payload: any): CreateShareStartType {
    return { type: CREATE_SHARE_START, payload };
}

export const CREATE_SHARE_END = '[SHARE] CREATE_SHARE_END';
export type CreateShareEndType = {
    type: typeof CREATE_SHARE_END;
    payload: ManageShareType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateShareEnd(
    payload: ManageShareType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateShareEndType {
    return { type: CREATE_SHARE_END, payload };
}

export const ERROR = '[SHARE] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorShares(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_SHARES_START = '[SHARE] GET_SHARES_START';
export type GetSharesStartType = {
    type: typeof GET_SHARES_START;
    payload: FilterShareType;
};
export function GetSharesStart(payload: FilterShareType): GetSharesStartType {
    return { type: GET_SHARES_START, payload };
}

export const GET_SHARES_END = '[SHARE] GET_SHARES_END';
export type GetSharesEndType = {
    type: typeof GET_SHARES_END;
    payload: { result: GetSharesResponse; filter: FilterShareType };
};
export function GetSharesEnd(payload: {
    result: GetSharesResponse;
    filter: FilterShareType;
}): GetSharesEndType {
    return { type: GET_SHARES_END, payload };
}

export const SET_SELECTED_SHARES = '[SHARE] SET_SELECTED_SHARES';
export type SetSelectedSharesType = {
    type: typeof SET_SELECTED_SHARES;
    payload: string[];
};
export function SetSelectedShares(payload: string[]): SetSelectedSharesType {
    return { type: SET_SELECTED_SHARES, payload };
}

export const DELETE_SHARES_START = '[SHARE] DELETE_SHARES_START';
export type DeleteSharesStartType = {
    type: typeof DELETE_SHARES_START;
    payload: string[];
};
export function DeleteSharesStart(payload: string[]): DeleteSharesStartType {
    return { type: DELETE_SHARES_START, payload };
}

export const DELETE_SHARES_END = '[SHARE] DELETE_SHARES_END';
export type DeleteSharesEndType = {
    type: typeof DELETE_SHARES_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteSharesEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteSharesEndType {
    return { type: DELETE_SHARES_END, payload };
}

export const CLEAR_SHARE_CREATION = '[SHARE] CLEAR_SHARE_CREATION';
export type ClearShareCreationActionType = {
    type: typeof CLEAR_SHARE_CREATION;
};
export function ClearShareCreation(): ClearShareCreationActionType {
    return { type: CLEAR_SHARE_CREATION };
}

export const GET_SHARE_UPDATE_START = '[DRVIER] GET_SHARE_UPDATE_START';
export type GetShareUpdateStartType = {
    type: typeof GET_SHARE_UPDATE_START;
    payload: { _id: string };
};
export function GetShareUpdateStart(payload: {
    _id: string;
}): GetShareUpdateStartType {
    return { type: GET_SHARE_UPDATE_START, payload };
}

export const GET_SHARE_UPDATE_END = '[SHARE] GET_SHARE_UPDATE_END';
export type GetShareUpdateEndType = {
    type: typeof GET_SHARE_UPDATE_END;
    payload: { result: ManageShareType };
};
export function GetShareUpdateEnd(payload: {
    result: ManageShareType;
}): GetShareUpdateEndType {
    return { type: GET_SHARE_UPDATE_END, payload };
}

export const SET_VEHICLES_SHARE_TYPE = '[SHARE] SET_VEHICLES_SHARE_TYPE';
export type SetVehiclesShareActionType = {
    type: typeof SET_VEHICLES_SHARE_TYPE;
    payload: SelectEventType[];
};
export function SetVehiclesShareAction(
    payload: SelectEventType[]
): SetVehiclesShareActionType {
    return { type: SET_VEHICLES_SHARE_TYPE, payload };
}

export const SET_SHARE_EXPIRED_DATE_TYPE =
    '[SHARE] SET_SHARE_EXPIRED_DATE_TYPE';
export type SetShareExpiredDateActionType = {
    type: typeof SET_SHARE_EXPIRED_DATE_TYPE;
    payload: number;
};
export function SetShareSubscriptionDateAction(
    payload: number
): SetShareExpiredDateActionType {
    return { type: SET_SHARE_EXPIRED_DATE_TYPE, payload };
}
export type ShareActionType =
    | TooglePoupType
    | CreateShareStartType
    | CreateShareEndType
    | ErrorActionType
    | GetSharesStartType
    | GetSharesEndType
    | SetSelectedSharesType
    | DeleteSharesStartType
    | DeleteSharesEndType
    | ClearShareCreationActionType
    | GetShareUpdateStartType
    | GetShareUpdateEndType
    | SetVehiclesShareActionType
    | SetShareExpiredDateActionType;

import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardHeader } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { AsyncInputSelect } from "../../../Components/Form/AsyncInputSelect";
import { useAppSelector } from "../../../redux/hooks";
import { fetchUsersByName, fetchUsersMobileByName } from "../../Authentification/_redux/authService";
import { SetEventNotificationAction } from "../redux/events.actions";
import { validateEmail } from "../../../helpers/validationHelper";
import { InputCreatableSelect } from "../../../Components/Form/InputCreatableSelect";



export const Notification = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {_id} = useParams();

    const isUpdate = _id !== 'add';

    const [inputValue, setInputValue] = useState('');
    
    const { manage } = useAppSelector((state) => state.eventsReducer);
    const { notification } = manage;

    const changeUserNotification = (key: 'mobile' | 'user' | 'email', element: any): void => {
        dispatch(SetEventNotificationAction({ key, value: element }));
    };

    const handleChange = (el: any, lastValue?: string): void => {
        if (notification?.email && lastValue) {
            const data = notification.email.find((e) => {
                return e.value === lastValue;
            });
            if (data) {
                return;
            }
        }
        dispatch(SetEventNotificationAction({ key: 'email', value: el }));
    };

    const handleInputChange = (inValue: string): void => {
        setInputValue(inValue);
    };

    const handleKeyDown = (event: any): void => {
        if (!inputValue) return;
        // eslint-disable-next-line default-case
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                const validEmail = validateEmail(inputValue);
                if (validEmail) {
                    handleChange(
                        [
                            ...(notification?.email ?? []),
                            { label: inputValue, value: inputValue },
                        ],
                        inputValue
                    );
                }
                setInputValue('');
                event.preventDefault();
            }
        }
    };

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="ALARM.NOTIFICATION" /></h4>
            </CardHeader>
            <CardBody>
                {
                 manage.events.length > 0 &&  ((isUpdate && manage.name) || !isUpdate) && <AsyncInputSelect 
                        isMulti
                        isClearable
                        label={intl.formatMessage({ id: 'ALARMS.NOTIFICATION_PLATFORM'})}
                        cacheOptions
                        defaultOptions
                        defaultValue={manage.notification?.user ?? []}
                        loadOptions={fetchUsersByName}
                        onChange={(element: any) => changeUserNotification('user', element)}
                    />
                }
                                {
                manage.events.length > 0 &&  ((isUpdate && manage.name) || !isUpdate) &&  <AsyncInputSelect 
                        isMulti
                        isClearable
                        label={intl.formatMessage({ id: 'ALARMS.NOTIFICATION_MOBILE'})}
                        cacheOptions
                        defaultOptions
                        defaultValue={manage.notification?.mobile ?? []}
                        loadOptions={fetchUsersMobileByName}
                        onChange={(element: any) => changeUserNotification('mobile', element)}
                    />
                }
                {
                    <InputCreatableSelect 
                        components={{ DropdownIndicator: null }}
                        isClearable
                        isMulti
                        label={intl.formatMessage({ id: 'ALARMS.NOTIFICATION_EMAIL'})}
                        inputValue={inputValue}
                        value={notification?.email ?? []}
                        menuIsOpen={false}
                        onChange={(element: any) => handleChange(element)}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                    />
                }
            </CardBody>
        </CardUi>               
    );
}

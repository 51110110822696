import { useAxios } from "../../../../common/hooks/axios/useAxios";

type UseMarkUnreadNotificationsResponse = { 
  error?: string;
  markAsyncNotificationsAsRead: (params: MarkNotificationsAsReadProps) => Promise<{
    success: {success: boolean, updated: boolean};
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}

type UseMarkUnreadNotificationsProps = {
  manual?: boolean,
}

type MarkNotificationsAsReadProps = {
  keys: string[]
}


export function useMarkUnreadNotifications(props: UseMarkUnreadNotificationsProps): UseMarkUnreadNotificationsResponse {
  const URL = `notifications/mark-as-read`;
  const { execute, cancel, error } = useAxios<{total: number}>(props.manual);

  const markAsyncNotificationsAsRead = async (body: MarkNotificationsAsReadProps): Promise<{
    success: {success: boolean, updated: boolean};
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}> => {
    return execute(URL, { method: "PATCH", data: body});
  };

  return { 
    error,
    markAsyncNotificationsAsRead,
    cancel, 
  };
}

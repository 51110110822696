import classNames from "classnames";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle, AiOutlineSearch } from "react-icons/ai";
import { Form, FormFeedback, Label } from "reactstrap";
import  { Input, InputProps } from "reactstrap";
import { ButtonUi } from "../basic/ButtonUi";
import { FiRefreshCw } from "react-icons/fi";
import { IF } from "../If";

type InputSearchType =  Omit<InputProps, 'valid'|'invalid'|'type'> & {label?: string, onSubmit: (data: any) => void, onRefresh?: (data: any) => void};
export const InputSearch = (props: InputSearchType) => {
    const {onSubmit, label, error, ...propsInput} = props; 
    const { register, handleSubmit, watch, getValues, resetField } = useForm();
    const firstMount = useRef<boolean>(false);
    const {ref, ...inputRegister} = register(props.name) as any;
    const name = watch(`${props.name}`);
   
    React.useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if(firstMount.current) {
                const value = getValues(props.name);
                onSubmit({[props.name]: value});
            }
        }, 500);
        
        return () => clearTimeout(delayDebounceFn);  
    }, [name]);
    

    const clearText = () => {
        resetField(props.name);
    }

    const refresh = () => {
        
        if(props.onRefresh) {
            const value = getValues(props.name);
            props.onRefresh({[props.name]: value});
        }
    }

    const sizeClassName = classNames({ [`${props.size ?? ''}`]: !!props.size });
    return (
        <React.Fragment>
            <Form className="input-search position-relative" onSubmit={handleSubmit(onSubmit)}>
                { propsInput.id && label && <Label for={propsInput.id}> {label} </Label> }
                <div className="d-flex align-items-center">
                    <div className="position-relative flex-grow-1">
                        <Input 
                            type="text"
                            {...inputRegister} 
                            {...propsInput}
                            innerRef={ref} 
                            invalid={!!error}
                            onKeyPress={()=> firstMount.current = true}
                        />
                        
                        {
                            (name && name.length > 0) 
                                ? <AiFillCloseCircle className={`clear-text-icon ${sizeClassName}`} onClick={clearText}/> 
                                : <AiOutlineSearch className={`search-text-icon ${sizeClassName}`} />
                        }
                    </div>
                    <IF condition={!!props.onRefresh}>
                        <ButtonUi className="ms-1" size={props.size} color="secondary" onClick={refresh}> <FiRefreshCw/></ButtonUi>
                    </IF>
                </div>

                
                { error && error.message && <FormFeedback valid={false}>{error.message}</FormFeedback> }
            </Form>
        </React.Fragment>
    );
};
import { useAxios } from "../useAxios";

export type GeoJsonDto = {
  type: string;
  coordinates: [number, number];
}

type ManageStopProps = {
    key?: string
    name: string;
    description: string;
    color: string;
    radius: string;
    latitude: number;
    longitude: number;
}

type UseManageStopResponse = { 
  response: boolean | undefined;
  manageStopLoading: boolean;
  error?: string;
  saveStop: (payload: ManageStopProps) => void;
  saveAsyncStop: (payload: ManageStopProps) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}

type UseManageStopProps = {
  manual?: boolean
  key?: string,
}

export function useManageStop(props: UseManageStopProps): UseManageStopResponse {
  const URL = `stops/${props.key ?? ''}`;
  const { execute, data, cancel, error, loading } = useAxios<UseManageStopResponse>(props.manual);

  const saveStop = (data: ManageStopProps) => {
    saveAsyncStop(data);
  };

  const saveAsyncStop = async (data: ManageStopProps) => {
    const { latitude, longitude, radius, ...mandatory } = data;
   const payload:any = {...mandatory};
   if(latitude && longitude) {
    payload.location = { type: 'Point', coordinates: [longitude, latitude] };
   }
   payload.radius= parseInt(radius, 10);

    return execute(URL, { method: mandatory.key ? "PATCH" : "POST", data: payload });
  };

  return { 
    response: data?.response,
    manageStopLoading: loading, 
    error,
    saveStop,
    saveAsyncStop, 
    cancel, 
  };
}

import * as fuelActions from './fuelActions';
import { FuelActionsType } from './fuelActions';
import { FuelChartType, FuelFillType, RangeType } from './types';

type StateType = {
    loading: boolean;
    showFuel: boolean;
    fuel: FuelFillType[];
    activeFuel?: FuelFillType & { index: number };
    fuelPeriod?: RangeType;
    chart?: FuelChartType;
};

const INITIAL_STATE: StateType = {
    loading: false,
    showFuel: true,
    fuel: [],
};

export function fuelReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: FuelActionsType
): StateType {
    switch (action.type) {
        case fuelActions.GET_START:
            return {
                ...state,
                loading: true,
                fuelPeriod: action.payload,
            };

        case fuelActions.GET_END: {
            const fuel = action.payload;
            return { ...state, fuel, loading: false };
        }

        case fuelActions.ERROR:
            return { ...state, loading: false };

        case fuelActions.SET_ACTIVE_END:
            if (
                state.activeFuel &&
                state.activeFuel.index === action.payload.index
            ) {
                return { ...state, activeFuel: undefined };
            }
            return { ...state, activeFuel: action.payload };

        case fuelActions.TOOGLE_FUEL:
            return {
                ...state,
                showFuel:
                    action.payload === undefined
                        ? !state.showFuel
                        : action.payload,
            };
        case fuelActions.GET_START_CHART:
            return {
                ...state,
                loading: true,
            };

        case fuelActions.GET_END_CHART: {
            if (action.payload) {
                const { date, fuel } = action.payload;
                return {
                    ...state,
                    loading: false,
                    chart: {
                        fuel,
                        date,
                    },
                };
            }
            return {
                ...state,
                loading: false,
                chart: undefined,
            };
        }
        case fuelActions.INIT_FUEL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

import { useAxios } from "../useAxios";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
  }

type ManagePassengerProps = {
    key?: string
    name: string;
    identifier?: string;
    phone?: string;
    latitude?: number,
    longitude?: number,
    address?: string;
    stop: { label: string, value: string };

}

type UseManagePassengerResponse = { 
  response: boolean | undefined;
  managePassengerLoading: boolean;
  error?: string;
  savePassenger: (payload: ManagePassengerProps) => void;
  saveAsyncPassenger: (payload: ManagePassengerProps) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}



type UseFetchReportItemsProps = {
  manual?: boolean
  key?: string,
}

export function useManagePassenger(props: UseFetchReportItemsProps): UseManagePassengerResponse {
  const URL = `passengers/${props.key ?? ''}`;
  const { execute, data, cancel, error, loading, init } = useAxios<UseManagePassengerResponse>(props.manual);

  const savePassenger = (data: ManagePassengerProps) => {
    saveAsyncPassenger(data);
  };

  const saveAsyncPassenger = async (data: ManagePassengerProps) => {
    const { latitude, longitude, identifier, phone, address, ...mandatory } = data;
   const payload:any = {...mandatory};
   if(latitude && longitude) {
    payload.location = { type: 'Point', coordinates: [latitude, longitude] };
   }
   if(identifier) {
    payload.identifier = identifier;
   }
   if(phone) {
    payload.phone = phone;
   }
   if(address) {
    payload.address = address;
   }

    return execute(URL, { method: mandatory.key ? "PATCH" : "POST", data: payload });
  };

  return { 
    response: data?.response,
    managePassengerLoading: loading, 
    error,
    savePassenger,
    saveAsyncPassenger, 
    cancel, 
  };
}

import { useContext } from "react";
import Plotly from "plotly.js-cartesian-dist";
import { LayoutContext } from "../../context/layout-style-context";
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

export const BasicPlot = (props) => {
    const { layoutMode } = useContext(LayoutContext);
    const propsCustom = {...props}
    if(layoutMode === 'dark') {
        propsCustom.layout = {
            ...propsCustom.layout,
            "font": {
                "color": "#f2f5fa"
            },
            "paper_bgcolor": "#1a1d21",
            "plot_bgcolor": "#1a1d21",
        }
    }
    propsCustom.config = {
        ...propsCustom.config,
        displaylogo: false
    }
    return <Plot {...propsCustom}></Plot>
}
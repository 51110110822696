import { BillingInList, ManageBillingType } from '../types';
import {
    BillingActionType,
    CLEAR_BILLING_CREATION,
    CREATE_BILLING_END,
    CREATE_BILLING_START,
    DELETE_BILLINGS_END,
    DELETE_BILLINGS_START,
    ERROR,
    GET_BILLINGS_END,
    GET_BILLINGS_START,
    GET_BILLING_UPDATE_END,
    GET_BILLING_UPDATE_START,
    SET_MODULES_BILLING_TYPE,
    SET_NOMINATION_BILLING_TYPE,
    SET_SELECTED_BILLINGS,
    TOOGLE_POPUP
} from './billing.actions';

type StateType = {
    loading: boolean;
    popup: boolean;
    manage: ManageBillingType;
    selectedBillings: string[];
    filter: {
        limit: number;
        skip: number;
    };
    billings: {
        list: BillingInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    popup: false,
    billings: {
        list: [],
        total: 0,
    },
    selectedBillings: [],
    manage: {
        name: '',
        modules: [],
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function billingReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: BillingActionType
): StateType {
    switch (action.type) {
        case CREATE_BILLING_START:
        case GET_BILLINGS_START:
        case GET_BILLING_UPDATE_START:
        case DELETE_BILLINGS_START: {
            return { ...state, loading: true };
        }

        case TOOGLE_POPUP:
            return {
                ...state,
                popup: action.payload,
            };

        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }
        case CREATE_BILLING_END: {
            const list = [...state.billings.list];
            const { _id, modifiedCount } = action.payload;
            const updatedBilling: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const billingIndex = state.billings.list.findIndex(
                        (billing) => billing.key === _id
                    );
                    if (billingIndex !== -1) {
                        list[billingIndex] = {
                            ...list[billingIndex],
                            ...updatedBilling,
                        };
                    }
                }
            } else {
                list.unshift(updatedBilling);
            }

            return {
                ...state,
                loading: false,
                billings: {
                    list,
                    total: state.billings.total + 1,
                },
                popup: false,
                selectedBillings: [],
            };
        }

        case GET_BILLINGS_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                billings: result,
                filter,
                loading: false,
            };
        case SET_SELECTED_BILLINGS:
            return {
                ...state,
                selectedBillings: action.payload,
            };
        case DELETE_BILLINGS_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedBillings:
                        state.selectedBillings.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    billings: {
                        total: state.billings.total - deletedCount,
                        list:
                            state.billings.list.filter(
                                (billing) => !_ids.includes(billing.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_BILLING_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                popup: true,
                loading: false,
            };
        }

        case SET_NOMINATION_BILLING_TYPE: {
            const newManage = {
                ...state.manage,
                ...action.payload,
            };
            if (
                newManage.description === undefined ||
                newManage.description.length === 0
            ) {
                delete newManage.description;
            }
            return {
                ...state,
                manage: newManage,
            };
        }

        case CLEAR_BILLING_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
            };
        }

        case SET_MODULES_BILLING_TYPE: {
            const { module } = action.payload;
            let modules = [...state.manage.modules];
            if (modules.includes(module)) {
                modules = modules.filter((newModule) => newModule !== module);
            } else {
                modules.push(module);
            }
            return {
                ...state,
                manage: {
                    ...state.manage,
                    modules,
                },
            };
        }
        default:
            return state;
    }
}

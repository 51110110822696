import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardFooter, Col, Row } from "reactstrap";
import { Avatar } from "../../../../Components/basic/avatar/avatar";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { CardUi } from "../../../../Components/basic/Card";
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect";
import { Tabs } from "../../../../Components/Tabs";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeEventLogic, SetSelectSubTypeAlarmAction, SetSelectTypeAlarmAction, AddNewEvent, RemoveNewEvent } from "../../redux/events.actions";
import { findAlarmsSubType, findAlarmsType } from "../../redux/events.service";
import { BooleanType } from "./BooleanType";
import { OperationValueType } from "./OperationValueType";
import { ValueType } from "./ValueType";
import { ZonesSelector } from "./ZonesType";

export const ConditionItem = ({position}: {position: number}) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();

    const isUpdate = _id !== 'add';

    const FILTERS: {label: string, value: '||' | '&&'}[] = [
        { label: 'COMMON.AND', value: '&&' },
        { label: 'COMMON.OR', value: '||' },
    ];

    const { manage } = useAppSelector((state) => state.eventsReducer);

    const handleChangeType = (data: any) => {
        switch(true) {
            case data === null:
                dispatch(SetSelectTypeAlarmAction({position}));
                break;
            case manage.events.length === 0 || ( data.value !== manage.events[position].type?.value) :
                dispatch(SetSelectTypeAlarmAction({position, data}));
                break;
        }
    }

    const handleChangesubType = (data: any) => {
        if(data) {
            dispatch(SetSelectSubTypeAlarmAction({position, data}))
        } else {
            dispatch(SetSelectSubTypeAlarmAction({position}));
        } 
    }

    const isBoolean = manage.events.length > 0 &&  manage.events[position].subType?.type === "boolean";
    const isNumberOrCustom = manage.events.length > 0 &&  (manage.events[position].subType?.type === "number" ||  manage.events[position].subType?.type === "Custom");
    const isSpeed = manage.events.length > 0 &&  (manage.events[position].subType?.type === "road" || manage.events[position].subType?.type === "custom");
    const isZone = manage.events.length > 0 &&  manage.events[position].type?.value === "Zone";
    const isFuel = manage.events.length > 0 &&  manage.events[position].type?.value === "Fuel";

    const defaultActive = useMemo(()=> manage.events.length > 0 ?  FILTERS.findIndex(f => f.value === manage.events[position].logic) : 0, []);

    const canAddNewCondition = !!manage.events[position].subType && 
        (
            ((isBoolean || isSpeed) && (!!manage.events[position].value) 
            || 
            (isNumberOrCustom && (!!manage.events[position].value) && (!!manage.events[position].operation)))
            || 
            isFuel
            || 
            (isZone &&  manage.events[position].zones !== undefined)
        );

    return (
        <div className="condition-item">
            {
                position > 0 &&<Row className="mb-4">
                <Col>
                    <Tabs 
                        defaultActive={defaultActive !== -1 ? defaultActive : 0}
                        color="primary" 
                        pills
                        className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                        onChange={(index: number) => dispatch(ChangeEventLogic({position, logic: FILTERS[index].value}))}
                    >
                        { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/></div>)}
                    </Tabs>
                </Col>
                </Row>
            }
                <CardUi noShadow>
                    <CardBody>
                        <Avatar className="me-4" size="xs" color="primary" text={`${position}`} rounded="rounded-circle"/>
                        <Row>
                            <Col xl={3}>
                                {
                                    manage.events.length > 0 &&  ((isUpdate && manage.name) || !isUpdate) &&  <AsyncInputSelect 
                                        isClearable
                                        label={intl.formatMessage({ id: 'ALARM.TYPE'})}
                                        error={!manage.events[position].type}
                                        cacheOptions
                                        defaultOptions
                                        defaultValue={manage.events[position].type}
                                        loadOptions={(typedText: string)=> findAlarmsType('teltonika', typedText)}
                                        onChange={(data: number)=> handleChangeType(data)}
                                    />
                                }
                                
                            </Col>
                            {
                                manage.events.length > 0 && <Col xl={isZone ? 9 : 4}>
                                    <AsyncInputSelect 
                                        key={`select__${manage.events[position].type?.value}`}
                                        isClearable
                                        label={intl.formatMessage({ id: 'ALARM.SUB_TYPE'})}
                                        error={!manage.events[position].subType}
                                        cacheOptions
                                        defaultOptions
                                        defaultValue={manage.events[position].subType}
                                        loadOptions={(typedText: string)=> findAlarmsSubType('teltonika', manage.events[position].type?.value, typedText)}
                                        onChange={(data: any)=> handleChangesubType(data)}
                                    />
                                </Col>
                            }

                            {
                                isBoolean && <Col xl={5}>
                                    <BooleanType position={position} selected={manage.events[position].value}/> 
                                </Col>
                            }

                            {
                                isNumberOrCustom && <Col xl={5}>
                                    <OperationValueType position={position} selected={manage.events[position].operation}/> 
                                </Col>
                            }

                            {
                                isSpeed  && <Col xl={5}>
                                    <ValueType position={position} selected={manage.events[position].operation}/> 
                                </Col>
                            }
                            
                            {
                                isZone  && <Col xl={12}>
                                    <ZonesSelector position={position} /> 
                                </Col>
                            }
                        </Row>
                    </CardBody>
                    <CardFooter>
                        
                        <ButtonUi 
                            color="success" 
                            size={"sm"} 
                            soft
                            onClick={() => dispatch(AddNewEvent({position}))}
                            disabled={!canAddNewCondition}
                        >
                            <FormattedMessage id="ALARM.ADD_CONDITION"/>
                        </ButtonUi>
                        
                        {
                            position > 0 && <ButtonUi 
                                className="ms-2" 
                                color="danger" 
                                soft 
                                size={"sm"}
                                onClick={() => dispatch(RemoveNewEvent({position}))}
                            >
                                <FormattedMessage id="COMMON.DELETE"/>
                            </ButtonUi>
                        }
                    </CardFooter>
                </CardUi> 
        </div>
            
    );
}

import Tree from 'antd/es/tree';
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineCar } from 'react-icons/ai';
import { BsBoxSeam } from 'react-icons/bs';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonUi } from '../../Components/basic/ButtonUi';
import { LoaderUi } from '../../Components/basic/LoaderUi';
import { InputSearch } from '../../Components/Form/InputSearch';
import { ScrollBar } from '../../Components/PerfectScrollBar';
import { getVehicleToAssign } from '../Vehicles/redux/vehicleModule.service';
import { GroupToAssign, VehicleToAssign } from '../Vehicles/types';

// import './assignVehicles.scss';

type AssignVehiclesType = {
    _id?: string;
    selectedVehicles?: string[];
    onSubmit: (checked: string[]) => void;
    onCancel: () => void;
};

export function AssignVehicles({
    _id,
    selectedVehicles = [],
    onSubmit,
    onCancel,
}: AssignVehiclesType): JSX.Element {
    const refScrollBar = useRef();
    const intl = useIntl();
    const [name, setName] = useState('');
    const [tree, setTree] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>(selectedVehicles);

    const fetchAll = async (): Promise<void> => {
        setLoading(true);
        const { units, groups } = await getVehicleToAssign({ name });
        const newGroups = groups
            ? groups.map((grp: GroupToAssign) => {
                  const newGrp: any = {
                      key: grp.key,
                      disabled: grp.units.length === 0,
                      title: (
                          <div className="tree-item">
                              <BsBoxSeam />
                              <span className="ms-1">{grp.value}</span>
                          </div>
                      ),
                  };
                  newGrp.children = grp.units.map((unit: VehicleToAssign) => {
                      return {
                          key: `${grp.key}#${unit.key}`,
                          title: (
                              <div className="tree-item">
                                  <AiOutlineCar />
                                  <span>{unit.value}</span>
                              </div>
                          ),
                      };
                  });
                  return newGrp;
              })
            : [];

        const newUnits = units
            ? units.map((vehicle: VehicleToAssign) => {
                  return { key: `#${vehicle.key}`, title: vehicle.value };
              })
            : [];
           
        setTree([...newGroups, ...newUnits]);
        setLoading(false);
    };

    useEffect(() => {
        fetchAll();
    }, [name]);

    const treeData = [
        {
            key: 'all',
            title: intl.formatMessage({id: 'COMMON.SELECT_ALL'}),
            children: tree,
        },
    ];

    const onCheck = (checked: any): void => {
        setSelected(checked);
    };

    const onSearch = (name: {searchBy: string}): void => {
        setName(name.searchBy);
    };

    return (
        <>
            <LoaderUi loading={isLoading} />
            <InputSearch
                className="mb-4"
                type="text"
                name={'searchBy'}
                placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                onSubmit={onSearch}
            />
             <ScrollBar
                id="detail-item-scrollbar"
                containerRef={refScrollBar}
                minusHeight={200}
            > 
                <div className="assign-vehicles">
                    <Tree
                        defaultExpandedKeys={['all']}
                        checkedKeys={selected}
                        checkable
                        showLine={false}
                        showIcon={false}
                        onCheck={onCheck}
                        treeData={treeData}
                        selectable={false}
                    />
                </div>
            </ScrollBar> 
            <div className="d-flex flex-row align-items-center justify-content-sm-around">
                <ButtonUi
                    color="primary"
                    soft
                    onClick={onCancel}
                >
                    <FormattedMessage id="COMMON.CANCEL" />
                </ButtonUi>
                <ButtonUi
                    color="primary"
                    onClick={() => onSubmit(selected)}              >
                    <FormattedMessage id="COMMON.UPDATE" />
                </ButtonUi>
            </div>
        </>
    );
}

import { Timeline } from 'antd'
import CanteenIcon from '../advanced/Passenger/icon/CanteenIcon'
import CoffeeCupIcon from '../advanced/Passenger/icon/CoffeeIcon'
import DoorOpenIcon from '../advanced/Passenger/icon/DoorOpenIcon'
import DotCircleIcon from '../advanced/Passenger/icon/DotCircleIcon'
import ToolsIcon from '../advanced/Passenger/icon/ToolsIcon'


export const TimelineUi = () => {
  const timelineData = [
    {
      dot: <DotCircleIcon />,
      title: 'Arived',
      description: `Aujourd'hui à 7h00`,
    },
    {
      dot: <DoorOpenIcon />,
      title: 'Locker room',
      description: `De 7h05 à 7h28`,
    },
    {
      dot: <ToolsIcon />,
      title: 'Plan',
      description: `De 7h30 à 12h00`,
    },
    {
      dot: <CanteenIcon />,
      title: 'Canteen',
      description: `De 12h05 à 12h35`,
    },
    {
      dot: <CoffeeCupIcon />,
      title: 'Break Area',
      description: `De 12h40 à 12h55`,
    },
    {
      dot: <DotCircleIcon />,
      title: 'Depart',
      description: `Aujourd'hui à 7h05`,
    },
  ]

  return (
    <div>
      <img
        src="/images/BuildingLayoutPlaceholder.png"
        alt="Building Layout Placeholder"
        className="img-fluid"
      />
      <Timeline mode="left" className="mt-4" style={{ marginLeft: '30px' }}>
        {timelineData.map((item, index) => (
          <Timeline.Item key={index} dot={item.dot}>
            <p className="mb-2 fw-bold">{item.title}</p>
            <p className="mb-0">{item.description}</p>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  )
}

import { isNumber } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl"
import ColorPicker from "react-pick-color";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormGroup, Label } from "reactstrap"
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { InputSelect } from "../../../Components/Form/InputSelect";
import { InputText } from "../../../Components/Form/InputText"
import { useAppSelector } from "../../../redux/hooks";
import { GeofenceType } from "../types";
import { ClearGeofenceDraw, CreateGeofenceStart, GetGeofenceUpdateStart, SetGeofenceColor, SetGeofencePoint, SetGeofenceRadius, SetGeofenceType } from "../_redux/geofenceModule.actions";

export const ManageGeofence = () => {
    const intl = useIntl();
    const { _id } = useParams();
    const isUpdate = !!_id && _id !== 'add';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { manage } = useAppSelector((state) => state.geofenceModuleReducer);

    const [canChooseColor, setChooseColor] = useState(false);
    
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({defaultValues: {
        name: manage.name, description: manage.description, radius: manage.radius, longitude: 0, latitude: 0
    }});
    const OPTIONS = [
        {
            label: "Polygon",
            value: GeofenceType.POLYGONE
        },
        {
            label: "Cércle",
            value: GeofenceType.CIRCLE
        },
        {
            label: "Point d'intérêt",
            value: GeofenceType.POINT_OF_INTEREST
        }
    ];

    // EFFECTS
    useEffect(() => {
        if(isUpdate) {
            dispatch(GetGeofenceUpdateStart({_id}))
        }
        return () => {
            dispatch(ClearGeofenceDraw());
        }
    }, []);

    useEffect(()=> {
        if(manage.name && manage.name.length > 0) {
            const {...data } = manage;
            const geofenceInfo: any = data;
            Object.keys(data).forEach((key: any) => setValue(key, geofenceInfo[key]));    
        }
    }, [manage.key]);

    useEffect(() => {
        if(manage.radius) {
            setValue('radius', Math.round(manage.radius));
        }
    }, [manage.radius]);

    useEffect(()=> {
        if(manage.geofence?.type === 'Point' && Array.isArray(manage.geofence.coordinates)) {
            setValue('latitude', manage.geofence.coordinates[1]);
            setValue('longitude', manage.geofence.coordinates[0]);
        } else {
            setValue('latitude', 0);
            setValue('longitude', 0);
        }    
    }, [manage.geofence]);

    // EVENTS
    const handleChangeType = (data: any) => {
        const {  value } = data;
        dispatch(SetGeofenceType({type: value}));
    }

    const changeRadius = (event: ChangeEvent<HTMLInputElement>) => {
        if (event) {
            const newRadiusValue = parseInt(event.target.value, 10);
            if(newRadiusValue >= 1) {
                dispatch(SetGeofenceRadius({radius: newRadiusValue}));
            }  
        }
    }

    const changeLatitude = (event: ChangeEvent<HTMLInputElement>) => {
        if (event && Array.isArray(manage.geofence.coordinates)) {
            const latitude = parseFloat(event.target.value);
            if(isNumber(latitude) && !isNaN(latitude)) {
                dispatch(SetGeofencePoint({point: {latitude, longitude: manage.geofence.coordinates[0]}}));
            }
        }
    }


    const changeLongitude = (event: ChangeEvent<HTMLInputElement>) => {
        if (event &&  Array.isArray(manage.geofence.coordinates)) {
            const longitude = parseFloat(event.target.value);
            if(isNumber(longitude) && !isNaN(longitude)) {
                dispatch(SetGeofencePoint({point: {latitude: manage.geofence.coordinates[1], longitude}}));
            }
        }
    }

    

    const setColor = (color: string) => dispatch(SetGeofenceColor({color}));
    const onCancel = () => navigate('/geofences');
    const submit = (data: any) => {
        const {latitude, longitude, radius, ...other} = data;
        const payload = { ...other, radius: +radius, color: manage.color, type: manage.type, geofence: manage.geofence };
        if(manage.key) { payload.key = manage.key; }

        dispatch(CreateGeofenceStart(payload));
    };

    return (
        <Form className="p-3" onSubmit={handleSubmit(submit)}>
            
            <FormGroup>
                <InputSelect 
                    key={`select__polygon`}
                    label={intl.formatMessage({ id: 'ALARM.OPERATION'})}
                    options={OPTIONS}
                    value={OPTIONS.find(type => type.value === manage.type) ?? []}
                    onChange={handleChangeType}
                    isDisabled={isUpdate}
                />
            </FormGroup>
            
            <FormGroup>
                <InputText
                    type="text"
                    label={intl.formatMessage({id: 'GEOFENCE.NAME'})}
                    id={'name'}
                    name={'name'}
                    placeholder={intl.formatMessage({id: 'GEOFENCE.PLACEHOLDER.NAME'})}
                    register={register('name', {
                        required: {
                            value: true, 
                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                        }})}
                    error={errors['name']}
                />
             </FormGroup>
            <FormGroup>
                <InputText
                    type="textarea"
                    label={intl.formatMessage({id: 'GEOFENCE.DESCRIPTION'})}
                    id={'description'}
                    name={'description'}
                    placeholder={intl.formatMessage({id: 'GEOFENCE.PLACEHOLDER.DESCRIPTION'})}
                    register={register('description')}
                    error={errors['description']}
                />
            </FormGroup>
            <FormGroup className="position-relative">
                <div className="d-flex flex-row align-items-center" onClick={()=> setChooseColor(!canChooseColor)}>
                    <h6 className="font-size-sm mb-0">Couleur de la géofence :</h6>
                    <div
                        className="rounded ms-2"
                        style={{ backgroundColor: manage.color, height: 18,
                        width: 18 }}
                    />
                    
                </div>
                {
                    canChooseColor && 
                    <div style={{position: 'absolute', top: -320}}>
                        <div onMouseLeave={()=> setChooseColor(false)}>
                            <ColorPicker color={manage.color} onChange={(color) => setColor(color.hex)}/>
                        </div>
                        
                    </div>
                }
            </FormGroup>
            {
                (manage.type === GeofenceType.CIRCLE || manage.type === GeofenceType.POINT_OF_INTEREST) && <>
                     <FormGroup>
                        <InputText
                            type="number"
                            min={1}
                            label={intl.formatMessage({id: 'GEOFENCE.RADIUS'})}
                            id={'radius'}
                            name={'radius'}
                            placeholder={intl.formatMessage({id: 'GEOFENCE.PLACEHOLDER.RADIUS'})}
                            register={register('radius', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            onChange={changeRadius}
                            error={errors['radius']}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label> <FormattedMessage id="GEOFENCE.COORDINATES"/> </Label>
                        <div className="d-flex">
                            <InputText
                                className="me-2"
                                type="number"
                                step="any"
                                id={'latitude'}
                                name={'latitude'}
                                placeholder={intl.formatMessage({id: 'GEOFENCE.LATITUDE'})}
                                register={register('latitude', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                onChange={changeLatitude}
                                error={errors['latitude']}
                            />
                            <InputText
                                type="number"
                                step="any"
                                id={'longitude'}
                                name={'longitude'}
                                placeholder={intl.formatMessage({id: 'GEOFENCE.LONGITUDE'})}
                                register={register('longitude', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                onChange={changeLongitude}
                                error={errors['longitude']}
                            />
                        </div>
                    
                    </FormGroup>
                </>
            } 
           
            <div className="d-flex flex-row align-items-center justify-content-sm-end mt-4">
                <ButtonUi className="me-2" color="primary" outline
                    onClick={onCancel}
                >
                    <FormattedMessage id="COMMON.CANCEL" />
                </ButtonUi>
                <ButtonUi color={"primary"} type="submit">
                    {
                        isUpdate ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                    }
                </ButtonUi> 
            </div>
        </Form>
    )
}
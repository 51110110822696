import { MdOutlineFoodBank } from 'react-icons/md'

export type FoodBankIconProps = { size?: number }

export default function CanteenIcon({
  size = 22,
}: FoodBankIconProps): JSX.Element {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <MdOutlineFoodBank style={{ fontSize: size * 0.7, color: 'white' }} />
    </div>
  )
}

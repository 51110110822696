import { useEffect, useState } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { CardUi } from "../../../../Components/basic/Card";
import { InputSelect } from "../../../../Components/Form/InputSelect";
import { InputText } from "../../../../Components/Form/InputText";
import { passwordValidator } from "../../../../helpers/validationHelper";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeUserType } from "../../redux/user.actions";
import { getTypesUser } from "../../redux/user.service";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any},
   watch: UseFormWatch<FieldValues>
}

export const General = ({register, watch ,errors}: ManageCompanyPageType) => {
    const {_id} = useParams();
    const isUpdate = _id !== 'add';
    const dispatch = useDispatch();
    const intl = useIntl();
    const validatorPassword = passwordValidator(_id === 'add');

    const [types, setTypes] = useState<{label: string, value: string}[]>([]);
    const { manage } = useAppSelector((state) => state.userReducer);

    const changeUserType = (element: any): void => {
        dispatch(ChangeUserType(element.value));
    };

    const getUserTypes = async () => {
        const userTypes = await getTypesUser();
        setTypes(userTypes.map(type => {
            return {
                label: intl.formatMessage({id: type.label}),
                value: type.value
            }
        }));
    }

    useEffect(() => {
        getUserTypes();
    }, []);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <InputText
                        type="text"
                        label={intl.formatMessage({id: 'USER.NAME'})}
                        id={'name'}
                        name={'name'}
                        placeholder={intl.formatMessage({id: 'USER.PLACEHOLDER.NAME'})}
                        register={register('name', {
                            required: {
                                value: true, 
                                message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                            }})}
                        error={errors['name']}
                    />
                </FormGroup>
                <Row>
                <Col xl={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                autocomplete={false}
                                label={intl.formatMessage({id: 'USER.USERNAME'})}
                                id={'username'}
                                name={'username'}
                                disabled={isUpdate}
                                placeholder={intl.formatMessage({id: 'USER.PLACEHOLDER.USERNAME'})}
                                register={register('username', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                error={errors['username']}
                            />
                        </FormGroup>
                    </Col>
                    {
                        types.length > 0 && <Col xl={6}>
                            <FormGroup>
                                <InputSelect
                                    label={intl.formatMessage({ id: 'USER.TYPE' })}
                                    value={types.find(type => manage.type === type.value) ?? []}
                                    options={types}
                                    onChange={(element) => changeUserType(element)}
                                />
                            </FormGroup>
                        </Col>
                    }
                   
                </Row>
                
                <FormGroup>
                    <InputText
                        type="password"
                        label={intl.formatMessage({id: 'USER.PASSWORD'})}
                        id={'password'}
                        name={'password'}
                        placeholder={intl.formatMessage({id: 'USER.PLACEHOLDER.PASSWORD'})}
                        register={register('password' as any, validatorPassword)}
                        error={errors['password']}
                    />
                </FormGroup>
                <FormGroup>
                    <InputText
                        type="password"
                        label={intl.formatMessage({id: 'USER.CONF_PASSWORD'})}
                        id={'confPassword'}
                        name={'confPassword'}
                        placeholder={intl.formatMessage({id: 'USER.PLACEHOLDER.CONF_PASSWORD'})}
                        register={register('confPassword', {
                            validate: {
                                isTheSame: (input: string) =>
                                    input === watch('password') ||
                                    intl.formatMessage({id: 'COMMON.SAME_PASSWORD'}),
                            },
                        })}
                        error={errors['confPassword']}
                    />
                </FormGroup>
            </CardBody>
        </CardUi>               
    );
}

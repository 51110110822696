import { FiExternalLink } from "react-icons/fi";
import { ButtonUi } from "../../../basic/ButtonUi";
import DriverEcoMarkSkeleton from "./skeleton";
import { RateUiComponent } from "../../../basic/RateUi";

type DriverMarkProps = {
    text: string,
    mark?: number | null,
    description: string,
    loading: boolean,
    showMore: ()=> void,
    allowColor?: boolean,
    [key: string]: any
}
export const DriverMark = ({text, mark, description, allowColor, loading, showMore, ...otherProps}: DriverMarkProps) => {

    if(loading) {
        return <DriverEcoMarkSkeleton {...otherProps} />
    }
    if(mark != null) {
        return (
            <div {...otherProps}>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <h6 className="text-primary fs-14 mb-0">{text}</h6>
                     {
                        description !== '(Mois en cours)' && <ButtonUi 
                        color="secondary" 
                        ghost 
                        size='sm'
                        onClick={showMore}
                    >
                        <>Voir détails <FiExternalLink/></>
                    </ButtonUi>
                     }
                    
                </div>
                
                <div className="d-flex mt-1">
                    <div className="d-flex flex-row align-items-baseline">
                        <h1 className="me-2 mb-0">{mark ? mark.toFixed(1) : '-'}</h1>
                        <div className="d-flex flex-column">
                            <RateUiComponent disabled allowHalf={true} mark={mark} size={20} allowColor={allowColor} />
                            <span style={{fontWeight: 500}} className="fs-11 text-secondary align-self-end">{description}</span>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }

    return <></>

}
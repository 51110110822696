import { FieldValues, UseFormRegister } from "react-hook-form";
import { FormGroup } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";

export enum Severity {
    "MINOR" = "minor",
    "MAJOR" = "major",
    "CRITICAL" = "critical"
}

type SeverityTypeComponentProps = {
    register: UseFormRegister<FieldValues>;
    errors: Record<string, string>;
    active: Severity;
    onChange: (severity: Severity) => void
}
export function SeverityTypeComponent(props: SeverityTypeComponentProps) {
    return ( 
        <FormGroup className="d-flex flex-column">
            <label className="form-label">Quelle est la gravité de l'incident ?</label>
            <div>
                <ButtonUi 
                    color="success" 
                    size="sm" 
                    className="me-2" 
                    outline={Severity.MINOR !== props.active}
                    onClick={()=> props.onChange(Severity.MINOR)}
                >
                    Mineur
                </ButtonUi>
                <ButtonUi 
                    color="warning" 
                    size="sm" 
                    className="me-2" 
                    outline={Severity.MAJOR !== props.active}
                    onClick={()=> props.onChange(Severity.MAJOR)}
                >
                    Majeur
                </ButtonUi>
                <ButtonUi 
                    color="danger" 
                    size="sm" 
                    className="me-2" 
                    outline={Severity.CRITICAL !== props.active}
                    onClick={()=> props.onChange(Severity.CRITICAL)}
                >
                    Critique
                </ButtonUi>
            </div>
        </FormGroup>
    );
}
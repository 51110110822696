import { useEffect, useState } from 'react';
import { MapContainer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Tiles } from './Tiles';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { EventMarkers } from './components/marker/EventMarkers';
import { InitMapAction, SetOptionMapAction } from '../../redux/rootActions';
import { useDispatch } from 'react-redux';
import { FuelMarkers } from './components/marker/FuelMarker';
import { ObjectsMarker } from './components/marker/ObjectsMarker';
import { ActionMapControl } from './actions-control';
import { TrajectPolyline } from './components/poyline/Traject-polyline';
import { DetailsActionMapControl } from './details-actions-control';
import { GeofenceDrawer } from '../../pages/Map/geofence-drawer';
import { CreatePassengerMarker } from '../../pages/Map/Passenger/create-passenger-marker';
import { StopsDrawer } from '../../pages/Map/stops-drawer';
import { IF } from '../../Components/If';
import { useMatch } from 'react-router-dom';
import { RoutesPolyline } from './components/poyline/routes-polyline';
import { StopsMarkers } from './components/marker/StopsMarkers/stops-markers';

const mapPages = ['stops', 'passengers', 'passenger-groups', 'instant-reports', 'map', 'drivers', 'driversv2', 'shared-objects', 'geofences', 'notifications'];

const MapComponent = () => {

    const { pathname } = useLocation();

    const map = useMap();
    const dispatch = useDispatch();

    const updateDimensions = () => {
        setTimeout(()=> map.invalidateSize(), 1000);  
    };

    useEffect(() => {
        dispatch(InitMapAction(map));
        dispatch(SetOptionMapAction({opt: 'zoom', value: map.getZoom()}));


    }, [map]);

    useEffect(() => {
        map.on('zoomend', function() {
            dispatch(SetOptionMapAction({opt: 'zoom', value: map.getZoom()}));
        });
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        updateDimensions();
    }, [pathname]);

    return null
}

export function MapModule({sharedMap}: {sharedMap?: boolean}): JSX.Element {
    const [visible, setVisible] = useState<boolean>(true);
    const { pathname } = useLocation();
    const updateStops = useMatch({path: "/stops/:key/update"});
    const drawStops = pathname === "/stops/add-manual" || !!updateStops;
    const passengerDetails = useMatch({path: "/passengers/:key/history"});
    const routeDetails = useMatch({path: "/routes/:key/details"});
    const stopsList = useMatch({path: "/stops"});
    useEffect(() => {
        const path = pathname.split("/");
        if(path.length > 1) {
            const isVisible = (mapPages.includes(path[1]) && !passengerDetails) || !!routeDetails ;
            setVisible(isVisible);
        } else {
            setVisible(false);
        }
    }, [pathname]);
    
    const mapClass = classNames({
        [`custom-leaflet-container`]: !sharedMap ,
        [`custom-leaflet-container-share`]: sharedMap,
        [`visible`]: visible,
        [`invisible-map`]: !visible,
    });
    return (
        <div className={mapClass}>
            <ActionMapControl />
            {
                !sharedMap && <DetailsActionMapControl />
            }
            
            <MapContainer
                id="map"
                center={[34.52050666666666, -5.946033333333332]}
                zoom={5}
                minZoom={4}
                zoomControl={false}
                style={{ height: '100%', width: '100%' }}
            >
                <MapComponent/>
                
                <Tiles/>
                <EventMarkers/>
                <FuelMarkers/>
                <ObjectsMarker sharedMap={sharedMap}/>
                <TrajectPolyline/>
                {/* <ParkingMarkers /> */}
                <GeofenceDrawer />
                <CreatePassengerMarker/> 
                <IF condition={drawStops}>
                    <StopsDrawer />
                </IF>
                <IF condition={!!stopsList}>
                    <StopsMarkers />
                </IF>
                
                <RoutesPolyline />
            </MapContainer>
        </div>
    );
}

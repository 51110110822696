import { IoMdAdd } from "react-icons/io";
import { Card, Label, CardBody } from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { GroupSelectionItem } from "./group-selection-item";
import ModalSelectPassengersGroups from "../../../../Components/GroupManagement/ModalSelectPassengersGroup";
import { useState } from "react";
import { BsBoxSeam } from "react-icons/bs";

type GroupsSelectionProps = {
    default: Record<string, {label: string, value: string}[]>,
    selectedGroup: string | null,
    setSelectedGroup: (key: string) => void,
    onChange: (groups: Record<string, {label: string, value: string}[]>) => void,
}
export const GroupsSelection = (props: GroupsSelectionProps) => {
    const [ selectedItems, setSelectedItems ] = useState<Record<string, {label: string, value: string}[]>>(props.default);
    const [ groups, setGroups ] = useState<string[]>([]);

        // Hooks
        const [isOpen, toggleOpen] = useState(false);

        // Events
        const toggleModal = () => {
            toggleOpen(!isOpen)
        }
    
        const onSelect = (data: any) => {
            setSelectedItems(data);
            const groups: string[] = data.other?.map((grp: any) =>  grp.value);
            setGroups(groups);
            props.setSelectedGroup(groups?.[0] ?? null);
            props.onChange(data);
        }

    return (
        <Card style={{width: 400}} className="px-0">
            <div className="d-flex flex-row align-items-center justify-content-between border-bottom py-2 px-3">
                <Label className="mb-0">Groupes</Label>
                <ButtonUi 
                    className="fw-bold" 
                    color={ "primary" }
                    size="sm"
                    ghost
                    onClick={toggleModal}
                >
                    <><IoMdAdd size={15}/> Ajouter</>

                </ButtonUi>
            </div>
            <CardBody className="px-0">
                {
                    selectedItems['other']?.map(item => {
                        return (
                            <GroupSelectionItem 
                                item={item} 
                                active={!!props.selectedGroup && props.selectedGroup === item.value }
                                onSelect={ props.setSelectedGroup}
                            />
                        )
                    })
                }
            </CardBody>
            <ModalSelectPassengersGroups                             
                title={"Ajouter vos groupes"}
                description="Choisissez vos passagers et rajoutez les au groupe."
                Icon={BsBoxSeam}
                isOpen={isOpen} 
                default={selectedItems}
                toggleModal={toggleModal}
                onSelect={onSelect}
            />
        </Card>
                   
    );
}
enum EmitterActions {
    "REFRESH_DRIVER_REVIEW_RATE" = "REFRESH_DRIVER_REVIEW_RATE",
    "REFRESH_DRIVER_INFO" = "REFRESH_DRIVER_INFO",
    "REFRESH_DRIVER_ECO" = "REFRESH_DRIVER_ECO",
    "REFRESH_ASSIGNMENT_LIST" = "REFRESH_ASSIGNMENT_LIST",

    "PASSENGER:SET_MARKER" = "PASSENGER:SET_MARKER",
    "PASSENGER:SET_LOCATION_VALUE" = "PASSENGER:SET_LOCATION_VALUE",

    "GROUP:GO_TO" = "GROUP:GO_TO",

    "ROUTES_INIT" = "ROUTES_INIT"
}

export default EmitterActions
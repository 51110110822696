import { useContext, useEffect, useRef, useState } from "react";
import {  AiOutlineEyeInvisible, AiOutlineUnorderedList } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import {ButtonGroup, CardFooter, CardHeader, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { InputSearch } from "../../../Components/Form/InputSearch";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { Paginator } from "../../../Components/basic/Paginator";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import classNames from "classnames";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { LayoutContext } from "../../../context/layout-style-context";
import { SetOptionMapAction } from "../../../redux/rootActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GetDriverStart } from "../_redux/driverModule.actions";
import { ManageDriver } from "../Manage";
import { DriverComponent } from "../../../Components/advanced/Driver/Driver-info";
import { LIMIT } from "../../../env";
import { DriverAction } from "../types";
import { DriverAssignList } from "../Assign";
import { GetStart } from "../../Map/Objects/_redux/unit-list.actions";


export const DriverList = ({sharedMap}: {sharedMap?: boolean}) => {
    const { key } = useParams();
    const [queryParameters] = useSearchParams();
    const action = queryParameters.get('action');
    const isUpdate = action === 'update'
    const listRef = useRef<any>();
    const navigate = useNavigate();
    const { height } = useContext(LayoutContext);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50,
    });
    const dispatch = useDispatch();
    

    const { drivers, loading, filter } = useAppSelector( (state) => state.driverModuleReducer );
    const { vehicleActive } = useAppSelector((state) => {
        return {
            vehicleActive: state.ObjectReducer.vehicle?._id,
        };
    });
    const canShowFooter = Math.ceil(drivers.total / filter.limit) > 1;
    const [hide, setHide] = useState<boolean>(false);
    
    const toogleHide = (): void => {
        setHide(!hide);
        let padding = 0;
        if(hide) { padding = padding + 374; }
        if(vehicleActive) { padding = padding + 450; }
        dispatch(SetOptionMapAction({opt: 'padding', value: padding}))
    };
    const intl = useIntl();

    

    const fetchDrivers = ({skip, limit, name} : { skip: number, limit: number, name?: string}) => {
        dispatch(GetDriverStart({ skip, limit, name }));
    }

    const onSearch = (data: { searchBy: string }): void => {
        fetchDrivers({ skip: 0, limit: LIMIT, name: data.searchBy });
    };

    // const chooseObject = (object: VehicleInfoType): void => {
    //     dispatch(InitVehicle({ vehicle: object }));
    // };
    
    useEffect(() => fetchDrivers({ skip: 0, limit: LIMIT }), []);
    const onPaginate = (skip: number, limit: number): void => fetchDrivers({ skip, limit });

    const ListObjectClassname = classNames({
        [`d-none`]: hide,
        'list-driver': true
    });

    const getIndex = () => {
        if(vehicleActive) {
            const index = drivers.list.findIndex(v => v.key === vehicleActive);
            if(index !== -1) {
                return index;
            }
        }
        return undefined
    }

    const toggleModal = () => {
        if(action) {
            navigate(key ? `/drivers/${key}/details` : '/drivers');
        } else {
            navigate('/drivers?action=add');
        }
    }
    

    const actionClick = (key: string, action: DriverAction) => {
        switch(action) {
            case DriverAction.SEARCH_UNIT: {
                dispatch(GetStart({ skip: 0, limit: LIMIT, name: key, driver: true }));
                break;
            }

            case DriverAction.DETAILS: {
                navigate(`/drivers/${key}/details`)
                break;
            }
        }
    }

    return (
        <>
            <ButtonUi 
                onClick={toogleHide}
                className={hide ? '' : 'd-none'} color={"primary"} Icon={AiOutlineUnorderedList}
            />
            <CardUi className={ListObjectClassname}>
                <LoaderUi loading={loading}/>
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <AiOutlineUnorderedList className="me-2"/>
                        <span><FormattedMessage id={"DRIVER.LIST"}/></span>
                    </h5>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={toogleHide}
                        onKeyDown={toogleHide}
                    >
                        <AiOutlineEyeInvisible size={20} />
                    </div>
                </CardHeader>
                
                <CardBodyUi className="p-0">
                    <div className="py-2 px-3 border-bottom">
                        <div className="d-flex flex-row">
                            <ButtonUi 
                                size="sm" 
                                color={"info"}
                                onClick={toggleModal}
                            >
                                <FormattedMessage id="DRIVER.NEW"/>
                            </ButtonUi>
                            <div className="ms-1 flex-grow-1">
                                <InputSearch
                                    type="text"
                                    name={'searchBy'}
                                    size="sm"
                                    placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                                    onSubmit={onSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="py-2 px-3 border-bottom">
                        <div className="d-flex justify-content-center">
                            <ButtonGroup size="sm" className="flex-grow-1">
                                <ButtonUi color="primary">Conducteurs</ButtonUi>
                                <ButtonUi color="primary" outline disabled>Groupes</ButtonUi>
                            </ButtonGroup>
                        </div>
                    </div>
                        
                    <div style={{height: height - (canShowFooter ? 330 : 255)}}>
                        <AutoSizer>
                            {
                                ({ height, width }) => {
                                    return(
                                        <List
                                            ref={listRef}
                                            width={width}
                                            height={height > 0 ? height : 0}
                                            rowCount={ drivers.list.length }
                                            deferredMeasurementCache={cache}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={rowRenderer}
                                            scrollToIndex={getIndex()}
                                        />
                                    )
                                }     
                            } 
                        </AutoSizer> 
                    </div>   
                    
                </CardBodyUi>
                {
                    canShowFooter ? (
                        <CardFooter>
                        <Row>
                            <Col>
                                <Paginator
                                    total={drivers.total}
                                    skip={filter.skip}
                                    limit={filter.limit}
                                    onPaginate={onPaginate}
                                />
                            </Col>
                        </Row>
                    </CardFooter>
                    ) : <></>
                }
                
            </CardUi> 
            <Modal 
                isOpen={!!action}
                toggle={toggleModal}
                size={"lg"}
                fullscreen={"sm"}
            >
                <ModalHeader toggle={toggleModal}>
                    {
                        isUpdate  ? <FormattedMessage id="DRIVER.UPDATE"/> : <FormattedMessage id="DRIVER.CREATE"/>
                    }
                </ModalHeader>
                <ModalBody>
                    <ManageDriver/>
                </ModalBody>
            </Modal>
           <DriverAssignList />
        </>
    );

    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                    <DriverComponent driver={drivers.list[index]} onClick={actionClick}/>
                </div>
            )}
          </CellMeasurer>
        );
    }
}
import classNames from 'classnames';
import { colorType, roundedType } from '../../../common/types';
import { IconType } from 'react-icons';


type avatarType = {
    id?: string;
    className?: string;
    isSoft?: boolean;
    color?: colorType;
    img?: string;
    text: string;
    icon?: IconType;
    maxChar?: number;
    alt?: string;
    size?: 'xxs' |  'xs'  | 'sm' | 'md' | 'lg' | 'xl';
    rounded?: roundedType;
    thumbnail?: boolean,
}

export const Avatar = (props: avatarType) => {
    const {className, icon, color, thumbnail, isSoft, img, text, maxChar, size, alt, rounded, ...otherProps} = props;
    const avatarClass = classNames('avatar', {
        [`${className}`]: !!className,
        [`avatar-title`]: img === undefined,
        [`avatar-${color}`]: color && !img,
        [`avatar-${size}`]: !!size,
        [rounded ?? '']: !!rounded, 
        'rounded-0' : rounded === 'rounded-0' || rounded === 'rounded-start' || rounded === 'rounded-end' 
           || rounded === 'rounded-top' || rounded === 'rounded-bottom',
        [`img-thumbnail`]: thumbnail && img,     
        [`soft`]: isSoft,  
        [`fs-10`]: size==='xxs' || (size==='xs' && !!maxChar && maxChar>2) ,
        [`fs-12`]: (!maxChar && size==='xs') || (!!maxChar && maxChar<=2),
        [`fs-14`]: size==='sm',
        [`fs-16`]: size==='md',
        [`fs-20`]: size==='lg',
        [`fs-24`]: size==='xl',
    }, {
        [`avatar-sm`]: size === undefined,
    });
    if(icon) {
        const Icon = icon;
        return <div className={avatarClass} {...otherProps}>
            <Icon />
        </div>
    }
    return (
        img === undefined ? 
            <div className={avatarClass} {...otherProps}>{text.substring(0, maxChar ? maxChar : (size !== 'xxs' ? 2 : 1))}</div>
        : 
            <img className={avatarClass} alt={alt} src={img} {...otherProps}/>
    );
}

import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Container, Row, Col, Form } from "reactstrap";
import { BreadCrumbActionUi } from "../../../Components/basic/BreadCrumbActionUi";
import { useAppSelector } from "../../../redux/hooks";
import { General } from "./General";
import { Type } from "./Types";
import { Notification } from "./Notification";

import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { useEffect } from "react";
import { GetMaintenanceStart, SaveNewMaintenanceEnd, SaveNewMaintenanceStart } from "../_redux/maintenance.actions";

const ManageMaintenancePage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {groupId, maintenanceId} = useParams();
    const navigate = useNavigate();
    const { user, maintenance } = useAppSelector((state) => {
        return {
            user: state.authReducer,
            maintenance: state.MaintenanceReducer
        }
    });

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm({
        mode: 'onChange'
    });

    const backToPrevious = () => {
        navigate({ pathname: '/maintenance' });
    }

    const onSubmit = async (data: any) => {
        const {km, engineTime, date, name, unit} = maintenance.manage;
        if((date.active || km.active || engineTime.active) 
            && name && unit && (!date.active || date.active && date.startTime)) {
            dispatch(SaveNewMaintenanceStart(data));
        }
        
    }

    useEffect(()=> {
        if(maintenanceId && groupId) {
            dispatch(GetMaintenanceStart({groupId, _id: maintenanceId}))
        }
        return () => {
            dispatch(SaveNewMaintenanceEnd())
        }
    }, []);


    useEffect(()=> {
        const {km, date, engineTime, alert} = maintenance.manage;
        if(km) {
            setValue('lastServiceMielage', `${km.lastServiceMielage}`);
            setValue('actualMielage',`${km.actualMielage}`);
            setValue('intervalKm', `${km.intervalKm}`);
        }

        if(engineTime) {
            setValue('lastServiceHours', `${Math.round(engineTime.lastServiceHours)}`);
            setValue('actualHours',`${engineTime.actualHours}`);
            setValue('intervalHours', `${engineTime.intervalHours}`);
        }

        if(date) {
            setValue('intervalDay',`${date.intervalDay}`);
        }

        if(alert && alert.emails.length > 0) {
            if(alert.alertDay  !== undefined) {
                setValue('alertDay', `${alert.alertDay}`);
            }

            if(alert.alertEngineTime !== undefined) {
                setValue('alertEngineTime', `${alert.alertEngineTime}`);
            }

            if(alert.alertKm  !== undefined) {
                setValue('alertKm', `${alert.alertKm}`);
            }
        }
  
    }, [
        maintenance.manage.alert?.alertDay,
        maintenance.manage.alert?.alertEngineTime,
        maintenance.manage.alert?.alertKm,
        maintenance.manage.km, 
        maintenance.manage.engineTime
    ]);

    return (
        <div id="driverPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'MAINTENANCE' })} 
                    pageTitle={user.user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        
                        <Col md={6}>
                            <Col>
                                <General/>
                            </Col>
                            
                            <Col>
                                <Notification register={register} errors={errors} watch={watch}/> 
                            </Col>
                        </Col>
                        
                        <Col md={6}>
                            <Type register={register} errors={errors} watch={watch}/>
                            <Col>
                                <div className="hstack gap-2 justify-content-end">
                                    <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                        <FormattedMessage id="COMMON.CANCEL"/>
                                    </ButtonUi>
                                    <ButtonUi color={"primary"} type="submit">
                                        {
                                            !maintenanceId ? <FormattedMessage id="COMMON.ADD"/>  : <FormattedMessage id="COMMON.UPDATE"/>
                                        }
                                    </ButtonUi> 
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
    );
}

export default ManageMaintenancePage;
import { AffectToType, ManageUserType, UserInList } from '../types';
import {
    ASSIGN_VEHICLE_USER_END,
    ASSIGN_VEHICLE_USER_START,
    CHANGE_USER_ROLE_ITEM,
    CHANGE_USER_TYPE,
    CLEAR_USER_CREATION,
    CREATE_USER_END,
    CREATE_USER_START,
    DELETE_USERS_END,
    DELETE_USERS_START,
    ERROR,
    GET_USERS_END,
    GET_USERS_START,
    GET_USER_ROLE_END,
    GET_USER_ROLE_START,
    GET_USER_UPDATE_END,
    GET_USER_UPDATE_START,
    SET_NOMINATION_USER_TYPE,
    SET_SELECTED_USERS,
    SET_USER_CONTACT_TYPE,
    TOOGLE_AFFECT_TO,
    TOOGLE_POPUP,
    UserActionType
} from './user.actions';

type StateType = {
    loading: boolean;
    popup: boolean;
    affectTo?: AffectToType;
    manage: ManageUserType;
    selectedUsers: string[];
    filter: {
        limit: number;
        skip: number;
    };
    users: {
        list: UserInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    popup: false,
    users: {
        list: [],
        total: 0,
    },
    selectedUsers: [],
    manage: {
        type: 'user',
        name: '',
        roles: [],
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function userReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: UserActionType
): StateType {
    switch (action.type) {
        case CREATE_USER_START:
        case GET_USERS_START:
        case GET_USER_ROLE_START:
        case GET_USER_UPDATE_START:
        case ASSIGN_VEHICLE_USER_START:
        case DELETE_USERS_START: {
            return { ...state, loading: true };
        }

        case TOOGLE_POPUP:
            return {
                ...state,
                popup: action.payload,
            };

        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }
        case CREATE_USER_END: {
            const list = [...state.users.list];
            const { _id, modifiedCount } = action.payload;
            const updatedUser: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const userIndex = state.users.list.findIndex(
                        (user) => user.key === _id
                    );
                    if (userIndex !== -1) {
                        list[userIndex] = {
                            ...list[userIndex],
                            ...updatedUser,
                        };
                    }
                }
            } else {
                list.unshift({...updatedUser});
            }

            return {
                ...state,
                loading: false,
                users: {
                    list,
                    total:
                        modifiedCount && modifiedCount > 0
                            ? state.users.total
                            : state.users.total + 1,
                },
                popup: false,
                selectedUsers: [],
            };
        }

        case ASSIGN_VEHICLE_USER_END: {
            const { _id, modifiedCount } = action.payload;
            const list = [...state.users.list];
            if (modifiedCount !== undefined) {
                const userIndex = state.users.list.findIndex(
                    (user) => user.key === _id
                );
                const updatedUser: any = { ...action.payload, ...{ key: _id } };
                if (userIndex !== -1) {
                    list[userIndex] = {
                        ...list[userIndex],
                        ...updatedUser,
                    };
                }
            }

            return {
                ...state,
                loading: false,
                users: {
                    ...state.users,
                    list,
                },
                popup: false,
                selectedUsers: [],
                affectTo: INITIAL_STATE.affectTo,
            };
        }

        case GET_USERS_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                users: result,
                filter,
                loading: false,
            };
        case SET_SELECTED_USERS:
            return {
                ...state,
                selectedUsers: action.payload,
            };
        case DELETE_USERS_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedUsers:
                        state.selectedUsers.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    users: {
                        total: state.users.total - deletedCount,
                        list:
                            state.users.list.filter(
                                (user) => !_ids.includes(user.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_USER_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                popup: true,
                loading: false,
            };
        }

        // Add it in the back
        case GET_USER_ROLE_END: {
            const rolesPayload = action.payload;
            const roles = !state.manage._id
                ? rolesPayload
                : rolesPayload.map(
                      (role: {
                          name: string;
                          add?: boolean;
                          delete?: boolean;
                          update?: boolean;
                          read?: boolean;
                      }) => {
                          const userRole = state.manage.roles.find(
                              (roleUser) => role.name === roleUser.name
                          );
                          if (userRole) {
                              if (role.add === undefined) {
                                  delete userRole.add;
                              }
                              if (role.update === undefined) {
                                  delete userRole.update;
                              }
                              if (role.delete === undefined) {
                                  delete userRole.delete;
                              }
                              if (role.read === undefined) {
                                  delete userRole.read;
                              }
                              return userRole;
                          }
                          return role;
                      }
                  );

            return {
                ...state,
                manage: {
                    ...state.manage,
                    roles,
                },
                loading: false,
            };
        }

        case CHANGE_USER_ROLE_ITEM: {
            const { key, type, value } = action.payload;
            const roles = [...state.manage.roles];
            const roleIndex = state.manage.roles.findIndex(
                (role) => role.name === type
            );
            if (roleIndex !== -1) {
                roles[roleIndex][key] = value;
            }

            return {
                ...state,
                manage: {
                    ...state.manage,
                    roles,
                },
            };
        }

        case CHANGE_USER_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    type: action.payload,
                },
            };
        }

        case SET_USER_CONTACT_TYPE: {
            const newManage = {
                ...state.manage,
                ...action.payload,
            };
            if (newManage.email === undefined || newManage.email.length === 0) {
                delete newManage.email;
            }
            if (
                newManage.mobile === undefined ||
                newManage.mobile.length === 0
            ) {
                delete newManage.mobile;
            }
            return {
                ...state,
                manage: newManage,
            };
        }

        case SET_NOMINATION_USER_TYPE: {
            const newManage = {
                ...state.manage,
                ...action.payload,
            };
            return {
                ...state,
                manage: newManage,
            };
        }

        case TOOGLE_AFFECT_TO: {
            const newState = { ...state };
            if (newState.affectTo) {
                delete newState.affectTo;
            } else {
                newState.affectTo = action.payload;
            }
            return newState;
        }

        case CLEAR_USER_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
            };
        }
        default:
            return state;
    }
}

import { Spinner } from "reactstrap";
import { BASE_URL } from "../env";
import { useAppSelector } from "../redux/hooks";

export function SplashScreen(): JSX.Element {
    const user = useAppSelector((state) => state.authReducer.user);

    return (
        <div className="auth-bg-cover py-5 d-flex flex-column justify-content-center align-items-center min-vh-100">
            <div className="d-flex flex-column justify-content-center align-items-center">
                {/* <img src={logoLight} alt=""  className="m-2" height="50"/> */}
                <img src={`${BASE_URL}/public/${user?.company._id}.svg`} alt=""  className="m-2" height="50"/>
                
                <div className="mt-4 d-flex flex-row justify-content-center align-items-center">
                    <Spinner
                        className="text-white"
                        type="grow"
                    />

                    <h5 className="text-white fw-bold mb-0 ms-3">Chargement en cours...</h5>
                </div>
                
            </div>
        </div>
    );
}
import { CardBody, CardHeader } from 'reactstrap'
import { Avatar } from '../../../Components/basic/avatar/avatar'
import CheckCircleIcon from '../../../Components/advanced/Passenger/icon/CheckCircleIcon'


function InfoProfile() {
  return (
    <div className="d-flex flex-column align-items-center">
      <Avatar
        className={'avatar-title'}
        color="primary"
        size={'xl'}
        text="Salki Omar"
        rounded="rounded-circle"
      />
      <div className="text-center mt-2">
        <h2 className="text-dark">Salki Omar</h2>
        <p className="text-muted fs-12">O0013MA</p>
      </div>

      <div className="w-100">
        <div>
          <CardHeader className="d-flex m-2 align-items-center">
            <CheckCircleIcon />
            <p className="fs-15 fw-bold mb-0 ms-2">Information:</p>
          </CardHeader>
          <CardBody className="m-2 my-2">
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Nom :</p>
              <p className="text-right mb-0">Omar</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Prénom :</p>
              <p className="text-right mb-0">Salki</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Identifiant :</p>
              <p className="text-right fw-bold mb-0">O0013MA</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Téléphone :</p>
              <p className="text-right mb-0">06 00 00 00 00</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Adresse :</p>
              <p className="text-right mb-0">Sect 10, Hay Nahda, Rabat.</p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p className="text-left mb-0">Groupe :</p>
              <p className="text-right fw-bold mb-0">PSA-Abdesalam</p>
            </div>
          </CardBody>
        </div>
      </div>
    </div>
  )
}

export default InfoProfile

/* eslint-disable */
import moment from 'moment-timezone';

const units: any = {
    fr: {
        second: { s: 'Seconde', p: 'Secondes' },
        minute: { s: 'Minute', p: 'Minutes' },
        hour: { s: 'Heure', p: 'Heures' },
        day: { s: 'Jour', p: 'Jours' },
        month: { s: 'Mois', p: 'Mois' },
        year: { s: 'Année', p: 'Années' },
        prefix: '',
        suffix: "Il y'a",
    },
    en: {
        second: { s: 'Second', p: 'Seconds' },
        minute: { s: 'Minute', p: 'Minutes' },
        hour: { s: 'Hour', p: 'Hours' },
        day: { s: 'Day', p: 'Days' },
        month: { s: 'Month', p: 'Months' },
        year: { s: 'Year', p: 'Years' },
        prefix: 'ago',
        suffix: '',
    },
    ar: {
        second: { s: 'ثانية', p: 'ثانية' },
        minute: { s: 'دقيقة', p: 'دقائق' },
        hour: { s: 'ساعة', p: 'ساعات' },
        day: { s: 'يوم', p: 'يوم' },
        month: { s: 'شهر', p: 'أشهر' },
        year: { s: 'سنة', p: 'سنوات' },
        prefix: '',
        suffix: '',
    },
};

export function diffDate(
    previous: Date,
    lang: string
): { time: number; unit: string; text: string } {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const { second, minute, hour, day, month, year, suffix, prefix } =
        units[lang];
    // @ts-ignore
    const elapsed = new Date() - previous;

    if (elapsed < msPerMinute) {
        const time = Math.round(elapsed / 1000);
        const unit = time > 1 ? second.p : second.s;
        return { time, unit, text: `${suffix} ${time} ${unit} ${prefix} ` };
    }
    if (elapsed < msPerHour) {
        const time = Math.round(elapsed / msPerMinute);
        const unit = time > 1 ? minute.p : minute.s;
        return { time, unit, text: `${suffix} ${time} ${unit} ${prefix} ` };
    }
    if (elapsed < msPerDay) {
        const time = Math.round(elapsed / msPerHour);
        const unit = time > 1 ? hour.p : hour.s;
        return { time, unit, text: `${suffix} ${time} ${unit} ${prefix} ` };
    }
    if (elapsed < msPerMonth) {
        const time = Math.round(elapsed / msPerDay);
        const unit = time > 1 ? day.p : day.s;
        return { time, unit, text: `${suffix} ${time} ${unit} ${prefix} ` };
    }
    if (elapsed < msPerYear) {
        const time = Math.round(elapsed / msPerMonth);
        const unit = time > 1 ? month.p : month.s;
        return { time, unit, text: `${suffix} ${time} ${unit} ${prefix} ` };
    }
    const time = Math.round(elapsed / msPerYear);
    const unit = time > 1 ? year.p : year.s;
    return { time, unit, text: `${suffix} ${time} ${unit}${prefix} ` };
}

export function diffDateComplete(previous: Date, lang: string): any {
    const m1 = moment();
    const m2 = moment(previous);
    const m3 = m1.diff(m2, 'minutes');

    const { second, minute, hour, day, month, year, suffix, prefix } =
        units[lang];

    const numdays = Math.floor(m3 / 1440);
    const numhours = Math.floor((m3 % 1440) / 60);
    const numminutes = Math.floor((m3 % 1440) % 60);

    switch (true) {
        case numdays > 0: {
            return `${numdays} ${numdays > 0 ? day.p : day.s}, ${numhours} ${
                numhours > 1 ? hour.p : hour.s
            }, ${numminutes} ${numminutes > 1 ? minute.p : minute.s}`;
        }
        case numhours > 0: {
            return `${numhours} ${
                numhours > 1 ? hour.p : hour.s
            }, ${numminutes} ${numminutes > 1 ? minute.p : minute.s}`;
        }
        default:
            return `${numminutes} ${numminutes > 1 ? minute.p : minute.s}`;
    }
}

export function getDayOfTheMonth(day: any, timeZone: string): any {
    const timeLine = [];
    let endDate = moment().tz(timeZone);
    const month = moment(day).clone().month();
    const todayMonth = endDate.clone().month();

    if (month !== todayMonth) {
        endDate = moment(day).clone().endOf('month');
    }
    const startOfTheMonth = endDate.clone().startOf('month');

    const limit = endDate.diff(startOfTheMonth, 'days') + 1;

    for (let i = limit; i > 0; i--) {
        const from = endDate
            .clone()
            .subtract(i - 1, 'd')
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        const to = endDate
            .clone()
            .subtract(i - 1, 'd')
            .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
        timeLine.push({ index: i, from, to });
    }
    return timeLine;
}
/*
export function getToday(timeZone: string): any {
  let today = moment().tz(timeZone);
  const from =  today.clone().set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime();
  const to =  today.toDate().getTime();
  return {from, to};
}
*/
export function getMonth(momentDate: any) {
    switch (momentDate.month()) {
        case 0:
            return 'Jan';
        case 1:
            return 'Fév';
        case 2:
            return 'Mar';
        case 3:
            return 'Avr';
        case 4:
            return 'Mai';
        case 5:
            return 'Jui';
        case 6:
            return 'Juil';
        case 7:
            return 'Aoû';
        case 8:
            return 'Sep';
        case 9:
            return 'Oct';
        case 10:
            return 'Nov';
        case 11:
            return 'Déc';
    }
}

export function getHours(allMinutes: number) {
    const hours = Math.floor((allMinutes * 1.6667e-5) / 60);
    return hours < 10 ? `0${hours}` : `${hours}`;
}

export function getMinutes(allMinutes: number) {
    const minutes = (allMinutes * 1.6667e-5) % 60;
    return minutes < 10 ? `0${minutes.toFixed(0)}` : `${minutes.toFixed(0)}`;
}

export function getDuration(ms: number) {
    if (ms) {
        let date;
        date = convertMS(ms);
        const { day, hour, minute, seconds } = date;
        if (day === 0) return `${hour}h:${minute}min:${seconds}s`;
        return `${day}j, ${hour}h:${minute}min:${seconds}s`;
    }
    return '-';
}
export function convertMS(milliseconds: number) {
    let day;
    let hour;
    let minute;
    let seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds %= 60;
    hour = Math.floor(minute / 60);
    minute %= 60;
    day = Math.floor(hour / 24);
    hour %= 24;
    return {
        day,
        hour,
        minute,
        seconds,
    };
}

export function formatDate(
    date: Date | string | number,
    timezone: string,
    format: string
) {
    return moment(date).tz(timezone).format(format);
}

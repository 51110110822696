import { Moment } from 'moment-timezone';
import { fetchTrajectsResponse } from './historyService';
import {
    ActiveTripOffType,
    ActiveTripOnType,
    FetchHistoryEndResponse,
    MouseEventPointTraject,
    PeriodType,
} from './types';

export const GET_START = '[HISTORY] GET_START';
export type GetStartType = {
    type: typeof GET_START;
    payload: PeriodType;
};
export function GetStart(payload: PeriodType): GetStartType {
    return { type: GET_START, payload };
}

export const GET_END = '[HISTORY] GET_END';
export type GetEndType = {
    type: typeof GET_END;
    payload: FetchHistoryEndResponse;
};
export function GetEnd(payload: FetchHistoryEndResponse): GetEndType {
    return { type: GET_END, payload };
}

export const TOOGLE_TIMELINE = '[HISTORY] TOOGLE_TIMELINE';
export type ToogleTimeLineType = {
    type: typeof TOOGLE_TIMELINE;
    payload?: boolean;
};
export function ToogleTimeLine(payload?: boolean): ToogleTimeLineType {
    return { type: TOOGLE_TIMELINE, payload };
}

export const TOOGLE_PARKING = '[HISTORY] TOOGLE_PARKING';
export type ToogleParkingType = {
    type: typeof TOOGLE_PARKING;
    payload?: boolean;
};
export function ToogleParkingAction(payload?: boolean): ToogleParkingType {
    return { type: TOOGLE_PARKING, payload };
}

export const TOOGLE_TRAJECT = '[HISTORY] TOOGLE_TRAJECT';
export type ToogleTrajectType = {
    type: typeof TOOGLE_TRAJECT;
    payload?: boolean;
};
export function ToogleTrajectAction(payload?: boolean): ToogleTrajectType {
    return { type: TOOGLE_TRAJECT, payload };
}

export const GET_TRAJECT_START = '[HISTORY] GET_TRAJECT_START';
export type GetTrajectStartType = {
    type: typeof GET_TRAJECT_START;
};
export function GetTrajectStart(): GetTrajectStartType {
    return { type: GET_TRAJECT_START };
}

export const GET_TRAJECT_END = '[HISTORY] GET_TRAJECT_END';
export type GetTrajectEndType = {
    type: typeof GET_TRAJECT_END;
    payload: fetchTrajectsResponse[];
};
export function GetTrajectEnd(payload: fetchTrajectsResponse[]): GetTrajectEndType {
    return { type: GET_TRAJECT_END, payload };
}

export const ACTIVE_TRAJECT_START = '[HISTORY] ACTIVE_TRAJECT_START';
export type ActiveTrajectStartType = {
    type: typeof ACTIVE_TRAJECT_START;
    payload: {_id: string, time?: {start: Moment, end: Moment}};
};
export function ActiveTrajectStart(payload: { _id: string, time?: {start: Moment, end: Moment} }): ActiveTrajectStartType {
    return { type: ACTIVE_TRAJECT_START, payload };
}

export const ACTIVE_TRAJECT_END = '[HISTORY] ACTIVE_TRAJECT_END';
export type ActiveTrajectEndType = {
    type: typeof ACTIVE_TRAJECT_END;
    payload: ActiveTripOnType | ActiveTripOffType;
};
export function ActiveTrajectEnd(
    payload: ActiveTripOnType | ActiveTripOffType
): ActiveTrajectEndType {
    return { type: ACTIVE_TRAJECT_END, payload };
}

export const SET_POINT_MOUSE_OVER = '[HISTORY] SET_POINT_MOUSE_OVER';
export type SetPointMouseOverType = {
    type: typeof SET_POINT_MOUSE_OVER;
    payload: MouseEventPointTraject | null;
};
export function SetPointMouseOver(
    payload: MouseEventPointTraject | null
): SetPointMouseOverType {
    return { type: SET_POINT_MOUSE_OVER, payload };
}

export const SET_POINT_MOUSE_CLICK = '[HISTORY] SET_POINT_MOUSE_CLICK';
export type SetPointMouseClickType = {
    type: typeof SET_POINT_MOUSE_CLICK;
    payload: {
        index: number;
        point: MouseEventPointTraject;
    };
};
export function SetPointMouseClick(payload: {
    index: number;
    point: MouseEventPointTraject;
}): SetPointMouseClickType {
    return { type: SET_POINT_MOUSE_CLICK, payload };
}

export const SET_HISTORY_TIME = '[HISTORY] SET_HISTORY_TIME';
export type SetHistoryTimeType = {
    type: typeof SET_HISTORY_TIME;
    payload: PeriodType;
};
export function SetHistoryTime(payload: PeriodType): SetHistoryTimeType {
    return { type: SET_HISTORY_TIME, payload };
}

export const INIT_HISTORY = '[HISTORY] INIT_HISTORY';
export type InitHistoryType = { type: typeof INIT_HISTORY };
export function InitHistory(): InitHistoryType {
    return { type: INIT_HISTORY };
}

export const ERROR = '[HISTORY] ERROR';
export type ErrorType = {
    type: typeof ERROR;
    payload: any;
};

export const GET_PARKING_START = '[HISTORY] GET_PARKING_START';
export type GetParkingStartType = {
    type: typeof GET_PARKING_START;
};
export function GetParkingStart(): GetParkingStartType {
    return { type: GET_PARKING_START };
}

export const GET_PARKING_END = '[HISTORY] GET_PARKING_END';
export type GetParkingEndType = {
    type: typeof GET_PARKING_END;
    payload: any[];
};
export function GetParkingEnd(payload: any[]): GetParkingEndType {
    return { type: GET_PARKING_END, payload };
}

export function Error(payload: string): ErrorType {
    return { type: ERROR, payload };
}

export type HistoryActionsType =
    | GetStartType
    | GetEndType
    | ToogleTimeLineType
    | ToogleParkingType
    | ToogleTrajectType
    | GetTrajectStartType
    | GetTrajectEndType
    | ActiveTrajectStartType
    | ActiveTrajectEndType
    | SetPointMouseOverType
    | SetPointMouseClickType
    | SetHistoryTimeType
    | InitHistoryType
    | GetParkingEndType
    | ErrorType;

import React from "react"
import ContentLoader from "react-content-loader"
type DriverMarkProps = {
  [key: string]: any
}
const DriverProfileSkeleton = (props: DriverMarkProps) => (
  <div {...props}>
    <ContentLoader 
        speed={2}
        width={370}
        height={60}
        viewBox="0 0 370 60"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="67" y="21" rx="3" ry="3" width="88" height="6" /> 
        <rect x="67" y="35" rx="3" ry="3" width="52" height="6" /> 
        <circle cx="26" cy="30" r="26" /> 
        <rect x="293" y="20" rx="4" ry="4" width="70" height="28" />
    </ContentLoader>
  </div>
  
)

export default DriverProfileSkeleton
import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { MdOutlineLogout } from "react-icons/md";
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { Logout, SwitchToMainUserStartAction } from '../../pages/Authentification/_redux/authActions';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
import { Avatar } from '../basic/avatar/avatar';

export const ProfileDropdown = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { user } = useAppSelector(
        (state) => state.authReducer
    );
    const name = user?.name || '';
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const logout = () => dispatch(Logout());
    const goToMainUser = () => dispatch(SwitchToMainUserStartAction());

    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <Avatar 
                            className={"avatar-title"} 
                            color="primary" 
                            size={"xs"} 
                            text='...'
                            rounded="rounded-circle"
                            img="https://app.telematics.services/img/avatar.svg"/>
                            <span className="text-start ms-xl-2">
                                <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{name}</span>
                                <span className={`d-none d-xl-block ms-1 fs-12  user-name-sub-text ${!user?.connectedAs ? 'text-muted' : 'text-danger'}`}>
                                    {!user?.connectedAs ? `${intl.formatMessage({id: user?.type ?? '.'})?.substring(0, 18)} ...` : `${intl.formatMessage({id: 'USER.CONNECTED_AS'})} (${user.connectedAs.name})`}
                                </span>
                            </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <h6 className="dropdown-header"><FormattedMessage id="COMMON.WELCOME" /> {name}!</h6>
                    
                     {
                        !user?.connectedAs &&  <>
                            <Link to="/profile">
                                <DropdownItem>
                                    <FaUserCircle className="align-middle text-muted me-1 icon-sm"/>
                                    <span className="align-middle"><FormattedMessage id="COMMON.PROFILE" /></span></DropdownItem>
                            </Link>
                            <div className="dropdown-divider"></div>
                         </>
                    }
                    {
                        !user?.connectedAs ? 
                            <DropdownItem onClick={logout}>
                                <MdOutlineLogout className="align-middle text-muted me-1 icon-sm"/>
                                <span className="align-middle" data-key="t-logout">
                                <FormattedMessage id="COMMON.LOGOUT" /></span>
                            </DropdownItem>
                            : 
                            <DropdownItem onClick={goToMainUser}>
                                <MdOutlineLogout className="align-middle text-muted me-1 icon-sm"/>
                                <span className="align-middle" data-key="t-logout">
                                <FormattedMessage id="USER.RETURN_TO_MAIN_ACCOUNT" /></span>
                            </DropdownItem>
                    }
                   
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};
import { axiosInstance } from "../../redux/setupAxios";


export type EcoDataResponse = {
    "detection": {_id: string, count: number}[],
    traject?: {
        "distance": number,
        "consomation": number,
        "duration": number,
        "maxSpeed": number,
        "count": number
    },
    score?: number,
    name:  string,
}
export async function getDriverStats(driverId?: string): Promise<EcoDataResponse | undefined> {
    let url = `/driver/${driverId}/generate`;
    try {
        const res = await axiosInstance.get<EcoDataResponse>(url);
        return res.data;
    } catch (e: any) { }
}

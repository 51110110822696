import { divIcon } from 'leaflet';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'leaflet-geometryutil';
import { renderToStaticMarkup } from 'react-dom/server';
import { useIntl } from 'react-intl';
import { Marker } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Address } from '../../../../../Components/Address';
import { EventName } from '../../../../../Components/advanced/Event-info';
import { formatDate } from '../../../../../helpers/dateHelper';
import { SetActiveEnd } from '../../../../../pages/Map/Object/Fuel/_redux/fuelActions';


export function MarkerFuelInfo({ event, active, index }: any): JSX.Element {
    const dispatch = useDispatch();
    const { timezone } = useSelector((state: any) => state.authReducer.user);
    const {date, km, coordinates, type} = event;
    
    const isFill = type === 'fill';

    const [lng, lat] = coordinates;
    const intl = useIntl();

    // RP5035, Aghbalou Aqorar, , Fès
    const address = '...';
    const getMarginTop = (): number => {
        const addressSize = address.length;
        return 155;
    }
    const iconPinPopupHtml = renderToStaticMarkup(
        <div className={`popup ${active ? 'popup-active' : ''}`}>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Nom:</span>
                <span className="value">
                {isFill ? 'Remplissage' : 'Pérte'}
                </span>
            </div>
            
            <div className="d-flex flex-row align-items-center address-container">
                <span className="address">
                    {' '}
                    <span className="key">Addresse:</span> ...

                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Position:</span>
                <span className="value">
                    [{lat}, {lng}]
                </span>
            </div>
            <div className="d-flex flex-row justify-content-between">
                <span className="key">Temps:</span>
                <span className="value">
                    {formatDate(date, timezone, 'DD/MM/YYYY HH:mm:ss')}
                </span>
            </div>

            <div className="d-flex flex-row justify-content-between">
                <span className="key">{isFill ? 'Remplissage' : 'Pérte'}:</span>
                <span className="value">
                    {isFill ? '+' : '-'}{ `${event.value?.toFixed(2)}`} Litres
                </span>
            </div>
        </div>
    );

    const handleClick = (): void => {
        if (active) {
            const payload = { ...event, ...{ index } };
            dispatch(SetActiveEnd(payload));
        }
    };

    const iconPopup = divIcon({
        html: iconPinPopupHtml,
        iconSize: [0, 0],
        iconAnchor: [128, getMarginTop()],
        popupAnchor: [0, -32],
    });
    return (
        event && (
            <Marker
                position={[lat, lng]}
                icon={iconPopup}
                zIndexOffset={99}
                eventHandlers={{
                    click: handleClick,
                }}
            />
        )
    );
}

import { IconType } from "react-icons";
import { Label } from "reactstrap";
import { IF } from "../If";

type ModalHeaderProps = {
    Icon: IconType;
    title: string;
    description?: string;
}

const ModalHeader = (props: ModalHeaderProps) => {
    return ( 
        <div className="d-flex flex-column justify-content-center align-items-center mb-4">
            <props.Icon className="text-primary mb-3" size={36}/>
            <Label className="fs-17 mb-3">{props.title}</Label> 
            <IF condition={!!props.description} >
                <span style={{fontWeight: 500}} className="text-muted fs-14 text-center">{props.description}</span>

            </IF>
        </div>
    );
}

export default ModalHeader;
import { axiosInstance } from "../../redux/setupAxios";

type MaintenanceStatsResponse = { expired: number, ok: number};
export async function getMaintenanceStats(): Promise<MaintenanceStatsResponse> {
    let url = `/maintenance/stats`;
    try {
        const res = await axiosInstance.get<any>(url);
        const expired = res.data.find((res: any) => res._id === 'expired')?.count ?? 0;
        const ok = res.data.find((res: any) => res._id === 'active')?.count ?? 0;
        
        return { expired, ok };
    } catch (e: any) {
        return {expired: 0, ok: 0};
    }
}

type UnitsStatsResponse = { total: number, movement: number, parking: number, idling: number };
export async function getUnitsStats(): Promise<UnitsStatsResponse> {
    let url = `/vehicle/stats`;
    try {
        const res = await axiosInstance.get<any>(url);
        
        return { ...res.data, idling: 0 };
    } catch (e: any) {
        return {total: 0, movement: 0, parking: 0, idling: 0};
    }
}

export type FuelBarResponse = {
    "name": string,
    "consumed": number
}
export async function getFuelStats(): Promise<FuelBarResponse[]> {
    let url = `/vehicle/stats/fuel`;
    try {
        const res = await axiosInstance.get<FuelBarResponse[]>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export type KmBarResponse = {
    "name": string,
    "km": number
}
export async function getKmStats(): Promise<KmBarResponse[]> {
    let url = `/vehicle/stats/km`;
    try {
        const res = await axiosInstance.get<KmBarResponse[]>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export type EventsStatsResponse = {
    "_id": string,
    "count": number
}
export async function getEventsStats(): Promise<EventsStatsResponse[]> {
    let url = `/event/stats?date=${new Date().getTime()}`;
    try {
        const res = await axiosInstance.get<EventsStatsResponse[]>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}


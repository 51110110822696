const getEnvPath = () => {
    const base = window.location.hostname;
    try {
        switch(base) {
            case "diplomatie.telematics.services": {
                return {
                    BASE_URL: "https://diplomatie.telematics.services",
                    SHARE_URL: "https://diplomatie.telematics.services",
                    SOCKET_IO: "https://diplomatie.telematics.services/realtime",
                    REVERSE_ADDRESS: "https://diplomatie.telematics.services/reverse",
                    HJID: "3521766",
                    HJSV: "6"
                }
            } 

            case "demo.telematics.services": {
                return {
                    BASE_URL: "http://demo.telematics.services:81",
                    SHARE_URL: "http://demo.telematics.services:81",
                    SOCKET_IO: "http://demo.telematics.services:82/realtime",
                    REVERSE_ADDRESS: "http://demo.telematics.services:8081/reverse",
                    HJID: "3521766",
                    HJSV: "6"
                }
            }
            
            case "localhost": {
                return {
                    BASE_URL: "http://localhost:3001",
                    SHARE_URL: "https://diplomatie.telematics.services",
                    SOCKET_IO: "http://localhost:4000/realtime",
                    // SOCKET_IO: "https://diplomatie.telematics.services/realtime",
                    REVERSE_ADDRESS: "https://diplomatie.telematics.services/reverse",
                    HJID: "",
                    HJSV: "6"
                }
            }
        }
    } catch(err) { }

    return {
        BASE_URL: "https://diplomatie.telematics.services",
        SHARE_URL: "https://diplomatie.telematics.services",
        SOCKET_IO: "https://diplomatie.telematics.services/realtime",
        REVERSE_ADDRESS: "https://diplomatie.telematics.services:83/reverse",
        REPORT_URL: "http://localost:3009",
        HJID: "3521766",
        HJSV: "6"
    }


}

export const {BASE_URL, SHARE_URL, SOCKET_IO, REVERSE_ADDRESS, REPORT_URL, HJID, HJSV} = getEnvPath();
export const LIMIT = 25;
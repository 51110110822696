import { divIcon } from 'leaflet';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Marker } from 'react-leaflet';

export function TrajectMarker({ position }: any) {
    const [lat, lng] = position;
    const iconPinHtml = renderToStaticMarkup(
        <div className={`marker-pin ${'bg-success'}`}>
            <FaMapMarkerAlt className="text-success" size={15}/>
        </div>
    );

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });



    return (
        <Marker
            position={{ lat, lng }}
            icon={iconPin}
        />
    );
}

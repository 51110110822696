import { FaEdit } from "react-icons/fa"
import { UncontrolledTooltip } from "reactstrap"
import { GeofenceAction, GeofenceInListType } from "../../../../pages/Geofence/types"

type GeofenceEditActionProps = {
    geofence: GeofenceInListType,
    onClick: (key: string, action: GeofenceAction) => void
}
export const GeofenceEditAction = ({geofence, onClick}: GeofenceEditActionProps) => {
    return(
        <>
            <FaEdit id={`edit-geo-${geofence.key}`} size={14} className="text-muted" onClick={()=> onClick(geofence.key, GeofenceAction.EDIT)}/>

            <UncontrolledTooltip target={`edit-geo-${geofence.key}`}>
                Modifier
            </UncontrolledTooltip>
        </>       
    )
}
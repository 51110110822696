import React from 'react';
import moment from 'moment';
import { TrajectIconItem } from './TrajectIconItem';
import { MdTimeline } from 'react-icons/md';
import { BsClockHistory } from 'react-icons/bs';
import { TrajectIconTime } from './TrajectTimeIcon';
import { isNumber } from 'lodash';
import { TrajectLimiter } from './TrajectLimiter';
import { TrajectTime } from './TrajectTime';
import classnames from 'classnames';


export function Traject({ trip, active }: any) {
    const { maxKm, minKm, startDate, endDate, endHistoryDate } = trip;
    const endDateTrip = endDate ? endDate : endHistoryDate;
    const duration = endDateTrip.diff(startDate);
    const diff = moment.utc(duration ?? 0);
    let km = '-';
    if(isNumber(maxKm) && isNumber(minKm)) {
        km = (maxKm - minKm).toFixed(2);
    }

    const trajectClassName = classnames('traject-info d-flex flex-row py-2 px-3', {'active': active})
    return (
        <div className={trajectClassName}>
            <TrajectTime trip={trip}/>
            <TrajectLimiter />
            <div className="cusror-pointer d-flex flex-column justify-content-between align-items-start">
                <span className="text-muted">...
                </span>
                <div className="d-flex flex-row">
                    <TrajectIconTime
                        color="#4cc476"
                        title="Temps du trajet"
                        icon={<BsClockHistory className="text-primary" size={16}/>}
                        hours={diff.format('HH')}
                        minutes={diff.format('mm')}
                        seconds={diff.format('ss')}
                    />
                    {
                        <TrajectIconItem 
                            className="ms-2" 
                            title="Kilométrage"
                            icon={<MdTimeline className="text-secondary" size={16}/>}
                            value={km}
                            unit="Km"
                        />
                    }

                    {/* {
                        maxSpeed >= 0 && <TrajectIconItem
                            className="ms-2" 
                            title="Vitésse maximal"
                            icon={<IoSpeedometerOutline className="text-warning" size={16}/>}
                            value={maxSpeed}
                            unit="Km/h"
                    />
                    } */}


                    {/* {
                        (fuel === 0 ||  fuel > 0) && <TrajectIconItem
                        className="ms-2" 
                        title="Carburant"
                        icon={<RiGasStationLine className="text-success" size={16}/>}
                        value={fuel.toFixed(2)}
                        unit="L"
                    />
                    } */}
                </div>
                <span className="text-muted">...
                </span>
            </div>
        </div>
           
        
        
    );
}
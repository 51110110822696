import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginEnd, LoginGenerateToken } from '../pages/Authentification/_redux/authActions';
import { getAuthToCookies } from '../pages/Authentification/_redux/authService';
import { useAppSelector } from '../redux/hooks';
import { AppPages } from './protected-routes';
import {PublicPages } from './public-routes';
import { ToolsComponents } from './ToolsComponents';

export function RoutesModule(): JSX.Element {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token, refreshToken, user } = useAppSelector(
        (state) => state.authReducer
    );
    
    const isAuthentificate = !!(token || refreshToken);
    useEffect(() => {
        const auth = getAuthToCookies();
        if (auth) {
            if (auth.token && auth.refreshToken) {
                dispatch(LoginEnd({ ...auth.token, ...auth.refreshToken }));
                if (pathname !== '/') navigate(`${pathname}${search}`);
                else navigate(`/dashboard`);
            } else if (auth.refreshToken && auth.refreshToken.refreshToken) {
                dispatch(LoginGenerateToken(auth.refreshToken));
                if (pathname !== '/') navigate(`${pathname}${search}`);
                else navigate(`/dashboard`);
            }
        }
    }, []);





    if(pathname.includes('shared-objects')) {
        return <PublicPages/>
    }
    
    return (
        <>
            <ToolsComponents/>
            { isAuthentificate ? <AppPages accountActive={!user?.refreshPassword} /> : <PublicPages/> }
        </>
        
    );
}

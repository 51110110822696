import { DeleteSensorType, FuelConfig, GetFuelConfigResponse, GetSensorsResponse, GetUpdateSensorType, ManageCalibrationSensor, ManageSensorType, TripVehicleType } from './../types';
import {
    FilterVehicleType,
    GetVehiclesResponse,
    GroupsVehicle,
    ManageVehicleType,
    TracerVehicle
} from '../types';

export const TOOGLE_POPUP = '[VEHICLE] TOOGLE_POPUP';
export type TooglePoupType = {
    type: typeof TOOGLE_POPUP;
    payload: boolean;
};
export function TooglePoup(payload: boolean): TooglePoupType {
    return { type: TOOGLE_POPUP, payload };
}

export const CREATE_VEHICLE_START = '[VEHICLE] CREATE_VEHICLE_START';
export type CreateVehicleStartType = {
    type: typeof CREATE_VEHICLE_START;
    payload: any;
};
export function CreateVehicleStart(
    payload: any
): CreateVehicleStartType {
    return { type: CREATE_VEHICLE_START, payload };
}

export const CREATE_VEHICLE_END = '[VEHICLE] CREATE_VEHICLE_END';
export type CreateVehicleEndType = {
    type: typeof CREATE_VEHICLE_END;
    payload: ManageVehicleType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateVehicleEnd(
    payload: ManageVehicleType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateVehicleEndType {
    return { type: CREATE_VEHICLE_END, payload };
}

export const ERROR = '[VEHICLE] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorVehicles(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_VEHICLES_START = '[VEHICLE] GET_VEHICLES_START';
export type GetVehiclesStartType = {
    type: typeof GET_VEHICLES_START;
    payload: FilterVehicleType;
};
export function GetVehiclesStart(
    payload: FilterVehicleType
): GetVehiclesStartType {
    return { type: GET_VEHICLES_START, payload };
}

export const GET_VEHICLES_END = '[VEHICLE] GET_VEHICLES_END';
export type GetVehiclesEndType = {
    type: typeof GET_VEHICLES_END;
    payload: { result: GetVehiclesResponse; filter: FilterVehicleType };
};
export function GetVehiclesEnd(payload: {
    result: GetVehiclesResponse;
    filter: FilterVehicleType;
}): GetVehiclesEndType {
    return { type: GET_VEHICLES_END, payload };
}

export const SET_SELECTED_VEHICLES = '[VEHICLE] SET_SELECTED_VEHICLES';
export type SetSelectedVehiclesType = {
    type: typeof SET_SELECTED_VEHICLES;
    payload: string[];
};
export function SetSelectedVehicles(
    payload: string[]
): SetSelectedVehiclesType {
    return { type: SET_SELECTED_VEHICLES, payload };
}

export const DELETE_VEHICLES_START = '[VEHICLE] DELETE_VEHICLES_START';
export type DeleteVehiclesStartType = {
    type: typeof DELETE_VEHICLES_START;
    payload: string[];
};
export function DeleteVehiclesStart(
    payload: string[]
): DeleteVehiclesStartType {
    return { type: DELETE_VEHICLES_START, payload };
}

export const DELETE_VEHICLES_END = '[VEHICLE] DELETE_VEHICLES_END';
export type DeleteVehiclesEndType = {
    type: typeof DELETE_VEHICLES_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteVehiclesEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteVehiclesEndType {
    return { type: DELETE_VEHICLES_END, payload };
}

export const CLEAR_VEHICLE_CREATION = '[VEHICLE] CLEAR_VEHICLE_CREATION';
export type ClearVehicleCreationActionType = {
    type: typeof CLEAR_VEHICLE_CREATION;
};
export function ClearVehicleCreation(): ClearVehicleCreationActionType {
    return { type: CLEAR_VEHICLE_CREATION };
}

export const GET_VEHICLE_UPDATE_START = '[DRVIER] GET_VEHICLE_UPDATE_START';
export type GetVehicleUpdateStartType = {
    type: typeof GET_VEHICLE_UPDATE_START;
    payload: { _id: string };
};
export function GetVehicleUpdateStart(payload: {
    _id: string;
}): GetVehicleUpdateStartType {
    return { type: GET_VEHICLE_UPDATE_START, payload };
}

export const GET_VEHICLE_UPDATE_END = '[VEHICLE] GET_VEHICLE_UPDATE_END';
export type GetVehicleUpdateEndType = {
    type: typeof GET_VEHICLE_UPDATE_END;
    payload: { result: ManageVehicleType & {trip: TripVehicleType} };
};
export function GetVehicleUpdateEnd(payload: {
    result: ManageVehicleType & {trip: TripVehicleType};
}): GetVehicleUpdateEndType {
    return { type: GET_VEHICLE_UPDATE_END, payload };
}

export const SET_VEHICLE_GROUP_TYPE = '[VEHIVLE] SET_VEHICLE_GROUP_TYPE';
export type SetGroupVehicleActionType = {
    type: typeof SET_VEHICLE_GROUP_TYPE;
    payload?: GroupsVehicle[];
};
export function SetGroupVehicleAction(
    payload?: GroupsVehicle[]
): SetGroupVehicleActionType {
    return { type: SET_VEHICLE_GROUP_TYPE, payload };
}

export const SET_VEHICLE_TRACER_TYPE = '[VEHIVLE] SET_VEHICLE_TRACER_TYPE';
export type SetTracerVehicleActionType = {
    type: typeof SET_VEHICLE_TRACER_TYPE;
    payload?: TracerVehicle;
};
export function SetTracerVehicleAction(
    payload?: TracerVehicle
): SetTracerVehicleActionType {
    return { type: SET_VEHICLE_TRACER_TYPE, payload };
}

export const SET_VEHICLE_NAME_TYPE = '[VEHIVLE] SET_VEHICLE_NAME_TYPE';
export type SetVehicleNameActionType = {
    type: typeof SET_VEHICLE_NAME_TYPE;
    payload: {
        name: string;
    };
};
export function SetVehicleNameAction(payload: {
    name: string;
}): SetVehicleNameActionType {
    return { type: SET_VEHICLE_NAME_TYPE, payload };
}

export const SET_SELECT_TYPE_SENSOR = '[VEHIVLE] SET_SELECT_TYPE_SENSOR';
export type SetSelectTypeSensorActionType = {
    type: typeof SET_SELECT_TYPE_SENSOR;
    payload?: {label: string, value: string};
};
export function SetSelectTypeSensorAction(payload?: {label: string, value: string}): SetSelectTypeSensorActionType {
    return { type: SET_SELECT_TYPE_SENSOR, payload };
}

export const SET_SELECT_SUB_TYPE_SENSOR = '[VEHIVLE] SET_SELECT_SUB_TYPE_SENSOR';
export type SetSelectSubTypeSensorActionType = {
    type: typeof SET_SELECT_SUB_TYPE_SENSOR;
    payload?: {label: string, value: string};
};
export function SetSelectSubTypeSensorAction(payload?: {label: string, value: string}): SetSelectSubTypeSensorActionType {
    return { type: SET_SELECT_SUB_TYPE_SENSOR, payload };
}

export const SET_SELECT_IO_SENSOR = '[VEHIVLE] SET_SELECT_IO_SENSOR';
export type SetSelectIoSensorActionType = {
    type: typeof SET_SELECT_IO_SENSOR;
    payload?: {label: string, value: string};
};
export function SetSelectIoSensorAction(payload?: {label: string, value: string}): SetSelectIoSensorActionType {
    return { type: SET_SELECT_IO_SENSOR, payload };
}

export const CLEAR_MANAGE_SENSOR = '[VEHIVLE] CLEAR_MANAGE_SENSOR';
export type ClearManageSensorActionType = {
    type: typeof CLEAR_MANAGE_SENSOR;
};
export function ClearManageSensorAction(): ClearManageSensorActionType {
    return { type: CLEAR_MANAGE_SENSOR };
}

export const CREATE_SENSOR_START = '[VEHICLE] CREATE_SENSOR_START';
export type CreateSensorStartType = {
    type: typeof CREATE_SENSOR_START;
    payload: any;
};
export function CreateSensoreStart(
    payload: any
): CreateSensorStartType {
    return { type: CREATE_SENSOR_START, payload };
}

export const CREATE_SENSOR_END = '[VEHICLE] CREATE_SENSOR_END';
export type CreateSensorEndType = {
    type: typeof CREATE_SENSOR_END;
    payload: ManageSensorType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateSensorEnd(
    payload: ManageSensorType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateSensorEndType {
    return { type: CREATE_SENSOR_END, payload };
}

export const TOOGLE_SENSOR_POPUP = '[VEHICLE] TOOGLE_SENSOR_POPUP';
export type ToogleSensorPopupType = {
    type: typeof TOOGLE_SENSOR_POPUP;
};
export function ToogleSensorPopup(): ToogleSensorPopupType {
    return { type: TOOGLE_SENSOR_POPUP };
}

export const GET_SENSORS_START = '[VEHICLE] GET_SENSORS_START';
export type GetSensorsStartType = {
    type: typeof GET_SENSORS_START;
    payload: {vehicleId: string};
};
export function GetSensorsStart(
    payload: {vehicleId: string}
): GetSensorsStartType {
    return { type: GET_SENSORS_START, payload };
}

export const GET_SENSORS_END = '[VEHICLE] GET_SENSORS_END';
export type GetSensorsEndType = {
    type: typeof GET_SENSORS_END;
    payload: GetSensorsResponse;
};
export function GetSensorsEnd(payload: GetSensorsResponse): GetSensorsEndType {
    return { type: GET_SENSORS_END, payload };
}

export const MANAGE_CALIBRATION_SENSOR = '[VEHICLE] MANAGE_CALIBRATION_SENSOR';
export type ManageCalibrationSensorType = {
    type: typeof MANAGE_CALIBRATION_SENSOR;
    payload: ManageCalibrationSensor;
};
export function ManageCalibration(payload: ManageCalibrationSensor): ManageCalibrationSensorType {
    return { type: MANAGE_CALIBRATION_SENSOR, payload };
}

export const DELETE_SENSOR_START = '[VEHICLE] DELETE_SENSOR_START';
export type DeleteSensorStartType = {
    type: typeof DELETE_SENSOR_START;
    payload: DeleteSensorType;
};
export function DeleteSensorStart(
    payload: DeleteSensorType
): DeleteSensorStartType {
    return { type: DELETE_SENSOR_START, payload };
}

export const DELETE_SENSOR_END = '[VEHICLE] DELETE_SENSOR_END';
export type DeleteSensorEndType = {
    type: typeof DELETE_SENSOR_END;
    payload: { modifiedCount: number; sensorId: string };
};
export function DeleteSensorEnd(payload: {
    modifiedCount: number;
    sensorId: string;
}): DeleteSensorEndType {
    return { type: DELETE_SENSOR_END, payload };
}

export const GET_SENSOR_UPDATE_START = '[VEHICLE] GET_SENSOR_UPDATE_START';
export type GetSensorUpdateStartType = {
    type: typeof GET_SENSOR_UPDATE_START;
    payload: { _id: string, sensorId: string };
};
export function GetSensorUpdateStart(payload: { _id: string, sensorId: string }): GetSensorUpdateStartType {
    return { type: GET_SENSOR_UPDATE_START, payload };
}

export const GET_SENSOR_UPDATE_END = '[VEHICLE] GET_SENSOR_UPDATE_END';
export type GetSensorUpdateEndType = {
    type: typeof GET_SENSOR_UPDATE_END;
    payload: GetUpdateSensorType;
};
export function GetSensorUpdateEnd(payload: GetUpdateSensorType): GetSensorUpdateEndType {
    return { type: GET_SENSOR_UPDATE_END, payload };
}

export const TOOGLE_TIMING_SENSOR_START = '[VEHICLE] TOOGLE_TIMING_SENSOR_START';
export type ToogleTimingSensorStartType = {
    type: typeof TOOGLE_TIMING_SENSOR_START;
    payload: {sensorId: string, value: boolean};
};
export function ToogleTimingSensorStart(
    payload: {sensorId: string, value: boolean}
): ToogleTimingSensorStartType {
    return { type: TOOGLE_TIMING_SENSOR_START, payload };
}

export const TOOGLE_TIMING_SENSOR_END = '[VEHICLE] TOOGLE_TIMING_SENSOR_END';
export type ToogleTimingSensorEndType = {
    type: typeof TOOGLE_TIMING_SENSOR_END;
    payload: { modifiedCount: number; sensorId: string, value: boolean; };
};
export function ToogleTimingSensorEnd(payload: {
    modifiedCount: number;
    sensorId: string;
    value: boolean;
}): ToogleTimingSensorEndType {
    return { type: TOOGLE_TIMING_SENSOR_END, payload };
}

export const TOOGLE_FUEL_CONFIG = '[VEHICLE] TOOGLE_FUEL_CONFIG';
export type ToogleFuelConfigType = { type: typeof TOOGLE_FUEL_CONFIG; };
export function ToogleFuelConfig(): ToogleFuelConfigType {
    return { type: TOOGLE_FUEL_CONFIG };
}

export const SET_FUEL_CONFIG = '[VEHICLE] SET_FUEL_CONFIG';
export type SetFuelConfigType = { type: typeof SET_FUEL_CONFIG; payload: FuelConfig};
export function SetFuelConfig(payload: FuelConfig): SetFuelConfigType {
    return { type: SET_FUEL_CONFIG, payload };
}

export const SET_FUEL_DETECTION_TYPE = '[VEHICLE] SET_FUEL_DETECTION_TYPE';
export type SetFuelDetectionType = { type: typeof SET_FUEL_DETECTION_TYPE; payload: "time" | 'mielage'};
export function SetFuelDetection(payload: "time" | 'mielage'): SetFuelDetectionType {
    return { type: SET_FUEL_DETECTION_TYPE, payload };
}

export const UPDATE_TRIP_START = '[VEHICLE] UPDATE_TRIP_START';
export type UpdateTripStartType = {
    type: typeof UPDATE_TRIP_START;
    payload: { minParkingTime: number, minSpeed: number, by: 'ignition' };
};
export function UpdateTripStart(
    payload: { minParkingTime: number, minSpeed: number, by: 'ignition' }
): UpdateTripStartType {
    return { type: UPDATE_TRIP_START, payload };
}

export const UPDATE_TRIP_END = '[VEHICLE] UPDATE_TRIP_END';
export type UpdateTripEndType = {
    type: typeof UPDATE_TRIP_END;
};
export function UpdateTripEnd(
): UpdateTripEndType {
    return { type: UPDATE_TRIP_END, };
}


export const GET_FUEL_CONFIG_END = '[VEHICLE] GET_FUEL_CONFIG_END';
export type GetFuelConfigEndType = {
    type: typeof GET_FUEL_CONFIG_END;
    payload?: GetFuelConfigResponse;
};
export function GetFuelConfigEnd(payload?: GetFuelConfigResponse): GetFuelConfigEndType {
    return { type: GET_FUEL_CONFIG_END, payload };
}

export const TOOGLE_VISIBLE_SENSOR_START = '[VEHICLE] TOOGLE_VISIBLE_SENSOR_START';
export type ToogleVisibleSensorStartType = {
    type: typeof TOOGLE_VISIBLE_SENSOR_START;
    payload: {sensorId: string, value: boolean};
};
export function ToogleVisibleSensorStart(
    payload: {sensorId: string, value: boolean}
): ToogleVisibleSensorStartType {
    return { type: TOOGLE_VISIBLE_SENSOR_START, payload };
}

export const TOOGLE_VISIBLE_SENSOR_END = '[VEHICLE] TOOGLE_VISIBLE_SENSOR_END';
export type ToogleVisibleSensorEndType = {
    type: typeof TOOGLE_VISIBLE_SENSOR_END;
    payload: { modifiedCount: number; sensorId: string, value: boolean; };
};
export function ToogleVisibleSensorEnd(payload: {
    modifiedCount: number;
    sensorId: string;
    value: boolean;
}): ToogleVisibleSensorEndType {
    return { type: TOOGLE_VISIBLE_SENSOR_END, payload };
}

export type VehicleActionType =
    | TooglePoupType
    | CreateVehicleStartType
    | CreateVehicleEndType
    | ErrorActionType
    | GetVehiclesStartType
    | GetVehiclesEndType
    | SetSelectedVehiclesType
    | DeleteVehiclesStartType
    | DeleteVehiclesEndType
    | ClearVehicleCreationActionType
    | GetVehicleUpdateStartType
    | GetVehicleUpdateEndType
    | SetGroupVehicleActionType
    | SetTracerVehicleActionType
    | SetVehicleNameActionType
    | SetSelectTypeSensorActionType
    | SetSelectSubTypeSensorActionType
    | SetSelectIoSensorActionType
    | ClearManageSensorActionType
    | CreateSensorStartType 
    | CreateSensorEndType
    | ToogleSensorPopupType
    | GetSensorsStartType
    | GetSensorsEndType
    | ManageCalibrationSensorType
    | DeleteSensorStartType
    | DeleteSensorEndType
    | GetSensorUpdateStartType
    | GetSensorUpdateEndType
    | ToogleTimingSensorStartType
    | ToogleTimingSensorEndType
    | ToogleFuelConfigType
    | SetFuelConfigType
    | UpdateTripStartType
    | UpdateTripEndType
    | ToogleVisibleSensorStartType
    | ToogleVisibleSensorEndType
    | SetFuelDetectionType

import { CardBody, CardHeader } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { CardUi } from "../../../../../Components/basic/Card";
import { Stepper } from "../../components/stepper";
import { UnitRoadComponent } from "./vehicle";
import { AlertUi } from "../../../../../Components/basic/AlertUi";
import { ButtonUi } from "../../../../../Components/basic/ButtonUi";
import { IoMdAdd } from "react-icons/io";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

type UnitsPageProps = {
    passengers: number,
    step: number,
    selectedUnits: {key: string, capacity: number}[],
    onChangeStep: (step: number) => void,
    onChangeUnits: (data: {key: string, capacity: number}[]) => void,
    children: JSX.Element
}
export const UnitsPage = (props: UnitsPageProps) => {
    const capacity = 19;
    const [minimum, setMinimum] = useState(Math.ceil(props.passengers / capacity))
    const [vehicles, setVehicles] = useState<{key: string, capacity: number}[]>(props.selectedUnits);

    useEffect(() => {
        if(vehicles.length === 0) {
            const vehicles = [];
            for(let i=0;i<minimum;i++) {
                vehicles.push({
                    key: (uuidv4()),
                    capacity
                })
            }
            setVehicles(vehicles);
        } 
    }, [minimum]);

    const addVehicle = useCallback(() => {
        const newVehicles = [...vehicles];
        newVehicles.push({                    
            key: (uuidv4()),
            capacity})
        setVehicles(newVehicles);
        props.onChangeUnits(newVehicles);
    }, [vehicles])

    const removeVehicle = useCallback((uid: string) => {
        const units = vehicles.filter(vehicle => vehicle.key !== uid)
        setVehicles(units);
        props.onChangeUnits(units);
    }, [vehicles])

    return (
        <div className="d-flex flex-row">
            <CardUi className="flex-grow-1">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                    <span><FormattedMessage id={"Étapes"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    <Stepper step={props.step} onChange={props.onChangeStep}/>
                </CardBody>
            </CardUi>
            <CardUi style={{flex: 4}} className="mx-2">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"Paramétrer les transports"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    <AlertUi color="warning">
                       <span>
                            Le nombre minimum des transports à choisir est: <span className="fw-bold">{minimum} transports</span>
                        </span>
                    </AlertUi>

                    <ButtonUi color="primary" className="w-100" outline onClick={addVehicle}>
                        <><IoMdAdd size={15}/> Ajouter</>
                    </ButtonUi>
                    <>
                        {
                            vehicles.map((unit, index: number) => {
                                return(
                                    <UnitRoadComponent 
                                        key={unit.key}
                                        uid={unit.key}
                                        index={index} 
                                        places={capacity}
                                        canBeRemoved={(index + 1) > minimum}
                                        onRemove={removeVehicle}
                                    /> 
                                )
                            })
                        }
                    </>
                </CardBody>
            </CardUi> 
            <CardUi className="flex-grow-1">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"Résumé"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    { props.children }
                </CardBody>
            </CardUi>
        </div>

    );
}
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { getDuration } from "../../../helpers/dateHelper";

type driverTrajectProps = {
    data?: {
        "distance": number,
        "consomation": number,
        "duration": number,
        "maxSpeed": number,
        "count": number
    }
}

export const DriverTraject = ({data}: driverTrajectProps) => {

    return (
        <CardUi>
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Analyse des trajets</h6>
                </div>
                <div>
                    <div className="d-flex mt-4 align-items-between justify-content-between">
                        <span className=" ms-1 fw-medium user-name-text">Nombre de trajets</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold">{ data?.count ?? '-' }</span>
                        </div>
                    </div>
                    <div className="d-flex mt-4 align-items-between justify-content-between">
                        <span className=" ms-1 fw-medium user-name-text">Temps de conduite</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold">{data?.duration ? getDuration(data.duration) : '-'}</span>
                        </div>
                    </div>
                    <div className="d-flex mt-4 align-items-between justify-content-between">
                        <span className=" ms-1 fw-medium user-name-text">Consomation en litres</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold">{ data?.consomation ?? '-' } Litres</span>
                        </div>
                    </div>
                    <div className="d-flex mt-4 align-items-between justify-content-between">
                        <span className=" ms-1 fw-medium user-name-text">Kilométrage parcourus</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold">{ data?.distance ?? '-' } km</span>
                        </div>
                    </div>
                    <div className="d-flex mt-4 align-items-between justify-content-between">
                        <span className=" ms-1 fw-medium user-name-text">Vitesse maximal</span>
                        <div className="d-flex flex-column align-items-center">
                            <span className="fw-bold">{ data?.maxSpeed ?? '-' } km/h</span>
                        </div>
                    </div>
                    
                </div>

            </CardBody>
        </CardUi>               
    );
}
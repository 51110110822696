import { AiFillEye } from "react-icons/ai";
import { RoutesColumnsMinutes } from "../../components/road/route-minutes";
import { getMinutesSteps } from "../../routes-details.page";
import { IF } from "../../../../../Components/If";
import { FaFlagCheckered, FaRegFlag } from "react-icons/fa";

interface Props {
  width: number;
  distance: string;
  name: string | number;
  load: number;
  steps: any[];
  idx: number;
  duration: number;
  start: Date;
  end: Date;
  setselectedPerson: any;
  color: string;
  type: "start" | "end";
}


function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

function convertMsToHM(seconds: number) {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  hours = hours % 24;

  return `${padTo2Digits(hours)}h : ${padTo2Digits(minutes)}m`;
}

export const RoadTableBody = ({
  width,
  name,
  load,
  steps,
  idx,
  duration,
  start,
  end,
  setselectedPerson,
  color,
  type,
  distance
}: Props) => {
  const WIDTH_DATA_COLUMS = 500;
  const waitingTime = 150000;
  const index = 0;
  const arrayLength = Math.ceil(
    (end.getTime() - start.getTime()) / 3600000 
  );
  const step = getMinutesSteps(end, start);
  return (
    <tbody>
      <tr
        className={`position-relative  ${idx % 2 ? "" : "table-body-gray"} `}
        style={{
          zIndex: 2,
        }}
      >
        <td style={{ width: 50 }} className="text-center">
          {" "}
          <AiFillEye size={18} />{" "}
        </td>
        <td className="text-center" style={{ width: 150 }}>
          {name}
        </td>
        <td className="text-center border" style={{ width: 100 }}>
          { distance } Km
        </td>
        <td className="text-center border" style={{ width: 100 }}>
          {load}/19
        </td>
        <td className="text-center" style={{ width: 100 }}>
          {convertMsToHM(duration)}
        </td>

        
          <td className="border text-center ">
            <IF condition={type === "start"}>
              <div
                className="position-absolute vertical-center-absolute d-flex justify-content-center align-items-center"
                style={{
                  width: 30,
                  height: 30,
                  left:
                    WIDTH_DATA_COLUMS +
                    (new Date(start).getMinutes() / 60 + index) * width + 1,
                  border: "2px solid",
                  borderRadius: 10,
                  zIndex: 122,
                  backgroundColor: color ?? "red"
                }}
              >
                <FaRegFlag
                  size={16}
                  color="white"
                  style={{
                    zIndex: 122,
                  }}
                />
              </div>
            </IF>

            <div
              className="position-absolute vertical-center-absolute" 
              style={{
                width: (duration / 60) * (width / 60),
                left:
                  WIDTH_DATA_COLUMS +
                  (new Date(start).getMinutes() / 60 + index) * width +
                  5,
                border: "2px solid",
                height: 10,
                backgroundColor: color ?? "red"
              }}
            />


            
            <IF condition={type === "end"}>
              <div
                className="position-absolute vertical-center-absolute d-flex justify-content-center align-items-center"
                style={{
                  width: 30,
                  height: 30,
                  left:
                    WIDTH_DATA_COLUMS +
                    (new Date(start).getMinutes() / 60 + index) * width +
                    (duration / 60) * (width / 60) - 5,
                  border: "2px solid",
                  borderRadius: 10,
                  zIndex: 122,
                  backgroundColor: color ?? "red"
                }}
              >
                <FaFlagCheckered
                  size={16}
                  color="white"
                  style={{
                    zIndex: 122,
                  }}
                />
              </div>
            </IF>
            
            {steps.map((step, idx) => {
              return (
                <div
                  className="position-absolute border border-2 bg-white rounded vertical-center-absolute"
                  style={{
                    height: 26,
                    left:
                      WIDTH_DATA_COLUMS +
                      (new Date(start).getMinutes() / 60 + index +
                        step.objects[0].arrival / 3600) * width,
                    width: waitingTime / 6000,
                    cursor: "pointer",
                    zIndex: 999,
                  }}
                  onClick={() =>
                    setselectedPerson({
                      selected: true,
                      data: step.objects,
                      name: step.stop,
                    })
                  }
                >
                  {step.objects.length}
                </div>
              );
            })} 
          </td>
      

        {Array.from(Array(arrayLength), (_, index) => {
          return <RoutesColumnsMinutes key={index} step={step} />;
        })}
      </tr>
    </tbody>
  );
};

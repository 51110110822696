import { ManageShareType, ShareInList } from '../types';
import {
    CLEAR_SHARE_CREATION,
    CREATE_SHARE_END,
    CREATE_SHARE_START,
    DELETE_SHARES_END,
    DELETE_SHARES_START,
    ERROR,
    GET_SHARES_END,
    GET_SHARES_START,
    GET_SHARE_UPDATE_END,
    GET_SHARE_UPDATE_START,
    SET_SELECTED_SHARES,
    SET_SHARE_EXPIRED_DATE_TYPE,
    SET_VEHICLES_SHARE_TYPE,
    ShareActionType,
    TOOGLE_POPUP
} from './share.actions';

type StateType = {
    loading: boolean;
    popup: boolean;
    manage: ManageShareType;
    selectedShares: string[];
    filter: {
        limit: number;
        skip: number;
    };
    shares: {
        list: ShareInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    popup: false,
    shares: {
        list: [],
        total: 0,
    },
    selectedShares: [],
    manage: {
        name: '',
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function shareReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: ShareActionType
): StateType {
    switch (action.type) {
        case CREATE_SHARE_START:
        case GET_SHARES_START:
        case GET_SHARE_UPDATE_START:
        case DELETE_SHARES_START: {
            return { ...state, loading: true };
        }

        case TOOGLE_POPUP:
            return {
                ...state,
                popup: action.payload,
            };

        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }
        case CREATE_SHARE_END: {
            const list = [...state.shares.list];
            const { _id, modifiedCount } = action.payload;
            const updatedShare: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount > 0) {
                    const shareIndex = state.shares.list.findIndex(
                        (share) => share.key === _id
                    );
                    if (shareIndex !== -1) {
                        list[shareIndex] = {
                            ...list[shareIndex],
                            ...updatedShare,
                        };
                    }
                }
            } else {
                list.unshift(updatedShare);
            }

            return {
                ...state,
                loading: false,
                shares: {
                    list,
                    total: state.shares.total + 1,
                },
                popup: false,
                selectedShares: [],
            };
        }

        case GET_SHARES_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                shares: result,
                filter,
                loading: false,
            };
        case SET_SELECTED_SHARES:
            return {
                ...state,
                selectedShares: action.payload,
            };
        case DELETE_SHARES_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedShares:
                        state.selectedShares.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    shares: {
                        total: state.shares.total - deletedCount,
                        list:
                            state.shares.list.filter(
                                (share) => !_ids.includes(share.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_SHARE_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                popup: true,
                loading: false,
            };
        }

        case CLEAR_SHARE_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
                selectedShares: [],
            };
        }

        case SET_VEHICLES_SHARE_TYPE:
            return {
                ...state,
                manage: {
                    ...state.manage,
                    units: action.payload,
                },
            };

        case SET_SHARE_EXPIRED_DATE_TYPE:
            return {
                ...state,
                manage: {
                    ...state.manage,
                    expiredDate: action.payload,
                },
            };
        default:
            return state;
    }
}

import { useEffect } from "react";
import { useAxios } from "../useAxios";
import { PassengerListItemType } from "./type";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
}

export type FetchPassengerProps = {
  name?: string,
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
  group?: string;
}

type UseFetchPassengerResponse = { 
  passengersWithStop: { list: PassengerListItemType[], total: number };
  fetchPassengersWithStopLoading: boolean;
  error?: string;
  fetchPassengersWithStop: (params?: FetchPassengerProps) => void;
  cancel: () => void;
}



type UseFetchReportItemsProps = {
  manual?: boolean,
  skip?: number,
  limit?: number,
  group?: string
}

export function useFetchPassengersWithStop(props: UseFetchReportItemsProps): UseFetchPassengerResponse {
  const URL = `passengers/with-stop`;
  const { execute, data, cancel, error, loading, init } = useAxios<{list: PassengerListItemType[], total: number}>(props.manual);

  useEffect(() => {
    if(!props.manual) {
      fetchPassengersWithStop({"pagination[skip]": props.skip ?? 0, "pagination[limit]": props.limit ?? 25});
    }
  }, []);

  const fetchPassengersWithStop = (params?: FetchPassengerProps) => {
    execute(URL, { method: "GET", params});
  };

  return { 
    passengersWithStop: data ?? {list: [], total: 0},
    fetchPassengersWithStopLoading: loading, 
    error,
    fetchPassengersWithStop,
    cancel, 
  };
}

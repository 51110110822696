import { useCallback, useEffect, useRef, useState } from "react"
import { CustomMarker } from "../../../modules/Map/components/marker/ObjectsMarker/CustomMarker"
import { LatLng, divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { MdOutlineAirlineSeatReclineNormal } from "react-icons/md";
import Emitter from "../../../helpers/emitter";
import EmitterActions from "../../../helpers/emitter/emitter.actions";
import { useMap } from "react-leaflet";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";

export const CreatePassengerMarker = () => {
    const map = useMap();
    const mapEventRef = useRef<any>();
    const [visible, setVisible] = useState(false);
    const [location, setLocation] = useState({lat: 0, lng: 0});
    const { pathname } = useLocation();
    const [queryParameters] = useSearchParams();

    const isAddMatch = useMatch({path: "/passengers/add"});
    const isUpdateMatch = useMatch({path: "/passengers/:_id/update"});

    useEffect( () => {
        Emitter.on(EmitterActions["PASSENGER:SET_MARKER"], (payload: {visibilty: boolean, location?: LatLng})=> {
            let position = map.getCenter();
            if(payload?.location) {
                position = payload.location;
                map.panTo(position);
            }
            setVisible(true);
            updateMarkerLocation(position);
        });

        if(!isAddMatch && !isUpdateMatch) {
            setVisible(false);
            setLocation({lat: 0, lng: 0});
            Emitter.off(["PASSENGER:SET_MARKER"], ()=> {});
            
        }
        return () => {
            Emitter.off(["PASSENGER:SET_MARKER"], ()=> { });
        };
    }, [pathname, queryParameters.get("action")]);

    useEffect(()=> {
        if(visible && !mapEventRef.current) {
            mapEventRef.current = map.on('click',mapEvent);  
        } else if(!visible){
            map.off('click', mapEvent);
            mapEventRef.current = null; 
        } 
    }, [visible]);

    const mapEvent = useCallback((e: any) => updateMarkerLocation(e.latlng), []);
    const updateMarkerLocation = useCallback((location: LatLng) => {
        setLocation(location);
        Emitter.emit(EmitterActions["PASSENGER:SET_LOCATION_VALUE"], { location });
    }, []);

    const updateLocation = (e: any) => {
        updateMarkerLocation(e.target.getLatLng());
    }

    const iconPinHtml = renderToStaticMarkup(
        <div className={"marker-pin bg-danger "}>
            <MdOutlineAirlineSeatReclineNormal size={13}/>
        </div>
    );

    
    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
    });
    
    if(visible && (isAddMatch || isUpdateMatch)) {
        return (
            <CustomMarker
                position={location}
                icon={iconPin}
                rotationOrigin="center"
                rotationAngle={0}
                draggable
                duration={1}
                eventHandlers={{ dragend: updateLocation }}
            />     
        )
    }
    return <></>
    
}
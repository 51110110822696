import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { GoTriangleUp } from 'react-icons/go';
import {CustomMarker} from './CustomMarker';

export function DirectionMarker({ vehicle, angle }: any) {
    const { _id, name, baseInfo } = vehicle;
    if(!baseInfo || !baseInfo.latitude || !baseInfo.latitude) {
        return null;
    }
    const iconPinHtml = renderToStaticMarkup(
        <div className='text-center'>
            <GoTriangleUp size={17} color="red"/>
        </div>
    );

    
    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [64, 64],
        iconAnchor: [34, angle >= 155 && angle <= 205 ? 49 : 54],
        // popupAnchor: [0, -32],
    });
    
    return (
        <CustomMarker
            key={_id}
            position={{ lat: baseInfo.longitude, lng: baseInfo.latitude }}
            icon={iconPin}
            rotationOrigin="center"
            rotationAngle={angle}
            duration={2000}
        />     
    );
}

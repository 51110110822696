import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import { GeofenceAction } from '../types';
import { CreateGeofenceEnd, CreateGeofenceStartType, CREATE_GEOFENCE_START, DeleteGeofenceEnd, DeleteGeofenceStart, DeleteGeofenceStartType, DELETE_GEOFENCE_START, ErrorGeofence, GeofenceActionsType, GetGeofencesEnd, GetGeofencesStartType, GetGeofenceUpdateEnd, GetGeofenceUpdateStartType, GET_GEOFENCES_START, GET_GEOFENCE_UPDATE_START } from './geofenceModule.actions';
import { deleteGeofences, getGeofence, getGeofences, saveGeofence, updateGeofence } from './geofenceModule.services';



export const GetGeofencesEpic = (
    action$: ActionsObservable<GetGeofencesStartType>,
    state$: { value: RootState }
): Observable<GeofenceActionsType> =>
    action$.pipe(
        ofType(GET_GEOFENCES_START),
        switchMap((action: GetGeofencesStartType) => {
            return from(getGeofences(action.payload)).pipe(
                map((result) => {
                    return GetGeofencesEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorGeofence(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorGeofence(err.msg));
        })
    );
   

export const CreateGeofenceEpic = (
    action$: ActionsObservable<CreateGeofenceStartType>,
    state$: { value: RootState }
): Observable<GeofenceActionsType | any> =>
    action$.pipe(
        ofType(CREATE_GEOFENCE_START),
        switchMap((action: CreateGeofenceStartType) => {
            const data = action.payload;
            return from(
                data.key ? updateGeofence(data) : saveGeofence(data)
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...data,
                        ...result,
                        ...{
                            key: data.key ? data.key : result.insertedId,
                        },
                    };

                    return of(
                        CreateGeofenceEnd(newManage),                         
                        SetRedirectAction('/geofences'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                    );
                }),
                catchError((err) => {
                    return of(ErrorGeofence(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorGeofence(err.msg));
        })
    );

export const DeleteGeofencesEpic = (
    action$: ActionsObservable<DeleteGeofenceStartType>,
    state$: { value: RootState }
): Observable<GeofenceActionsType | any> =>
    action$.pipe(
        ofType(DELETE_GEOFENCE_START),
        switchMap((action: DeleteGeofenceStartType) => {
            return from(deleteGeofences({ _ids: action.payload })).pipe(
                mergeMap((result) => {
                    return of(DeleteGeofenceEnd({
                        ...result,
                        keys: action.payload,
                    }), SetNotificationAction({
                        title: "success",
                        "message": 'Action',
                        timeToClose: 3000,
                        type: 'success'
                    }));
                }),
                catchError((err) => {
                    return of(ErrorGeofence(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorGeofence(err.msg));
        })
    );
        
    export const GetGeofenceEpic = (
        action$: ActionsObservable<GetGeofenceUpdateStartType>,
        state$: { value: RootState }
    ): Observable<GeofenceActionsType | any> =>
        action$.pipe(
            ofType(GET_GEOFENCE_UPDATE_START),
            switchMap((action: GetGeofenceUpdateStartType) => {
                return from(getGeofence(action.payload)).pipe(
                    map((result) => {
                        return GetGeofenceUpdateEnd({ result });
                    }),
                    catchError((err) => {
                        return of(ErrorGeofence(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorGeofence(err.msg));
            })
        );
import { useRef } from "react";
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from "reactstrap";
import { ScrollBar } from "../../../../Components/PerfectScrollBar";
import ReportResultItem from "../../../../Components/advanced/Report/report-result-item";
import { ReportItems } from "../../../../common/hooks/axios/report/useFetchReportItems";

type ReportListProps = {
    items: ReportItems[],
    selected: string[],
    toggle: (itemId: string, action: 'add' | 'remove') => void;
}
const ReportList = (props: ReportListProps) => {
    const refScrollBar = useRef();
    return (
        <ScrollBar
            id="popup-content-scrollbar"
            minusHeight={551}
            containerRef={refScrollBar}
        >
            <UncontrolledAccordion open="1" defaultOpen={[
                '1',
                '2'
            ]} className="pt-2">
                <AccordionItem>
                    <AccordionHeader targetId="2">
                        Résultat du rapport
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                        {
                            props.items.map(item => <ReportResultItem item={item} selected={props.selected} toggle={props.toggle}/>)
                        }                                                                      
                    </AccordionBody>
                </AccordionItem>
            </UncontrolledAccordion>
        </ScrollBar>                        
    );
}

export default ReportList;

import classNames from "classnames";
import { FiHelpCircle } from "react-icons/fi";
import { GoSettings } from "react-icons/go";
import { useDispatch } from "react-redux";
import { formatDate, getDuration } from "../../../helpers/dateHelper";
import { SetActiveStart } from "../../../pages/Map/Object/Event/_redux/eventActions";
import { useAppSelector } from "../../../redux/hooks";
import { Address } from "../../Address";
import IdlingnIcon from "../Object-info/Icon/idlingIcon";
import IgnitionIcon from "../Object-info/Icon/ignitionIcon";
import MovementIcon from "../Object-info/Icon/movementIcon";


export const EventName = ({event, onlyText}: {event: any, onlyText?: boolean}) => {
    let eventName = '';
    if(event.type !== 'custom') {
        switch(true) {
            case event.type === 'event-time' && event.io === 'ignition':
                eventName = event.value ? 'Entrer au mode contact on' : 'Sortie du mode contact off';
                break;
            case event.type === 'event-time' && event.io === 'idling':
                eventName = event.value ? 'Entrer au mode ralenti' : 'Sortie du mode ralenti';
                break;
                case event.type === 'event-time' && event.io === 'movement':
                    eventName = event.value ? 'Entrer au mode mouvement on' : 'Sortie du mode mouvement off';
                    break;
        } 
        if(event.duration && !onlyText) {
            eventName+= `. Durée(${getDuration(event.duration)})` 
        }   
    } else {
        return event.alarm && event.alarm.length > 0 
            ? (onlyText ? <>{event.alarm[0].name}</> : <h6 className="mb-0">{event.alarm[0].name}</h6>)
            : (onlyText ? <>Alarme supprimée</> : <h6 className="mb-0 text-danger">Alarme supprimée</h6>)
    }
    return onlyText ? <>{eventName}</> : <h6 className="mb-0">{eventName}</h6>
}

const EventIcon = ({event}: {event: any}) => {
    if(event.type === 'custom') {
        return <GoSettings size={18}/>
    } else {
        switch(true) {
            case event.type === 'event-time' && event.io === 'ignition':
                return <IgnitionIcon enable={event.value} size={15} />
            case event.type === 'event-time' && event.io === 'idling':
                return <IdlingnIcon enable={event.value} size={20}/>
            case event.type === 'event-time' && event.io === 'movement':
                return <MovementIcon speed={"1"} moving={event.value} size={18}/>
        }
    }
    return <FiHelpCircle size={18}/>;
}

export const EventInfo = ({event, active}: {event: any, active: boolean}) => {
    const user = useAppSelector((state) => state.authReducer.user);
    const dispatch = useDispatch();
    const eventInfoClassName = classNames({
        [`d-flex flex-row justify-content-between align-items-center py-2 px-3`]: true,
        ['event-info']: true,
        ['active']: active
    });
    
    const handleClick = (event: any): void => {
        dispatch(SetActiveStart(event));
    };

    return(
        <div className={eventInfoClassName} onClick={()=> handleClick(event)}>
            <div className="d-flex flex-column">
                <EventName event={event}/>
                <span className="text-muted address">
                    <Address coordinates={event.coordinates} />
                </span>
                
                <span className="text-muted">{
                    user ? formatDate(event.date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
                }</span>
            </div>
            <EventIcon event={event} />
        </div>
    )  
}


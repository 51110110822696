import { Popconfirm } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from "react";
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { LoaderUi } from '../../../Components/basic/LoaderUi';
import { InputFlatPickrUi } from "../../../Components/Form/InputFlatPickr";
import { TableUi } from "../../../Components/Table";
import Emitter from '../../../helpers/emitter';
import EmitterActions from '../../../helpers/emitter/emitter.actions';
import { useAxios } from "../../../common/hooks/axios/useAxios";
import { useAppSelector } from '../../../redux/hooks';
import { SetNotificationAction } from '../../../redux/rootActions';
import { DriverRegistrationShift } from "./registration-shift";


export const DriverAssignList = () => {
    const [queryParameters] = useSearchParams();
    const {key} = useParams();
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch();
    const [range, setRange] = useState<Date[]>();
    const { execute: deleteAssignment, loading: loadingDelete, status } = useAxios<any[]>(true);
    const { data, execute, loading } = useAxios<any[]>(
        true, 
        `driver/${key}/history`, 
        { method: 'GET'}
    );

    const { user } = useAppSelector(state => {
        return { user: state.authReducer.user }
    });
    const isOpen = queryParameters.get('assignments') === '1' && !!key
    
    const fetchData = () => {
        if(!range && !!key) {
            execute(`driver/${key}/history`, { method: 'GET' })
        }
        if(!!key && range?.length === 2) {
            const from = range[0].getTime();
            const to = range[1].getTime();
            execute(`driver/${key}/history`, { method: 'GET', params: {from, to}})
        }
    }

    useEffect(()=> {
        if(status && loadingDelete === false) {
            if(status <= 204) {
                dispatch(SetNotificationAction({
                    title: "Supprimé avec sucès",
                    "message": 'Affectation supprimé',
                    timeToClose: 3000,
                    type: 'success'
                }));
                Emitter.emit(EmitterActions.REFRESH_ASSIGNMENT_LIST, '');
                Emitter.emit(EmitterActions.REFRESH_DRIVER_ECO, '');
                navigate('?assignments=1');
            } else {
                dispatch(SetNotificationAction({
                    title: "Erreur",
                    "message": 'Un problème interne est survenu, veuillez ressayer plus tard',
                    timeToClose: 3000,
                    type: 'error'
                })); 
            }

        }
    }, [loadingDelete]);

    useEffect(()=> {
        fetchData();
        return () => {
            setRange(undefined);
        }
    }, [key, isOpen]);

    useEffect( () => {
        Emitter.on(EmitterActions.REFRESH_ASSIGNMENT_LIST, ()=> {
            fetchData();
            setRange(undefined)
        });
        return () => {
            Emitter.off(EmitterActions.REFRESH_ASSIGNMENT_LIST, ()=> {
                fetchData();
                setRange(undefined)
            });
        };
    }, []);

    const newBinding = () => {
        if(key) {
            navigate(`?assignments=1&assign=1`);
        }
    }

    const deleteBinding = (assignmentKey: string) => {
        deleteAssignment(`driver/${key}/history/${assignmentKey}`, { method: 'DELETE'})
    }



    const close = () => navigate(location.pathname);
    const search = (range: Date[]) => {
        if(range?.length === 2 && key) {   
            const from = range[0].getTime();
            const to = range[1].getTime();
            setRange(range);
            execute(
                `driver/${key}/history`, 
                {  method: 'GET', params: { from, to }}
            );
        }
    }
    const columns = [
        {
            title: "Unité",
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: "Type",
            dataIndex: 'binding',
            key: 'binding',
            render: (binding: string) => {
                return <FormattedMessage id={`${binding}`}/>
            }
        },
        {
            title: "Début",
            dataIndex: 'start',
            key: 'start',
            render: (date: string) => {
                const transformedDate = moment(new Date(date)).tz(user?.timezone ?? 'Africa/casablanca');
                return transformedDate.format('DD-MM-YYYY HH:mm')
            }
        },
        {
            title: "Fin",
            dataIndex: 'end',
            key: 'end',
            render: (date: string) => {
                if(!date)  {
                    return '-';
                }
                const transformedDate = moment(new Date(date)).tz(user?.timezone ?? 'Africa/casablanca');
                return transformedDate.format('DD-MM-YYYY HH:mm')
            }
        },
        {
            title: "Créer par",
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: "Action",
            dataIndex: 'action',
            key: 'action',
            render: (value: string, data: any) => {
                
                if(!data.end) { return '-'; }
                return (
                    <Popconfirm
                        title="Êtes-vous sûr de le supprimer ?"
                        onConfirm={()=> deleteBinding(data.key)}            
                        okText="Oui"
                        cancelText="Non"
                    >
                        <div
                            role="button"
                            tabIndex={0}
                            className="me-3 p-1 ghost btn btn-danger btn-sm d-flex flex-row justify-content-center align-items-center"
                        >
                           <MdDelete 
                                className={'text-danger'} size={18}/><span className={'text-danger fs-13'}>Supprimer</span> 
                        </div>
                    </Popconfirm>
                )    
            }
        },
    ];
    
    return (
        <Modal isOpen={isOpen} toggle={close} size={"lg"} fullscreen={"lg"}>
            <LoaderUi loading={loading || loadingDelete} />
            <ModalHeader className="border" toggle={close}>
                <h5 className="mb-0">
                    Afféctation manuelle et historique - conducteur
                </h5>
            </ModalHeader>
            <ModalBody>
            <Row>
                <Col>
                    <ButtonUi 
                        color="info" 
                        className="mb-2 float-start animation btn btn-primary"
                        onClick={newBinding}
                    >
                        Nouvelle affectation
                    </ButtonUi>
                </Col>
                <Col>
                    <div className='d-flex flex-row'>
                        <InputFlatPickrUi
                            className="form-control me-2"
                            value={ range }
                            placeholder="Plage horaires"
                            options={{
                                mode: 'range',
                                dateFormat: "Y-m-d H:i",
                            }}
                            onChange={search}
                            
                            // error={manage.subscription === undefined}
                        />
                    </div>
                    
                </Col>
            </Row>
                <TableUi columns={columns} data={data ?? []} minusHeight={120+70}/>
                <DriverRegistrationShift />
            </ModalBody>
        </Modal>
    );
}
import classNames from "classnames";

type MaintenanceInfoType = {
    active: boolean,
    expired: number;
    valid: number; 
    name: string;
    onClickAction: () => void
}
export const MaintenanceInfo =({active, expired, valid, name, onClickAction}: MaintenanceInfoType)=> {
    const MaintenanceInfoClass = classNames({
        ['border-bottom px-3 py-2 d-flex justify-content-between align-items-center']: true,
        ["maintenance-info"]: true,
        ['active']: active,
      });
    return(
        <div className={MaintenanceInfoClass} onClick={onClickAction}>
            <div>
                <h6>{name}</h6>
                <div className="text-muted fs-12">{valid+expired} au Total</div>
            </div>
            <div>
                <span className="fs-14 text-success">{valid}</span>
                <span className="fs-14  text-danger ms-2 ps-2 border-start">{expired}</span>
            </div>
        </div>
    );
}
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { AiOutlineCar, AiOutlineNotification, AiOutlineRadarChart, AiOutlineSend } from 'react-icons/ai';
import { BsBuilding, BsFileEarmarkText, BsMap, BsTools } from 'react-icons/bs';
import { FiUsers, FiShare2 } from 'react-icons/fi';
import { SiMongodb } from 'react-icons/si';
import { GiCaptainHatProfile, GiGps } from 'react-icons/gi';
import { GoGraph } from 'react-icons/go';
import { IoIosNotificationsOutline, IoMdPaper } from 'react-icons/io';
import { RiSecurePaymentLine } from 'react-icons/ri';
import { GiBusStop } from 'react-icons/gi';
import { FaRoute } from 'react-icons/fa';


import { FormattedMessage } from 'react-intl';
import { UserType } from '../../pages/Authentification/_redux/types';
import { useAppSelector } from '../../redux/hooks';
import { MdAirlineSeatReclineNormal, MdOutlineAccessAlarm } from 'react-icons/md';

const navData: any[] = [
    {
        label: "COMMON.MENU",
        isHeader: true,
    },
     {
        ico: GoGraph,
        label: 'ASIDE.DASHBOARD',
        link: '/dashboard',
        role: ['zone:rd', 'zone:wt', 'zone:add', 'zone:dt']
    },
    {
        ico: BsMap,
        label: 'ASIDE.MAP',
        link: '/map',
        notSecure: true
    },
    {
        ico: AiOutlineRadarChart,
        label: 'ASIDE.ZONE',
        link: '/geofences',
        role: ['zone:rd', 'zone:wt', 'zone:add', 'zone:dt']
    },
    {
        ico: GiCaptainHatProfile,
        label: 'ASIDE.DRIVERS',
        link: '/drivers',
        role: ['driver:rd', 'driver:wt', 'driver:add', 'driver:dt']
    },
    {
        ico: MdAirlineSeatReclineNormal,
        label: 'ASIDE.PASSENGERS',
        link: '/passengers',
        role: ['passenger:rd', 'passenger:wt', 'passenger:add', 'passenger:dt']
    },
    {
        ico: GiBusStop,
        label: 'ASIDE.STOPS',
        link: '/stops',
        role: ['stops:rd', 'stops:wt', 'stops:add', 'stops:dt']
    },
    {
        ico: FaRoute,
        label: 'ASIDE.ROUTES',
        link: '/routes',
        role: ['road:rd', 'road:wt', 'road:add', 'road:dt']
    },
    {
        ico: IoMdPaper,
        label: 'ASIDE.DRIVER_LOGS',
        link: '/incident',
        role: ['driver:rd']
    },
    {
        ico: IoIosNotificationsOutline,
        label: 'ASIDE.ALARMS',
        link: '/alarms',
        role: ['alarm:rd', 'alarm:wt', 'alarm:add', 'alarm:dt']
    },
    {
        ico: BsFileEarmarkText,
        label: 'ASIDE.REPORTS',
        link: '/instant-reports',
        role: ['instant-report:rd', 'instant-report:wt', 'instant-report:add', 'instant-report:dt']
    },
    {
        ico: BsFileEarmarkText,
        label: 'ASIDE.REPORTS',
        link: '/reports',
        role: ['report:rd', 'report:wt', 'report:add', 'report:dt']
    },
    // {
    //     ico: MdOutlineAccessAlarm,
    //     label: 'ASIDE.CRON',
    //     link: '/jobs',
    //     role: ['cron:rd', 'cron:wt', 'cron:add', 'cron:dt']
    // },
    {
        ico: BsTools,
        label: 'ASIDE.MAITENANCE',
        link: '/maintenance',
        role: ['maintenance:rd', 'maintenance:wt', 'maintenance:add', 'maintenance:dt']
    },
    {
        ico: FiShare2,
        label: 'ASIDE.SHARE',
        link: '/share',
        role: ['share:rd', 'share:add', 'share:dt']
    },
    // {
    //     ico: SiMongodb,
    //     label: 'ASIDE.ECO',
    //     link: '/eco',
    //     role: ['eco:rd', 'eco:add', 'eco:dt']
    // },
    {
        ico: FiUsers,
        label: 'ASIDE.USERS',
        link: '/users',
        role: ['user:rd', 'user:wt', 'user:add', 'user:dt']
    },
    {
        ico: AiOutlineCar,
        label: 'ASIDE.UNITS',
        link: '/units',
        role: ['vehicle:rd']
    },
    // {
    //     label: "COMMON.ADMIN",
    //     isHeader: true,
    // },
    {
        ico: BsBuilding,
        label: 'ASIDE.COMPANIES',
        link: '/companies',
    },
    {
        ico: GiGps,
        label: 'ASIDE.TRACERS',
        link: '/tracers',
    },
    {
        ico: RiSecurePaymentLine,
        label: 'ASIDE.BILLING',
        link: '/subscription',
    },
    // {
    //     ico: AiOutlineSend,
    //     label: 'ASIDE.COMMANDS',
    //     link: '/commands'
    // },
    // {
    //     ico: AiOutlineNotification,
    //     label: 'ASIDE.NOTIFICATIONS',
    //     link: '/notifications',
    //     role: ['zone:rd', 'zone:wt', 'zone:add', 'zone:dt']
    // },
    
];

const canShowMenuItem = ({item, user}: {item: any, user?: UserType | null}) =>{
    // Page menu not secure
    if(item.notSecure) { return true; }
    
    // User is connected with his children
    if(user?.connectedAs) {
        if(user?.connectedAs.roles.filter(r => item.role?.includes(r)).length > 0) {
            return true;
        }
        return false
    }
    
    // User is admin
    if(user?.type === 'admin') {
        return true;
    }

    // Check user Roles
    if(user?.roles && user?.roles.filter(r => item.role?.includes(r)).length > 0) {
        return true
    }

    return false
}
const NavLinkItem = ({item, user}: {item: any, user?: UserType | null}) => {
    const canShow = useMemo(()=> {
        return canShowMenuItem({item, user})
    }, [user])
    if(user) {
        const Icon = item.ico;
        if(canShow)
            return (
                <li className="nav-item">
                    <NavLink
                        className="nav-link menu-link"
                        to={item.link ? item.link : "/#"}>
                            <Icon/>
                        <span><FormattedMessage id={item.label} /> </span>
                    </NavLink>
                </li>
            );
    }



    return null
}

export const SidebarContent = (props: any) => {
    const { user } = useAppSelector((state) => state.authReducer);
    return (
        <React.Fragment>
            {/* menu Items */}
            {(navData || []).map((item, key) => {
                
                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {item['isHeader'] ?
                            <li className="menu-title"><span data-key="t-menu"><FormattedMessage id={item.label} /></span></li>
                            : 
                            <NavLinkItem  item={item} user={user}/>  
                        }
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};



import { CardBody, CardHeader, Container } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { CardUi } from "../../Components/basic/Card";
import CalendarUi from "../../Components/basic/CalendarUi";
import { TimelineUi } from "../../Components/basic/TimelineUi";
import InfoProfile from "./components/infoProfile";
import { BreadCrumbActionsUi } from "../../Components/basic/BreadCrumbActionsUi";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { useNavigate, useParams } from "react-router-dom";

const HistoryPassengerPage = () => {
    const { key } = useParams();
    const navigate = useNavigate();

    const intl = useIntl();
    
    return (
        <div id="incidentPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionsUi 
                    title={intl.formatMessage({ id: 'Historique du passager' })} 
                    pageTitle={''} 
                >
                    <ButtonUi color="info" onClick={() => navigate(`/passengers/${key}/details`)}>Retour</ButtonUi>
                </BreadCrumbActionsUi>
                <div className="d-flex flex-row mx-2">
                    <CardUi className="flex-grow-2" style={{ height: '100%' }}>
                        <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                            <h5 className="d-flex align-items-center mb-0 text-primary">
                                <span>
                                    <FormattedMessage id={'Calendrier'} />
                                </span>
                            </h5>
                        </CardHeader>
                        <CardBody>
                            <CalendarUi />
                        </CardBody>
                    </CardUi>
                
                    <CardUi className="mx-2" style={{ flex: 1, height: '100%' }}>
                        <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                        <h5 className="d-flex align-items-center mb-0 text-primary">
                            <span>
                                <FormattedMessage id={'Traçage'} />
                            </span>
                        </h5>
                        </CardHeader>
                        <CardBody>
                            <TimelineUi />
                        </CardBody>
                    </CardUi>
                
                    <CardUi className="flex-grow-1" style={{ flex: 2, height: '100%' }}>
                        <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                        <h5 className="d-flex align-items-center mb-0 text-primary">
                            <span>
                             <FormattedMessage id={'Profile'} />
                            </span>
                        </h5>
                        </CardHeader>
                        <CardBody>
                            <InfoProfile />
                        </CardBody>
                    </CardUi>
                </div>
            </Container>
        </div>

      )
}

export default HistoryPassengerPage;
import { axiosInstance } from '../../../../../redux/setupAxios';
import { FuelChartType, FuelFillType, RangeType } from './types';

export async function fetchFuel(
    vehicleId: string,
    payload: RangeType,
    token: string
): Promise<FuelFillType[]> {
    const { from, to } = payload;
    const params = `?from=${from}&to=${to}`;

    const url = `event/${vehicleId}/fill${params}`;
    try {
        const res = await axiosInstance.get<FuelFillType[]>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function fetchChartFuel(
    unit: string,
    payload: {range: RangeType, active: string},
): Promise<FuelChartType | undefined> {
    const { range,  active} = payload;
    const { from, to } = range;
    const params = `?from=${from}&to=${to}`;

    const url = `vehicle/${unit}/fuel/${active}${params}`;
    try {
        const res = await axiosInstance.get<FuelChartType>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Col, Form, Row } from "reactstrap";
import { InputText } from "../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../redux/hooks";
import { SetSelectValueAlarmAction } from "../../redux/events.actions";

type BooleanTypeProps = {
    position: number,
    selected?: string
}
export const ValueType = ({position, selected}: BooleanTypeProps) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();

    const { manage } = useAppSelector((state) => state.eventsReducer);
    const { register, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            value: manage.events[position].value
        }
    });



    const handleChange = (data: any) => {
        const value = data.target.value &&  data.target.value.length > 0 ? data.target.value : undefined;
        if(value) {
            dispatch(SetSelectValueAlarmAction({position, data: value}))
        } else {
            dispatch(SetSelectValueAlarmAction({position}));
        } 
    }

    return (
        <Form>
            <Row>
                <Col xl={12}>
                    <InputText 
                        label={intl.formatMessage({ id: 'ALARM.VALUE'})}
                        type="text"
                        id="value"
                        name="value"
                        placeholder="valeur"
                        register={register("value")}
                        error={manage.events[position].value ? undefined : {message: 'required'}}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
            
        </Form>
                
    );
}


import classNames from "classnames";
import { RiLayoutRowFill, RiLayoutRowLine } from "react-icons/ri";
import { ButtonUi } from "../../basic/ButtonUi";
import { FiExternalLink } from "react-icons/fi";
import { MdDelete } from "react-icons/md";

export enum ResultPosition {
    TOP = "TOP",
    BOTTOM = "BOTTOM"
}
type ReportResultItemProps = {
    className?: string;
    item: {label: string, value: string};
    selected: string[];
    position?: ResultPosition;
    toggle: (itemId: string, action: 'add' | 'remove') => void;
}

const ReportResultItem = (props: ReportResultItemProps) => {
    const reportResultItemClassname = classNames(
        'report-result-item',
        'd-flex flex-row justify-content-between align-items-center p-3 border-bottom',
        { [`${props.className}`]: !!props.className }
    );

    const getPosition = ()=> {
        const index = props.selected.indexOf(props.item.value);
        switch(index) {
            case 0: {
                return ResultPosition.BOTTOM
            }
            case 1: {
                return ResultPosition.TOP
            }
            default: {
                return undefined
            }
        }
    };

    return (
        <div className={reportResultItemClassname}>
            <div className="d-flex flex-row align-items-center">
                <span className="fs-14">{ props.item.label }</span>
                <ReportResultItemAction 
                    position={getPosition()} 
                    itemId={props.item.value} 
                    toggle={props.toggle}
                    canAdd={props.selected.length<2}
                />
            </div>
            
           <PositionReportResultItem position={getPosition()} />
        </div>
    );
}

export default ReportResultItem;

export const PositionReportResultItem = (props: {position?: ResultPosition}) => {
    switch(props.position) {
        case ResultPosition.TOP: {
            return <RiLayoutRowFill size={17} className="text-primary"/>
        }
        case ResultPosition.BOTTOM: {
            return <RiLayoutRowFill size={17} className="text-primary" style={{transform: "rotate(180deg)"}}/>
        }
        default: {
            return <></>
            // return <RiLayoutRowLine size={17} />
        }
    }
}

export const ReportResultItemAction = (props: {itemId: string, canAdd: boolean; position?: ResultPosition, toggle: (itemId: string, action: 'add' | 'remove') => void;}) => {
    
    const showReport = ()=> {
        props.toggle(props.itemId, 'add');
    };

    const removeReport = ()=> {
        props.toggle(props.itemId, 'remove');
    };

    if(props.position) {
        return(
            <ButtonUi className="action ms-2" color="danger" ghost  size='sm' onClick={removeReport}>
                <div className="d-flex flex-row align-items-center">
                    <span className="ms-1 me-1">Retirer</span> <MdDelete size={15}/>
                </div>
            </ButtonUi>
        )
    }

    if(props.canAdd) {
        return(
            <ButtonUi className="action ms-2" color="secondary" ghost  size='sm' onClick={showReport}>
                <div className="d-flex flex-row align-items-center">
                    <span className="ms-1 me-1">Afficher</span> <FiExternalLink/>
                </div>
            </ButtonUi>
        )
    }

    return <></>
    
}
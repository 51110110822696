import { useEffect } from "react";
import { useAxios } from "../useAxios";

export type ReportTemplate = {
  "value": string, "label": string, "target":"unit"
}
type UnitToAssignResult = {
    key: string;
    group: string;
    data: { label: string, value: string }[];
}

type useFetchUnitsToAssignResponse = { 
  unitsToAssign: UnitToAssignResult[];
  unitsToAssignLoading: boolean;
  error?: string;
  fetchUnitsToAssign: (inputValue: string) => void;
  cancel: () => void;
}

type useFetchUnitsToAssignProps = {
  manual?: boolean
}
export function useFetchUnitsToAssign(props: useFetchUnitsToAssignProps): useFetchUnitsToAssignResponse {
  const URL = "vehicle/to-assign";
  const INIT_VALUE: UnitToAssignResult[] = [];
  const { execute, data, cancel, error, loading } = useAxios<UnitToAssignResult[]>();

  useEffect(() => {
    fetchUnitsToAssign();
  }, [])

  const fetchUnitsToAssign = async (inputValue?: string) => {
    execute(URL, { method: "GET", params: { name: inputValue } });
  };

  return { 
    unitsToAssign: data ?? INIT_VALUE, 
    fetchUnitsToAssign, 
    cancel, 
    unitsToAssignLoading: loading, 
    error: error
  };
}

import { useAxios } from "../useAxios";

export type ReportTemplate = {
  "value": string, "label": string, "target":"unit"
}
type ReportTemplatesResult = {
    list: ReportTemplate[];
    total: number;
}

type UseFetchReportTemplateResponse = { 
  reportTemplate: ReportTemplatesResult;
  reportTemplateLoading: boolean;
  error?: string;
  fetchReportTemplate: () => void;
  fetchReportTemplateForSelect: (inputValue: string) => Promise<ReportTemplate[]>;
  cancel: () => void;
}

type UseFetchReportTemplatesProps = {
  manual?: boolean
}
export function useFetchReportTemplates(props: UseFetchReportTemplatesProps): UseFetchReportTemplateResponse {
  const URL = "report-template/name";
  const INIT_VALUE = { list: [], total: 0 };
  const { execute, data, cancel, error, loading } = useAxios<ReportTemplatesResult>(props.manual);

  const fetchReportTemplate = () => {
    execute(URL, { method: "GET" });
  };

  const fetchReportTemplateForSelect = async (inputValue: string) => {
    const result = await execute(URL, { method: "GET", params: { searchBy: inputValue } });
    return result.success?.list;
  };

  return { 
    reportTemplate: data ?? INIT_VALUE, 
    fetchReportTemplate, 
    fetchReportTemplateForSelect,
    cancel, 
    reportTemplateLoading: loading, 
    error: error
  };
}

import {  memo, useContext } from 'react';
import { LayoutContext } from '../../../context/layout-style-context';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List } from 'react-virtualized';


const LOADING = 1;
const LOADED = 2;

type  InfiniteScrollProps = {
    data: any[];
    onEvent?: (key: string, action: string, data: any) => void;
    totalItems: number,
    loadMoreRows: (props: {startIndex: number, stopIndex: number}) => Promise<boolean>
    Component: any,
    ComponentSkeleton: any,
    minHeight?: number,
    autoHeight?: boolean,
    itemStatusRef: React.MutableRefObject<any>,
}
export const InfiniteScroll = memo((props: InfiniteScrollProps) => {
    const { height } = useContext(LayoutContext);
    const cache = new CellMeasurerCache({ fixedWidth: true });

    const _rowRenderer = ({index, key, style, parent}: any) => {
        if(props.itemStatusRef.current[index] !== LOADED) {
            return (
                <CellMeasurer
                    cache={cache}
                    columnIndex={1}
                    key={key}
                    parent={parent}
                    rowIndex={index}
                >
                    {({ measure }) => (
                        <div key={`${key}`} style={style}>
                            <props.ComponentSkeleton />
                        </div>
                    )}
              </CellMeasurer>
            )
        }
        return (
            <CellMeasurer
                cache={cache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure }) => {
                    return (
                        <div key={`${key}${props.itemStatusRef.current[index]}`} style={style}>
                            <props.Component 
                                {...props.data[index]} 
                                onEvent={props.onEvent} 
                                dataKey={props.data[index].key}
                            /> 
                        </div>
                    )
                }}
          </CellMeasurer>
        )

    }
    
    const isRowLoaded = (data: any) => { return !!(props.itemStatusRef.current?.[data.index]) }
    
    const loadMoreRows = async (params: {startIndex: number, stopIndex: number}) => {
        for (let index = params.startIndex; index <= params.stopIndex; index++) {
            props.itemStatusRef.current[index] = LOADING;
        }
        const result = await props.loadMoreRows(params);
        if(result) {
            for (let index = params.startIndex; index <= params.stopIndex; index++) {
                props.itemStatusRef.current[index] = LOADED;
            }
        } else {
            props.itemStatusRef.current = {};
        }
    }

    return (
        <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={props.totalItems}>
            {({onRowsRendered, registerChild}) => {
                return (
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <List
                                ref={registerChild}
                                height={height - (props.minHeight ?? 0)}
                                autoHeight={props.autoHeight }
                                onRowsRendered={onRowsRendered}
                                rowCount={props.totalItems}
                                rowHeight={cache.rowHeight}
                                rowRenderer={_rowRenderer}
                                width={width}
                            />
                            )
                        }
                    </AutoSizer>
                )
            }}
        </InfiniteLoader>
    );
});


import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { diffDateComplete } from '../../../helpers/dateHelper';

export type LastTripTextProps = {
    on: boolean;
    id: string;
    date: string; 
};

export default function TripText({on, date}: LastTripTextProps) {
    const customInterval = 2000;
    const [time, setTime] = useState('-');

    const updateTimer = () => {
        const diffTripTime = diffDateComplete(new Date(date), 'fr');
        setTime(diffTripTime);
    };

    useEffect(() => {
        updateTimer();
        const _interval = setInterval(() => {
            updateTimer();
        }, customInterval);
        return () => {
            clearInterval(_interval);
        };
    }, [date]);

    if (on) {
        return <><FormattedMessage id="COMMON.ON_TRIP" />: {time}</>;
    }
    return <><FormattedMessage id="COMMON.OFF_TRIP" />: {time}</>;
}
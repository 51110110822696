import { Col, Container, Row } from "reactstrap";
import { General } from "./General";


export const ManageDriver = () => {

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <General className={''}/>
                </Col>
            </Row> 
        </Container>
               
    );
}
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";

import { ColorType } from "../../Components/types";
import { useAppSelector } from "../../redux/hooks";
import { GetEventsStart } from "../Alarms/redux/events.actions";
import { EventStatus } from "./components/event-status";
import { FuelStatus } from "./components/fuel-status";
import { KmStatus } from "./components/km-status";
import { MaintenanceStatus } from "./components/maintenance-status";
import { ParcStatus } from "./components/parc-status";



export const COLOR_TYPE_USER: {[key: string]: ColorType} = {
    'user': 'info',
    'sub_admin_company': 'warning',
    'admin_company': 'danger',
    'admin': 'success'
}

const DashboardPage = () => {

    const dispatch = useDispatch();


    const { filter } =
        useAppSelector((state) => state.eventsReducer);

    const { user } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        dispatch(GetEventsStart(filter));
    }, []);



    return (
        <div id="alarmPage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={"Tableau de bord"} 
                    pageTitle={user?.company.name || ''} 
                />
                <Row>
                    <ParcStatus />
                    <Col md={4} className="d-flex align-items-stretch"> 
                        <MaintenanceStatus /> 
                    </Col>
                    <Col md={8}> 
                        <FuelStatus /> 
                    </Col>
                    <Col md={4} className="d-flex align-items-stretch">
                        <EventStatus/>
                    </Col>
                    <Col md={8}> 
                        <KmStatus /> 
                    </Col>
                    
                    {/* <Col md={4} className="d-flex align-items-stretch">
                        <ZoneStatus/>
                    </Col> */}
                    
                </Row>
            </Container>
        </div>
    );
}

export default DashboardPage;
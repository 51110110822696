import { VehicleInfoType } from "../../../Objects/_redux/types";
import { VisibleSensor } from "./type";

export const INIT_VEHICLE = '[VEHICLE] INIT_VEHICLE';
export const CLEAR = '[VEHICLE] CLEAR';

export const ERROR = '[VEHICLE] ERROR';

export type InitVehicleType = {
    type: typeof INIT_VEHICLE;
    payload: { vehicle: VehicleInfoType };
};
export function InitVehicle(payload: {
    vehicle: VehicleInfoType;
    sharedMap?: boolean
}): InitVehicleType {
    return { type: INIT_VEHICLE, payload };
}

export type ClearType = { type: typeof CLEAR };
export function Clear(): ClearType {
    return { type: CLEAR };
}

export const SET_VEHICLE_DATA = '[VEHICLES] SET_VEHICLE_DATA';
export type SetVehicleDataType = {
    type: typeof SET_VEHICLE_DATA;
    payload: any;
};
export function SetVehicleData(payload: any): SetVehicleDataType {
    return { type: SET_VEHICLE_DATA, payload };
}

export const SET_VEHICLE_CNX = '[VEHICLES] SET_VEHICLE_CNX';
export type SetVehicleCnxType = {
    type: typeof SET_VEHICLE_CNX;
    payload: any;
};
export function SetVehicleCnx(payload: any): SetVehicleCnxType {
    return { type: SET_VEHICLE_CNX, payload };
}

export const RETRIEVE_UNIT_SENSORS_END = '[VEHICLES] RETRIEVE_UNIT_SENSORS_END';
export type RetrieveUnitSensorsEndType = { 
    type: typeof RETRIEVE_UNIT_SENSORS_END, 
    payload: VisibleSensor[];
};
export function RetrieveUnitSensorsEnd(payload: VisibleSensor[]): RetrieveUnitSensorsEndType {
    return { type: RETRIEVE_UNIT_SENSORS_END, payload };
}

export function Error(msg: string): any {
    return { type: ERROR, msg };
}

export type VehicleActionsType = InitVehicleType  | SetVehicleDataType | SetVehicleCnxType | ClearType | RetrieveUnitSensorsEndType;

import { UseFormRegister } from "react-hook-form";
import { useIntl } from "react-intl";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";

import { CardUi } from "../../../Components/basic/Card";
import { InputText } from "../../../Components/Form/InputText";
import { numberValidator } from "../../../helpers/validationHelper";

import { useAppSelector } from "../../../redux/hooks";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any}
}
export const Limit = ({register, errors}: ManageCompanyPageType) => {
    const intl = useIntl();
    const validatorNumber = numberValidator(true, 0, 500);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'>Limites</h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                label={intl.formatMessage({ id: 'COMPANY.USER' })}
                                id={'user'}
                                name={'user'}
                                placeholder={intl.formatMessage({ id: 'COMPANY.LIMIT_USER' })}
                                register={register('user', validatorNumber)}
                                error={errors['user']}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                label={intl.formatMessage({ id: 'COMPANY.VEHICLE' })}
                                id={'vehicle'}
                                name={'vehicle'}
                                placeholder={intl.formatMessage({ id: 'COMPANY.LIMIT_VEHICLE' })}
                                register={register('vehicle', validatorNumber)}
                                error={errors['vehicle']}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                label={intl.formatMessage({ id: 'COMPANY.EVENT' })}
                                id={'event'}
                                name={'event'}
                                placeholder={intl.formatMessage({ id: 'COMPANY.LIMIT_EVENT' })}
                                register={register('event', validatorNumber)}
                                error={errors['event']}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                label={intl.formatMessage({ id: 'COMPANY.DRIVER' })}
                                id={'driver'}
                                name={'driver'}
                                placeholder={intl.formatMessage({ id: 'COMPANY.LIMIT_DRIVER' })}
                                register={register('driver', validatorNumber)}
                                error={errors['driver']}
                            />
                        </FormGroup>
                    </Col>
                </Row>


            </CardBody>
        </CardUi>               
    );
}
import { useState, useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, CardHeader, CardBody, CardFooter, Row, Col } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { ColorType } from "../../Components/types";
import { SHARE_URL } from "../../env";
import { useAppSelector } from "../../redux/hooks";
import { ActionTabBar } from "../Company/components/ActionTabBar";
import { AssignVehiclesSlider } from "../components/VehicleAssignSlider";
import { SetSelectedVehicles } from "../Vehicles/redux/vehicleModule.actions";
import { DeleteSharesStart, GetSharesStart } from "./redux/share.actions";
import Emitter from "../../helpers/emitter";


export const COLOR_TYPE_USER: {[key: string]: ColorType} = {
    'user': 'info',
    'sub_admin_company': 'warning',
    'admin_company': 'danger',
    'admin': 'success'
}

const SharePositionPage = () => {
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },

    ];
    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { popup, shares, filter, selectedShares, loading } = useAppSelector(
        (state) => state.shareReducer
    );
    const { user } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        if(shares.list.length === 0) dispatch(GetSharesStart(filter));
    }, []);

    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
            case 'view':
                navigate({
                    pathname: `/share/${_ids[0]}`,
                });
                break;
            case 'delete':
                Emitter.emit(Emitter.event.DELETE_POPUP, {
                    textConfirmation: "confirm",
                    title: _ids.length > 1 ? "Supprimer les partages des positions" : "Supprimer le partage de position",
                    description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                    onDelete: () => dispatch(DeleteSharesStart(_ids))
                });
                break;
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEye,
            name: intl.formatMessage({ id: 'COMMON.VIEW' }),
            action: 'view'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        }
    ];


    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.STATUS' }),
            dataIndex: 'expiredDate',
            key: 'expiredDate',
            render: (expiredDate: number) => {
               const diff = new Date(expiredDate).getTime() - new Date().getTime();
                if(diff >= 0) {
                    return(<BadgeUi color="success" light><FormattedMessage id="COMMON.ACTIVED" /></BadgeUi>);
                }
                return(<BadgeUi color="danger" light><FormattedMessage id="COMMON.EXPIRED" /></BadgeUi>)
            }
        },
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'SHARE.LINK' }),
            dataIndex: 'tracerName',
            key: 'tracerName',
            render: (url: string, share: any) =>
                `${SHARE_URL}/shared-objects/${share.key}`,
        },
        {
            title: intl.formatMessage({ id: 'SHARE.VEHICLES' }),
            dataIndex: 'vehicles',
            key: 'vehicles',
            render: (vehicles: string[] | undefined) => {
                if(vehicles) {
                    let vehiclesString = '';
                    // eslint-disable-next-line no-restricted-syntax
                    vehicles.forEach((vehicle, index) => {
                        vehiclesString = `${vehiclesString}${
                            index !== 0 ? ',' : ''
                        } ${vehicle}`;
                    });
                    return (
                       vehiclesString.length > 22
                            ? `${vehiclesString.substring(0, 22)}...`
                            : vehiclesString
                       
                    );
                }
                return '-';

            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ADDED_BY' }),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (name: string) => {
                return (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" rounded="rounded-circle" text={name}></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>)
                
            }
            
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedVehicles(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetSharesStart({ ...filter, ...{ select: FILTERS[index].value } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetSharesStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetSharesStart({ ...filter, ...{ skip, limit } }));
    };

    return (
        <div id="sharePage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'SHARE' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'SHARE.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            selectedShares.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${shares.total})`}</div>)}
                            </Tabs>
                            :
                            <ActionTabBar selected={selectedShares} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={shares.list}
                            selected={selectedShares}
                            onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+60+ (Math.ceil(shares.total / filter.limit) > 1 ? 60 : 0)}

                        />
                    </CardBody>
                    {
                        Math.ceil(shares.total / filter.limit) > 1 ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={shares.total}
                                        skip={filter.skip}
                                        limit={filter.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                   
                </CardUi>

            </Container>
            <AssignVehiclesSlider/> 
        </div>
    );
}

export default SharePositionPage;
import { useCallback } from 'react';
import { Progress } from 'reactstrap';
type ViewPointProgressBarComponentProps = {
    [key: string]: any,
    percentage: number,
};

export const ViewPointProgressBarComponent = ({score, percentage, ...other}: ViewPointProgressBarComponentProps) => {

    const getProgressColor = useCallback(()=> {
        switch(true) {
            case score >=4:
                return 'success';
            case score >=2.5 && score <=3.9:
                return 'warning';
            case score <=2.4:
                return 'danger';
            default:
                return 'primary'
        }
    }, [score]);
    
    
    return(
        <div {...other} className="d-flex flex-row align-items-center">
            <span style={{ fontWeight: 500 }} className="me-2 fs-14">{score}</span>
            <Progress
                color={getProgressColor()}
                style={{
                    flex: 1,
                    height: '10px'
                }}
                value={percentage}
            />
            <span className="ms-1 text-muted fs-12">{percentage.toFixed(1)}%</span>
        </div>
    );
}

import { ImFileEmpty } from 'react-icons/im';

export function EmptyUi({
    msg,
}: {
    msg?: string;
}): JSX.Element {
    return (
        <div 
            className="position-absolute loading-item d-flex flex-column align-items-center justify-content-center"
            style={{    
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <ImFileEmpty size={25} className="text-primary"/>
            <span className="text-primary fs-14 mt-2">{ msg ?? 'Aucune donnée'}</span>
        </div>
    );
}

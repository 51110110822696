import { useAxios } from "../../useAxios";

type UseDeleteGroupPassengerProps = {
  manual?: boolean
  type: string,
  
}

type UseDeleteGroupPassengerResponse = { 
  isGroupPassengerDeleted: boolean | undefined;
  deleteGroupPassengerLoading: boolean;
  error?: string;
  deleteGroupPassenger: (group: string) => void;
  deleteGroupAsyncPassenger: (group: string) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}


export function useDeleteGroupPassenger(props: UseDeleteGroupPassengerProps): UseDeleteGroupPassengerResponse {
  const URL = `groups/${props.type}`;
  const { execute, data, cancel, error, loading } = useAxios<any>(props.manual);

  const deleteGroupPassenger = (group: string) => {
    deleteGroupAsyncPassenger(group);
  };

  const deleteGroupAsyncPassenger = async (group: string) => {
    return execute(`${URL}/${group}`, { method: "DELETE" });
  };

  return { 
    isGroupPassengerDeleted: true,
    deleteGroupPassengerLoading: loading, 
    error,
    deleteGroupPassenger,
    deleteGroupAsyncPassenger, 
    cancel, 
  };
}

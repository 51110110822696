export const linearInterpolation = (entree: number, calibration: any) => {
    const size = calibration.length;
    if(size > 1 && entree >= calibration[0].x && entree <= calibration[size-1].x) {
        let min = null;
        let max = null;
        for(let item of calibration) {
            if(item.x <= entree ) {
                min = item;
            } else if(!max && item.x >= entree) {
                max = item;
                break;
            }
        }
        
        if(!max) { max = min; }

        if(max.x !== min.x) {
            return ( ( entree - min.x )*( max.y - min.y) / ( max.x  - min.x) ) + min.y;
        }  else if(min.x > 0) {
            return calibration[size-1].y
        }  
    }

    
    return 0;

}

import { axiosInstance } from "../../../redux/setupAxios";
import { ManageTracerType, FilterTracerType } from "../types";

export async function getTracers(filter: FilterTracerType): Promise<any> {
    let url = `/tracers?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function saveTracer(tracer: ManageTracerType): Promise<any> {
    const url = `/tracers`;
    try {
        const res = await axiosInstance.post<ManageTracerType>(url, tracer);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateTracer(
    tracer: ManageTracerType
): Promise<ManageTracerType> {
    const url = `/tracers`;
    try {
        const res = await axiosInstance.patch<any>(url, tracer);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getTracer({ _id }: { _id: string }): Promise<any> {
    const url = `/tracers/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteTracers({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/tracers${params}`);
}

export async function getTracersForVehicle({
    _id,
    name,
}: {
    name: string;
    _id?: string
}): Promise<any> {
    let url = `/tracers/vehicle?`;
    if(_id) {
        url+=`_id=${_id}&`;
    }
    url+=`name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function fetchTracerByUid(
    uid: string | undefined
): Promise<any> {
    try {
        const res = await axiosInstance.get<any>(
            `tracers/uid/${uid}`
        );
        if (res.data.found === false) {
            return { for: 'uid', error: false };
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return {
        for: 'uid',
        error: true,
        msg: "Identifiant unique déjà utilisé !",
    };
}
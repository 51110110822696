import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import { ManageMaintenancePageType } from ".";
import { InputText } from "../../../../Components/Form/InputText";
import { numberValidator } from "../../../../helpers/validationHelper";
import { useAppSelector } from "../../../../redux/hooks";
import { ToogleMaitenanceConfigAction } from "../../_redux/maintenance.actions";

export const MaintenanceKm = ({
  register,
  watch,
  errors,
}: ManageMaintenancePageType) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { manage } = useAppSelector((state) => state.MaintenanceReducer);
  const intervalValidation = numberValidator(manage.km.active, 0, 999999);
  const lastServiceMielageValidation = numberValidator(manage.km.active, 0, 999999);

  return (
    <Row>
      <Col md={12}>
        <FormGroup>
          <div className="form-check form-switch form-switch-md">
            <Input
              disabled={!manage.unit}
              className="form-check-input"
              type="checkbox"
              id="mileageOption"
              checked={manage.km.active}
              onClick={() => dispatch(ToogleMaitenanceConfigAction("km"))}
            />
            <Label className="form-check-label" for="mileageOption">
              <FormattedMessage id="MAINTENANCE.MILEAGE" />
            </Label>
          </div>
        </FormGroup>
      </Col>

      {
        manage.km.active && <>
          <Col md={4}>
            <FormGroup>
              {
                <InputText
                  disabled={!manage.km.active || !manage.unit}
                  type="number"
                  label={intl.formatMessage({ id: "MAINTENANCE.INTERVAL_KM" })}
                  id={"intervalKm"}
                  name={"intervalKm"}
                  placeholder={intl.formatMessage({
                    id: "MAINTENANCE.INTERVAL_KM",
                  })}
                  register={register("intervalKm", intervalValidation)}
                  error={errors["intervalKm"]}
                />
              }
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              {
                <InputText
                  disabled={!manage.km.active || !manage.unit}
                  type="number"
                  label={intl.formatMessage({
                    id: "MAINTENANCE.LAST_SERVICE_MIELAGE",
                  })}
                  id={"lastServiceMielage"}
                  name={"lastServiceMielage"}
                  placeholder={intl.formatMessage({
                    id: "MAINTENANCE.LAST_SERVICE_MIELAGE",
                  })}
                  register={register("lastServiceMielage", lastServiceMielageValidation)}
                  error={errors["lastServiceMielage"]}
                />
              }
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              {
                <InputText
                  type="number"
                  label={intl.formatMessage({ id: "MAINTENANCE.ACTUAL_KM" })}
                  id={"actualMielage"}
                  name={"actualMielage"}
                  placeholder={intl.formatMessage({ id: "MAINTENANCE.ACTUAL_KM" })}
                  register={register("actualMielage")}
                  error={errors["actualMielage"]}
                  disabled={true}
                />
              }
            </FormGroup>
          </Col>
        </>
      }
      
    </Row>
  );
};

import { ButtonGroup, CardBody, CardHeader, Form, FormGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { CardUi } from "../../../../Components/basic/Card";
import RangePicker from "../../../../Components/basic/RangePicker";
import { RangePickerProps } from "antd/lib/date-picker";
import { useCallback, useState } from "react";
import { Stepper } from "../components/stepper";
import moment from "moment";
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect";
import { getStops } from "../../../../pages/Passengers/manage-passenger-modal";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";


type StopsPageProps = {
    step: number,
    defaultPeriod?: [Date, Date],
    direction?: "start" | "end",
    defaultStop?: { label: string, value: string },
    onChangeDirection: (direction: "start" | "end") => void,
    onChangeStep: (step: number) => void,
    onChangePeriod: (period: [Date, Date]) => void,
    onChangeStop: (stop: { label: string, value: string }) => void,
    children: JSX.Element
}
export const StopsPage = (props: StopsPageProps) => {
    const [ passengerStop, setPassengerStop ] = useState<{label: string, value: string} | undefined>(props.defaultStop);
    const [ stopType, setStopType ] = useState<"start" | "end">(props.direction ?? "start");
    const [ rangeTime, setRangeTime ] = useState<[Date, Date] | null>(props.defaultPeriod ?? null); 

    const onChangeTime: RangePickerProps['onChange'] = (dates, dateString) => {
        if(dates && dates?.[0] && dates?.[1]) {
            setRangeTime([dates[0]?.toDate(), dates[1]?.toDate()]);
            props.onChangePeriod([dates[0]?.toDate(), dates[1]?.toDate()]);
        }
    }
    const fetchStops = async (inputValue: string) => getStops({ name: inputValue });
    const setStop = (stop: any) => { 
        setPassengerStop(stop); 
        props.onChangeStop(stop); 
    };
    const changeDirection = useCallback((direction: "start" | "end"): any => {
        props.onChangeDirection(direction);
        setStopType(direction);
    }, []);
    
    return (
        <div className="d-flex flex-row">
            <CardUi className="flex-grow-1">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"Étapes"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    <Stepper step={props.step} onChange={props.onChangeStep}/>
                </CardBody>
            </CardUi>
            <CardUi style={{flex: 4}} className="mx-2">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={stopType === "start" ? "Paramétrer le départ des transports" : "Paramétrer l'arrivée des transports"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    <Form>
                        <div className="d-flex justify-content-center mb-3">
                            <ButtonGroup>
                                <ButtonUi color="primary"  size="sm" outline={ stopType === "end" } onClick={()=>changeDirection("start")}>Départ unique</ButtonUi>
                                <ButtonUi color="primary" size="sm" outline={ stopType === "start" }  onClick={()=>changeDirection("end")}>Déstination unique</ButtonUi>
                            </ButtonGroup>
                        </div>

                        <FormGroup className="d-flex flex-column">
                            <RangePicker 
                                id="period" 
                                label="Période maximal du trajet" 
                                format={"DD/MM/YYYY HH:mm"}
                                onChange={onChangeTime}
                                value={rangeTime?.length === 2 ? [moment(rangeTime[0]), moment(rangeTime[1])] : null}
                                // disabled={disabled}
                                showTime
                            />
                        </FormGroup>
                        <FormGroup>
                            {
                                <AsyncInputSelect 
                                    isClearable
                                    label={stopType === "start" ? "Départ" : "Arrivée"}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={passengerStop}
                                    error={!passengerStop}
                                    loadOptions={fetchStops}
                                    value={passengerStop}
                                    onChange={setStop}
                                />
                            }
                        </FormGroup>
                    </Form>
                </CardBody>
            </CardUi> 
            <CardUi className="flex-grow-1">
                <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"Résumé"}/></span>
                    </h5>
                </CardHeader>
                <CardBody>
                    { props.children }
                </CardBody>
            </CardUi>
        </div>

    );
}
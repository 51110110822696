/* eslint-disable  */
import { useCallback, useEffect, useRef } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
import L from "leaflet";
import 'leaflet-draw';
import 'leaflet-draw-drag';
import Emitter from "../../../helpers/emitter";

const INIT_RADIUS = 150;
const COLOR = "#FF0000";

export const StopsDrawer = () => {
    
    const featureRef = useRef();
    const circleRef = useRef();
    const map = useMap();

    useEffect(() => {
        Emitter.on(Emitter.event["STOP:UPDATE_FORM"], initCircle);
        return () => {
            circleRef.current?.editing.disable();
            Emitter.off(Emitter.event["STOP:UPDATE_FORM"], initCircle);
            clearEditZone();
        };
    }, []);


    const initCircle = useCallback((payload) => { 
        
        
        if(!circleRef.current) {
            const location = payload.location ?? map.getCenter();
            const color = payload.color ?? COLOR;
            const radius = payload.radius ?? INIT_RADIUS;
            circleRef.current = new L.Circle(location, radius, {color, draggable: true});
            enableEditMode();
            onUpdateCircle( radius, circleRef.current.toGeoJSON().geometry, circleRef.current.options.color);
    
            circleRef.current.on('edit', (e) => {
                const parseRadius = parseInt(circleRef.current.getRadius(), 10);
                circleRef.current.setRadius(parseRadius);
                onUpdateCircle( parseRadius, circleRef.current.toGeoJSON().geometry, circleRef.current.options.color);
                map.dragging.enable();
            });
        } else {
            circleRef.current.editing.disable();
            if(payload.radius) { circleRef.current.setRadius(payload.radius); }
            if(payload.location) { circleRef.current.setLatLng(payload.location); }
            if(payload.color) { circleRef.current.setStyle({color: payload.color}); }
            circleRef.current.editing.enable();

            map.fitBounds(featureRef.current.getBounds());
            onUpdateCircle(
                circleRef.current.getRadius(),
                circleRef.current.toGeoJSON().geometry,
                circleRef.current.options.color
            );
        }

    }, []);

    const enableEditMode = () => {
        featureRef.current.addLayer(circleRef.current);
        circleRef.current.editing.enable();
        map.dragging.enable();
        map.fitBounds(featureRef.current.getBounds());
        onUpdateCircle( INIT_RADIUS, circleRef.current.toGeoJSON().geometry, COLOR);
    }

    const clearEditZone = () => {
        if(featureRef.current) {
            const layersKey= Object.keys(featureRef.current._layers);
            if(layersKey.length > 0) {
                featureRef.current._layers[layersKey[0]].editing.disable();
                featureRef.current.removeLayer(circleRef.current);
                circleRef.current = null;
            }
        }
    }

    const onUpdateCircle = (radius, location, color) => {
        Emitter.emit(
            Emitter.event["STOP:UPDATE"], 
            { color, radius, location: {lat: location.coordinates[1], lng: location.coordinates[0]}}
        );
    }

    return (
        <FeatureGroup ref={featureRef}/>
    );
}

import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import { CreateTracerEnd, CreateTracerStartType, CREATE_TRACER_START, DeleteTracersEnd, DeleteTracersStartType, DELETE_TRACERS_START, ErrorTracer, GetTracersEnd, GetTracersStartType, GetTracerUpdateEnd, GetTracerUpdateStartType, GET_TRACERS_START, GET_TRACER_UPDATE_START, TracerActionType } from './tracer.actions';

import {
    deleteTracers,
    getTracer, getTracers, saveTracer, updateTracer
} from './tracer.service';


export const CreateTracerEpic = (
    action$: ActionsObservable<CreateTracerStartType>,
    state$: { value: RootState }
): Observable<TracerActionType | any> =>
    action$.pipe(
        ofType(CREATE_TRACER_START),
        switchMap((action: CreateTracerStartType) => {
            const { manage } = state$.value.TracerReducer;
            return from(
                manage._id
                    ? updateTracer({ ...manage, ...action.payload  })
                    : saveTracer({ ...manage,  ...action.payload})
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...state$.value.TracerReducer.manage,
                        ...action.payload,
                        ...result,
                        ...{
                            _id: action.payload._id ? action.payload._id : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                        },
                    };
                    return of(
                        CreateTracerEnd(newManage),
                        SetRedirectAction('/tracers'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                      );
                }),
                catchError((err) => {
                    return of(ErrorTracer(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorTracer(err.msg));
        })
    );


export const GetTracersEpic = (
    action$: ActionsObservable<GetTracersStartType>,
    state$: { value: RootState }
): Observable<TracerActionType> =>
    action$.pipe(
        ofType(GET_TRACERS_START),
        switchMap((action: GetTracersStartType) => {
            return from(getTracers(action.payload)).pipe(
                map((result) => {
                    return GetTracersEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorTracer(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorTracer(err.msg));
        })
    );

    export const DeleteTracersEpic = (
        action$: ActionsObservable<DeleteTracersStartType>,
        state$: { value: RootState }
    ): Observable<TracerActionType> =>
        action$.pipe(
            ofType(DELETE_TRACERS_START),
            switchMap((action: DeleteTracersStartType) => {
                return from(deleteTracers({ _ids: action.payload })).pipe(
                    map((result) => {
                        return DeleteTracersEnd({
                            ...result.data,
                            _ids: action.payload,
                        });
                    }),
                    catchError((err) => {
                        return of(ErrorTracer(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorTracer(err.msg));
            })
        );

    export const GetTracerEpic = (
        action$: ActionsObservable<GetTracerUpdateStartType>,
        state$: { value: RootState }
    ): Observable<TracerActionType> =>
        action$.pipe(
            ofType(GET_TRACER_UPDATE_START),
            switchMap((action: GetTracerUpdateStartType) => {
                return from(getTracer(action.payload)).pipe(
                    map((result) => {
                        return GetTracerUpdateEnd({ result });
                    }),
                    catchError((err) => {
                        return of(ErrorTracer(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorTracer(err.msg));
            })
        );
// export const GetUserEpic = (
//     action$: ActionsObservable<GetUserUpdateStartType>,
//     state$: { value: RootState }
// ): Observable<UserActionType> =>
//     action$.pipe(
//         ofType(GET_USER_UPDATE_START),
//         switchMap((action: GetUserUpdateStartType) => {
//             return from(getUser(action.payload)).pipe(
//                 map((result) => {
//                     return GetUserUpdateEnd({ result });
//                 }),
//                 catchError((err) => {
//                     return of(ErrorUsers(err.msg));
//                 })
//             );
//         }),
//         catchError((err) => {
//             return of(ErrorUsers(err.msg));
//         })
//     );

// export const DeleteUsersEpic = (
//     action$: ActionsObservable<DeleteUsersStartType>,
//     state$: { value: RootState }
// ): Observable<UserActionType | any> =>
//     action$.pipe(
//         ofType(DELETE_USERS_START),
//         switchMap((action: DeleteUsersStartType) => {
//             return from(deleteUsers({ _ids: action.payload })).pipe(
//                 mergeMap((result) => {
//                     return of(
//                         DeleteUsersEnd({
//                             ...result.data,
//                             _ids: action.payload,
//                         }), 
//                         SetNotificationAction({
//                             title: "success",
//                             "message": 'Action',
//                             timeToClose: 3000,
//                             type: 'success'
//                         })
//                     );
//                 }),
//                 catchError((err) => {
//                     return of(ErrorUsers(err.msg));
//                 })
//             );
//         }),
//         catchError((err) => {
//             return of(ErrorUsers(err.msg));
//         })
//     );

// export const GetUserRoles = (
//     action$: ActionsObservable<GetUserRoleStartType>,
//     state$: { value: RootState }
// ): Observable<UserActionType> =>
//     action$.pipe(
//         ofType(GET_USER_ROLE_START),
//         switchMap(() => {
//             return from(getUserRoles()).pipe(
//                 map((result) => {
//                     return GetUserRoleEnd(result);
//                 }),
//                 catchError((err) => {
//                     return of(ErrorUsers(err.msg));
//                 })
//             );
//         }),
//         catchError((err) => {
//             return of(ErrorUsers(err.msg));
//         })
//     );

// export const AssignVehiclesToUserEpic = (
//     action$: ActionsObservable<SetVehicleAssignStartActionType>,
//     state$: { value: RootState }
// ): Observable<UserActionType> =>
//     action$.pipe(
//         ofType(ASSIGN_VEHICLE_USER_START),
//         switchMap((action: SetVehicleAssignStartActionType) => {
//             const _id = state$.value.userReducer.affectTo?._id ?? '';
//             return from(assignVehiclesToUser(_id, action.payload)).pipe(
//                 map((result) => {
//                     return AssignVehicleUserEndAction({
//                         ...result,
//                         _id,
//                     });
//                 }),
//                 catchError((err) => {
//                     return of(ErrorUsers(err.msg));
//                 })
//             );
//         }),
//         catchError((err) => {
//             return of(ErrorUsers(err.msg));
//         })
//     );

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { AlertUi } from "../../../Components/basic/AlertUi";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { InputText } from "../../../Components/Form/InputText";
import { useAppSelector } from "../../../redux/hooks";
import { ClearManageDriverAction, CreateDriverStart, GetDriverUpdateStart } from "../_redux/driverModule.actions";
import { fetchDriverByCode } from "../_redux/driverModule.Service";



export const General = ({className}: {className: string}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { key } = useParams();
    const isUpdate = !!key;
    const [fetchLoading, setFetchLoading] = useState(false);
    const { error, loading, manage } =
        useAppSelector((state) => state.driverModuleReducer);
    
    const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm({defaultValues: {
        name: manage.name ,code: manage.code, mobile: manage.mobile, description: manage.description
    }});

    // Effects
    useEffect(()=> {
        if(manage.name && manage.name.length > 0) {
            const {...data } = manage;
            const driverInfo: any = data;
            Object.keys(data).forEach((key: any) => setValue(key, driverInfo[key]));    
        }
    }, [manage.key]);

    useEffect(()=> {
        if(isUpdate) { dispatch(GetDriverUpdateStart({_id: key})) }
        return function cleanup() {
            dispatch(ClearManageDriverAction());
        };
    }, []);

    // Events
    const onSubmit = async (data: any) => {
        const newData = { ...data };

        if(newData.code.length === 0) { delete newData.code; }
        if(newData.description.length === 0) { delete newData.description; }
        if(newData.mobile.length === 0) { delete newData.mobile; }

        if(newData.code && newData.code.length > 0 && (!isUpdate || (isUpdate && newData.code !== manage.code))) {
            setFetchLoading(true);
            const exist = await fetchDriverByCode(newData.code);
            if (exist && exist.error) {
                setFetchLoading(false);
                setError(exist.for, { message: exist.msg, type: 'isUnique' });
                return;
            }
        }
        dispatch(CreateDriverStart(newData));
     }



    
    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={`"position-relative" ${className}`}>
            <LoaderUi loading={loading || fetchLoading}/>
            <Row>
                { error && <AlertUi solid color="danger" dismissible>{error}</AlertUi> }
                <Col md={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'DRIVER.NAME'})}
                            id={'name'}
                            name={'name'}
                            placeholder={intl.formatMessage({id: 'DRIVER.PLACEHOLDER.NAME'})}
                            register={register('name', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                },
                                maxLength: {
                                    value: 30,
                                    message: `${intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })}: 30`
                                }})}
                            error={errors['name']}
                        />
                    </FormGroup>
                </Col> 
                <Col md={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'DRIVER.CODE'})}
                            id={'code'}
                            name={'code'}
                            placeholder={intl.formatMessage({id: 'DRIVER.PLACEHOLDER.CODE'})}
                            register={register('code')}
                            error={errors['code']}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'DRIVER.PHONE'})}
                            id={'mobile'}
                            name={'mobile'}
                            placeholder={intl.formatMessage({id: 'DRIVER.PLACEHOLDER.PHONE'})}
                            register={register('mobile')}
                            error={errors['mobile']}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'DRIVER.DESCRIPTION'})}
                            id={'description'}
                            name={'description'}
                            placeholder={intl.formatMessage({id: 'DRIVER.PLACEHOLDER.DESCRIPTION'})}
                            register={register('description')}
                            error={errors['description']}
                        />
                    </FormGroup>
                </Col> 
                <Col md={12}>
                    <div className="hstack gap-2 justify-content-end">
                        <ButtonUi color={"primary"} type="submit" loading={loading || fetchLoading}>
                            {
                               isUpdate ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                            }
                        </ButtonUi> 
                    </div>
                </Col>
            </Row>

        </Form>     
    );
}
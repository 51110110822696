export function TrajectTime({ trip }: any) {
    const { startDate, endDate, endHistoryDate } = trip;
    const endDateTrip = endDate ? endDate : endHistoryDate;
    return (
        <div className="time d-flex flex-column justify-content-between align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="fw-bold fs-11">{startDate.format('HH:mm')}</span>
                <span className="text-muted fs-12">{startDate.format('DD/MM/YYYY')}</span>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <span className="fw-bold fs-11">
                    {endDateTrip.format('HH')}:{endDateTrip.format('mm')}
                </span>
                <span className="text-muted fs-12">{endDateTrip.format('DD/MM/YYYY')}</span>
            </div>
        </div>
    );
}

import React, { useEffect } from 'react';
import * as Sentry from "@sentry/react";
import { BASE_URL } from '../env';
export const SentryContext = React.createContext<null>(null);

type SentryContextProviderType = {
  children: JSX.Element;
};

export const SentryContextProvider: React.FunctionComponent<SentryContextProviderType> = (
  props: SentryContextProviderType,
) => {

  useEffect(() => {
    Sentry.init({
      dsn: "https://167a526503290ebaf5ad2683fe7cfed3@o4505698973057024.ingest.sentry.io/4505698974367744",
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [BASE_URL],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }, []);

  return <SentryContext.Provider value={null}>{props.children}</SentryContext.Provider>;
};
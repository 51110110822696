import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { CardBody, CardHeader } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { Planning } from "../../../Components/Planning";
import { useAppSelector } from "../../../redux/hooks";
import { SetTimeEventType } from "../redux/events.actions";


export const Interval = () => {
   
    const dispatch = useDispatch();
    const { manage } = useAppSelector((state) => state.eventsReducer);


    return (

        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'>
                    <FormattedMessage id="ALARMS.TIME" />
                </h4>
            </CardHeader>
            <CardBody>
                <Planning 
                    defaultTimesSelect={manage.timesSelected} 
                    onChange={(time)=> dispatch(SetTimeEventType(time))}
                />
            </CardBody>
        </CardUi>
    );
}
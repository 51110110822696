import { axiosInstance } from '../../../redux/setupAxios';
import { ManageBillingType, FilterBillingType, SubscriptionsCompanyType } from '../types';


export async function saveBilling(billing: ManageBillingType): Promise<any> {
    const url = `/billing`;
    try {
        const res = await axiosInstance.post<ManageBillingType>(url, billing);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateBilling(
    billing: ManageBillingType
): Promise<ManageBillingType> {
    const url = `/billing`;
    try {
        const res = await axiosInstance.patch<any>(url, billing);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getBillings(filter: FilterBillingType): Promise<any> {
    let url = `/billing?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getBilling({ _id }: { _id: string }): Promise<any> {
    const url = `/billing/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteBillings({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/billing${params}`);
}

export async function fetchSubscriptionsByName(
    value: string
): Promise<SubscriptionsCompanyType> {
    let params = '';
    if (value) {
        params = `?name=${value}`;
    }

    const result = await axiosInstance.get<SubscriptionsCompanyType>(
        `billing/company${params}`
    );
    return result.data;
}

import { PeriodType } from '../../History/_redux/types';
import * as eventsActions from './eventActions';

export interface State {
    loading: boolean;
    showEvents: boolean;
    activeEvent: any;
    events: any;
    eventsPeriod?: PeriodType
}

const INITIAL_STATE: State = {
    loading: false,
    showEvents: true,
    activeEvent: null,
    events: [],
    
};

// eslint-disable-next-line default-param-last
export function eventsObjectReducer(state = INITIAL_STATE, action: any): any {
    switch (action.type) {
        case eventsActions.GET_START:
            return {
                ...state,
                loading: true
            };

        case eventsActions.GET_END: {
            const {events, period} = action.payload;
            return { ...state, events, loading: false, eventsPeriod: period };
        }

        case eventsActions.ERROR:
            return { ...state, loading: false };

        case eventsActions.SET_ACTIVE_END:
            if (
                state.activeEvent &&
                state.activeEvent.index === action.payload.index
            ) {
                return { ...state, activeEvent: null };
            }
            return { ...state, activeEvent: action.payload };

        case eventsActions.TOOGLE_EVENTS:
            return {
                ...state,
                showEvents:
                    action.payload === undefined
                        ? !state.showEvents
                        : action.payload,
            };

        case eventsActions.INIT_EVENTS:
            return INITIAL_STATE;

        default:
            return state;
    }
}

import { FuelConfig, ManageVehicleType, SensorsType, TripVehicleType, VehicleInList } from '../types';
import {
    CLEAR_MANAGE_SENSOR,
    CLEAR_VEHICLE_CREATION,
    CREATE_SENSOR_END,
    CREATE_SENSOR_START,
    CREATE_VEHICLE_END,
    CREATE_VEHICLE_START,
    DELETE_SENSOR_END,
    DELETE_SENSOR_START,
    DELETE_VEHICLES_END,
    DELETE_VEHICLES_START,
    ERROR,
    GET_SENSORS_END,
    GET_SENSORS_START,
    GET_SENSOR_UPDATE_END,
    GET_SENSOR_UPDATE_START,
    GET_VEHICLES_END,
    GET_VEHICLES_START,
    GET_VEHICLE_UPDATE_END,
    GET_VEHICLE_UPDATE_START,
    MANAGE_CALIBRATION_SENSOR,
    SET_FUEL_CONFIG,
    SET_FUEL_DETECTION_TYPE,
    SET_SELECTED_VEHICLES,
    SET_SELECT_IO_SENSOR,
    SET_SELECT_SUB_TYPE_SENSOR,
    SET_SELECT_TYPE_SENSOR,
    SET_VEHICLE_GROUP_TYPE,
    SET_VEHICLE_NAME_TYPE,
    SET_VEHICLE_TRACER_TYPE,
    TOOGLE_FUEL_CONFIG,
    TOOGLE_SENSOR_POPUP,
    TOOGLE_TIMING_SENSOR_END,
    TOOGLE_TIMING_SENSOR_START,
    TOOGLE_VISIBLE_SENSOR_START,
    UPDATE_TRIP_END,
    UPDATE_TRIP_START,
    VehicleActionType
} from './vehicleModule.actions';

const FUEL_CONF_INIT: FuelConfig =  {
    fill: 10,
    drill: 20,
    fillThreshold: 0.5,
    drillThreshold: 0.5,
    type: "mielage",
    timeout: 0,
};

type StateType = {
    loading: boolean;
    popup: boolean;
    manage: ManageVehicleType;
    info?: any;
    selectedVehicles: string[];
    filter: {
        limit: number;
        skip: number;
    };
    vehicles: {
        list: VehicleInList[];
        total: number;
    };
    sensors: SensorsType;
    trip: TripVehicleType;

};

const INITIAL_STATE: StateType = {
    loading: false,
    popup: false,
    vehicles: {
        list: [],
        total: 0,
    },
    selectedVehicles: [],
    manage: {
        name: '',
    },
    filter: {
        limit: 20,
        skip: 0,
    },
    sensors: {
        popupSensor: false,
        manage: {
            name: '',
            param: ''
        },
        list: [],
        calibration:[]
    },
    trip: {
        minParkingTime: 10,
        minSpeed: 2
    },   
};

export function vehicleModuleReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: VehicleActionType
): StateType {
    switch (action.type) {
        case CREATE_VEHICLE_START:
        case GET_VEHICLES_START:
        case GET_VEHICLE_UPDATE_START:
        case DELETE_VEHICLES_START:
        case CREATE_SENSOR_START:
        case UPDATE_TRIP_START:
        case GET_SENSORS_START:
        case DELETE_SENSOR_START:
        case GET_SENSOR_UPDATE_START:
        case TOOGLE_TIMING_SENSOR_START:
        case TOOGLE_VISIBLE_SENSOR_START: {
            return { ...state, loading: true };
        }

        case TOOGLE_SENSOR_POPUP:
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    popupSensor: !state.sensors.popupSensor,
                }
            };

        case ERROR: {
            return {
                ...state,
                loading: false,
            };
        }
        
        case CREATE_VEHICLE_END: {
            const list = [...state.vehicles.list];
            const { _id, modifiedCount } = action.payload;
            const updatedVehicle: any = { ...action.payload, ...{ key: _id } };
            if (modifiedCount !== undefined) {
                if (modifiedCount >= 0) {
                    const vehicleIndex = state.vehicles.list.findIndex(
                        (vehicle) => vehicle.key === _id
                    );
                    
                    if (vehicleIndex !== -1) {
                        updatedVehicle.tracerName = updatedVehicle.tracer && updatedVehicle.tracer.label ? updatedVehicle.tracer.label : undefined;
                        list[vehicleIndex] = {
                            ...list[vehicleIndex],
                            ...updatedVehicle,
                        };
                    }
                }
            } else {
                list.unshift(updatedVehicle);
            }

            return {
                ...state,
                loading: false,
                vehicles: {
                    list,
                    total: state.vehicles.total + 1,
                },
                popup: false,
                selectedVehicles: [],
            };
        }

        case CREATE_SENSOR_END: {
            const list = [...state.sensors.list];
            const { _id, modifiedCount, io, type, subType, } = action.payload;
            const updatedSensor: any = { ...action.payload, ...{ key: _id, io: io?.label, type: type?.label, subType: subType?.label } };
            if (modifiedCount !== undefined) {
                if (modifiedCount >= 0) {
                    const sensorIndex = list.findIndex(
                        (sensor) => sensor.key === _id
                    );
                    
                    if (sensorIndex !== -1) {
                        updatedSensor.tracerName = updatedSensor.tracer && updatedSensor.tracer.label ? updatedSensor.tracer.label : undefined;
                        list[sensorIndex] = {
                            ...list[sensorIndex],
                            ...updatedSensor,
                        };
                    }
                }
            } else {
                list.push(updatedSensor);
            }

            return {
                ...state,
                loading: false,
                sensors: {
                    ...state.sensors,
                    list,
                    popupSensor: false,
                    manage: INITIAL_STATE.sensors.manage,
                    fuel: undefined,
                    calibration: []
                },
            };
        }

        case GET_VEHICLES_END:
            // eslint-disable-next-line no-case-declarations
            const { result, filter } = action.payload;
            return {
                ...state,
                vehicles: result,
                filter,
                loading: false,
            };
        case SET_SELECTED_VEHICLES:
            return {
                ...state,
                selectedVehicles: action.payload,
            };
        case DELETE_VEHICLES_END: {
            const { deletedCount, _ids } = action.payload;

            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    selectedVehicles:
                        state.selectedVehicles.filter(
                            (_id) => !_ids.includes(_id)
                        ) || [],
                    vehicles: {
                        total: state.vehicles.total - deletedCount,
                        list:
                            state.vehicles.list.filter(
                                (vehicle) => !_ids.includes(vehicle.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_VEHICLE_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                info: action.payload.result.info,
                trip: action.payload.result.trip,
                popup: true,
                loading: false,
            };
        }

        case SET_VEHICLE_TRACER_TYPE: {
            const manage = { ...state.manage };
            if (action.payload) {
                manage.tracer = action.payload;
            } else {
                delete manage.tracer;
            }
            return {
                ...state,
                manage,
            };
        }

        case SET_VEHICLE_GROUP_TYPE: {
            const manage = { ...state.manage };
            if (action.payload) {
                manage.groups = action.payload;
            } else {
                delete manage.groups;
            }
            return {
                ...state,
                manage,
            };
        }

        case SET_VEHICLE_NAME_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    name: action.payload.name,
                },
            };
        }

        
        case CLEAR_VEHICLE_CREATION: {
            return {
                ...state,
                manage: INITIAL_STATE.manage,
            };
        }

        case SET_SELECT_TYPE_SENSOR: {
            if(state.sensors) {
                return {
                    ...state,
                    sensors: {
                        ...state.sensors,
                        manage: {
                            ...state.sensors.manage,
                            type: action.payload
                        }
                    }
                }
            }
            return state;
        }

        case SET_SELECT_SUB_TYPE_SENSOR: {
            if(state.sensors) {
                return {
                    ...state,
                    sensors: {
                        ...state.sensors,
                        manage: {
                            ...state.sensors.manage,
                            subType: action.payload
                        }
                    }
                }
            }
            return state;
        }

        case SET_SELECT_IO_SENSOR: {
            if(state.sensors) {
                return {
                    ...state,
                    sensors: {
                        ...state.sensors,
                        manage: {
                            ...state.sensors.manage,
                            io: action.payload
                        }
                    }
                }
            }
            return state;
        }

        case CLEAR_MANAGE_SENSOR: {
            if(state.sensors) {
                return {
                    ...state,
                    sensors: {
                        ...state.sensors,
                        manage: INITIAL_STATE.sensors?.manage || {name: ''}
                    }
                }
            }
            return state;
        }
            
        case GET_SENSORS_END: {
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    list: action.payload
                }
            }
        }

        case MANAGE_CALIBRATION_SENSOR: {
            const {key, x, y} = action.payload;
            let calibration = [...state.sensors.calibration];
            const size = calibration.length;
            const lastItemKey = size > 0 ? calibration[size-1].key : -1;
            switch(true) {
                case key === undefined && x === null && y === null: // Ajouter une nouvelle ligne
                    calibration.push({key: lastItemKey+1, x: null, y: null, error: true});
                    break;
                case key !== null && key !== undefined && x === null && y === null:
                    calibration = calibration.filter(item => item.key !== key); // Supprimer une ligne par id
                    break;
                case key !== null && key !== undefined: // modifier une ligne
                    const index = calibration.findIndex(item => item.key === key);
                    const value = index > 0 ? calibration[index -1].x : 0;
                    if(index >= 0) {
                        calibration[index] = {
                            ...calibration[index],
                            ...action.payload,
                            error: !(x !== null && y !== null && ((index === 0 ) || (value !== null && value >= 0 && x > value)))
                        };
                        break;
                    } 
            }
            
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    calibration
                }
               
            }
        }

        case DELETE_SENSOR_END: {
            const {modifiedCount, sensorId} = action.payload;
            if (modifiedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    sensors: {
                        ...state.sensors,
                        list: state.sensors.list.filter(sensor => sensor.key !== sensorId)
                    }
                };
            }
            return state;
        }

        case GET_SENSOR_UPDATE_END: {
            const { manage, calibration, fuel } = action.payload;
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    manage,
                    calibration: calibration || [],
                    fuel: fuel,
                    popupSensor: true,
                },
                loading: false
            }
        }

        case TOOGLE_TIMING_SENSOR_END: {
            const {modifiedCount, sensorId, value} = action.payload
            const list = [...state.sensors.list];
            if (modifiedCount !== undefined) {
                if (modifiedCount >= 0) {
                    const sensorIndex = list.findIndex(
                        (sensor) => sensor.key === sensorId
                    );
                    
                    if (sensorIndex !== -1) {
                        list[sensorIndex] = {
                            ...list[sensorIndex],
                            ...{additional: value ? 'event-time' : undefined},
                        };
                    }
                }
            }

            return {
                ...state,
                loading: false,
                sensors: {
                    ...state.sensors,
                    list,
                    popupSensor: false,
                    manage: state.sensors.manage
                },
            };
        }
        
        case TOOGLE_FUEL_CONFIG: {
            const oldState = {...state}
            if(oldState.sensors?.fuel) {
                delete oldState.sensors?.fuel;
                return oldState
            }
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    fuel: FUEL_CONF_INIT
                }
                
            }
        }        
        case SET_FUEL_CONFIG: {
            return {
                ...state,
                sensors: {
                    ...state.sensors,
                    fuel: action.payload
                }
                
            }
        }

        case SET_FUEL_DETECTION_TYPE: {
            if(state.sensors.fuel) {
                return {
                    ...state,
                    sensors: {
                        ...state.sensors,
                        fuel: {
                            ...state.sensors.fuel,
                            type: action.payload
                        }
                    } 
                }
            }
            
            return state;
        }

        

        case UPDATE_TRIP_END:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}

import { axiosInstance } from "../../../../redux/setupAxios";
import { FilterUserType, TypesReportsEventType } from "./types";

export async function fetchTypesReport(): Promise<TypesReportsEventType> {
    const result = await axiosInstance.get<TypesReportsEventType>(
        `report/type`
    );
    return result.data;
}

export async function generateReport(payload: any): Promise<any> {
  const result = await axiosInstance.post<TypesReportsEventType>(
      `report`,
      payload
  );
  return result.data;
}

export async function getReports(filter: FilterUserType): Promise<any> {
  let url = `/report?skip=${filter.skip}&limit=${filter.limit}`;
  if (filter.name && filter.name !== '') {
      url += `&name=${filter.name}`;
  }
  if (filter.select) {
      url += `&select=${filter.select}`;
  }
  try {
      const res = await axiosInstance.get<any>(url);
      return res.data;
  } catch (e: any) {
      throw Error(e.message);
  }
}




export async function download(payload: {_id: string}): Promise<any> {
  const url = `report/${payload._id}/download`;
  return await axiosInstance.get<any>(url, {responseType: 'blob'});
}



export const vehiclesToAssign = (vehicles: string[]) => {
    const vehicleIds = vehicles
      .filter((vehicle) => vehicle.includes('#'))
      .map((vehicle) => {
        const result = vehicle.split('#');
        return result[result.length - 1];
      });
  
    return vehicleIds;
  };
  

import { useEffect, useState } from "react";
import { ListMapComponent } from "../../Components/List/ListMap/ListMap";
import HeaderListMapComponent from "../../Components/List/ListMap/ListMapHeader";
import { ListMapSearchComponent } from "../../Components/List/ListMap/ListMapSearch";
import { ListMapData } from "../../Components/List/ListMap/ListMapData";
import StopListItem from "./components/stop-list-item";
import { HEIGHT_LIST_MAP_FOOTER, ListMapFooter } from "../../Components/List/ListMap/ListMapFooter";
import { IF } from "../../Components/If";
import CreateStopModal from "./create-stop.modal";
import { useNavigate } from "react-router-dom";
import { useFetchStops } from "../../common/hooks/axios/stops/useFetchStops";
import { useDeleteStop } from "../../common/hooks/axios/stops/useDeleteStop";
import { SetNotificationAction } from "../../redux/rootActions";
import { useDispatch } from "react-redux";
import StopGroup from "./group-management";
import Emitter from "../../helpers/emitter";


const CHILDREN_HEIGHT = 240;
const LIMIT = 500;


export const StopListPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isVisible, setVisibility] = useState<boolean>(true);
    const [filter, setFilter] = useState<{"pagination[limit]": number, "pagination[skip]": number, name?: string, group?: string}>({"pagination[limit]": LIMIT, "pagination[skip]": 0});

    const { fetchStops, stops, fetchStopsLoading } = useFetchStops({manual: false, limit: filter["pagination[limit]"]})
    const { deleteAsyncStop, deleteStopLoading } = useDeleteStop({manual: false})
    const showFooter = Math.ceil(stops.total / LIMIT) > 1;
    
    useEffect(() => {
        if(stops.list.length > 0) {
            Emitter.emit(Emitter.event["STOP:DRAW_ALL"], stops.list);
        }
    }, [stops]);

    // Functions
    const refetchData = (skip: number, params?: {searchBy?: string, group?: string}) => {
        const newFilter = {...filter, 'pagination[limit]': LIMIT, 'pagination[skip]': skip };
        if(params?.hasOwnProperty('group')) { newFilter.group = params.group; }
        if(params?.hasOwnProperty('searchBy')) { newFilter.name = params.searchBy; }
        setFilter(newFilter);
        fetchStops(newFilter);
    };

    const onSelectGroup = (key: string | null) => {
        refetchData(0, {group: key ?? undefined});
    }

    const onSearch = ({searchBy}: {searchBy?: string}) => { 
        refetchData(0, {searchBy});
    }

    const onPaginate = (skip: number, limit: number) => { 
        refetchData(skip);
    }
    
    const onClickCreate = () => { 
        navigate(`/stops/add-manual`); 
    }

    const toggleVisibility = () => { setVisibility(!isVisible); }

    const onEvent = async (action: string, key: string, confirmationText?: string) => { 
        switch(action) {
            case 'locate': {
                const stop: any = stops.list.find((stop) => stop.key === key );
                if(stop) {
                    Emitter.emit(Emitter.event["STOP:LOCALISE"], stop.location[0].coordinates);
                }
                break;
            }
            case 'edit': {
                navigate(`/stops/${key}/update`);
                break;
            }
            case 'delete': {
                if(!deleteStopLoading) {
                    Emitter.emit(Emitter.event.DELETE_POPUP, {
                        textConfirmation: confirmationText ?? "confirm",
                        title: "Supprimer l'arrêt",
                        description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                        onDelete: () => deleteAsyncStop(key),
                        onDeleteSuccess: () => {
                                dispatch(SetNotificationAction({
                                    title: `succès`,
                                    "message": `Arrêt supprimé`,
                                    timeToClose: 5000,
                                    type: 'success'
                                }));
                                refetchData(0);  
                        },
                    });
                }
                break;
            }
        }
    }

    return (
        <div className="page-content object-page" >
            <ListMapComponent isVisible={isVisible} toggleVisibility={toggleVisibility} loading={fetchStopsLoading || deleteStopLoading}>
                <HeaderListMapComponent title="Liste des arrêts" toggleVisibility={toggleVisibility}/>
                <StopGroup onSelect={(key: string | null) => onSelectGroup(key)}/>
                <ListMapSearchComponent onClickCreate={onClickCreate} onSearch={onSearch} />
                <ListMapData 
                    Component={StopListItem}
                    onEvent={onEvent}
                    data={stops.list} 
                    minusHeight={CHILDREN_HEIGHT + (showFooter ? HEIGHT_LIST_MAP_FOOTER : 0)} 
                />

                <IF condition={showFooter}>
                    <ListMapFooter 
                        total={stops.total}
                        skip={filter["pagination[skip]"]}
                        limit={filter["pagination[limit]"]}
                        onPaginate={onPaginate}
                    />
                </IF>
            </ListMapComponent>
            <CreateStopModal />
        </div>
    );
      
        
}

export default StopListPage;
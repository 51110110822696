import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CardBody, CardHeader, Col, Container, Form, FormGroup, Row } from "reactstrap";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { AsyncInputSelect } from "../../Components/Form/AsyncInputSelect";
import { InputFlatPickrUi } from "../../Components/Form/InputFlatPickr";
import { InputText } from "../../Components/Form/InputText";
import { useAxios } from "../../common/hooks/axios/useAxios";
import { useAppSelector } from "../../redux/hooks";
import { SetNotificationAction } from "../../redux/rootActions";
import { getDriversForInput } from "../Driver/_redux/driverModule.Service";
import { getVehiclesForMaintenance } from "../Maitenance/_redux/maintenance.service";
import { Incident, IncidentTypeComponent } from "./components/incident-type-component";
import { Severity, SeverityTypeComponent } from "./components/severity-type-component";



type IncidentType = {
    name?: string;
    unit?: { label: string; value: string },
    driver?: { label: string; value: string },
    motif?: string,
    observation?: string,
    type: Incident;
    severity: Severity,
}
export function ManageIncidentPage() {

    // hooks
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { _id } = useParams();
    const { user } = useAppSelector((state) => state.authReducer);
    const [ choosedDate, setChoosedDate ] = useState<Date>(new Date());
    const [ data, setData ] = useState<IncidentType>({
        type: Incident.PENALTY,
        severity: Severity.MINOR
    });
    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const { data: result, status, execute, loading } = useAxios(true);
    const { data: manageData, execute: manageExecute, loading: manageLoading } = useAxios<IncidentType & {date: Date}>(true);
    
    // vars
    const isUpdate = _id !== 'add';

    // functions 
    const fetchVehicles = async (inputValue: string) => getVehiclesForMaintenance({ name: inputValue });
    const fetchDrivers = async (inputValue: string) => getDriversForInput({ name: inputValue });

    // events 
    const onSubmit = async (payload: any) => { 
        if(data.unit && data.driver) {
            const newData = {
                ...data,
                ...payload,
                date: choosedDate
            }
            if(isUpdate) {
                newData._id = _id;
            }
            execute('incidents', {method: "POST", data: newData});
        }
    }
    const setVehicle = (unit: any) => { setData({...data, unit}) };
    const setDriver = (driver: any) => { setData({...data, driver}) };
    const onChangeIncidentType = (incident: Incident) => { 
        setData({...data, type: incident})
     };
     const onChangeSeverityType = (severity: Severity) => {
        setData({...data, severity})
    };
    const onChangeDate = (date: Date[]) => {
        setChoosedDate(date[0]);
    };
    const backToPrevious = () => {
        navigate('/incident');
    }

    // Effects
    useEffect(()=> {
        if(_id !== 'add') {
            manageExecute(`incidents/${_id}`, {method: 'GET'})
        }
    }, []);

    useEffect(()=> {
        if(!!manageData) {
            const { date: manageDate, ...manageOther } = manageData;
            setChoosedDate(manageDate);
            setData(manageOther);
            setValue('name', manageOther.name);
            if(manageOther?.motif) {
                setValue('motif', manageOther?.motif);
            }
            setValue('observation', manageOther.observation);
        }
    }, [manageData]);


    useEffect(()=> {
        if(status && status >= 200 && status <=204) {
            dispatch(SetNotificationAction({
                title: "Incident créé/modifié avec succès",
                "message": 'Succès',
                timeToClose: 3000,
                type: 'success'
            }));
            navigate('/incident');
        }
    }, [result, status])

    return (
        <div id="incidentPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'INCIDENT' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={backToPrevious}
                />
                <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
                    <LoaderUi loading={loading || manageLoading}/>
                    <Row>
                        <LoaderUi loading={false}/>
                        <Col md={6} className="mb-3">
                            <CardUi className="h-100">
                                <CardHeader>
                                    <h4 className='card-title mb-0'><FormattedMessage id="INCIDENT.INFO" /></h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                    <Col xl={6}>
                                            <FormGroup>
                                                <InputText
                                                    type="text"
                                                    label={intl.formatMessage({id: 'INCIDENT.NAME'})}
                                                    id={'name'}
                                                    name={'name'}
                                                    placeholder={intl.formatMessage({id: 'INCIDENT.PLACEHOLDER.NAME'})}
                                                    register={register('name', {
                                                        required: {
                                                            value: true, 
                                                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                                        }})}
                                                    error={errors['name']}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={6}>
                                            <FormGroup>
                                                {
                                                    <InputFlatPickrUi
                                                        className="form-control"
                                                        label={intl.formatMessage({ id: 'INCIDENT.TIME' })}
                                                        value= {choosedDate}
                                                        options={{ dateFormat: "d M, Y" }}
                                                        onChange={onChangeDate}
                                                    />
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col  xl={12}>
                                            <FormGroup>
                                                {
                                                    <AsyncInputSelect 
                                                        label={intl.formatMessage({ id: 'COMMON.UNIT'})}
                                                        cacheOptions
                                                        defaultOptions
                                                        defaultValue={data?.unit}
                                                        error={!data?.unit}
                                                        loadOptions={fetchVehicles}
                                                        value={data?.unit}
                                                        onChange={setVehicle}
                                                    />
                                                }
                                            </FormGroup>
                                        </Col>
                                        <Col  xl={12}>
                                            <FormGroup>
                                                {
                                                    <AsyncInputSelect 
                                                        label={intl.formatMessage({ id: 'COMMON.DRIVER'})}
                                                        cacheOptions
                                                        defaultOptions
                                                        defaultValue={data?.unit}
                                                        error={!data?.unit}
                                                        loadOptions={fetchDrivers}
                                                        value={data?.driver}
                                                        onChange={setDriver}
                                                    />
                                                }
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                </CardBody>
                            </CardUi>   
                        </Col>

                        <Col md={6} className="mb-3">
                            <CardUi className="h-100">
                                <CardHeader>
                                    <h4 className='card-title mb-0'><FormattedMessage id="INCIDENT.INFO" /></h4>
                                </CardHeader>
                                <CardBody>
                                    <IncidentTypeComponent 
                                        register={register} 
                                        errors={errors} 
                                        active={data.type}
                                        onChange={onChangeIncidentType}
                                    />

                                    <SeverityTypeComponent 
                                        register={register} 
                                        errors={errors} 
                                        active={data.severity}
                                        onChange={onChangeSeverityType}
                                    />
    
                                    <Col md={12}>
                                        <FormGroup>
                                            <InputText
                                                type="textarea"
                                                label={intl.formatMessage({ id: 'COMMON.OBSERVATION'})}
                                                id={'observation'}
                                                name={'observation'}
                                                placeholder={intl.formatMessage({id: 'COMMON.PLACEHOLDER.OBSERVATION'})}
                                                register={register('observation')}
                                                error={errors['observation']}
                                            />
                                        </FormGroup> 
                                    </Col>

                                </CardBody>
                            </CardUi>   
                        </Col>
                        <Col>
                            <div className="hstack gap-2 justify-content-end">
                                <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                    <FormattedMessage id="COMMON.CANCEL"/>
                                </ButtonUi>
                                <ButtonUi color={"primary"} type="submit">
                                    {
                                       !isUpdate ? <FormattedMessage id="COMMON.ADD"/>  : <FormattedMessage id="COMMON.UPDATE"/>
                                    }
                                </ButtonUi> 
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </div>
            
       
    );
}
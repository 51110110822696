import { CompanyLimit, FilterCompanyType, GetCompanysResponse, ManageCompanyType, NominationCompanyType } from "../types";


export const CREATE_COMPANY_START = '[COMPANY] CREATE_COMPANY_START';
export type CreateCompanyStartType = {
    type: typeof CREATE_COMPANY_START;
    payload: {
        name: string;
        limit: CompanyLimit;
    };
};
export function CreateCompanyStart(payload: {
    name: string;
    limit: CompanyLimit;
}): CreateCompanyStartType {
    return { type: CREATE_COMPANY_START, payload };
}

export const CREATE_COMPANY_END = '[COMPANY] CREATE_COMPANY_END';
export type CreateCompanyEndType = {
    type: typeof CREATE_COMPANY_END;
    payload: ManageCompanyType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateCompanyEnd(
    payload: ManageCompanyType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateCompanyEndType {
    return { type: CREATE_COMPANY_END, payload };
}

export const ERROR = '[COMPANY] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorCompanys(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_COMPANYS_START = '[COMPANY] GET_COMPANYS_START';
export type GetCompanysStartType = {
    type: typeof GET_COMPANYS_START;
    payload: FilterCompanyType;
};
export function GetCompanysStart(
    payload: FilterCompanyType
): GetCompanysStartType {
    return { type: GET_COMPANYS_START, payload };
}

export const GET_COMPANYS_END = '[COMPANY] GET_COMPANYS_END';
export type GetCompanysEndType = {
    type: typeof GET_COMPANYS_END;
    payload: { result: GetCompanysResponse; filter: FilterCompanyType };
};
export function GetCompanysEnd(payload: {
    result: GetCompanysResponse;
    filter: FilterCompanyType;
}): GetCompanysEndType {
    return { type: GET_COMPANYS_END, payload };
}

export const SET_SELECTED_COMPANYS = '[COMPANY] SET_SELECTED_COMPANYS';
export type SetSelectedCompanysType = {
    type: typeof SET_SELECTED_COMPANYS;
    payload: string[];
};
export function SetSelectedCompanys(
    payload: string[]
): SetSelectedCompanysType {
    return { type: SET_SELECTED_COMPANYS, payload };
}

export const DELETE_COMPANYS_START = '[COMPANY] DELETE_COMPANYS_START';
export type DeleteCompanysStartType = {
    type: typeof DELETE_COMPANYS_START;
    payload: string[];
};
export function DeleteCompanysStart(
    payload: string[]
): DeleteCompanysStartType {
    return { type: DELETE_COMPANYS_START, payload };
}

export const DELETE_COMPANYS_END = '[COMPANY] DELETE_COMPANYS_END';
export type DeleteCompanysEndType = {
    type: typeof DELETE_COMPANYS_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteCompanysEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteCompanysEndType {
    return { type: DELETE_COMPANYS_END, payload };
}

export const CLEAR_COMPANY_CREATION = '[COMPANY] CLEAR_COMPANY_CREATION';
export type ClearCompanyCreationActionType = {
    type: typeof CLEAR_COMPANY_CREATION;
};
export function ClearCompanyCreation(): ClearCompanyCreationActionType {
    return { type: CLEAR_COMPANY_CREATION };
}

export const GET_COMPANY_UPDATE_START = '[DRVIER] GET_COMPANY_UPDATE_START';
export type GetCompanyUpdateStartType = {
    type: typeof GET_COMPANY_UPDATE_START;
    payload: { _id: string };
};
export function GetCompanyUpdateStart(payload: {
    _id: string;
}): GetCompanyUpdateStartType {
    return { type: GET_COMPANY_UPDATE_START, payload };
}

export const GET_COMPANY_UPDATE_END = '[COMPANY] GET_COMPANY_UPDATE_END';
export type GetCompanyUpdateEndType = {
    type: typeof GET_COMPANY_UPDATE_END;
    payload: { result: ManageCompanyType };
};
export function GetCompanyUpdateEnd(payload: {
    result: ManageCompanyType;
}): GetCompanyUpdateEndType {
    return { type: GET_COMPANY_UPDATE_END, payload };
}

export const SET_COMPANY_STATUS_TYPE = '[COMPANY] SET_COMPANY_STATUS_TYPE';
export type SetCompanyStatusActionType = {
    type: typeof SET_COMPANY_STATUS_TYPE;
    payload: boolean;
};
export function SetCompanyStatusAction(
    payload: boolean
): SetCompanyStatusActionType {
    return { type: SET_COMPANY_STATUS_TYPE, payload };
}

export const SET_COMPANY_SUBSCRIPTION_DATE_TYPE =
    '[COMPANY] SET_COMPANY_SUBSCRIPTION_DATE_TYPE';
export type SetCompanySubscriptionDateActionType = {
    type: typeof SET_COMPANY_SUBSCRIPTION_DATE_TYPE;
    payload: number;
};
export function SetCompanySubscriptionDateAction(
    payload: number
): SetCompanySubscriptionDateActionType {
    return { type: SET_COMPANY_SUBSCRIPTION_DATE_TYPE, payload };
}

export const SET_COMPANY_SUBSCRIPTION_TYPE_TYPE =
    '[COMPANY] SET_COMPANY_SUBSCRIPTION_TYPE_TYPE';
export type SetCompanySubscriptionTypeActionType = {
    type: typeof SET_COMPANY_SUBSCRIPTION_TYPE_TYPE;
    payload: { label: string; value: string };
};
export function SetCompanySubscriptionTypeAction(payload: {
    label: string;
    value: string;
}): SetCompanySubscriptionTypeActionType {
    return { type: SET_COMPANY_SUBSCRIPTION_TYPE_TYPE, payload };
}


export const SWITCH_COMPANY_START = '[COMPANY] SWITCH_COMPANY_START';
export type SwitchCompanyStartActionType = {
    type: typeof SWITCH_COMPANY_START;
    payload: { _id: string };
};
export function SwitchCompanyStart(payload: {
    _id: string;
}): SwitchCompanyStartActionType {
    return { type: SWITCH_COMPANY_START, payload };
}

export const SWITCH_COMPANY_END = '[COMPANY] SWITCH_COMPANY_END';
export type SwitchCompanyEndActionType = {
    type: typeof SWITCH_COMPANY_END;
};
export function SwitchCompanyEnd(): SwitchCompanyEndActionType {
    return { type: SWITCH_COMPANY_END };
}

export type CompanyActionType =
    | CreateCompanyStartType
    | CreateCompanyEndType
    | ErrorActionType
    | GetCompanysStartType
    | GetCompanysEndType
    | SetSelectedCompanysType
    | DeleteCompanysStartType
    | DeleteCompanysEndType
    | ClearCompanyCreationActionType
    | GetCompanyUpdateStartType
    | GetCompanyUpdateEndType
    | SetCompanyStatusActionType
    | SetCompanySubscriptionDateActionType
    | SetCompanySubscriptionTypeActionType
    | SwitchCompanyStartActionType
    | SwitchCompanyEndActionType;


import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';


export type TripIconProps = { trip: { on: boolean }, size: number };

export default function TripIcon({trip, size = 16}: TripIconProps) {
    if (trip.on) {
        return <AiFillPlayCircle size={size} style={{ color: 'rgba(1, 147, 10, 1)' }} />;
    }
    return <AiFillPauseCircle size={size} style={{ color: '#1976d2' }} />;
}
import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem } from 'reactstrap';
import { ButtonUi } from './ButtonUi';

type paginatorType = {
    total: number;
    skip: number;
    limit: number;
    onPaginate: (skip: number, limit: number) => void;
};

export function Paginator({ skip, limit, onPaginate, total }: paginatorType) {
    const totalPages = Math.ceil(total / limit);

    const getPage = () => {
        const pageIndex = Math.ceil(skip / limit);
        return pageIndex + 1;
    };

    const [currentPage, setCurrentPage] = useState(getPage());

    useEffect(() => setCurrentPage(getPage()), [total, skip]);

    const next = () => {
        onPaginate(skip + limit, limit);
    };

    const back = () => {
        onPaginate(skip - limit, limit);
    };


    const gotToPage = (page: number) => {
        if(page !== currentPage)
        onPaginate((page -1) * limit, limit);
    };

    
        return (
            <Pagination className="float-end">
                <PaginationItem>
                    <ButtonUi size='sm' color={"primary"} onClick={back} disabled={currentPage===1} outline>{'<'}</ButtonUi>
                </PaginationItem>
                {
                    Array.from({length: totalPages}, (_, i) => i + 1).map(page => (
                        <PaginationItem >
                            <ButtonUi 
                                size='sm'
                                color={"primary"} 
                                onClick={()=> gotToPage(page)} 
                                outline={page !== currentPage}>{`${page}`}
                            </ButtonUi>
                        </PaginationItem>)
                    )
                }
                <PaginationItem >
                    <ButtonUi size='sm' color={"primary"} onClick={next} disabled={!(currentPage >= 1 && skip + limit < total)} outline>{'>'}</ButtonUi>
                </PaginationItem>
            </Pagination>        
        );

}
        
        
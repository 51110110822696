import { useEffect } from "react";
import { useAxios } from "../useAxios";

export type FetchGroupsProps = {
  name?: string,
}

type Group = { key: string, name: string, totalItems: number };

type UseFetchGroupsResponse = { 
  groups: Group[];
  fetchGroupsLoading: boolean;
  error?: string;
  fetchGroups: (params?: FetchGroupsProps) => void;
  cancel: () => void;
}


type UseFetchGroupsProps = {
  manual?: boolean,
  type: string,
}

export function useFetchGroups(props: UseFetchGroupsProps): UseFetchGroupsResponse {
  const URL = `groups/${props.type}`;
  const { execute, data, cancel, error, loading, init } = useAxios<Group[]>(props.manual);

  useEffect(() => {
    if(!props.manual) { fetchGroups(); }
  }, []);

  const fetchGroups = (params?: FetchGroupsProps) => {
    execute(URL, { method: "GET", params});
  };

  return { 
    groups: data ?? [],
    fetchGroupsLoading: loading, 
    error,
    fetchGroups,
    cancel, 
  };
}

import {  useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";

import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { useAppSelector } from "../../redux/hooks";

import { DriverMark } from "./components/mark";
import { DriverTraject } from "./components/traject";
import { EcoDataResponse, getDriverStats } from "./eco.service";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { DriverChart } from "./components/driver-chart";
import { useParams } from "react-router-dom";

export const EcoPage = () => {

    const [ loading, setLoading ] = useState(false);
    const [data, setData] = useState<EcoDataResponse>()
    const { user } = useAppSelector((state) => state.authReducer);
    const { _id } = useParams();

    useEffect(()=> {
        setLoading(true);
        getDriverStats(_id)
            .then((response)=> {
                setData(response);
            })
            .catch((response)=> {
                setData(response);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);

    return (
        <div id="alarmPage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={"Comportement de conduite"} 
                    pageTitle={user?.company.name || ''} 
                />
                <LoaderUi loading={loading} />
                <Row>
                    <Col md={4}>
                        <Col  md={12}>
                            <DriverMark score={data?.score} name={data?.name} company={"ministère"}/>
                        </Col>
                        <Col md={12}>
                            <DriverTraject data={data?.traject}/>
                        </Col>
                    </Col>
                     <Col md={6}>
                        <DriverChart data={data?.detection}/>
                    </Col> 
                </Row>
                

            </Container>
        </div>
    );
}
import classNames from 'classnames';
import { divIcon, Point } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { RiCarFill } from 'react-icons/ri';
import {  Tooltip, } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { InitVehicle } from '../../../../../pages/Map/Object/Details/_redux/vehicleActions';
import { useAppSelector } from '../../../../../redux/hooks';
import { SetOptionMapAction } from '../../../../../redux/rootActions';
import {CustomMarker} from './CustomMarker';
import { DirectionMarker } from './DirectionMarker';

export function ObjectMarker({ vehicle, keepTrack, isClustered, sharedMap, selected }: {vehicle: any, keepTrack: boolean, isClustered?: boolean, sharedMap?: boolean, selected?: boolean}) {

    const dispatch = useDispatch();
    const { optionsMap } = useAppSelector(
        (state) => state.mainReducer
    );
    const { _id, name, baseInfo } = vehicle;
    if(!baseInfo || !baseInfo.longitude || !baseInfo.latitude) {
        return null;
    }
    
    const { latitude, longitude, orientation } = baseInfo;
    const className = keepTrack ? "marker-pin bg-success" : "marker-pin bg-primary";
    const iconPinHtml = renderToStaticMarkup(
        <div className={className}>
            <RiCarFill size={13}/>
        </div>
    );

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        // popupAnchor: [0, -32],
    });

    const tooltipVehicleClass = classNames({
        [`tooltip-vehicle`]: true,
    });

    const chooseObject = (): void => {
        dispatch(SetOptionMapAction({opt: 'zoom', value: 18}));
        dispatch(InitVehicle({ vehicle, sharedMap }));
    };
    
    if(selected && keepTrack || !selected) {
        return (
            <CustomMarker
                key={_id}
                position={{ lat: longitude, lng: latitude }}
                tracking={{
                    keepTrack,
                    padding: optionsMap ? optionsMap.padding : undefined,
                    zoom: optionsMap ? optionsMap.zoom : undefined
                }}
                icon={iconPin}
                rotationAngle={0}
                duration={2000}
                eventHandlers={{ click: chooseObject }}
            >
                {
                    ((orientation === 0 || !!orientation) && !isClustered) && <DirectionMarker vehicle={vehicle} angle={orientation}/>
                }
                <Tooltip
                    className={tooltipVehicleClass}
                    permanent={true}
                    offset={new Point(0, 0)}
                    direction={'right'}
                >
                    {name} {vehicle.driver && `(${vehicle.driver})`}
                </Tooltip>
            </CustomMarker>
        );
    }

    return (<></>);
}

import { IconType } from "react-icons";
import { IoMdClose } from "react-icons/io";
import { Modal, ModalBody, Row, Label } from "reactstrap";
import { useFetchUnitsToAssign } from "../../common/hooks/axios/unit/useFetchUnitsToAssign";
import SelectItems from "../advanced/v2/select-items/select-items";
import { ButtonUi } from "../basic/ButtonUi";


type ModalSelectUnitsProps = {
    default: Record<string, {label: string, value: string}[]>
    isOpen: boolean;
    title: string;
    description: string;
    Icon: IconType;
    toggleModal: () => void;
    onSelect: (selection: Record<string, {label: string, value: string}[]>) => void;
}
const ModalSelectUnits = (props: ModalSelectUnitsProps) => {

    const { unitsToAssign, fetchUnitsToAssign } = useFetchUnitsToAssign({manual: false});    

    const onSearch = ({searchBy}: {searchBy: string}) => fetchUnitsToAssign(searchBy);
    const onRefresh = ({searchBy}: {searchBy: string}) => fetchUnitsToAssign(searchBy);

    return (
        <Modal isOpen={props.isOpen} size={"md"} fullscreen={"md"} toggle={props.toggleModal}>
            <ButtonUi 
                style={{zIndex: 4, right: 10, top: 10}}
                className="position-absolute"
                color="primary"
                rounded-pill
                onClick={props.toggleModal}
                ghost
                size="sm"
            >
                <IoMdClose size={20}/>
            </ButtonUi>
            <ModalBody>
                <Row>
                    <div className="d-flex flex-column justify-content-center align-items-center mb-4">
                        <props.Icon className="text-primary mb-3" size={36}/>
                        <Label className="fs-17 mb-3">{props.title}</Label> 
                        <span style={{fontWeight: 500}} className="text-muted fs-14">{props.description}</span>
                    </div>
                </Row>
                <SelectItems
                    default={props.default}
                    data={unitsToAssign}
                    maxSelection={100}
                    onSearch={onSearch}
                    onRefresh={onRefresh}
                    showSelectedItems={true}
                    onSelect={props.onSelect}
                />
            </ModalBody>
        </Modal>
    );
}

export default ModalSelectUnits;
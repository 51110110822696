import { ButtonUi } from "../../../basic/ButtonUi";
import { IconType } from "react-icons";
import { ColorType } from "../../../types";

type DriverActionProps = {
    color: ColorType,
    text: string,
    icon: IconType,
    disabled?: boolean,
    onClick?: ()=> void,
}
export const DriverAction = ({color, text, icon, disabled, onClick}: DriverActionProps) => {
    const Icon = icon;
    const click = () => {
        if(!disabled && onClick) {
            onClick();
        }
    }
    return (
        <div className="d-flex flex-column align-items-center" style={{flex: "1 1 0px"}} onClick={click}>
            <ButtonUi size="sm" className="p-2" color={color} outline circle disabled={disabled} loading>
                <Icon size={18}/>
            </ButtonUi>
            <span style={{fontWeight: 500, cursor: disabled ? 'default': 'pointer'}} className={`mt-1 fs-11  text-center text-${color} ${disabled && 'opacity-75'}`}>{text}</span>
        </div>                  
    );
}
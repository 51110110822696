import classNames from "classnames";
import { AiOutlineDelete } from "react-icons/ai";
import { BiBus } from "react-icons/bi";
import { IF } from "../../../../../Components/If";

type UnitRoadComponentProps = {
    uid: string,
    index: number,
    places?: number,
    canBeRemoved: boolean,
    onRemove: (uid: string) => void
}
export const UnitRoadComponent = (props: UnitRoadComponentProps) => {

    const VehicleObjectClass = classNames({
        ['object-info px-3 d-flex align-items-center justify-content-between']: true,
    });

    const remove = () => { 
        if(props.canBeRemoved) { props.onRemove(props.uid); }
        }
    return (    
        <div className="border py-2 mt-3">
            <div>
                
            </div>
            <div className={VehicleObjectClass}>
                <div className="d-flex align-items-center">
                    <div className="d-flex flex-row align-items-center">
                        <BiBus className="text-primary me-1" size={18}/>
                        <h6 className="mb-0"> Transport: { props.index +1 }</h6> 
                    </div>
                </div>
                <div>
                    <div className="d-flex flex-row align-items-center">
                        <span className="text-success">{props.places} places</span>
                        <IF condition={props.canBeRemoved}>
                            <div  className="ghost btn btn-danger rounded-circle p-0 m-0 ms-3" onClick={remove}>
                                <AiOutlineDelete className="text-danger icon-sm"/> 
                            </div>
                        </IF>
                        
                    </div>
                </div>


            </div>
        </div>
    );
}
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import Emitter from '../../../helpers/emitter';
import EmitterActions from '../../../helpers/emitter/emitter.actions';
import { SetNotificationAction, SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import { BindDriverEnd, BindDriverStartType, BIND_DRIVERS_START, CreateDriverEnd, CreateDriverStartType, CREATE_DRIVER_START, DeleteDriverEnd, DeleteDriverStart, DeleteDriverStartType, DELETE_DRIVERS_END, DELETE_DRIVERS_START, DriverActionsType, ErrorDriver, GetDriverEnd, GetDriverStart, GetDriverStartActionType, GetDriverUpdateEnd, GetDriverUpdateStartType, GetUnitToAssignDriverEnd, GetUnitToAssignDriverEndActionType, GetUnitToAssignDriverStartActionType, GET_DRIVER_UPDATE_START, GET_START, GET_UNIT_TO_ASSIGN_START, UnbindDriverEnd, UnbindDriverStartType, UNBIND_DRIVERS_START } from './driverModule.actions';
import { bindDriver, deleteDrivers, getDriver, getDrivers, getUnitsToAssign, saveDriver, unbindDriver, updateDriver } from './driverModule.Service';

export const SaveDriverEpic = (
    action$: ActionsObservable<CreateDriverStartType>,
    state$: { value: RootState }
): Observable<DriverActionsType | any> =>
    action$.pipe(
        ofType(CREATE_DRIVER_START),
        switchMap((action: CreateDriverStartType) => {
            const other = state$.value.driverModuleReducer.manage;
            return from(
                other.key
                    ? updateDriver({...action.payload, ...{_id: other.key}})
                    : saveDriver(action.payload)
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...other,
                        ...result,
                        ...{
                            key: other.key ? other.key : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                            name: action.payload.name,
                        },
                    };       
                       
                    Emitter.emit(EmitterActions.REFRESH_DRIVER_INFO, "");
                    return of(CreateDriverEnd(newManage),
                    SetRedirectAction(other.key ? `/drivers/${other.key}/details` : '/drivers'),
                    SetNotificationAction({
                        title: "succès",
                        "message": other.key ? "Conducteur modifié" : "Conducteur ajouté",
                        timeToClose: 3000,
                        type: 'success'
                    }));
                }),
                catchError((err) => {
                    return of(ErrorDriver(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorDriver(err.msg));
        })
    );

export const GetDriversEpic = (
    action$: ActionsObservable<GetDriverStartActionType>,
    state$: { value: RootState }
): Observable<DriverActionsType> =>
    action$.pipe(
        ofType(GET_START),
        switchMap((action: GetDriverStartActionType) => {
            return from(getDrivers(action.payload)).pipe(
                map((result) => {
                    return GetDriverEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorDriver(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorDriver(err.msg));
        })
    );

export const GetDriverEpic = (
    action$: ActionsObservable<GetDriverUpdateStartType>,
    state$: { value: RootState }
): Observable<DriverActionsType> =>
    action$.pipe(
        ofType(GET_DRIVER_UPDATE_START),
        switchMap((action: GetDriverUpdateStartType) => {
            return from(getDriver(action.payload)).pipe(
                map((result) => {
                    return GetDriverUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorDriver(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorDriver(err.msg));
        })
    );

    export const DeleteDriverEpic = (
        action$: ActionsObservable<DeleteDriverStartType>,
        state$: { value: RootState }
    ): Observable<DeleteDriverStartType | any> =>
        action$.pipe(
            ofType(DELETE_DRIVERS_START),
            switchMap((action: DeleteDriverStartType) => {
                return from(deleteDrivers({keys: action.payload})).pipe(
                    mergeMap((result) => {
                        return of(
                            DeleteDriverEnd({
                                ...result.data,
                                keys: action.payload,
                            }),
                            SetNotificationAction({
                                title: "success",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }),
                    catchError((err) => {
                        return of(ErrorDriver(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorDriver(err.msg));
            })
        );

    export const BindDriverEpic = (
        action$: ActionsObservable<BindDriverStartType>,
        state$: { value: RootState }
    ): Observable<DriverActionsType | any> =>
        action$.pipe(
            ofType(BIND_DRIVERS_START),
            switchMap((action: BindDriverStartType) => {
                return from(bindDriver(action.payload)).pipe(
                    switchMap((result) => {
                        Emitter.emit(EmitterActions.REFRESH_DRIVER_INFO, "");
                        return of(
                            BindDriverEnd({...result}),
                            SetRedirectAction(`/drivers/${action.payload.driver}/details`),
                            SetNotificationAction({
                                title: "success",
                                "message": 'Conducteur assigné avec succès',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }),
                    catchError((err) => {
                        return of(ErrorDriver(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorDriver(err.msg));
            })
        );
            
            export const UnbindDriverEpic = (
                action$: ActionsObservable<UnbindDriverStartType>,
                state$: { value: RootState }
            ): Observable<any> =>
                action$.pipe(
                    ofType(UNBIND_DRIVERS_START),
                    switchMap((action: UnbindDriverStartType) => {
                        return from(unbindDriver(action.payload)).pipe(
                            switchMap((result) => {
                                Emitter.emit(EmitterActions.REFRESH_DRIVER_INFO, "");
                                return  of(
                                    UnbindDriverEnd({...result, key: action.payload.key}),
                                    SetNotificationAction({
                                        title: "success",
                                        "message": 'Conducteur détaché avec succès',
                                        timeToClose: 3000,
                                        type: 'success'
                                    }) 
                                );
                            }),
                            catchError((err) => {
                                return of(ErrorDriver(err.msg));
                            })
                        );
                    }),
                    catchError((err) => {
                        return of(ErrorDriver(err.msg));
                    })
                );

    export const GetUnitToAssignDriverEpic = (
        action$: ActionsObservable<GetUnitToAssignDriverStartActionType>,
        state$: { value: RootState }
    ): Observable<DriverActionsType> =>
        action$.pipe(
            ofType(GET_UNIT_TO_ASSIGN_START),
            switchMap((action: GetUnitToAssignDriverStartActionType) => {
                return from(getUnitsToAssign(action.payload)).pipe(
                    map((result) => {
                        return GetUnitToAssignDriverEnd({result: {list: result}, filter: action.payload});
                    }),
                    catchError((err) => {
                        return of(ErrorDriver(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorDriver(err.msg));
            })
        );
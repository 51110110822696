import { ManageUserType, FilterUserType, GetReportsResponse, UserRoleItem, NominationUserType } from "./types";

export const TOOGLE_AFFECT_TO = '[REPORT] TOOGLE_AFFECT_TO';
export type ToogleAffectToActionType = {
    type: typeof TOOGLE_AFFECT_TO;
    
};
export function ToogleAfectToAction(): ToogleAffectToActionType {
    return { type: TOOGLE_AFFECT_TO };
}

export const SELECT_OBJECT = '[REPORT] SELECT_OBJECT';
export type SelectObjectActionType = {
    type: typeof SELECT_OBJECT;
    payload: string[];
    
};
export function SelectObjectAction(payload: string[]): SelectObjectActionType {
    return { type: SELECT_OBJECT, payload };
}

export const SET_DATE_REPORT = '[REPORT] SET_DATE_REPORT';
export type SetDateReportActionType = {
    type: typeof SET_DATE_REPORT;
    payload: Date[];
    
};
export function SetDateReportAction(payload: Date[]): SetDateReportActionType {
    return { type: SET_DATE_REPORT, payload };
}


export const GENERATE_REPORT_START = '[REPORT] GENERATE_REPORT_START';
export type GenerateReportStartType = {
    type: typeof GENERATE_REPORT_START;
};
export function GenearateReportStart(): GenerateReportStartType {
    return { type: GENERATE_REPORT_START };
}

export const GENERATE_REPORT_END = '[REPORT] GENERATE_REPORT_END';
export type GenerateReportEndType = {
    type: typeof GENERATE_REPORT_END;
    payload: any;
};
export function GenearateReportEnd(
    payload: any
): GenerateReportEndType {
    return { type: GENERATE_REPORT_END, payload };
}

export const DOWNLOAD_REPORT_START = '[REPORT] DOWNLOAD_REPORT_START';
export type DownloadReportStartType = {
    type: typeof DOWNLOAD_REPORT_START;
    payload: any;
};
export function DownloadReportStart(
    payload: any
): DownloadReportStartType {
    return { type: DOWNLOAD_REPORT_START, payload };
}

export const DOWNLOAD_REPORT_END = '[REPORT] DOWNLOAD_REPORT_END';
export type DownloadReportEndType = {
    type: typeof DOWNLOAD_REPORT_END;
    payload: any;
};
export function DownloadReportEnd(
    payload: any
): DownloadReportEndType {
    return { type: DOWNLOAD_REPORT_END, payload };
}

export const SET_TYPE_REPORT = '[REPORT] SET_TYPE_REPORT';
export type SetTypeReportType = {
    type: typeof SET_TYPE_REPORT;
    payload: string;
};
export function SetTypeReport(
    payload: string
): SetTypeReportType {
    return { type: SET_TYPE_REPORT, payload };
}

export const ERROR = '[REPORT] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorReport(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_REPORTS_START = '[REPORT] GET_REPORTS_START';
export type GetReportsStartType = {
    type: typeof GET_REPORTS_START;
    payload: FilterUserType;
};
export function GetReportsStart(payload: FilterUserType): GetReportsStartType {
    return { type: GET_REPORTS_START, payload };
}

export const GET_REPORTS_END = '[REPORT] GET_REPORTS_END';
export type GetReportsEndType = {
    type: typeof GET_REPORTS_END;
    payload: { result: GetReportsResponse; filter: FilterUserType };
};
export function GetReportsEnd(payload: {
    result: GetReportsResponse;
    filter: FilterUserType;
}): GetReportsEndType {
    return { type: GET_REPORTS_END, payload };
}

export const SET_SELECTED_USERS = '[USER] SET_SELECTED_USERS';
export type SetSelectedUsersType = {
    type: typeof SET_SELECTED_USERS;
    payload: string[];
};
export function SetSelectedUsers(payload: string[]): SetSelectedUsersType {
    return { type: SET_SELECTED_USERS, payload };
}

export const DELETE_USERS_START = '[USER] DELETE_USERS_START';
export type DeleteUsersStartType = {
    type: typeof DELETE_USERS_START;
    payload: string[];
};
export function DeleteUsersStart(payload: string[]): DeleteUsersStartType {
    return { type: DELETE_USERS_START, payload };
}

export const DELETE_USERS_END = '[USER] DELETE_USERS_END';
export type DeleteUsersEndType = {
    type: typeof DELETE_USERS_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteUsersEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteUsersEndType {
    return { type: DELETE_USERS_END, payload };
}

export const CLEAR_USER_CREATION = '[USER] CLEAR_USER_CREATION';
export type ClearUserCreationActionType = {
    type: typeof CLEAR_USER_CREATION;
};
export function ClearUserCreation(): ClearUserCreationActionType {
    return { type: CLEAR_USER_CREATION };
}

export const GET_USER_UPDATE_START = '[USER] GET_USER_UPDATE_START';
export type GetUserUpdateStartType = {
    type: typeof GET_USER_UPDATE_START;
    payload: { _id: string };
};
export function GetUserUpdateStart(payload: {
    _id: string;
}): GetUserUpdateStartType {
    return { type: GET_USER_UPDATE_START, payload };
}

export const GET_USER_UPDATE_END = '[USER] GET_USER_UPDATE_END';
export type GetUserUpdateEndType = {
    type: typeof GET_USER_UPDATE_END;
    payload: { result: ManageUserType };
};
export function GetUserUpdateEnd(payload: {
    result: ManageUserType;
}): GetUserUpdateEndType {
    return { type: GET_USER_UPDATE_END, payload };
}

export const GET_USER_ROLE_START = '[USER] GET_USER_ROLE_START';
export type GetUserRoleStartType = {
    type: typeof GET_USER_ROLE_START;
};
export function GetUserRoleStart(): GetUserRoleStartType {
    return { type: GET_USER_ROLE_START };
}

export const GET_USER_ROLE_END = '[USER] GET_USER_ROLE_END';
export type GetUserRoleEndType = {
    type: typeof GET_USER_ROLE_END;
    payload: any;
};
export function GetUserRoleEnd(payload: any): GetUserRoleEndType {
    return { type: GET_USER_ROLE_END, payload };
}

export const CHANGE_USER_ROLE_ITEM = '[USER] CHANGE_USER_ROLE_ITEM';
export type ChangeUserRoleItemType = {
    type: typeof CHANGE_USER_ROLE_ITEM;
    payload: UserRoleItem;
};
export function ChangeUserRoleItem(
    payload: UserRoleItem
): ChangeUserRoleItemType {
    return { type: CHANGE_USER_ROLE_ITEM, payload };
}

export const CHANGE_USER_TYPE = '[USER] CHANGE_USER_TYPE';
export type ChangeUserTypeType = {
    type: typeof CHANGE_USER_TYPE;
    payload: string;
};
export function ChangeUserType(payload: string): ChangeUserTypeType {
    return { type: CHANGE_USER_TYPE, payload };
}

export const SET_USER_CONTACT_TYPE = '[USER] SET_USER_CONTACT_TYPE';
export type SetUserContactActionType = {
    type: typeof SET_USER_CONTACT_TYPE;
    payload: { mobile?: string; email?: string };
};
export function SetUserContactAction(payload: {
    mobile?: string;
    email?: string;
}): SetUserContactActionType {
    return { type: SET_USER_CONTACT_TYPE, payload };
}

export const SET_NOMINATION_USER_TYPE = '[USER] SET_NOMINATION_USER_TYPE';
export type SetNominationUserActionType = {
    type: typeof SET_NOMINATION_USER_TYPE;
    payload: NominationUserType;
};
export function SetNominationUserAction(
    payload: NominationUserType
): SetNominationUserActionType {
    return { type: SET_NOMINATION_USER_TYPE, payload };
}



export const ASSIGN_VEHICLE_USER_START = '[VEHIVLE] ASSIGN_VEHICLE_USER_START';
export type SetVehicleAssignStartActionType = {
    type: typeof ASSIGN_VEHICLE_USER_START;
    payload: string[];
};
export function AssignVehicleUserStartAction(
    payload: string[]
): SetVehicleAssignStartActionType {
    return { type: ASSIGN_VEHICLE_USER_START, payload };
}

export const ASSIGN_VEHICLE_USER_END = '[VEHIVLE] ASSIGN_VEHICLE_USER_END';
export type SetVehicleAssignEndActionType = {
    type: typeof ASSIGN_VEHICLE_USER_END;
    payload: {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount: number;
    };
};
export function AssignVehicleUserEndAction(payload: {
    _id: string;
    updatedBy: string;
    updatedAt: string;
    modifiedCount: number;
}): SetVehicleAssignEndActionType {
    return { type: ASSIGN_VEHICLE_USER_END, payload };
}

export type UserActionType =
    | SelectObjectActionType
    | SetDateReportActionType
    | GenerateReportStartType
    | GenerateReportEndType
    | SetTypeReportType
    | ErrorActionType
    | GetReportsStartType
    | GetReportsEndType
    | DownloadReportStartType
    | DownloadReportEndType

    | SetSelectedUsersType
    | DeleteUsersStartType
    | DeleteUsersEndType
    | ClearUserCreationActionType
    | GetUserUpdateStartType
    | GetUserUpdateEndType
    | GetUserRoleStartType
    | GetUserRoleEndType
    | ChangeUserRoleItemType
    | ChangeUserTypeType
    | SetUserContactActionType
    | SetNominationUserActionType
    | ToogleAffectToActionType
    | SetVehicleAssignStartActionType
    | SetVehicleAssignEndActionType;

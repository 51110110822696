import classNames from "classnames";
import { useCallback, useState } from "react";
import { AiOutlineUnorderedList, AiOutlineEyeInvisible } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { CardHeader } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { SetOptionMapAction } from "../../../redux/rootActions";
import FetchReportForm, { FilterData } from "./fetch-report-form";
import { IF } from "../../../Components/If";
import SelectUnit from "./select-unit";
import ReportList from "./report-list";
import { useFetchReportItems } from "../../../common/hooks/axios/report/useFetchReportItems";
import ReportDetail from "./report-detail/report-detail";


const ReportPage = () => {
    const dispatch = useDispatch(); 
    const [hide, setHide] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<FilterData>();
    const [selectedReport, setSelectedReport] = useState<string[]>([]);
    const [selectedUnit, setSelectedUnit] = useState<{label: string, value: string} | null>();
    const { reportItems, fetchReportItems, initReportItems } = useFetchReportItems({manual: true});

    // Events
    const toggleHide = () => {
        setHide(!hide);
        let padding = 0;
        if(hide) { padding = padding + 374; }

        dispatch(SetOptionMapAction({opt: 'padding', value: padding}))
    };

    // Functions
    const getFilteredData = useCallback((data: FilterData)=> {
        setFilterData(data);
        fetchReportItems(data.template);
    }, []);

    const toggleReport = (itemId: string, action: 'add' | 'remove')=> {
        
        if(action === 'add') {
            if(selectedReport.length < 2) {
                const newSelectedReport = [...selectedReport];
                newSelectedReport.push(itemId)
                setSelectedReport(newSelectedReport);
            }

        } else if(action === 'remove') {
            setSelectedReport(selectedReport.filter(id => id !== itemId))
        }
    };

    const onReset = () => {
        initReportItems();
        setSelectedReport([]);
    }


    // Vars
    const showReport = reportItems.list.length > 0;

    const reportClassname = classNames({
        [`d-none`]: hide,
        'align-self-start': true
    });

    return (
        <div className="page-content report-snap">
            <div className="report-snap-body">
                <ButtonUi 
                    onClick={toggleHide}
                    className={hide ? 'align-self-start' : 'd-none'} color={"primary"} Icon={AiOutlineUnorderedList}
                />
                <CardUi className={reportClassname} style={{ minWidth: 400}}>
                    <LoaderUi loading={false}/>
                    <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                        <h5 className="d-flex align-items-center mb-0 text-primary">
                            <AiOutlineUnorderedList className="me-2" />
                            <span><FormattedMessage id={"REPORT"}/></span>
                        </h5>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={toggleHide}
                            onKeyDown={toggleHide}
                        >
                            <AiOutlineEyeInvisible size={20} />
                        </div>
                    </CardHeader>
                    <CardBodyUi className="p-0">
                        <FetchReportForm 
                            onSubmit={getFilteredData} 
                            onReset={onReset} 
                            onChangeItems={setFilterData}
                            onChangeTimes={setFilterData}
                            disabled={showReport}
                        />
                        
                        <IF condition={showReport}>
                            <SelectUnit 
                                units={filterData?.selectedItems ?? []} 
                                onChange={setSelectedUnit}
                            />
                        </IF>

                        <IF condition={showReport}>
                            <ReportList 
                                items={reportItems.list}
                                selected={selectedReport}
                                toggle={toggleReport}
                            />
                        </IF>      
                    </CardBodyUi>
                </CardUi>
                
                {
                    selectedUnit && filterData && showReport && selectedReport.length > 0 && 
                    <div className="ms-2" style={{zIndex: 999, flex: 1, overflow: "scroll"}}> 
                        {
                            [...selectedReport].reverse().map((reportId, position) => <ReportDetail 
                                key={reportId}
                                template={filterData?.template} 
                                reportId={reportId} 
                                item={selectedUnit} 
                                range={{
                                    from: filterData.rangeTime[0].toISOString(),
                                    to: filterData.rangeTime[1].toISOString()
                                }}
                                isFull={ selectedReport.length === 1}
                                position={position}
                                close={()=> toggleReport(reportId, 'remove')}
                            />)
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default ReportPage;
import EventEmitter from 'eventemitter3';
enum EventActions {
  "DELETE_POPUP" = "DELETE_POPUP",
  "STOP:UPDATE" = "STOP:UPDATE",
  "STOP:UPDATE_FORM" = "STOP:UPDATE_FORM",
  "STOP:DRAW_ALL" = "STOP:DRAW_ALL",
  "STOP:LOCALISE" = "STOP:LOCALISE",
  // SOCKET_IO notifications
  "IO:NEW_NOTIFICATION" = "IO:NEW_NOTIFICATION"
}

const eventEmitter = new EventEmitter();

const Emitter = {
  event: EventActions,
  on: (event: any, fn: any) => eventEmitter.on(event, fn),
  once: (event: any, fn: any) => eventEmitter.once(event, fn),
  off: (event: any, fn: any) => eventEmitter.off(event, fn),
  emit: (event: any, payload: any) => eventEmitter.emit(event, payload)
}

Object.freeze(Emitter);

export default Emitter;


import { Method } from "axios";
import { useState, useRef, useEffect } from "react";
import { axiosInstance } from "../../../redux/setupAxios";

export function useAxios<T>(manual = false, url?: string, options?: {method: Method, params?: any, data?: any}) {
  const [data, setData] = useState<T | null>(null);
  const [status, setStatus] = useState<number| null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const controllerRef = useRef(new AbortController());
  const cancel = () => {
    controllerRef.current.abort();
  }
  const init = () => {
    setData(null);
  }
  const execute = async (url: string, options: {method: Method, params?: any, data?: any}) => {
    setLoading(true);
    try {
      const response = await axiosInstance.request({
        signal: controllerRef.current.signal,
        url,
        ...options
      });

      setData(response.data);
      setStatus(response.status);
      setLoading(false);
      return {success: response.data };
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
      return {error: error.message };
    }
    setLoading(false);
  }

  useEffect(() => {
    if(!manual && !!url && !!options) { execute(url, options); }
  }, []);

  return { cancel, execute, status, data, error, loading, init };
}

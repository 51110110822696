import { Rate, RateProps } from "antd";
import classNames from "classnames";

type RateUiComponentProps = {
    mark: number,
    size?: number,
    allowColor?: boolean,
} & Omit<RateProps, 'value'>
export const RateUiComponent = ({mark, allowColor, size, ...other}: RateUiComponentProps) => {
    const rateClassName = classNames({
        'text-success': allowColor && mark >=4,
        'text-warning': allowColor && mark >=2.5 && mark <4,
        'text-danger': allowColor && mark <2.4,
    })
    
    return (
        <Rate 
            value={mark ?? 0}
            style={{fontSize: size ?? 18}} 
            className={rateClassName} 
            {...other}
        />
    );
}
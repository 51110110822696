import { useIntl } from "react-intl";
import { AiOutlineIdcard } from "react-icons/ai";
import { Avatar } from "../../../Components/basic/avatar/avatar";
import { UncontrolledTooltip } from "reactstrap";
import { FiExternalLink } from "react-icons/fi";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { PassengerAction } from "../types";

type PassengerListItemProps = {
    item: {
        key: string,
        name: string,
        identifier: string,
    },
    onEvent?: (action: string, data?: any) => void
}


export const PassengerListItem = (props: PassengerListItemProps): JSX.Element => {

    const intl = useIntl();
    
    const onClick = () => {
        if(props.onEvent) {
            props.onEvent(PassengerAction.DETAILS, props.item.key)
        }
    }
    // return (
    //     <div className="py-2 px-3 d-flex flex-column justify-content-center border-bottom">
    //         <h6 style={{fontWeight: 500}} className="mb-0 d-flex justify-content-between align-items-center">
    //             <div className="d-flex flex-row align-items-center">
    //                 <GiBusStop className="me-1 text-primary" size={20} /> 
    //                 <span className="text-capitalize">
    //                 { props.item.name}
    //             </span>
    //             </div>
                
    //             {
    //                 props.onEvent && 
                    
    //                 <ActionTableDropdown 
    //                     _id={props.item.key} 
    //                     onChange={onClick}
    //                     options={actions}
    //                 />
    //             }
    //         </h6>
    //         <span className="text-muted fs-12">
    //             { props.item.description ?? "-"}
    //         </span>
            
    //     </div>
    // );  
    return (
        <div className="object-info py-2 px-3 d-flex align-items-center justify-content-between border-bottom">
            <div className="d-flex align-items-center flex-grow-1">
                <Avatar 
                    text={props.item.name}
                    size="sm" 
                    color="primary" 
                    className="me-2"
                    rounded='rounded-circle'
                />
                <div className="flex-grow-1">
                    <div className='d-flex flex-row justify-content-between align-items-center flex-grow-1'>
                        <h6 className="mb-0">
                            <SliceText text={props.item.name.toLowerCase()} limit={20}/>
                            {/*props.item.identifier && <BadgeUi className="ms-2" color={'success'} light>Affecté</BadgeUi>*/}
                        </h6>
                        {
                            onClick && <ButtonUi 
                            color="secondary" 
                            ghost 
                            size='sm'
                            onClick={onClick}
                        >
                                <>Voir détails <FiExternalLink/></>
                        </ButtonUi>
                        }
                        
                    </div>

                    {
                        props.item.identifier && <div className="d-flex">
                            <span className="text-muted fs-10">
                                <AiOutlineIdcard/> { props.item.identifier }
                            </span>
                    </div>
                    }
                </div>
            </div>
        </div>
    )    
}

export default PassengerListItem;
const SliceText = ({text, limit}: {text: string, limit: number}) => {
    const id = text.replaceAll(" ", "");
    if(text.length > limit) {
        return <>
            <span id={`passenger-${id}`}>
                {`${text.slice(0, limit)}...`}
            </span>
            <UncontrolledTooltip target={`passenger-${id}`}>
                { text }
            </UncontrolledTooltip>
        </>
    }
    return <>{text}</>
}
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, CardHeader, TabContent, TabPane} from "reactstrap";
import { Acl } from "../../../../Components/Acl";
import { BreadCrumbActionUi } from "../../../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../../../Components/basic/Card";
import { CardBodyUi } from "../../../../Components/basic/CardBodyUi";
import { Tabs } from "../../../../Components/Tabs";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearVehicleCreation } from "../../redux/vehicleModule.actions";
import CustomFields from "../CustomFields";
import { ManageVehicleBasicInfo } from "../ManageBasicInfo";
import { Sensors } from "../Sensors";
import { TripConfiguration } from "../Trip";
import { VehicleInfo } from "./VehicleInfo";

const UpdateVehiclePage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const [activeNav, setActiveNav] = useState(0);
    const { user } = useAppSelector(
        (state) => state.authReducer
    );
    const onChangeNav = (active: number) => {
        setActiveNav(active);
    }
    const { loading } = useAppSelector((state) => state.vehicleModuleReducer);
    
    useEffect(() => {
        return function cleanup() {
            dispatch(ClearVehicleCreation());
        };
    }, []);

    return (
        <div id="vehicleDetails" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'UNIT' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Row>
                    <Col xl={3}>
                        <VehicleInfo/>
                    </Col>
                    <Col xl={9}>
                        <CardUi>
                            <CardHeader className="position-relative">
                                <Tabs isCustom color="primary" className="card-header-tabs border-bottom-0" onChange={onChangeNav}>
                                    <div>Général</div>
                                    <div>Champs personnalisés</div>
                                    {/* <div>Limiteur de vitesse</div> */}
                                    <Acl type="admin"><div>Capteurs</div></Acl>
                                    <Acl type="admin">
                                        <div>Détécteurs de trajet</div>
                                    </Acl>
                                </Tabs>

                            </CardHeader>
                            <CardBodyUi className="p-4"> 
                                <TabContent activeTab={`${activeNav}`}>
                                    <TabPane tabId="0">
                                        <ManageVehicleBasicInfo/>
                                    </TabPane>
                                    <TabPane tabId="1" id="account-general">
                                        <CustomFields />
                                    </TabPane>
                                    {/*<TabPane tabId="2" id="account-general">
                                        <SpeedLimit/>
                                    </TabPane> */}
                                    <Acl type="admin">
                                        <TabPane tabId="2" id="account-general">
                                        <Sensors/>
                                        </TabPane>
                                        </Acl>
                                        <Acl type="admin">
                                            <TabPane tabId="3" id="account-general">
                                                <TripConfiguration/>
                                            </TabPane>
                                        </Acl>
                                    
                                </TabContent>
                            </CardBodyUi> 
                        </CardUi>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default UpdateVehiclePage;
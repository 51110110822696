import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Col, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { AsyncInputSelect } from "../../../Components/Form/AsyncInputSelect";
import { InputFlatPickrUi } from "../../../Components/Form/InputFlatPickr";
import Emitter from "../../../helpers/emitter";
import EmitterActions from "../../../helpers/emitter/emitter.actions";
import { useAxios } from "../../../common/hooks/axios/useAxios";
import { SetNotificationAction } from "../../../redux/rootActions";
import { axiosInstance } from "../../../redux/setupAxios";
import { getVehiclesForMaintenance } from "../../Maitenance/_redux/maintenance.service";



type UnitType = { _id: string; label: string; value: string; }
export const DriverRegistrationShift = () => {
    
    const intl = useIntl();
    const [queryParameters] = useSearchParams();
    const dispatch = useDispatch()
    const { key } = useParams();
    const navigate = useNavigate();
    const [ unit, setUnit ] = useState<UnitType>();
    const [ range, setRange ] = useState<Date[]>();
    const [maxDate, setMaxDate] = useState(new Date())

    const toggle = () => navigate(`?assignments=1`);
    const { execute, loading, status } = useAxios(true);


    const fetchUnits = async (inputValue: string) => getVehiclesForMaintenance({ name: inputValue });
    const updateUnit = (payload: any) => { setUnit(payload) };
    const fetchDateLimit = async () => {
        let url = `/driver/${key}/date-limit/${unit?.value}`;
       
        try {
            const { data } = await axiosInstance.get(url);
           setMaxDate(new Date(data.limit));
            } catch (error) {
                dispatch(SetNotificationAction({
                    title: "Erreur interne",
                    "message": 'Un problème interne est survenu',
                    timeToClose: 3000,
                    type: 'error'
                }));
            }
      };

    const submit = () => { 
        if(unit && range && range.length >= 2) {
            execute(`/driver/${key}/shift/${unit?.value}`, {method: 'POST', data: {
                start: range[0],
                end: range[1]
            }});
        }
    };

    useEffect(()=> {
        if(status && loading === false) {
            if(status <= 204) {
                dispatch(SetNotificationAction({
                    title: "Ajoutée",
                    "message": 'Afféctation ajoutée avec succès',
                    timeToClose: 3000,
                    type: 'success'
                }));
                Emitter.emit(EmitterActions.REFRESH_ASSIGNMENT_LIST, '');
                Emitter.emit(EmitterActions.REFRESH_DRIVER_ECO, '');
                navigate('?assignments=1');
            } else {
                dispatch(SetNotificationAction({
                    title: "Erreur",
                    "message": 'Un problème interne est survenu, veuillez ressayer plus tard',
                    timeToClose: 3000,
                    type: 'error'
                })); 
            }

        }
    }, [loading]);
    
    const changeStart = (dates: Date[]) => {
        if(dates.length > 1) { setRange(dates); }  
    }



    const isOpen = !!queryParameters.get('assign');
    useEffect(()=> {
        if(!isOpen) {
            setRange(undefined);
            setUnit(undefined);
            setMaxDate(new Date())
        }
    }, [isOpen]);



    useEffect(()=> {
        if(unit) {
            fetchDateLimit();
        }
    }, [unit])
    
    return (
        <Modal isOpen={isOpen} fullscreen={"sm"} toggle={toggle}>
            <LoaderUi loading={loading} />
            <ModalHeader className="border" toggle={toggle}>
                <h5 className="mb-0">
                    Afféctation manuelle du conducteur
                </h5>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            {
                                <AsyncInputSelect 
                                    label={intl.formatMessage({ id: 'COMMON.UNIT'})}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={[]}
                                    error={!unit}
                                    loadOptions={fetchUnits}
                                    onChange={updateUnit}
                                    onOpen={()=> setMaxDate(new Date())}
                                />
                            }
                        </FormGroup>
                    </Col>
                    
                    <Col md={12}>
                        <FormGroup>
                            <InputFlatPickrUi
                                className="form-control"
                                value={ range }
                                label="Début de l'afféctation"
                                placeholder="Date début"
                                options={{
                                    mode: 'range',
                                    enableTime: true,
                                    dateFormat: "Y-m-d H:i",
                                    maxDate: maxDate 
                                }}
                                onChange={changeStart}
                                error={!range || range.length < 2}
                            />
                        </FormGroup>
                        
                    </Col>

                    

                    <Col md={12}>
                        <div className="hstack gap-2 justify-content-end">
                            <ButtonUi color={"primary"} onClick={submit} disabled={!range || !unit}>
                                <FormattedMessage id="COMMON.ADD"/>
                            </ButtonUi> 
                        </div>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>

 
    );
}
import { useEffect } from "react";
import { useAxios } from "../useAxios";

export type ReportTemplate = {
  "value": string, "label": string, "target":"unit"
}
type PassengerToAssignResult = {
    key: string;
    group: string;
    data: { label: string, value: string }[];
}

type useFetchPassengersToAssignResponse = { 
  passengersToAssign: PassengerToAssignResult[];
  passengersToAssignLoading: boolean;
  error?: string;
  fetchPassengersToAssign: (inputValue: string) => void;
  cancel: () => void;
}

type useFetchPassengersToAssignProps = {
  manual?: boolean
}
export function useFetchPassengersToAssign(props: useFetchPassengersToAssignProps): useFetchPassengersToAssignResponse {
  const URL = "passengers/to-assign";
  const INIT_VALUE: PassengerToAssignResult[] = [];
  const { execute, data, cancel, error, loading } = useAxios<PassengerToAssignResult[]>();

  useEffect(() => {
    fetchPassengersToAssign();
  }, [])

  const fetchPassengersToAssign = async (inputValue?: string) => {
    execute(URL, { method: "GET", params: { name: inputValue } });
  };

  return { 
    passengersToAssign: data ?? INIT_VALUE, 
    fetchPassengersToAssign, 
    cancel, 
    passengersToAssignLoading: loading, 
    error: error
  };
}

import {  useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollBar } from "../../../../Components/PerfectScrollBar";
import InfoDetail  from "../../../../Components/advanced/Object-info/Detail/InfoDetail";
import TripIcon from "../../../../Components/advanced/Object-info/Icon/tripIcon";
import TripText from "../../../../Components/advanced/Object-info/TripText";
import { FaMapMarkerAlt, FaSatelliteDish } from "react-icons/fa";
import { IoIosSpeedometer } from "react-icons/io";
import classNames from "classnames";
import ConnexionIcon from "../../../../Components/advanced/Object-info/Icon/connexionIcon";
import { SignalGsmIcon, SignalGsmText } from "../../../../Components/advanced/Object-info/Icon/SignalGsmIcon";
import { DynamicFaIcon } from "../../../../Components/Icon/dynamic-icon";
import { VisibleSensor } from "./_redux/type";
import { Address } from "../../../../Components/Address";
import { GiCaptainHatProfile, GiElectric } from "react-icons/gi";
import { MdBatteryChargingFull } from "react-icons/md";
import IgnitionIcon from "../../../../Components/advanced/Object-info/Icon/ignitionIcon";
import React from "react";

export const ObjectDetails = ({object, sensors, display, sharedMap}: {object: any, sensors?: VisibleSensor[], display: boolean, sharedMap?: boolean}) => {
    const refScrollBar = useRef();
    const intl = useIntl();
    const { baseInfo, online, lastTrip } = object;
    const detailsClass = classNames({
        [`d-none`]: !display,
        [`mt-2`]: true,
    });

    const ignitionStatus = (typeof baseInfo?.ignition === 'boolean' &&  baseInfo?.ignition) || (typeof baseInfo?.ignition === 'string' &&  baseInfo?.ignition === 'true');

    return (
        <div className={detailsClass}>
            <ScrollBar
                id="popup-content-scrollbar"
                minusHeight={200}
                containerRef={refScrollBar}
            >
                <InfoDetail 
                    icon={<FaMapMarkerAlt color="gray" size={18} />} 
                    title={`${intl.formatMessage({ id: 'COMMON.ADDRESS' })}`}
                >
                    <Address coordinates={[baseInfo?.latitude, baseInfo?.longitude]}/>
                </InfoDetail>
                {
                    object.driver && 
                        <InfoDetail 
                            icon={<GiCaptainHatProfile color="gray" size={18} />} 
                            title={`${intl.formatMessage({ id: 'COMMON.DRIVER' })}`}
                        >
                            <span>{object.driver}</span>
                        </InfoDetail>
                }
                

                <InfoDetail 
                    icon={<ConnexionIcon size={18} enable={!online} />} 
                    title={`${intl.formatMessage({ id: 'COMMON.CNX_STATUS' })}`}
                >
                    {
                        !online ? (
                            <FormattedMessage id="COMMON.ONLINE" />
                            ) : (
                            <FormattedMessage id="COMMON.OFFLINE" />
                        )
                    }
                </InfoDetail>
        
                {
                    lastTrip && <InfoDetail 
                    icon={<TripIcon size={18} trip={lastTrip} />} 
                    title={"État du trajet"}
                >
                    <TripText {...lastTrip} />
                    </InfoDetail>
                }
                

                {
                    !!baseInfo && (baseInfo.signalNetwork !== undefined) &&  
                        <InfoDetail 
                            icon={<SignalGsmIcon signalValue={+baseInfo.signalNetwork} size={18} />} 
                            title={`${intl.formatMessage({ id: 'COMMON.SIGNAL_GSM' })}`}
                        >
                            <FormattedMessage id={SignalGsmText({signalValue: +baseInfo.signalNetwork})} /> ({baseInfo.signalNetwork}%)
                        </InfoDetail>   
                }

                {
                    !!baseInfo && (baseInfo?.ignition !== undefined) &&  
                        <InfoDetail 
                            icon={<IgnitionIcon size={14} enable={ignitionStatus}/>} 
                            title={`${intl.formatMessage({ id: 'COMMON.IGNITION' })}`}
                        >
                            { ignitionStatus ? "En marche" : "En arrêt" }
                        </InfoDetail> 
                }

                {
                !!baseInfo && (baseInfo.speed || +baseInfo.speed > 0) &&  
                    <InfoDetail 
                        icon={<IoIosSpeedometer color="gray" size={20} />} 
                        title={`${intl.formatMessage({ id: 'COMMON.SPEED' })}`}
                    >
                        { baseInfo.speed } km/h
                    </InfoDetail>  
                }

                {
                    sensors?.filter(sensor => !!baseInfo?.[sensor.param]).map(sensor => {
                        return (<InfoDetail 
                            icon={<DynamicFaIcon name={sensor.icon} color="gray" size={20} />} 
                            title={`${sensor.name}`}
                        >
                            { typeof baseInfo?.[sensor.param]  !==  "number"  ? baseInfo?.[sensor.param] : baseInfo?.[sensor.param].toFixed(2) } {sensor.unit}
                        </InfoDetail> )
                    })
                }


                {
                    !!baseInfo && (baseInfo.satellite || +baseInfo.satellite >= 0) &&  
                        <InfoDetail 
                            icon={<FaSatelliteDish color="gray" size={20} />} 
                            title={`${intl.formatMessage({ id: 'COMMON.SATELITE' })}`}
                        >
                            { baseInfo.satellite }
                        </InfoDetail> 
                }

                {
                    (!!baseInfo && (baseInfo.unitVoltage !== undefined)) && 
                        <InfoDetail 
                            icon={<GiElectric color="gray" size={20} />} 
                            title={`${intl.formatMessage({ id: 'COMMON.UNIT_VOLTAGE' })}`}
                        >
                            { baseInfo.unitVoltage ?? '-' } mv
                        </InfoDetail> 
                }

                {
                    !!baseInfo && (baseInfo.batteryVoltage !== undefined) &&  
                        <InfoDetail 
                            icon={<MdBatteryChargingFull color="gray" size={20} />} 
                            title={`${intl.formatMessage({ id: 'COMMON.UNIT_BATTERY_VOLTAGE' })}`}
                        >
                            { baseInfo.batteryVoltage ?? '-' } mv
                        </InfoDetail> 
                }

            </ScrollBar>
        </div>
        
                   
    );
}

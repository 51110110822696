import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from '../../../redux/store';
import {
    BillingActionType,
    CreateBillingEnd,
    CreateBillingStartType,
    CREATE_BILLING_START,
    DeleteBillingsEnd,
    DeleteBillingsStartType,
    DELETE_BILLINGS_START,
    ErrorBillings,
    GetBillingsEnd,
    GetBillingsStartType,
    GetBillingUpdateEnd,
    GetBillingUpdateStartType,
    GET_BILLINGS_START,
    GET_BILLING_UPDATE_START
} from './billing.actions';
import {
    deleteBillings,
    getBilling,
    getBillings,
    saveBilling,
    updateBilling
} from './billing.service';

export const CreateBillingEpic = (
    action$: ActionsObservable<CreateBillingStartType>,
    state$: { value: RootState }
): Observable<BillingActionType> =>
    action$.pipe(
        ofType(CREATE_BILLING_START),
        switchMap((action: CreateBillingStartType) => {
            const { manage } = state$.value.billingReducer;
            return from(
                manage._id
                    ? updateBilling({ ...manage, ...action.payload })
                    : saveBilling({ ...manage, ...action.payload })
            ).pipe(
                map((result) => {
                    const newManage = {
                        ...state$.value.billingReducer.manage,
                        ...result,
                        ...{
                            _id: manage._id ? manage._id : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                        },
                    };

                    return CreateBillingEnd(newManage);
                }),
                catchError((err) => {
                    return of(ErrorBillings(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorBillings(err.msg));
        })
    );

export const GetBillingsEpic = (
    action$: ActionsObservable<GetBillingsStartType>,
    state$: { value: RootState }
): Observable<BillingActionType> =>
    action$.pipe(
        ofType(GET_BILLINGS_START),
        switchMap((action: GetBillingsStartType) => {
            return from(getBillings(action.payload)).pipe(
                map((result) => {
                    return GetBillingsEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorBillings(err.msg));
                })
            );
            
        }),
        catchError((err) => {
            return of(ErrorBillings(err.msg));
        })
    );

export const GetBillingEpic = (
    action$: ActionsObservable<GetBillingUpdateStartType>,
    state$: { value: RootState }
): Observable<BillingActionType> =>
    action$.pipe(
        ofType(GET_BILLING_UPDATE_START),
        switchMap((action: GetBillingUpdateStartType) => {
            return from(getBilling(action.payload)).pipe(
                map((result) => {
                    return GetBillingUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorBillings(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorBillings(err.msg));
        })
    );

export const DeleteBillingsEpic = (
    action$: ActionsObservable<DeleteBillingsStartType>,
    state$: { value: RootState }
): Observable<BillingActionType> =>
    action$.pipe(
        ofType(DELETE_BILLINGS_START),
        switchMap((action: DeleteBillingsStartType) => {
            return from(deleteBillings({ _ids: action.payload })).pipe(
                map((result) => {
                    return DeleteBillingsEnd({
                        ...result.data,
                        _ids: action.payload,
                    });
                }),
                catchError((err) => {
                    return of(ErrorBillings(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorBillings(err.msg));
        })
    );

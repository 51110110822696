import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../redux/hooks';
import { ObjectMarker } from './ObjectMarker';
import MarkerClusterGroup from 'react-leaflet-cluster'


export function ObjectsMarker(props: {sharedMap?: boolean}) {
    const dispatch = useDispatch();

    const { vehicles, object, optionsMap } = useAppSelector(
        (state) => {
            return {
                vehicles: state.vehicleListReducer.vehicles,
                object: state.ObjectReducer.vehicle,
                optionsMap: state.mainReducer.optionsMap
            }
        }
    );

    if(!optionsMap.showMarker) return null;

    if(optionsMap.isClustered) {
        return (
            <MarkerClusterGroup chunkedLoading >
                { 
                    vehicles.list.filter((obj: any)=> !!obj.baseInfo).map((obj: any) => <ObjectMarker isClustered={optionsMap.isClustered} vehicle={obj} keepTrack={object && object._id === obj._id ? true : false} sharedMap={props.sharedMap}/>) 
                }    
            </MarkerClusterGroup>
        )
    }

    return (
        <> 
            { 
                vehicles.list.filter((obj: any)=> !!obj.baseInfo).map((obj: any) => <ObjectMarker vehicle={obj} keepTrack={object && object._id === obj._id ? true : false} sharedMap={props.sharedMap} selected={!!object}/>) 
            } 
        </>
    );
}

import { axiosInstance } from "../../../../../redux/setupAxios";
import { VehicleInfoType } from "../../../Objects/_redux/types";

export async function fetchVisibleSensorsUnits(
    payload: {vehicle: VehicleInfoType, sharedMap?: boolean}
): Promise<any> {
    if(payload.sharedMap) {
        return []
    }
    const url = `vehicle/${payload.vehicle._id}/sensors/visible`;
    
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

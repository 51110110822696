import React from "react";
import { useDispatch } from "react-redux";
import { Col, Input, CardBody } from "reactstrap";
import { ButtonUi } from "../../../../../../Components/basic/ButtonUi";
import { CardUi } from "../../../../../../Components/basic/Card";
import { TableUi } from "../../../../../../Components/Table";
import { useAppSelector } from "../../../../../../redux/hooks";
import { ManageCalibration } from "../../../../redux/vehicleModule.actions";

export const CalibrationTable = () => {
    const dispatch = useDispatch();
    const { sensors } = useAppSelector((state) => state.vehicleModuleReducer);
    
    const columns = [
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            render: (value: number, data: any) => <Input 
                                            bsSize="sm"
                                            autoComplete={'off'} 
                                            type="number" 
                                            min={0} 
                                            defaultValue={value}
                                            invalid={!!data.error}
                                            onChange={(e)=> changeValue(e, {key: data.key, y: data.y, x: +e.target.value})}
                                        />
        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            render: (value: number, data: any) => <Input 
                                            bsSize="sm"
                                            autoComplete={'off'} 
                                            type="number" 
                                            min={0} 
                                            invalid={!!data.error && !value}
                                            defaultValue={value}
                                            onChange={(e)=> changeValue(e, {key: data.key, x: data.x, y: +e.target.value})}
                                        />
        },
        {
            title: 'Action',
            dataIndex: 'key',
            key: 'key',
            render: (key: number) => <ButtonUi color="danger" size="sm" ghost onClick={(e) => deleteLine(e, key)}>Supprimer</ButtonUi>
        },

    ];

    const addLine = (e: any) => {
        e.target.blur();
        dispatch(ManageCalibration({x: null, y: null}));
    }

    const deleteLine = (e: any, index: number) => {
        e.target.blur();
        dispatch(ManageCalibration({key: index, x: null, y: null}));
    }

    const changeValue = (e: any, data: {key: number, x: number, y: number}) => {
        dispatch(ManageCalibration(data));
    }
    
    return (
        <React.Fragment>
            
            <Col>
                <div className="hstack gap-2 justify-content-end mb-2">
                    <ButtonUi color="info" size="sm" ghost onClick={(e) => addLine(e)}>Ajouter une ligne</ButtonUi>
                    </div>
                </Col>
            
            <Col sm={12}>
                <CardUi noShadow>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={sensors.calibration}
                            minusHeight={210}

                        />
                    </CardBody>
                </CardUi>
                
            </Col>
        </React.Fragment>    
    );
}
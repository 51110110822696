import { Label } from 'reactstrap';
import { IoNotificationsOffOutline } from 'react-icons/io5';


export const NotificationEmpty = () => {

    return (
        <div className="d-flex flex-column align-items-center justify-content-center p-3">
            <IoNotificationsOffOutline size={36} className="text-primary"/>
            <Label className="fs-14 my-2">Pas de nouvelles notifications</Label>
            <span className="fs-12 text-muted text-center">Il semble que vous n'ayez reçu aucune notification</span>
        </div>
    );
};


import React from "react"
import { Link } from "react-router-dom";
import { Container } from "reactstrap"
import SimpleBar from "simplebar-react";
import { SidebarContent } from "./SideBarContent";
import { useAppSelector } from "../../redux/hooks";
import MetaTags from 'react-meta-tags';
import { IF } from "../../Components/If";

export const SideBar = () => {
  
  const user = useAppSelector(
    (state) => {
        return {
            title: state.authReducer.user?.company.title,
            ico: state.authReducer.user?.company.ico,
            logo: state.authReducer.user?.company.logo
        }
    }, (old, next)=> {
        return old.title === next.title 
        && old.ico === next.ico
    }
  );

  // useEffect(() => {
  //   var verticalOverlay = document.getElementsByClassName("vertical-overlay");
  //   if (verticalOverlay) {
  //     verticalOverlay[0].addEventListener("click", function () {
  //       document.body.classList.remove("vertical-sidebar-enable");
  //     });
  //   }
  // });
  
  return(<React.Fragment>
      <div className="app-menu navbar-menu">
        <MetaTags>
          <title>{user.title}</title>
          <link rel="icon" href={`/images/${user.ico}`}/>
        </MetaTags>
        <div className="navbar-brand-box">
          {/* <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSm} alt="" height="28" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link> */}
          <IF condition={!!user.logo}>
          <Link to="/" className="logo logo-light">
            <span className="logo-sm justify-content-center">
            {/* <img src={logoSm} alt="" height="12" /> */}
            
              <img src={`/images/${user.logo}`} height="12" alt="logo"/>
            

            </span>
            <span className="pt-3 logo-lg flex-grow-1 d-flex flex-row justify-content-center align-items-center">
              <img src={`/images/${user.logo}`} alt=""  className="m-2" width="215"/>
         
              {/* <div className="text-white fs-5">
                <span className="fw-light">Telematics</span>
                <span className="fw-bold"> Services</span>
              </div> */}
            </span>
          </Link>
          </IF>
          <button
          //   onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        
        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
               <SidebarContent layoutType={'vertical'} />
            </ul>
          </Container>
        </SimpleBar>
        
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
}

import { ActionTableDropdown } from "../../../Components/Dropdown/TableActionDropdown";
import { useIntl } from "react-intl";
import { IconType } from "react-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { GiBusStop } from "react-icons/gi";
import { BsFillPinMapFill } from "react-icons/bs";

type StopListItemProps = {
    item: {
        key: string,
        name: string,
        description: string,
        color: string,
    },
    onEvent?: (action: string, data?: any, confirmationText?: string) => void
}


export const StopListItem = (props: StopListItemProps): JSX.Element => {

    const intl = useIntl();

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: BsFillPinMapFill,
            name: intl.formatMessage({ id: 'COMMON.LOCATE' }),
            action: 'locate'
        },
        {
           
            name: 'divier',
        },
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        }
    ];
    

    const onClick = (action: string) => {
        if(props.onEvent) {
            props.onEvent(action, props.item.key, props.item.name)
        }
    }
    return (
        <div className="py-2 px-3 d-flex flex-column justify-content-center border-bottom">
            <h6 style={{fontWeight: 500}} className="mb-0 d-flex justify-content-between align-items-center">
                <div className="d-flex flex-row align-items-center">
                    <GiBusStop className="me-1 text-primary" size={20} /> 
                    <span className="text-capitalize">
                    { props.item.name}
                </span>
                </div>
                
                {
                    props.onEvent && 
                    
                    <ActionTableDropdown 
                        _id={props.item.key} 
                        onChange={onClick}
                        options={actions}
                    />
                }
            </h6>
            <span className="text-muted fs-12">
                { props.item.description ?? "-"}
            </span>
            
        </div>
    );      
}

export default StopListItem;
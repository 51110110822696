import moment from 'moment-timezone';
import { ActionsObservable, ofType } from 'redux-observable';
import { from, of,Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SetOptionMapAction } from '../../../../../redux/rootActions';
import { RootState } from '../../../../../redux/store';
import * as actions from './historyActions';
import {
    ActiveTrajectEnd,
    ActiveTrajectStartType,
    Error,
    GetEnd,
    GetParkingStart,
    GetParkingStartType,
    GetStartType,
    GetTrajectEnd,
    GetTrajectStart,
    GetTrajectStartType,
    HistoryActionsType,
} from './historyActions';
import { fetchHistory, fetchHistoryResponse, fetchParking, fetchTraject, fetchTrajects } from './historyService';
import { FetchHistoryEndResponse, PeriodType } from './types';

export const FetchHistoryEpic = (action$: ActionsObservable<GetStartType>, state$: { value: RootState }): Observable<HistoryActionsType | any> =>
    action$.pipe(
        ofType(actions.GET_START),
        switchMap((action: GetStartType) => {
            const range = state$.value.historyReducer.period;
            const { user } = state$.value.authReducer;
            if(!state$.value.ObjectReducer.vehicle?._id || !user?.timezone || ( range && (action.payload.from === range.from && action.payload.to === range.to))) {
                return of(Error(''));
            }
            const { _id } = state$.value.ObjectReducer.vehicle;
            const payloadClone: PeriodType & { _id: string} = { ...action.payload, _id };
            
            return from(fetchHistory(payloadClone)).pipe(
                switchMap((data: fetchHistoryResponse) => {
                    const result: FetchHistoryEndResponse = {
                        ...payloadClone,
                        ...data,
                        ...{ timezone: user.timezone },
                        
                    };
                    return of(
                        GetEnd(result), 
                        GetTrajectStart(),
                        GetParkingStart(),
                        SetOptionMapAction({opt: 'showMarker', value: false})
                    );
                }),
                catchError((err) => {
                    return of(Error('Problem unknow'));
                })
            );
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );

export const FetchTrajectStartEpic = (action$: ActionsObservable<GetTrajectStartType>, state$: { value: RootState }): Observable<HistoryActionsType> =>
    action$.pipe(
        ofType(actions.GET_TRAJECT_START),
        switchMap((action: GetTrajectStartType) => {
            const { vehicle } = state$.value.ObjectReducer;
            const { data, ids } = state$.value.historyReducer.trip;
            if (data.length > 0 && vehicle?._id && Array.isArray(ids)) {
                const time = { 
                    start: data[0].startDate.toDate().getTime(), 
                    end: data[data.length - 1].endDate.toDate().getTime() 
                };
                
                return from(fetchTrajects({ ids, unitId: vehicle?._id, time })).pipe(
                    map((res) => {
                        return GetTrajectEnd(res);
                    }),
                    catchError((err) => {
                        return of(Error('Problem unknow'));
                    })
                );
            }
            return of(Error(''));
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );
    
export const SetActiveTrajectEpic = (action$: ActionsObservable<ActiveTrajectStartType>, state$: { value: RootState }): Observable<HistoryActionsType> =>
    action$.pipe(
        ofType(actions.ACTIVE_TRAJECT_START),
        switchMap((action: ActiveTrajectStartType) => {
            if(!state$.value.ObjectReducer.vehicle?._id) {
                return of(Error(''));
            }
            const unitId = state$.value.ObjectReducer.vehicle._id;
            const leafletMap = state$.value.mainReducer.map;
            const { data } = state$.value.historyReducer.trip;
            const { _id } = action.payload;
            const tripIndex = data.findIndex((entry) => entry._id === _id);

            if (tripIndex !== -1) {
                const { startDate, endDate } = data[tripIndex];
                return from(
                    fetchTraject({ _id, unitId, time: { startDate, endDate } })
                ).pipe(
                    map((res: any) => {
                        const bounds = res.data.map((e: any) => [
                            e.location[1],
                            e.location[0],
                        ]);

                        leafletMap.fitBounds(bounds, {
                            paddingTopLeft: [600, 20],
                        });
                        const activeTripOn = {
                            index: tripIndex,
                            traject: res.data,
                            position: bounds,
                            on: true,
                        };
                        return ActiveTrajectEnd(activeTripOn);
                    }),
                    catchError((err) => {
                        return of(Error('Problem unknow'));
                    })
                );
            }
            
            return of(Error(''));
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );

    export const FetchParkingEpic = (action$: ActionsObservable<GetParkingStartType>, state$: { value: RootState }): Observable<HistoryActionsType> =>
    action$.pipe(
        ofType(actions.GET_PARKING_START),
        switchMap((action: GetParkingStartType) => {
            if(!state$.value.ObjectReducer.vehicle?._id || !state$.value.historyReducer.period) {
                return of(Error(''));
            }
            
            const unitId = state$.value.ObjectReducer.vehicle._id;
            const period = state$.value.historyReducer.period;
        
            return from(
                fetchParking({ unitId, time: { startDate: moment(period.from), endDate: moment(period.to) } })
            ).pipe(
                map((res: any) => {
                    return actions.GetParkingEnd(res)
                }),
                catchError((err) => {
                    return of(Error('Problem unknow'));
                })
            );
        }
    
    ),
    catchError((err) => {
        return of(Error('Problem unknow'));
    }));

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CardBody, CardHeader, Col, Container, Form, FormGroup, Row} from "reactstrap";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { AsyncInputSelect } from "../../Components/Form/AsyncInputSelect";
import { InputFlatPickrUi } from "../../Components/Form/InputFlatPickr";
import { InputText } from "../../Components/Form/InputText";
import { useAppSelector } from "../../redux/hooks";
import { ClearShareCreation, CreateShareStart, GetShareUpdateStart, SetShareSubscriptionDateAction, SetVehiclesShareAction } from "./redux/share.actions";
import { fetchVehiclesByName } from "./redux/share.service";

const ManageSharePositionPage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {_id} = useParams();
    const isUpdate = _id !== 'add';
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const { manage, loading } = useAppSelector(
        (state) => state.shareReducer
    );

    
    const onSubmit = async (payload: any) => {
        if(manage.expiredDate && manage.units && manage.units.length > 0) {
            dispatch(CreateShareStart(payload));
        } 
    }
    
    const backToPrevious = () => {
        navigate({ pathname: '/share' });
    }

    const changeVehicleAssignment = (element: any): void => {
        dispatch(SetVehiclesShareAction(element));
    };

    useEffect(() => {
        if(_id && _id !== 'add') {
            dispatch(GetShareUpdateStart({ _id }))
        }
        return function cleanup() {
            dispatch(ClearShareCreation());
        };
    }, []);

    useEffect(() => {
        setValue('name', manage.name);
    }, [manage.name]);

    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    

    return (
        <div id="ManageSharePositionPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'SHARE' })} 
                    pageTitle={''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                     
                <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
                    <Row>
                        <LoaderUi loading={false}/>
                        <CardUi>
                            <CardHeader>
                                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xl={6}>
                                        <FormGroup>
                                            <InputText
                                                type="text"
                                                autocomplete={false}
                                                label={intl.formatMessage({id: 'SHARE.NAME'})}
                                                id={'name'}
                                                name={'name'}
                                                placeholder={intl.formatMessage({id: 'SHARE.PLACEHOLDER.NAME'})}
                                                register={register('name', {
                                                    required: {
                                                        value: true, 
                                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                                    }})
                                                }
                                                error={errors['name']}
                                                disabled={isUpdate}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <FormGroup>
                                            {
                                                <InputFlatPickrUi
                                                    className="form-control"
                                                    label={intl.formatMessage({ id: 'SHARE.SUBSCRIPTION_TIME' })}
                                                    value={ manage.expiredDate ? new Date(manage.expiredDate): undefined }
                                                    options={{
                                                        dateFormat: "d M, Y",
                                                        minDate
                                                    }}
                                                    onChange={(date: Date[]) =>  dispatch(SetShareSubscriptionDateAction(date[0].getTime())) }
                                                    error={manage.expiredDate === undefined}
                                                    disabled={isUpdate}
                                                />
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col xl={12}>
                                        <FormGroup>
                                            {
                                                (manage._id || !isUpdate ) && <AsyncInputSelect 
                                                    isMulti
                                                    label={intl.formatMessage({ id: 'SHARE.VEHICLE'})}
                                                    cacheOptions
                                                    defaultOptions
                                                    defaultValue={manage.units}
                                                    loadOptions={fetchVehiclesByName}
                                                    onChange={(element: any) => changeVehicleAssignment(element)}
                                                    isDisabled={isUpdate}
                                                />
                                            }
                                        
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <div className="hstack gap-2 justify-content-end">
                                            <ButtonUi color={"primary"} soft onClick={backToPrevious}>
                                                <FormattedMessage id="COMMON.CANCEL"/>
                                            </ButtonUi>
                                            
                                            {
                                               !isUpdate && <ButtonUi color={"primary"} type="submit">
                                                <FormattedMessage id="COMMON.ADD"/>
                                            </ButtonUi>
                                            } 
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </CardUi>       
                    </Row>
                </Form>
            </Container>
        </div>
    );
} 
export default ManageSharePositionPage;
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineFileAdd } from "react-icons/ai";
import { ImSpinner10 } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, Form, FormGroup } from "reactstrap";
import { DriverSubMark } from "../../../../../Components/advanced/Driver/Driver-eco-mark/driver-sub-mark";
import { ButtonUi } from "../../../../../Components/basic/ButtonUi";
import { RateUiComponent } from "../../../../../Components/basic/RateUi";
import { InputFlatPickrUi } from "../../../../../Components/Form/InputFlatPickr";
import { InputText } from "../../../../../Components/Form/InputText";
import Emitter from "../../../../../helpers/emitter";
import EmitterActions from "../../../../../helpers/emitter/emitter.actions";
import { useAxios } from "../../../../../common/hooks/axios/useAxios";
import { SetNotificationAction } from "../../../../../redux/rootActions";

const INITIAL_RANKING = {
    resume: 0, details: {"availabilty": 0, "discipline": 0, "punctuality": 0, "dress": 0}}
export const AddViewPoint = () => {
    // Hooks
    const dispatch = useDispatch();
    const { key } = useParams();
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();
    const { data:result, execute, loading, status } = useAxios(true);
    const [ranking, setRanking] = useState<{resume: number, details: Record<string, number>}>(INITIAL_RANKING);
    const [rankingTime, setRankingTime] = useState<Date>(new Date());
    const { register, handleSubmit, formState: { errors }, setValue, setError } = useForm();
    
    useEffect(()=> {
        if(status && status >= 200 && status <=204) {
            dispatch(SetNotificationAction({
                title: "Avis rajouté",
                "message": 'Succès',
                timeToClose: 3000,
                type: 'success'
            }));
            toggle();
            clearData();
            Emitter.emit(EmitterActions.REFRESH_DRIVER_REVIEW_RATE, "")
        }
    }, [result, status])
    
    
    

    // Events
    const toggle = (): void => {

        navigate(`/drivers/${key}/details`)
    };

    const onSubmit = (data: any) => {
        if(ranking.resume > 0 && rankingTime) {
            const payload: {comment?: string, ranking: {resume: number, details: Record<string, number>}, rankingTime: Date} = {ranking, rankingTime};
            if(data.comment.length > 0) {
                payload.comment = data.comment;
            }
            execute(
                `driver/${key}/view-point`, 
                { method: 'POST', data: { ...payload, additionalUnit: key } }
            );
        }
    };

    const editRanking = (key: string, value: number) => {
        let sum = 0;
        const details = {...ranking.details, [key]: value};
        const keys = Object.keys(details);
        keys.forEach(key => {
            sum +=details[key];
        })
        setRanking({resume: sum/keys.length, details})
    }

    const clearData = () => {
        setRanking(INITIAL_RANKING);
        setRankingTime(new Date());
        setValue('comment', '');
    }



    return (
        <Modal 
            isOpen={queryParameters.get('add-view-point') === '1' && !!key}
            size={"sm"}
            fullscreen={"sm"}
            id="rank-driver-modal"
            toggle={toggle}
        >
            <ModalHeader className="border d-flex flex-row align-items-center mb-0 text-primary" toggle={toggle}> 
                <AiOutlineFileAdd size={20} className="me-2"/>
                <span style={{fontWeight: 500}} className="text-primary">Ajouter un avis</span>
            </ModalHeader>
            <ModalBody className="p-0">
                <div className="d-flex flex-column align-items-center p-3">
                    <h6 className="fs-16 text-primary">Résumé</h6>
                    <div className="resume mt-1 d-flex flex-row align-items-center justify-content-center px-3 py-1">
                        <RateUiComponent  allowHalf allowColor mark={ranking.resume} disabled />
                        <span className="ms-3 fs-12 self-align-end">{ranking.resume?.toFixed(1) ?? '-'} sur 5</span>
                    </div>
                </div>
                <div className="d-flex flex-column mt-2 p-3 border-bottom">
                    {
                        Object.keys(ranking.details).map(key => {
                            return <DriverSubMark text={key} mark={ranking.details[key]} onChange={(value)=> editRanking(key, value)} />
                        })
                    }
                </div>

                <Form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <InputFlatPickrUi
                            className="form-control"
                            label={"Date de la saisie"}
                            value={rankingTime}
                            options={{
                                dateFormat: "d M, Y",
                                maxDate: new Date()
                            }}
                            onChange={(date: Date[]) =>  setRankingTime(date[0]) }
                        />
                    </FormGroup>
                    <FormGroup>
                            <InputText
                                type="textarea"
                                label="Commentaire"
                                id={'comment'}
                                name={'comment'}
                                placeholder={''}
                                register={register('comment')}
                                error={errors['comment']}
                                rows="4"
                            />
                    </FormGroup>
                    <div className="d-flex flex-row align-items-center justify-content-sm-end mt-2">
                        <ButtonUi color={"primary"} type="submit" disabled={!ranking.resume || loading}>
                            {
                                loading ? <div className="loading-item d-flex justify-content-center align-items-center"><ImSpinner10 size={16} className="me-2"/><span>En cours...</span></div> : "Ajouter"
                            }    
                        </ButtonUi> 
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}
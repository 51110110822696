import Modal from "../../Components/Modal/modal";
import ModalHeader from "../../Components/Modal/modal-header";
import { GiBusStop } from "react-icons/gi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { SiProbot } from "react-icons/si";
import { ChoiceIconComponent } from "../../Components/ChoiceIcon";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";


export const CreateStopModal = () => {

    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();
    const action = queryParameters.get('action');
    const [ isOpen, setOpen ] = useState(true);

    useEffect(() => {
        setOpen(action === "choice");
    }, [action]);

   const onClick = (option: string) => {
        navigate(`/stops/add-${option}`); 
   }

    const toggleOpen = () => {  
        navigate('/stops'); 
    }

    return (
        <Modal isOpen={isOpen} size={"md"} fullscreen={"md"} toggle={toggleOpen}>
            <ModalHeader 
                Icon={GiBusStop} 
                title={"Création des arrêts"} 
                description={"Veuillez choisir l'option qui vous convient pour créer les arrêts."} 
            />

            <div className="d-flex flex-row justify-content-center mt-5">
                <ChoiceIconComponent 
                    name="Manuel" 
                    option="manual" 
                    onClick={onClick} 
                    Icon={HiOutlineLocationMarker}  
                />
                <ChoiceIconComponent 
                    name="Automatique" 
                    option="automatic" 
                    onClick={onClick} 
                    Icon={SiProbot}  
                />
            </div>
        </Modal>
    );
      
        
}

export default CreateStopModal;
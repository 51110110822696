import {  FormGroup, Label } from "reactstrap";
import { memo, useCallback, useState } from "react";

import ViewSelectedItems from "../../../../Components/advanced/v2/view-selected-items";
import { BsBoxSeam } from "react-icons/bs";
import ModalSelectUnits from "../../../../Components/GroupManagement/ModalSelectUnits";

type SelectUnitReportProps = {
    selectedItems: Record<string, {label: string, value: string}[]>,
    disabled: boolean;
    onSelect: (selection: Record<string, {label: string, value: string}[]>) => void;
} 
const SelectUnitReport= (props: SelectUnitReportProps) => {
    
    // Hooks
    const [isOpen, toggleOpen] = useState(false);
    const getSelectedItems = useCallback(() => {
        return Object.values(props.selectedItems).reduce((result, current) => [...result, ...current], []);
    }, [props.selectedItems]);

    // Events
    const toggleModal = () => {
        if(!props.disabled) {
            toggleOpen(!isOpen)
        };
    }

    return (
        <FormGroup >
            <Label>Unités</Label>
            <ViewSelectedItems 
                items={getSelectedItems()} 
                target={"unit"} 
                limit={3} 
                addActionName="Modifier la séléction"
                editActionName="Cliquer ici pour ajouter des unités pour le rapport"
                onClick={toggleModal}
                disabled={props.disabled}
            >
                <ModalSelectUnits 
                    title="Ajouter des conducteurs au rapport"
                    description="Choisissez vos unités et rajoutez les au rapport."
                    Icon={BsBoxSeam}
                    isOpen={isOpen} 
                    default={props.selectedItems}
                    toggleModal={toggleModal}
                    onSelect={props.onSelect}
                />
            </ViewSelectedItems>
        </FormGroup>
    );
}

export default memo(SelectUnitReport);
import classNames from "classnames";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IF } from "../../../Components/If";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { Passenger } from "../../../common/hooks/axios/passenger/type";
import DriverProfileSkeleton from "../../Driver/List/Details/DriverProfile/Skeleton";
import { Avatar } from "../../../Components/basic/avatar/avatar";

type PassengerProfileProps = {
    loading: boolean,
    passenger: Passenger | null,
    [key: string]: any
}
export const PassengerProfile = ({passenger, loading, ...other}: PassengerProfileProps) => {

    const profileClassName = classNames(
        'border-bottom d-flex flex-row justify-content-between align-items-center', {
        [other?.className ?? '']: !!other?.className
    });


    if(loading) {
        return <div className={profileClassName}> <DriverProfileSkeleton /></div>
    }

    if(!passenger) {
        return <></>
    }

    return (
        <div className={profileClassName}>
            <div className="d-flex flex-row">
                <Avatar 
                    text={passenger.name ?? '-'}
                    size="sm" 
                    color="primary"
                    className="me-3"
                    rounded="rounded-circle"
                />
                <div className="d-flex flex-column justify-content-center">
                    <label className="mb-0">{passenger.name ?? '-'}</label>
                    <IF condition={!!passenger.identifier}>
                        <span className="text-muted fs-12">{passenger.identifier}</span>
                    </IF>                
                </div>
            </div>

            <ButtonUi className={`d-flex flex-row justify-content-center align-items-center`} size="sm" color="info" soft>                         
                <>
                    <FaMapMarkerAlt size={12} className="me-1"/> Localiser
                </>
            </ButtonUi>
        </div>             
    );
}
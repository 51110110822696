import { InputSearch } from "../../../../Components/Form/InputSearch"
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useRef, useState } from "react";
import { ScrollBar } from "../../../../Components/PerfectScrollBar";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import { DriverAssignmentComponent } from "../../../../Components/advanced/Driver/Driver-assignment";
import { useDispatch } from "react-redux";
import { GetUnitToAssignDriverStart } from "../../_redux/driverModule.actions";
import { useAppSelector } from "../../../../redux/hooks";

type AssignVehicleToDriverProps = {
    onSubmit: (unit: string)=> void;
    onCancel: ()=> void;
}
export const AssignVehicleToDriver = ({onSubmit, onCancel}: AssignVehicleToDriverProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const refScrollBar = useRef();

    const [name, setName] = useState('');
    const [active, setActive] = useState<string | undefined>(undefined);

    const { assignment } = useAppSelector((state) => state.driverModuleReducer);
    
    const onSearch = (name: {searchBy: string}): void => {
        setName(name.searchBy);
        dispatch(GetUnitToAssignDriverStart({name: name.searchBy}));
    };

    useEffect(()=> {
        dispatch(GetUnitToAssignDriverStart({}));
    }, []);

    const chooseToAssign = (unit: string) => {
        if(unit === active) {
            setActive(undefined);
        } else {
            setActive(unit);
        }
    }

    const submit = () => {
        if(active) {
            onSubmit(active)
        }
        
    }

    return (
        <>
            <div className="border-bottom px-4 py-3">
                <InputSearch
                    type="text"
                    name={'searchBy'}
                    placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                    onSubmit={onSearch}
                />
            </div>
            
            
            <ScrollBar
                id="assign-unit-scrollbar"
                containerRef={refScrollBar}
                minusHeight={200}
            > 
                <div className="px-3 pt-3">
                    {
                        assignment.units.map(data => {
                            return (
                                <DriverAssignmentComponent 
                                    key={ data.key }
                                    unit={data} 
                                    active={ data.key ===  active}
                                    onSelect={chooseToAssign}
                                />
                            )
                        })
                    }
                </div>
            </ScrollBar> 
            
            <div className="d-flex flex-row align-items-center justify-content-sm-end px-4 py-3 border-top">
                <ButtonUi className="me-2" color="primary" outline
                    onClick={onCancel}
                >
                    <FormattedMessage id="COMMON.CANCEL" />
                </ButtonUi>
                <ButtonUi color="primary" disabled={!active} onClick={submit}>
                    <FormattedMessage id="COMMON.ASSIGN" />
                </ButtonUi>
            </div>
            
        </>
        
    )
}
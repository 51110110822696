import { useAxios } from "../useAxios";

export type ReportItems = {
  "value": string, "label": string,
}

type ReportTemplatesResult = {
    list: ReportItems[];
    total: number;
}

type UseFetchReportItemsResponse = { 
  reportDetails: any;
  reportDetailsLoading: boolean;
  error?: string;
  initReportItems: () => void;
  fetchReportDetails: (payload: FetchReportDetailsProps) => void;
  cancel: () => void;
}

type FetchReportDetailsProps = {
  template: string;
  reportId: string;
  "item[label]": string;
  "item[value]": string;
  "range[from]": string;
  "range[to]": string;
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
  hasExtra?: boolean
}

type UseFetchReportItemsProps = {
  manual?: boolean
}

export function useFetchReportDetails(props: UseFetchReportItemsProps): UseFetchReportItemsResponse {
  const URL = "report/instantaneous";
  const { execute, data, cancel, error, loading, init } = useAxios<ReportTemplatesResult>(props.manual);

  const fetchReportDetails = (params: FetchReportDetailsProps) => {
    execute(URL, { method: "GET", params });
  };

  return { 
    initReportItems: init,
    reportDetails: data, 
    fetchReportDetails, 
    cancel, 
    reportDetailsLoading: loading, 
    error: error
  };
}

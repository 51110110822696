import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { FaFlagCheckered, FaRegFlag } from 'react-icons/fa';
import { GiBusStop } from 'react-icons/gi';
import { Marker } from 'react-leaflet';

export function RouteMarker({ position, color, type }: {position: any[], color: string, type: "start" | "end" | "stop"}) {
    const [lng, lat] = position;
    let iconPinHtml = renderToStaticMarkup(
        <div className={`marker-pin`} style={{backgroundColor: color}}>
            <FaRegFlag size={15} style={{color}}/>
        </div>
    );

    switch(type) {
        case "end": {
            iconPinHtml = renderToStaticMarkup(
                <div className={`marker-pin`} style={{backgroundColor: color}}>
                    <FaFlagCheckered size={15} style={{color}}/>
                </div>
            );
            break;
        }

        case 'stop': {
            iconPinHtml = renderToStaticMarkup(
                <div className={`marker-pin`} style={{backgroundColor: color}}>
                    <GiBusStop size={15} style={{color}}/>
                </div>
            );
        }
    }
    switch(type) {
        case "start": {

            break; 
        }
    }
 

    const iconPin = divIcon({
        html: iconPinHtml,
        iconSize: [0, 0],
        iconAnchor: [15, 37],
        popupAnchor: [0, -32],
    });



    return (
        <Marker
            position={{ lat, lng }}
            icon={iconPin}
        />
    );
}

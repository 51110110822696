import { ListNotification } from "./List/ListNotification";


const NotificationPage = () => {
    
    return (
        <div className="page-content object-page">
            <div className="d-flex flex-row">
                <ListNotification />
            </div>       
        </div>
    );
}

export default NotificationPage;
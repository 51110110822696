import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import classNames from "classnames";
import moment from "moment";
import { Label } from "reactstrap";

type RangePickerType = RangePickerProps & {label?: string, id?: string, showRange?: boolean};

const RangePicker = ({label, id, showRange, ...props}: RangePickerType) => {
    const { RangePicker } = DatePicker;
    const className = classNames("d-flex flex-column",{
        "opacity-50": props.disabled
    })
    return (
        <div className={className}>
            { label && <Label for={id}> {label} </Label> }
            <RangePicker
                id={id}
                className={'period'}
                {...props}
                ranges={ showRange ? {
                    'Aujourd\'hui': [moment().set({hour:0,minute:0,second:0,millisecond:0}), moment()],
                    'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'La semaine dernière': [moment().subtract(6, 'days'), moment()],
                    'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    'Ce mois-ci': [moment().startOf('month'), moment()],
                } : undefined}
            /> 
        </div>
                        
    );
}

export default RangePicker;
import { AiFillEye } from "react-icons/ai";
import { RoutesMinutes } from "../../components/road/route-minutes";
import { useEffect, useRef } from "react";
import { getMinutesSteps } from "../../routes-details.page";

interface Props {
  start: Date;
  end: Date;
  onChangeRowSize: (size: number) => void
}

export const RoadTableHeader = ({ start, end, onChangeRowSize }: Props) => {
  const step = getMinutesSteps(end, start);
  const rowRef = useRef<any>(null);
  const arrayLength = Math.ceil(
    (end.getTime() - start.getTime()) / 3600000
  );

  useEffect(() => {
    onChangeRowSize(rowRef?.current?.offsetWidth ?? 700);
  }, []);

  return (
    <thead
      className=" position-sticky top-0 bg-white"
      style={{
        width: "100%",
        textAlign: "center",
        zIndex: 1333,
      }}
    >
      <tr
        className="bg-primary text-light border"
        style={{
          height: 40,
          alignItems: "center",
          textAlign: "center",
          marginBottom: 10,
        }}
      >

        <th
          scope="col"
          className="text-center  border "
          style={{ width: 50, minWidth: 50 }}
        >
          <AiFillEye size={18} />
        </th>
        <th
          scope="col"
          className="text-center  border "
          style={{ width: 150, minWidth: 150 }}
        >
          Transport
        </th>
        <th
          scope="col"
          className="text-center  border "
          style={{ width: 100, minWidth: 100 }}
        >
          Distance
        </th>
        <th
          scope="col"
          className="text-center border "
          style={{ width: 100, minWidth: 100 }}
        >
          Capacité
        </th>
        <th
          scope="col"
          className="text-center border "
          style={{ width: 100, minWidth: 100 }}
        >
          Time
        </th>
        {Array.from(Array(arrayLength), (_, index) => {
          return (
            <th colSpan={step} ref={rowRef} className="border text-center">
              { new Date(start.getTime() + index * 3600000).getHours() }:00
            </th>
          );
        })}
      </tr>
      <tr className="bg-transparent">
        <th scope="col"></th>
        <th scope="col"></th>
        <th scope="col border border-primary"></th>
        <th scope="col border border-primary"></th>
        <th scope="col border border-primary"></th>
        {Array.from(Array(arrayLength), (_, index) => {
          return <RoutesMinutes step={step} />;
        })}
      </tr>
    </thead>
  );
};

import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { InputText } from "../../../Components/Form/InputText";
import { useIntl } from "react-intl";
import { passwordValidator } from "../../../helpers/validationHelper";
import { useAxios } from "../../../common/hooks/axios/useAxios";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { SetNotificationAction } from "../../../redux/rootActions";
import { useDispatch } from "react-redux";
import { Logout } from "../../Authentification/_redux/authActions";
import { useAppSelector } from "../../../redux/hooks";
import { AlertUi } from "../../../Components/basic/AlertUi";
import { AiOutlineWarning } from "react-icons/ai";


export const Password = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { user } = useAppSelector((state) => state.authReducer);
    const { register, handleSubmit, watch, setError, clearErrors, formState: { errors }, reset } = useForm();
    const { data, execute, loading } = useAxios<any>(true);
    const onSubmit = (data: any) => {
        clearErrors();
        execute(`account/password`, {method: 'PATCH', data});
    };


    const inputsText = [
        {
            xl: 4,
            label: 'Ancien mot de passe', id: 'password', name: 'password', placeholder: "Votre mot de passe courant",
            validation: {required: {value: true, message: intl.formatMessage({id: 'COMMON.REQUIRED'})}}
        },
        {
            xl: 4,
            label: 'Nouveau mot de passe', id: 'newPassword', name: 'newPassword', placeholder: "Votre nouveau mot de passe",
            validation: passwordValidator(true)
        },
        {
            xl: 4,
            label: 'Confirmation du mot de passe', id: 'confPassword', name: 'confPassword', placeholder: "Confirmation du mot de passe",
            validation: {
                validate: {
                    isTheSame: (input: string) =>
                        input === watch('newPassword') ||
                        intl.formatMessage({id: 'COMMON.SAME_PASSWORD'}),
                },
            }
        }
    ];

    useEffect(()=> {
        if(!loading && data?.error) {
            setError('password', {message: 'Votre ancien mot de passe est incorrect'})
        } else if(!loading && data?.matchedCount >=1) {
            dispatch(SetNotificationAction({
                title: `Votre mot de passe est modifié avec succès`,
                "message": `Mot de passe modifié`,
                timeToClose: 5000,
                type: 'success'
            }));
            reset();
            dispatch(Logout())
        }
    }, [loading])

    return (
       <React.Fragment>
            <LoaderUi loading={loading}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    {
                        user?.refreshPassword &&  <AlertUi color="danger" solid className="d-flex align-items-center">
                        <><AiOutlineWarning size={18} className="me-2"/> Merci de changer votre mot de passe pour pouvoir utiliser l'application.</>
                    </AlertUi>
                    }

                    {
                        inputsText.map(inputText => (
                            <Col xl={inputText.xl}>
                                <FormGroup>
                                    <InputText
                                        type="password"
                                        label={inputText.label}
                                        id={inputText.id}
                                        name={inputText.name}
                                        placeholder={inputText.placeholder}
                                        register={register(inputText.name, inputText.validation)}
                                        error={errors[inputText.name]}
                                    />
                                </FormGroup>
                            </Col> 
                        ))
                    }
                </Row>
                    <Col>
                        <div className="hstack gap-2 justify-content-end">
                            <ButtonUi color={"primary"} type="submit" soft>Modifier</ButtonUi> 
                        </div>
                        
                    </Col>
                
            </Form>
            {/* <div className="mt-4 mb-3 border-bottom pb-2 d-flex flex-row align-items-center justify-content-between">
                <h5 className="card-title mb-0">Historique de connexion</h5>
                <ButtonUi color={"primary"} ghost>Tout déconnecter</ButtonUi>
            </div> */}
       </React.Fragment>
    );
}
import { useContext, useRef } from "react";
import { VehicleInfoType } from "../../Objects/_redux/types";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

import classNames from "classnames";
import { LayoutContext } from "../../../../context/layout-style-context";
import { Traject } from "../../../../Components/advanced/Traject-info/TrajectOn";

import { useAppSelector } from "../../../../redux/hooks";
import { ActiveTrajectStart } from "./_redux/historyActions";
import { useDispatch } from "react-redux";

export const ObjectHistory = ({object, display}: {object: VehicleInfoType, display: boolean}) => {
    const HEIGHT_FIX = 235;
    const { height } = useContext(LayoutContext);
    const detailsClass = classNames({
        [`d-none`]: !display,
    });

    const dispatch = useDispatch();
    const listRef = useRef<any>();
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50,
    });

    const { history } = useAppSelector(state => {
        return {
            user: state.authReducer.user,
            history: state.historyReducer
        }
    });
    const { trip, activeTrip } = history;
    
    const getIndex = () => {
        if(activeTrip) {
            return activeTrip.index
        }
        return undefined
    }

    return (
        <div className={detailsClass} style={{height: height - HEIGHT_FIX}}>
            <AutoSizer>
                {
                    ({ height, width }) => {
                        return(
                            <List
                                ref={listRef}
                                width={width}
                                height={height > 0 ? height : 0}
                                rowCount={ trip.data.length}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={rowRenderer}
                                scrollToIndex={getIndex()}
                            />
                        )
                    }     
                }
            </AutoSizer>
        </div>          
    );




    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        const active = index === activeTrip?.index;
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                    onClick={() => dispatch(ActiveTrajectStart({ _id: trip.data[index]._id, time: { start: trip.data[index].startDate, end: trip.data[index].endDate } })) }
                >
                    <Traject trip={trip.data[index]} active={active}/>      
                </div>
            )}
          </CellMeasurer>
        );
    }
}


import {
    FilterEventType,
    ManageEvenType,
    SelectEventType,
} from './types';


export const ERROR = '[EVENTS] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorEvents(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const ADD_NEW_EVENT = '[EVENTS] ADD_NEW_EVENT';
export type AddNewEventActionType = {
    type: typeof ADD_NEW_EVENT;
    payload: { position: number };
};
export function AddNewEvent(payload: {
    position: number;
}): AddNewEventActionType {
    return { type: ADD_NEW_EVENT, payload };
}

export const REMOVE_NEW_EVENT = '[EVENTS] REMOVE_NEW_EVENT';
export type RemoveNewEventActionType = {
    type: typeof REMOVE_NEW_EVENT;
    payload: { position: number };
};
export function RemoveNewEvent(payload: {
    position: number;
}): RemoveNewEventActionType {
    return { type: REMOVE_NEW_EVENT, payload };
}

export const SET_EVENT_LOGIC = '[EVENTS] SET_EVENT_LOGIC';
export type SetEventLogicActionType = {
    type: typeof SET_EVENT_LOGIC;
    payload: { position: number; logic: '&&' | '||' };
};
export function ChangeEventLogic(payload: {
    position: number;
    logic: '&&' | '||';
}): SetEventLogicActionType {
    return { type: SET_EVENT_LOGIC, payload };
}


export const SET_SELECTED_ZONE_EVENT_TYPE =
    '[EVENTS] SET_SELECTED_ZONE_EVENT_TYPE';
export type SetEventSelectedZoneActionType = {
    type: typeof SET_SELECTED_ZONE_EVENT_TYPE;
    payload: { position: number; zones: {label:string, value:string}[] };
};
export function ChangeSelectZoneEventType(payload: {
    position: number;
    zones: {label:string, value:string}[];
}): SetEventSelectedZoneActionType {
    return { type: SET_SELECTED_ZONE_EVENT_TYPE, payload };
}



export const SET_EVENT_NOTIFICATION_TYPE =
    '[EVENTS] SET_USER_NOTIFICATION_TYPE';
export type SetUserEventNotificationActionType = {
    type: typeof SET_EVENT_NOTIFICATION_TYPE;
    payload: { key: 'user' | 'mobile' | 'email'; value: SelectEventType[] };
};
export function SetEventNotificationAction(payload: {
    key: 'user' | 'mobile' | 'email';
    value: SelectEventType[];
}): SetUserEventNotificationActionType {
    return { type: SET_EVENT_NOTIFICATION_TYPE, payload };
}


export const CLEAR_EVENT_CREATION = '[EVENTS] CLEAR_EVENT_CREATION';
export type ClearEventCreationActionType = {
    type: typeof CLEAR_EVENT_CREATION;
};
export function ClearEventCreation(): ClearEventCreationActionType {
    return { type: CLEAR_EVENT_CREATION };
}

export const CREATE_EVENT_START = '[EVENTS] CREATE_EVENT_START';
export type CreateEventStartType = {
    type: typeof CREATE_EVENT_START;
    payload: {name: string, description?: string, interval?: string};
};
export function CreateEvent(payload: {name: string, description: string, interval?: string}): CreateEventStartType {
    return { type: CREATE_EVENT_START, payload };
}

export const CREATE_EVENT_END = '[EVENTS] CREATE_EVENT_END';
export type CreateEventEndType = {
    type: typeof CREATE_EVENT_END;
    payload: ManageEvenType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateEventEnd(
    payload: ManageEvenType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateEventEndType {
    return { type: CREATE_EVENT_END, payload };
}

export const TOOGLE_POPUP = '[EVENTS] TOOGLE_POPUP';
export type TooglePoupType = {
    type: typeof TOOGLE_POPUP;
    payload: boolean;
};
export function TooglePoup(payload: boolean): TooglePoupType {
    return { type: TOOGLE_POPUP, payload };
}

export const SET_SELECTED_EVENTS = '[EVENTS] SET_SELECTED_EVENTS';
export type SetSelectedEventsType = {
    type: typeof SET_SELECTED_EVENTS;
    payload: string[];
};
export function SetSelectedEvents(payload: string[]): SetSelectedEventsType {
    return { type: SET_SELECTED_EVENTS, payload };
}

export const GET_EVENTS_START = '[EVENTS] GET_EVENTS_START';
export type GetEventsStartType = {
    type: typeof GET_EVENTS_START;
    payload: FilterEventType;
};
export function GetEventsStart(payload: FilterEventType): GetEventsStartType {
    return { type: GET_EVENTS_START, payload };
}

export const GET_EVENTS_END = '[EVENTS] GET_EVENTS_END';
export type GetEventsEndType = {
    type: typeof GET_EVENTS_END;
    payload: { result: any; filter: FilterEventType };
};
export function GetEventsEnd(payload: {
    result: any;
    filter: FilterEventType;
}): GetEventsEndType {
    return { type: GET_EVENTS_END, payload };
}

export const DELETE_EVENTS_START = '[EVENTS] DELETE_EVENTS_START';
export type DeleteEventsStartType = {
    type: typeof DELETE_EVENTS_START;
    payload: string[];
};
export function DeleteEventsStart(payload: string[]): DeleteEventsStartType {
    return { type: DELETE_EVENTS_START, payload };
}

export const DELETE_EVENTS_END = '[EVENTS] DELETE_EVENTS_END';
export type DeleteEventsEndType = {
    type: typeof DELETE_EVENTS_END;
    payload: { deletedCount: number; deletedIds: string[] };
};
export function DeleteEventsEnd(payload: {
    deletedCount: number;
    deletedIds: string[];
}): DeleteEventsEndType {
    return { type: DELETE_EVENTS_END, payload };
}

export const SET_STATUS_EVENTS_START = '[EVENTS] SET_STATUS_EVENTS_START';
export type SetStatusEventsStartType = {
    type: typeof SET_STATUS_EVENTS_START;
    payload: { _ids: string[]; status: boolean };
};
export function SetStatusEventsStart(payload: {
    _ids: string[];
    status: boolean;
}): SetStatusEventsStartType {
    return { type: SET_STATUS_EVENTS_START, payload };
}

export const SET_STATUS_EVENTS_END = '[EVENTS] SET_STATUS_EVENTS_END';
export type SetStatusEventsEndType = {
    type: typeof SET_STATUS_EVENTS_END;
    payload: { modifiedCount: number; updatedIds: string[]; status: boolean };
};
export function SetStatusEventsEnd(payload: {
    modifiedCount: number;
    updatedIds: string[];
    status: boolean;
}): SetStatusEventsEndType {
    return { type: SET_STATUS_EVENTS_END, payload };
}


export const GET_EVENT_UPDATE_START = '[EVENTS] GET_EVENT_UPDATE_START';
export type GetEventUpdateStartType = {
    type: typeof GET_EVENT_UPDATE_START;
    payload: { _id: string };
};
export function GetEventUpdateStart(payload: {
    _id: string;
}): GetEventUpdateStartType {
    return { type: GET_EVENT_UPDATE_START, payload };
}

export const GET_EVENT_UPDATE_END = '[EVENTS] GET_EVENT_UPDATE_END';
export type GetEventUpdateEndType = {
    type: typeof GET_EVENT_UPDATE_END;
    payload: { result: ManageEvenType };
};
export function GetEventUpdateEnd(payload: {
    result: ManageEvenType;
}): GetEventUpdateEndType {
    return { type: GET_EVENT_UPDATE_END, payload };
}

export const TOOGLE_AFFECT_TO = '[EVENTS] TOOGLE_AFFECT_TO';
export type ToogleAffectToActionType = {
    type: typeof TOOGLE_AFFECT_TO;
    payload?: {_id: string};
};
export function ToogleAffectToAction(
    payload?: {_id: string}
): ToogleAffectToActionType {
    return { type: TOOGLE_AFFECT_TO, payload };
}

export const ASSIGN_VEHICLE_ALARM_START = '[EVENTS] ASSIGN_VEHICLE_ALARM_START';
export type SetVehicleAssignStartActionType = {
    type: typeof ASSIGN_VEHICLE_ALARM_START;
    payload: string[];
};
export function AssignVehicleAlarmStartAction(
    payload: string[]
): SetVehicleAssignStartActionType {
    return { type: ASSIGN_VEHICLE_ALARM_START, payload };
}

export const ASSIGN_VEHICLE_ALARM_END = '[EVENTS] ASSIGN_VEHICLE_ALARM_END';
export type SetVehicleAssignEndActionType = {
    type: typeof ASSIGN_VEHICLE_ALARM_END;
    payload: {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount: number;
    };
};
export function AssignVehicleAlarmEndAction(payload: {
    _id: string;
    updatedBy: string;
    updatedAt: string;
    modifiedCount: number;
}): SetVehicleAssignEndActionType {
    return { type: ASSIGN_VEHICLE_ALARM_END, payload };
}



// V2 
export const SET_TIMES_EVENT_TYPE = '[EVENTS] SET_TIMES_EVENT_TYPE';
export type SetTimeEventActionType = {
    type: typeof SET_TIMES_EVENT_TYPE;
    payload: any;
};
export function SetTimeEventType(payload: any): SetTimeEventActionType {
    return { type: SET_TIMES_EVENT_TYPE, payload };
}

export const SET_SELECT_TYPE_ALARM = '[ALARM] SET_SELECT_TYPE_ALARM';
export type SetSelectTypeAlarmActionType = {
    type: typeof SET_SELECT_TYPE_ALARM;
    payload: {data?: {label: string, value: string}, position: number};
};
export function SetSelectTypeAlarmAction(payload: {data?: {label: string, value: string}, position: number}): SetSelectTypeAlarmActionType {
    return { type: SET_SELECT_TYPE_ALARM, payload };
}

export const SET_SELECT_SUB_TYPE_ALARM = '[ALARM] SET_SELECT_SUB_TYPE_ALARM';
export type SetSelectSubTypeAlarmActionType = {
    type: typeof SET_SELECT_SUB_TYPE_ALARM;
    payload: {data?: {label: string, value: string, type: string}, position: number};
};
export function SetSelectSubTypeAlarmAction(payload: {data?: {label: string, value: string, type: string}, position: number}): SetSelectSubTypeAlarmActionType {
    return { type: SET_SELECT_SUB_TYPE_ALARM, payload };
}

export const SET_SELECT_VALUE_ALARM = '[ALARM] SET_SELECT_VALUE_ALARM';
export type SetSelectValueAlarmActionType = {
    type: typeof SET_SELECT_VALUE_ALARM;
    payload: {data?: string, position: number};
};
export function SetSelectValueAlarmAction(payload: {data?: string, position: number}): SetSelectValueAlarmActionType {
    return { type: SET_SELECT_VALUE_ALARM, payload };
}

export const CHANGE_EVENT_OPERATION = '[EVENTS] CHANGE_EVENT_OPERATION';
export type ChangeEventOperationActionType = {
    type: typeof CHANGE_EVENT_OPERATION;
    payload: { position: number; operation: string };
};
export function ChangeEventOperation(payload: {
    position: number;
    operation: string;
}): ChangeEventOperationActionType {
    return { type: CHANGE_EVENT_OPERATION, payload };
}



export type EventsActionType =
    | AddNewEventActionType
    | RemoveNewEventActionType
    | ChangeEventOperationActionType
    | SetEventLogicActionType
    | SetEventSelectedZoneActionType
    | SetTimeEventActionType
    | SetUserEventNotificationActionType
    | ClearEventCreationActionType
    | CreateEventStartType
    | CreateEventEndType
    | TooglePoupType
    | SetSelectedEventsType
    | GetEventsStartType
    | GetEventsEndType
    | DeleteEventsStartType
    | DeleteEventsEndType
    | SetStatusEventsStartType
    | SetStatusEventsEndType
    | GetEventUpdateStartType
    | GetEventUpdateEndType
    | ToogleAffectToActionType
    | SetVehicleAssignStartActionType
    | SetVehicleAssignEndActionType
    | SetSelectTypeAlarmActionType
    | SetSelectSubTypeAlarmActionType
    | SetSelectValueAlarmActionType
    | ErrorActionType;

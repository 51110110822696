import { useState, useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineEye, AiOutlineFileExcel } from "react-icons/ai";


import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, CardHeader, CardBody, CardFooter, Row, Col, Progress } from "reactstrap";

import { DownloadReportStart, GetReportsStart, SetSelectedUsers } from "./redux/report.actions";
import { InputSearch } from "../../../Components/Form/InputSearch";
import { TableUi } from "../../../Components/Table";
import { BadgeUi } from "../../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../../Components/basic/Card";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { Paginator } from "../../../Components/basic/Paginator";
import { ColorType } from "../../../Components/types";
import { formatDate } from "../../../helpers/dateHelper";
import { useAppSelector } from "../../../redux/hooks";
import { ActionTabBar } from "../../Company/components/ActionTabBar";
import { Tabs } from "../../../Components/Tabs";
import { Avatar } from "../../../Components/basic/avatar/avatar";


export const COLOR_TYPE_USER: {[key: string]: ColorType} = {
    'user': 'info',
    'sub_admin_company': 'warning',
    'admin_company': 'danger',
    'admin': 'success'
}

const ReportAsyncPage = () => {
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },

    ];
    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { selectedUsers } =
        useAppSelector((state) => state.userReducer);

    const { report, filter, loading } =
    useAppSelector((state) => state.reportReducer);

    const { user } = useAppSelector((state) => state.authReducer);
    if (user?.type === 'admin' && !user?.connectedAs.type) {
        FILTERS = [
            ...FILTERS,
            ...[{ label: 'USER.ADMINS', value: 'admin_company' }],
            ...[{ label: 'USER.USERS', value: 'user' }],
        ];
    }

    useEffect(() => {
        dispatch(GetReportsStart(filter));
        const _interval = setInterval(() => {
            dispatch(GetReportsStart(filter));
        }, 3000);
        return () => {
            clearInterval(_interval);
        };
    }, [filter]);


    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
                navigate({
                    pathname: `/reports/${_ids[0]}`,
                });
                break;
        }
    }

    const download = (_id: string) =>  dispatch(DownloadReportStart({_id}));

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEye,
            name: intl.formatMessage({ id: 'COMMON.SHOW' }),
            action: 'view'
        },
    ];


    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.STATUS' }),
            dataIndex: 'status',
            key: 'status',
            render: (status: number) => 
                status === -1 ? <BadgeUi color={"warning"}>En attente</BadgeUi>: 
                <Progress 
                            color={status !== 100 ? "warning" : 'primary'}
                            animated={status !== 100}
                            value={status}>{status}%</Progress>
            
        },
        {
            title: intl.formatMessage({ id: 'COMMON.TYPE' }),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => {
                return(<BadgeUi color={COLOR_TYPE_USER[type]} light><FormattedMessage id={type}></FormattedMessage></BadgeUi>)
            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.CREATED_AT' }),
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.CREATED_BY' }),
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (name: string) => {
                return (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" text={name} rounded={"rounded-circle"}></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>)
                
            }
            
        },
        {
            title:intl.formatMessage({ id: 'COMMON.DOWNLOAD' }),
            dataIndex: 'download',
            key: 'download',
            render: (name: string, data: any) => 
                data.status !== 100 ? '-' : 
                (<div className="d-flex align-items-center">
                    <div className="me-2" style={{cursor: 'pointer'}} onClick={()=>download(data.key)}>
                        <AiOutlineFileExcel className="text-success" size={21}/>
                    </div>
                    {/* <div className="me-2" style={{cursor: 'pointer'}}>
                        <AiOutlineFilePdf className="text-danger" size={21}/>
                    </div> */}
                </div>)
        },
        // {
        //     title:intl.formatMessage({ id: 'COMMON.VIEW' }),
        //     dataIndex: 'view',
        //     key: 'view',
        //     render: (name: string, data: any) => 
        //         data.status !== 100 ? '-' : 
        //         (<div className="d-flex align-items-center">
        //             <Link to={`/reports/${data.key}`} className="me-2" style={{cursor: 'pointer'}}>
        //                 <FaArrowRight className="text-primary" size={16}/>
        //             </Link>
        //         </div>)
        // },
    ];

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedUsers(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetReportsStart({ ...filter, ...{ select: FILTERS[index].value } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetReportsStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetReportsStart({ ...filter, ...{ skip, limit } }));
    };

    return (
        <div id="reportPage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'REPORT' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'REPORT.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading && report.list.length === 0}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            selectedUsers.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${report.total})`}</div>)}
                            </Tabs>
                            :
                            <ActionTabBar selected={selectedUsers} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={report.list}
                            selected={selectedUsers}
                            onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+60+ (Math.ceil(report.total / filter.limit) > 1 ? 60 : 0)}

                        />
                    </CardBody>
                    {
                        Math.ceil(report.total / filter.limit) > 1 ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={report.total}
                                        skip={filter.skip}
                                        limit={filter.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                   
                </CardUi>

            </Container>
        </div>
    );
}

export default ReportAsyncPage;
import { axiosInstance } from '../../../../redux/setupAxios';
import { VehicleInfoListResponse, VehicleStartListType } from './types';

export async function fetchUnits(
    payload: VehicleStartListType
): Promise<{ data: VehicleInfoListResponse }> {
    const { skip, limit, name, status } = payload;

    let params = `skip=${skip}&limit=${limit}`;
    if (name) {
        params += `&filter[item][name]=${name}`;
    }
    if (status) {
        params += `&filter[item][status]=${status}`;
    }
    const url = `vehicle/map?${params}`;
    return axiosInstance.get(url);
}

export async function fetchShareVehicles(
    payload: VehicleStartListType & {_id: string}
): Promise<{ data: VehicleInfoListResponse }> {
    const { skip, limit, name, _id } = payload;

    let params = `skip=${skip}&limit=${limit}`;
    if (name) {
        params += `&filter[item][name]=${name}`;
    }
    const url = `share/${_id}/map?${params}`;
    return axiosInstance.get(url);
}



import classNames from "classnames";
import { AiOutlineMinus, AiOutlinePlus, AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { MdCenterFocusStrong, MdDeviceHub, MdLayers, MdNearMeDisabled, MdSwapCalls } from "react-icons/md";
import { useDispatch } from "react-redux";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { FitBoundsAction } from "../../../pages/Map/Objects/_redux/unit-list.actions";
import { useAppSelector } from "../../../redux/hooks";
import { SetOptionMapAction } from "../../../redux/rootActions";

export function ActionMapControl({sharedMap}: {sharedMap?: boolean}): JSX.Element {
    const dispatch = useDispatch();
    const {map, optionsMap} = useAppSelector((state) => state.mainReducer);

    const showMarkersClassName = classNames({
        'mb-1': true,
        'active': !optionsMap.showMarker
      });

      const isClusteredClassName = classNames({
        'mb-1': true,
        'active': optionsMap.isClustered
      });

    return (
        <div className="action-map-control">
            <div className="d-flex flex-column">
                <ButtonUi className="mb-1" color={"default"} onClick={()=> map.zoomIn()}>
                    <AiOutlinePlus size={18}/>
                </ButtonUi>
                <ButtonUi color={"default"} onClick={()=> map.zoomOut()}>
                    <AiOutlineMinus size={18} />
                </ButtonUi>
            </div>

            <div className="mt-5 d-flex flex-column">
                <ButtonUi 
                    className={showMarkersClassName} 
                    color={"default"} 
                    onClick={()=> dispatch(SetOptionMapAction({opt: 'showMarker', value: !optionsMap.showMarker}))}
                >
                    <MdNearMeDisabled size={18}/>
                </ButtonUi>
                <ButtonUi
                    disabled={!optionsMap.showMarker}
                    className={isClusteredClassName}
                    color={"default"} 
                    onClick={()=> dispatch(SetOptionMapAction({opt: 'isClustered', value: !optionsMap.isClustered}))}
                >
                    <MdDeviceHub size={18}/>
                </ButtonUi>
                <ButtonUi color={"default"} onClick={()=> dispatch(FitBoundsAction())} >
                    <MdCenterFocusStrong size={18}/>
                </ButtonUi>
            </div>

            <div className="mt-5 d-flex flex-column">
                {
                    sharedMap && <ButtonUi className="mb-1" color={"default"}>
                        <MdSwapCalls size={18}/>
                    </ButtonUi>
                }
                <ButtonUi color={"default"} >
                    <MdLayers size={18}/>
                </ButtonUi>
            </div>
        </div>
    );
}

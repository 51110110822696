import { useEffect } from "react";
import { useAxios } from "../../useAxios";


type GroupPassengers = {
  name: string;
  items: { label: string, value: string }[]
}

type UseFetchGroupResponse = { 
  group: GroupPassengers | null;
  fetchGroupPassengerLoading: boolean;
  error?: string;
  fetchGroup: () => void;
  cancel: () => void;
}



type UseFetchGroupProps = {
  manual?: boolean,
  key?: string,
  type: "passengers" | "stops"
}

export function useFetchGroup(props: UseFetchGroupProps): UseFetchGroupResponse {
  const URL = `groups/${props.type}/${props.key}`;
  const { execute, data, cancel, error, loading, init } = useAxios<GroupPassengers>(props.manual);

  useEffect(() => {
    if(!props.manual && props.key) {
      fetchGroup();
    }
  }, []);

  const fetchGroup = () => {
    execute(URL, { method: "GET"});
  };

  return { 
    group: data,
    fetchGroupPassengerLoading: loading, 
    error,
    fetchGroup,
    cancel, 
  };
}

import React, { useEffect } from "react";
import { useState } from "react";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
import { ButtonUi } from "../basic/ButtonUi";


export const FullScreenDropdown = () => {
    const dc:any = window.document
    const [isFullScreenMode, setIsFullScreenMode] = useState(true);
    
    const toggleFullscreen = () => {
        setIsFullScreenMode(!isFullScreenMode);
        if (
            !dc.fullscreenElement &&
            !dc.mozFullScreenElement &&
            !dc.webkitFullscreenElement
        ) {
            // current working methods
            if (dc.documentElement.requestFullscreen) {
                dc.documentElement.requestFullscreen();
            } else if (dc.documentElement.mozRequestFullScreen) {
                dc.documentElement.mozRequestFullScreen();
            } else if (dc.documentElement.webkitRequestFullscreen) {
                dc.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (dc.cancelFullScreen) {
                dc.cancelFullScreen();
            } else if (dc.mozCancelFullScreen) {
                dc.mozCancelFullScreen();
            } else if (dc.webkitCancelFullScreen) {
                dc.webkitCancelFullScreen();
            }
        }
    }

    useEffect(() => {
        window.document.addEventListener("fullscreenchange", exitHandler);
        window.document.addEventListener("webkitfullscreenchange", exitHandler);
        window.document.addEventListener("mozfullscreenchange", exitHandler);
        return () => {
            window.document.removeEventListener("fullscreenchange", exitHandler);
            window.document.removeEventListener("webkitfullscreenchange", exitHandler);
            window.document.removeEventListener("mozfullscreenchange", exitHandler);
        };
    }, []);

    const exitHandler = () => {
        const dc:any = window.document
        if (
            !dc.webkitIsFullScreen &&
            !dc.mozFullScreen &&
            !dc.msFullscreenElement
        )
            dc.body.classList.remove("fullscreen-enable");
    };

    return (
            <div className="ms-1 header-item d-none d-sm-flex">
                <ButtonUi   
                    className="btn-topbar" 
                    color="secondary" 
                    ghost 
                    circle 
                    Icon={isFullScreenMode ?  BiFullscreen : BiExitFullscreen}
                    onClick={toggleFullscreen}
                />
            </div>
    );
};
import { useContext, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { EmptyUi } from "../../basic/EmptyUi";
import { LayoutContext } from "../../../context/layout-style-context";

type ListMapDataProps = {
    Component: any,
    data: any[];
    minusHeight?: number;
    onEvent?: (key: string, action: string) => void;
}

export const ListMapData = (props: ListMapDataProps) => {
    const { height } = useContext(LayoutContext);
    const listRef = useRef<any>();

    const canShowFooter = true;
    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });
    

    const getIndex = () => {
        return undefined
    }

    const rowRenderer = ({ index, isScrolling, key, parent, style }: any) => {
        const { Component } = props;
        return (
            <CellMeasurer
              cache={cache}
              columnIndex={0}
              key={key}
              parent={parent}
              rowIndex={index}
            >
              {({ measure }) => (
                    <div key={key} style={style}>
                        <Component item={props.data[index]} onEvent={props.onEvent}/>
                    </div>
              )}
            </CellMeasurer>
          )
    };

    return (           
        <div style={{height: height - (props.minusHeight ?? 0)}}>
            <AutoSizer>
                {
                    ({ height, width }) => {
                        return(
                            props.data.length ? 
                            <List
                                ref={listRef}
                                width={width}
                                height={height > 0 ? height : 0}
                                rowCount={ props.data.length }
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={rowRenderer}
                                scrollToIndex={getIndex()}
                            />:
                            <EmptyUi msg="Aucune donnée"/>
                        )
                    }     
                } 
            </AutoSizer> 
        </div>        
    );
}
import { useEffect } from "react";
import { useAxios } from "../useAxios";

export type ReportTemplate = {
  "value": string, "label": string, "target": "unit"
}
type StopToAssignResult = {
    key: string;
    group: string;
    data: { label: string, value: string }[];
}

type useFetchStopsToAssignResponse = { 
  stopsToAssign: StopToAssignResult[];
  stopsToAssignLoading: boolean;
  error?: string;
  fetchStopsToAssign: (inputValue: string) => void;
  cancel: () => void;
}

type useFetchPassengersToAssignProps = {
  manual?: boolean
}
export function useFetchStopsToAssign(props: useFetchPassengersToAssignProps): useFetchStopsToAssignResponse {
  const URL = "stops/to-assign";
  const INIT_VALUE: StopToAssignResult[] = [];
  const { execute, data, cancel, error, loading } = useAxios<StopToAssignResult[]>();

  useEffect(() => {
    fetchStopsToAssign();
  }, [])

  const fetchStopsToAssign = async (inputValue?: string) => {
    execute(URL, { method: "GET", params: { name: inputValue } });
  };

  return { 
    stopsToAssign: data ?? INIT_VALUE, 
    fetchStopsToAssign, 
    cancel, 
    stopsToAssignLoading: loading, 
    error: error
  };
}

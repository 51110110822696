/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonUi } from './basic/ButtonUi';
import { ScrollBar } from './PerfectScrollBar';

const header = [
    '00h',
    '01h',
    '02h',
    '03h',
    '04h',
    '05h',
    '06h',
    '07h',
    '08h',
    '09h',
    '10h',
    '11h',
    '12h',
    '13h',
    '14h',
    '15h',
    '16h',
    '17h',
    '18h',
    '19h',
    '20h',
    '21h',
    '22h',
    '23h',
];
const days = ['Lu', 'Ma', 'Mer', 'Je', 'Ve', 'Sa', 'Di'];
const hours = header.map((hour) => {
    return {
        name: hour,
        description: `${hour.slice(0, 2)}:00`,
        class: 'cell',
    };
});
const times = days.map((day) => {
    return { name: day, data: hours };
});

type PlanningProps = {
    defaultTimesSelect?: any[],
    onChange?: (timesSelected: any[]) => void
}
export function Planning({defaultTimesSelect = [], onChange}: PlanningProps): JSX.Element {
    const refScrollBar = useRef();
    const refClicked = useRef(false);

    const changeValue = (alarmTimesSelected: any[]) => {
        if(onChange) onChange(alarmTimesSelected);
    }
    const downClickTable = (ev: any): void => {
        ev.preventDefault();
        refClicked.current = true;
    };

    const upClickTable = (ev: any): void => {
        ev.preventDefault();
        refClicked.current = false;
    };

    const always = (): void => {
        const alarmTimesSelected = [
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
        ];
        changeValue(alarmTimesSelected);
    };

    const weekend = (): void => {
        const alarmTimesSelected = [
            [],
            [],
            [],
            [],
            [],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
        ];
        changeValue(alarmTimesSelected);
    };

    const worksDay = (): void => {
        const alarmTimesSelected = [
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [
                '00h',
                '01h',
                '02h',
                '03h',
                '04h',
                '05h',
                '06h',
                '07h',
                '08h',
                '09h',
                '10h',
                '11h',
                '12h',
                '13h',
                '14h',
                '15h',
                '16h',
                '17h',
                '18h',
                '19h',
                '20h',
                '21h',
                '22h',
                '23h',
            ],
            [],
            [],
        ];
        changeValue(alarmTimesSelected);
    };

    const select = (ev: any, day: number, hour: number): void => {
        if (
            (refClicked.current && ev.type === 'mouseenter') ||
            ev.type === 'mousedown'
        ) {
            const dayHour = times[day].data[hour];
            const timesTmp = [...defaultTimesSelect];
            const i = timesTmp[day].indexOf(dayHour.name);
            if (i === -1) {
                timesTmp[day].push(dayHour.name);
            } else {
                timesTmp[day].splice(i, 1);
            }
            changeValue(timesTmp);
        }
    };

    useEffect(() => {
        if (defaultTimesSelect.length === 0) {
            always();
        }
    }, []);

    return (
        <div className="planifier">
            <ScrollBar
                id="popup-content-scrollbar"
                minusHeight={420}
                containerRef={refScrollBar}
            >
                <table
                    cellSpacing="0"
                    cellPadding="0"
                    onMouseDown={(event) => downClickTable(event)}
                    onMouseUp={(event) => upClickTable(event)}
                >
                    <colgroup>
                        <col className="day" />
                    </colgroup>
                    <thead>
                        <tr className="fixed-row">
                            <th />
                            {header.map((h) => (
                                <th key={h} className="hour">
                                    {h}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {times.map((day, i) => (
                            <tr key={day.name}>
                                <th className="day">{day.name}</th>
                                {day.data.map((time, j) => {
                                    const dayTime = defaultTimesSelect[i];
                                    const classSelection =
                                        dayTime &&
                                        dayTime.indexOf(time.name) !== -1
                                            ? `${time.class} selected`
                                            : time.class;
                                    return (
                                        <td
                                            key={time.name}
                                            className={classSelection}
                                            onMouseEnter={(event) =>
                                                select(event, i, j)
                                            }
                                            onMouseDown={(event) =>
                                                select(event, i, j)
                                            }
                                        />
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ScrollBar>
            <div className="hstack gap-2 justify-content-center mt-3">
                <ButtonUi
                    color="primary"
                    onClick={always}
                    size="sm"
                    outline
                >
                    <FormattedMessage id="ALARMS.ALWAYS"/>
                </ButtonUi>
                <ButtonUi
                    color="primary"
                    onClick={worksDay}
                    size="sm"
                    outline
                >
                    <FormattedMessage id="ALARMS.WORKS_DAY"/>
                </ButtonUi>
                <ButtonUi
                    color="primary"
                    onClick={weekend}
                    size="sm"
                    outline
                >
                    <FormattedMessage id="ALARMS.WEEKEND"/>
                </ButtonUi>
                
                
            </div> 
            
        </div>
    );
}

import { FilterMaintenanceType, GetMaintenanceType } from './../types';
import { GroupsMaintenance, MaintenanceInList, SelectEmailNotification } from "../types";

export const SET_MAINTENANCE_TYPE =
    '[MAINTENANCE] SET_MAINTENANCE_TYPE';
export type SetMaintenanceTypeActionType = {
    type: typeof SET_MAINTENANCE_TYPE;
    payload: { label: string; value: string,  __isNew__?: boolean } | null;
};
export function SetMaintenanceTypeAction(payload: {
    label: string;
    value: string;
    __isNew__?: boolean
} | null): SetMaintenanceTypeActionType {
    return { type: SET_MAINTENANCE_TYPE, payload };
}

export const SET_MAINTENANCE_VEHICLE =
    '[MAINTENANCE] SET_MAINTENANCE_VEHICLE';
export type SetMaintenanceVehicleActionType = {
    type: typeof SET_MAINTENANCE_VEHICLE;
    payload: { label: string; value: string } | null;
};
export function SetMaintenanceVehicleAction(payload: {
    label: string;
    value: string;
}): SetMaintenanceVehicleActionType {
    return { type: SET_MAINTENANCE_VEHICLE, payload };
}

export const TOOGLE_MAINTENANCE_CONFIG =
    '[MAINTENANCE] TOOGLE_MAINTENANCE_CONFIG';
export type ToogleMaintenanceActionType = {
    type: typeof TOOGLE_MAINTENANCE_CONFIG;
    payload: 'date'| 'km' | 'engineTime'
};
export function ToogleMaitenanceConfigAction(payload: 'date'| 'km' | 'engineTime'): ToogleMaintenanceActionType {
    return { type: TOOGLE_MAINTENANCE_CONFIG, payload };
}

export const SET_MAINTENANCE_NOTIFICATION_TYPE =
    '[MAINTENANCE] SET_MAINTENANCE_NOTIFICATION_TYPE';
export type SetEmailNotificationActionType = {
    type: typeof SET_MAINTENANCE_NOTIFICATION_TYPE;
    payload:  SelectEmailNotification[];
};
export function SetEventNotificationAction(payload: SelectEmailNotification[]): SetEmailNotificationActionType {
    return { type: SET_MAINTENANCE_NOTIFICATION_TYPE, payload };
}

export const GET_MAINTENANCE_VEHICLE_INFO_START =
    '[MAINTENANCE] GET_MAINTENANCE_VEHICLE_INFO_START';
export type GetMaintenanceVehicleInfoStartActionType = {
    type: typeof GET_MAINTENANCE_VEHICLE_INFO_START;
    payload:  {_id: string};
};
export function GetMaintenanceVehicleInfoStartAction(payload:{_id: string}): GetMaintenanceVehicleInfoStartActionType {
    return { type: GET_MAINTENANCE_VEHICLE_INFO_START, payload };
}

export const GET_MAINTENANCE_VEHICLE_INFO_END =
    '[MAINTENANCE] GET_MAINTENANCE_VEHICLE_INFO_END';
export type GetMaintenanceVehicleInfoEndActionType = {
    type: typeof GET_MAINTENANCE_VEHICLE_INFO_END;
    payload:  {km: number, engineTime: number};
};
export function GetMaintenanceVehicleInfoEndAction(payload: {km: number, engineTime: number}): GetMaintenanceVehicleInfoEndActionType {
    return { type: GET_MAINTENANCE_VEHICLE_INFO_END, payload };
}

export const ERROR = '[MAINTENANCE] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorMaintenance(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const SET_MAINTENANCE_NOTIFICATION_EMAIL = '[MAINTENANCE] SET_MAINTENANCE_NOTIFICATION_EMAIL';
export type SetMaintenanceNotificationEmailType = {
    type: typeof SET_MAINTENANCE_NOTIFICATION_EMAIL;
    payload: {label: string, value: string}[];
};
export function SetMaintenanceNotificationEmail( payload: {label: string, value: string}[]): SetMaintenanceNotificationEmailType {
    return { type: SET_MAINTENANCE_NOTIFICATION_EMAIL, payload };
}

export const SET_MAINTENANCE_TIME_DAY = '[MAINTENANCE] SET_MAINTENANCE_TIME_DAY';
export type SetMaintenanceTimeDayType = {
    type: typeof SET_MAINTENANCE_TIME_DAY;
    payload: Date;
};
export function SetMaintenanceTimeDay( payload: Date): SetMaintenanceTimeDayType {
    return { type: SET_MAINTENANCE_TIME_DAY, payload };
}

export const SAVE_NEW_MAINTENANCE_START = '[MAINTENANCE] SAVE_NEW_MAINTENANCE_START';
export type SaveNewMaintenanceStartType = {
    type: typeof SAVE_NEW_MAINTENANCE_START;
    payload: any;
};
export function SaveNewMaintenanceStart( payload: any): SaveNewMaintenanceStartType {
    return { type: SAVE_NEW_MAINTENANCE_START, payload };
}

export const SAVE_NEW_MAINTENANCE_END = '[MAINTENANCE] SAVE_NEW_MAINTENANCE_END';
export type SaveNewMaintenanceEndType = {
    type: typeof SAVE_NEW_MAINTENANCE_END;
};
export function SaveNewMaintenanceEnd(): SaveNewMaintenanceEndType {
    return { type: SAVE_NEW_MAINTENANCE_END };
}

export const GET_FILTERS_MAINTENANCE_START = '[MAINTENANCE] GET_FILTERS_MAINTENANCE_START';
export type GetFiltersMaintenanceStartType = { type: typeof GET_FILTERS_MAINTENANCE_START };
export function GetFiltersMaintenanceStart(): GetFiltersMaintenanceStartType {
    return { type: GET_FILTERS_MAINTENANCE_START };
}

export const GET_FILTERS_MAINTENANCE_END = '[MAINTENANCE] GET_FILTERS_MAINTENANCE_END';
export type GetFiltersMaintenanceEndType = {
    type: typeof GET_FILTERS_MAINTENANCE_END;
    payload: GroupsMaintenance[];
};
export function GetFiltersMaintenanceEnd( payload: GroupsMaintenance[]): GetFiltersMaintenanceEndType {
    return { type: GET_FILTERS_MAINTENANCE_END, payload };
}

export const GET_MAINTENANCE_LIST_START = '[MAINTENANCE] GET_MAINTENANCE_LIST_START';
export type GetMaintenanceListStartType = {
    type: typeof GET_MAINTENANCE_LIST_START;
    payload: FilterMaintenanceType;
};
export function GetMaintenanceListStart( payload: FilterMaintenanceType): GetMaintenanceListStartType {
    return { type: GET_MAINTENANCE_LIST_START, payload };
}

export const GET_MAINTENANCE_LIST_END = '[MAINTENANCE] GET_MAINTENANCE_LIST_END';
export type GetMaintenanceListEndType = {
    type: typeof GET_MAINTENANCE_LIST_END;
    payload: {
        result: {
            list: MaintenanceInList[];
            total: number;
        },
        filter: FilterMaintenanceType
    };
};
export function GetMaintenanceListEnd( payload: {
    result: {
        list: MaintenanceInList[];
        total: number;
    },
    filter: FilterMaintenanceType
}): GetMaintenanceListEndType {
    return { type: GET_MAINTENANCE_LIST_END, payload };
}

export const SET_SELECTED_MAINTENANCE = '[MAINTENANCE] SET_SELECTED_MAINTENANCE';
export type SetSelectedMaintenanceType = {
    type: typeof SET_SELECTED_MAINTENANCE;
    payload: string[];
};
export function SetSelectedMaintenance(payload: string[]): SetSelectedMaintenanceType {
    return { type: SET_SELECTED_MAINTENANCE, payload };
}

export const DELETE_MAINTENANCES_START = '[MAINTENANCE] DELETE_MAINTENANCES_START';
export type DeleteMaintenancesStartType = {
    type: typeof DELETE_MAINTENANCES_START;
    payload: {_ids:string[], groupId: string};
};
export function DeleteMaintenanceStart(payload: {_ids:string[], groupId: string}): DeleteMaintenancesStartType {
    return { type: DELETE_MAINTENANCES_START, payload };
}

export const DELETE_MAINTENANCES_END = '[DRIMAINTENANCEVER] DELETE_MAINTENANCES_END';
export type DeleteMaintenancesEndType = {
    type: typeof DELETE_MAINTENANCES_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteMaintenanceEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteMaintenancesEndType {
    return { type: DELETE_MAINTENANCES_END, payload };
}

export const GET_MAINTENANCE_START = '[MAINTENANCE] GET_MAINTENANCE_START';
export type GetMaintenanceStartType = {
    type: typeof GET_MAINTENANCE_START;
    payload: {_id: string, groupId: string};
};
export function GetMaintenanceStart(payload: {_id: string, groupId: string}): GetMaintenanceStartType {
    return { type: GET_MAINTENANCE_START, payload };
}

export const GET_MAINTENANCE_END = '[MAINTENANCE] GET_MAINTENANCE_END';
export type GetMaintenanceEndType = {
    type: typeof GET_MAINTENANCE_END;
    payload: GetMaintenanceType;
};
export function GetMaintenanceEnd(payload: GetMaintenanceType): GetMaintenanceEndType {
    return { type: GET_MAINTENANCE_END, payload };
}

export type MaintenanceActionType =
    | SetMaintenanceTypeActionType
    | SetMaintenanceVehicleActionType
    | ToogleMaintenanceActionType
    | SetEmailNotificationActionType
    | GetMaintenanceVehicleInfoStartActionType
    | GetMaintenanceVehicleInfoEndActionType
    | ErrorActionType
    | SetMaintenanceNotificationEmailType
    | SetMaintenanceTimeDayType
    | SaveNewMaintenanceStartType
    | SaveNewMaintenanceEndType
    | GetFiltersMaintenanceStartType
    | GetFiltersMaintenanceEndType
    | GetMaintenanceListStartType
    | GetMaintenanceListEndType
    | SetSelectedMaintenanceType
    | DeleteMaintenancesStartType
    | DeleteMaintenancesEndType
    | GetMaintenanceStartType
    | GetMaintenanceEndType;
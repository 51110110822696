import { FilterType } from "../User/types";

export enum GeofenceAction {
    DELETE = "DELETE",
    EDIT = "EDIT"
}
export  enum GeofenceType {
    POLYGONE = "polygon",
    LINE = "stringLine",
    CIRCLE = 'circle',
    POINT_OF_INTEREST = "poi",
}

export type GeofenceInListType = {
    key: string;
    name: string;
    color: string;
    radius?: number;
    type: GeofenceType;
}

export type PointType = {
    latitude: number,
    longitude: number
};

export type ManageGeofence = {
    key?: string,
    type: GeofenceType,
    name: string,
    description: string,
    color: string,
    radius?: number
    point?: PointType
}

export type GetGeofencesResponse = {
    list: GeofenceInListType[];
    total: number;
};

export type FilterGeofenceType = FilterType & {
    select?: 'all' | GeofenceType;
};
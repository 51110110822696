import { useState, useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, CardHeader, CardBody, CardFooter, Row, Col } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { formatDate } from "../../helpers/dateHelper";
import { useAppSelector } from "../../redux/hooks";
import { ActionTabBar } from "../Company/components/ActionTabBar";
import { AssignVehiclesSlider } from "../components/VehicleAssignSlider";
import { DeleteTracersStart, GetTracersStart, SetSelectedTracers } from "./redux/tracer.actions";
import Emitter from "../../helpers/emitter";

const TracerPage = () => {
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },

    ];
    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { tracers, filter, selectedTracers, loading } =
        useAppSelector((state) => state.TracerReducer);

    const { user } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        if(tracers.list.length === 0) dispatch(GetTracersStart(filter));
    }, []);

    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
                navigate({
                    pathname: `/tracers/${_ids[0]}`,
                });
                break;
            case 'edit':
                navigate({
                    pathname: `/tracers/${_ids[0]}`,
                });
                break;
            case 'delete':
                Emitter.emit(Emitter.event.DELETE_POPUP, {
                    textConfirmation: "confirm",
                    title: _ids.length > 1 ? "Supprimer le traceur" : "Supprimer les traceurs",
                    description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                    onDelete: () => dispatch(DeleteTracersStart(_ids))

                });
                break;
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
        {
            name: 'divier'
        }
    ];


    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.STATUS' }),
            dataIndex: 'online',
            key: 'online',
            render: (status: boolean | undefined) => {
                switch(status) {
                    case true:
                        return(<BadgeUi color="success" light><FormattedMessage id="COMMON.CONNECTED" /></BadgeUi>);
                    case false:
                        return(<BadgeUi color="danger" light><FormattedMessage id="COMMON.DISCONNECTED" /></BadgeUi>);
                    default:
                        return(<BadgeUi color="info" light><FormattedMessage id="COMMON.NEW" /></BadgeUi>)
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'TRACER.UID' }),
            dataIndex: 'uid',
            key: 'uid',
        },
        
        {
            title: intl.formatMessage({ id: 'TRACER.SIM' }),
            dataIndex: 'sim',
            key: 'sim',
        },
        
        {
            title: intl.formatMessage({ id: 'COMMON.BRAND' }),
            dataIndex: 'brand',
            key: 'brand',
            render: (type: string) => {
                return(type)
            }
        },
        {
            title: intl.formatMessage({ id: 'COMMON.UNIT' }),
            dataIndex: 'unit',
            key: 'unit',
            render: (type: string) => {
                return(type)
            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.LAST_UPDATE' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.UPDATED_BY' }),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (name: string) => {
                return (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" rounded="rounded-circle" text={name}></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>)
                
            }
            
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedTracers(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetTracersStart({ ...filter, ...{ select: FILTERS[index].value } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetTracersStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetTracersStart({ ...filter, ...{ skip, limit } }));
    };

    return (
        <div id="tracerPage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'TRACER' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'TRACER.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            selectedTracers.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${tracers.total})`}</div>)}
                            </Tabs>
                            :
                            <ActionTabBar selected={selectedTracers} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={tracers.list}
                            selected={selectedTracers}
                            onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+60+ (Math.ceil(tracers.total / filter.limit) > 1 ? 60 : 0)}

                        />
                    </CardBody>
                    {
                        Math.ceil(tracers.total / filter.limit) > 1 ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={tracers.total}
                                        skip={filter.skip}
                                        limit={filter.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                   
                </CardUi>

            </Container>
            <AssignVehiclesSlider/> 
        </div>
    );
}

export default TracerPage;
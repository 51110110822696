import { useState, useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, CardHeader, CardBody, CardFooter, Row, Col } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { ColorType } from "../../Components/types";
import { formatDate } from "../../helpers/dateHelper";
import { useAppSelector } from "../../redux/hooks";
import { ActionTabBar } from "../Company/components/ActionTabBar";
import { AssignVehiclesSlider } from "../components/VehicleAssignSlider";
import { DeleteVehiclesStart, GetVehiclesStart, SetSelectedVehicles } from "./redux/vehicleModule.actions";
import Emitter from "../../helpers/emitter";


export const COLOR_TYPE_USER: {[key: string]: ColorType} = {
    'user': 'info',
    'sub_admin_company': 'warning',
    'admin_company': 'danger',
    'admin': 'success'
}

const VehiclePage = () => {
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },

    ];
    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { vehicles, filter, selectedVehicles, loading } =
        useAppSelector((state) => state.vehicleModuleReducer);

    const { user } = useAppSelector((state) => state.authReducer);

    useEffect(() => {
        dispatch(GetVehiclesStart(filter));
    }, []);

    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
                navigate({
                    pathname: `/units/${_ids[0]}`,
                });
                break;
            case 'edit':
                navigate({
                    pathname: `/units/${_ids[0]}`,
                });
                break;
            case 'delete':
                Emitter.emit(Emitter.event.DELETE_POPUP, {
                    textConfirmation: "confirm",
                    title: _ids.length > 1 ? "Supprimer le véhicule" : "Supprimer les véhicules",
                    description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                    onDelete: () => dispatch(DeleteVehiclesStart(_ids))
                });
                
                break;
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
        {
            name: 'divier'
        },
        {
            Icon: MdContentCopy,
            name: intl.formatMessage({ id: 'COMMON.CLONE' }),
            action: 'switchTo'
        },
    ];


    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.STATUS' }),
            dataIndex: 'status',
            key: 'status',
            render: (status: boolean) => {
                if(status) {
                    return(<BadgeUi color="success" light><FormattedMessage id="COMMON.ACTIVED" /></BadgeUi>);
                }
                return(<BadgeUi color="danger" light><FormattedMessage id="COMMON.DEACTIVED" /></BadgeUi>)
            }
        },
        {
            title: intl.formatMessage({ id: 'COMMON.NAME' }),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({ id: 'UNIT.TRACER' }),
            dataIndex: 'tracerName',
            key: 'tracerName',
        },
        {
            title: intl.formatMessage({ id: 'COMMON.TYPE' }),
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => {
                return(type)
            }
        },
        {
            title: intl.formatMessage({ id: 'UNIT.PLATE' }),
            dataIndex: 'plate',
            key: 'plate',
        },
        {
            title:intl.formatMessage({ id: 'COMMON.LAST_UPDATE' }),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (date: string) => user?.timezone ? formatDate(date, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
        },
        {
            title:intl.formatMessage({ id: 'COMMON.UPDATED_BY' }),
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            render: (name: string) => {
                return (<div className="d-flex align-items-center">
                    <Avatar size="xxs" color="primary" rounded="rounded-circle" text={name}></Avatar> 
                    <span className="ms-1 text-muted">{name}</span>
                </div>)
                
            }
            
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedVehicles(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetVehiclesStart({ ...filter, ...{ select: FILTERS[index].value } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetVehiclesStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetVehiclesStart({ ...filter, ...{ skip, limit } }));
    };

    return (
        <div id="vehiclePage" className="page-content">
            
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'UNIT' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'UNIT.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={loading}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            selectedVehicles.length === 0 ? 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${vehicles.total})`}</div>)}
                            </Tabs>
                            :
                            <ActionTabBar selected={selectedVehicles} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={vehicles.list}
                            selected={selectedVehicles}
                            onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+60+ (Math.ceil(vehicles.total / filter.limit) > 1 ? 60 : 0)}
                        />
                    </CardBody>
                    {
                        Math.ceil(vehicles.total / filter.limit) > 1 ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={vehicles.total}
                                        skip={filter.skip}
                                        limit={filter.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                   
                </CardUi>
            </Container>
            <AssignVehiclesSlider/> 
        </div>
    );
}

export default VehiclePage;
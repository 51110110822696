import { Alert, Badge, UncontrolledAlert } from "reactstrap"
import { ColorType } from "../types"
import classNames from 'classnames';


type alertUiType = {
    color: ColorType
    borderless?: boolean;
    borderLeft?: boolean;
    dismissible?: boolean;
    isOpen?: boolean;
    toogle?: ()=> void;
    outline?: boolean;
    solid?: boolean;
    children: JSX.Element | string;
    className?: string;
}

export const AlertUi = ({color, borderless, dismissible, toogle, outline, solid, borderLeft, isOpen, className, children}: alertUiType) => {
    const alertClassName = classNames({
        [`alert-borderless`]: borderless && !borderLeft,
        [`alert-border-left`]: !borderless && borderLeft,
        [`outline`]: outline && !borderLeft,
        [`solid`]: solid && !outline,
        [`${className}`]: !!className,
      });

    if(dismissible) {
        return <UncontrolledAlert color={color} className={alertClassName} >{children}</UncontrolledAlert>
    }
    return <Alert color={color} className={alertClassName} isOpen={isOpen} toogle={toogle}>{children}</Alert>
}



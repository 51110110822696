/* eslint-disable  */
import { useEffect, useRef } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
import L from "leaflet";
import 'leaflet-draw';
import 'leaflet-draw-drag';
import { useAppSelector } from "../../../redux/hooks";
import { GeofenceType } from "../../Geofence/types";
import { SetGeofence, SetGeofenceRadius } from "../../Geofence/_redux/geofenceModule.actions";
import { useDispatch } from "react-redux";
import { usePrevious } from "../../../common/hooks/usePrevious";

export const GeofenceDrawer = () => {
    const featureRef = useRef();
    const map = useMap();
    const dispatch = useDispatch();
    const { manage } = useAppSelector((state) => state.geofenceModuleReducer);
    const previousType = usePrevious(manage.type);
    
    const canUpdate = !!(manage.geofence && (previousType === manage.type || manage.key));
    const clearEditZone = () => {
        const layersKey= Object.keys(featureRef.current._layers);
        if(layersKey.length > 0) {
            featureRef.current._layers[layersKey[0]].editing.disable();
            featureRef.current.removeLayer(featureRef.current._layers[layersKey[0]]);
        }
    }

    const drawPolygon = () => {
        let polygon;
        
        if(!canUpdate) {
            const currentPoint = map.latLngToContainerPoint(map.getCenter()); 
            const width = 0;
            const height = 0;
            const xDifference = width*0.5;
            const yDifference = height*0.5; 
    
            
            const b = L.point((currentPoint.x + xDifference), (currentPoint.x + xDifference));
            const c = L.point((currentPoint.y + yDifference), (currentPoint.y + yDifference));
            const d = L.point((currentPoint.x + xDifference), (currentPoint.y - yDifference));
            polygon = new L.Polygon(
                [
                    map.containerPointToLatLng(d),
                    map.containerPointToLatLng(b),
                    map.containerPointToLatLng(c),   
                ], {color: manage.color, draggable: true});
        } else {
            const data = {type: 'Feature', properties: {}, geometry: manage.geofence};
            const geoJson = new L.GeoJSON(data, {color: manage.color, draggable: true});
            const layer = geoJson.getLayers();
            polygon = layer[0];
        }
            
        featureRef.current.addLayer(polygon);
        polygon.editing.enable();
        map.dragging.enable();
        dispatch(SetGeofence({geofence: polygon.toGeoJSON().geometry}));
        map.fitBounds(featureRef.current.getBounds());
        polygon.on('edit', (e) => {
            dispatch(SetGeofence({geofence: polygon.toGeoJSON().geometry}));
            map.dragging.enable();
        });
    }

    const drawCircle = () =>{
        let circle;
        if(canUpdate) {
            let coordinates = [manage.geofence.coordinates[1], manage.geofence.coordinates[0]];
            if(manage.point) {
                coordinates = [manage.point.latitude, manage.point.longitude];
            }
           circle = new L.Circle(coordinates, manage.radius ?? 3000, {color: manage.color, draggable: true});
        } else {
           circle = new L.Circle(map.getCenter(), 3000, {color: manage.color, draggable: true});
           dispatch(SetGeofenceRadius({radius: circle.getRadius()}));
        }
        featureRef.current.addLayer(circle);
        circle.editing.enable();
        map.dragging.enable();
        dispatch(SetGeofence({geofence: circle.toGeoJSON().geometry}));
        map.fitBounds(featureRef.current.getBounds());
        circle.on('edit', (e) => {
            dispatch(SetGeofenceRadius({radius: circle.getRadius()}));
            dispatch(SetGeofence({geofence: circle.toGeoJSON().geometry}));
            map.dragging.enable();
        });
    }

    useEffect(()=> {
        clearEditZone();
        
        if(manage.type === undefined ) {
            return;
        }

        switch(manage.type) {
            case GeofenceType.POLYGONE: {
                drawPolygon();
                break;
            }

            case GeofenceType.POINT_OF_INTEREST:
            case GeofenceType.CIRCLE: {
                drawCircle();
                break;
            }
        }
        
    }, [manage._id, manage.type, manage.color, manage.radius, manage.point]);

    return (
        <FeatureGroup ref={featureRef}/>
    );
}

import { EmptyUi } from '../../../../Components/basic/EmptyUi';
import { BasicPlot } from '../../../../Components/Plotly';
import { arrayEquals } from '../../../../helpers/comapre';
import { useAppSelector } from '../../../../redux/hooks';

export const FuelChart =({unit}: {unit: string}) => {

    const { chart, trip, loading } = useAppSelector((state) => {
        return { 
            loading: state.fuelReducer.loading,
            chart: state.fuelReducer.chart,
            trip: state.historyReducer.trip,
        };
    }, (item, previousItem) => {
        return(
            arrayEquals(item.chart?.fuel ?? [], previousItem.chart?.fuel ?? []) && 
            arrayEquals(item.chart?.date ?? [], previousItem.chart?.date ?? []) && 
            arrayEquals(item.trip.data ?? [], previousItem.trip?.data ?? [])
        ) 
            
    });

    if (!chart && loading === false) {
        return <EmptyUi msg="Aucun capteur trouvé"/>
    }

    return (
        <BasicPlot
                data={[
                    {
                        fill: 'tonexty',
                        fillcolor: '#4051894d',
                        x: chart?.date || [],
                        y: chart?.fuel || [],
                        type: 'scatter',
                        mode: 'lines',
                        marker: { color: '#405189' },
                        hovertemplate: `<br><b>Date</b>: %{x|%Y/%m/%d %H:%M:%S}<br><b>Fuel:</b> %{y} ${unit ?? '-'}<extra></extra>`,
                    },
                    // {
                    //     x: chart?.date || [],
                    //     y: chart?.fuel || [],
                    //     mode: 'markers',
                    //     type: 'scatter',
                    //     marker: { size: 5, color: '#405189' }
                    //   }
                ]}
                layout={{
                    width: 450,
                    height: 170,
                    showlegend: false,
                    margin: { t: 0, b: 20, l: 0, r: 0 },
                    xaxis: {
                        anchor: 'free',
                        showspikes: true,
                        spikemode: 'across',
                        spikethickness: 1,
                        spikesnap: 'cursor',
                    },
                    yaxis: {
                        anchor: 'free',
                        showspikes: true,
                        spikemode: 'across',
                        spikethickness: 1,
                        spikesnap: 'cursor',
                    },
                    shapes: trip.data.map((trip) => {
                        return {
                            type: 'rect',
                            xref: 'x',
                            yref: 'paper',
                            x0: trip.startDate.toDate(),
                            y0: 0,
                            x1: trip.endDate.toDate(),
                            y1: 1,
                            fillcolor: '#4cc476',
                            opacity: 0.4,
                            line: {
                                width: 0,
                            },
                        };
                    }),
                }}
            />
    );
}
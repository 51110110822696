import { useContext, useEffect, useRef, useState } from "react";
import { AiFillNotification } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import {CardFooter, CardHeader, Col, Row} from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { CardBodyUi } from "../../../Components/basic/CardBodyUi";
import { useDispatch } from "react-redux";
import { Paginator } from "../../../Components/basic/Paginator";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { LayoutContext } from "../../../context/layout-style-context";
import { LIMIT } from "../../../env";
import { NotificationComponent } from "../../../Components/advanced/Notification-info";
import { useAxios } from "../../../common/hooks/axios/useAxios";
import { Filter, ListNotificationResponse } from "../types";
import { useAppSelector } from "../../../redux/hooks";





export const ListNotification = () => {
    // hooks
    const dispatch = useDispatch();
    const listRef = useRef<any>();
    const { height } = useContext(LayoutContext);
    const [{skip, limit}, setFilter] = useState<Filter>({skip: 0, limit: LIMIT})
    const { data, loading, execute } = useAxios<ListNotificationResponse>(true);
    const { user } = useAppSelector((state) => state.authReducer);

    // Effects
    useEffect(()=> {
        execute('event', {method: 'GET', params: {limit, skip}})
    }, [skip, limit]);

    // event functions
    const onPaginate = (skip: number, limit: number): void => setFilter({skip, limit});



    // values
    const canShowFooter = Math.ceil(data?.total ?? 0 / limit) > 1;
    
    
    // React virtualized
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 75,
    });

    const getIndex = () => {
        // if(vehicleActive && data?.list.length > 0) {
        //     const index = data.list.findIndex((v: any) => v.key === vehicleActive);
        //     if(index !== -1) {
        //         return index;
        //     }
        // }
        return undefined
    }

    return (
        <CardUi className="list-object">
            <LoaderUi loading={loading}/>
            <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                <h5 className="d-flex align-items-center mb-0 text-primary">
                    <AiFillNotification className="me-2"/>
                    <span><FormattedMessage id={"NOTIFICATION.LIST"}/></span>
                </h5>
            </CardHeader>
            
            <CardBodyUi className="p-0">  
                <div style={{height: height - (canShowFooter ? 220 : 200)}}>
                    
                    <AutoSizer>
                        {
                            ({ height, width }) => {
                                return(
                                    <List
                                        ref={listRef}
                                        width={width}
                                        height={height > 0 ? height : 0}
                                        rowCount={ data?.list ? data.list.length : 0 }
                                        deferredMeasurementCache={cache}
                                        rowHeight={cache.rowHeight}
                                        rowRenderer={rowRenderer}
                                        scrollToIndex={getIndex()}
                                    />
                                )
                            }     
                        } 
                    </AutoSizer> 
                </div>   
                
            </CardBodyUi>
            {
                canShowFooter ? (
                    <CardFooter>
                    <Row>
                        <Col>
                            <Paginator
                                skip={skip}
                                limit={limit}
                                total={data?.total ?? 0}
                                onPaginate={onPaginate}
                            />
                        </Col>
                    </Row>
                </CardFooter>
                ) : <></>
            }
            
        </CardUi>
    );

    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                    <NotificationComponent notification={data?.list[index]} timezone={user?.timezone}/>
                </div>
            )}
          </CellMeasurer>
        );
    }
}
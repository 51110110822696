import { FilterType } from "../../User/types";

export type SaveDriverType = {
    key?: string;
    name: string;
    code?: string;
    mobile?: string;
    description?: string;
};

export enum AssignmentType {
    MANUAL = 'manual',
    AUTOMATIC = 'automatic',
    BOTH = 'both',
}
  
export type DriverInListType = {
    key: string;
    name: string;
    assignment: {
        type: AssignmentType,
        to?: string,
        assignedDate?: Date,
        unAssignedDate?: Date,
    },
    position: {
        latitude?: number,
        longitude?: number
    }
    info?: any
}

export type DriverStartListType = FilterType;
export type UnitToAssignDriverType = {name?: string;};
export type UnitToAssignDriverInListType = {
    key: string,
    name: string,
    driver?: string | null
};

import { Input, Label } from "reactstrap";

type RowComponentType = {
    checked: boolean,
    columns: any[],
    data: any,
    haveSelected: boolean,
    toogleSelected: (key: string, isSelected: boolean) => void
}

export const TableRowComponent = ({checked, columns, data, haveSelected, toogleSelected}: RowComponentType) => {

    return (
        <tr>
            {
                haveSelected &&  <th scope="row">
                        <div className="form-check">
                            <Input 
                                className="form-check-input" 
                                type="checkbox" 
                                checked={checked} 
                                onClick={() => {
                                toogleSelected(data.key, !checked);
                            }} />
                            <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
                        </div>
                    </th>
            }
            
            
            {
                columns.map(column => {
                    if(column.render) {
                        return <td key={column.dataIndex}>{column.render(data[column.dataIndex], data) ?? '-'}</td>
                    }
                    return <td key={column.dataIndex}><div>{data[column.dataIndex] ?? '-'}</div></td>
                } )
            }
        </tr>
    )
}


// {/* <tr>
//                         <th scope="row">
//                             <div className="form-check">
//                                 <Input className="form-check-input" type="checkbox" defaultValue="" id="responsivetableCheck01" />
//                                 <Label className="form-check-label" htmlFor="responsivetableCheck01"></Label>
//                             </div>
//                         </th>
                        
//                         {/* <td><Link to="#w-medium">#VZ2110</Link></td>
//                         <td>10 Oct, 14:47</td>
//                         <td className="text-success"><i className="ri-checkbox-circle-line fs-17 align-middle"></i> Paid</td>
//                         <td>
//                             <div className="d-flex gap-2 align-items-center">
//                                 <div className="flex-shrink-0">
//                                     <img src={""} alt="" className="avatar-xs rounded-circle" />
//                                 </div>
//                                 <div className="flex-grow-1">
//                                     Jordan Kennedy
//                                 </div>
//                             </div>
//                         </td>
//                         <td>Mastering the grid</td>
//                         <td>$9.98</td> */}
//                     </tr> */}
import { useAxios } from "../../useAxios";


type ManageGroupProps = {
    key?: string
    name: string;
    items: string[]
}

type UseManageGroupResponse = { 
  response: boolean | undefined;
  manageGroupLoading: boolean;
  error?: string;
  saveGroup: (payload: ManageGroupProps) => void;
  saveAsyncGroup: (payload: ManageGroupProps) => Promise<{
    success: any;
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}



type UseManageGroupProps = {
  type: 'passenger' | 'stop',
  manual?: boolean,
  key?: string,
}

export function useManageGroup(props: UseManageGroupProps): UseManageGroupResponse {
  const URL = `groups/${props.type}/${props.key ?? ''}`;
  const { execute, data, cancel, error, loading } = useAxios<UseManageGroupResponse>(props.manual);

  const saveGroup = (data: ManageGroupProps) => {
    saveAsyncGroup(data);
  };

  const saveAsyncGroup = async (data: ManageGroupProps) => {
    return execute(URL, { method: props.key ? "PATCH" : "POST", data });
  };

  return { 
    response: data?.response,
    manageGroupLoading: loading, 
    error,
    saveGroup,
    saveAsyncGroup, 
    cancel, 
  };
}

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Emitter from '../../../../helpers/emitter';
import EmitterActions from '../../../../helpers/emitter/emitter.actions';
import { useAxios } from '../../../../common/hooks/axios/useAxios';
import { DriverAction } from '../../../../pages/Driver/types';
import { RateUiComponent } from '../../../basic/RateUi';
import { ViewPointProgressBarComponent } from './ViewPointProgressBar';
type ViewPointDetailComponentProps = {
    className?: string,
    onClick?: (key: string, action: DriverAction) => void,
    [key: string]: any,
}

type reviewRateDetailsProps = {
    reviewRate: { score: number, total: number },
    details: { 1: number, 2: number, 3: number, 4: number, 5: number }
}
export const ViewPointDetailComponent = ({className, onClick, ...other}: ViewPointDetailComponentProps) => {
    const { key } = useParams();
    const url = `driver/${key}/review-rate/details`
    const {data, loading, execute} = useAxios<reviewRateDetailsProps>(
        false, 
        url, 
        { method: 'GET'}
    );

    const fetchData = () => execute(url, {method: 'GET'});
    useEffect( () => {
        Emitter.on(EmitterActions.REFRESH_DRIVER_REVIEW_RATE, fetchData);
        return () => {
            Emitter.off(EmitterActions.REFRESH_DRIVER_REVIEW_RATE, fetchData);
        };
    }, []);

    return(
        <div className={`d-flex flex-row justify-content-between align-items-center ${className ? className : ''}`} {...other}>
            <div className="d-flex" style={{flex: 1}}>
                <div className="flex-grow-1 me-5">
                    <ViewPointProgressBarComponent  score={5} percentage={data?.details[5] ?? 0} />
                    <ViewPointProgressBarComponent  score={4} percentage={data?.details[4] ?? 0}/>
                    <ViewPointProgressBarComponent  score={3} percentage={data?.details[3] ?? 0}/>
                    <ViewPointProgressBarComponent  score={2} percentage={data?.details[2] ?? 0}/>
                    <ViewPointProgressBarComponent  score={1} percentage={data?.details[1] ?? 0}/>
                </div>
            </div>
            <div className="d-flex mt-1">
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <h1 className="me-2 mb-0">{data?.reviewRate.score?.toFixed(1) ?? '-'}</h1>
                    <div className="d-flex flex-column">
                        <RateUiComponent disabled mark={data?.reviewRate.score ?? 0} allowHalf allowColor/>
                        <span style={{fontWeight: 500}} className="fs-11 text-secondary align-self-end">({data?.reviewRate.total ?? '-'} avis)</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

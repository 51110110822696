import { useState } from "react";
import { CardHeader, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import { Avatar } from "../../Components/basic/avatar/avatar";
import { BreadCrumbUi } from "../../Components/basic/BreadCrumbUi";
import { CardUi } from "../../Components/basic/Card";
import { CardBodyUi } from "../../Components/basic/CardBodyUi";
import { Tabs } from "../../Components/Tabs";
import { useAppSelector } from "../../redux/hooks";
import { General } from "./Account/general";
import { Password } from "./Account/password";
import { FormattedMessage } from "react-intl";

const ProfilePage = () => {
    const [activeNav, setActiveNav] = useState(0);
    const { user } = useAppSelector(
        (state) => state.authReducer
    );
    const onChangeNav = (active: number) => {
        setActiveNav(active);
    }
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumbUi title={"Profile"} pageTitle={user?.company.name || ''} />
                <Row>
                    <Col xl={3}>
                        <CardUi>
                            <CardBodyUi className="d-flex flex-column align-items-center">
                                <div className="mb-4">
                                    <Avatar 
                                        text={user?.name || ''} 
                                        color="primary" 
                                        size="xl" 
                                        img="https://app.telematics.services/img/avatar.svg"
                                        rounded="rounded-circle"
                                        thumbnail
                                    />   
                                </div>
                                <h5 className="fs-16 mb-1">{user?.name}</h5>
                                <p className="text-muted mb-0">
                                    <FormattedMessage id={user?.type ?? '.'} />
                                </p>
                            </CardBodyUi> 
                        </CardUi>
                    </Col>
                    <Col xl={9}>
                        <CardUi>
                            <CardHeader>
                                <h5 className="mb-0">Mot de passe</h5>
                                {/* <Tabs isCustom color="primary" className="card-header-tabs border-bottom-0" onChange={onChangeNav}>
                                    <div>Général</div>
                                    <div>Mot de passe</div>  */}
                                    {/* <div>Configuration système</div> 
                                    <div>Style</div>  */}
                                {/* </Tabs> */}
                            </CardHeader>
                            <CardBodyUi className="p-4"> 
                                {/* <TabContent activeTab={`${activeNav}`}> */}
                                    {/* <TabPane tabId="0" id="account-general">
                                       <General/>
                                    </TabPane> */}
                                    {/* <TabPane tabId="0" id="account-password"> */}
                                        <Password/>
                                    {/* </TabPane> */}

                                    {/* <TabPane tabId="2" id="account-system-config">
                                       <ConfigSystem/>
                                    </TabPane>

                                    <TabPane tabId="3" id="account-style">
                                        <Style/>
                                    </TabPane> */}
                                {/* </TabContent> */}
                            </CardBodyUi> 
                        </CardUi>
                    </Col>
                </Row>
            </Container>
            
            
        </div>
    );
}
export default ProfilePage;
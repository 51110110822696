import classNames from "classnames";
import React, { forwardRef } from "react";
import AsyncSelect from "react-select/async";
import {Label } from "reactstrap";

type AsyncInputSelectType =  any & {label?: string};
export const AsyncInputSelect = forwardRef((props: AsyncInputSelectType, ref) => {
    const {label, error, className, ...options} =  props;
    const asyncSelectClass = classNames({
        choices: true,
        [`is-invalid`]: !!error,
        [`${className}`]: !!className,
      });
      
    return (
        <React.Fragment>
            { label && <Label>{label}</Label> }                                           
            <AsyncSelect
                ref={ref}
                {...options}
                className={asyncSelectClass}
                classNamePrefix="choices"
            />
        </React.Fragment>
    );
});
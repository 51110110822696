import React from "react"
import ContentLoader from "react-content-loader"
type DriverMarkProps = {
  [key: string]: any
}
const NotificationSkeleton = (props: DriverMarkProps) => (
  <div {...props}>
    <ContentLoader 
        speed={2}
        width={320}
        height={70}
        viewBox="0 0 320 70"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <circle cx="31" cy="26" r="16" /> 
        <rect x="58" y="14" rx="3" ry="3" width="170" height="8" />
        <rect x="58" y="30" rx="3" ry="3" width="200" height="6" /> 
        <rect x="58" y="40" rx="3" ry="3" width="100" height="6" /> 
        <rect x="58" y="55" rx="4" ry="4" width="30" height="6" />
    </ContentLoader>
  </div>
  
)

export default NotificationSkeleton;
import {  Progress } from "reactstrap";

type SelectItemsIndicatorProps = {
    value: number;
    max: number;
}
const SelectItemsIndicator= (props: SelectItemsIndicatorProps) => {
    
    return (
        <div className="mt-4">
            <div className="d-flex flex-row justify-content-between mb-1">
                <span style={{fontWeight: 400}}>
                    <span className="text-success" style={{fontWeight: 500}}>{props.value} </span> sélectionnées
                </span>
                <span className="text-muted">Maximum: {props.max}</span>
            </div>
            <Progress
                color={"success"}
                style={{
                    flex: 1,
                    height: '5px'
                }}
                value={props.value}
                max={props.max}
            />
        </div> 
    );
}

export default SelectItemsIndicator;
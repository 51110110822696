import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { useAppSelector } from "../../redux/hooks";
import { useFetchRoutes } from "../../common/hooks/axios/routes/useFetchRoutes";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { IconType } from "react-icons";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";


type queryFilter = {
    skip: number;
    limit: number;
    name?: string;
    severity: string;
}

export function RoutesPage() {

    const intl = useIntl();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authReducer);
    const [ params, setParams ] = useState<queryFilter>({skip: 0, limit: 25, severity: 'all'});
    const { fetchRoutesLoading, fetchRoutes, routes } = useFetchRoutes({manual: false});


    const canPaginate = Math.ceil((routes?.total ?? 0) / +params.limit) > 1;
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },
        { label: 'COMMON.ACTIVE', value: 'active' },
        { label: 'COMMON.DRAFT', value: 'draft' },
    ];
    
    const onClickAction = (action: string, _ids: string[]) => { 
        switch(action) {
            case 'add':
            case 'edit': {
                navigate({ pathname: `/routes/optimize`});
                break;
            }

            case "view": {
                navigate({ pathname: `/routes/${_ids[0]}/details`});
                break;
            }
        }
    }

    

    const onSearch = ({ searchBy }: { searchBy: string }): void => {
        if(searchBy.length > 0) {
            setParams({...params, name: searchBy})
        } else {
            const newParams = {...params};
            delete newParams.name;
            setParams(newParams);
        }
    };

    const onChangeFilter = (index: number): void => { 
        setParams({...params, severity: FILTERS[index].value})
    };
    const onPaginate = (skip: number, limit: number): void => {
        setParams({...params, ...{ skip, limit }})
    };

    // Effects
    useEffect(()=> {
        fetchRoutes(params);
    }, [params]);


    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEye,
            name: intl.formatMessage({ id: 'COMMON.VIEW' }),
            action: 'view'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
    ];
    
    const columns = [
        {
            title: intl.formatMessage({ id: 'COMMON.STATUS' }),
            dataIndex: 'expiration',
            key: 'expiration',
            render: (expiration: Date) => {
                if(expiration) {
                    return <BadgeUi color="warning"> Draft</BadgeUi>;
                }
                return <BadgeUi color="primary"> Active</BadgeUi>;
            }
        },
        {
            title: intl.formatMessage({ id: 'COMMON.DIRECTION' }),
            dataIndex: 'type',
            key: 'type',
            render: (type: string, data: any) => {
                const message = data.direction === "end" ? "Vers" : "De";
                return ( `${message} : ` + data.stop);
            }
        },
        {
            title: intl.formatMessage({ id: 'ROUTES.TOTAL_ROUTES' }),
            dataIndex: 'routes',
            key: 'routes'
        },
        {
            title: intl.formatMessage({ id: 'ROUTES.TOTAL_ASSIGNED_PASSENGERS' }),
            dataIndex: 'load',
            key: 'load',
            render: (load: string) => {
                return <BadgeUi color="success"> { `${load}` }</BadgeUi>;
            }
        },
        {
            title: intl.formatMessage({ id: 'ROUTES.TOTAL_UNASSIGNED_PASSENGERS' }),
            dataIndex: 'unassigned',
            key: 'unassigned',
            render: (unassigned: string, data: any) => {
                console.log(data)
                return <BadgeUi color="danger"> { `${unassigned}` }</BadgeUi>;
            }
        },
        {
            title: intl.formatMessage({ id: 'ROUTES.DISTANCE' }),
            dataIndex: 'distance',
            key: 'distance',
            render: (distance: number) => {
                if(distance) {
                    return `${(distance ? distance / 1000 : 0).toFixed(1)} km`;
                }
                return '-'
            }
        },
        {
            title: intl.formatMessage({ id: 'ROUTES.DURATION' }),
            dataIndex: 'duration',
            key: 'duration',
            render: (duration: number) => {
                if(duration) {
                    return `${(duration ? duration / 60 : 0).toFixed(1)} Minutes`;
                }
                return '-'
            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];

    return (
        <div id="routesPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'ROUTES' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'ROUTES.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                <CardUi>
                    <LoaderUi loading={fetchRoutesLoading}/>
                    <CardHeader className="d-flex justify-content-between">
                       { 
                            <Tabs 
                                color="primary" 
                                pills
                                className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                onChange={(index: number) => onChangeFilter(index)}
                            >
                                { FILTERS.map((filter, index: number)=> <div>
                                    <FormattedMessage id={filter.label}/>
                                    </div>)}
                            </Tabs>
                            // :
                            // <ActionTabBar selected={selectedVehicles} onClick={onClickAction}/>
                        }
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' }) + " par nom"}
                            onSubmit={onSearch}
                        />
                    </CardHeader>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={routes?.list ?? []}
                            // selected={[]}
                            // onChangeSelectedItem={onChangeSelectedItem}
                            minusHeight={190+(canPaginate ? 120 : 60)}
                        />
                    </CardBody>
                    {
                        canPaginate ? (
                            <CardFooter>
                            <Row>
                                <Col>
                                    <Paginator
                                        total={routes?.total ?? 0}
                                        skip={params.skip}
                                        limit={params.limit}
                                        onPaginate={onPaginate}
                                    />
                                </Col>
                            </Row>
                        </CardFooter>
                        ) : <></>
                    }
                </CardUi>
            </Container>
        </div>
    );
}
import { stat } from "fs";
import { LIMIT } from "../../../env";
import { FilterGeofenceType, GeofenceInListType, GeofenceType } from "../types";
import { CLEAR_GEOFENCE_DRAW, CREATE_GEOFENCE_END, CREATE_GEOFENCE_START, DELETE_GEOFENCE_END, DELETE_GEOFENCE_START, GeofenceActionsType, GET_GEOFENCES_END, GET_GEOFENCES_START, GET_GEOFENCE_UPDATE_END, GET_GEOFENCE_UPDATE_START, SET_GEOFENCE_COLOR, SET_GEOFENCE_DRAW, SET_GEOFENCE_POINT, SET_GEOFENCE_RADIUS, SET_GEOFENCE_TYPE } from "./geofenceModule.actions";

export type StateType = {
    loading: boolean;
    geofence: {
        list: GeofenceInListType[];
        total: number;
    };
    filter: FilterGeofenceType;
    manage: {
        key?: string,
        type?: GeofenceType,
        name: string,
        description?: string,
        geofence?: any,
        color: string,
        radius?: number
        point?: {
            latitude: number,
            longitude: number
        }
    }
};

const INITIAL_STATE: StateType = {
    loading: false,
    filter: {
        limit: LIMIT,
        skip: 0,
    },
    manage: {
        name: "",
        color: "#0ab39c",
    },
    geofence: {
        list: [],
        total: 0
    }
};

export function geofenceModuleReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: GeofenceActionsType
): StateType {
    switch (action.type) {
        case CREATE_GEOFENCE_START:
        case DELETE_GEOFENCE_START:
        case GET_GEOFENCE_UPDATE_START:
        case GET_GEOFENCES_START: {
            return {
                ...state,
                loading: true,
            }
        }
        case SET_GEOFENCE_TYPE: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    type: action.payload.type
                }
            }
        } 
        case SET_GEOFENCE_COLOR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    color: action.payload.color
                }
            }
        }  
        
        case SET_GEOFENCE_DRAW: {
            const manage = {
                ...state.manage,
                geofence: action.payload.geofence,
                point: undefined,
            };

            return {
                ...state,
                manage
            }
        } 

        case SET_GEOFENCE_RADIUS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    radius: action.payload.radius
                }
            }
        }

        case SET_GEOFENCE_POINT: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    point: action.payload.point
                }
            }
        }
        
        case CLEAR_GEOFENCE_DRAW: {
            return {
                ...state,
                manage: INITIAL_STATE.manage
            }
        } 
        
        case GET_GEOFENCES_END:
            const { result, filter } = action.payload;
            return {
                ...state,
                geofence: result,
                filter,
                loading: false,
            };
        
        case CREATE_GEOFENCE_END: {
            const list = [...state.geofence.list];
            const { key, modifiedCount } = action.payload;
            const updatedSensor: any = { ...action.payload };
            
            if (modifiedCount !== undefined) {
                if (modifiedCount >= 0) {
                    const geofenceIndex = list.findIndex(
                        (entry) => entry.key === key
                    );
                    
                    if (geofenceIndex !== -1) {
                        list[geofenceIndex] = {
                            ...list[geofenceIndex],
                            ...updatedSensor,
                        };
                    }
                }
            } else {
                list.push(updatedSensor);
            }

            return {
                ...state,
                loading: false,
                geofence: {
                    ...state.geofence,
                    list,
                },
            };
        }

        case DELETE_GEOFENCE_END: {
            const { deletedCount, keys } = action.payload;
            if (deletedCount > 0) {
                return {
                    ...state,
                    loading: false,
                    geofence: {
                        total: state.geofence.total - deletedCount,
                        list:
                            state.geofence.list.filter(
                                (entry) => !keys.includes(entry.key)
                            ) || [],
                    },
                };
            }
            return state;
        }

        case GET_GEOFENCE_UPDATE_END: {
            return {
                ...state,
                manage: action.payload.result,
                loading: false,
            };
        }
        default:
            return state;
    }
}

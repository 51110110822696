import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Col, Container, Form, Row } from "reactstrap";
import { BreadCrumbActionUi } from "../../../Components/basic/BreadCrumbActionUi";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../redux/hooks";
import { Contact } from "../../components/form/Contact";
import {  GetCompanyUpdateStart, ClearCompanyCreation, CreateCompanyStart } from "../redux/company.actions";
import { General } from "./general";
import { Limit } from "./limit";
import { Subscription } from "./subscription";

type ManageCompanyPageType = {
   
}
const ManageCompanyPage = ({}: ManageCompanyPageType) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {_id} = useParams();
    const { manage, loading } = useAppSelector((state) => state.companyReducer);
    const { user } = useAppSelector((state) => state.authReducer);

    const backToPrevious = () => {
        navigate({ pathname: '/companies/' });
    }
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    useEffect(() => {
        if(_id && _id !== 'add') {
            dispatch(GetCompanyUpdateStart({ _id }))
        }
        return function cleanup() {
            dispatch(ClearCompanyCreation());
        };
    }, []);

    useEffect(()=> {
        const { name, description, email, mobile, limit } = manage;
        setValue('name', name);
        setValue('description', description);
        setValue('email', email);
        setValue('mobile', mobile);
        setValue('user', limit.user);
        setValue('driver', limit.driver);
        setValue('vehicle', limit.vehicle);
        setValue('event', limit.event);
    }, [
        manage.name, 
        manage.description, 
        manage.email, 
        manage.mobile,
         manage.limit
    ]);
    
        
    const onSubmit = (data: any) => {
        if(manage.subscription && manage.subscriptionType && manage.active !== undefined) {
            const {user, event, vehicle, driver, email, mobile, ...allData} = data;
            const savedData = {
                ...allData,
                limit: {
                    user, event, vehicle, driver
                }
            }
            if(email && email.length > 0) {
                savedData.email = email;
            }
            if(mobile && mobile.length > 0) {
                savedData.mobile = mobile;
            }
            dispatch(CreateCompanyStart(savedData));
        }  
    };

    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={"Entreprises"} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <Form onSubmit={handleSubmit(onSubmit)} className="position-relative">
                    <Row>
                        <LoaderUi loading={loading}/>
                        <Col md={6}>
                            <General register={register} errors={errors}/>
                        </Col>
                        <Col md={6}>
                            <Subscription register={register} errors={errors}/>
                        </Col>
                        <Col md={6}>
                            <Contact register={register} errors={errors}/>
                        </Col>
                        <Col md={6}>
                            <Limit register={register} errors={errors}/>
                        </Col>
                    </Row>
                    <Col>
                        <div className="hstack gap-2 justify-content-end">
                            <ButtonUi color={"primary"} soft onClick={backToPrevious}>Annulé</ButtonUi>
                            <ButtonUi color={"primary"} type="submit">Modifier</ButtonUi> 
                        </div>
                        
                    </Col>
                </Form>
            </Container>
            
            
        </div>
    );
}

export default ManageCompanyPage;
import { useDispatch, useSelector } from 'react-redux';
import { SetActiveStart } from '../../../../../pages/Map/Object/Event/_redux/eventActions';
import { EventMarker } from './event-marker';
import { MarkerEventInfo } from './marker-event-info';

export function EventMarkers(): JSX.Element | null {
    const dispatch = useDispatch();

    const { events, activeEvent, showEvents } = useSelector(
        (state: any) => state.eventsObjectReducer
    );

    const handleClick = (event: any): void => {
        dispatch(SetActiveStart(event));
    };

    if (events.length > 0 && showEvents) {
        return (
            <>
                {events.map((event: any, index: number) => {
                    const active = activeEvent && activeEvent._id === event._id;

                    if (active) {
                        return (
                            <>
                                <MarkerEventInfo
                                    event={event}
                                    active={active}
                                    index={index}
                                />
                                <EventMarker event={event} active={active} />
                            </>
                        );
                    }
                    return <EventMarker event={event} click={handleClick} />;
                })}
            </>
        );
    }

    return null;
}

import { Card } from "reactstrap"
import classNames from 'classnames';


type cardUiType = {
    children: JSX.Element[] | JSX.Element | string;
    className?: string;
    noShadow?: boolean
    [key: string]: any,
}

export const CardUi = ({className, noShadow, children, ...other}: cardUiType) => {
    const cardClassname = classNames({
        [`card-noShadow`]: !!noShadow,
        [`${className}`]: !!className,
      });


    return <Card {...other} className={cardClassname} >{children}</Card>
}



import { Popconfirm } from "antd";
import { useEffect, useRef } from "react";
import { AiOutlineArrowLeft, AiOutlineEdit } from "react-icons/ai";
import { FaUser, FaAddressCard } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";
import { HiPhone } from "react-icons/hi";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { CardHeader } from "reactstrap";
import { IF } from "../../Components/If";
import { ScrollBar } from "../../Components/PerfectScrollBar";
import { CardUi } from "../../Components/basic/Card";
import { CardBodyUi } from "../../Components/basic/CardBodyUi";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { useDeletePassenger } from "../../common/hooks/axios/passenger/useDeletePassenger";
import { useFetchPassenger } from "../../common/hooks/axios/passenger/useFetchPassenger";
import { useAxios } from "../../common/hooks/axios/useAxios";
import { SetOptionMapAction, SetNotificationAction } from "../../redux/rootActions";
import { DriverAction } from "../../Components/advanced/Driver/Driver-action";
import ManagePassengerModal from "./manage-passenger-modal";
import { PassengerProfile } from "./components/passenger-profile";
import { GiBusStop } from "react-icons/gi";
import { BsClockHistory } from "react-icons/bs";
import Emitter from "../../helpers/emitter";

const DetailsPassengerPage = () => {
    const { key } = useParams();
    const [queryParameters] = useSearchParams();
    const action = queryParameters.get('action');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refScrollBar = useRef();
    
    const { passenger, fetchPassengerLoading, fetchPassenger} = useFetchPassenger({manual: false, key});
    const { deleteAsyncPassenger, deletePassengerLoading} = useDeletePassenger({manual: true, key});

    
    const { loading: loadingDelete} = useAxios(true);
    
    const toogleHide = (): void => {
        const padding = 374;
        dispatch(SetOptionMapAction({opt: 'padding', value: padding}));
        navigate('/passengers');
    };

    useEffect(() => {
        if(passenger && action === null) {
            fetchPassenger();
        }
    }, [action]);

    const deletePassenger= async () => {
        if(!deletePassengerLoading) {
            const result = await deleteAsyncPassenger();
            if(result.success) {
                dispatch(SetNotificationAction({
                    title: `succès`,
                    "message": `Passager supprimé`,
                    timeToClose: 5000,
                    type: 'success'
                }));
                navigate(`/passengers`);
            }
        }
    } 

    return (
        <div className="page-content object-page" >
            <CardUi className="driver-details">
                <LoaderUi loading={fetchPassengerLoading || loadingDelete}/>
                <CardHeader className="d-flex align-items-center mb-0">
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={toogleHide}
                        onKeyDown={toogleHide}
                        className="me-3 p-1 ghost btn btn-primary btn-sm rounded-circle"
                    >
                        <AiOutlineArrowLeft size={20} />
                    </div>
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"PASSENGER.DETAILS"}/></span>
                    </h5>
                </CardHeader>
                
                
                <CardBodyUi className="p-0">
                    <ScrollBar
                        id="detail-passenger-scrollbar"
                        containerRef={refScrollBar}
                        minusHeight={170}
                    > 
                        <PassengerProfile className="p-3" loading={fetchPassengerLoading} passenger={passenger}/>

                        <div className="py-3 px-3 border-bottom">
                            <h6 className="text-primary fs-14">Actions</h6>
                            <div className="d-flex mt-3">
                                <DriverAction 
                                    color="secondary"
                                    icon={BsClockHistory} 
                                    text="Historiques"
                                    onClick={()=> navigate(`/passengers/${key}/history`)}
                                />
                                <DriverAction 
                                    color="secondary"
                                    icon={AiOutlineEdit} 
                                    text="Modifier"
                                    onClick={()=> navigate(`/passengers/${key}/details?action=update`)}
                                />
                                  
                                    <DriverAction 
                                        color="danger"
                                        icon={FiTrash} 
                                        text="Supprimer"
                                        onClick={()=> {
                                            Emitter.emit(Emitter.event.DELETE_POPUP, {
                                                textConfirmation: passenger?.name ?? "confirm",
                                                title: "Supprimer le passager",
                                                description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                                                onDelete: deletePassenger,
                                            });
                                        }}
                                    />
                                
                            </div>
                        </div>

                        <div className="py-3 px-3">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <h6 className="text-primary fs-14 mb-0">Informations</h6>
                            </div>
                            
                            <div className="d-flex flex-column mt-1">
                                <IF condition={!!passenger?.name}>
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        <div className="text-info me-3">
                                            <FaUser size={20} />
                                        </div>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Passager</label>
                                            <span className="text-muted fs-11">{passenger?.name}</span>
                                        </div>
                                    </div>
                                </IF>
                                <IF condition={!!passenger?.identifier}>
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        <div className="text-info me-3">
                                            <FaAddressCard size={20} />
                                        </div>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Identifiant</label>
                                            <span className="text-muted fs-11">{passenger?.identifier}</span>
                                        </div>
                                    </div>
                                </IF>

                                <IF condition={!!passenger?.stop}>
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        <div className="text-info me-3">
                                            <GiBusStop size={20} />
                                        </div>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Arrêt</label>
                                            <span className="text-muted fs-11">{passenger?.stop?.label}</span>
                                        </div>
                                    </div>
                                </IF>

                                <IF condition={!!passenger?.phone}>
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        <div className="text-info me-3">
                                            <HiPhone size={20} />
                                        </div>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Téléphone</label>
                                            <span className="text-muted fs-11">{passenger?.phone}</span>
                                        </div>
                                    </div>
                                </IF>

                            </div>
                        </div> 
                    </ScrollBar>
                </CardBodyUi>
            </CardUi>
            <ManagePassengerModal />
        </div>

    );
}

export default DetailsPassengerPage;
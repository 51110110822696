import React, { useContext, useRef } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { TileLayerDecorator } from '../../Components/Leaflet/TileLayer';
import { LayoutContext } from '../../context/layout-style-context';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

export function Tiles() {
    const { layoutMode } = useContext(LayoutContext);

//     googleStreets = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
//     maxZoom: 20,
//     subdomains:['mt0','mt1','mt2','mt3']
// });




    return <ReactLeafletGoogleLayer 
        type="hybrid" 
        googleMapsLoaderConf={{region: 'MA'}}
        googleMapsAddLayers={[{
            name: 'TrafficLayer'
        }]}
    />
    return layoutMode === 'light'? 
        <TileLayer 
            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            maxZoom={21}
            subdomains={['mt0','mt1','mt2','mt3']}
            attribution={
                'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
            }
        /> : <TileLayerDecorator url=""/>
         
    // return <TileLayer 
    // url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
    // maxZoom={21}
    // subdomains={['mt0','mt1','mt2','mt3']}
    // attribution={
    //     'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
    // }
// />
    
}

import Popconfirm from 'antd/es/popconfirm';
import { BiLink } from 'react-icons/bi';
import { DriverAction } from '../../../../../pages/Driver/types';
import { AssignmentType, DriverInListType } from '../../../../../pages/Driver/_redux/types';
import { ButtonUi } from '../../../../basic/ButtonUi';

type DriverAssignmentActionProps = {
    driver: DriverInListType | null,
    onClick: (key: string, action: DriverAction) => void
}
export const DriverAssignmentAction = ({driver, onClick}: DriverAssignmentActionProps) => {
    
    const action = () => {
        if(driver?.assignment?.type === AssignmentType.MANUAL && driver.assignment?.to) {
            onClick(driver.key, DriverAction.UNBIND);
        } else if(driver?.assignment?.type === AssignmentType.BOTH && !driver.assignment?.to) {
            onClick(driver.key, DriverAction.BIND);
        }  
    }

    if(driver?.assignment?.to) {
        return(
            <>
                <Popconfirm
                    title="Êtes-vous sûr de le détacher ?"
                    onConfirm={action}            
                    okText="Oui"
                    cancelText="Non"
                >

                    <ButtonUi className={`d-flex flex-row justify-content-center align-items-center`} size="sm" color="danger" soft disabled={driver?.assignment.type !== AssignmentType.MANUAL}>                         
                        <>
                            <BiLink size={18} className="me-1"/> Détacher
                        </>
                    </ButtonUi>
                </Popconfirm>
                
                
            </>       
        )
    }
    return(
        <>
            <ButtonUi className={`d-flex flex-row justify-content-center align-items-center`} size="sm" color="info" soft onClick={action} disabled={driver?.assignment.type !== AssignmentType.BOTH}>                         
                <>
                    <BiLink size={18} className="me-1"/> Assigner
                </>
            </ButtonUi>
        </>       
    )
}
import classNames from "classnames";

export function TrajectIconItem({
    className,
    color,
    title,
    icon,
    value,
    unit,
}: any) {
    const TrajectIconClassname = classNames({
        [`${className}`]: !!className,
        'd-flex flex-row align-items-end': true
    });
    return (
        <div className={TrajectIconClassname}>
            {icon}
            <span className="ms-1 fs-12 fw-bold">{value}</span>
            <span className="fs-11">{unit}</span>
        </div>
    );
}
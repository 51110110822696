import classNames from "classnames";

export function TrajectIconTime({
    className,
    title,
    icon,
    hours,
    minutes,
    seconds,
}: any) {
    const TrajectIconClassname = classNames({
        [`${className}`]: !!className,
        'd-flex flex-row align-items-end': true
    });

    return (
        <div className={TrajectIconClassname}>
            {icon}
            <span className="ms-1 fs-12 fw-bold">{hours}</span>
            <span className="fs-12">h</span>
            <span className="ms-1 fs-12 fw-bold">{minutes}</span>
            <span className="fs-12">m</span>
            {seconds && (
                <>
                    <span className="ms-1 fs-12 fw-bold">{seconds}</span>
                    <span className="fs-12">s</span>
                </>
            )}
        </div>
    );
}
import { SetNotificationAction, SetRedirectAction } from './../../../redux/rootActions';
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RootState } from '../../../redux/store';
import { ChangeUser } from '../../Authentification/_redux/authActions';
import { deleteAuthCookie } from '../../Authentification/_redux/authService';
import {
    CompanyActionType,
    CreateCompanyEnd,
    CreateCompanyStartType,
    CREATE_COMPANY_START,
    DeleteCompanysEnd,
    DeleteCompanysStartType,
    DELETE_COMPANYS_START,
    ErrorCompanys,
    GetCompanysEnd,
    GetCompanysStartType,
    GetCompanyUpdateEnd,
    GetCompanyUpdateStartType,
    GET_COMPANYS_START,
    GET_COMPANY_UPDATE_START,
    SwitchCompanyEnd,
    SwitchCompanyStartActionType,
    SWITCH_COMPANY_START
} from './company.actions';
import {
    deleteCompanys,
    getCompany,
    getCompanys,
    saveCompany,
    switchCompany,
    updateCompany
} from './company.service';

export const CreateCompanyEpic = (
    action$: ActionsObservable<CreateCompanyStartType>,
    state$: { value: RootState }
): Observable<any> =>
    action$.pipe(
        ofType(CREATE_COMPANY_START),
        switchMap((action: CreateCompanyStartType) => {
            const { manage } = state$.value.companyReducer;
            return from(
                manage._id
                    ? updateCompany({ ...manage, ...action.payload })
                    : saveCompany({ ...manage, ...action.payload })
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...state$.value.companyReducer.manage,
                        ...result,
                        ...{
                            _id: manage._id ? manage._id : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                            name: action.payload.name
                        },
                    };

                    return of(
                        CreateCompanyEnd(newManage),
                        SetRedirectAction('/companies'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                      );
                }),
                catchError((err) => {
                    return of(ErrorCompanys(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorCompanys(err.msg));
        })
    );

export const GetCompanysEpic = (
    action$: ActionsObservable<GetCompanysStartType>,
    state$: { value: RootState }
): Observable<CompanyActionType> =>
    action$.pipe(
        ofType(GET_COMPANYS_START),
        switchMap((action: GetCompanysStartType) => {
            return from(getCompanys(action.payload)).pipe(
                map((result) => {
                    return GetCompanysEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorCompanys(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorCompanys(err.msg));
        })
    );

export const GetCompanyEpic = (
    action$: ActionsObservable<GetCompanyUpdateStartType>,
    state$: { value: RootState }
): Observable<CompanyActionType> =>
    action$.pipe(
        ofType(GET_COMPANY_UPDATE_START),
        switchMap((action: GetCompanyUpdateStartType) => {
            return from(getCompany(action.payload)).pipe(
                map((result) => {
                    return GetCompanyUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorCompanys(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorCompanys(err.msg));
        })
    );

export const DeleteCompanysEpic = (
    action$: ActionsObservable<DeleteCompanysStartType>,
    state$: { value: RootState }
): Observable<CompanyActionType> =>
    action$.pipe(
        ofType(DELETE_COMPANYS_START),
        switchMap((action: DeleteCompanysStartType) => {
            return from(deleteCompanys({ _ids: action.payload })).pipe(
                map((result) => {
                    return DeleteCompanysEnd({
                        ...result.data,
                        _ids: action.payload,
                    });
                }),
                catchError((err) => {
                    return of(ErrorCompanys(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorCompanys(err.msg));
        })
    );

export const SwitchCompanyEpic = (
    action$: ActionsObservable<SwitchCompanyStartActionType>,
    state$: { value: RootState }
): Observable<any> =>
    action$.pipe(
        ofType(SWITCH_COMPANY_START),
        switchMap((action: SwitchCompanyStartActionType) => {
            const { _id } = action.payload;
            return from(switchCompany({ _id })).pipe(
                switchMap((result) => {
                    const { user } = state$.value.authReducer;
                    if (result.modifiedCount > 0 && user) {
                        deleteAuthCookie();
                        return of(
                            SwitchCompanyEnd(),
                            ChangeUser({
                                ...user,
                                company: {
                                    ...user.company,
                                    _id,
                                    name: state$.value.companyReducer.companys.list.find(c => c.key === _id)?.name || undefined,
                                },
                            }),
                            SetNotificationAction({
                                message: 'success',
                                title: '',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }
                    return of(ErrorCompanys(''));
                }),
                catchError((err) => {
                    return of(ErrorCompanys(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorCompanys(err.msg));
        })
    );

import { vehiclesToAssign } from './report.service';
import { TOOGLE_AFFECT_TO, SELECT_OBJECT, SET_DATE_REPORT, GENERATE_REPORT_START, GENERATE_REPORT_END, SET_TYPE_REPORT, GET_REPORTS_END, GET_REPORTS_START, DOWNLOAD_REPORT_START, DownloadReportEnd, DOWNLOAD_REPORT_END } from './report.actions';
import { AffectToType, ManageReportType, ManageUserType, ReportInList } from './types';
import {
    UserActionType
} from './report.actions';

type StateType = {
    loading: boolean;
    manage: ManageReportType
    filter: {
        limit: number;
        skip: number;
    };
    report: {
        list: ReportInList[];
        total: number;
    };
};

const INITIAL_STATE: StateType = {
    loading: false,
    manage: {
        affectTo: {
            type: 'object',
            list: [],
            data: [],
            visible: false
        },
        date: []
    },
    report: {
        list: [],
        total: 0,
    },
    filter: {
        limit: 20,
        skip: 0,
    },
};

export function reportReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: UserActionType
): StateType {
    switch (action.type) {
        case TOOGLE_AFFECT_TO: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    affectTo: {
                        ...state.manage.affectTo,
                        visible: !state.manage.affectTo.visible
                    }   
                }
            }
        }

        case SELECT_OBJECT:{
            return {
                ...state,
                manage: {
                    ...state.manage,
                    affectTo: {
                        ...state.manage.affectTo,
                        visible: !state.manage.affectTo.visible,
                        list: action.payload,
                        data: vehiclesToAssign(action.payload)
                    }   
                }
            }
        }

        case SET_DATE_REPORT: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    date: action.payload 
                }
            }
        }

        case SET_TYPE_REPORT: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    type: action.payload 
                }
            }
        }

        
        case GET_REPORTS_START:
        case DOWNLOAD_REPORT_START:
        case GENERATE_REPORT_START:{
            return {
                ...state, loading: true
            }
        }

        case GENERATE_REPORT_END:{
            return {
                ...state, loading: false
            }
        }

        case GET_REPORTS_END:
            const { result, filter } = action.payload;
            return {
                ...state,
                report: result,
                filter,
                loading: false,
            };

            case DOWNLOAD_REPORT_END: {
                return {
                    ...state,
                    loading: false
                }
            }

        default:
            return state;
    }
}

import { IoCheckboxOutline } from "react-icons/io5";
import { Label } from "reactstrap";

type ShiftResumeGroupProps = { text: string };
const ShiftResumeGroup = (props: ShiftResumeGroupProps) => {
  return (
    <div className="d-flex flex-row align-items-center bg-light p-2 mb-2">
      <IoCheckboxOutline size={16} className="me-1"/><Label className="mb-0 text-uppercase"> { props.text } </Label>
    </div>
  );
};

export default ShiftResumeGroup;
import { useEffect, useState } from 'react'
import { CardUi } from '../../../../Components/basic/Card'
import { RoadTable } from './road-result/road-table'
import { RoadTableBody } from './road-result/road-table-body'
import { RoadTableHeader } from './road-result/road-table-header'
import { useParams } from 'react-router-dom'
import { useFetchOptimizedRoad } from '../../../../common/hooks/axios/routes/useFetchOptimizedRoad'
import { RoadTableFooter } from './road-result/road-table-footer'
import Emitter from '../../../../helpers/emitter'
import EmitterActions from '../../../../helpers/emitter/emitter.actions'
import { v4 as uuidv4 } from 'uuid';

const Tracker = () => {
  const { key } = useParams();
  const [rowSize, setRowSize] = useState(0);
  const {fetchOptimizedRoad, optimizedRoad} = useFetchOptimizedRoad({manual: true, key});

  useEffect(() => { fetchOptimizedRoad(); }, [key]);
  useEffect(() => {
    if(optimizedRoad?.routes?.length) {
      const routes = optimizedRoad?.routes.map((route: any, index: number) => {
        return { 
          key: uuidv4(), 
          geometry: route.geometry, 
          color: route.color, 
          start: route.steps[0].location,
          end: route.steps[route.steps.length - 1].location,
          steps: sortData(route.steps)

        }
      })
      Emitter.emit(EmitterActions.ROUTES_INIT, routes);
    }

  }, [optimizedRoad]);

  if( ! optimizedRoad?.routes ||  optimizedRoad?.routes.length === 0) {
    return <></>;
  }

  const { type, summary, routes } = optimizedRoad;
  const start = new Date(2023, 7, 7, 0, 0, 0);
  const end = new Date(start.getTime() + (summary.maxRoadDuration ?? 0) * 1000)
  const sortData = (data: any) => {
    const grouped: any[] = [];
  
    data.forEach((obj: any) => {
      if (!obj.description) {
        return;
      }
      const { stop } = JSON.parse(obj.description);
      const existingGroup = grouped.find((group) => group.stop === stop);
      if (existingGroup) {
        existingGroup.objects.push(obj);
      } else {
        grouped.push({ stop, location: obj.location, objects: [obj] });
      }
    });
  
    return grouped;
  };
  

  return (
    <div className="d-flex align-items-end report-snap-body" style={{height: "calc(100vh - 105px)"}}>
        <CardUi className="mb-0">
          <div className='position-relative'
            style={{
              maxHeight: '250px',
              width: 'calc(100vw - 300px)',
              overflowY: 'scroll',
            }}
          >
            <RoadTable>
              <RoadTableHeader onChangeRowSize={setRowSize} start={start} end={end} />
                {
                  routes.map((route: any, index: number) => {
                    return (
                      <RoadTableBody
                        key={route.description}
                        name={route.vehicle}
                        color={route.color}
                        load={type === "end" ? route.pickup[0] : route.delivery[0]}
                        steps={sortData(route.steps)}
                        width={ rowSize }
                        idx={index}
                        duration={route.duration}
                        start={start}
                        end={end}
                        type={optimizedRoad.type}
                        distance={(route.distance / 1000).toFixed(1)}
                        setselectedPerson={()=> alert('ff')}
                      />
                    )
                  })
                }               
            </RoadTable>
          </div>
          <RoadTableFooter optimizedRoad={optimizedRoad}/>
        </CardUi>
        
    </div>
  )
}

export default Tracker


import { useState } from "react";
import { Row, Col } from "reactstrap";
import { Tabs } from "../../../../../../Components/Tabs";

import { CalibrationGraph } from "./Graph";
import { CalibrationTable } from "./Table";

export const Calibration = ({className}: {className?: string}) => {

    const [active, setActive] = useState(0);
    const onChangeTab = (index: number) => {
        setActive(index)
    }
    return (
        <Row className={className}>
            <Col>
            <Tabs isCustom color="primary" onChange={onChangeTab}>
                <div>Table</div>
                <div>Graphique</div>
            </Tabs>
            </Col>
            
            {
               active === 1 ? <CalibrationGraph/> : <CalibrationTable/>
            }
        
            
        </Row>    
    );
}
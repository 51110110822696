import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts';
import { EventsStatsResponse } from '../dashboard.service';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';


// const colors: Record<string, string> = {
//     "in-zone": "rgba(10, 179, 156, 1)",
//     "out-zone": "rgba(240, 101, 72, 1)",
//     "custom": "rgba(247, 184, 75, 1)",
//     "stop": "rgba(41, 156, 219, 1)",
    
// }

const options: ApexOptions = {
    dataLabels: {
        enabled: true,
    },
    legend: {
        show: true,
        position: "bottom"
    },
}

type EventPieProps = {
    data: EventsStatsResponse[]
}
export const EventPie = (props: EventPieProps) => {
    const intl = useIntl();

    const values = useCallback(()=> {
        return props.data.map(entry => entry.count);
    }, [props.data]);

    const getOptions = useCallback(()=> {
        
        return {
            ...options,
            labels: props.data.map(entry => intl.formatMessage({ id: entry._id })),
            // colors: props.data.map(entry => colors[entry._id])
        }
    }, [props.data]);
    
    return (
        <Chart 
            options={getOptions()} 
            series={values()} 
            type="pie"
        />
    );
}
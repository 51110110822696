import { ButtonUi } from "./basic/ButtonUi"
import { BiMoon, BiSun } from 'react-icons/bi';
import { layoutModeTypes } from "../Layouts/types";

type LightDarkType = {
    layoutMode: layoutModeTypes
    onChangeLayoutMode: ()=>void
}
export const LightDark = ({layoutMode, onChangeLayoutMode}: LightDarkType) => {
    const isDark = layoutMode === 'dark';

    return(    
        <div className="ms-1 header-item d-none d-sm-flex">
            <ButtonUi   
                className="btn-topbar" 
                color="secondary" 
                ghost 
                circle 
                Icon={isDark ? BiSun : BiMoon}
                onClick={onChangeLayoutMode}
            />
        </div>
    );
}
export const RoutesMinutes = ({step}: {step: number}) => {
    switch(step) {
        case 12: {
            return (
                <>
                    <th className="border text-center">00</th>
                    <th className="border text-center">05</th>
                    <th className="border text-center">10</th>
                    <th className="border text-center">15</th>
                    <th className="border text-center">20</th>
                    <th className="border text-center">25</th>
                    <th className="border text-center">30</th>
                    <th className="border text-center">35</th>
                    <th className="border text-center">40</th>
                    <th className="border text-center">45</th>
                    <th className="border text-center">50</th>
                    <th className="border text-center">55</th>
                </>    
            )
        }
        case 6: {
            return (
                <>
                    <th className="border text-center">00</th>
                    <th className="border text-center">10</th>
                    <th className="border text-center">20</th>
                    <th className="border text-center">30</th>
                    <th className="border text-center">40</th>
                    <th className="border text-center">50</th>
                </>    
            )
        }

        default: {
            return (
                <>
                    <th className="border text-center">00</th>
                    <th className="border text-center">30</th>
                </>    
            ) 
        }
    }
}

export const RoutesColumnsMinutes = ({step}: {step: number}) => {
    switch(step) {
        case 12: {
            return (
                <>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                </>    
            )
        }
        case 6: {
            return (
                <>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                    <td className="border text-center"></td>
                </>    
            )
        }

        default: {
            return (
                <>
                    <td className="border text-center"></td>
                </>    
            ) 
        }
    }
}




import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { BreadCrumbActionUi } from "../../../../Components/basic/BreadCrumbActionUi";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearVehicleCreation } from "../../redux/vehicleModule.actions";
import { ManageVehicleBasicInfo } from "../ManageBasicInfo";

const AddVehiclePage = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const { user } = useAppSelector((state) => state.authReducer);
    
    useEffect(() => {
        return function cleanup() {
            dispatch(ClearVehicleCreation());
        };
    }, []);

    return (
        <div id="userPage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'VEHICLE' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'COMMON.BACK' })}
                    onClickAction={()=> navigate(-1)}
                />
                <ManageVehicleBasicInfo/>
            </Container>
        </div>
    );
}

export default AddVehiclePage;
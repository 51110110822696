import { RiDeleteBin6Line } from 'react-icons/ri';
import { GeofenceAction, GeofenceInListType } from '../../../../pages/Geofence/types';

type GeofenceDeleteActionProps = {
    geofence: GeofenceInListType,
    onClick: (key: string, action: GeofenceAction) => void
}
export const GeofenceDeleteAction = ({geofence, onClick}: GeofenceDeleteActionProps) => {
    return(
        <div onClick={()=>onClick(geofence.key, GeofenceAction.DELETE)}>
            <RiDeleteBin6Line id={`delete-geo-${geofence.key}`} size={14} className="text-danger" />
        </div>       
    )
}
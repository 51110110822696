
import {
    MdSignalCellular1Bar,
    MdSignalCellular2Bar,
    MdSignalCellular3Bar,
    MdSignalCellular0Bar,
    MdSignalCellular4Bar,
} from 'react-icons/md';

export type SignalGsmProps = { signalValue: number, size?: number };

export function SignalGsmIcon({ signalValue, size=16 }: SignalGsmProps) {
    switch (signalValue) {
        case 20 :
            return (
                <MdSignalCellular1Bar
                    style={{ fontSize: size, color: '#ff8c55b8' }}
                />
            );
        case 40:
            return (
                <MdSignalCellular2Bar
                    style={{ fontSize: size, color: '#ff8c55b8' }}
                />
            );
        case 50:
        case 60:
            return (
                <MdSignalCellular3Bar
                    style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }}
                />
            );
        case 80:
        case 100:
            return (
                <MdSignalCellular4Bar
                    style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }}
                />
            );
        default:
            return <MdSignalCellular0Bar style={{ fontSize: size }} />;
    }
}

export function SignalGsmText({ signalValue }: SignalGsmProps) {
    switch (signalValue) {
        case 20:
            return 'COMMON.VERY_POOR';
        case 40:
            return 'COMMON.POOR';
        case 50:
        case 60:
            return 'COMMON.MEDIUM';
        case 80:
        case 100:
            return 'COMMON.EXCELLENT';
        default:
            return 'COMMON.VERY_POOR';
    }
}

import { FiWifi, FiWifiOff } from 'react-icons/fi';

export type ConnexionIconProps = { enable: boolean, size?: number };

export default function ConnexionIcon({enable, size=13}: ConnexionIconProps) {
    if (enable) {
        return (
            <FiWifi style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }} />
        );
    }
    return <FiWifiOff style={{ fontSize: size, color: 'gray' }} />;
}

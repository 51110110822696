import { FormattedMessage } from "react-intl";
import { FormGroup } from "reactstrap";
import { ButtonUi } from "../../../../Components/basic/ButtonUi";
import RangePicker from "../../../../Components/basic/RangePicker";
import { MdOutlineRefresh } from "react-icons/md";
import { useCallback, useRef, useState } from "react";
import {  ReportTemplate, useFetchReportTemplates } from "../../../../common/hooks/axios/report/useFetchReportTemplate";
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect";
import { IF } from "../../../../Components/If";
import SelectUnitReport from "./unit-select-report";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment-timezone";

export type FilterData = {
    rangeTime: [Date, Date];
    selectedItems: {label: string, value: string}[];
    template: string;
}
type FetchReportFormProps = {
    disabled?: boolean;
    onSubmit: (data: FilterData) => void;
    onChangeItems?: (data: FilterData) => void;
    onChangeTimes?: (data: FilterData) => void;
    onReset: () => void;
}
const FetchReportForm= ({disabled, onChangeItems, onSubmit, onReset}: FetchReportFormProps) => {
    
    // Refs
    const reactSelectRef = useRef();

    // Hooks
    const { fetchReportTemplateForSelect } = useFetchReportTemplates({manual: true});
    const [ template, setTemplate ] = useState<ReportTemplate | null>(null);
    const [ selectedItems, setSelectedItems ] = useState<Record<string, {label: string, value: string}[]>>({});
    const [ rangeTime, setRangeTime ] = useState<[Date, Date] | null>(); 

    // Events
    const onSelect = (data: Record<string, {label: string, value: string}[]>) => {
        setSelectedItems(data)
        if(onChangeItems && rangeTime && template) {   
            onChangeItems({
                rangeTime, 
                selectedItems: Object.values(data).reduce((result, current) => [...result, ...current], []), 
                template: template.value
            });
        }
    };

    const onChangeTime: RangePickerProps['onChange'] = (dates, dateString) => {
        if(dates && dates?.[0] && dates?.[1]) {
            setRangeTime([dates[0]?.toDate(), dates[1]?.toDate()]);
            if(onChangeItems && selectedItems && template) {   
                onChangeItems({
                    rangeTime: [dates[0]?.toDate(), dates[1]?.toDate()], 
                    selectedItems: Object.values(selectedItems).reduce((result, current) => [...result, ...current], []), 
                    template: template.value
                });
            }
        }
    }
    
    const initForm = () => {
        (reactSelectRef.current as any).clearValue();
        setSelectedItems({});
        setRangeTime(null);
        onReset();
    }
    
    const submit = () => {
        if(template !== null && Object.entries(selectedItems).length > 0 && rangeTime?.length === 2) {
            onSubmit({
                rangeTime, 
                selectedItems: Object.values(selectedItems).reduce((result, current) => [...result, ...current], []), 
                template: template.value
            }) 
        }
        
    }

    // Functions
    const isActive = useCallback(()=> {
        return template !== null && Object.entries(selectedItems).length > 0 && rangeTime?.length === 2;
    }, [template, selectedItems, rangeTime]);

    return (
        <div className="py-2 px-3">
            <FormGroup>
                <AsyncInputSelect 
                    ref={reactSelectRef}
                    label={"Type du rapport"}
                    defaultOptions
                    loadOptions={fetchReportTemplateForSelect}
                    onChange={setTemplate}
                    isDisabled={disabled}
                />
            </FormGroup>
            <IF condition={template?.target === "unit"}>
                <SelectUnitReport selectedItems={selectedItems} onSelect={onSelect} disabled={false}/>
            </IF>


            <FormGroup className="d-flex flex-column">
                <RangePicker 
                    id="period" 
                    label="Plage horaires" 
                    format={"DD/MM/YYYY HH:mm"}
                    onChange={onChangeTime}
                    value={rangeTime?.length === 2 ? [moment(rangeTime[0]), moment(rangeTime[1])] : null}
                    // disabled={disabled}
                    showTime
                />
            </FormGroup>

            <div className="hstack gap-2 justify-content-end">  
                <ButtonUi color={"primary"} soft onClick={initForm}>
                    <div className="d-flex align-items-center">
                        <MdOutlineRefresh className="me-1"/>
                        <FormattedMessage id="COMMON.RESET"/>
                    </div>
                </ButtonUi>
                <ButtonUi color={"primary"} type="submit" disabled={!isActive() || disabled} onClick={submit}>
                    <FormattedMessage id="COMMON.EXECUTE"/>
                </ButtonUi>
            </div>
        </div>
    );
}

export default FetchReportForm;




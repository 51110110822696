import { VehicleInfoType } from '../../../Objects/_redux/types';
import { VisibleSensor } from './type';
import * as vehicleActions from './vehicleActions';
import { RETRIEVE_UNIT_SENSORS_END, VehicleActionsType } from './vehicleActions';

type StateType = {
    loading: boolean;
    vehicle: VehicleInfoType | null;
    sensors?: VisibleSensor[],
};

const INITIAL_STATE: StateType = {
    loading: false,
    vehicle: null,
};

export function ObjectReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: VehicleActionsType
): StateType {
    switch (action.type) {
        case vehicleActions.INIT_VEHICLE: {
            return { ...state, vehicle: action.payload.vehicle, sensors:undefined, loading: true };
        }

        case vehicleActions.SET_VEHICLE_DATA: {
            const {_id, data} = action.payload;
            if(!!state.vehicle && _id === state.vehicle._id) {
                return {
                    ...state,
                    vehicle: {
                        ...state.vehicle,
                        baseInfo: data
                    },
                        
                }
            }
            return state;
        }
        
        case vehicleActions.SET_VEHICLE_CNX: {
            const {_id, data} = action.payload;
            if(!!state.vehicle && _id === state.vehicle._id) {
                return {
                    ...state,
                    vehicle: {
                        ...state.vehicle,
                        online: data === 'true'
                    },
                        
                }
            }            

   
            
            return state;
        }

        case RETRIEVE_UNIT_SENSORS_END: {
            return {
                ...state,
                sensors: action.payload,
                loading: false
            }
        }
        
        case vehicleActions.CLEAR: {
            return INITIAL_STATE;
        }

        default:
            return state;
    }
}

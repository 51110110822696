import * as authActions from './authActions';
import { AuthActionsType } from './authActions';
import { LoginEndType } from './types';

type StateType = LoginEndType & {
    loading: boolean;
    timeOut: number | null;
    error: string | null;
};

const INITIAL_STATE: StateType = {
    expiresIn: 0,
    expiresInRefresh: 0,
    token: null,
    refreshToken: null,
    user: null,
    loading: false,
    timeOut: null,
    error: null,
};

export const authReducer = (
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: AuthActionsType
): StateType => {
    switch (action.type) {
        case authActions.LOGIN_START:
        case authActions.SWITCH_USER_START_TYPE:
            return { ...state, loading: true };

        case authActions.LOGIN_END:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case authActions.LOGIN_GENERATE_TOKEN:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };

        case authActions.CHANGE_USER_TYPE: {
            return {
                ...state,
                user: action.payload,
                token: null,
            };
        }

        case authActions.SWITCH_USER_END_TYPE: {
            return {
                ...state,
                loading: false,
            };
        }

        case authActions.LOGOUT:
            return INITIAL_STATE;
            
        case authActions.ERROR:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

import axios from 'axios';
import { BASE_URL } from '../env';
import { ErrorEvents } from '../pages/Alarms/redux/events.actions';
import { LoginEnd } from '../pages/Authentification/_redux/authActions';
import { requestNewToken } from '../pages/Authentification/_redux/authService';
import { LoginEndType } from '../pages/Authentification/_redux/types';
import { ErrorBillings } from '../pages/Billing/redux/billing.actions';
import { ErrorCompanys } from '../pages/Company/redux/company.actions';
import { ErrorDriver } from '../pages/Driver/_redux/driverModule.actions';
import { ErrorGeofence } from '../pages/Geofence/_redux/geofenceModule.actions';
import { ErrorMaintenance } from '../pages/Maitenance/_redux/maintenance.actions';
import { ErrorShares } from '../pages/SharePosition/redux/share.actions';
import { ErrorTracer } from '../pages/Tracers/redux/tracer.actions';
import { ErrorVehicles } from '../pages/Vehicles/redux/vehicleModule.actions';
import { SetNotificationAction } from './rootActions';
import store from './store';

export const axiosInstance = axios.create({
    baseURL: `${BASE_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.interceptors.request.use((config) => {
    const newConfig = { ...config };
    const state = store.getState();
    const { token } = state.authReducer;
    if (token && newConfig && newConfig.headers) {
        newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
});

axiosInstance.interceptors.response.use(
    (config) => {
        return config;
    },
    async (err) => {
        const state = store.getState();
        const originalConfig = err.config;
        const { dispatch } = store;
        const { refreshToken } = state.authReducer;
        if (err.response) {
            const { status } = err.response;
            if (status === 401 && refreshToken && !err.config.retry) {
                // eslint-disable-next-line no-param-reassign
                err.config.retry = true;
                try {
                    const response: {
                        data: LoginEndType;
                        status: number;
                    } = await requestNewToken(refreshToken);
                    if (
                        response &&
                        (response.status === 201 || response.status === 200)
                    ) {
                        dispatch(LoginEnd(response.data));
                    }

                    return axiosInstance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            } else if(status === 401) {
                errorMsg(dispatch, {
                    message: 'Session expirée', 
                    title: "Votre session a expiré"
                });
            }

            if(status === 403 || status === 404) {
                errorMsg(dispatch, {
                    message: 'Problème de droit', 
                    title: "Vous n’avez pas l’autorisation d'utiliser cette ressource"
                });
            } else if(status >= 500) {
                errorMsg(dispatch, {
                    message: 'Erreur interne', 
                    title: "Un problème interne est survenue, veuillez actuliser votre page !"
                });
            }
        }
        return Promise.reject(err);
    }
);



const errorMsg = (dispatch: any, payload: {message: string, title: string}) => {
    dispatch(SetNotificationAction({
        type: 'error',
        ...payload,
        timeToClose: 10000
    }));
    Promise.all([
        ErrorEvents(''),
        ErrorBillings(''),
        ErrorCompanys(''),
        ErrorDriver(''),
        ErrorGeofence(''),
        ErrorMaintenance(''),
        ErrorShares(''),
        ErrorTracer(''),
        ErrorVehicles('')
    ])
}
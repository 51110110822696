import AllStatus from '../../../../assets/images/objects/all.svg';
import { Timer } from '../../../Timer';
import ChargingIcon from '../Icon/ChargingIcon';
import ConnexionIcon from '../Icon/connexionIcon';
import MovementObjectIcon from '../Icon/movementObjectIcon';
;

export const Object = () => {
    return(
        <div className="object-info py-2 px-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
            <img src={AllStatus} className="me-2"/>
            <div>
                <h6 className="mb-0">Sac 123455</h6>
                <div className="d-flex">
                    <div>
                        <span className="text-muted fs-10">
                            <Timer date={new Date(2022, 2, 28)} lang={"fr"}/>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div className="d-flex align-items-center justify-content-between" style={{width: '60px'}}> 
            <ChargingIcon battery={80} size={16}/>
            <MovementObjectIcon size={16} moving={false}/>
            <ConnexionIcon size={16} enable={false}/>
        </div>
    </div>
    )
}
import React from 'react';
import './App.css';
import './assets/scss/theme.scss';

import { LayoutStyleContextProvider } from './context/layout-style-context';
import I18nContextprovider from './context/i18n-context';
import { I18nProvider } from './i18n/I18nProvider';
import { RoutesModule } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

function App() {
  return (
    <Provider store={store}>
      <I18nContextprovider>
            <I18nProvider>
              <ConfigProvider locale={frFR}>
                <LayoutStyleContextProvider>
                  <BrowserRouter>
                    <RoutesModule/>
                  </BrowserRouter>
                </LayoutStyleContextProvider>
                </ConfigProvider>
          </I18nProvider>
        </I18nContextprovider>
    </Provider>
    

    
  );
}

export default App;

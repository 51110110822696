import { filterType, VehicleInfoListResponse } from './types';
import * as vehiclesActions from './unit-list.actions';
import { VehiclesListActionsType } from './unit-list.actions';

type StateType = {
    vehicles: VehicleInfoListResponse;
    filter: filterType;
    loading: boolean;
};

const INITIAL_STATE: StateType = {
    vehicles: {
        list: [],
        total: 0,
    },
    filter: {
        limit: 20,
        skip: 0,
    },
    loading: false,
};

export function vehicleListReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: VehiclesListActionsType
): StateType {
    switch (action.type) {
        case vehiclesActions.GET_START:
        case vehiclesActions.GET_SHARE_START:
            return { ...state, loading: true };

        case vehiclesActions.GET_END: {
            const { vehicleResponse, filter } = action.payload;
            const { list, total } = vehicleResponse;
            return {
                ...state,
                loading: false,
                vehicles: { list, total },
                filter,
            };
        }

        case vehiclesActions.SET_VEHICLE_DATA: {
            const {_id, data} = action.payload;
            const index = state.vehicles.list.findIndex(v => v._id === _id);
            
            if(index !== -1) {
                const object = state.vehicles.list[index];
                object.baseInfo = data;
                const list = [
                    ...state.vehicles.list.slice(0, index),
                    object,
                    ...state.vehicles.list.slice(index + 1)
                ];
                return {
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        list
                    }
                }
            }
            
            return state;
        }

        case vehiclesActions.SET_VEHICLE_CNX: {
            const {_id, data} = action.payload;
            const index = state.vehicles.list.findIndex(v => v._id === _id);
            
            if(index !== -1) {
                const object = state.vehicles.list[index];
                object.online = data === 'true';
                const list = [
                    ...state.vehicles.list.slice(0, index),
                    object,
                    ...state.vehicles.list.slice(index + 1)
                ];
                return {
                    ...state,
                    vehicles: {
                        ...state.vehicles,
                        list
                    }
                }
            }
            
            return state;
        }

        case vehiclesActions.ERROR:
            return { ...state, loading: false };

        default:
            return state;
    }
}

import moment from "moment";
import { useState, useEffect } from "react";
import { IconType } from "react-icons";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { CardBody, CardFooter, CardHeader, Col, Container, Row } from "reactstrap";
import { MaintenanceInfo } from "../../Components/advanced/Maintenance/maintenance-info";
import { BadgeUi } from "../../Components/basic/BadgeUi";
import { BreadCrumbActionUi } from "../../Components/basic/BreadCrumbActionUi";
import { CardUi } from "../../Components/basic/Card";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { Paginator } from "../../Components/basic/Paginator";
import { ActionTableDropdown } from "../../Components/Dropdown/TableActionDropdown";
import { InputSearch } from "../../Components/Form/InputSearch";
import { TableUi } from "../../Components/Table";
import { Tabs } from "../../Components/Tabs";
import { useAppSelector } from "../../redux/hooks";
import { ActionTabBar } from "../Company/components/ActionTabBar";
import { MaintenanceInList } from "./types";
import { DeleteMaintenanceStart, GetFiltersMaintenanceStart, GetMaintenanceListStart, SetSelectedMaintenance } from "./_redux/maintenance.actions";
import Emitter from "../../helpers/emitter";

const MaintenancePage = () => {
    let FILTERS = [
        { label: 'COMMON.ALL', value: 'all' },
        { label: 'COMMON.IN_PROGRESS', value: 'valid' },
        { label: 'COMMON.EXPIRED', value: 'expired' }

    ];
    const [activeFilter, setActiveFilter] = useState(0);
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const {groupId} = useParams()

    const { user } = useAppSelector((state) => state.authReducer);
    const { maintenance, filter, groups, selectedMaintenances,loading } = useAppSelector( (state) => state.MaintenanceReducer );

    useEffect(() => {
        dispatch(GetFiltersMaintenanceStart());
    }, []);

    useEffect(()=> {
        if(groups.length > 0) {
            navigate({ pathname: `/maintenance/${groups[0]._id}`});
        }
    }, [groups]);

    useEffect(()=> {
        if(groupId) dispatch(GetMaintenanceListStart({...filter, groupId}));
    }, [groupId]);

    const onChangeSelectedItem = (keys: string[]): void => {
        dispatch(SetSelectedMaintenance(keys));
    };

    const onChangeFilter = (index: number): void => {
        setActiveFilter(index);
        dispatch(GetMaintenanceListStart({...filter, ...{ select: FILTERS[index].value } }))
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(GetMaintenanceListStart({...{ ...filter, ...{ skip: 0 } }, ...{ name: data.searchBy }, }))
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetMaintenanceListStart({ ...filter, ...{ skip, limit } }));
    };


    const onClickAction = (action: string, _ids: string[]) => {
        switch(action) {
            case 'add':
                navigate({
                    pathname: `/maintenance/${_ids[0]}`,
                });
                break;
            case 'edit':
                navigate({
                    pathname: `/maintenance/${groupId}/${_ids[0]}`,
                });
                break;
            case 'delete':
                if(groupId) {
                    Emitter.emit(Emitter.event.DELETE_POPUP, {
                        textConfirmation: "confirm",
                        title: _ids.length > 1 ? "Supprimer les maintenances" : "Supprimer la maintenance",
                        description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                        onDelete: () => dispatch(DeleteMaintenanceStart({_ids, groupId}))
                    });       
                }
                break;
        }
    }

    const actions: ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] = [
        {
            Icon: AiOutlineEdit,
            name: intl.formatMessage({ id: 'COMMON.UPDATE' }),
            action: 'edit'
        },
        {
            Icon: AiOutlineDelete,
            name: intl.formatMessage({ id: 'COMMON.DELETE' }),
            action: 'delete'
        },
    ];


    const columns = [
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            render: (value: undefined, data: MaintenanceInList) => {
                const {expirationDate, expirationHours, expirationKm} = data
                const expired = expirationDate && expirationDate < 0 || expirationHours && expirationHours < 0 || expirationKm && expirationKm < 0;
                 if(expired) {
                    return(<BadgeUi color="danger" light><FormattedMessage id="COMMON.EXPIRED" /></BadgeUi>)
                 }
                 return(<BadgeUi color="success" light><FormattedMessage id="COMMON.ACTIVED" /></BadgeUi>);
            }
        },
        {
            title: 'Unité',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: 'Éxpiration (KM)',
            dataIndex: 'expirationKm',
            key: 'expirationKm',
            render: (value: number | null) => {
                if(value || value === 0) {
                    if(value > 0) {
                        return(
                            <h6 className="mb-0">
                                Expire dans {value.toFixed(1)} Km
                            </h6> 
                        );
                    }
                    return(
                        <h6 className="text-danger mb-0">
                            Expiré depuis {(value*-1).toFixed(1)} Km
                        </h6> 
                    );
                }
                return '-';
            }
        },
        {
            title: 'Éxpiration (Date)',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render: (value: number | null) => {
                if(value || value === 0) {
                    if(value > 0) {
                        return(
                            <h6 className="mb-0">
                                Expire dans {moment.duration(value).asDays().toFixed(1)} Jours
                            </h6> 
                        );
                    }
                    return(
                        <h6 className="text-danger mb-0">
                            Expiré depuis {moment.duration(value*-1).asDays().toFixed(1)} Jours
                        </h6> 
                    );
                }
                return '-';
            }
        },
        {
            title: 'Éxpiration (Heures moteur)',
            dataIndex: 'expirationHours',
            key: 'expirationHours',
            render: (value: number | null) => {
                if(value || value === 0) {
                    if(value > 0) {
                        return(
                            <h6 className="mb-0">
                                Expire dans {moment.duration(value).asHours().toFixed(1)} Heures
                            </h6> 
                        );
                    }
                    return(
                        <h6 className="text-danger mb-0">
                            Expiré depuis {moment.duration(value*-1).asHours().toFixed(1)} Heures
                        </h6> 
                    );
                }
                return '-';
            }
        },
        {
            title:intl.formatMessage({ id: 'COMMON.ACTIONS' }),
            dataIndex: 'key',
            key: 'key',
            render: (name: string) => {
                return (
                    <ActionTableDropdown 
                        _id={name} 
                        onChange={onClickAction} 
                        options={actions}
                    />
                )
            }
        },
    ];


    
    return (
        <div id="maintenancePage" className="page-content">
            <Container fluid>
                <BreadCrumbActionUi 
                    title={intl.formatMessage({ id: 'MAINTENANCE' })} 
                    pageTitle={user?.company.name || ''} 
                    actionName={intl.formatMessage({ id: 'MAINTENANCE.CREATE' })}
                    onClickAction={()=> onClickAction('add', ['add'])}
                />
                   
                <Row>
                    {
                        groups.length > 0 && <Col md={3} sm={4}>
                        <CardUi className="list">
                            <LoaderUi loading={loading}/>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">
                                        <FormattedMessage id="MAINTENANCE.TYPE"/>
                                    </h5>
                                   
                            </CardHeader>
                            <CardBody className="px-0">
                                {
                                    groups.map(grp => <MaintenanceInfo 
                                            active={groupId === grp._id}
                                            key={grp._id} 
                                            expired={grp.expired} 
                                            valid={grp.all - grp.expired} 
                                            name={grp.name}
                                            onClickAction={()=> navigate({ pathname: `/maintenance/${grp._id}`})}
                                        />
                                    )
                                }
                            </CardBody>
                        </CardUi>
                    </Col>
                    }
                    
                    <Col md={groups.length > 0 ? 9 : 12} sm={groups.length > 0 ? 8 : 12}>
                        <CardUi>
                            <LoaderUi loading={loading}/>
                            <CardHeader className="d-flex justify-content-between">
                            { 
                                    selectedMaintenances.length === 0 ? 
                                    <Tabs 
                                        color="primary" 
                                        pills
                                        className="card-header-tabs border-bottom-0 d-none d-sm-flex" 
                                        onChange={(index: number) => onChangeFilter(index)}
                                    >
                                        { FILTERS.map((filter, index: number)=> <div><FormattedMessage id={filter.label}/> {index === activeFilter && `(${maintenance.total})`}</div>)}
                                    </Tabs>
                                    :
                                    <ActionTabBar selected={selectedMaintenances} onClick={onClickAction}/>
                                }
                                <InputSearch
                                    type="text"
                                    name={'searchBy'}
                                    placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                                    onSubmit={onSearch}
                                />
                            </CardHeader>
                            <CardBody className="p-0">
                                <TableUi 
                                    columns={columns}
                                    data={maintenance.list}
                                    selected={selectedMaintenances}
                                    onChangeSelectedItem={onChangeSelectedItem}
                                    minusHeight={190+60+ (Math.ceil(maintenance.total / filter.limit) > 1 ? 60 : 0)}

                                />
                            </CardBody>
                            {
                                Math.ceil(maintenance.total / filter.limit) > 1 ? (
                                    <CardFooter>
                                    <Row>
                                        <Col>
                                            <Paginator
                                                total={maintenance.total}
                                                skip={filter.skip}
                                                limit={filter.limit}
                                                onPaginate={onPaginate}
                                            />
                                        </Col>
                                    </Row>
                                </CardFooter>
                                ) : <></>
                            }
                        </CardUi>
                    </Col>
                </Row>
                
            </Container>
        </div>
        
    )
}

export default MaintenancePage;
import { useEffect, useRef } from "react";
import { AiOutlineArrowLeft, AiOutlineEdit, AiOutlineFileAdd } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { CardHeader, Modal, ModalBody, ModalHeader } from "reactstrap";
import { CardUi } from "../../../../Components/basic/Card";
import { CardBodyUi } from "../../../../Components/basic/CardBodyUi";
import { useDispatch } from "react-redux";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { SetNotificationAction, SetOptionMapAction } from "../../../../redux/rootActions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import { BsClockHistory } from "react-icons/bs";
import { DriverAction } from "../../../../Components/advanced/Driver/Driver-action";
import { ScrollBar } from "../../../../Components/PerfectScrollBar";
import { DriverEcoRate } from "./DriverEcoRate";
import { useAxios } from "../../../../common/hooks/axios/useAxios";
import { DriverInListType } from "../../_redux/types";
import { DriverProfile } from "./DriverProfile/DriverProfile";
import { AddViewPoint } from "./DriverViewPoint/AddViewPoint";
import { DriverAssignList } from "../../Assign";
import { DriverReviewRate } from "./DriverReviewRate";
import { FaCarAlt, FaMapMarkerAlt, FaUnlink } from "react-icons/fa";
import { Address } from "../../../../Components/Address";
import { BiLink } from "react-icons/bi";
import { Popconfirm } from "antd";
import { ManageDriver } from "../../Manage";
import Emitter from "../../../../helpers/emitter";
import EmitterActions from "../../../../helpers/emitter/emitter.actions";
import { AssignDriverSlider } from "../assign-vehicle/VehicleAssignSlider";

export const DriverDetails = () => {
    const { key } = useParams();
    const [queryParameters] = useSearchParams();
    const action = queryParameters.get('action');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const refScrollBar = useRef();
    
    const {data, loading, execute} = useAxios<DriverInListType>(
        false, 
        `driver/${key}/details`, 
        { method: 'GET'}
    );

    const { loading: loadingDelete, execute: deleteDriverRequest, status} = useAxios(true);
    const toggleModal = () => {
        if(action) {
            navigate(key ? `/drivers/${key}/details` : '/drivers');
        } else {
            navigate('/drivers?action=add');
        }
    }
    
    const navigateToAddReview = () => {
        let params = "?add-view-point=1";
        if(queryParameters.get('reviews')) {
            params+='&reviews=1';
        }
        navigate(`/drivers/${key}/details${params}`);
    }
    
    const toogleHide = (): void => {
        const padding = 374;
        dispatch(SetOptionMapAction({opt: 'padding', value: padding}));
        navigate('/drivers');
    };

    const deleteDriver = () => {
        if(!data?.assignment?.to && !loadingDelete) {
            deleteDriverRequest(`driver?keys[]=${key}`, {method: 'DELETE'})
        }
        
    }

    useEffect( () => {
        Emitter.on(EmitterActions.REFRESH_DRIVER_INFO, ()=> execute(`driver/${key}/details`, {method: 'GET'}));
        return () => {
            Emitter.off(EmitterActions.REFRESH_DRIVER_INFO, ()=> execute(`driver/${key}/details`, {method: 'GET'}));
        };
    }, []);
    
    useEffect(()=> {
        if(status && status <= 204)  {
            navigate(`/drivers`);
            dispatch(SetNotificationAction({
                title: `succès`,
                "message": `Conducteur supprimé`,
                timeToClose: 5000,
                type: 'success'
            }));
        }
    }, [status]);  

    return (
        <>
            <CardUi className="driver-details">
                <LoaderUi loading={loadingDelete}/>
                <CardHeader className="d-flex align-items-center mb-0">
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={toogleHide}
                        onKeyDown={toogleHide}
                        className="me-3 p-1 ghost btn btn-primary btn-sm rounded-circle"
                    >
                        <AiOutlineArrowLeft size={20} />
                    </div>
                    <h5 className="d-flex align-items-center mb-0 text-primary">
                        <span><FormattedMessage id={"DRIVER.DETAILS"}/></span>
                    </h5>
                </CardHeader>
                
                
                <CardBodyUi className="p-0">
                    <ScrollBar
                        id="detail-item-scrollbar"
                        containerRef={refScrollBar}
                        minusHeight={170}
                    > 
                        <DriverProfile className="p-3" loading={loading} driver={data} />

                        <div className="py-3 px-3 border-bottom">
                            <h6 className="text-primary fs-14">Actions</h6>
                            <div className="d-flex mt-3">
                                <DriverAction 
                                    color="secondary"
                                    icon={BsClockHistory} 
                                    text="Historiques d'affectation"
                                    onClick={()=> navigate(`/drivers/${key}/details?assignments=1`)}
                                />
                                <DriverAction 
                                    color="secondary"
                                    icon={AiOutlineFileAdd} 
                                    text="Ajouter un avis"
                                    onClick={navigateToAddReview}
                                />
                                <DriverAction 
                                    color="secondary"
                                    icon={AiOutlineEdit} 
                                    text="Modifier"
                                    onClick={()=> navigate('?action=update')}
                                />
                                {/* <DriverAction 
                                    color="secondary"
                                    icon={FiLayers} 
                                    text="Ajouter à un groupe"
                                    onClick={()=> alert('add groupe')}
                                /> */}
                                <Popconfirm
                                    title="Êtes-vous sûr ?"
                                    onConfirm={deleteDriver}            
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <DriverAction 
                                        color="danger"
                                        icon={FiTrash} 
                                        text="Supprimer"
                                        disabled={!!data?.assignment?.to}
                                    />
                                </Popconfirm>
                                
                            </div>
                        </div>

                        
                        <DriverReviewRate />
                        <DriverEcoRate />

                        <div className="py-3 px-3">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <h6 className="text-primary fs-14 mb-0">Informations</h6>
                            </div>
                            
                            <div className="d-flex flex-column mt-1">
                            {
                                    <div className="d-flex flex-row align-items-center mt-3">
                                        { data?.assignment?.to ? 
                                                <BiLink size={24} className="text-info me-3"/>
                                                : <FaUnlink size={20} className="text-info me-3"/>
                                            }
                                        
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">État</label>
                                            
                                            { data?.assignment?.to ? 
                                                <span className="text-muted fs-11">Assigné</span> 
                                                : <span className="text-muted fs-11">Non assigné</span> 
                                            }
                                            
                                        </div>
                                    </div>
                                }
                                {
                                    data?.assignment?.to && <div className="d-flex flex-row align-items-center mt-3">
                                        <FaCarAlt size={20} className="text-info me-3"/>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Véhicule</label>
                                            <span className="text-muted fs-11">{data?.assignment?.to}</span>
                                        </div>
                                    </div>
                                }
                                {
                                    data?.position && !!data?.position.longitude &&  <div className="d-flex flex-row align-items-center mt-3">
                                        <FaMapMarkerAlt size={20} className="text-info me-3"/>
                                        <div className="d-flex flex-column fs-12">
                                            <label className="mb-0">Adresse</label>
                                            
                                            <span className="text-muted fs-11"><Address coordinates={[data?.position.latitude, data?.position.longitude]}/></span>
                                        </div>
                                    </div>
                                }

                                
                            
                            </div>
                        </div> 
                    </ScrollBar>
                </CardBodyUi>
                <AddViewPoint />
                <Modal 
                    isOpen={!!action}
                    toggle={toggleModal}
                    size={"lg"}
                    fullscreen={"sm"}
                >
                    <ModalHeader toggle={toggleModal}>
                        {
                            action === 'update'  ? <FormattedMessage id="DRIVER.UPDATE"/> : <FormattedMessage id="DRIVER.CREATE"/>
                        }
                    </ModalHeader>
                    <ModalBody>
                        <ManageDriver/>
                    </ModalBody>
                </Modal>
                <DriverAssignList />
            </CardUi> 
            <AssignDriverSlider />
        </>
        
    );
}
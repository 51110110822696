import { useIntl } from "react-intl";
import { useAppSelector } from "../../../../../../redux/hooks";
import { linearInterpolation } from "../../../../../../helpers/formula";
import { useEffect, useState } from "react";
import { Col, Input, Label } from "reactstrap";
import { BasicPlot } from "../../../../../../Components/Plotly";

export const CalibrationGraph = () => {
    const { sensors } = useAppSelector((state) => state.vehicleModuleReducer);
    const [shape, setShape] = useState(undefined);

    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const intl = useIntl();
    const calibration = sensors.calibration;

    useEffect(()=> {
        const yValue = linearInterpolation(x, calibration);
        if(x || x === 0) {
            setY(yValue);
            setShape(yValue);
        }
    }, [x, y]);




    return (
        <>
            
            <BasicPlot
                data={[
                    {
                        x: sensors.calibration.map(item => item.x),
                        y: sensors.calibration.map(item => item.y),
                        type: 'scatter',
                        name: 'Calibration',
                        title: {
                            text: 'X'
                        }
                    },

                    {
                        x: calibration.length > 0 ? [calibration[0].x, sensors.calibration[calibration.length-1].x] : [0, 0],
                        y: calibration.length > 0 ? [calibration[0].y, sensors.calibration[calibration.length-1].y] : [0, 0],
                        type: 'scatter',
                        line: {color: 'red', dash:"dot"},
                        name: 'Linéaire',
                    },
                    {
                        x: [x], 
                        y: [y], 
                        name: "valeur",
                        mode: "lines+markers",
                        marker: { size: 14, color: 'rgb(255, 0, 0)', },
                    }


                ]}

                layout={{  
                    margin: { t: 20, b: 20, l: 30, r: 0 },            
                    xaxis: {
                        anchor: 'free',
                        showspikes: true,
                        spikemode: 'across',
                        spikethickness: 1,
                        spikesnap: 'cursor',
                    },
                    yaxis: {
                        anchor: 'free',
                        showspikes: true,
                        spikemode: 'across',
                        spikethickness: 1,
                        spikesnap: 'cursor',
                    },
                    shapes: [
                        {
                            type: 'line',
                            xref: 'x',
                            x0: calibration.length > 0 ? calibration[0].x : 0,
                            y0: y,
                            x1: x,
                            y1: y,
                            line:{
                                color: 'rgb(255, 0, 0)',
                                width: 2,
                                dash:'dot'
                            }
                        },
                        {
                            type: 'line',
                            xref: 'x',
                            x0: x,
                            y0: 0,
                            x1: x,
                            y1: y,
                            line:{
                                color: 'rgb(255, 0, 0)',
                                width: 2,
                                dash:'dot'
                            }
                        }
                    ]
                }}
                config={{
                    modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d', 'zoom2d'],
                }}
            /> 
            <Col xs={12}>
                
                <div className="d-flex align-items-center">
                    <Input
                        className="mb-2 mt-3 float-right"
                        placeholder="Valeur X"
                        style={{width: 110}}
                        type='number'
                        onChange={(e) => setX(+e.target.value)}
                    />

                    <Label className="ms-2 mb-2 mt-3" >X: {x}, Y: {y}</Label>
                </div>
                
            </Col>
        </>
        
    );
}

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListMapComponent from "../../Components/List/ListMap/ListMap";
import HeaderListMapComponent from "../../Components/List/ListMap/ListMapHeader";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import ColorPicker, { ColorObject } from "react-pick-color";
import { Form, FormGroup, Label } from "reactstrap";
import { InputText } from "../../Components/Form/InputText";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import EmitterActions from "../../helpers/emitter/emitter.actions";
import Emitter from "../../helpers/emitter";
import { LatLng } from "leaflet";
import { isNumber } from "lodash";
import { SetNotificationAction } from "../../redux/rootActions";
import { useDispatch } from "react-redux";
import { useManageStop } from "../../common/hooks/axios/stops/useManageStop";
import { useFetchStop } from "../../common/hooks/axios/stops/useFetchPassenger";

export const ManageManualStopPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const intl = useIntl();
    const [isVisible, setVisibility] = useState<boolean>(true);
    const [canChooseColor, setChooseColor] = useState(false);
    const [color, setColor] = useState("#FF0000");
    const { key } = useParams();
    const { saveAsyncStop, manageStopLoading } = useManageStop({key});
    const { stop, fetchStopLoading } = useFetchStop({manual: false, key});

    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

    // Effects
    useEffect( () => {
        Emitter.on(Emitter.event["STOP:UPDATE"], setInputsValues);
        if(!key) {
            setTimeout(()=> {
                Emitter.emit(Emitter.event["STOP:UPDATE_FORM"], { 
                    visible: true, color, radius: 150 
                });
            }, 1);
        }

        
        return () => {
            Emitter.emit(Emitter.event["STOP:UPDATE_FORM"], {  visible: false });
            Emitter.off(Emitter.event["STOP:UPDATE"], setInputsValues);
        };
    }, []);

    useEffect(()=> {
        if(stop) {
            setValue('name', stop.name);
            setValue('description', stop.description);
            if(stop.location) {
                const [lng, lat] = stop.location.coordinates;
                Emitter.emit(Emitter.event["STOP:UPDATE_FORM"], { 
                    visibility: true, 
                    location: {
                        lat, lng
                    },
                    visible: true, color: stop.color, radius: stop.radius 
                });
            }
        }
    }, [stop?.location, stop?.name, stop?.description, stop?.radius, stop?.color]);


    // Functions
    const setInputsValues = useCallback(({location, radius, color}: {location: LatLng, radius: number, color: string}) => {
        if(radius) { setValue('radius', radius); }
        if(color) { setValue('color', color); setColor(color);}
        if(location.lat) { setValue('latitude', location.lat); }
        if(location.lng) { setValue('longitude', location.lng); }
    }, []);

    const changeColor = useCallback((color: ColorObject) => {
        Emitter.emit(Emitter.event["STOP:UPDATE_FORM"], { color: color.hex });
        setColor(color.hex);
    }, []);


    // Events
    const toggleVisibility = () => { setVisibility(!isVisible); }
    const onBack = () => {  navigate('/stops'); }
    const onChangeRadius = (event: ChangeEvent<HTMLInputElement>) => { 
        if (event) {
            const newRadiusValue = parseInt(event.target.value, 10);
            if(newRadiusValue >= 1) {
                Emitter.emit(Emitter.event["STOP:UPDATE_FORM"], {radius: newRadiusValue});
            }  
        }
    };
    const changeLatitude = (event: ChangeEvent<HTMLInputElement>) => {
        if(event) {
            const lngParsed = parseFloat(getValues('longitude'));
            const latParsed = parseFloat(event.target.value);
            const longitude = isNumber(lngParsed) && !isNaN(lngParsed) ? lngParsed : 0;
            if(isNumber(latParsed) && !isNaN(latParsed)) {
                setValue('latitude', latParsed);
                setValue('longitude', longitude);
                Emitter.emit(
                    Emitter.event["STOP:UPDATE_FORM"], 
                    { location: { lat: latParsed, lng: longitude } }
                );
            }
        }
    }

    const changeLongitude = (event: ChangeEvent<HTMLInputElement>) => {
        if(event) {
            const lngParsed = parseFloat(event.target.value);
            const latParsed = parseFloat(getValues('latitude'));
            const latitude = isNumber(latParsed) && !isNaN(latParsed) ? latParsed : 0;

            if(isNumber(lngParsed) && !isNaN(lngParsed)) {
                setValue('latitude', latitude);
                setValue('longitude', lngParsed);
                Emitter.emit(
                    Emitter.event["STOP:UPDATE_FORM"], 
                    { location: { lat: latitude, lng: lngParsed } }
                );
            }
        }
    }


    const submit = async (data: any) => { 
        const result = await saveAsyncStop({...data, key});
        if(result.success) {            
           navigate(`/stops`);
            dispatch(SetNotificationAction({
                title: "succès",
                "message": key ? "Arrêt modifié" : "Arrêt ajouté",
                timeToClose: 3000,
                type: 'success'
            }));
        }
    };

    const back = async (data: any) => { 
        navigate("/stops")
    };

    return (
        <div className="page-content object-page" >
            <ListMapComponent isVisible={isVisible} toggleVisibility={toggleVisibility} loading={fetchStopLoading || manageStopLoading}>
                <HeaderListMapComponent 
                    title={ key ?  "Modifé l'arrêt" :  "Ajouté un arrêt"} 
                    toggleVisibility={toggleVisibility}
                    back={onBack}
                />
                <Form className="p-3" onSubmit={handleSubmit(submit)}>
            
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'COMMON.NAME'})}
                            id={'name'}
                            name={'name'}
                            placeholder={intl.formatMessage({id: 'STOP.PLACEHOLDER.NAME'})}
                            register={register('name', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            error={errors['name']}
                        />
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            type="textarea"
                            label={intl.formatMessage({id: 'COMMON.DESCRIPTION'})}
                            id={'description'}
                            name={'description'}
                            placeholder={intl.formatMessage({id: 'STOP.PLACEHOLDER.DESCRIPTION'})}
                            register={register('description')}
                            error={errors['description']}
                        />
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <div className="d-flex flex-row align-items-center" onClick={()=> setChooseColor(!canChooseColor)}>
                            <h6 className="font-size-sm mb-0">Couleur de la géofence :</h6>
                            <div
                                className="rounded ms-2"
                                style={{ backgroundColor: color, height: 18,
                                width: 18 }}
                            />
                            
                        </div>
                        {
                            canChooseColor && 
                            <div style={{position: 'absolute', top: -320}}>
                                <div onMouseLeave={()=> setChooseColor(false)}>
                                    <ColorPicker color={color} onChange={changeColor}/>
                                </div>
                            </div>
                        }
                    </FormGroup>
                    <FormGroup>
                        <InputText
                            type="number"
                            min={1}
                            label={intl.formatMessage({id: 'GEOFENCE.RADIUS'})}
                            id={'radius'}
                            name={'radius'}
                            placeholder={intl.formatMessage({id: 'STOP.PLACEHOLDER.RADIUS'})}
                            register={register('radius', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                }})}
                            onChange={onChangeRadius}
                            error={errors['radius']}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label> <FormattedMessage id="GEOFENCE.COORDINATES"/> </Label>
                        <div className="d-flex">
                            <InputText
                                className="me-2"
                                type="number"
                                step="any"
                                id={'latitude'}
                                name={'latitude'}
                                placeholder={intl.formatMessage({id: 'GEOFENCE.LATITUDE'})}
                                register={register('latitude', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                onChange={changeLatitude}
                                error={errors['latitude']}
                            />
                            <InputText
                                type="number"
                                step="any"
                                id={'longitude'}
                                name={'longitude'}
                                placeholder={intl.formatMessage({id: 'GEOFENCE.LONGITUDE'})}
                                register={register('longitude', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                onChange={changeLongitude}
                                error={errors['longitude']}
                            />
                        </div>
                    
                    </FormGroup>

                    <div className="d-flex flex-row align-items-center justify-content-sm-end mt-4">
                        <ButtonUi className="me-2" color="primary" outline onClick={back} >
                            <FormattedMessage id="COMMON.CANCEL" />
                        </ButtonUi>
                        <ButtonUi color={"primary"} type="submit">
                             { key ?  <FormattedMessage id="COMMON.UPDATE"/> :  <FormattedMessage id="COMMON.ADD"/>} 
                        </ButtonUi> 
                    </div>
                </Form>
            </ListMapComponent>
        </div>
    );
      
        
}  
import {
    createElementObject,
    createPathComponent,
    extendContext,
  } from '@react-leaflet/core';
import L from 'leaflet';
import './lib/drif-marker';
import 'leaflet-rotatedmarker';




function createCustomMarker(props, context) {
    const {position, children, ...other} = props;

    const marker = new L.Marker(props.position, other);
    return createElementObject(
        marker,
      extendContext(context, { overlayContainer: marker }),
    )
  }
  
  function updateCustomMarker(instance, props, prevProps) {
        if (props.position.lat !== prevProps.position.lat || props.position.lng !== prevProps.position.lng || 
            props?.tracking?.keepTrack !== prevProps?.tracking?.keepTrack
            || props?.tracking?.padding !== prevProps?.tracking?.padding
            ) {
            instance.slideTo(props.position, {
                duration: props.duration,
                keepAtCenter: props?.tracking?.keepTrack || false,
                zoom: props?.tracking?.zoom || undefined,
                padding: props?.tracking?.padding || undefined
            })
        }

        if (props.icon !== prevProps.icon) {
            instance.setIcon(props.icon);
        }
        if (props.zIndexOffset !== prevProps.zIndexOffset) {
            instance.setZIndexOffset(props.zIndexOffset);
        }
        if (props.opacity !== prevProps.opacity) {
            instance.setOpacity(props.opacity);
        }
        if (props.draggable !== prevProps.draggable) {
            if (props.draggable === true) {
                instance.dragging.enable();
            } else {
                instance.dragging.disable();
            }
        }
        if (props.rotationAngle !== prevProps.rotationAngle) {
            instance.setRotationAngle(props.rotationAngle);
        }
        if (props.rotationOrigin !== prevProps.rotationOrigin) {
            instance.setRotationOrigin(props.rotationOrigin);
        }
  }
  
export const CustomMarker = createPathComponent(createCustomMarker, updateCustomMarker);


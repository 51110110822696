import { useEffect, useState } from "react";
import { MdCheckBox, MdError } from "react-icons/md";
import { Link } from "react-router-dom";
import { CardBody, Label } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { MaintenancePie } from "../chart/maintenance-pie";
import { getMaintenanceStats } from "../dashboard.service";

export const MaintenanceStatus = () => {

    const [ loading, setLoading ] = useState(false);
    const [ ok, setOk ] = useState(0);
    const [ expired, setExpired ] = useState(0);

    useEffect(()=> {
        setLoading(true);
        getMaintenanceStats()
            .then((response)=> {
                setOk(response.ok);
                setExpired(response.expired);
            })
            .catch((response)=> {
                setOk(response.ok);
                setExpired(response.expired);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);

    return (
        <CardUi className="flex-grow-1">
            <LoaderUi loading={loading}/>
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">État Maintenance</h6>
                    <Link to="/maintenance">voir plus</Link>
                </div>
                <div className="mt-4 d-flex flex-row justify-content-center align-items-center">
                    <MaintenancePie expired={expired} ok={ok}/>
                </div>
            </CardBody>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <MdCheckBox className="text-success me-2" size={18}/>
                            <Label className="fw-normal fs-14 mb-0">État OK</Label>
                        </div>
                        
                        <span className="text-success fs-12 fw-bold">{ok}</span>
                    </div>
                </li>
                <li className="list-group-item">
                    <div className="d-flex flex-row justify-content-between align-items-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <MdError className="text-danger me-2" size={18}/>
                            <Label className="fw-normal fs-14 mb-0">Éxpirées</Label>
                        </div>
                        
                        <span className="text-danger fs-12 fw-bold">{expired}</span>
                    </div>
                </li>
            </ul>
        </CardUi>               
    );
}
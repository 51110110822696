/* eslint-disable default-param-last */
import { combineReducers } from 'redux';
import { eventsReducer } from '../pages/Alarms/redux/events.reducer';
import { authReducer } from '../pages/Authentification/_redux/authReducer';
import { billingReducer } from '../pages/Billing/redux/billing.reducer';
import { companyReducer } from '../pages/Company/redux/company.reducer';
import { MaintenanceReducer } from '../pages/Maitenance/_redux/maintenance.reducer';
import { ObjectReducer } from '../pages/Map/Object/Details/_redux/vehicleReducer';
import { eventsObjectReducer } from '../pages/Map/Object/Event/_redux/eventReducer';
import { fuelReducer } from '../pages/Map/Object/Fuel/_redux/fuelReducer';
import { historyReducer } from '../pages/Map/Object/History/_redux/historyReducer';
import { vehicleListReducer } from '../pages/Map/Objects/_redux/unit-list.reducer';
import { sharedMapReducer } from '../pages/SharedMap/redux/sharedMap.reducer';
import { shareReducer } from '../pages/SharePosition/redux/share.reducer';
import { TracerReducer } from '../pages/Tracers/redux/tracer.reducer';
import { userReducer } from '../pages/User/redux/user.reducer';
import { vehicleModuleReducer } from '../pages/Vehicles/redux/vehicleModule.reducer';
import { driverModuleReducer } from '../pages/Driver/_redux/driverModule.reducer';
import * as mainActions from './rootActions';
import { MainActionsType } from './rootActions';
import { geofenceModuleReducer } from '../pages/Geofence/_redux/geofenceModule.reducer';
import { reportReducer } from '../pages/Report/async/redux/report.reducer';

export type notificationType = {
    type: 'info' | 'success' | 'warnning' | 'error',
    message: string,
    title: string,
    timeToClose: number
}
interface MainState {
    map: any | null;
    optionsMap:  {
        zoom?: number,
        padding: number,
        showMarker: boolean,
        isClustered: boolean,
    },
    redirect?: string
    notification?: notificationType
}

const INITIAL_STATE: MainState = {
    map: null,
    optionsMap: {
        padding: 700,
        showMarker: true,
        isClustered: false
    },
};

export function mainReducer(
    state = INITIAL_STATE,
    action: MainActionsType
): MainState {
    switch (action.type) {

        case mainActions.INIT_MAP:
            return {
                ...state,
                map: action.payload,
            };

        case mainActions.SET_OPTION_MAP: {
            const {opt, value} = action.payload
            return {
                ...state,
                optionsMap: {
                    ...state.optionsMap,
                    [opt]: value
                }
            }
        }

        case mainActions.SET_REDIRECT:
            return {
                ...state,
                redirect: action.payload,
            };

        case mainActions.SET_NOTIFICATION:
            return {
                ...state,
                notification: action.payload,
            };
        default:
            return state;
    }
}

export const rootReducer = combineReducers({
    mainReducer,
    authReducer,
    companyReducer,
    userReducer,
    vehicleModuleReducer,
    eventsReducer,
    shareReducer,
    sharedMapReducer,
    vehicleListReducer,
    ObjectReducer,
    historyReducer,
    eventsObjectReducer,
    fuelReducer,
    MaintenanceReducer,
    billingReducer,
    TracerReducer,
    driverModuleReducer,
    geofenceModuleReducer,
    reportReducer
});

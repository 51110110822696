import React from 'react';
import { ImSpinner10 } from 'react-icons/im';

export function LoaderUi({
    loading,
}: {
    loading: boolean;
}): JSX.Element | null {
    if (loading) {
        return (
            <div className="loading-container" >
                <div className="loading-item">
                    <ImSpinner10 />
                    <span>Chargement...</span>
                </div>
            </div>
        );
    }
    return null;
}

import { formatDate } from "../../../../../helpers/dateHelper"
import ShiftResumeGroup from "./check-box"
import Detail from "./detail"
import Title from "./title"
import Transport from "./transport"

type ResumeShiftProps = {
    groups: Record<string, {label: string, value: string, totalItems?: number}[]>,
    start?: Date,
    end?: Date,
    stop?: {label: string, value: string},
    direction: "start" | "end", 
    period?: [Date, Date],
    totalPassenger?: number,
    units?: Record<string, {label: string, value: string, stop?: {label: string, value: string}}[]>
}
export const ResumeShift = (props: ResumeShiftProps) => {
    const { other } = props.groups;
    const start = props.period?.length ? formatDate(props.period[0], "Africa/casablanca", 'DD-MM-YYYY à HH:mm') : "...";
    const end = props.period?.length && props.period?.length > 0 ? formatDate(props?.period[1], "Africa/casablanca", 'DD-MM-YYYY à HH:mm') : "...";

    return (
        <>
            <div className="d-flex flex-column justify-content-between">
                <Title text="Groupe" />
                {
                    other.map((group) => {
                        return (<ShiftResumeGroup text={group.label} />)
                    })
                }
                <Detail text="Nombre de passager" description={`${props.totalPassenger ?? 0}`} />
            </div>
            <div className="d-flex flex-column justify-content-between mt-4">
                <Title text="Paramétrage du trajet" />
                <Detail text="Date de début" description={start} />
                <Detail text="Date de fin" description={end} />
                <Detail text={props.direction === "start" ? "Départ" : "Arrivé"}  description={props.stop?.label ?? "..."} />
            </div>
        </>
    );
}
import { axiosInstance } from "../../../redux/setupAxios";
import { FilterMaintenanceType, GroupsMaintenance, MaintenanceInList, ManageMaintenanceType } from "../types";

export async function getVehiclesForMaintenance({
    name,
}: {
    name: string;
}): Promise<{label: string, value: string}[]> {
    const url = `/maintenance/units?name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function getTypeForMaintenance({
    name,
}: {
    name: string;
}): Promise<{label: string, value: string}[]> {
    const url = `/maintenance/type?name=${name}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return [];
    }
}

export async function getMaintenanceVehicle({
    _id,
}: {
    _id: string;
}): Promise<{km: number, engineTime: number}> {
    const url = `/maintenance/vehicle/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        return {km: 0, engineTime: 0};
    }
}

export async function saveMaintenance(payload: any): Promise<any> {
    const url = `/maintenance`;
    try {
        const  res = !payload._id
            ? await axiosInstance.post<any>(url, payload) : await axiosInstance.patch<any>(url, payload)
        return res.data;
    } catch (e: any) {
        return {km: 0, engineTime: 0};
    }
}


export async function getMaintenanceGroups(): Promise<GroupsMaintenance[]> {
    const url = `/maintenance/filters`;
    const res = await axiosInstance.get<any>(url);
    return res.data;
    
}

export async function getMaintenanceList(filter:FilterMaintenanceType): Promise<{
    list: MaintenanceInList[];
    total: number;
}> {
    let url = `/maintenance/group/${filter.groupId}?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
   
    const res = await axiosInstance.get<any>(url);
    return res.data;
    
}

export async function deleteMaintenances({
    _ids,
    groupId
}: {
    _ids: string[];
    groupId: string;
}): Promise<{ data: { deletedCount: number } }> {
    let params = `/group/${groupId}?`;
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/maintenance${params}`);
}

export async function getMaintenance(props: {groupId: string, _id: string}): Promise<Omit<ManageMaintenanceType, 'alert'> & {
    alert?: {
        emails: string[],
        alertKm?: number,
        alertDay?: number,
        alertEngineTime?: number
    },

}> {
    const url = `/maintenance/group/${props.groupId}/${props._id}`;
    const res = await axiosInstance.get<any>(url);
    return res.data;
    
}





import { axiosInstance } from "../../../redux/setupAxios";
import { ManageShareType, FilterShareType } from "../types";

export async function saveShare(share: ManageShareType): Promise<any> {
    const url = `/share`;
    try {
        const res = await axiosInstance.post<ManageShareType>(url, share);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateShare(
    share: ManageShareType
): Promise<ManageShareType> {
    const url = `/share`;
    try {
        const res = await axiosInstance.patch<any>(url, share);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getShares(filter: FilterShareType): Promise<any> {
    let url = `/share?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getShare({ _id }: { _id: string }): Promise<any> {
    const url = `/share/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteShares({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/share${params}`);
}

export async function fetchVehiclesByName(
    value: string
): Promise<{ label: string; value: string }> {
    let params = '';
    if (value) {
        params = `?name=${value}`;
    }

    const result = await axiosInstance.get<{ label: string; value: string }>(
        `share/vehicles${params}`
    );
    return result.data;
}
import React, { useContext, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classNames from 'classnames';
import { French } from "flatpickr/dist/l10n/fr.js";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import { I18nContext } from '../../context/i18n-context';
import { SupportedLang } from '../../context/types';

const getLanguage = (lang: SupportedLang) => {
    switch(lang){
        case 'fr':
            return French;
        case 'ar':
            return Arabic;
    }
}

type InputFlatPickrUiType = any & {label?: string}
export const InputFlatPickrUi = (props: InputFlatPickrUiType) => {
    const { lang } = useContext(I18nContext);
    const {label, error, options, className, ...propsInput} = props; 
    const [customOptions, setCustomOptions] = useState(options);

    const inputFlatPickrUiClass = classNames({
        [`is-invalid`]: !!error,
        [`${className}`]: !!className,
      });
      
      
      useEffect(()=> {
        if(options) {
            if(options.locale) {
                setCustomOptions({
                    ...options,
                    locale: {
                        ...options.locale,
                        ...getLanguage(lang)
                    }
                });
            } else {
                setCustomOptions({...options, locale: {...getLanguage(lang)} });
            }

        }
      }, [lang, options.locale, lang, options.maxDate])

    return (
        <React.Fragment>
                { label && <Label for={propsInput.id}> {label} </Label> }
                <Flatpickr 
                    className={inputFlatPickrUiClass}
                    options={customOptions}
                    {...propsInput}
                />
        </React.Fragment>
    );
};
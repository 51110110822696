import { formatMessage } from "@formatjs/intl";
import { useState } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Row, Col, CardBody, CardHeader, FormGroup } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { InputCreatableSelect } from "../../../Components/Form/InputCreatableSelect";
import { InputText } from "../../../Components/Form/InputText";
import { numberValidator, validateEmail } from "../../../helpers/validationHelper";
import { useAppSelector } from "../../../redux/hooks";
import { SetMaintenanceNotificationEmail } from "../_redux/maintenance.actions";

type ManageMaintenancePageType = {
    register: UseFormRegister<any>,
    errors: {[x: string]: any},
    watch: UseFormWatch<FieldValues>
 }
export const Notification = ({register, watch ,errors}: ManageMaintenancePageType) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { manage } = useAppSelector((state) => state.MaintenanceReducer);

    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (inValue: string): void => {
        setInputValue(inValue);
    };

    const handleKeyDown = (event: any): void => {
        if (!inputValue) return;
        // eslint-disable-next-line default-case
        switch (event.key) {
            case 'Enter':
            case 'Tab': {
                const validEmail = validateEmail(inputValue);
                if (validEmail) {
                    handleChange(
                        [
                            ...(manage.alert?.emails ?? []),
                            { label: inputValue, value: inputValue },
                        ],
                        inputValue
                    );
                }
                setInputValue('');
                event.preventDefault();
            }
        }
    };

    const handleChange = (el: any, lastValue?: string): void => {
        if (manage.alert?.emails && lastValue) {
            const data = manage.alert.emails.find((e) => {
                return e.value === lastValue;
            });
            if (data) {
                return;
            }
        }
        dispatch(SetMaintenanceNotificationEmail(el));
    };


    const canAlert =  manage.alert && manage.alert.emails.length > 0;

    const kmValidation = numberValidator(manage.km.active && !!canAlert, 0, 9999999999);
    const hourValidation = numberValidator(manage.engineTime.active && !!canAlert, 0, 9999999999);
    const dayValidation = numberValidator(manage.date.active && !!canAlert, 0, 9999999999);

    return (
        <CardUi>
            <CardHeader>
                <h5 className="card-title mb-0">
                    <FormattedMessage id="MAINTENANCE.NOTIFICATION"/>
                </h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            
                            {
                                <InputCreatableSelect 
                                    components={{ DropdownIndicator: null }}
                                    isClearable
                                    isMulti
                                    label={intl.formatMessage({ id: 'ALARMS.NOTIFICATION_EMAIL'})}
                                    inputValue={inputValue}
                                    value={manage.alert?.emails ?? []}
                                    menuIsOpen={false}
                                    onChange={handleChange}
                                    onInputChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            }
                        </FormGroup>
                    </Col>
                    
                    {
                        manage.km.active && canAlert && <Col md={4}>
                            <FormGroup>
                                {
                                    <InputText
                                        type="number"
                                        label={intl.formatMessage({id: 'MAINTENANCE.ALERT_KM'})}
                                        id={'alertKm'}
                                        name={'alertKm'}
                                        placeholder={intl.formatMessage({id: 'MAINTENANCE.ALERT_KM'})}
                                        register={register('alertKm', kmValidation)}
                                        error={errors['alertKm']}
                                        disabled={!manage.km.active || !canAlert}
                                    />
                                }
                            </FormGroup>
                        </Col>
                    }
                    
                    {
                       manage.date.active && canAlert && <Col md={4}>
                        <FormGroup>
                            {
                                <InputText
                                    type="number"
                                    label={intl.formatMessage({id: 'MAINTENANCE.ALERT_DATE'})}
                                    id={'alertDay'}
                                    name={'alertDay'}
                                    placeholder={intl.formatMessage({id: 'MAINTENANCE.ALERT_DATE'})}
                                    register={register('alertDay', dayValidation)}
                                    error={errors['alertDay']}
                                    disabled={!manage.date.active || !canAlert}
                                />
                            }
                        </FormGroup>
                    </Col>
                    }
                    
                    {
                       manage.engineTime.active  && canAlert && 
                       <Col md={4}>
                            <FormGroup>
                                {
                                    <InputText
                                        type="number"
                                        label={intl.formatMessage({id: 'MAINTENANCE.ALERT_HOURS'})}
                                        id={'alertEngineTime'}
                                        name={'alertEngineTime'}
                                        placeholder={intl.formatMessage({id: 'MAINTENANCE.ALERT_HOURS'})}
                                        register={register('alertEngineTime', hourValidation)}
                                        error={errors['alertEngineTime']}
                                        disabled={!manage.engineTime.active || !canAlert}
                                    />
                                }
                            </FormGroup>
                        </Col>
                    }
                </Row>
            </CardBody>
        </CardUi>
    );
}
import {
    FilterBillingType,
    GetBillingsResponse,
    ManageBillingType,
    NominationBillingType
} from '../types';

export const TOOGLE_POPUP = '[BILLING] TOOGLE_POPUP';
export type TooglePoupType = {
    type: typeof TOOGLE_POPUP;
    payload: boolean;
};
export function TooglePoup(payload: boolean): TooglePoupType {
    return { type: TOOGLE_POPUP, payload };
}

export const CREATE_BILLING_START = '[BILLING] CREATE_BILLING_START';
export type CreateBillingStartType = {
    type: typeof CREATE_BILLING_START;
    payload: any;
};
export function CreateBillingStart(payload: any): CreateBillingStartType {
    return { type: CREATE_BILLING_START, payload };
}

export const CREATE_BILLING_END = '[BILLING] CREATE_BILLING_END';
export type CreateBillingEndType = {
    type: typeof CREATE_BILLING_END;
    payload: ManageBillingType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateBillingEnd(
    payload: ManageBillingType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateBillingEndType {
    return { type: CREATE_BILLING_END, payload };
}

export const ERROR = '[BILLING] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorBillings(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_BILLINGS_START = '[BILLING] GET_BILLINGS_START';
export type GetBillingsStartType = {
    type: typeof GET_BILLINGS_START;
    payload: FilterBillingType;
};
export function GetBillingsStart(
    payload: FilterBillingType
): GetBillingsStartType {
    return { type: GET_BILLINGS_START, payload };
}

export const GET_BILLINGS_END = '[BILLING] GET_BILLINGS_END';
export type GetBillingsEndType = {
    type: typeof GET_BILLINGS_END;
    payload: { result: GetBillingsResponse; filter: FilterBillingType };
};
export function GetBillingsEnd(payload: {
    result: GetBillingsResponse;
    filter: FilterBillingType;
}): GetBillingsEndType {
    return { type: GET_BILLINGS_END, payload };
}

export const SET_SELECTED_BILLINGS = '[BILLING] SET_SELECTED_BILLINGS';
export type SetSelectedBillingsType = {
    type: typeof SET_SELECTED_BILLINGS;
    payload: string[];
};
export function SetSelectedBillings(
    payload: string[]
): SetSelectedBillingsType {
    return { type: SET_SELECTED_BILLINGS, payload };
}

export const DELETE_BILLINGS_START = '[BILLING] DELETE_BILLINGS_START';
export type DeleteBillingsStartType = {
    type: typeof DELETE_BILLINGS_START;
    payload: string[];
};
export function DeleteBillingsStart(
    payload: string[]
): DeleteBillingsStartType {
    return { type: DELETE_BILLINGS_START, payload };
}

export const DELETE_BILLINGS_END = '[BILLING] DELETE_BILLINGS_END';
export type DeleteBillingsEndType = {
    type: typeof DELETE_BILLINGS_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteBillingsEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteBillingsEndType {
    return { type: DELETE_BILLINGS_END, payload };
}

export const CLEAR_BILLING_CREATION = '[BILLING] CLEAR_BILLING_CREATION';
export type ClearBillingCreationActionType = {
    type: typeof CLEAR_BILLING_CREATION;
};
export function ClearBillingCreation(): ClearBillingCreationActionType {
    return { type: CLEAR_BILLING_CREATION };
}

export const GET_BILLING_UPDATE_START = '[DRVIER] GET_BILLING_UPDATE_START';
export type GetBillingUpdateStartType = {
    type: typeof GET_BILLING_UPDATE_START;
    payload: { _id: string };
};
export function GetBillingUpdateStart(payload: {
    _id: string;
}): GetBillingUpdateStartType {
    return { type: GET_BILLING_UPDATE_START, payload };
}

export const GET_BILLING_UPDATE_END = '[BILLING] GET_BILLING_UPDATE_END';
export type GetBillingUpdateEndType = {
    type: typeof GET_BILLING_UPDATE_END;
    payload: { result: ManageBillingType };
};
export function GetBillingUpdateEnd(payload: {
    result: ManageBillingType;
}): GetBillingUpdateEndType {
    return { type: GET_BILLING_UPDATE_END, payload };
}

export const SET_NOMINATION_BILLING_TYPE =
    '[BILLING] SET_NOMINATION_DRIVER_TYPE';
export type SetNominationDriverActionType = {
    type: typeof SET_NOMINATION_BILLING_TYPE;
    payload: NominationBillingType;
};
export function SetNominationBillingAction(
    payload: NominationBillingType
): SetNominationDriverActionType {
    return { type: SET_NOMINATION_BILLING_TYPE, payload };
}

export const SET_MODULES_BILLING_TYPE = '[BILLING] SET_MODULES_BILLING_TYPE';
export type SetModuleBillingActionType = {
    type: typeof SET_MODULES_BILLING_TYPE;
    payload: { module: string };
};
export function SetModuleBillingAction(payload: {
    module: string;
}): SetModuleBillingActionType {
    return { type: SET_MODULES_BILLING_TYPE, payload };
}
export type BillingActionType =
    | TooglePoupType
    | CreateBillingStartType
    | CreateBillingEndType
    | ErrorActionType
    | GetBillingsStartType
    | GetBillingsEndType
    | SetSelectedBillingsType
    | DeleteBillingsStartType
    | DeleteBillingsEndType
    | ClearBillingCreationActionType
    | GetBillingUpdateStartType
    | GetBillingUpdateEndType
    | SetNominationDriverActionType
    | SetModuleBillingActionType;

import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Avatar } from '../../Components/basic/avatar/avatar';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiAlarmWarningFill } from 'react-icons/ri';
import { NotificationType } from './hooks/notification/type';
import { IF } from '../../Components/If';
import { Timer } from '../../Components/Timer';

export type NotificationItemDropdownProps = NotificationType & {dataKey: string, onEvent?: (key: string, action: string, data: string) => void};
export const NotificationItemDropdown = (props: NotificationItemDropdownProps) => {
    const onClick = () => { 
        if(props.onEvent) {
            props.onEvent(props.dataKey, 'goTo', props.link);
        }
     };

    return (   
        <DropdownItem className="px-3 py-2" onClick={onClick}>
            <div className="d-flex align-items-start"> 
                <div className="me-2"> 
                    <Avatar color="primary" size="xs" text={props.name} isSoft rounded="rounded-circle" icon={RiAlarmWarningFill}/>
                </div> 
                <div className="flex-grow-1 d-flex align-items-center justify-content-between"> 
                    <div className="flex-grow-1"> 
                        <p className="mb-0 d-flex align-items-center justify-content-between" style={{fontWeight: !props.isReaded ? 600 : 'normal'}}>
                            <Link className="text-whitespace" to={props.link}>{ props.name }</Link>
                            <IF condition={!props.isReaded}>
                                <span className="p-1 bg-info border border-light rounded-circle">
                                    <span className="visually-hidden">New alerts</span>
                                </span>
                            </IF>

                        </p>
                        <IF condition={!!props.description}>
                            <span className="text-whitespace text-muted fw-normal fs-11">
                                { props.description }
                            </span>
                        </IF>
                         
                        <p className="mb-0 text-muted d-flex align-items-center" style={{fontWeight: 600}}>
                            <AiOutlineClockCircle className='fs-11'/>
                            <span className="ms-1 text-whitespace fs-11">
                                <Timer date={props?.date ?? '-'} lang={"fr"}/>
                            </span> 
                        </p>
                    </div>

                </div>
            </div>
        </DropdownItem> 
    );
};
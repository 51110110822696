import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { FormFeedback, Label } from "reactstrap";
import  { Input, InputProps } from "reactstrap";

type InputTextType =  Omit<InputProps, 'valid'|'invalid'|'type'> & {
    register: UseFormRegisterReturn, 
    label?: string, 
    autocomplete?:boolean,
    type: 'text'|'password'|'time'|'textarea' | 'number'
};

export const InputText = (props: InputTextType) => {
    const {hidden, autcomplete, register, label, error, ...propsInput} = props; 
    const {ref, ...inputRegister} = register;
    return (
        <React.Fragment>
            { propsInput.id && label && <Label hidden={hidden} for={propsInput.id}> {label} </Label> }
            <Input 
                {...inputRegister} 
                {...propsInput}
                hidden={hidden}
                autoComplete={!autcomplete ? 'off': 'on'}
                innerRef={ref} 
                invalid={!!error}
                // valid={!error}
            />
            { error && error.message && <FormFeedback valid={false}>{error.message}</FormFeedback> }
        </React.Fragment>
    );
};
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DriverMark } from "../../../../Components/advanced/Driver/Driver-eco-mark";
import Emitter from "../../../../helpers/emitter";
import EmitterActions from "../../../../helpers/emitter/emitter.actions";
import { useAxios } from "../../../../common/hooks/axios/useAxios";

export const DriverReviewRate = () => {
    const { key } = useParams();
    const navigate = useNavigate();
    const url = `driver/${key}/review-rate`
    const {data, loading, execute} = useAxios<{score: number | null, total: number | null}>(
        false, 
        url, 
        { method: 'GET'}
    );

    const fetchData = () => execute(url, {method: 'GET'});
    useEffect( () => {
        Emitter.on(EmitterActions.REFRESH_DRIVER_REVIEW_RATE, fetchData);
        return () => {
            Emitter.off(EmitterActions.REFRESH_DRIVER_REVIEW_RATE, fetchData);
        };
    }, []);

    return (
        <DriverMark
            className="py-3 px-3 border-bottom"
            text={"Note des avis"} 
            description={`(${data?.total} avis)`} 
            mark={data?.score} 
            showMore={()=> navigate('?reviews=1')}
            loading={loading}
            allowColor
        />             
    );
}
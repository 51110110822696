import classNames from "classnames";
import { CardUi } from "../../basic/Card";
import { ButtonUi } from "../../basic/ButtonUi";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { IF } from "../../If";
import { LoaderUi } from "../../basic/LoaderUi";

type ListMapComponenentProps = {
    isVisible: boolean, 
    loading?: boolean, 
    children: any,
    toggleVisibility?: () => void;
}
export const ListMapComponent = (props: ListMapComponenentProps) => {

    const ListMapClassname = classNames({
        [`d-none`]: !props.isVisible,
        'list-driver': true
    });

    return (
        <>
            <IF condition={!!props.toggleVisibility}>
                <ButtonUi 
                    onClick={props.toggleVisibility}
                    className={!props.isVisible ? '' : 'd-none'} color={"primary"} Icon={AiOutlineUnorderedList}
                />
            </IF>
            
            <CardUi className={ListMapClassname}>
                <LoaderUi loading={!!props.loading} />
                {props.children}
            </CardUi>
        </>
       
    );
}

export default ListMapComponent;
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { RootState } from '../../../../../redux/store';
import { Error } from '../../../Objects/_redux/unit-list.actions';
import { fetchVisibleSensorsUnits } from './unit.service';
import { InitVehicleType, INIT_VEHICLE, RetrieveUnitSensorsEnd } from './vehicleActions';


export const FetchVisibleSensorEpic = (
    action$: ActionsObservable<InitVehicleType>,
    state$: { value: RootState }
): Observable<any> =>
    action$.pipe(
        ofType(INIT_VEHICLE),
        switchMap((action: InitVehicleType) => {
            return from(fetchVisibleSensorsUnits(action.payload)).pipe(
                switchMap((res) => {
                    return of(RetrieveUnitSensorsEnd(res));
                }),
                catchError((err) => {
                    return of(Error('COMMON.INTERN_ERROR'));
                })
            );
        }),
        catchError((err) => {
            return of(Error('COMMON.INTERN_ERROR'));
        })
    );

import { useEffect } from "react";
import { useAxios } from "../useAxios";
import { Passenger } from "./type";

export type GeoJsonDto = {
    type: string;
    coordinates: [number, number];
}

type UseFetchPassengerResponse = { 
  passenger: Passenger | null;
  fetchPassengerLoading: boolean;
  error?: string;
  fetchPassenger: () => void;
  cancel: () => void;
}



type UseFetchReportItemsProps = {
  manual?: boolean,
  key?: string
}

export function useFetchPassenger(props: UseFetchReportItemsProps): UseFetchPassengerResponse {
  const URL = `passengers/${props.key}`;
  const { execute, data, cancel, error, loading, init } = useAxios<Passenger>(props.manual);

  useEffect(() => {
    if(!props.manual && props.key) {
      fetchPassenger();
    }
  }, []);

  const fetchPassenger = () => {
    execute(URL, { method: "GET"});
  };

  return { 
    passenger: data,
    fetchPassengerLoading: loading, 
    error,
    fetchPassenger,
    cancel, 
  };
}

import React, { createContext, useEffect, useState } from 'react';
import { changeHTMLAttribute, DIR, LANG } from '../Layouts/helpers';
import { I18nContextPropsType, I18nContextType, SupportedLang } from './types';

export const I18nContext = createContext<I18nContextType>({
    lang: 'fr',
    direction: 'ltr',
});

function I18nContextprovider({ children }: I18nContextPropsType): JSX.Element {
    const [lang, setLang] = useState<SupportedLang>('en');
    const [direction, setDirection] = useState('ltr');


    function initLayoutLanguage() {
        const layoutMode = (localStorage.getItem(LANG) || 'fr') as SupportedLang;
        changeLanguage(layoutMode);
    }

    const changeDirection = React.useCallback(
        (language: SupportedLang) => {
            let directionTmp = direction;
            if (language === 'ar') {
                directionTmp = 'rtl';
            } else {
                directionTmp = 'ltr';
            }
            setDirection(directionTmp);
            changeHTMLAttribute(DIR, directionTmp);
            changeHTMLAttribute(LANG, language);
            document
                .getElementsByTagName('body')[0]
                .setAttribute('class', directionTmp);
        },
        [direction]
    );

    const changeLanguage = React.useCallback(
        (language: SupportedLang) => {
            setLang(language);
            changeDirection(language);
        },
        [changeDirection, setLang]
    );

    const value = React.useMemo(
        () => ({ lang, changeLanguage, direction }),
        [lang, changeLanguage, direction]
    );

    useEffect(() => {
        initLayoutLanguage();
    }, []);

    return (
        <I18nContext.Provider value={value}>{children}</I18nContext.Provider>
    );
}

export default I18nContextprovider;

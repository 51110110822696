import { Badge } from "reactstrap"
import { ColorType } from "../types"
import classNames from 'classnames';


type badgeUiType = {
    children: JSX.Element | string | number | any;
    className?: string;
    color: ColorType
    outline?: boolean;
    bordred?: boolean;
    label?: boolean;
    light?: boolean;
    pill?: boolean;
    style?: any;
}

export const BadgeUi = ({color, outline, light, pill, label, bordred, className, children, ...other}: badgeUiType) => {
    const badgeClassName = classNames({
        [`outline`]: outline,
        [`light`]: light,
        [`badge-border`]: bordred,
        [`badge-label`]: label && !bordred && !light,
        [`${className}`]: !!className,
      });
    return <Badge color={color} className={badgeClassName} pill={pill} {...other}>{children}</Badge>
}



import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Offcanvas, OffcanvasHeader, Container, OffcanvasBody } from "reactstrap";
import { useAppSelector } from "../../redux/hooks";
import { AssignVehicles } from "./VehiclesAssign";
import { SelectObjectAction, ToogleAfectToAction } from "../Report/async/redux/report.actions";


export const AssignVehiclesReport = () => {
    const [selected, setSelected] = useState<string[] | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const intl = useIntl();

    const { manage } = useAppSelector((state) => state.reportReducer);
    const { user } = useAppSelector((state) => state.authReducer);



    const fetchSelected = async (): Promise<void> => {
        setLoading(true);
        if (manage.affectTo) {
            setSelected(manage.affectTo.list);
        } else {
            setSelected(null);
        }
        setLoading(false);
    };


    useEffect(() => { fetchSelected(); }, [manage.affectTo]);

    return (
        <Offcanvas  className="assignVehicles" direction="end" isOpen={manage.affectTo.visible} toggle={()=> dispatch(ToogleAfectToAction())}>
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark border-none">
                <span className="m-0 me-2 text-white"><FormattedMessage id={'COMMON.ASSIGN'}/></span>
            </OffcanvasHeader>
            <OffcanvasBody>
                <Container fluid>
                    {
                        manage.affectTo && selected && (
                            <AssignVehicles
                                selectedVehicles={selected}
                                onSubmit={(vehicles: string[]) =>
                                    dispatch(SelectObjectAction(vehicles))
                                }
                                onCancel={() => 
                                    dispatch(ToogleAfectToAction())
                                }
                            />
                        )
                    }
                </Container>
            </OffcanvasBody>
        </Offcanvas>          
    );
}
import Tracker from "./tracker"
import { Container } from "reactstrap"

export const RoutesDetailsPage = () => {


    return(
        <div id="road-page" className="page-content">
            <Container fluid>
                <Tracker />
            </Container>
        </div>
    )
}


export const getMinutesSteps = (end: Date, start: Date) => {
    const hours = end.getHours() - start.getHours();
    switch(hours) {
        case 0:
        case 1:
        case 2:
        case 3:
            return 12;
        case 4:
        case 5:
        case 6:
            return 6;
        default: 
            return 2;
    }
}
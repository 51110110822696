import { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { FuelBar } from "../chart/fuel-bar";
import { FuelBarResponse, getFuelStats } from "../dashboard.service";

export const FuelStatus = () => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<FuelBarResponse[]>([]);

    useEffect(()=> {
        setLoading(true);
        getFuelStats()
            .then((response)=> {
                setData(response);
            })
            .catch((response)=> {
                setData(response);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);


    return (
        <CardUi>
            <LoaderUi loading={loading} />
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Top fuel consomation (Litres)</h6>
                </div>
                <div>
                    <FuelBar data={data}/>
                </div>
            </CardBody>
        </CardUi>               
    );
}
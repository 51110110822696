import React, { useCallback, useEffect, useState } from 'react'
import { ImSpinner10 } from 'react-icons/im';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Input, Col, CardBody } from 'reactstrap';
import { ButtonUi } from '../../../../Components/basic/ButtonUi';
import { CardUi } from '../../../../Components/basic/Card';
import { TableUi } from '../../../../Components/Table';
import { useAxios } from '../../../../common/hooks/axios/useAxios';
import { SetNotificationAction } from '../../../../redux/rootActions';

type FieldsType = {
    key: number;
    name: string | null;
    value: string | null;
    error: boolean;
}
export default function CustomFields() {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const { data, execute, loading } = useAxios<FieldsType[]>(true);
    const { data: customFields, loading: loadingFields } = useAxios(false, `vehicle/${_id}/custom-fields`, {method: 'GET'});
    const [fields, setFields] = useState<FieldsType[]>(data ?? []);
    
    useEffect(()=> {
        setFields(Array.isArray(customFields) ? customFields : []);
    }, [customFields]);

    useEffect(()=> {
        if(loading === false && data) {
            dispatch(SetNotificationAction({
                title: "Champs personnalisés modifiés avec succées",
                "message": 'Modification',
                timeToClose: 3000,
                type: 'success'
            }))
        }
    }, [loading]);

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            render: (value: string, data: any) => <Input 
                
                autoComplete={'off'} 
                type="text" 
                defaultValue={value}
                invalid={!!data.error}
                onChange={(e)=> changeValue(e, {key: data.key, value: data.value, name: e.target.value})}
            />
        },
        {
            title: 'Valeur',
            dataIndex: 'value',
            key: 'value',
            render: (value: string, data: any) => <Input 
                
                autoComplete={'off'} 
                type="text" 
                invalid={!!data.error && !value}
                defaultValue={value}
                onChange={(e)=> changeValue(e, {key: data.key, name: data.name, value: e.target.value})}
            />
        },
        {
            title: 'Action',
            dataIndex: 'key',
            key: 'key',
            render: (key: number) => <ButtonUi color="danger" size="sm" ghost onClick={(e) => deleteLine(e, key)}>Supprimer</ButtonUi>
        },

    ];

    const addLine = (e: any) => {
        e.target.blur();
        const customFields = [...fields];
        customFields.push({ key: fields.length + 1, name: null, value: null, error: true });

        setFields(customFields);
    }

    const deleteLine = (e: any, index: number) => {
        e.target.blur();
        setFields(fields.filter(item => item.key !== index));
    }

    const changeValue = (e: any, data: {key: number, name: string, value: string}) => {
        const customFields = [...fields];
        const index = customFields.findIndex(item => item.key === data.key);
        if(index >= 0) {
            customFields[index] = {
                ...customFields[index],
                ...data,
                error: !(data.name !== null && data.value !== null && ((index === 0 ) || (data.value !== null)))
            };
        } 

        setFields(customFields);
    }

    const actionDisabled = useCallback(()=> !!fields.find(field => !field.name || !field.value), [fields]);
    const submit = () => execute('vehicle/custom-fields', {method: 'PATCH', data: {_id, custom: fields}});
    
    return (
        <React.Fragment> 
            
            <Col>
                <div className="hstack gap-2 justify-content-end mb-2">
                    <ButtonUi color="info" size="sm" ghost onClick={(e) => addLine(e)}>Ajouter une ligne</ButtonUi>
                    </div>
                </Col>
            
            <Col sm={12}>
                <CardUi noShadow>
                    <CardBody className="p-0">
                        <TableUi 
                            columns={columns}
                            data={fields}
                            minusHeight={400}
                        />
                    </CardBody>
                </CardUi>
            </Col>
            <Col>
                <div className="hstack gap-2 justify-content-end">
                    <ButtonUi color={"primary"} type="submit" disabled={actionDisabled()} onClick={submit} loading={loading || loadingFields}>
                        {
                            loading || loadingFields ? <ImSpinner10 size={14}/> : <FormattedMessage id="COMMON.UPDATE"/>
                        }
                    </ButtonUi> 
                </div>
            </Col>
        </React.Fragment>    
    );
}

import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetRedirectAction, SetNotificationAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import {
    AssignVehicleUserEndAction,
    ASSIGN_VEHICLE_USER_START,
    CreateUserEnd,
    CreateUserStartType,
    CREATE_USER_START,
    DeleteUsersEnd,
    DeleteUsersStartType,
    DELETE_USERS_START,
    ErrorUsers,
    GetUserRoleEnd,
    GetUserRoleStartType,
    GetUsersEnd,
    GetUsersStartType,
    GetUserUpdateEnd,
    GetUserUpdateStartType,
    GET_USERS_START,
    GET_USER_ROLE_START,
    GET_USER_UPDATE_START,
    SetVehicleAssignStartActionType,
    UserActionType
} from './user.actions';
import {
    assignVehiclesToUser,
    deleteUsers,
    getUser,
    getUserRoles,
    getUsers,
    saveUser,
    updateUser
} from './user.service';

export const CreateUserEpic = (
    action$: ActionsObservable<CreateUserStartType>,
    state$: { value: RootState }
): Observable<UserActionType | any> =>
    action$.pipe(
        ofType(CREATE_USER_START),
        switchMap((action: CreateUserStartType) => {
            const { manage } = state$.value.userReducer;
            const { email, mobile, type, ...payload } = manage; 
            return from(
                manage._id
                    ? updateUser({ ...payload, ...action.payload, type  })
                    : saveUser({ ...payload,  ...action.payload, type})
            ).pipe(
                mergeMap((result) => {
                    const {password, confPassword, ...otherProps} = action.payload;
                    const newManage = {
                        ...state$.value.userReducer.manage,
                        ...otherProps,
                        ...result,
                        ...{
                            _id: payload._id ? payload._id : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                        },
                    };
                    return of(
                        CreateUserEnd(newManage),
                        SetRedirectAction('/users'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                      );
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

export const GetUsersEpic = (
    action$: ActionsObservable<GetUsersStartType>,
    state$: { value: RootState }
): Observable<UserActionType> =>
    action$.pipe(
        ofType(GET_USERS_START),
        switchMap((action: GetUsersStartType) => {
            return from(getUsers(action.payload)).pipe(
                map((result) => {
                    return GetUsersEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

export const GetUserEpic = (
    action$: ActionsObservable<GetUserUpdateStartType>,
    state$: { value: RootState }
): Observable<UserActionType> =>
    action$.pipe(
        ofType(GET_USER_UPDATE_START),
        switchMap((action: GetUserUpdateStartType) => {
            return from(getUser(action.payload)).pipe(
                map((result) => {
                    return GetUserUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

export const DeleteUsersEpic = (
    action$: ActionsObservable<DeleteUsersStartType>,
    state$: { value: RootState }
): Observable<UserActionType | any> =>
    action$.pipe(
        ofType(DELETE_USERS_START),
        switchMap((action: DeleteUsersStartType) => {
            return from(deleteUsers({ _ids: action.payload })).pipe(
                mergeMap((result) => {
                    return of(
                        DeleteUsersEnd({
                            ...result.data,
                            _ids: action.payload,
                        }), 
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                    );
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

export const GetUserRoles = (
    action$: ActionsObservable<GetUserRoleStartType>,
    state$: { value: RootState }
): Observable<UserActionType> =>
    action$.pipe(
        ofType(GET_USER_ROLE_START),
        switchMap(() => {
            return from(getUserRoles()).pipe(
                map((result) => {
                    return GetUserRoleEnd(result);
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

export const AssignVehiclesToUserEpic = (
    action$: ActionsObservable<SetVehicleAssignStartActionType>,
    state$: { value: RootState }
): Observable<UserActionType> =>
    action$.pipe(
        ofType(ASSIGN_VEHICLE_USER_START),
        switchMap((action: SetVehicleAssignStartActionType) => {
            const _id = state$.value.userReducer.affectTo?._id ?? '';
            return from(assignVehiclesToUser(_id, action.payload)).pipe(
                map((result) => {
                    return AssignVehicleUserEndAction({
                        ...result,
                        _id,
                    });
                }),
                catchError((err) => {
                    return of(ErrorUsers(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorUsers(err.msg));
        })
    );

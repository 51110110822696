import { ManageUserType, FilterUserType, GetUsersResponse, UserRoleItem, NominationUserType } from "../types";

export const TOOGLE_POPUP = '[USER] TOOGLE_POPUP';
export type TooglePoupType = {
    type: typeof TOOGLE_POPUP;
    payload: boolean;
};
export function TooglePoup(payload: boolean): TooglePoupType {
    return { type: TOOGLE_POPUP, payload };
}

export const CREATE_USER_START = '[USER] CREATE_USER_START';
export type CreateUserStartType = {
    type: typeof CREATE_USER_START;
    payload: any;
};
export function CreateUserStart(payload: any): CreateUserStartType {
    return { type: CREATE_USER_START, payload };
}

export const CREATE_USER_END = '[USER] CREATE_USER_END';
export type CreateUserEndType = {
    type: typeof CREATE_USER_END;
    payload: ManageUserType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateUserEnd(
    payload: ManageUserType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateUserEndType {
    return { type: CREATE_USER_END, payload };
}

export const ERROR = '[USER] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorUsers(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const GET_USERS_START = '[USER] GET_USERS_START';
export type GetUsersStartType = {
    type: typeof GET_USERS_START;
    payload: FilterUserType;
};
export function GetUsersStart(payload: FilterUserType): GetUsersStartType {
    return { type: GET_USERS_START, payload };
}

export const GET_USERS_END = '[USER] GET_USERS_END';
export type GetUsersEndType = {
    type: typeof GET_USERS_END;
    payload: { result: GetUsersResponse; filter: FilterUserType };
};
export function GetUsersEnd(payload: {
    result: GetUsersResponse;
    filter: FilterUserType;
}): GetUsersEndType {
    return { type: GET_USERS_END, payload };
}

export const SET_SELECTED_USERS = '[USER] SET_SELECTED_USERS';
export type SetSelectedUsersType = {
    type: typeof SET_SELECTED_USERS;
    payload: string[];
};
export function SetSelectedUsers(payload: string[]): SetSelectedUsersType {
    return { type: SET_SELECTED_USERS, payload };
}

export const DELETE_USERS_START = '[USER] DELETE_USERS_START';
export type DeleteUsersStartType = {
    type: typeof DELETE_USERS_START;
    payload: string[];
};
export function DeleteUsersStart(payload: string[]): DeleteUsersStartType {
    return { type: DELETE_USERS_START, payload };
}

export const DELETE_USERS_END = '[USER] DELETE_USERS_END';
export type DeleteUsersEndType = {
    type: typeof DELETE_USERS_END;
    payload: { deletedCount: number; _ids: string[] };
};
export function DeleteUsersEnd(payload: {
    deletedCount: number;
    _ids: string[];
}): DeleteUsersEndType {
    return { type: DELETE_USERS_END, payload };
}

export const CLEAR_USER_CREATION = '[USER] CLEAR_USER_CREATION';
export type ClearUserCreationActionType = {
    type: typeof CLEAR_USER_CREATION;
};
export function ClearUserCreation(): ClearUserCreationActionType {
    return { type: CLEAR_USER_CREATION };
}

export const GET_USER_UPDATE_START = '[USER] GET_USER_UPDATE_START';
export type GetUserUpdateStartType = {
    type: typeof GET_USER_UPDATE_START;
    payload: { _id: string };
};
export function GetUserUpdateStart(payload: {
    _id: string;
}): GetUserUpdateStartType {
    return { type: GET_USER_UPDATE_START, payload };
}

export const GET_USER_UPDATE_END = '[USER] GET_USER_UPDATE_END';
export type GetUserUpdateEndType = {
    type: typeof GET_USER_UPDATE_END;
    payload: { result: ManageUserType };
};
export function GetUserUpdateEnd(payload: {
    result: ManageUserType;
}): GetUserUpdateEndType {
    return { type: GET_USER_UPDATE_END, payload };
}

export const GET_USER_ROLE_START = '[USER] GET_USER_ROLE_START';
export type GetUserRoleStartType = {
    type: typeof GET_USER_ROLE_START;
};
export function GetUserRoleStart(): GetUserRoleStartType {
    return { type: GET_USER_ROLE_START };
}

export const GET_USER_ROLE_END = '[USER] GET_USER_ROLE_END';
export type GetUserRoleEndType = {
    type: typeof GET_USER_ROLE_END;
    payload: any;
};
export function GetUserRoleEnd(payload: any): GetUserRoleEndType {
    return { type: GET_USER_ROLE_END, payload };
}

export const CHANGE_USER_ROLE_ITEM = '[USER] CHANGE_USER_ROLE_ITEM';
export type ChangeUserRoleItemType = {
    type: typeof CHANGE_USER_ROLE_ITEM;
    payload: UserRoleItem;
};
export function ChangeUserRoleItem(
    payload: UserRoleItem
): ChangeUserRoleItemType {
    return { type: CHANGE_USER_ROLE_ITEM, payload };
}

export const CHANGE_USER_TYPE = '[USER] CHANGE_USER_TYPE';
export type ChangeUserTypeType = {
    type: typeof CHANGE_USER_TYPE;
    payload: string;
};
export function ChangeUserType(payload: string): ChangeUserTypeType {
    return { type: CHANGE_USER_TYPE, payload };
}

export const SET_USER_CONTACT_TYPE = '[USER] SET_USER_CONTACT_TYPE';
export type SetUserContactActionType = {
    type: typeof SET_USER_CONTACT_TYPE;
    payload: { mobile?: string; email?: string };
};
export function SetUserContactAction(payload: {
    mobile?: string;
    email?: string;
}): SetUserContactActionType {
    return { type: SET_USER_CONTACT_TYPE, payload };
}

export const SET_NOMINATION_USER_TYPE = '[USER] SET_NOMINATION_USER_TYPE';
export type SetNominationUserActionType = {
    type: typeof SET_NOMINATION_USER_TYPE;
    payload: NominationUserType;
};
export function SetNominationUserAction(
    payload: NominationUserType
): SetNominationUserActionType {
    return { type: SET_NOMINATION_USER_TYPE, payload };
}

export const TOOGLE_AFFECT_TO = '[USER] TOOGLE_AFFECT_TO';
export type ToogleAffectToActionType = {
    type: typeof TOOGLE_AFFECT_TO;
    payload?: { _id: string };
};
export function ToogleAfectToAction(payload?: {
    _id: string;
}): ToogleAffectToActionType {
    return { type: TOOGLE_AFFECT_TO, payload };
}

export const ASSIGN_VEHICLE_USER_START = '[VEHIVLE] ASSIGN_VEHICLE_USER_START';
export type SetVehicleAssignStartActionType = {
    type: typeof ASSIGN_VEHICLE_USER_START;
    payload: string[];
};
export function AssignVehicleUserStartAction(
    payload: string[]
): SetVehicleAssignStartActionType {
    return { type: ASSIGN_VEHICLE_USER_START, payload };
}

export const ASSIGN_VEHICLE_USER_END = '[VEHIVLE] ASSIGN_VEHICLE_USER_END';
export type SetVehicleAssignEndActionType = {
    type: typeof ASSIGN_VEHICLE_USER_END;
    payload: {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount: number;
    };
};
export function AssignVehicleUserEndAction(payload: {
    _id: string;
    updatedBy: string;
    updatedAt: string;
    modifiedCount: number;
}): SetVehicleAssignEndActionType {
    return { type: ASSIGN_VEHICLE_USER_END, payload };
}

export type UserActionType =
    | TooglePoupType
    | CreateUserStartType
    | CreateUserEndType
    | ErrorActionType
    | GetUsersStartType
    | GetUsersEndType
    | SetSelectedUsersType
    | DeleteUsersStartType
    | DeleteUsersEndType
    | ClearUserCreationActionType
    | GetUserUpdateStartType
    | GetUserUpdateEndType
    | GetUserRoleStartType
    | GetUserRoleEndType
    | ChangeUserRoleItemType
    | ChangeUserTypeType
    | SetUserContactActionType
    | SetNominationUserActionType
    | ToogleAffectToActionType
    | SetVehicleAssignStartActionType
    | SetVehicleAssignEndActionType;

import { isUndefined } from "lodash";
import { useContext, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import { ButtonGroup, CardFooter, Col, Row } from "reactstrap";
import { GeofenceComponent } from "../../Components/advanced/Geofence-info";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { EmptyUi } from "../../Components/basic/EmptyUi";
import { Paginator } from "../../Components/basic/Paginator";
import { InputSearch } from "../../Components/Form/InputSearch";
import { LayoutContext } from "../../context/layout-style-context";
import { useAppSelector } from "../../redux/hooks";
import { GeofenceAction, GeofenceType } from "./types";
import { DeleteGeofenceStart, GetGeofencesStart } from "./_redux/geofenceModule.actions";
import Emitter from "../../helpers/emitter";

export const GeofenceList = () => {
    const intl = useIntl();
    const listRef = useRef<any>();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { height } = useContext(LayoutContext);
    const { filter, geofence } = useAppSelector((state) => state.geofenceModuleReducer);
    
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 45,
    });
    const canShowFooter = Math.ceil(geofence.total / filter.limit) > 1;    
    useEffect(() => {
        dispatch(GetGeofencesStart(filter));
    }, []);
    
    const getIndex = () => {
        // if(vehicleActive) {
        //     const index = drivers.list.findIndex(v => v.key === vehicleActive);
        //     if(index !== -1) {
        //         return index;
        //     }
        // }
        return undefined
    }


    
    const onChangeFilter = (select: 'all' | GeofenceType): void => {
        dispatch(GetGeofencesStart({ ...{ ...filter, ...{ skip: 0 } }, ...{ select } }));
    };

    const onSearch = (data: { searchBy: string }): void => {
        dispatch(
            GetGeofencesStart({
                ...{ ...filter, ...{ skip: 0 } },
                ...{ name: data.searchBy },
            })
        );
    };

    const onPaginate = (skip: number, limit: number): void => {
        dispatch(GetGeofencesStart({ ...filter, ...{ skip, limit } }));
    };

    const actionClick = (key: string, action: GeofenceAction) => {
        switch(action) {
            case GeofenceAction.EDIT: {
                navigate(`/geofences/${key}`);
                break;
            }

            case GeofenceAction.DELETE: {
                Emitter.emit(Emitter.event.DELETE_POPUP, {                    
                    textConfirmation: "confirm",
                    title: "Supprimer la zone",
                    description: "Veuillez renseigner le nom proposé pour pouvoir valider la suppression",
                    onDelete: () => dispatch(DeleteGeofenceStart([key]))
                });
                break;
            }
        }
    }

    return (

        <>
            <div className="py-2 px-3 border-bottom">
                <div className="d-flex flex-row">
                    <ButtonUi 
                        size="sm" 
                        color={"info"}
                        onClick={()=> navigate('/geofences/add')}
                    >
                        <FormattedMessage id="DRIVER.NEW"/>
                    </ButtonUi>
                    <div className="ms-1 flex-grow-1">
                        <InputSearch
                            type="text"
                            name={'searchBy'}
                            size="sm"
                            placeholder={intl.formatMessage({ id: 'COMMON.QUICK_SEARCH' })}
                            onSubmit={onSearch}
                        />
                    </div>
                </div>
            </div>
            <div className="py-2 px-3 border-bottom">
                <div className="d-flex justify-content-center">
                    <ButtonGroup size="sm" className="flex-grow-1">
                        <ButtonUi color="primary" outline={!isUndefined(filter.select) && filter.select !== 'all'} onClick={()=>onChangeFilter('all')}>Tous</ButtonUi>
                        <ButtonUi color="primary" outline={filter.select !== GeofenceType.POLYGONE} onClick={()=>onChangeFilter(GeofenceType.POLYGONE)}>Polygon</ButtonUi>
                        <ButtonUi color="primary" outline={filter.select !== GeofenceType.CIRCLE} onClick={()=>onChangeFilter(GeofenceType.CIRCLE)}>Cércle</ButtonUi>
                        <ButtonUi color="primary" outline={filter.select !== GeofenceType.POINT_OF_INTEREST} onClick={()=>onChangeFilter(GeofenceType.POINT_OF_INTEREST)}>POI</ButtonUi>
                    </ButtonGroup>
                </div>
            </div>
                
            <div style={{height: height - (canShowFooter ? 330 : 255)}}>
                <AutoSizer>
                    {
                        ({ height, width }) => {
                            return(
                                geofence.list.length ?
                                <List
                                    ref={listRef}
                                    width={width}
                                    height={height > 0 ? height : 0}
                                    rowCount={ geofence.list.length }
                                    deferredMeasurementCache={cache}
                                    rowHeight={cache.rowHeight}
                                    rowRenderer={rowRenderer}
                                    scrollToIndex={getIndex()}
                                />
                                :
                                <EmptyUi msg="Aucune donnée"/>
                            )
                        }     
                    } 
                </AutoSizer> 
            </div> 

            {
                canShowFooter ? (
                    <CardFooter>
                    <Row>
                        <Col>
                            <Paginator
                                total={geofence.total}
                                skip={filter.skip}
                                limit={filter.limit}
                                onPaginate={onPaginate}
                            />
                        </Col>
                    </Row>
                </CardFooter>
                ) : <></>
            }
        </>

           
    );
    
    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                    <GeofenceComponent geofence={geofence.list[index]} onClick={actionClick}/>
                </div>
            )}
          </CellMeasurer>
        );
    }
}
import classNames from "classnames";
import { ColorType } from "../../../../../Components/types";

const Detail = ({
  className,
  text,
  description,
  hidden,
  hiddenColor,
}: {
  className?: string,
  text: string;
  description: string;
  hidden?: string;
  hiddenColor?: ColorType | 'muted';
}) => {
  const detailClassName = classNames('d-flex align-items-center justify-content-between mt-2', {
    [className ?? ''] : !!className, 
  });
  
  const hiddenClassName = classNames('ms-1', {
    [`color-${hiddenColor}` ?? ''] : !!hiddenColor && hiddenColor !== 'muted',
    'text-muted': hiddenColor === 'muted',
  })
  return (
    <div className={detailClassName}>
      <p className="mb-0">{text} :</p>
      <p className="mb-0" style={{fontWeight: 500}}>
        {description}
        <span className={hiddenClassName}>
          { hidden ? `(${hidden})` : null }
        </span>
      </p>
    </div>
  );
};

export default Detail;

import { Col, Form, FormGroup, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useCallback, useEffect, useState } from "react";
import { BsBoxSeam } from "react-icons/bs";
import { InputText } from "../../../Components/Form/InputText";
import ModalSelectPassengers from "../../../Components/GroupManagement/ModalSelectPassengers";
import ViewSelectedItems from "../../../Components/advanced/v2/view-selected-items";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { useNavigate, useParams } from "react-router-dom";
import { useManageGroup } from "../../../common/hooks/axios/passenger/group/useManagePassengerGroup";
import { SetNotificationAction } from "../../../redux/rootActions";
import { useDispatch } from "react-redux";
import Emitter from "../../../helpers/emitter";
import EmitterActions from "../../../helpers/emitter/emitter.actions";
import { useFetchGroup } from "../../../common/hooks/axios/passenger/group/useFetchGroupPassenger";
import { IF } from "../../../Components/If";

type PassengerGroupProps = {
    name: string,
}

export const PassengerGroupForm = (props: PassengerGroupProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { key } = useParams();
    const navigate  = useNavigate();

    const { saveAsyncGroup } = useManageGroup({key, manual: true, type:  'passenger'});
    const { group } = useFetchGroup({key, manual: false, type: "passengers"});
    
    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
    const [ selectedItems, setSelectedItems ] = useState<Record<string, {label: string, value: string}[]>>({})
    
    useEffect(() => {
        if(group) {
            setSelectedItems({['other']: group.items})
        }
    }, [group]);

    useEffect(() => {
        setValue('name', group?.name);
    }, [group?.name])
    
    const onSubmit = async (data: any) => { 
        const result = await saveAsyncGroup({...data, items: getSelectedItems().map(item => item.value)});
        if(result.success) {   
            const key = result.success.insertedId;         
            dispatch(SetNotificationAction({
                title: "succès",
                "message": key ? "Groupe modifié" : "Groupe ajouté",
                timeToClose: 3000,
                type: 'success'
            }));

            Emitter.emit(EmitterActions["GROUP:GO_TO"], { key, name: data.name, totalItems: getSelectedItems().length })
        }
    }

    const onCancel = useCallback(() => { navigate("/passengers"); }, []);
    
    const getSelectedItems = useCallback(() => {
        return Object.values(selectedItems).reduce((result, current) => [...result, ...current], []);
    }, [selectedItems]);


    // Hooks
    const [isOpen, toggleOpen] = useState(false);

    // Events
    const toggleModal = () => {
        toggleOpen(!isOpen)
    }

    const onSelect = (data: any) => {
        setSelectedItems(data);
    }

        
    return(    
        <Form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
                <InputText
                    type="text"
                    label={"Nom du groupe"}
                    id={'name'}
                    name={'name'}
                    placeholder={"Veuillez renseigner le nom du groupe"}
                    register={register('name', {
                        required: {
                            value: true, 
                            message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                        }})}
                    error={errors['name']}
                />
            </FormGroup>
            <FormGroup>
                <Label>Passagers</Label>
                <ViewSelectedItems 
                    items={getSelectedItems()} 
                    target={"unit"} 
                    limit={3} 
                    addActionName="Modifier la séléction"
                    editActionName="Cliquer ici pour ajouter des passagers au groupe"
                    onClick={toggleModal}
                >
                    <IF condition={!key || !!(group?.name) }>
                        <ModalSelectPassengers 
                            title={key ? "Modifier des passagers au groupe" : "Ajouter des passagers au groupe"}
                            description="Choisissez vos passagers et rajoutez les au groupe."
                            Icon={BsBoxSeam}
                            isOpen={isOpen} 
                            default={selectedItems}
                            toggleModal={toggleModal}
                            onSelect={onSelect}
                        />
                    </IF>
                </ViewSelectedItems>
            </FormGroup>
            <Col>
                <div className="mt-4 hstack gap-2 justify-content-end">
                    <ButtonUi color={"primary"} soft onClick={onCancel}>
                        <FormattedMessage id="COMMON.CANCEL"/>
                    </ButtonUi>
                    <ButtonUi color={"primary"} type="submit">
                        { key ? <FormattedMessage id="COMMON.UPDATE"/>  : <FormattedMessage id="COMMON.ADD"/> }
                        
                    </ButtonUi> 
                </div>
            </Col>
        </Form>
    );
}



import fileDownload from 'js-file-download';
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { SetRedirectAction, SetNotificationAction } from '../../../../redux/rootActions';
import { RootState } from '../../../../redux/store';
import {

    DownloadReportEnd,
    DownloadReportStartType,
    DOWNLOAD_REPORT_START,
    ErrorReport,
    GenearateReportEnd,
    GenerateReportStartType,
    GENERATE_REPORT_START,
    GetReportsEnd,
    GetReportsStartType,
    GET_REPORTS_START,
    UserActionType
} from './report.actions';
import { download, generateReport, getReports } from './report.service';


export const GenerateReportEpic = (
    action$: ActionsObservable<GenerateReportStartType>,
    state$: { value: RootState }
): Observable<UserActionType | any> =>
    action$.pipe(
        ofType(GENERATE_REPORT_START),
        switchMap((action: GenerateReportStartType) => {
            const { manage } = state$.value.reportReducer;
            const {affectTo, ...other} = manage;
            return from(generateReport({data: affectTo.list, ...other})
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...result,
                        ...{
                            _id: result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                        },
                    };
                    return of(
                        GenearateReportEnd(newManage),
                        SetRedirectAction('/reports'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                      );
                }),
                catchError((err) => {
                    return of(ErrorReport(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorReport(err.msg));
        })
    );

export const GetReportsEpic = (
    action$: ActionsObservable<GetReportsStartType>,
    state$: { value: RootState }
): Observable<UserActionType> =>
    action$.pipe(
        ofType(GET_REPORTS_START),
        switchMap((action: GetReportsStartType) => {
            return from(getReports(action.payload)).pipe(
                map((result) => {
                    return GetReportsEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorReport(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorReport(err.msg));
        })
    );

    export const DownloadReportEpic = (
        action$: ActionsObservable<DownloadReportStartType>,
        state$: { value: RootState }
    ): Observable<UserActionType> =>
        action$.pipe(
            ofType(DOWNLOAD_REPORT_START),
            switchMap((action: DownloadReportStartType) => {
                return from(download(action.payload)).pipe(
                    map((result) => {
                        fileDownload(result.data, `report.xlsx`);
                        return DownloadReportEnd({ result, filter: action.payload });
                    }),
                    catchError((err) => {
                        return of(ErrorReport(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorReport(err.msg));
            })
        );
   
    //     export const StartDownloadEpic = (action$, state$) => action$.pipe(
    //     ofType(actions.START_DOWLOAD),
    //     switchMap((action) => {
    //             return from(download(action.payload, state$.value.authReducer.token)).pipe(
    //                 map(res => {
    //                     fileDownload(res.data, `${action.payload.name}.xlsx`);
    //                     return EndDownload(res.data);
    //                 }),
    //                 catchError(err => {
    //                     let msg = 'une erreur s\'est produite !';
    //                     if (err.response.status === 401) {
    //                         return of(AutoLogout(), Error(''));
    //                     }
    //                     return of(Error(msg))
    //                 })
    //             )
    //        // }
    //     }),
    //     catchError(err => {
    //         return of(Error('Problem unknow'))
    //     })
    // );
import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { AlertUi } from "../../../../../Components/basic/AlertUi";
import { ButtonUi } from "../../../../../Components/basic/ButtonUi";
import { AsyncInputSelect } from "../../../../../Components/Form/AsyncInputSelect";
import { InputText } from "../../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../../redux/hooks";
import { ClearManageSensorAction, CreateSensoreStart, SetSelectIoSensorAction, SetSelectSubTypeSensorAction, SetSelectTypeSensorAction } from "../../../redux/vehicleModule.actions";
import { fetchSensorByName, findSensorsIo, findSensorsSubType, findSensorsType } from "../../../redux/vehicleModule.service";

export const General = ({className}: {className: string}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {_id} = useParams();
    const [fetchLoading, setFetchLoading] = useState(false);
    const { sensors, loading } =
        useAppSelector((state) => state.vehicleModuleReducer);
        const { param, type, subType, io } = sensors.manage;
    
        const { register, handleSubmit, formState: { errors }, getValues, setValue, setError } = useForm({defaultValues: {
            name: sensors.manage.name,
            param: sensors.manage.param,
            icon: sensors.manage.icon,
            description: sensors.manage.description,
            unit: sensors.manage.unit,
            paramValue: sensors.manage.paramValue,
    }});


    const calibrationError = useMemo(()=> sensors.calibration.find(item => item.error), [sensors.calibration]);
    const calibrationSize = sensors.calibration.length;
    const disableParam = type?.value === "Digital" || type?.value === "Mielage" || sensors.manage.subType?.value === 'Driver' || !!sensors.manage._id;
    const disableFormula = type?.value === "Digital" || sensors.manage.subType?.value === 'Driver';

    const onSubmit = async (data: any) => {
        if(_id && io && subType && type && (calibrationSize === 0 || (!calibrationError && sensors.calibration.length > 1))){
            setFetchLoading(true);
            const newData = { ...data};
            if(data.fuel && data.fuel.timeout) {
                data.fuel.timeout = +data.fuel.timeout;
            }
            if (disableParam) {
                newData.param = param;
            } else {
                const exist = await fetchSensorByName(_id, newData.param);
    
                if (exist && exist.error) {
                    setFetchLoading(false);
                    setError(exist.for, { message: exist.msg, type: 'isUnique' });
                    return;
                }
            }
            dispatch(CreateSensoreStart({...data, vehicleId: _id }));
        }

    }

    const handleChangeType = (data: any) => {
        if(data) {
            dispatch(SetSelectTypeSensorAction(data))
        } else {
            dispatch(SetSelectTypeSensorAction(undefined));
        } 
        dispatch(SetSelectSubTypeSensorAction(undefined));
        dispatch(SetSelectIoSensorAction(undefined));

    }
    const handleChangeSubType = (data: any) => {
        if(data) {
            dispatch(SetSelectSubTypeSensorAction(data));
        } else {
            dispatch(SetSelectSubTypeSensorAction(undefined));
        }
        dispatch(SetSelectIoSensorAction(undefined));

    }
    
    const handleChangeIo= (data: any) => {
        if(data) {
            dispatch(SetSelectIoSensorAction(data));
            if(type?.value === "Digital") { setValue('param', data.value); } 
            if(type?.value === "Mielage") { setValue('param', 'km'); } 
            if(subType?.value === "Driver") { setValue('param', 'driver'); } 
           
            const formula = getValues('paramValue');
            if(formula === undefined || formula.length === 0) {
                setValue('paramValue', `$${data.value}$`);
            } else {
                setValue('paramValue', `${formula.replaceAll(formula, `$${data.value}$`)}`);
            }
            
        } else {
            dispatch(SetSelectIoSensorAction(undefined));
        }
    }

    useEffect(()=> {
        return function cleanup() {
            dispatch(ClearManageSensorAction());
        };
    }, []);


    return (
        <Form onSubmit={handleSubmit(onSubmit)} className={`"position-relative" ${className}`}>
            <Row>
                <Col md={12}>
                    {
                        (calibrationError || calibrationSize ===1) && <AlertUi color="danger" solid>
                            Veuillez vérifier votre tableau de calibration
                            </AlertUi>
                    }
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'SENSOR.NAME'})}
                            id={'name'}
                            name={'name'}
                            placeholder={intl.formatMessage({id: 'SENSOR.PLACEHOLDER.NAME'})}
                            register={register('name', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                },
                                maxLength: {
                                    value: 12,
                                    message: `${intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })}: 6`
                                }})}
                            error={errors['name']}
                        />
                    </FormGroup>
                </Col> 
                <FormGroup>
                    <InputText
                        type="textarea"
                        label={intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                        id={'description'}
                        name={'description'}
                        placeholder={intl.formatMessage({id: 'COMPANY.PLACEHOLDER.DESCRIPTION'})}
                        register={register('description')}
                        error={errors['description']}
                    />
                </FormGroup>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'SENSOR.PARAM'})}
                            id={'param'}
                            name={'param'}
                            disabled={disableParam}
                            placeholder={intl.formatMessage({id: 'SENSOR.PLACEHOLDER.PARAM'})}
                            register={register('param', {
                                required: {
                                    value: true, 
                                    message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                },
                                maxLength: {
                                    value: 12,
                                    message: `${intl.formatMessage({ id: 'COMMON.MAX_LENGTH' })}: 6`
                                }})}
                            error={errors['param']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'SENSOR.ICON'})}
                            id={'icon'}
                            name={'icon'}
                            placeholder={intl.formatMessage({id: 'SENSOR.PLACEHOLDER.ICON'})}
                            register={register('icon')}
                            error={errors['icon']}
                        />
                    </FormGroup>
                </Col>
                <Col xl={6}>
                    {
                        sensors?.manage && <AsyncInputSelect 
                                isClearable
                                label={intl.formatMessage({ id: 'SENSOR.TYPE'})}
                                error={!type}
                                cacheOptions
                                defaultOptions
                                defaultValue={type}
                                loadOptions={(typedText: string)=> findSensorsType('teltonika', typedText)}
                                onChange={handleChangeType}
                                isDisabled={sensors.manage._id}
                            />
                    }
                </Col> 
                
                <Col xl={6} c>
                    
                    
                    {
                        sensors?.manage && <FormGroup>
                            <AsyncInputSelect 
                                key={`select_${type?.value}`}
                                isClearable
                                label={intl.formatMessage({ id: 'SENSOR.SUB_TYPE'})}
                                error={!subType}
                                cacheOptions
                                defaultOptions
                                defaultValue={ subType }
                                loadOptions={(typedText: string)=> findSensorsSubType(_id, {tracer: 'teltonika', type: type?.value}, typedText)}
                                onChange={handleChangeSubType}
                                isDisabled={sensors.manage.timing}
                            />
                        </FormGroup>
                    }
            
                </Col> 
                
                <Col xl={6}>
                    
                    {
                        sensors?.manage && 
                        <FormGroup>
                            <AsyncInputSelect 
                                key={`select_${type?.value}_${subType?.value}`}
                                isClearable
                                error={!io}
                                label={intl.formatMessage({ id: 'SENSOR.SUB_TYPE'})}
                                cacheOptions
                                defaultOptions
                                defaultValue={ io }
                                loadOptions={(typedText: string)=> findSensorsIo({tracer: 'teltonika', type: type?.value, subType: subType?.value}, typedText)}
                                onChange={handleChangeIo}
                                isDisabled={sensors.manage.timing}
                            />
                        </FormGroup>
                    }
                </Col>
                <Col xl={6}>
                    <FormGroup>
                        <InputText
                            type="text"
                            label={intl.formatMessage({id: 'SENSOR.UNIT'})}
                            id={'unit'}
                            name={'unit'}
                            placeholder={intl.formatMessage({id: 'SENSOR.PLACEHOLDER.UNIT'})}
                            register={register('unit')}
                            error={errors['unit']}
                            disabled={!sensors.manage.type || sensors.manage.type.value === 'Digital' || sensors.manage.type.value === 'Timing'}
                        />
                    </FormGroup>
                </Col> 
                <FormGroup>
                    <InputText
                        // hidden={!sensors.manage.type || (sensors.manage.type.value === 'Digital') || sensors.manage.type.value === 'Working time'}
                        disabled={disableFormula}
                        type="textarea"
                        label={intl.formatMessage({ id: 'COMMON.FORMULA' })}
                        id={'paramValue'}
                        name={'paramValue'}
                        placeholder={intl.formatMessage({id: 'SENSOR.PLACEHOLDER.FORMULA'})}
                        register={register('paramValue', {
                            required: {
                                value: true, 
                                message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                            },
                        })}
                        error={errors['paramValue']}
                    />
                </FormGroup>
                <Col>
                    <div className="hstack gap-2 justify-content-end">
                        <ButtonUi color={"primary"} type="submit" loading={loading || fetchLoading}>
                            {
                                sensors.manage._id ? <FormattedMessage id="COMMON.UPDATE"/> : <FormattedMessage id="COMMON.ADD"/>
                            }
                        </ButtonUi> 
                    </div>
                </Col>
            </Row>

        </Form>     
    );
}
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RootState } from '../../../../../redux/store';
import * as actions from './fuelActions';
import {
    Error,
    FuelActionsType,
    GetChartFuelStartActionType,
    getFuelEnd,
    GetFuelStartActionType,
    SetActiveEnd,
    SetActiveStartActionType,
} from './fuelActions';
import { fetchChartFuel, fetchFuel } from './fuelService';

/*
 * Fetch vehicles data
 * */

export const FetchFuelEpics = (
    action$: ActionsObservable<GetFuelStartActionType>,
    state$: { value: RootState }
): Observable<FuelActionsType> =>
    action$.pipe(
        ofType(actions.GET_START),
        switchMap((action: GetFuelStartActionType) => {
            const vehicleId = state$.value.ObjectReducer.vehicle?._id;
            const { token } = state$.value.authReducer;
            if (!vehicleId || !token) {
                return of(Error('Problem unknow'));
            }
            return from(fetchFuel(vehicleId, action.payload, token)).pipe(
                map((res) => {
                    return getFuelEnd(res);
                }),
                catchError((err) => {
                    return of(Error('Problem unknow'));
                })
            );
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );

export const FetchChartFuelEpics = (
    action$: ActionsObservable<GetChartFuelStartActionType>,
    state$: { value: RootState }
): Observable<FuelActionsType> =>
    action$.pipe(
        ofType(actions.GET_START_CHART),
        switchMap((action: GetChartFuelStartActionType) => {
            const vehicleId = state$.value.ObjectReducer.vehicle?._id;
            const { token } = state$.value.authReducer;

            if (!vehicleId || !token) {
                return of(Error('Problem unknow'));
            }

            return from(fetchChartFuel(vehicleId, action.payload)).pipe(
                map((res) => {
                    return actions.GetChartFuelEnd(res);
                }),
                catchError((err) => {
                    return of(Error('Problem unknow'));
                })
            );
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );

export const SetActiveFuelEpic = (
    action$: ActionsObservable<SetActiveStartActionType>,
    state$: { value: RootState }
): Observable<FuelActionsType> =>
    action$.pipe(
        ofType(actions.SET_ACTIVE_START),
        switchMap((action: SetActiveStartActionType) => {
            const leafletMap = state$.value.mainReducer.map;
            const { fuel } = state$.value.fuelReducer;

            const { _id, coordinates } = action.payload;
            const [lng, lat] = coordinates;
            leafletMap.fitBounds([[lat, lng]], {
                paddingTopLeft: [600, 20],
            });

            const index = fuel.findIndex((event: any) => event._id === _id);
            return of(SetActiveEnd({ ...{ index }, ...action.payload }));
        }),
        catchError((err) => {
            return of(Error('Problem unknow'));
        })
    );

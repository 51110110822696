import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts';

const apexPlotOptions: ApexPlotOptions = {
    pie: {
        donut: {
            size: "65",
            labels: {
                show: true,
                total: {
                    show: true,
                    showAlways: true,
                }
            }
        }
    }
}
const options: ApexOptions = {
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false,
    },
    plotOptions: apexPlotOptions,
    colors: ['rgba(10, 179, 156, 1)', 'rgba(240, 101, 72, 1)'],
    labels: ['État ok', "Éxpirée"]
}
type MaintenancePieProps = {
    expired: number,
    ok: number
}
export const MaintenancePie = (props: MaintenancePieProps) => {
    return (
        <Chart 
            options={options} 
            series={[props.ok, props.expired]} 
            type="donut" 
            width={"70%"}
        />
    );
}
import { UseFormRegister } from "react-hook-form";
import { useIntl } from "react-intl";
import {CardBody, CardHeader, FormGroup } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { InputText } from "../../../Components/Form/InputText";
import { mobileValidator, emailValidator } from "../../../helpers/validationHelper";


type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any}
}
export const Contact = ({register, errors}: ManageCompanyPageType) => {
    const intl = useIntl();
    const validatorMobile = mobileValidator(false, 'ar-MA');
    const validatorEmail = emailValidator(false);
        
    return (
        <CardUi>
        <CardHeader>
            <h4 className='card-title mb-0'>Contact</h4>
        </CardHeader>
        <CardBody>
        <FormGroup>
                <InputText
                    type="text"
                    label={intl.formatMessage({ id: 'COMMON.EMAIL' })}
                    id={'email'}
                    name={'email'}
                    placeholder={intl.formatMessage({ id: 'COMPANY.PLACEHOLDER.EMAIL' })}
                    register={register('email' as any, validatorEmail)}
                    error={errors['email' as 'name']}
                />
            </FormGroup>
            <FormGroup>
                <InputText
                    type="text"
                    label={intl.formatMessage({ id: 'COMMON.PHONE' })}
                    id={'mobile'}
                    name={'mobile'}
                    placeholder={intl.formatMessage({id: 'COMPANY.PLACEHOLDER.PHONE'})}
                    register={register('mobile' as 'name', validatorMobile)}
                    error={errors['mobile'  as 'name']}
                />
            </FormGroup>
        </CardBody>
    </CardUi>             
    );
}
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {CardBody, CardHeader, Input } from "reactstrap";
import { CardUi } from "../../../../Components/basic/Card";
import { TableUi } from "../../../../Components/Table";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeUserRoleItem, GetUserRoleStart } from "../../redux/user.actions";


export const UserRole = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { manage } = useAppSelector((state) => state.userReducer);

    const changeRole = (type: string, key: string, value: boolean): void => {
        dispatch(ChangeUserRoleItem({ key, value, type }));
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: intl.formatMessage({id: "COMMON.READ_ONLY_OPTION"}),
            dataIndex: 'read',
            key: 'read',
            render: (selected: boolean | undefined, obj: any) => selected !== undefined ? <Input 
                type="checkbox" 
                defaultValue="" 
                checked={selected}
                onChange={(e)=> changeRole(obj.name, 'read', e.target.checked)}
            /> : '-'
        },
        {
            title: intl.formatMessage({id: "COMMON.ADD_OPTION"}),
            dataIndex: 'add',
            key: 'add',
            render: (selected: boolean | undefined, obj: any) => selected !== undefined ? <Input 
                type="checkbox" 
                defaultValue="" 
                checked={selected}
                onChange={(e)=> changeRole(obj.name, 'add', e.target.checked)}
            /> : '-'
        },
        {
            title: intl.formatMessage({id: "COMMON.UPDATE_OPTION"}),
            dataIndex: 'update',
            key: 'update',
            render: (selected: boolean | undefined, obj: any) => selected !== undefined ? <Input 
                type="checkbox" 
                defaultValue="" 
                checked={selected}
                onChange={(e)=> changeRole(obj.name, 'update', e.target.checked)}
            /> : '-'
        },
        {
            title: intl.formatMessage({id: "COMMON.DELETE_OPTION"}),
            dataIndex: 'delete',
            key: 'delete',
            render: (selected: boolean | undefined, obj: any) => selected !== undefined ? <Input 
                type="checkbox" 
                defaultValue="" 
                checked={selected}
                onChange={(e)=> changeRole(obj.name, 'delete', e.target.checked)}
            /> : '-'
        },
    ];

    useEffect(() => {
        const isUpdate = manage._id !== 'add';
        if (manage.name || manage.roles.length === 0 || isUpdate) {
            dispatch(GetUserRoleStart());
        }
    }, [manage.name]);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="USER.ROLE" /></h4>
            </CardHeader>
            <CardBody>
                <TableUi columns={columns} data={manage.roles}  minusHeight={300}
/>
            </CardBody>
        </CardUi>               
    );
}
import { Label } from "reactstrap";
import { BsBoxSeam } from "react-icons/bs";
import classNames from "classnames";

type GroupSelectionItemProps = {
    item: any,
    active: boolean
    onSelect: (key: string) => void;
}
export const GroupSelectionItem = (props: GroupSelectionItemProps) => {
    const groupClassName = classNames("px-3 py-3 group-item", {"active": props.active})
    return (
        <div className={groupClassName} onClick={() => props.onSelect(props.item.value)}>
            <BsBoxSeam size={16}/>
            <Label className="mb-0 ms-2 cursor-pointer d-flex align-items-center justify-content-between flex-grow-1">
                <span>{props.item.label}</span> <span>(-/{props.item.totalItems})</span>
            </Label>
        </div>
    );
}
import { axiosInstance } from "../../../redux/setupAxios";
import { FilterType } from "../../User/types";
import { FilterGeofenceType, GeofenceAction, GetGeofencesResponse, ManageGeofence } from "../types";
import { GetGeofenceUpdateStartType, GET_GEOFENCE_UPDATE_START } from "./geofenceModule.actions";

export async function getGeofences(filter: FilterGeofenceType): Promise<GetGeofencesResponse> {
    let url = `/geofence?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }

    if (filter.select) {
        url += `&select=${filter.select}`;
    }
   
    try {
        const res = await axiosInstance.get<GetGeofencesResponse>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function saveGeofence(vehicle: ManageGeofence): Promise<any> {
    const url = `/geofence`;
    try {
        const res = await axiosInstance.post<{insertedId: string}>(url, vehicle);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateGeofence(
    vehicle: ManageGeofence
): Promise<ManageGeofence> {
    const url = `/geofence`;
    try {
        const res = await axiosInstance.patch<ManageGeofence>(url, vehicle);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}


export async function deleteGeofences({
    _ids,
}: {
    _ids: string[];
}): Promise<{ deletedCount: number }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&keys[]=${id}`;
        } else {
            params += `keys[]=${id}`;
        }
    });

    try {
        const res = await axiosInstance.delete<{ deletedCount: number }>(`/geofence${params}`);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
    
}

export async function getGeofence({_id}: {
    _id: string
}): Promise<ManageGeofence & {geofence: any}> {
    const url = `/geofence/${_id}`;
    try {
        const res = await axiosInstance.get<ManageGeofence & {geofence: any}>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}



import { useContext, useRef } from "react";
import { VehicleInfoType } from "../../Objects/_redux/types";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

import classNames from "classnames";
import { LayoutContext } from "../../../../context/layout-style-context";


import { useAppSelector } from "../../../../redux/hooks";
import { EventInfo } from "../../../../Components/advanced/Event-info";

export const ObjectEvent = ({object, display}: {object: VehicleInfoType, display: boolean}) => {
    const HEIGHT_FIX = 235;
    const { height } = useContext(LayoutContext);
    const detailsClass = classNames({
        [`d-none`]: !display,
    });

    const listRef = useRef<any>();
    const { events, activeEvent } = useAppSelector(state => state.eventsObjectReducer);
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 50,
    });

    return (
        <div className={detailsClass} style={{height: height - HEIGHT_FIX}}>
            <AutoSizer>
                {
                    ({ height, width }) => {
                        return(
                            <List
                                ref={listRef}
                                width={width}
                                height={height > 0 ? height : 0}
                                rowCount={events.length}
                                deferredMeasurementCache={cache}
                                rowHeight={cache.rowHeight}
                                rowRenderer={rowRenderer}
                                scrollToIndex={activeEvent ? activeEvent.index : undefined}
                            />
                        )
                    }     
                } 
            </AutoSizer> 
        </div>          
    );

    function rowRenderer ({ index, isScrolling, key, parent, style }: any): JSX.Element {
        return (
          <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
          >
            {({ measure }) => (
                <div 
                    key={key}
                    style={style}
                >
                   
                     <EventInfo event={events[index]} active={index === activeEvent?.index}/> 
                </div>
            )}
          </CellMeasurer>
        );
    }
}


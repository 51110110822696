import Rate from "antd/es/rate";
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";

type driverMarkProps = {
    name?: string,
    company: string,
    score?: number
}

export const DriverMark = (props: driverMarkProps) => {

    return (
        <CardUi>
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Note global</h6>
                </div>

                <div className="d-flex mt-4 align-items-between justify-content-between">

                    <span className="d-flex align-items-center">
                        <img className="avatar xs avatar-squared avatar-title" src="https://app.telematics.services/img/avatar.svg"/>
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{props.name ?? '-'}</span>
                            <span className="d-none d-xl-block ms-1 fs-12  user-name-sub-text text-muted">Ministère</span>
                        </span>
                    </span>

                    <div className="d-flex flex-column align-items-center">
                        <span className="fw-bold">Note: {props.score ?? '-'}/5</span>
                        <Rate disabled defaultValue={0} value={props.score ?? 0} allowHalf />
                    </div>

                </div>
            </CardBody>
        </CardUi>                
    );
}
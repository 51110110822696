import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeSelectZoneEventType } from "../../redux/events.actions";
import { getZonesForEvent } from "../../redux/events.service";

export const ZonesSelector = ({position}: {position: number}) => {
    const {_id} = useParams();
    const dispatch = useDispatch();
    const intl = useIntl();
    

    const { manage } = useAppSelector((state) => state.eventsReducer);
    const defaultValue = manage.events[position].zones || [];
    const [selectedOption, setSelectedOption] = useState(defaultValue);


    useEffect(() => {
        if (selectedOption) {
            dispatch(
                ChangeSelectZoneEventType({
                    position,
                    zones: selectedOption,
                })
            );
        }
    }, [selectedOption]);


    const chageOperation = (element: any): void => {
        setSelectedOption(element);
    };



    return (
        <AsyncInputSelect 
            isMulti
            isClearable
            label={intl.formatMessage({ id: 'ALARM.ZONES'})}
            error={false}
            cacheOptions
            defaultOptions
            defaultValue={manage.events[position].zones}
            loadOptions={getZonesForEvent}
            onChange={chageOperation}
        />  );  
}

import { useEffect, useState } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { CardUi } from "../../../../Components/basic/Card";
import { InputText } from "../../../../Components/Form/InputText";
import { useAppSelector } from "../../../../redux/hooks";
import { ChangeUserType } from "../../../User/redux/user.actions";
import { getTypesUser } from "../../../User/redux/user.service";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any},
   watch: UseFormWatch<FieldValues>
}

export const General = ({register, watch ,errors}: ManageCompanyPageType) => {
    const {_id} = useParams();

    const isUpdate = _id !== 'add';
    const dispatch = useDispatch();
    const intl = useIntl();

    const [types, setTypes] = useState<{label: string, value: string}[]>([]);
    const { manage } = useAppSelector((state) => state.userReducer);

    const changeUserType = (element: any): void => {
        dispatch(ChangeUserType(element.value));
    };

    const getUserTypes = async () => {
        const userTypes = await getTypesUser();
        setTypes(userTypes.map(type => {
            return {
                label: intl.formatMessage({id: type.label}),
                value: type.value
            }
        }));
    }

    useEffect(() => {
        getUserTypes();
    }, []);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xl={12}>
                        <FormGroup>
                            <InputText
                                type="text"
                                autocomplete={false}
                                label={intl.formatMessage({id: 'TRACER.NAME'})}
                                id={'name'}
                                name={'name'}
                                placeholder={intl.formatMessage({id: 'TRACER.PLACEHOLDER.NAME'})}
                                register={register('name', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                error={errors['name']}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                autocomplete={false}
                                label={intl.formatMessage({id: 'TRACER.UID.ALL'})}
                                id={'uid'}
                                name={'uid'}
                                disabled={isUpdate}
                                placeholder={intl.formatMessage({id: 'TRACER.PLACEHOLDER.UID'})}
                                register={register('uid', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                error={errors['uid']}
                            />
                        </FormGroup>
                    </Col>
                    <Col xl={6}>
                        <FormGroup>
                            <InputText
                                type="text"
                                autocomplete={false}
                                label={intl.formatMessage({id: 'TRACER.SIM'})}
                                id={'sim'}
                                name={'sim'}
                                placeholder={intl.formatMessage({id: 'TRACER.PLACEHOLDER.SIM'})}
                                register={register('sim', {
                                    required: {
                                        value: true, 
                                        message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                                    }})}
                                error={errors['sim']}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </CardUi>               
    );
}

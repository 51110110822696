import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, mergeMap } from 'rxjs/operators';
import { SetRedirectAction, SetNotificationAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import {
    CreateSensorEnd,
    CreateSensorStartType,
    CreateVehicleEnd,
    CreateVehicleStartType,
    CREATE_SENSOR_START,
    CREATE_VEHICLE_START,
    DeleteSensorEnd,
    DeleteSensorStartType,
    DeleteVehiclesEnd,
    DeleteVehiclesStartType,
    DELETE_SENSOR_START,
    DELETE_VEHICLES_START,
    ErrorVehicles,
    GetSensorsEnd,
    GetSensorsStartType,
    GetSensorUpdateEnd,
    GetSensorUpdateStartType,
    GetVehiclesEnd,
    GetVehiclesStartType,
    GetVehicleUpdateEnd,
    GetVehicleUpdateStartType,
    GET_SENSORS_START,
    GET_SENSOR_UPDATE_START,
    GET_VEHICLES_START,
    GET_VEHICLE_UPDATE_START,
    ToogleTimingSensorEnd,
    ToogleTimingSensorStartType,
    TOOGLE_TIMING_SENSOR_START,
    UpdateTripEnd,
    UpdateTripStartType,
    UPDATE_TRIP_START,
    VehicleActionType
} from './vehicleModule.actions';
import {
    createSensor,
    deleteSensor,
    deleteVehicles,
    getSensor,
    getSensors,
    getVehicle,
    getVehicles,
    saveVehicle,
    toogleSensorTiming,
    updateSensor,
    updateTripConfig,
    updateVehicle
} from './vehicleModule.service';

export const CreateVehicleEpic = (
    action$: ActionsObservable<CreateVehicleStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType | any> =>
    action$.pipe(
        ofType(CREATE_VEHICLE_START),
        switchMap((action: CreateVehicleStartType) => {
            const { manage } = state$.value.vehicleModuleReducer;
            const {groups, tracer, ...payload} = manage;
            const data = { ...payload, ...action.payload, groups, tracer };
            return from(
                payload._id ? updateVehicle(data) : saveVehicle(data)
            ).pipe(
                mergeMap((result) => {
                    const newManage = {
                        ...data,
                        ...result,
                        ...{
                            _id: payload._id ? payload._id : result.insertedId,
                            updatedAt: result.updatedAt,
                            updatedBy: result.updatedBy,
                            modifiedCount: result.modifiedCount,
                        },
                    };

                    return of(
                        CreateVehicleEnd(newManage),                         
                        SetRedirectAction('/units'),
                        SetNotificationAction({
                            title: "success",
                            "message": 'Action',
                            timeToClose: 3000,
                            type: 'success'
                        })
                    );
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const GetVehiclesEpic = (
    action$: ActionsObservable<GetVehiclesStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType> =>
    action$.pipe(
        ofType(GET_VEHICLES_START),
        switchMap((action: GetVehiclesStartType) => {
            return from(getVehicles(action.payload)).pipe(
                map((result) => {
                    return GetVehiclesEnd({ result, filter: action.payload });
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const GetVehicleEpic = (
    action$: ActionsObservable<GetVehicleUpdateStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType> =>
    action$.pipe(
        ofType(GET_VEHICLE_UPDATE_START),
        switchMap((action: GetVehicleUpdateStartType) => {
            return from(getVehicle(action.payload)).pipe(
                map((result) => {
                    return GetVehicleUpdateEnd({ result });
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const DeleteVehiclesEpic = (
    action$: ActionsObservable<DeleteVehiclesStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType | any> =>
    action$.pipe(
        ofType(DELETE_VEHICLES_START),
        switchMap((action: DeleteVehiclesStartType) => {
            return from(deleteVehicles({ _ids: action.payload })).pipe(
                mergeMap((result) => {
                    return of(DeleteVehiclesEnd({
                        ...result.data,
                        _ids: action.payload,
                    }), SetNotificationAction({
                        title: "success",
                        "message": 'Action',
                        timeToClose: 3000,
                        type: 'success'
                    }));
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const CreateSensorEpic = (
    action$: ActionsObservable<CreateSensorStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType | any> =>
    action$.pipe(
        ofType(CREATE_SENSOR_START),
        switchMap((action: CreateSensorStartType) => {
            const { sensors } = state$.value.vehicleModuleReducer;
            if(sensors?.manage) {
                const { unit, description, vehicleId, fuel, ...propsSensor } = action.payload;
                const {_id, io, type, subType} = sensors.manage;
                if(unit) { propsSensor.unit = unit; }
                if(description) { propsSensor.description = description; }
                const data = {...propsSensor, io, type, subType};
                if(sensors.calibration && sensors.calibration.length > 1) {
                    data.calibration = sensors.calibration.map(calib => {return ({key: calib.key, x: calib.x, y: calib.y})});
                }
                
                if(!!sensors.fuel) { 
                    data.fuel = sensors.fuel; 
                }

                return from(
                    _id ? updateSensor(vehicleId, _id, data) : createSensor(vehicleId, data)
                ).pipe(
                    mergeMap((result) => {
                        const newManage = {
                            ...data,
                            ...result,
                            ...{
                                _id: _id || result.insertedId,
                            },
                        };
    
                        return of(
                            CreateSensorEnd(newManage),                         
                            SetNotificationAction({
                                title: "success",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }),
                     catchError((err) => {
                        return of(ErrorVehicles(err.msg));
                    })
                );
            }
            return of(ErrorVehicles(''));
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );
    

export const GetSensorsEpic = (
    action$: ActionsObservable<GetSensorsStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType> =>
    action$.pipe(
        ofType(GET_SENSORS_START),
        switchMap((action: GetSensorsStartType) => {
            return from(getSensors(action.payload.vehicleId)).pipe(
                map((result) => {
                    return GetSensorsEnd(result);
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const DeleteSensorsEpic = (
    action$: ActionsObservable<DeleteSensorStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType | any> =>
    action$.pipe(
        ofType(DELETE_SENSOR_START),
        switchMap((action: DeleteSensorStartType) => {
            return from(deleteSensor(action.payload)).pipe(
                mergeMap((result) => {
                    return of(DeleteSensorEnd({
                        ...result,
                        sensorId: action.payload.sensorId,
                    }), SetNotificationAction({
                        title: "success",
                        "message": 'Action',
                        timeToClose: 3000,
                        type: 'success'
                    }));
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

export const GetSensorEpic = (
    action$: ActionsObservable<GetSensorUpdateStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType> =>
    action$.pipe(
        ofType(GET_SENSOR_UPDATE_START),
        switchMap((action: GetSensorUpdateStartType) => {
            return from(getSensor(action.payload)).pipe(
                map((result) => {
                    return GetSensorUpdateEnd(result);
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

    export const UpdateSensorTiming = (
        action$: ActionsObservable<ToogleTimingSensorStartType>,
        state$: { value: RootState }
    ): Observable<VehicleActionType | any> =>
        action$.pipe(
            ofType(TOOGLE_TIMING_SENSOR_START),
            switchMap((action: ToogleTimingSensorStartType) => {
                const { _id } = state$.value.vehicleModuleReducer.manage;
                const {sensorId, value} = action.payload;
                return from( toogleSensorTiming(_id, sensorId, value) ).pipe(
                    mergeMap((result) => {
                        const newManage = {
                            modifiedCount: result.modifiedCount,
                            ...action.payload
                        };
    
                        return of(
                            ToogleTimingSensorEnd(newManage),                         
                            SetNotificationAction({
                                title: "success",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    }),
                    catchError((err) => {
                        return of(ErrorVehicles(err.msg));
                    })
                );
            }),
            catchError((err) => {
                return of(ErrorVehicles(err.msg));
            })
        );


export const UpdateTripEpic = (
    action$: ActionsObservable<UpdateTripStartType>,
    state$: { value: RootState }
): Observable<VehicleActionType | any> =>
    action$.pipe(
        ofType(UPDATE_TRIP_START),
        switchMap((action: UpdateTripStartType) => {
            const { _id } = state$.value.vehicleModuleReducer.manage;
            return from(updateTripConfig(_id, action.payload)
            ).pipe(
                mergeMap((result) => {
                    if(result.matchedCount > 0 ) {
                        return of(
                            UpdateTripEnd(),                         
                            SetNotificationAction({
                                title: "success",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'success'
                            })
                        );
                    } else {
                        return of(    
                            ErrorVehicles(''),      
                            SetNotificationAction({
                                title: "error",
                                "message": 'Action',
                                timeToClose: 3000,
                                type: 'error'
                            })
                        );
                    }
                    
                }),
                catchError((err) => {
                    return of(ErrorVehicles(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(ErrorVehicles(err.msg));
        })
    );

   
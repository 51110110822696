import { useEffect } from "react";
import { useAxios } from "../useAxios";

export type FetchRoadProps = {
  name?: string,
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
  group?: string;
}

type UseFetchRoadResponse = { 
  routes: { list: any[], total: number };
  fetchRoutesLoading: boolean;
  error?: string;
  fetchRoutes: (params?: FetchRoadProps) => void;
  cancel: () => void;
}

type UseFetchRoutesProps = {
  manual?: boolean,
  skip?: number,
  limit?: number,
}

export function useFetchRoutes(props: UseFetchRoutesProps): UseFetchRoadResponse {
  const URL = `road`;
  const { execute, data, cancel, error, loading } = useAxios<{list: any[], total: number}>(props.manual);

  useEffect(() => {
    if(!props.manual) {
      fetchRoutes({"pagination[skip]": props.skip ?? 0, "pagination[limit]": props.limit ?? 25});
    }
  }, []);

  const fetchRoutes = (params?: FetchRoadProps) => {
    execute(URL, { method: "GET", params});
  };

  return { 
    routes: data ?? {list: [], total: 0},
    fetchRoutesLoading: loading, 
    error,
    fetchRoutes,
    cancel, 
  };
}

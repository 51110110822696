import { useCallback, useEffect, useState } from "react";
import Emitter from "../../../../../helpers/emitter";
import { StopListItemType } from "../../../../../common/hooks/axios/stops/type";
import { useMap } from "react-leaflet";
import { StopMarker } from "./stop-marker";
import MarkerClusterGroup from "react-leaflet-cluster";

export const StopsMarkers = () => {
    const map = useMap();
    const [ zoom, setZoom ] = useState(map.getZoom())
    const [ stops, setStops] = useState<StopListItemType[]>([]);
    
    useEffect(() => {
        Emitter.on(Emitter.event["STOP:DRAW_ALL"], draw);
        Emitter.on(Emitter.event["STOP:LOCALISE"], localize);
        map.on("zoom", () => { setZoom(map.getZoom()); })
        return () => {
            Emitter.off(Emitter.event["STOP:DRAW_ALL"], draw);
            map.off("zoom", () => { setZoom(map.getZoom()); })
        };
    }, []);

    const draw = useCallback((payload: StopListItemType[]) => { setStops(payload); }, []);
    const localize = useCallback((payload: [number, number]) => {
        map.setView([payload[1], payload[0]], 18)
    }, []);
    
    return (
        <MarkerClusterGroup >
            {
                stops?.map((stop: any, index) => {
                    return <StopMarker stop={stop} permanent={zoom >= 16} count={index}/>
                })
            }
        </MarkerClusterGroup>
    );

}
import { useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";

// export const data = {
//     labels: ['Éxcès de vitesse', 'Accélération brutale', 'Freinage brutal', 'Virage brutal', 'Marche au ralenti'],
//     datasets: [
//       {
//         label: 'Nombre de détéction',
//         data: [6, 15, 3, 1, 0],
//         // backgroundColor: 'rgba(255, 99, 132, 0.2)',
//         // borderColor: 'rgba(255, 99, 132, 1)',
//         borderWidth: 1,
//       },
//     ],
//   };
const options = {
    chart: {
        toolbar: {
            show: false
        }
    },
      dataLabels: {
        enabled: true
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff']
            }
          }
        }
      },
    //   title: {
    //     text: 'Nombre d\'infraction'
    //   },
      colors: ['#FF4560'],
      markers: {
        size: 4,
        colors: ['#fff'],
        strokeColor: '#FF4560',
        strokeWidth: 2,
      },
    //   tooltip: {
    //     y: {
    //       formatter: function(val) {
    //         return val
    //       }
    //     }
    //   },
      xaxis: {
        categories: ['Éxcès de vitesse', 'Accélération brutale', 'Freinage brutal', 'Virage brutal', 'Marche au ralenti']
      },
      yaxis: {
        tickAmount: 5,
        // labels: {
        //   formatter: function(val, i) {
        //     if (i % 2 === 0) {
        //       return val
        //     } else {
        //       return ''
        //     }
        //   }
        // }
      }
    } 
  
type DriverChartProps = {
    data?: {_id: string, count: number}[]
}
export const DriverChart = ({data}: DriverChartProps) => {
    const getSeries = useCallback(()=> {
        
        const speeding = data?.find(entry => entry._id === "speeding")?.count ?? 0;
        const harshAcceleration = data?.find(entry => entry._id === "event_1")?.count ?? 0;
        const harshBraking = data?.find(entry => entry._id === "event_2")?.count ?? 0;
        const harshCornering = data?.find(entry => entry._id === "event_3")?.count ?? 0;
        const idling = 0;

        return [{
            name: 'Valeur',
            data: [speeding, harshAcceleration, harshBraking, harshCornering, idling],
        }];
    }, [data]);

    return (
        <CardUi>
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Nombre d'infraction</h6>
                </div>
                <ReactApexChart 
                    options={options} 
                    series={getSeries()} 
                    type="radar"
                />
            </CardBody>
        </CardUi>             
    );
}
import { CardBody } from "reactstrap"
import classNames from 'classnames';


type cardBodyUiType = {
    children: JSX.Element[] | string | JSX.Element;
    className?: string;
    [key: string]: any;
}

export const CardBodyUi = ({className, children, ...other}: cardBodyUiType) => {
    const cardClassname = classNames({
        [`${className}`]: !!className,
      });


    return <CardBody className={cardClassname} {...other}>{children}</CardBody>
}



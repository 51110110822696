import { ButtonUi } from "../../../basic/ButtonUi";
import { AccordionBody, AccordionHeader, AccordionItem, Label } from "reactstrap";
import { IoMdAdd } from "react-icons/io";
import { BiAddToQueue } from "react-icons/bi";
import { FiLayers } from "react-icons/fi";
import { TiArrowSortedDown } from "react-icons/ti";
import { IF } from "../../../If";
import { memo } from "react";
import { IoRemove } from "react-icons/io5";

type SelectItemProps = {
    id: string;
    group: string;
    data: { label: string, value: string }[];
    selectedItems: { label: string, value: string }[];
    onSelect: (id: string, data: { label: string, value: string }[]) => void;
}

const SelectItem= (props: SelectItemProps) => {

    const selectedItemsSize = props.selectedItems.length;
    const canAddAll = selectedItemsSize !== props.data.length;

    const select = (item: { label: string, value: string}) => {
        const index = props.selectedItems.findIndex(i => item.value === i.value);
        const items = [...props.selectedItems];
        if(index === -1) {
            items.push(item);
        } else {
            items.splice(index, 1);
        }

        props.onSelect(props.id, items);
    };

    const selectAll = () => {
        if(canAddAll) {
            props.onSelect(props.id, props.data);
        } else {
            props.onSelect(props.id, []);
        }
    };

    return (    
        <AccordionItem>
            <div className="accordion-header-container">
                <div className="action-container" >
                    <ButtonUi 
                        className="action fw-bold" 
                        color={canAddAll ? "primary" : "danger"}
                        size="sm" 
                        ghost
                        onClick={selectAll}
                    >
                        { canAddAll ? 
                            <> <BiAddToQueue size={15}/> Ajouter tous</> 
                            : <> <IoRemove size={15}/> Retirer tous</> 
                        }
                    </ButtonUi>
                </div>
                
                <AccordionHeader targetId={props.group}>
                    <div className="d-flex flex-row align-items-center">
                        <TiArrowSortedDown size={17} className="text-muted accordion-arrow"/> 
                        <FiLayers className="ms-3" size={15}/>
                        <Label className="mb-0 ms-2 cursor-pointer fs-13">{ props.group }</Label>
                        <IF condition={!!selectedItemsSize}>
                            <span className="fs-12 ms-2">(Sélectionnée: {selectedItemsSize}/{props.data.length} )</span>
                        </IF>  
                    </div>
                </AccordionHeader>
                
            </div>
            
            
            <AccordionBody accordionId={props.group}>
                <div className="ms-5 me-3 py-3 table-responsive" style={{height: `${45*4}px` }}>
                    {
                        props.data.map((item) => {
                            const founded = props.selectedItems.findIndex(i => item.value === i.value) !== -1;
                            return(
                                <div key={item.value} className="d-flex flex-row align-items-center justify-content-between mb-2">
                                    <Label className="mb-0 ms-2 cursor-pointer fs-13">{ item.label }</Label>
                                    <ButtonUi 
                                        className="fw-bold" 
                                        color={founded ? "danger" : "primary" }
                                        size="sm"
                                        ghost
                                        onClick={()=> select(item)}
                                    >
                                        <IF condition={!founded}>
                                            <><IoMdAdd size={15}/> Ajouter</>
                                        </IF>
                                        <IF condition={founded}>
                                            <><IoRemove size={15}/> Retirer</>
                                        </IF>
                                    </ButtonUi>
                                </div>
                            );
                        })
                    }

                </div>
            </AccordionBody>
        </AccordionItem>    
    );
}

export default memo(SelectItem);
import { useState, useEffect, useCallback, useMemo } from "react";
import {  useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import { ButtonUi } from "../../../../../Components/basic/ButtonUi";
import { useAppSelector } from "../../../../../redux/hooks";
import { ClearManageSensorAction, } from "../../../redux/vehicleModule.actions";
import { Calibration } from "./Calibration";
import { FuelConfiguration } from "./FuelDetection";
import { General } from "./General";

type ManageSensorType = {
    isOpen?: boolean;
}

enum ActiveWindow {
    GENERAL = "general",
    FUEL_CONSUMPTION = "fuelConsumption",
    CALIBRATION = "calibration"
}

export const ManageSensor = ({isOpen}: ManageSensorType) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { sensors } = useAppSelector((state) => state.vehicleModuleReducer);
    const [activePage, setActivePage] = useState<ActiveWindow>(ActiveWindow.GENERAL);

    

    useEffect(()=> {
        return function cleanup() {
            dispatch(ClearManageSensorAction());
        };
    }, []);

    const onSwitchAction = (e: any, value: ActiveWindow) => {
        e.target.blur();
        setActivePage(value);
    }

    const canShowFuelConsumption = useMemo(()=> {
        const type = sensors.manage.type?.value.trim().toLowerCase();
        const subType = sensors.manage.subType?.value.trim().toLowerCase();

        return type === 'fuel' &&  subType === 'fuel level';
    }, [sensors.manage.type?.value, sensors.manage.subType?.value])

    
    return (
        <Container>
            <Row>
                <Col md={3}>
                    <ButtonUi color={"info"}  soft={ActiveWindow.GENERAL !== activePage} className="mb-4 w-100" onClick={(e: any)=> onSwitchAction(e, ActiveWindow.GENERAL)}>Général</ButtonUi>
                    {
                        canShowFuelConsumption && <ButtonUi color={"info"}  soft={ActiveWindow.FUEL_CONSUMPTION !== activePage} className="mb-4 w-100" onClick={(e: any)=> onSwitchAction(e, ActiveWindow.FUEL_CONSUMPTION)}>Gazoil Rempl../Perte</ButtonUi>
                    }
                    <ButtonUi color={"info"} soft={ActiveWindow.CALIBRATION !== activePage} className="mb-4 w-100" onClick={(e: any)=> onSwitchAction(e, ActiveWindow.CALIBRATION)}>Calibration</ButtonUi>
                </Col>
                <Col md={9}>
                    <General className={ActiveWindow.GENERAL !== activePage ? 'd-none' : ''}/>
                    <FuelConfiguration className={ActiveWindow.FUEL_CONSUMPTION !== activePage ? 'd-none' : ''}/>
                    <Calibration className={ActiveWindow.CALIBRATION !== activePage ? 'd-none' : undefined}/>
                </Col>
            </Row> 
        </Container>
               
    );
}
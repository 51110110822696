import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { io, Socket } from "socket.io-client";
import { SOCKET_IO } from "../../env";
import { SetVehicleData } from "../../pages/Map/Object/Details/_redux/vehicleActions";
import {  SetVehicleDataInList } from "../../pages/Map/Objects/_redux/unit-list.actions";
import { useAppSelector } from "../../redux/hooks";
import Emitter from "../../helpers/emitter";

export const SocketIoModule = () => {

    const dispatch = useDispatch();
    const socketRef = useRef<Socket>();
    const roomsToLeave = useRef<string[]>([]);
    const {timezone, token, tokenShare, objects} = useAppSelector((state) => {
        return {
            tokenShare: state.sharedMapReducer.token,
            token: state.authReducer.token,
            timezone: state.authReducer.user?.timezone,
            objects: state.vehicleListReducer.vehicles.list
        }
    });


    useEffect(()=> {
        connectToServer();
        socketRef.current?.on('connect', () => {
            console.log('connected....');
            joinRooms();
        });
        socketRef.current?.on('gps-data', ({key, ...data}) => {
            dispatch(SetVehicleDataInList({_id: key, data: {...data, unitDate: +data?.unitDate}}));
            dispatch(SetVehicleData({_id: key, data: {...data, unitDate: +data?.unitDate}}));
        });
        socketRef.current?.on('notification', (data) => {
            Emitter.emit(Emitter.event["IO:NEW_NOTIFICATION"], data);
        });
        socketRef.current?.on('cnx', data => {
            // dispatch(SetVehicleCnx(data));
            // dispatch(SetVehicleCnxInList(data));
        });
    }, [token]);

    useEffect(()=> {
        joinRooms();
    }, [JSON.stringify(objects.map(obj => obj._id))]);


    const connectToServer = () => {
        socketRef.current?.disconnect();
        const tokenSocketIO = tokenShare  ? tokenShare : (`${token}` || '');
        socketRef.current = io(SOCKET_IO, {
            secure: true,
            extraHeaders: {
                token: tokenSocketIO
            } 
        });
    }

    const joinRooms = () => {
        const _ids = objects.map(obj => obj._id);
        if(_ids.length > 0) {
            console.log('join')
            console.log(_ids)
            socketRef.current?.emit('join', {toJoin: _ids, toLeave: roomsToLeave.current});
            roomsToLeave.current = _ids;
        }
    }
 
    return null;
}
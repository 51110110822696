import React from 'react'
import { FaTools } from 'react-icons/fa'

export type ToolsIconProps = { size?: number }

export default function ToolsIcon({ size = 22 }: ToolsIconProps): JSX.Element {
  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        backgroundColor: 'red',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FaTools style={{ fontSize: size * 0.6, color: 'white' }} />
    </div>
  )
}

import { axiosInstance } from '../../../redux/setupAxios';
import { UserType } from '../../Authentification/_redux/types';
import { FilterCompanyType, ManageCompanyType } from '../types';

export async function saveCompany(company: ManageCompanyType): Promise<any> {
    const url = `/company`;
    try {
        const res = await axiosInstance.post<ManageCompanyType>(url, company);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateCompany(
    company: ManageCompanyType
): Promise<ManageCompanyType> {
    const url = `/company`;
    try {
        const res = await axiosInstance.patch<any>(url, company);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getCompanys(filter: FilterCompanyType): Promise<any> {
    let url = `/company?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getCompany({ _id }: { _id: string }): Promise<any> {
    const url = `/company/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteCompanys({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/company${params}`);
}

export async function switchCompany({
    _id,
}: {
    _id: string;
}): Promise<{ modifiedCount: number; user: UserType }> {
    const url = `account/admin/company/${_id}`;
    try {
        const res = await axiosInstance.post<{
            modifiedCount: number;
            user: UserType;
        }>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { CardHeader, CardBody, FormGroup } from "reactstrap"
import { CardUi } from "../../../../Components/basic/Card"
import { AsyncInputSelect } from "../../../../Components/Form/AsyncInputSelect"
import { InputFlatPickrUi } from "../../../../Components/Form/InputFlatPickr"
import { useAppSelector } from "../../../../redux/hooks"
import { SetDateReportAction, SetTypeReport, ToogleAfectToAction } from "../redux/report.actions"
import { fetchTypesReport } from "../redux/report.service"

export const General = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { manage } = useAppSelector((state) => state.reportReducer);

    const { register, handleSubmit, formState: { errors }, setError, setValue, watch } = useForm();
   
    const changeDate = (dates: Date[]) => {
        dispatch(SetDateReportAction(dates))
    }

    const changeTypeReport = (element: any): void => {
        dispatch(SetTypeReport(element));
    };

    return(
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.REPORT_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>

            <FormGroup>
                <label style={{cursor: "pointer"}} className="form-label"> 
                    <FormattedMessage id="REPORT.NAME" />
                </label>
                <div style={{cursor: "pointer"}} className="form-control" onClick={()=> dispatch(ToogleAfectToAction())}>
                    {manage.affectTo.data.length} <FormattedMessage id="REPORT.UNITS" />
                </div>
                </FormGroup>

                <FormGroup>
                    <AsyncInputSelect 
                        label={intl.formatMessage({ id: 'REPORT.TYPES'})}
                        loadOptions={fetchTypesReport}
                        isSearchable={false}
                        defaultOptions
                        onChange={changeTypeReport}
                    />
                </FormGroup>

                <FormGroup>
                    <InputFlatPickrUi
                        className="form-control"
                        label={intl.formatMessage({ id: 'REPORT.SUBSCRIPTION_TIME' })}
                        value={manage.date}
                        placeholder="Choisir les dates...."
                        options={{
                            mode: 'range',
                            enableTime: true,
                            dateFormat: "Y-m-d H:i",
                        }}
                        onChange={(dates: Date[]) =>  changeDate(dates) }
                        // error={manage.subscription === undefined}
                    />
                </FormGroup>
            </CardBody>
        </CardUi>
    )
}
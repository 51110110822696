export const GET_START = '[EVENTS] GET_START';
export const GET_END = '[EVENTS] GET_END';
export const ERROR = '[EVENTS] ERROR';

export const SET_ACTIVE_START = '[EVENTS] SET_ACTIVE_START';
export const SET_ACTIVE_END = '[EVENTS] SET_ACTIVE_END';

export const TOOGLE_EVENTS = '[EVENTS] TOOGLE_EVENTS';
export const INIT_EVENTS = '[EVENTS] INIT_EVENTS';

export function GetEventsStart(payload: any): any {
    return { type: GET_START, payload };
}

export function getEventsEnd(payload: any): any {
    return { type: GET_END, payload };
}

export function Error(msg: string): any {
    return { type: ERROR, msg };
}

export function SetActiveStart(payload: any): any {
    return { type: SET_ACTIVE_START, payload };
}

export function SetActiveEnd(payload: any): any {
    return { type: SET_ACTIVE_END, payload };
}

export function ToogleEventsAction(payload?: boolean): any {
    return { type: TOOGLE_EVENTS, payload };
}

export function InitEvents(): any {
    return { type: INIT_EVENTS };
}

import { Container } from "reactstrap";
import { useIntl } from "react-intl";
import { useAppSelector } from "../../../redux/hooks";
import { useCallback, useState } from "react";
import { StopsPage } from "./steps/stops";
import { UnitsPage } from "./steps/vehicles";
import { GroupsRoadPage } from "./steps/groups";
import { BreadCrumbActionsUi } from "../../../Components/basic/BreadCrumbActionsUi";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { IF } from "../../../Components/If";
import { ResumeShift } from "./components/road-resume";
import { useSaveRoad } from "../../../common/hooks/axios/routes/useSaveRoad";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { useNavigate } from "react-router-dom";

export const RouteOptimizerPage = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.authReducer);
    const [ step, setStep ] = useState(0);
    const [ groups, setGroups ] = useState<Record<string, {label: string, value: string}[]>>({});
    const [ shiftDirection, setShiftDirection ] = useState<"start" | "end">("start");
    const [ period, setPeriod ] = useState<[Date, Date] | undefined>(undefined);
    const [ shiftStop, setShiftStop ] = useState<{label: string, value: string} | undefined>();
    const [ selectedUnits, setSelectedUnits ] = useState<{key: string, capacity: number}[]>([]);
    const { saveAsyncRoad, saveRoadLoading } = useSaveRoad({});

    const totalPassenger = useCallback(() => {
        let total = 0;
        groups?.other?.forEach((element: any) => {
            total+=element.totalItems
        });

        return total;
    }, [groups]);

    const onSelect = (units: {key: string, capacity: number}[]) => {
        setSelectedUnits(units)
    }

    const renderStep = useCallback(() => {
        switch(step) {
            case 0: 
                return <GroupsRoadPage default={groups} onChange={setGroups}/>;
            case 1: 
                return (
                    <StopsPage 
                        step={step} 
                        defaultPeriod={period}
                        defaultStop={shiftStop}
                        onChangeStep={setStep}
                        onChangeDirection={setShiftDirection}
                        onChangePeriod={setPeriod}
                        onChangeStop={setShiftStop}
                    > 
                        <ResumeShift 
                            groups={groups}
                            period={period}
                            direction={shiftDirection}
                            stop={shiftStop}
                            totalPassenger={totalPassenger()}
                        />
                    </StopsPage>);
            case 2:
                return <UnitsPage 
                            passengers={totalPassenger()}
                            step={step} 
                            onChangeStep={setStep} 
                            selectedUnits={selectedUnits}
                            onChangeUnits={onSelect}
                        >
                            <ResumeShift 
                                groups={groups}
                                period={period}
                                direction={shiftDirection}
                                stop={shiftStop}
                                totalPassenger={totalPassenger()}
                            />
                        </UnitsPage>;
        }
    }, [step, shiftDirection, period, shiftStop, JSON.parse(JSON.stringify(selectedUnits))]);

    const onClickAction = async () => { 
        switch(true) {
            case step === 2 && !!period : {
                if(period && selectedUnits.length && groups.other && shiftStop ) {
                    const saved = await saveAsyncRoad({
                        selectedUnits: selectedUnits,
                        shiftStop,
                        period,
                        shiftDirection,
                        groups: groups.other
                    });

                    if(!!saved.success?.insertedId) {
                        navigate(`/routes/${saved.success.insertedId}/details`)
                    }
                }
                break;
            } 
            case step === 0 && groups?.other.length > 0: {
                setStep(1);
                break;
            }
            case step === 1 && groups?.other.length > 0 && !!period && !!shiftStop?.value: {
                setStep(2);
                break;
            }

        }
    };

    const disableNext = !((step === 0 && groups?.other?.length > 0) || (step === 1 && groups?.other?.length > 0 && !!period && !!shiftStop?.value) || (step === 2 && groups?.other?.length > 0 && !!period && !!shiftStop?.value));
 

    const onPrevious = () => { 
        if(step > 0) { setStep(step - 1); }
    };

    return (
            <div id="road-page" className="page-content">
                <LoaderUi loading={saveRoadLoading} />
                <Container fluid>
                    <BreadCrumbActionsUi 
                        title={intl.formatMessage({ id: 'Optimisation des trajets' })} 
                        pageTitle={user?.company.name || ''} 
                    >
                        <>
                            <IF condition={step > 0}>
                                <ButtonUi color="info" onClick={onPrevious}>Précédent</ButtonUi>
                            </IF>
                            <ButtonUi className="ms-2" color="info" onClick={onClickAction} disabled={disableNext}>Suivant</ButtonUi>
                        </>
                    </BreadCrumbActionsUi>
                    { renderStep() }
                </Container>
            </div>

    );
}
import { GiButtonFinger } from 'react-icons/gi';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { formatDate } from '../../helpers/dateHelper';
import { NotificationType } from '../../pages/Notification/types';


type DriverComponentProps = {
    notification?: NotificationType,
    timezone?: string,
    onClick?: (key: string, action: any) => void
}
export const NotificationComponent = (props: DriverComponentProps) => {
    const chooseDriver = () => { }

    return(
        <div className="object-info py-2 px-3 d-flex align-items-center justify-content-between border-bottom" onClick={chooseDriver}>
        <div className="d-flex align-items-center">
            <IoIosNotificationsOutline size={24} className="me-3 text-info"/> 
            <div>
            <div className="d-flex">
                    <div>
                        <h6 >
                            { props.notification?.alarm }
                        </h6>
                    </div>
                </div>
                <label className="mb-0 text-muted fs-12 fw-normal">
                { props.notification?.unit }
                </label>
                <div className="d-flex">
                    <div>
                        <span className="text-muted fs-10">
                            {
                                props.notification?.date && props.timezone ? formatDate(props.notification?.date, props.timezone, 'DD/MM/YYYY HH:mm:ss') : '-'
                            }
                        </span>
                    </div>
                </div>
                
            </div>
        </div>

        
    </div>
    )
}
import classNames from "classnames";
import { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineUnorderedList } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CardHeader } from "reactstrap";
import { ButtonUi } from "../../Components/basic/ButtonUi";
import { CardUi } from "../../Components/basic/Card";
import { CardBodyUi } from "../../Components/basic/CardBodyUi";
import { LoaderUi } from "../../Components/basic/LoaderUi";
import { useAppSelector } from "../../redux/hooks";
import { SetOptionMapAction } from "../../redux/rootActions";
import { GeofenceList } from "./list";
import { ManageGeofence } from "./manage";

const GeofencePage = () => {
    const dispatch = useDispatch();

    const { _id } = useParams();
    const isManage = !!_id;

    const [hide, setHide] = useState<boolean>(false);
    const geofenceClassname = classNames({
        [`d-none`]: hide,
        'list-geofence': true
    });

    const {  loading } = useAppSelector((state) => state.geofenceModuleReducer);


    const toggleHide = () => {
        setHide(!hide);
        let padding = 0;
        if(hide) { padding = padding + 374; }

        dispatch(SetOptionMapAction({opt: 'padding', value: padding}))
    };


    return (
        <div className="page-content object-page">
            <div className="d-flex flex-row">
                <ButtonUi 
                    onClick={toggleHide}
                    className={hide ? '' : 'd-none'} color={"primary"} Icon={AiOutlineUnorderedList}
                />

                <CardUi className={geofenceClassname}>
                    <LoaderUi loading={loading}/>
                    <CardHeader className="d-flex justify-content-between align-items-center mb-0">
                        <h5 className="d-flex align-items-center mb-0 text-primary">
                            <AiOutlineUnorderedList className="me-2"/>
                            <span><FormattedMessage id={"GEOFENCE.LIST"}/></span>
                        </h5>
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={toggleHide}
                            onKeyDown={toggleHide}
                        >
                            <AiOutlineEyeInvisible size={20} />
                        </div>
                    </CardHeader>
                    
                    <CardBodyUi>
                        {
                            isManage ? <ManageGeofence /> : <GeofenceList />
                        }
                        
                    </CardBodyUi>
                    
                </CardUi> 
            </div>       
        </div>
    );

}

export default GeofencePage;
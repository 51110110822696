import React, { useEffect, useState } from "react";
import { GoPrimitiveDot } from "react-icons/go";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Row, Col} from "reactstrap";
import { Acl } from "../../../../Components/Acl";
import { Avatar } from "../../../../Components/basic/avatar/avatar";
import { BadgeUi } from "../../../../Components/basic/BadgeUi";
import { CardUi } from "../../../../Components/basic/Card";
import { CardBodyUi } from "../../../../Components/basic/CardBodyUi";
import { Timer } from "../../../../Components/Timer";
import { useAppSelector } from "../../../../redux/hooks";
import { ClearVehicleCreation } from "../../redux/vehicleModule.actions";


export const VehicleInfo = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const [activeNav, setActiveNav] = useState(0);
    const { user } = useAppSelector(
        (state) => state.authReducer
    );
    const { info } = useAppSelector(
        (state) => state.vehicleModuleReducer
    );


    useEffect(() => {
        return function cleanup() {
            dispatch(ClearVehicleCreation());
        };
    }, []);


    return (
        <React.Fragment>
            <CardUi>
                <CardBodyUi className="d-flex flex-column align-items-center">
                    <div className="mb-4">
                        <Avatar 
                            text={info?.name || ''} 
                            color="primary" 
                            size="lg"
                            rounded="rounded-circle"
                            thumbnail
                        />   
                    </div>
                    <h5 className="fs-16 mb-1">{info?.name} <GoPrimitiveDot className={info?.online ? "text-success" : "text-danger"}/></h5>
                    <p className="text-muted mb-0">{info?.type}</p>
                </CardBodyUi> 
            </CardUi>
            <Acl type="admin">
                <CardUi className="mt-3">
                    <CardBodyUi>
                        <>
                            <h6 className="text-muted">INFORMATION BOITIER</h6>
                            <Row className="mt-4">
                                
                                {
                                    user?.type === "admin" && info?.tracer && (
                                        <>
                                            <Col xs={6} className="mt-1">
                                                <div className="d-flex flex-column">
                                                    <label className="mb-1 text-muted fs-12">UID:</label>
                                                    <label className="fs-13 text-truncate">{info?.tracer.uid || '-'}</label>
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="d-flex flex-column">
                                                    <label className="mb-1 text-muted fs-12">Traceur:</label>
                                                    <div>
                                                        <BadgeUi color={"primary"}>{info?.tracer.name || '-'}</BadgeUi>
                                                    </div>
                                                    
                                                </div>
                                            </Col>
                                        </>
                                    )
                                }
                                <Col xs={6}>
                                    <div className="d-flex flex-column">
                                        <label className="mb-1 text-muted fs-12">Staut:</label>
                                        <div>
                                            {
                                                info?.active ? <BadgeUi color={"success"}>Active</BadgeUi> : <BadgeUi color={"danger"}>Inactif</BadgeUi>
                                            }
                                            
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="d-flex flex-column">
                                        <label className="mb-1 text-muted fs-12">Dernière communication:</label>
                                        <label className="fs-13">{
                                            info?.lastSent? <Timer date={info?.lastSent} lang={"fr"}></Timer> : '-'}</label>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </CardBodyUi>
                    
                </CardUi>
            </Acl>
            
            {/* <CardUi className="mt-3">
                <CardBodyUi>
                    <h6 className="text-muted">ALARMES</h6>
                    <div className="mt-4 badges-info">
                        <BadgeUi color="primary">Excès de vitesse</BadgeUi>
                        <BadgeUi color="primary">Zone</BadgeUi>
                        <BadgeUi color="primary">Carburant</BadgeUi>
                    </div> 
                </CardBodyUi>   
            </CardUi> */}
            {
                info?.groups && info?.groups.length > 0 && ( 
                    <CardUi className="mt-3">
                        <CardBodyUi>
                            <h6 className="text-muted">Groupes</h6>
                            <div className="mt-4 badges-info">
                                {
                                    info.groups.map((grp: string) => <BadgeUi color="info">{grp}</BadgeUi>)
                                }
                            </div> 
                        </CardBodyUi>   
                    </CardUi>)
            }

        </React.Fragment>
    );
}

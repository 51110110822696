import { MdBatteryFull, MdBattery90, MdBattery80, MdBattery60, MdBattery50, MdBattery30, MdBattery20, MdBatteryAlert } from 'react-icons/md';

export type ChargingIconProps = { battery: number, size?: number };

export default function ChargingIcon({battery, size=16}: ChargingIconProps) {
    if(battery >98) {
        return <MdBatteryFull size={size} color="green"/>
    }else if(battery >= 90) {
        return <MdBattery90 size={size} color="green"/>
    } else if(battery >= 80) {
        return <MdBattery80 size={size} color="green"/>
    }
    else if(battery >= 60) {
        return <MdBattery60 size={size} color="green"/>
    }
    else if(battery >= 50) {
        return <MdBattery50 size={size} color="green"/>
    }
    else if(battery >= 30) {
        return <MdBattery30 size={size} color="orange"/>
    }
    else if(battery >= 20) {
        return <MdBattery20 size={size} color="orange"/>
    } else {
        return <MdBatteryAlert size={size} color="red"/>
    } 
}

import { DriverInListType, DriverStartListType, SaveDriverType, UnitToAssignDriverInListType, UnitToAssignDriverType } from "./types";

export const CREATE_DRIVER_START = '[DRIVER] CREATE_DRIVER_START';
export type CreateDriverStartType = {
    type: typeof CREATE_DRIVER_START;
    payload: SaveDriverType;
};
export function CreateDriverStart(payload: SaveDriverType): CreateDriverStartType {
    return { type: CREATE_DRIVER_START, payload };
}

export const CREATE_DRIVER_END = '[DRIVER] CREATE_DRIVER_END';
export type CreateDriverEndType = {
    type: typeof CREATE_DRIVER_END;
    payload: SaveDriverType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateDriverEnd(payload: SaveDriverType & {
    _id: string;
    updatedBy: string;
    updatedAt: string;
    modifiedCount?: number;
}): CreateDriverEndType {
    return { type: CREATE_DRIVER_END, payload };
}

export const GET_START = '[DRIVER] GET_DRIVER_START';
export type GetDriverStartActionType = {
    type: typeof GET_START;
    payload: DriverStartListType;
};
export function GetDriverStart(payload: DriverStartListType): GetDriverStartActionType {
    return { type: GET_START, payload };
}

export const GET_END = '[DRIVER] GET_DRIVER_END';
export type GetEndActionType = {
    type: typeof GET_END;
    payload: { result: {list: DriverInListType[], total: number}, filter:  DriverStartListType};
};
export function GetDriverEnd(payload: { result: {list: DriverInListType[], total: number}, filter:  DriverStartListType}): GetEndActionType {
    return { type: GET_END, payload };
}

export const GET_DRIVER_UPDATE_START = '[DRIVER] GET_DRIVER_UPDATE_START';
export type GetDriverUpdateStartType = {
    type: typeof GET_DRIVER_UPDATE_START;
    payload: { _id: string };
};
export function GetDriverUpdateStart(payload: {
    _id: string;
}): GetDriverUpdateStartType {
    return { type: GET_DRIVER_UPDATE_START, payload };
}

export const GET_DRIVER_UPDATE_END = '[DRIVER] GET_DRIVER_UPDATE_END';
export type GetDriverUpdateEndType = {
    type: typeof GET_DRIVER_UPDATE_END;
    payload: { result: SaveDriverType };
};
export function GetDriverUpdateEnd(payload: {
    result: SaveDriverType;
}): GetDriverUpdateEndType {
    return { type: GET_DRIVER_UPDATE_END, payload };
}

export const DELETE_DRIVERS_START = '[DRIVER] DELETE_DRIVERS_START';
export type DeleteDriverStartType = {
    type: typeof DELETE_DRIVERS_START;
    payload: string[];
};
export function DeleteDriverStart(payload: string[]): DeleteDriverStartType {
    return { type: DELETE_DRIVERS_START, payload };
}

export const DELETE_DRIVERS_END = '[DRIVERS] DELETE_DRIVERS_END';
export type DeleteDriversEndType = {
    type: typeof DELETE_DRIVERS_END;
    payload: { deletedCount: number; keys: string[] };
};

export function DeleteDriverEnd(payload: { deletedCount: number, keys: string[] }): DeleteDriversEndType {
    return { type: DELETE_DRIVERS_END, payload};
}

export const BIND_DRIVERS_START = '[DRIVER] BIND_DRIVERS_START';
export type BindDriverStartType = {
    type: typeof BIND_DRIVERS_START;
    payload: { driver: string, unit: string};
};
export function BindDriverStart(payload: { driver: string, unit: string}): BindDriverStartType {
    return { type: BIND_DRIVERS_START, payload };
}

export const BIND_DRIVERS_END = '[DRIVERS] BIND_DRIVERS_END';
export type BindDriverEndType = {
    type: typeof BIND_DRIVERS_END;
    payload: { unitKey: string, unitName: string, driver: string };
};

export function BindDriverEnd(payload: { unitKey: string, unitName: string, driver: string }): BindDriverEndType {
    return { type: BIND_DRIVERS_END, payload};
}

export const UNBIND_DRIVERS_START = '[DRIVER] UNBIND_DRIVERS_START';
export type UnbindDriverStartType = {
    type: typeof UNBIND_DRIVERS_START;
    payload: { key: string};
};
export function UnbindDriverStart(payload: { key: string}): UnbindDriverStartType {
    return { type: UNBIND_DRIVERS_START, payload };
}

export const UNBIND_DRIVERS_END = '[DRIVERS] UNBIND_DRIVERS_END';
export type UnbindDriverEndType = {
    type: typeof UNBIND_DRIVERS_END;
    payload: { unAssignedDate: Date, type: any, key: string };
};

export function UnbindDriverEnd(payload: { unAssignedDate: Date, type: any, key: string }): UnbindDriverEndType {
    return { type: UNBIND_DRIVERS_END, payload};
}

export const GET_UNIT_TO_ASSIGN_START = '[DRIVER] GET_UNIT_TO_ASSIGN_START';
export type GetUnitToAssignDriverStartActionType = {
    type: typeof GET_UNIT_TO_ASSIGN_START;
    payload: UnitToAssignDriverType;
};
export function GetUnitToAssignDriverStart(payload: UnitToAssignDriverType): GetUnitToAssignDriverStartActionType {
    return { type: GET_UNIT_TO_ASSIGN_START, payload };
}

export const GET_UNIT_TO_ASSIGN_END = '[DRIVER] GET_UNIT_TO_ASSIGN_END';
export type GetUnitToAssignDriverEndActionType = {
    type: typeof GET_UNIT_TO_ASSIGN_END;
    payload: { result: {list: UnitToAssignDriverInListType[] }, filter:  UnitToAssignDriverType};
};
export function GetUnitToAssignDriverEnd(payload: { result: {list: UnitToAssignDriverInListType[], }, filter:  UnitToAssignDriverType}): GetUnitToAssignDriverEndActionType {
    return { type: GET_UNIT_TO_ASSIGN_END, payload };
}

export const ERROR = '[DRIVER] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};

export function ErrorDriver(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const CLEAR_MANAGE_DRIVER = '[DRIVER] CLEAR_MANAGE_DRIVER';
type ClearManageDriverActionType = {
    type: typeof CLEAR_MANAGE_DRIVER;
};
export function ClearManageDriverAction(): ClearManageDriverActionType {
    return { type: CLEAR_MANAGE_DRIVER };
}


export type DriverActionsType =
    | CreateDriverStartType
    | CreateDriverEndType
    | GetDriverStartActionType
    | GetEndActionType
    | GetDriverUpdateStartType
    | GetDriverUpdateEndType
    | ClearManageDriverActionType
    | DeleteDriverStartType
    | DeleteDriversEndType
    | BindDriverStartType
    | BindDriverEndType
    | UnbindDriverStartType
    | UnbindDriverEndType
    | GetUnitToAssignDriverStartActionType
    | GetUnitToAssignDriverEndActionType
    | ErrorActionType;


import { FaKey } from 'react-icons/fa';
import { IoWater } from 'react-icons/io5';

export type FuelProps = { fill: boolean, size?: number };

export default function FuelIcon({fill, size=11}: FuelProps): JSX.Element {

    return fill ? <IoWater style={{ fontSize: size, color: 'rgba(1, 147, 10, 1)' }} /> :
    <IoWater style={{ fontSize: size, color: 'red' }} />;
}

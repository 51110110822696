import classNames from "classnames";
import { FaCheck } from "react-icons/fa";
import { TbHandFinger, TbHandFingerOff } from "react-icons/tb";
import { FormattedMessage } from "react-intl";

type DriverAssignmentComponentType = {
    unit: {
        key: string,
        name: string,
        driver?: string | null,
    },
    active: boolean,
    onSelect?: (unitKey: string) => void
}

export const DriverAssignmentIcon = ({ selected, driver }: { selected: boolean, driver: boolean }) => {
    switch(true) {
        case selected:
            return <FaCheck className="text-light" size={22}/>
        case driver:
            return <TbHandFingerOff className="text-danger" size={22}/>
        default:
            return <TbHandFinger className="text-success" size={22}/>
    }
}

export const DriverAssignmentComponent = ({ unit, active, onSelect }: DriverAssignmentComponentType) => {
    
    const driverClassName = classNames(
        'driver-assign-info px-3 py-2 d-flex align-items-center mb-2 border rounded',
        { "opacity-50": !!unit.driver },
        { "free-to-use" : !unit.driver },
        { "selected": active },
    );

    const chooseDriver = () => {
        if(onSelect && (!unit.driver)) {
            onSelect(unit.key);
        }
    }
    
    return (
        <div className={driverClassName} onClick={chooseDriver}>
            <DriverAssignmentIcon selected={active} driver={!!unit.driver}/>
            <div className="ms-2">
                <h6 className={`${active ? "text-light" : ""} mb-0 user-select-none`}>{ unit.name }</h6>
                {
                    unit.driver ? 
                    <span className="text-muted fs-12 user-select-none"><FormattedMessage id="DRIVER.ASSIGNED_TO" />  : {unit.driver}</span> 
                    : <span className={`${active ? "text-light" : "text-success"} fs-12`}> 
                        <FormattedMessage id="DRIVER.FREE" /> 
                    </span>
                }
                
            </div>
        </div>
    );
}
import { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {CardBody, CardHeader, FormGroup } from "reactstrap";

import { CardUi } from "../../../Components/basic/Card";
import { AsyncInputSelect } from "../../../Components/Form/AsyncInputSelect";
import { InputSelect } from "../../../Components/Form/InputSelect";

import { useAppSelector } from "../../../redux/hooks";
import { fetchSubscriptionsByName } from "../../Billing/redux/billing.service";
import { SetCompanyStatusAction, SetCompanySubscriptionDateAction, SetCompanySubscriptionTypeAction } from "../redux/company.actions";
import { InputFlatPickrUi } from "../../../Components/Form/InputFlatPickr";

const STATUS = [
    { label: 'On', value: true },
    { label: 'Off', value: false },
];

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any}
}
export const Subscription = ({register, errors}: ManageCompanyPageType) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { manage, loading} = useAppSelector((state) => state.companyReducer);
       
    const changeSubscriptionSelection = (element: any): void => {
        dispatch(SetCompanySubscriptionTypeAction(element));
    };

    const [selectedOption, setSelectedOption] = useState<any>(undefined);

    const chageOperation = (element: any): void => {
        setSelectedOption(element);
        dispatch(SetCompanyStatusAction(element.value));
    };

    useEffect(() => {
        const defaultValue = STATUS.find(
            (option) => option.value === manage.active
        );
        setSelectedOption(defaultValue);
    }, [loading])
    

    return (
        <CardUi>
        <CardHeader>
            <h4 className='card-title mb-0'>{intl.formatMessage({ id: 'COMPANY.SUBSCRIPTION'})}</h4>
        </CardHeader>
        
        {
           !loading ? (
            <CardBody>
                <FormGroup>
                    <AsyncInputSelect 
                        label={intl.formatMessage({ id: 'COMPANY.SUBSCRIPTION_TYPE'})}
                        cacheOptions
                        defaultOptions
                        defaultValue={manage.subscriptionType}
                        loadOptions={fetchSubscriptionsByName}
                        onChange={changeSubscriptionSelection}
                        error={manage.subscriptionType === undefined}
                    />
                </FormGroup>
                <FormGroup>
                    <InputSelect
                        label={intl.formatMessage({ id: 'COMPANY.STATUS' })}
                        value={selectedOption}
                        onChange={chageOperation}
                        options={STATUS}
                    />
                </FormGroup>
                <FormGroup>
                    <InputFlatPickrUi
                        className="form-control"
                        label={intl.formatMessage({ id: 'COMPANY.SUBSCRIPTION_TIME' })}
                        value={manage.subscription}
                        options={{
                            dateFormat: "d M, Y",
                            minDate: new Date()
                        }}
                        onChange={(date: Date[]) =>  dispatch(SetCompanySubscriptionDateAction(date[0].getTime())) }
                        error={manage.subscription === undefined}
                    />
                </FormGroup>
            </CardBody>
           )  : <div>Loading</div>
        }
    </CardUi>             
    );
}
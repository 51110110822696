 import { FaChevronRight } from "react-icons/fa";
import { InputSearch } from "../Form/InputSearch";
import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../context/layout-style-context";
import { GroupItem } from "./group-item";
import { ActionTableDropdown } from "../Dropdown/TableActionDropdown";
import { IconType } from "react-icons";
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus, AiOutlineSetting } from "react-icons/ai";
import { useFetchGroups } from "../../common/hooks/axios/group/useFetchGroups";
import { LoaderUi } from "../basic/LoaderUi";
import { useNavigate } from "react-router-dom";
import Emitter from "../../helpers/emitter";
import EmitterActions from "../../helpers/emitter/emitter.actions";


type GroupManagementProps = {
    type: "passenger" | "stop",
    minusHeight?: number,
    children: any,
    onClickAction: (action: string, _ids: string[]) => void,
    onSelect: (key: string | null) => void,
}

export const GroupManagement = (props: GroupManagementProps) => {
    const { groups, fetchGroups, fetchGroupsLoading }= useFetchGroups({manual: false, type: props.type})
    const navigate = useNavigate();
    const { height } = useContext(LayoutContext);
    const [ active, setActive ] = useState(false);
    const [ activeGroup, setActiveGroup ] = useState<GroupItem | null>(null);
    const groupBodyClassName = classNames(
        "group-body px-3  bg-white rounded overflow-auto",
        { 
            active,
            "border": active,
        }
    )
    const groupHeaderClassName = classNames(
        "group-header d-flex flex-row justify-content-between px-3 py-2",
        {  active, }
    )

    const getActions = useCallback((key: string): ({
        Icon: IconType;
        name: string;
        action: string;
    } | {
        name: 'divier', action?: string, Icon?: IconType,
    })[] => {
        let actions: any = [
            {
                Icon: AiOutlinePlus,
                name: "Ajouter un nouveau groupe",
                action: 'new'
            }
        ];

        if(key !== 'all') {
            actions = [...actions, ...[{
                name: 'divier'
            },
            {
                Icon: AiOutlineEdit,
                name: "Modifier le groupe",
                action: 'edit'
            },
            {
                Icon: AiOutlineDelete,
                name: "Supprimer le groupe",
                action: 'delete'
            }]]
        }

        return actions;
    }, []);

    const onSelect = (group: GroupItem) => {
        if(group.key === "all") {
            setActiveGroup(group);
            props.onSelect(null);
        } else {
            setActiveGroup(group);
            props.onSelect(group.key);
        }
        
        setActive(false);
    }

    const onSearch = ({searchBy}: {searchBy: string}) => {
        fetchGroups({name: searchBy});
    }

    const getDefaultGroupName = useCallback(() => {
        switch(props.type) {
            case 'passenger': 
                return "Tous les passagers";
            case 'stop': 
                return "Tous les arrêts";
            default: 
            return ''
        }
    }, [props.type]);

    const gotToGroup = (data: any) => {
        fetchGroups();
        setActiveGroup(data);
        props.onSelect(data.key);
        navigate(`/${props.type}s`);
    }

    useEffect( () => {
        Emitter.on(EmitterActions["GROUP:GO_TO"], gotToGroup);
        return () => {
            Emitter.off(EmitterActions["GROUP:GO_TO"], gotToGroup);
        };
    }, []);

    return(    
        <div className="group border-bottom position-relative">
            <div className={groupHeaderClassName}>
                <div className="d-flex flex-row align-items-center cursor-pointer" onClick={()=> setActive(!active)}>
                    <FaChevronRight className="text-primary chevron-icon"/> 
                    <label className="mb-0 ms-2 fs-13 text-primary">{activeGroup ? `${activeGroup.name}`: getDefaultGroupName()}</label>
                </div>
                <ActionTableDropdown 
                    _id={activeGroup?.key || 'all'} 
                    onChange={props.onClickAction} 
                    options={getActions(activeGroup?.key || 'all')}
                    Icon={AiOutlineSetting}
                />
            </div>
            <div className={groupBodyClassName} style={{zIndex: 99, top: 38, height: active ? (height - (props.minusHeight ?? 0) ): 0}}>
                <LoaderUi loading={fetchGroupsLoading} />
                <div className="mt-2">
                    <InputSearch
                        type="text"
                        name={'searchBy'}
                        placeholder={"Chercher le groupe par leur nom"}
                        size="sm"
                        onSubmit={onSearch}
                    />
                </div>
                
                <div className="mt-3">
                    <GroupItem  group={{key: 'all', name: getDefaultGroupName(), totalItems: 0}} onSelect={onSelect} active={'all' === activeGroup?.key || !activeGroup}/>
                   { groups.map(group => <GroupItem key={group.key} group={group} onSelect={onSelect} active={group.key === activeGroup?.key}/>) }
                </div>
            </div>

            { props.children }
        </div>
    );
}



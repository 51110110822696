import { useAxios } from "../../../../common/hooks/axios/useAxios";
import { NotificationType } from "./type";



export type FetchNotificationProps = {
  name?: string,
  "pagination[skip]"?: number;
  "pagination[limit]"?: number; 
}


type UseFetchNotificationResponse = { 
  notifications: { data: NotificationType[], total: number };
  fetchNotificationsLoading: boolean;
  error?: string;
  fetchNotifications: (params?: FetchNotificationProps) => void;
  fetchAsyncNotifications: (params?: FetchNotificationProps) => Promise<{
    success: { data: NotificationType[], total: number };
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}>;
  cancel: () => void;
}

type UseFetchNotificationsProps = {
  manual?: boolean,
  skip?: number,
  limit?: number,
}

export function useFetchNotifications(props: UseFetchNotificationsProps): UseFetchNotificationResponse {
  const URL = `notifications`;
  const { execute, data, cancel, error, loading, init } = useAxios<{data: any[], total: number}>(props.manual);

  const fetchNotifications = (params?: FetchNotificationProps) => {
    execute(URL, { method: "GET", params});
  };

  const fetchAsyncNotifications = async (params?: FetchNotificationProps): Promise<{
    success: { data: NotificationType[], total: number };
    error?: undefined;
} | {
    error: any;
    success?: undefined;
}> => {
    return execute(URL, { method: "GET", params});
  };

  return { 
    notifications: data ?? {data: [], total: 0},
    fetchNotificationsLoading: loading, 
    error,
    fetchNotifications,
    fetchAsyncNotifications,
    cancel, 
  };
}

import classNames from "classnames";
import { GiStopSign } from "react-icons/gi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { IoWater } from "react-icons/io5";
import { MdTimeline } from "react-icons/md";
import { useDispatch } from "react-redux";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { ToogleEventsAction } from "../../../pages/Map/Object/Event/_redux/eventActions";
import { ToogleFuelAction } from "../../../pages/Map/Object/Fuel/_redux/fuelActions";
import { ToogleParkingAction, ToogleTrajectAction } from "../../../pages/Map/Object/History/_redux/historyActions";
import { useAppSelector } from "../../../redux/hooks";

export function DetailsActionMapControl(): JSX.Element | null {
    const dispatch = useDispatch();
    const { showParking, showTraject } = useAppSelector((state) => state.historyReducer);
    const { showEvents } = useAppSelector((state) => state.eventsObjectReducer);
    const { showFuel } = useAppSelector((state) => state.fuelReducer);
    const { vehicle } = useAppSelector((state) => state.ObjectReducer);

    const showParkingClassName = classNames({
        'me-1': true,
        'active': !showParking
    });

    const showTrajectClassName = classNames({
        'me-1': true,
        'active': !showTraject
    });

    const showEventsClassName = classNames({
        'me-1': true,
        'active': !showEvents
    });

    const showFuelClassName = classNames({
        'me-1': true,
        'active': !showFuel
    });

    //   const ToogleParking = () =>  dispatch(ToogleParkingAction());
      const ToogleTraject = () =>  dispatch(ToogleTrajectAction());
      const ToogleEvents = () =>  dispatch(ToogleEventsAction());
      const ToogleFuel = () =>  dispatch(ToogleFuelAction());

    if(vehicle)
        return (
            <div className="details-action-map-control">
                <div className="d-flex flex-row">
                    {/* <ButtonUi className={showParkingClassName} color={"default"} onClick={ToogleParking}>
                        <GiStopSign size={18}/>
                    </ButtonUi> */}
                    <ButtonUi className={showTrajectClassName} color={"default"} onClick={ToogleTraject}>
                        <MdTimeline size={18} />
                    </ButtonUi>
                    <ButtonUi className={showEventsClassName} color={"default"} onClick={ToogleEvents}>
                        <IoMdNotificationsOutline size={18}/>
                    </ButtonUi>
                    <ButtonUi className={showFuelClassName} color={"default"} onClick={ToogleFuel}>
                        <IoWater size={18} />
                    </ButtonUi>
                </div>
            </div>
        );
    return null;
}

import {
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { VerticalLayout } from '../Layouts/VerticalLayout';
import { EcoPage } from '../pages/Eco';
import MaintenancePage from '../pages/Maitenance';
import AlarmPage from '../pages/Alarms';
import ManageAlarmPage from '../pages/Alarms/manage';
import BillingPage from '../pages/Billing';
import ManageBillingPage from '../pages/Billing/manage';
import CompanyPage from '../pages/Company';
import ManageCompanyPage from '../pages/Company/ManageCompany/ManageCompany';
import DriverPage from '../pages/Driver';
import GeofencePage from '../pages/Geofence';
import ManageMaintenancePage from '../pages/Maitenance/Manage';
import MapPage from '../pages/Map';
import SharePositionPage from '../pages/SharePosition';
import ManageSharePositionPage from '../pages/SharePosition/manage';
import TracerPage from '../pages/Tracers';
import AddTracerPage from '../pages/Tracers/manage/AddTracerPage';
import UserPage from '../pages/User';
import CreateUserPage from '../pages/User/manage/CreateUser';
import VehiclePage from '../pages/Vehicles';
import DashboardPage from '../pages/Dashboard';
import AddVehiclePage from '../pages/Vehicles/manage/AddVehiclePage';
import UpdateVehiclePage from '../pages/Vehicles/manage/UpdateVehiclePage';
import NotificationPage from '../pages/Notification';
import { IncidentPage } from '../pages/Incident/incident';
import { ManageIncidentPage } from '../pages/Incident/manage-incident';
import ProfilePage from '../pages/Profile';
import ReportPage from '../pages/Report/instanuous';
import PassengerPage from '../pages/Passengers';
import StopListPage from '../pages/Stops/stop-list.page';
import { ManageManualStopPage } from '../pages/Stops/manage-stop-manual.page';
import DetailsPassengerPage from '../pages/Passengers/details-passenger';
import ReportAsyncPage from '../pages/Report/async';
import { ViewReportPage } from '../pages/Report/async/view-report';
import ManageReportPage from '../pages/Report/async/Manage';
import HistoryPassengerPage from '../pages/Passengers/history-passenger';
import { RoutesPage } from '../pages/Routes';
import { RoutesDetailsPage } from '../modules/trip-optimizer/Routes/routes-details.page';
import { RouteOptimizerPage } from '../modules/trip-optimizer/Routes/routes.page';

type AppPagesProps = {
    accountActive : boolean
}
export function AppPages({accountActive}: AppPagesProps): JSX.Element {
    return (
        <VerticalLayout>
            {
                !accountActive ? 
                    <Routes>
                        <Route path="/profile" element={<ProfilePage/>}/>
                        <Route path="*" element={<Navigate to="/profile" replace />} />
                    </Routes>
                    :
                    
                    <Routes>
                        {/* <Route path="/dev" element={<RoutesDetailsPage />} /> */}
                    <Route path="/dashboard" element={<DashboardPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/map" element={<MapPage/>}/>
                    <Route path="/map/vehicle/:vehicleKey" element={<MapPage/>}/>
                    <Route path="/drivers" element={<DriverPage/>}/>
                    <Route path="/drivers/:key/:page" element={<DriverPage/>}/>
                    <Route path="/drivers/:key/:page/:action" element={<DriverPage/>}/>
                    
                    <Route path="/drivers/:driverKey/assign" element={<DriverPage/>}/>
                    <Route path="/drivers/:driver/shift" element={<DriverPage/>}/>
                    <Route path="/drivers/:driver/shift/:action" element={<DriverPage/>}/>
                    <Route path="/drivers/:_id" element={<DriverPage/>}/>
                    
                    <Route path="/passengers" element={<PassengerPage/>}/>
                    <Route path="/passenger-groups" element={<PassengerPage/>}/>
                    <Route path="/passenger-groups/:key" element={<PassengerPage/>}/>
                    <Route path="/passengers/:key/details" element={<DetailsPassengerPage/>}/>
                    <Route path="/passengers/:key/history" element={<HistoryPassengerPage/>}/>
                    
                    <Route path="/stops" element={<StopListPage />}/>
                    <Route path="/stop-groups" element={<StopListPage/>}/>
                    <Route path="/stop-groups/:key" element={<StopListPage/>}/>
                    <Route path="/stops/add-manual" element={<ManageManualStopPage />}/>
                    <Route path="/stops/:key/update" element={<ManageManualStopPage />}/>

                    <Route path="/routes" element={<RoutesPage />} />
                    <Route path="/routes/optimize" element={<RouteOptimizerPage />} />
                    <Route path="/routes/:key/details" element={<RoutesDetailsPage />} />
                    
                    <Route path='/geofences' element={<GeofencePage/>}/>
                    <Route path='/geofences/:_id' element={<GeofencePage/>}/>
                    
                    <Route path="/companies" element={<CompanyPage/>}/>
                    <Route path="/companies/:_id" element={<ManageCompanyPage/>}/>

                    <Route path="/users" element={<UserPage/>}/>
                    <Route path="/users/:_id" element={<CreateUserPage/>}/>

                    <Route path="/units" element={<VehiclePage/>}/>
                    <Route path="/units/add" element={<AddVehiclePage/>}/>
                    <Route path="/units/:_id" element={<UpdateVehiclePage/>}/>

                    <Route path="/tracers" element={<TracerPage/>}/>
                    <Route path="/tracers/:_id" element={<AddTracerPage/>}/>
                    
                    <Route path="/alarms" element={<AlarmPage/>}/>
                    <Route path="/alarms/:_id" element={<ManageAlarmPage/>}/>
                    <Route path="/eco/:_id" element={<EcoPage/>}/>
                    {/* <Route path="/drivers" element={<DriverPage/>}/>
                    <Route path="/drivers/:_id" element={<CreateDriverPage/>}/> */}

                    <Route path="/share" element={<SharePositionPage/>}/>
                    <Route path="/share/:_id" element={<ManageSharePositionPage/>}/>

                    <Route path="/maintenance" element={<MaintenancePage/>}/>
                    <Route path="/maintenance/add" element={<ManageMaintenancePage/>}/>
                    <Route path="/maintenance/:groupId/:maintenanceId" element={<ManageMaintenancePage/>}/>
                    <Route path="/maintenance/:groupId" element={<MaintenancePage/>}/>

                    <Route path="/instant-reports" element={<ReportPage/>}/>
                    <Route path="/reports" element={<ReportAsyncPage/>}/>
                    <Route path="/reports/add" element={<ManageReportPage/>}/>
                    <Route path="/reports/:_id" element={<ViewReportPage/>}/>


                    <Route path="/subscription" element={<BillingPage/>}/>
                    <Route path="/subscription/:_id" element={<ManageBillingPage/>}/>

                    <Route path="/notifications" element={<NotificationPage/>}/>

                    <Route path="/incident" element={<IncidentPage/>}/>
                    <Route path="/incident/:_id" element={<ManageIncidentPage/>}/>

                    
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Routes>
            }

        </VerticalLayout>
    );
}

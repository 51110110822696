export enum DriverAction {
    // DELETE = "DELETE",
    // EDIT = "EDIT",
    // UNBIND = "UNBIND",
    // BIND = "BIND",
    // ECO = "ECO",
    // HISTORY = "HISTORY",
    SEARCH_UNIT = "SEARCH_UNIT",
    DETAILS = "DETAILS",
    UNBIND = "UNBIND",
    BIND = "BIND"
}
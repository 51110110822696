import { combineEpics } from 'redux-observable';
import { CreateEventEpic, GetEventsEpic, DeleteEventsEpic, UpdateEventsStatusEpic, GetEventEpic, AssignVehiclesToAlarmEpic } from '../pages/Alarms/redux/events.epics';
import { LogInEpic, LogInEndEpic, SwitchToMainUserEpic, SwitchUserEpic, LogOutEpic, LoginGenerateToken } from '../pages/Authentification/_redux/authEpics';
import { GetCompanysEpic, CreateCompanyEpic, GetCompanyEpic, SwitchCompanyEpic } from '../pages/Company/redux/company.epics';
import { FetchEventsEpics, SetActiveEventEpic } from '../pages/Map/Object/Event/_redux/eventEpics';
import { FetchFuelEpics, SetActiveFuelEpic, FetchChartFuelEpics } from '../pages/Map/Object/Fuel/_redux/fuelEpics';
import { FetchHistoryEpic, FetchParkingEpic, FetchTrajectStartEpic, SetActiveTrajectEpic } from '../pages/Map/Object/History/_redux/historyEpic';
import { FetchVehiclesEpic, FetchVehiclesShareEpic, FitBoundsEpic } from '../pages/Map/Objects/_redux/unit-list.epics';
import { CreateShareEpic, DeleteSharesEpic, GetSharesEpic, GetShareEpic } from '../pages/SharePosition/redux/share.epics';
import { GetUsersEpic, GetUserRoles, CreateUserEpic, GetUserEpic, DeleteUsersEpic, AssignVehiclesToUserEpic } from '../pages/User/redux/user.epics';
import { CreateSensorEpic, CreateVehicleEpic, DeleteSensorsEpic, DeleteVehiclesEpic, GetSensorEpic, GetSensorsEpic, GetVehicleEpic, GetVehiclesEpic, UpdateSensorTiming, UpdateTripEpic } from '../pages/Vehicles/redux/vehicleModule.epics';
import { GetBillingsEpic, GetBillingEpic, CreateBillingEpic, DeleteBillingsEpic } from '../pages/Billing/redux/billing.epics';
import { GetShareTokenEpic } from '../pages/SharedMap/redux/sharedMap.epics';
import { CreateTracerEpic, DeleteTracersEpic, GetTracerEpic, GetTracersEpic } from '../pages/Tracers/redux/tracer.epics';
import { BindDriverEpic, DeleteDriverEpic, GetDriverEpic, GetDriversEpic, GetUnitToAssignDriverEpic, SaveDriverEpic, UnbindDriverEpic } from '../pages/Driver/_redux/driverModule.epics';
import { GetMaintenanceVehicleInfoEpic, SaveMaintenanceEpic, GetMaintenanceGroupsEpic, GetMaintenanceListEpic, DeleteMaintenanceEpic, GetMaintenanceEpic } from '../pages/Maitenance/_redux/maintenance.epics';
import { CreateGeofenceEpic, DeleteGeofencesEpic, GetGeofenceEpic, GetGeofencesEpic } from '../pages/Geofence/_redux/geofenceModule.epics';
import { FetchVisibleSensorEpic } from '../pages/Map/Object/Details/_redux/unit.epics';
import { DownloadReportEpic, GenerateReportEpic, GetReportsEpic } from '../pages/Report/async/redux/report.epics';


export const rootEpics = combineEpics(
    LogInEpic,
    LogInEndEpic,
    LogOutEpic,
    LoginGenerateToken,

    // Company
    GetCompanysEpic,
    CreateCompanyEpic,
    GetCompanyEpic,
    SwitchCompanyEpic,

    // User
    SwitchUserEpic,
    SwitchToMainUserEpic,
    GetUsersEpic,
    GetUserRoles,
    CreateUserEpic,
    GetUserEpic,
    DeleteUsersEpic,
    AssignVehiclesToUserEpic,

    //Vehicles
    CreateVehicleEpic,
    DeleteVehiclesEpic,
    GetVehicleEpic,
    GetVehiclesEpic,
    FetchVehiclesShareEpic,
    UpdateTripEpic,

    FetchVisibleSensorEpic,
    
    // Sensors
    CreateSensorEpic,
    GetSensorsEpic,
    DeleteSensorsEpic,
    GetSensorEpic,
    UpdateSensorTiming,

    // Alarms
    CreateEventEpic,
    GetEventsEpic,
    DeleteEventsEpic,
    UpdateEventsStatusEpic,
    GetEventEpic,
    AssignVehiclesToAlarmEpic,

    //drivers
    SaveDriverEpic,
    GetDriversEpic,
    GetDriverEpic,
    DeleteDriverEpic,
    UnbindDriverEpic,
    GetUnitToAssignDriverEpic,
    BindDriverEpic,

    // share positions
    CreateShareEpic,
    DeleteSharesEpic,
    GetSharesEpic,
    GetShareEpic,

    // Map shared positions
    GetShareTokenEpic,
    
    // Map
    FetchVehiclesEpic,
    FitBoundsEpic,

    // Hhistory
    FetchHistoryEpic,
    FetchTrajectStartEpic,
    SetActiveTrajectEpic,
    FetchParkingEpic,

    // Events
    FetchEventsEpics,
    SetActiveEventEpic,

    // Fuel
    FetchFuelEpics,
    SetActiveFuelEpic,
    FetchChartFuelEpics,

    // Trip

    // Maintenance
    GetMaintenanceVehicleInfoEpic,
    SaveMaintenanceEpic,
    GetMaintenanceGroupsEpic,
    GetMaintenanceListEpic,
    DeleteMaintenanceEpic,
    GetMaintenanceEpic,

    // Billings
    GetBillingsEpic,
    GetBillingEpic,
    CreateBillingEpic,
    DeleteBillingsEpic,

    // Tracers
    GetTracersEpic,
    GetTracerEpic,
    CreateTracerEpic,
    DeleteTracersEpic,

    // Geofences
    GetGeofencesEpic,
    CreateGeofenceEpic,
    DeleteGeofencesEpic,
    GetGeofenceEpic,

    // Report async
    GenerateReportEpic,
    GetReportsEpic,
    DownloadReportEpic,
);


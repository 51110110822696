import { lazy, Suspense } from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import { SplashScreen } from '../Components/SplashScreen';
import { RoutesDetailsPage } from '../modules/trip-optimizer/Routes/routes-details.page';


const AuthentificationPage = lazy(() => import('../pages/Authentification'));
const SharedMap = lazy(() => import('../pages/SharedMap'));
export function PublicPages(): JSX.Element {
    return (
        <Routes>
            <Route path="/dev" element={<RoutesDetailsPage />} />
            <Route path="/shared-objects/:_id" element={<Suspense fallback={<SplashScreen/>} ><SharedMap/></Suspense>}/>
            <Route path="*" element={<Suspense fallback={<SplashScreen/>} ><AuthentificationPage/></Suspense>}/>
        </Routes>
    );
}
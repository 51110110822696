import { useEffect } from "react";
import { useAxios } from "../../useAxios";

type GroupsToAssignResult = {
    key: string;
    group: string;
    data: { label: string, value: string }[];
}

type useFetchToAssignResponse = { 
  groupsToAssign: GroupsToAssignResult[];
  groupsToAssignLoading: boolean;
  error?: string;
  fetchGroupsToAssign: (inputValue: string) => void;
  cancel: () => void;
}

type useFetchGroupsToAssignProps = {
  manual?: boolean
  type: "passenger"
}
export function useFetchGroupsToAssign(props: useFetchGroupsToAssignProps): useFetchToAssignResponse {
  const URL = `groups/${props.type}/to-assign`;
  const INIT_VALUE: GroupsToAssignResult[] = [];
  const { execute, data, cancel, error, loading } = useAxios<GroupsToAssignResult[]>();

  useEffect(() => {
    fetchGroupsToAssign();
  }, [])

  const fetchGroupsToAssign = (inputValue?: string) => {
    execute(URL, { method: "GET", params: { name: inputValue } });
  };

  return { 
    groupsToAssign: data ?? INIT_VALUE, 
    fetchGroupsToAssign, 
    cancel, 
    groupsToAssignLoading: loading, 
    error: error
  };
}

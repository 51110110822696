import { IF } from "../If";
import { ButtonUi } from "../basic/ButtonUi"
import { TbCheck, TbBox } from "react-icons/tb";

export type GroupItem = {key: string, totalItems: number, name: string};
type GroupItemProps = {
    active?: boolean,
    group: GroupItem,
    onSelect?: (group: GroupItem) => void;
}
export const GroupItem = (props: GroupItemProps) => {

    const onSelect = () => {
        if(props.onSelect) { props.onSelect(props.group) }
    };

    return(   
        <div className="d-flex justify-content-between align-items-center py-2">
            <div className="d-flex align-items-center">
                {
                    props.active ? <TbCheck size={18} className="me-1"/> : <TbBox size={18} className="me-1"/>
                }
                <label className="mb-0 ms-2 fs-13 form-label">{props.group.name} {
                    props.group.key !== 'all' && `(${props.group.totalItems})`
                }</label>
            </div>
            <IF condition={!props.active}>
                <ButtonUi 
                    className="action fw-bold" 
                    color={"primary"}
                    size="sm" 
                    ghost
                    onClick={onSelect}
                >
                    Choisir
                </ButtonUi>
            </IF>
        </div>               
    );
}



import { FilterTracerType, GetTracersResponse, ManageTracerType } from "../types";

export const ERROR = '[TRACER] ERROR';
type ErrorActionType = {
    type: typeof ERROR;
    payload: string;
};
export function ErrorTracer(payload: string): ErrorActionType {
    return { type: ERROR, payload };
}

export const SET_SELECTED_TRACERS = '[TRACER] SET_SELECTED_TRACERS';
export type SetSelectedTracersType = {
    type: typeof SET_SELECTED_TRACERS;
    payload: string[];
};
export function SetSelectedTracers(
    payload: string[]
): SetSelectedTracersType {
    return { type: SET_SELECTED_TRACERS, payload };
}

export const GET_TRACERS_START = '[TRACER] GET_TRACERS_START';
export type GetTracersStartType = {
    type: typeof GET_TRACERS_START;
    payload: FilterTracerType;
};
export function GetTracersStart(payload: FilterTracerType): GetTracersStartType {
    return { type: GET_TRACERS_START, payload };
}

export const GET_TRACERS_END = '[TRACER] GET_TRACERS_END';
export type GetTracersEndType = {
    type: typeof GET_TRACERS_END;
    payload: { result: GetTracersResponse; filter: FilterTracerType };
};
export function GetTracersEnd(payload: {
    result: GetTracersResponse;
    filter: FilterTracerType;
}): GetTracersEndType {
    return { type: GET_TRACERS_END, payload };
}

export const CREATE_TRACER_START = '[TRACER] CREATE_TRACER_START';
export type CreateTracerStartType = {
    type: typeof CREATE_TRACER_START;
    payload: any;
};
export function CreateTracerStart(payload: any): CreateTracerStartType {
    return { type: CREATE_TRACER_START, payload };
}

export const CREATE_TRACER_END = '[TRACER] CREATE_TRACER_END';
export type CreateTracerEndType = {
    type: typeof CREATE_TRACER_END;
    payload: ManageTracerType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    };
};
export function CreateTracerEnd(
    payload: ManageTracerType & {
        _id: string;
        updatedBy: string;
        updatedAt: string;
        modifiedCount?: number;
    }
): CreateTracerEndType {
    return { type: CREATE_TRACER_END, payload };
}

export const DELETE_TRACERS_START = '[TRACER] DELETE_TRACERS_START';
export type DeleteTracersStartType = {
    type: typeof DELETE_TRACERS_START;
    payload: string[];
};
export function DeleteTracersStart(payload: string[]): DeleteTracersStartType {
    return { type: DELETE_TRACERS_START, payload };
}

export const DELETE_TRACERS_END = '[TRACER] DELETE_TRACERS_END';
export type DeleteTracersEndType = {
    type: typeof DELETE_TRACERS_END;
    payload: { deletedCount: number; _ids: string[] };
};

export function DeleteTracersEnd(payload: { deletedCount: number; _ids: string[] }): DeleteTracersEndType {
    return { type: DELETE_TRACERS_END, payload };
}

export const GET_TRACER_UPDATE_START = '[TRACER] GET_TRACER_UPDATE_START';
export type GetTracerUpdateStartType = {
    type: typeof GET_TRACER_UPDATE_START;
    payload: { _id: string };
};
export function GetTracerUpdateStart(payload: {
    _id: string;
}): GetTracerUpdateStartType {
    return { type: GET_TRACER_UPDATE_START, payload };
}

export const GET_TRACER_UPDATE_END = '[TRACER] GET_TRACER_UPDATE_END';
export type GetTracerUpdateEndType = {
    type: typeof GET_TRACER_UPDATE_END;
    payload: { result: ManageTracerType };
};
export function GetTracerUpdateEnd(payload: {
    result: ManageTracerType;
}): GetTracerUpdateEndType {
    return { type: GET_TRACER_UPDATE_END, payload };
}

export const CLEAR_TRACER_CREATION = '[TRACER] CLEAR_TRACER_CREATION';
export type ClearTracerCreationActionType = {
    type: typeof CLEAR_TRACER_CREATION;
};
export function ClearTracerCreation(): ClearTracerCreationActionType {
    return { type: CLEAR_TRACER_CREATION };
}
export type TracerActionType =
    | GetTracersStartType
    | GetTracersEndType
    | SetSelectedTracersType
    | CreateTracerStartType
    | CreateTracerEndType
    | DeleteTracersStartType
    | DeleteTracersEndType
    | GetTracerUpdateStartType
    | GetTracerUpdateEndType
    | ClearTracerCreationActionType
    | ErrorActionType;

import isEmail from "validator/lib/isEmail";
import isMobilePhone, { MobilePhoneLocale } from "validator/lib/isMobilePhone";

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  export const emailValidator = (required: boolean) => {
    const validation: {required?: {value:boolean, message:string}, validate: {isEmail: (input: string)=> boolean | string}} = {
        validate: {
            isEmail: (input: string) => input && input.length > 0 ? (isEmail(input)  || "Ce champs doit être un email : example@provider.com") : true
        }
      }
    if(required) {
        validation.required = {
            value: true,
            message: 'Ce champs est obligatoire'
        };
    }
    return validation;
  }

  export const mobileValidator = (required: boolean, local: MobilePhoneLocale) => {
    const validation: {required?: {value:boolean, message:string}, validate: {isMobilePhone: (input: string)=> boolean | string}} = {
        validate: {
          isMobilePhone: (input: string) => input && input.length > 0 ? (isMobilePhone(input, local)  || "Ce champs doit être un numèros de téléphone : 0787888888") : true
        }
      }
    if(required) {
        validation.required = {
            value: true,
            message: 'Ce champs est obligatoire'
        };
    }
    return validation;
  }
  export const numberValidator = (required: boolean, min: number, max: number) => {
    const validation: any = {
      validate: {
        min: (input: string) => input.length > 0  && +input > min ? true : `Ce champs doit contenir un nombre superrieur à ${min}`,
        max: (input: string) => input.length > 0  && +input <= max ? true : `Ce champs doit contenir un nombre inférrieur à ${max+1}`,
    }
    };

    if(required) {
        validation.required = {
            value: true,
            message: 'Ce champs est obligatoire'
        };
    }
    return validation;
  }


  export const passwordValidator = (required: boolean) => {
    const validation: any = {
      validate: {
          hasNumber: (input: string) => input && input.length > 0 ? ( /\d/.test(input)) || "Ce champs doit contenir un nombre": true,
          hasUpper: (input: string) => input && input.length > 0 ? (/[A-Z]/.test(input)) || "Ce champs doit contenir une lettre en majuscule": true,
          hasLower: (input: string) => input && input.length > 0 ? (/[a-z]/.test(input)) || "Ce champs doit contenir une lettre en miniscule" : true,
      }
    }
      if(required) {
        validation.required = {
            value: true,
            message: 'Ce champs est obligatoire'
        };
    }
    return validation;

}
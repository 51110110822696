import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { ButtonUi } from './ButtonUi';

type BreadCrumbUiType = {
    title: string,
    pageTitle: string
    actionName?: string;
    onClickAction?: ()=> void
}
export const BreadCrumbActionUi = ({ title, pageTitle, actionName, onClickAction }: BreadCrumbUiType) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                        <div className="page-title-left">
                            <h4 className="mb-sm-0">{title}</h4>
                            <Breadcrumb className="mb-0">
                                    <BreadcrumbItem>
                                        <Link to="#">{pageTitle}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>{title}</BreadcrumbItem>
                                </Breadcrumb>
                        </div>
                       


                        { actionName &&
                            <div className="page-title-right">
                            <ButtonUi color="info" onClick={onClickAction}>{actionName}</ButtonUi>
                        </div>
}

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
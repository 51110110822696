import { notificationType } from "./rootReducer";

export const INIT_MAP = '[ROOT] INIT_MAP';
export const SET_REDIRECT = '[ROOT] SET_REDIRECT';
export const SET_NOTIFICATION = '[ROOT] SET_NOTIFICATION';

export type InitMapActionType = {
    type: typeof INIT_MAP;
    payload: any;
};
export function InitMapAction(payload: any): InitMapActionType {
    return { type: INIT_MAP, payload };
}

export const SET_OPTION_MAP = '[ROOT] SET_OPTION_MAP';
export type SetOptionMapActionType = {
    type: typeof SET_OPTION_MAP;
    payload: {opt: 'zoom' | 'padding' | 'showMarker' | 'isClustered', value: any};
};
export function SetOptionMapAction(payload: {opt: 'zoom' | 'padding'| 'showMarker' | 'isClustered', value: any}): SetOptionMapActionType {
    return { type: SET_OPTION_MAP, payload };
}

export type SetRedirectType = {
    type: typeof SET_REDIRECT;
    payload?: string;
};
export function SetRedirectAction(payload?: string): SetRedirectType {
    return { type: SET_REDIRECT, payload };
}

export type SetNotificationType = {
    type: typeof SET_NOTIFICATION;
    payload?: notificationType;
};
export function SetNotificationAction(payload?: notificationType): SetNotificationType {
    return { type: SET_NOTIFICATION, payload };
}



export type MainActionsType = InitMapActionType | SetOptionMapActionType | SetNotificationType | SetRedirectType;

import { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import { KmBar } from "../chart/km-bar";
import { getKmStats, KmBarResponse } from "../dashboard.service";

export const KmStatus = () => {
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<KmBarResponse[]>([]);

    useEffect(()=> {
        setLoading(true);
        getKmStats()
            .then((response)=> {
                setData(response);
            })
            .catch((response)=> {
                setData(response);
            })
            .finally(()=> {
                setLoading(false);
            });   
    }, []);


    return (
        <CardUi>
            <LoaderUi loading={loading} />
            <CardBody>
                <div className="d-flex flex-row justify-content-between">
                    <h6 className="mb-0 fs-15">Distance parcourus (km)</h6>
                </div>
                <div>
                    <KmBar data={data}/>
                </div>
            </CardBody>
        </CardUi>               
    );
}
import { Polyline } from "react-leaflet"
import * as mapboxPolyline from "@mapbox/polyline";
import { useEffect, useState } from "react";
import EmitterActions from "../../../../helpers/emitter/emitter.actions";
import Emitter from "../../../../helpers/emitter";
import { RouteMarker } from "../marker/Route/route-marker";

export const RoutesPolyline = () => {
    const [routes, setRoutes] = useState([]);

    useEffect( () => {
        Emitter.on(EmitterActions.ROUTES_INIT, setRoutes);
        return () => {
            Emitter.off(EmitterActions.ROUTES_INIT, setRoutes);
        };
    }, []);

    return (
        <>
            { 
                routes.map((route: any) => {
                    const positions = mapboxPolyline.decode(route.geometry);
                    return (
                        <>
                        {
                            route.steps.map((step: any) => {
                                return(
                                    <RouteMarker 
                                        position={step.location} 
                                        color={route.color}
                                        type="stop" 
                                    />
                                )
                            })
                        }
                            
                            <RouteMarker 
                                position={route.start} 
                                color={route.color}
                                type="start"
                            />
                            <RouteMarker 
                                position={route.end} 
                                color={route.color}
                                type="end"
                            />
                            <Polyline 
                                key={route.key}
                                positions={positions}
                                color="white"
                                weight={8}
                            />
                            <Polyline 
                                key={route.key}
                                positions={positions}
                                color={route.color ?? "red"}
                                weight={4}
                            />
                        </>
                        
                    )
                })
            }
        </>

    )
}

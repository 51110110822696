import { TableUi } from "../../../Components/Table";
import { useCallback, useContext } from "react";
import { LayoutContext } from "../../../context/layout-style-context";
import { IoMdClose } from "react-icons/io";
import { CardHeader } from "reactstrap";
import { ButtonUi } from "../../../Components/basic/ButtonUi";
import { CardUi } from "../../../Components/basic/Card";
import { formatDate } from "../../../helpers/dateHelper";
import { useAppSelector } from "../../../redux/hooks";
import ReportPagination from "./report-detail/report-pagination";
import { LoaderUi } from "../../../Components/basic/LoaderUi";
import classNames from "classnames";
import { BadgeUi } from "../../../Components/basic/BadgeUi";

type ReportTableProps = {
    data: any;
    name: string;
    columns: { key: string, title: string, addToSensors?: boolean }[];
    skip: number;
    loading: boolean;
    isFull: boolean;
    position: number;
    fetch: (skip: number, limit: number) => void;
    close: () => void;
}
const ReportTable = ({position, data, columns: reportColumns, name, skip, loading, isFull, fetch, close}: ReportTableProps) => {
    const { user } = useAppSelector((state) => state.authReducer);
    const { height } = useContext(LayoutContext);
    
    const getColumns = useCallback(()=> {
        return reportColumns.map(column => {
            return {
                ...column,
                dataIndex: column.key,
                render: (value: any, rowData: any) => {
                    switch(column.key) {
                        case "start":
                        case "end": 
                        case "unitDate": {
                            return user?.timezone && value ? formatDate(value, user.timezone, 'DD/MM/YYYY HH:mm:ss') : '-';
                        }
                        case "posStart":
                        case "posEnd": {
                            const url = `https://www.google.com/maps/search/?api=1&query=${value[1]},${value[0]}`;
                            return <a className="text-info" style={{zIndex: 0}} target="_blank" href={url}>[{value[1]?.toFixed(4)}, {value[0]?.toFixed(4)}]</a>
                        }
                        case "distance": {
                            return `${value} km`
                        }
                        case "maxSpeed": 
                        case "avgSpeed": {
                            return `${value} km/h`
                        }
                        case "consumption": {
                            return `${value?.toFixed(1) ?? '-'} L`
                        }
                        case "ignition":
                        case "movement": {
                            return value ? <BadgeUi color={"success"}><>On</></BadgeUi> : <BadgeUi color={"danger"}><>Off</></BadgeUi>
                        }
                        case "type": {
                            if(value === 'fill') {
                                return <BadgeUi color={"success"}><>Remplissage</></BadgeUi>
                            } else if(value === 'drill') {
                                return <BadgeUi color={"danger"}><>Pérte</></BadgeUi>
                            } else {
                                return value
                            }
                            
                        }

                        default: {
                            if(column.addToSensors && rowData.sensors) {
                                const parsedSensors = JSON.parse(rowData.sensors);
                                const value = parsedSensors[column.key];
                                switch(typeof value) {
                                    case "boolean": {
                                        return value ? <BadgeUi color={"success"}><>On</></BadgeUi> : <BadgeUi color={"danger"}><>Off</></BadgeUi>
                                    }
                                    case "undefined": {
                                        break;
                                    }
                                    default: {
                                        return parsedSensors[column.key];
                                    }
                                }
                            }
                            return value;
                        }
                    }
                }
            }
        })
    }, [reportColumns]);

    const onPaginate = useCallback((skip: number, limit: number) => {
        fetch(skip, limit);
    }, []);

    const CardClassName = classNames({
        "mb-1": position === 0,
        "mb-2": position === 1
    })

    return (
        <CardUi className={CardClassName} style={{flex: 1}}> 
            <LoaderUi loading={loading}/>
            <CardHeader className="report-actions d-flex align-items-center">
                <ReportPagination total={data?.total ?? 0} skip={skip} limit={25} onPaginate={onPaginate}/>
                <h6 className="mb-0"> {name} </h6>
                <div className="d-flex align-items-center">
                    {/* <div className="d-flex align-items-center me-3">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="button" type="button" className="animation btn btn-primary btn-sm">
                                    <div className="d-flex flex-row align-items-center"><FaDownload className="me-1"/>  <span>Telecharger</span></div>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={()=> alert('gggg')} className="d-flex flex-row">
                                    <RiFileExcel2Line size={18} className="text-success me-1" />
                                    <span>Format Excel</span>
                                </DropdownItem>
                                <DropdownItem onClick={()=> alert('gggg')} className="d-flex flex-row">
                                    <BsFillFileEarmarkPdfFill className="text-danger me-1" size={18} />
                                    <span>Format Pdf</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div> */}
                    <ButtonUi color="primary" size="sm" ghost onClick={close}>
                        <IoMdClose size={18}/>
                    </ButtonUi>
                </div>
            </CardHeader>
            <TableUi
                columns={getColumns()}
                data={data?.data ?? []}
                minusHeight={(isFull ?  143 : height/2 + 94 ) ?? 0}
            /> 
        </CardUi> 
    );
}

export default ReportTable;
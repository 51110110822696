import _ from 'lodash';
import { Moment } from 'moment-timezone';
import { BASE_URL } from '../../../../../env';
import { axiosInstance } from '../../../../../redux/setupAxios';
import { PeriodType, TripData } from './types';

export type fetchHistoryResponse = {
    stats?: {
        km: number;
        fuel: number;
        duration: number;
    };
    trips: TripData[],
}
export async function fetchHistory(payload: PeriodType & {_id: string}): Promise<fetchHistoryResponse> {
    const { from, to, _id } = payload;
    const params = `?from=${from}&to=${to}`;
    const url = `${BASE_URL}/api/vehicle/${_id}/history${params}`;
    const result = await axiosInstance.get<fetchHistoryResponse>(url);

    return result.data    
}

export type fetchTrajectsResponse = { _id: string, startDate: string, location: string };
export async function fetchTrajects(payload: { unitId: string, ids: string[], time: {start: number, end: number}}): Promise<fetchTrajectsResponse[]> {
    const { ids, unitId, time } = payload;

    const url = `${BASE_URL}/api/vehicle/${unitId}/trajects`;
    const axiosCalls: any[] = [];
    const chunks = _.chunk(ids, 50);
    chunks.forEach((entries, i: number) => {
        let filter = '';
        if (i === 0 || i === chunks.length - 1) {
            filter = `?start=${time.start}&end=${time.end}`;
        }

        return axiosCalls.push(axiosInstance.post<fetchTrajectsResponse[]>(url + filter, entries));
    });

    let all: any = await Promise.all(axiosCalls);
    all = all.map((el: any) => el.data);
    return all.flat();
}

export async function fetchTraject(payload: {_id: string, unitId: string, time: {startDate: Moment, endDate: Moment}}): Promise<any> {
    const { time, _id, unitId } = payload;
    const { startDate, endDate } = time;
    const filter = `?start=${startDate.toDate().getTime()}&end=${endDate
        .toDate()
        .getTime()}`;
    const params = `/${_id}`;
    const url = `${BASE_URL}/api/vehicle/${unitId}/traject${params}${filter}`;
    return axiosInstance.get(url);
}


export async function fetchParking(payload: { unitId: string, time: {startDate: Moment, endDate: Moment}}): Promise<any> {
    const { time, unitId } = payload;
    const { startDate, endDate } = time;
    const filter = `?from=${startDate.toDate().getTime()}&to=${endDate
        .toDate()
        .getTime()}`;    
    const url = `${BASE_URL}/api/event/${unitId}/parking${filter}`;
    const parking = await axiosInstance.get(url);
    return parking.data
}



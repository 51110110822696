import React from 'react';
import { IconType } from 'react-icons';
import { AiOutlineCheck } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { SetModuleBillingAction } from '../redux/billing.actions';

export function Subscription({
    name,
    ICON,
    active = false,
}: {
    name: string;
    ICON: IconType;
    active?: boolean;
}): JSX.Element {
    const dispatch = useDispatch();

    const onSubmit = async (): Promise<void> => {
        dispatch(SetModuleBillingAction({ module: name }));
    };

    return (
        <div
            className={`subscription ${active ? 'subscription-active' : ''}`}
            onClick={onSubmit}
            tabIndex={0}
            role="button"
            onKeyDown={onSubmit}
        >
            {active ? <AiOutlineCheck /> : <ICON />}
            <span>{name}</span>
        </div>
    );
}

import {
    createElementObject,
    createPathComponent,
    extendContext,
  } from '@react-leaflet/core';
import { antPath } from "leaflet-ant-path";



function createCustomMarker(props, context) {
    const { positions, setZIndexOffset, options } = props;
    const path = antPath(positions, options);

    return createElementObject(
        path,
        extendContext(context, { overlayContainer: path }),
    )
  }
  
  function updateCustomMarker(instance, props, prevProps) {
    if (prevProps.positions !== props.positions) {
      instance.setLatLngs(prevProps.positions);
    }
  
    if (props.zIndexOffset && props.zIndexOffset !== prevProps.zIndexOffset) {
      instance.setZIndexOffset(props.zIndexOffset);
    }
    instance.setStyle({ ...props.options, ...prevProps.options });
  }
  
export const AntPolyline = createPathComponent(createCustomMarker, updateCustomMarker);
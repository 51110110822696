import { useEffect, useState } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { InputText } from "../../../Components/Form/InputText";
import { numberValidator } from "../../../helpers/validationHelper";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any},
}

export const General = ({register ,errors}: ManageCompanyPageType) => {
    const intl = useIntl();
    const validatorNumber = numberValidator(true, 2, 3600*8);
    
    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="ALARM.INFO" /></h4>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <InputText
                        type="text"
                        label={intl.formatMessage({id: 'ALARM.NAME'})}
                        id={'name'}
                        name={'name'}
                        placeholder={intl.formatMessage({id: 'ALARM.PLACEHOLDER.NAME'})}
                        register={register('name', {
                            required: {
                                value: true, 
                                message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                            }})}
                        error={errors['name']}
                    />
                </FormGroup>
                <FormGroup>
                    <InputText
                        type="textarea"
                        label={intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                        id={'description'}
                        name={'description'}
                        placeholder={intl.formatMessage({id: 'ALARM.PLACEHOLDER.DESCRIPTION'})}
                        register={register('description')}
                        error={errors['description']}
                    />
                </FormGroup>
                <FormGroup>
                    <InputText
                        type="number"
                        label={intl.formatMessage({ id: 'COMMON.INTERVAL' })}
                        id={'interval'}
                        name={'interval'}
                        placeholder={intl.formatMessage({id: 'ALARM.PLACEHOLDER.INTERVAL'})}
                        register={register('interval', validatorNumber)}
                        error={errors['interval']}
                    />
                </FormGroup>
            </CardBody>
        </CardUi>               
    );
}

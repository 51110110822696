import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SetRedirectAction } from '../../../redux/rootActions';
import { RootState } from '../../../redux/store';
import * as actions from './authActions';
import {
    AuthActionsType,
    LoginEndActionType,
    LoginGenerateTokenActionType,
    LoginStartActionType,
    LogoutActionType,
    SwitchUserStartType,
} from './authActions';
import {
    deleteAuthCookie,
    deleteLoginCookie,
    logIn,
    requestNewToken,
    saveDataToCookies,
    switchToMainUser,
    switchToUser,
} from './authService';

export const LogInEpic = (
    action$: ActionsObservable<LoginStartActionType>
): Observable<AuthActionsType> =>
    action$.pipe(
        ofType(actions.LOGIN_START),
        switchMap((action: LoginStartActionType) => {
            return from(logIn(action.payload)).pipe(
                map((res) => {
                    return actions.LoginEnd(res.data);
                }),
                catchError((err) => {
                    let msg = 'COMMON.INTERN_ERROR';
                    if (err.response.status === 401) {
                        msg = 'LOGIN.WRONG_PASSWORD';
                    }
                    return of(actions.Error(msg));
                })
            );
        }),
        catchError((err) => {
            return of(actions.Error('COMMON.INTERN_ERROR'));
        })
    );

    export const LoginGenerateToken = (
        action$: ActionsObservable<LoginGenerateTokenActionType>
    ): Observable<AuthActionsType | any> =>
        action$.pipe(
            ofType(actions.LOGIN_GENERATE_TOKEN),
            
            switchMap((action: LoginGenerateTokenActionType) => {
                return from(requestNewToken(action.payload.refreshToken ?? '')).pipe(
                    map((res) => {
                        return actions.LoginEnd(res.data);
                    }),
                    catchError((err) => {
                        return of( actions.Error(''), actions.Logout() );
                    })
                );
            }),
            catchError((err) => {
                return of(actions.Error('COMMON.INTERN_ERROR'));
            })
        );
    export const LogInEndEpic = (
        action$: ActionsObservable<LoginEndActionType>
    ): Observable<AuthActionsType | any> =>
    action$.pipe(
        ofType(actions.LOGIN_END),
        map((action: LoginEndActionType) => {
            saveDataToCookies(action.payload);
            return actions.Error('');
        }),
        catchError((err) => {
            return of(actions.Error('COMMON.INTERN_ERROR'));
        })
    );

export const SwitchUserEpic = (
    action$: ActionsObservable<SwitchUserStartType>,
    state$: { value: RootState }
): Observable<AuthActionsType> =>
    action$.pipe(
        ofType(actions.SWITCH_USER_START_TYPE),
        switchMap((action: SwitchUserStartType) => {
            return from(switchToUser(action.payload)).pipe(
                map((result) => {
                    // if (result.modifiedCount > 0) {
                        deleteAuthCookie();
                        window.location.href = '/map';
                    // }
                    return actions.SwitchToUserEndAction();
                }),
                catchError((err) => {
                    return of(actions.Error(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(actions.Error(err.msg));
        })
    );

export const SwitchToMainUserEpic = (
    action$: ActionsObservable<actions.SwitchToMainUserStartType>,
    state$: { value: RootState }
): Observable<AuthActionsType> =>
    action$.pipe(
        ofType(actions.SWITCH_TO_MAIN_USER_START_TYPE),
        switchMap((action: actions.SwitchToMainUserStartType) => {
            return from(switchToMainUser()).pipe(
                map((result) => {
                    // if (result.modifiedCount > 0) {
                        deleteAuthCookie();
                        window.location.href = '/map';
                    // }
                    return actions.SwitchToMainUserEndAction();
                }),
                catchError((err) => {
                    return of(actions.Error(err.msg));
                })
            );
        }),
        catchError((err) => {
            return of(actions.Error(err.msg));
        })
    );

    export const LogOutEpic = (
        action$: ActionsObservable<LogoutActionType>
    ): Observable<any> =>
        action$.pipe(
            ofType(actions.LOGOUT),
            map((action: LogoutActionType) => {
                deleteLoginCookie();
                return SetRedirectAction('/')
            }),
            catchError((err) => {
                return of(actions.Error('COMMON.INTERN_ERROR'));
            })
        );
/* eslint-disable react/jsx-no-undef */
import { formatMessage } from "@formatjs/intl";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Row, Col, CardBody, CardHeader, FormGroup } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { AsyncInputSelect } from "../../../Components/Form/AsyncInputSelect";
import { InputAsyncCreatableSelect } from "../../../Components/Form/CreatableSelect";
import { useAppSelector } from "../../../redux/hooks";
import { SetMaintenanceTypeAction, SetMaintenanceVehicleAction } from "../_redux/maintenance.actions";
import { getTypeForMaintenance, getVehiclesForMaintenance } from "../_redux/maintenance.service";

export const General = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { manage } = useAppSelector((state) => state.MaintenanceReducer);
    const {groupId, maintenanceId} = useParams();
    const isUpdate = !!(groupId && maintenanceId);

    const fetchVehicles = async (inputValue: string) => !isUpdate ? getVehiclesForMaintenance({ name: inputValue }) : [];
    const fetchTypeName = async (inputValue: string) => !isUpdate ? getTypeForMaintenance({ name: inputValue }) : [];

    const setName = (payload: any) => {
        if(!isUpdate) {
            dispatch(SetMaintenanceTypeAction(payload));
        }
    }

    const setVehicle = (payload: any) => {
        if(!isUpdate) {
            dispatch(SetMaintenanceVehicleAction(payload));
        }
    }
    
    
    return (
        <CardUi>
            <CardHeader>
                <h5 className="card-title mb-0">
                    <FormattedMessage id="COMMON.GENERAL_INFORMATIONS"/>
                </h5>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            {
                               ((isUpdate && manage._id) || !isUpdate) && <InputAsyncCreatableSelect 
                                    isClearable
                                    label={intl.formatMessage({ id: 'COMMON.NAME'})}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={manage.name}
                                    error={!manage.name}
                                    loadOptions={fetchTypeName}
                                    onChange={setName}
                                    isDisabled={isUpdate}
                                />
                            }
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            {
                                ((isUpdate && manage._id) || !isUpdate) &&  <AsyncInputSelect 
                                    label={intl.formatMessage({ id: 'COMMON.UNIT'})}
                                    cacheOptions
                                    defaultOptions
                                    defaultValue={manage.unit}
                                    error={!manage.unit}
                                    loadOptions={fetchVehicles}
                                    onChange={setVehicle}
                                    isDisabled={isUpdate}
                                />
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </CardUi>
    );
}
import { axiosInstance } from "../../../redux/setupAxios";
import { ManageUserType, FilterUserType } from "../types";


export async function saveUser(user: ManageUserType): Promise<any> {
    const {email, mobile, ...data} = user
    const userTocreate: ManageUserType = {...data};
    if(email && email.length > 0) { userTocreate.email = email; }
    if(mobile && mobile.length > 0) { userTocreate.mobile = mobile; }
    const url = `/account`;
    try {
        const res = await axiosInstance.post<ManageUserType>(url, userTocreate);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function updateUser(
    user: ManageUserType
): Promise<ManageUserType> {
    const {email, mobile, password, ...data} = user
    const userTocreate: ManageUserType = {...data};
    if(email && email.length > 0) { userTocreate.email = email; }
    if(mobile && mobile.length > 0) { userTocreate.mobile = mobile; }
    if(password && password.length > 0) { userTocreate.password = password; }
    const url = `/account`;
    try {
        const res = await axiosInstance.patch<any>(url, userTocreate);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getUsers(filter: FilterUserType): Promise<any> {
    let url = `/account?skip=${filter.skip}&limit=${filter.limit}`;
    if (filter.name && filter.name !== '') {
        url += `&name=${filter.name}`;
    }
    if (filter.select) {
        url += `&select=${filter.select}`;
    }
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getUser({ _id }: { _id: string }): Promise<any> {
    const url = `/account/${_id}`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function deleteUsers({
    _ids,
}: {
    _ids: string[];
}): Promise<{ data: { deletedCount: number } }> {
    let params = '?';
    _ids.forEach((id, i) => {
        if (i !== 0) {
            params += `&ids[]=${id}`;
        } else {
            params += `ids[]=${id}`;
        }
    });

    return axiosInstance.delete<{ deletedCount: number }>(`/account${params}`);
}

export async function getUserRoles(): Promise<any> {
    try {
        const res = await axiosInstance.get<any>('company/subscription');
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function fetchAccountByUserName(
    username: string | undefined
): Promise<any> {
    try {
        const res = await axiosInstance.get<any>(
            `account/username/${username}`
        );
        if (res.data.found === false) {
            return { for: 'username', error: false };
        }
        // eslint-disable-next-line no-empty
    } catch (e) {}
    return {
        for: 'username',
        error: true,
        msg: "Nom d'utilisateur déjà utilisé !",
    };
}

export async function assignVehiclesToUser(
    _id: string,
    vehicles: string[]
): Promise<{
    updatedBy: string;
    updatedAt: string;
    modifiedCount: number;
}> {
    const url = `/account/assign`;
    try {
        const res = await axiosInstance.patch<any>(url, {
            _id,
            vehiclesToAssign: vehicles,
        });
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

export async function getTypesUser(): Promise<{label: string, value: string}[]> {
    const url = `/account/types`;
    try {
        const res = await axiosInstance.get<any>(url);
        return res.data;
    } catch (e: any) {
        throw Error(e.message);
    }
}

/* eslint-disable */
import PerfectScrollbar from 'perfect-scrollbar';

import { useContext, useEffect, useRef } from 'react';
import '../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css';
import { LayoutContext } from '../../context/layout-style-context';

export function ScrollBar({
    children,
    id,
    minusHeight,
    minusWidth,
    containerRef,
    options,
    onEndReached
}: any) {
    const { width, height } = useContext(LayoutContext);
    const ps = useRef<PerfectScrollbar | null>(null);
    const defaultOptions = {
        wheelSpeed: 0.5,
        swipeEasing: true,
        wheelPropagation: true,
        minScrollbarLength: 40,
        suppressScrollX: true,
    };

    useEffect((): any => {
        ps.current = new PerfectScrollbar(`#${id}`, options || defaultOptions);
        let container = containerRef.current;
        if(onEndReached) {
            containerRef.current.addEventListener(
                'ps-y-reach-end',
                (event: any) => {
                    onEndReached();
                }
            )
        }
        
        return () => {
            if(container && onEndReached) {
                container.removeEventListener('ps-y-reach-end', (event: any) => {
                    onEndReached();
                })
            };
            
            ps.current = null;
        } ;
    }, []);

    useEffect(() => {
        ps.current?.update();
    }, [height]);

    return (
        <div
            ref={containerRef}
            id={id}
            style={{
                height: `${height - minusHeight}px`,
                width: minusWidth ? `${width - minusWidth}px` : '100%',
                position: 'relative'
            }}
        >
            {children}
        </div>
    );
}

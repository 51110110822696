import moment from 'moment-timezone';
import * as historyActions from './historyActions';
import * as mapboxPolyline from "@mapbox/polyline";
import { HistoryActionsType } from './historyActions';
import {
    ActiveTrip,
    MouseEvent,
    PeriodType,
    PolylineData,
    Trip,
} from './types';



export interface StateType {
    _id: string | null;
    loading: boolean;
    trip: Trip;
    period?: PeriodType;
    showTimeLine: boolean;
    polyline: PolylineData[];
    showParking: boolean;
    parking: any[];
    showTraject: boolean;
    activeTrip: ActiveTrip;
    
    points: MouseEvent;
    tripLoading: boolean;
}

const INITIAL_STATE: StateType = {
    _id: null,
    loading: false,
    trip: {
        loading: false,
        data: []
    },
    tripLoading: false,
    showTimeLine: false,
    polyline: [],
    showParking: true,
    showTraject: true,
    activeTrip: null,
    points: {
        mouseOver: null,
        mouseClick: {},
    },
    parking: [],
};

export function historyReducer(
    // eslint-disable-next-line default-param-last
    state = INITIAL_STATE,
    action: HistoryActionsType
): StateType {
    switch (action.type) {
        case historyActions.GET_START:
            return {
                ...state,
                trip: {
                    ...state.trip,
                    loading: true,
                }
            };

        case historyActions.GET_END: {
            const { trips, from, to } = action.payload;
            const tripsId: string[] = [];
            const dataTrips = [];
            const sortedTrips = trips.sort( compare );
            for(let sortedTrip of sortedTrips) {
                tripsId.push(sortedTrip._id);
                dataTrips.push({
                    ...sortedTrip, 
                    startDate: moment(sortedTrip.startDate).tz(action.payload.timezone),
                    endDate: moment(sortedTrip.endDate).tz(action.payload.timezone),
                    endHistoryDate: moment(sortedTrip.endHistoryDate).tz(action.payload.timezone),
                    maxKm: sortedTrip.maxKm,
                    minKm: sortedTrip.minKm,
                })
            }

            return {
                ...state,
                loading: false,
                trip: {
                    ids: tripsId,
                    data: dataTrips,
                    loading: false,
                },
                period: { from, to },
            };
        }

        case historyActions.ERROR:
            return { 
                ...state, 
                trip: {
                    ...state.trip,
                    loading: false,
                },
                loading: false
             };

        case historyActions.TOOGLE_TIMELINE:
            return {
                ...state,
                showTimeLine:
                    action.payload === undefined
                        ? !state.showTimeLine
                        : action.payload,
            };

        case historyActions.TOOGLE_PARKING:
            return {
                ...state,
                showParking:
                    action.payload === undefined
                        ? !state.showParking
                        : action.payload,
            };

        case historyActions.TOOGLE_TRAJECT:
            return {
                ...state,
                showTraject:
                    action.payload === undefined
                        ? !state.showTraject
                        : action.payload,
            };

        case historyActions.GET_TRAJECT_START: {
            return { ...state, polyline: INITIAL_STATE.polyline };
        }

        case historyActions.GET_TRAJECT_END: {
            const polyline = action.payload.map((trip: any) => {
                return { _id: trip._id, location: mapboxPolyline.decode(trip.location) };
            });

            return { ...state, polyline };
        }

        case historyActions.ACTIVE_TRAJECT_START: {
            return {
                ...state,
                tripLoading: true,
                activeTrip: null
            };
        }

        case historyActions.ACTIVE_TRAJECT_END: {
            return { ...state, activeTrip: action.payload, tripLoading: false };
        }

        case historyActions.SET_POINT_MOUSE_OVER:
            return {
                ...state,
                points: {
                    ...state.points,
                    mouseOver: action.payload,
                },
            };

        case historyActions.SET_POINT_MOUSE_CLICK: {
            const { index, point } = action.payload;
            let { mouseClick } = state.points;
            if (mouseClick) {
                if (!mouseClick[index]) {
                    mouseClick = { ...mouseClick };
                    mouseClick[index] = point;
                } else {
                    delete mouseClick[index];
                }

                return {
                    ...state,
                    points: {
                        ...state.points,
                        mouseClick,
                    },
                };
            }
            return state;
        }
        
        case historyActions.INIT_HISTORY:
            return INITIAL_STATE;

        case historyActions.SET_HISTORY_TIME:
            return {
                ...state,
                period: action.payload,
            };
            
        case historyActions.GET_PARKING_END: {
            return {
                ...state,
                parking: action.payload,
            }
        }
        default:
            return state;
    }
}


function compare( a: any, b: any ) {    
    if ( a.startDate < b.startDate ){
      return -1;
    }

    if ( a.startDate > b.startDate ){
      return 1;
    }
    return 0;
  }
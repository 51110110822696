import React from "react"
import ContentLoader from "react-content-loader"
type DriverMarkProps = {
  [key: string]: any
}
const DriverEcoMarkSkeleton = (props: DriverMarkProps) => (
  <div {...props}>
    <ContentLoader 
      speed={2}
      width={368}
      height={69}
      viewBox="0 0 368 69"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> 
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" /> 
    <rect x="0" y="20" rx="2" ry="2" width="52" height="39" /> 
    <rect x="57" y="37" rx="2" ry="2" width="18" height="18" /> 
    <rect x="83" y="37" rx="2" ry="2" width="18" height="18" /> 
    <rect x="107" y="37" rx="2" ry="2" width="18" height="18" /> 
    <rect x="133" y="37" rx="2" ry="2" width="18" height="18" /> 
    <rect x="158" y="37" rx="2" ry="2" width="18" height="18" /> 
    <rect x="134" y="61" rx="4" ry="4" width="42" height="8" /> 
    <rect x="295" y="0" rx="2" ry="2" width="65" height="8" /> 
    <rect x="0" y="0" rx="2" ry="2" width="65" height="8" />
    </ContentLoader>
  </div>
  
)

export default DriverEcoMarkSkeleton
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";
import { AssignVehicleToDriver } from ".";
import { LoaderUi } from "../../../../Components/basic/LoaderUi";
import { useAppSelector } from "../../../../redux/hooks";
import { BindDriverStart } from "../../_redux/driverModule.actions";


export const AssignDriverSlider = () => {
    const { key } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [queryParameters] = useSearchParams();

    

    const { loading } = useAppSelector((state) => state.driverModuleReducer);
    
    const goToDrivers = () => {
        navigate(`/drivers/${key}/details`)
    }

    const assign = (unit: string) => {
        if(key) {
            dispatch(BindDriverStart({driver: key, unit}));
        }
    }

    return (
        <Offcanvas  className="assignVehicles border-0" direction="end" isOpen={!!queryParameters.get('assign-request')} toggle={goToDrivers}>
            <LoaderUi loading={loading} />
            <OffcanvasHeader className="d-flex align-items-center bg-primary p-3 offcanvas-header-dark border-none">
                <span className="m-0 me-2 text-white"><FormattedMessage id={'DRIVER.ASSIGN_VEHICLE'}/></span>
            </OffcanvasHeader>
            <OffcanvasBody className="driver-assign-canvas">
                <AssignVehicleToDriver onCancel={goToDrivers} onSubmit={assign}/>
            </OffcanvasBody>
        </Offcanvas>  
    );
}
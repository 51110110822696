import { useEffect, useState } from "react";
import { FieldValues, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { CardUi } from "../../../Components/basic/Card";
import { InputSelect } from "../../../Components/Form/InputSelect";
import { InputText } from "../../../Components/Form/InputText";
import { passwordValidator } from "../../../helpers/validationHelper";
import { useAppSelector } from "../../../redux/hooks";
import { ChangeUserType } from "../../User/redux/user.actions";
import { getTypesUser } from "../../User/redux/user.service";

type ManageCompanyPageType = {
   register: UseFormRegister<any>,
   errors: {[x: string]: any},
   watch: UseFormWatch<FieldValues>
}

export const General = ({register, watch ,errors}: ManageCompanyPageType) => {
    const {_id} = useParams();
    const isUpdate = _id !== 'add';
    const dispatch = useDispatch();
    const intl = useIntl();
    const validatorPassword = passwordValidator(_id === 'add');

    const [types, setTypes] = useState<{label: string, value: string}[]>([]);
    const { manage } = useAppSelector((state) => state.userReducer);

    const changeUserType = (element: any): void => {
        dispatch(ChangeUserType(element.value));
    };

    const getUserTypes = async () => {
        const userTypes = await getTypesUser();
        setTypes(userTypes.map(type => {
            return {
                label: intl.formatMessage({id: type.label}),
                value: type.value
            }
        }));
    }

    useEffect(() => {
        getUserTypes();
    }, []);

    return (
        <CardUi>
            <CardHeader>
                <h4 className='card-title mb-0'><FormattedMessage id="COMMON.GENERAL_INFORMATIONS" /></h4>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <InputText
                        type="text"
                        label={intl.formatMessage({id: 'SUBSCRIPTION.NAME'})}
                        id={'name'}
                        name={'name'}
                        placeholder={intl.formatMessage({id: 'SUBSCRIPTION.PLACEHOLDER.NAME'})}
                        register={register('name', {
                            required: {
                                value: true, 
                                message: intl.formatMessage({ id: 'COMMON.REQUIRED' }),
                            }})}
                        error={errors['name']}
                    />
                </FormGroup>
                <Row>
                    <Col xl={12}>
                        <FormGroup>
                            <InputText
                                style={{height: '134px'}}
                                type="textarea"
                                label={intl.formatMessage({ id: 'COMMON.DESCRIPTION' })}
                                id={'description'}
                                name={'description'}
                                placeholder={intl.formatMessage({id: 'SUBSCRIPTION.PLACEHOLDER.DESCRIPTION'})}
                                register={register('description')}
                                error={errors['description']}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </CardUi>               
    );
}
